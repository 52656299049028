import * as React from "react";

import styled from "styled-components";

import ConfigurationPanel from "./ConfigurationPanel";
import UserPanel from "./UsersPanel";
import UsersRequestedPanel from "./UsersRequestedPanel";
import MarketsPanel from "./MarketsPanel";
import DashboardPanel from "./DashboardPanel";
import MarketDashboardPanel from "./MarketDashboardPanel";
import { HomePageLayoutTabs } from "../../utils/constants";
import useTabs from "../../hooks/useTabs";
import Button from "@ingka/button";

import clipboardCheckmark from "@ingka/ssr-icon/paths/clipboard-checkmark";
import display from "@ingka/ssr-icon/paths/display";
import globe from "@ingka/ssr-icon/paths/globe";
import people from "@ingka/ssr-icon/paths/people";
import dataChart from "@ingka/ssr-icon/paths/data-chart";

import { OverflowCarousel } from "@ingka/carousel";
import useWindowSize from "../../hooks/useWindowSize";
import { headerAndFooterHeight } from "../../utils/variables";
import { breakpointL } from "@ingka/variables/design-tokens";
import ListBox from "@ingka/list-box";
import { ListViewItem } from "@ingka/list-view";
import ButtonDropDown from "../ButtonDropDown/ButtonDropDown";

import useStore, {
	activeApplicationSelector,
	setApplicationUsersSelector,
	applicationUsersSelector,
} from "../../store";
import Schema from "../Schema";
import UiSchemas from "../ui-schemas";
import Schemas from "../Schemas";

import useAuthentication from "../../hooks/useAuthentication";
import { getAppUsersList } from "../../services/users";
import { Counter } from "../Counter/Counter";

const StyledOverflowCarousel = styled(OverflowCarousel)`
	.overflow-carousel__content {
		gap: 0.5rem;
	}
`;

export const FullHeightContainer = styled.div`
	min-height: calc(100vh - ${headerAndFooterHeight});
	padding-bottom: 2rem;
`;

const StyledListBox = styled(ListBox)`
	z-index: 1000;
`;

const StyledSectionWrapper = styled.div`
	margin-top: 2rem;

	@media only screen and (max-width: ${breakpointL}) {
		margin-top: 1rem;
	} ;
`;

const UserRequestedWrapperMenu = styled.div`
	display: flex;
	gap: 0.5rem;
`;

interface HomePageLayoutProps {
	showAdminLayout?: boolean;
}

const HomePageLayout: React.FC<HomePageLayoutProps> = ({ showAdminLayout }) => {
	const { activeTab, setActiveTab } = useTabs();
	const { isMobile, isTablet, isDesktop, isBigDesktop } = useWindowSize();
	const activeApplication = useStore(activeApplicationSelector);

	const [dashboardMenuOpen, setDashboardMenuOpen] = React.useState<boolean>(false);
	const [userMenuOpen, setUserMenuOpen] = React.useState<boolean>(false);
	const [schemaMenuOpen, setSchemaMenuOpen] = React.useState<boolean>(false);
	const { getToken } = useAuthentication();
	const setApplicationUsers = useStore(setApplicationUsersSelector);
	const applicationUsers = useStore(applicationUsersSelector);

	const DEFAULT_ACTIVE_TAB = HomePageLayoutTabs.Configuration;

	React.useEffect(() => {
		if (
			!activeTab ||
			!Object.values(HomePageLayoutTabs).includes(activeTab as HomePageLayoutTabs) ||
			(activeApplication && !activeApplication.showDashboard && activeTab === HomePageLayoutTabs.Dashboard) ||
			(activeApplication && !activeApplication.showMarketDashboard && activeTab === HomePageLayoutTabs.MarketDashboard)
			// Do not remove the below code maybe needed later

			//  || (!showAdminLayout &&
			// 	activeTab !== HomePageLayoutTabs.Configuration &&
			// 	!showAdminLayout &&
			// 	activeTab !== HomePageLayoutTabs.Dashboard)
		) {
			setActiveTab(DEFAULT_ACTIVE_TAB);
		}
	}, [DEFAULT_ACTIVE_TAB, activeTab, setActiveTab, activeApplication, showAdminLayout]);

	const navigationButtonsData = React.useMemo(() => {
		const navigationButtons = [
			{
				key: "configuration",
				text: "Configuration",
				sectionId: HomePageLayoutTabs.Configuration,
				icon: clipboardCheckmark,
				visible: true,
			},
			{
				key: "schemas",
				text: "Schemas",
				sectionId: HomePageLayoutTabs.Schemas,
				icon: display,
				visible: showAdminLayout,
			},
			{ key: "markets", text: "Markets", sectionId: HomePageLayoutTabs.Markets, icon: globe, visible: showAdminLayout },
			{
				key: "dashboard",
				text: "Dashboard",
				sectionId: HomePageLayoutTabs.Dashboard,
				icon: dataChart,
				visible: activeApplication ? activeApplication.showDashboard || activeApplication.showMarketDashboard : false,
			},
			{ key: "users", text: "Users", sectionId: HomePageLayoutTabs.Users, icon: people, visible: showAdminLayout },
		];
		return navigationButtons;
	}, [showAdminLayout, activeApplication]);

	const SectionToRender = React.useMemo(() => {
		switch (activeTab) {
			case HomePageLayoutTabs.Configuration:
				return <ConfigurationPanel />;
			case HomePageLayoutTabs.Schemas:
				return <Schemas />;
			case HomePageLayoutTabs.Markets:
				return <MarketsPanel />;
			case HomePageLayoutTabs.Users:
				return <UserPanel />;
			case HomePageLayoutTabs.UsersRequested:
				return <UsersRequestedPanel />;
			case HomePageLayoutTabs.Dashboard:
				return <DashboardPanel />;
			case HomePageLayoutTabs.MarketDashboard:
				return <MarketDashboardPanel />;
			case HomePageLayoutTabs.SchemaBuilder:
				return <Schema />;
			case HomePageLayoutTabs.UiSchemaBuilder:
				return <UiSchemas />;
			default:
				return <ConfigurationPanel />;
		}
	}, [activeTab]);

	const handleClickDashboardMenuItem = React.useCallback(
		(event: string) => {
			if (event === "dashboard") {
				setActiveTab(HomePageLayoutTabs.Dashboard);
			} else if (event === "marketDashboard") {
				setActiveTab(HomePageLayoutTabs.MarketDashboard);
			} else if (event === "users") {
				setActiveTab(HomePageLayoutTabs.Users);
			} else if (event === "usersRequested") {
				setActiveTab(HomePageLayoutTabs.UsersRequested);
			} else if (event === HomePageLayoutTabs.SchemaBuilder) {
				setActiveTab(HomePageLayoutTabs.SchemaBuilder);
			} else if (event === HomePageLayoutTabs.UiSchemaBuilder) {
				setActiveTab(HomePageLayoutTabs.UiSchemaBuilder);
			} else if (event === HomePageLayoutTabs.Schemas) {
				setActiveTab(HomePageLayoutTabs.Schemas);
			}
			if (dashboardMenuOpen) {
				setDashboardMenuOpen(false);
			}
			if (userMenuOpen) {
				setUserMenuOpen(false);
			}
			if (schemaMenuOpen) {
				setSchemaMenuOpen(false);
			}
		},
		[setActiveTab, dashboardMenuOpen, userMenuOpen, schemaMenuOpen]
	);

	React.useEffect(() => {
		(async () => {
			if (activeApplication) {
				const token = await getToken();
				try {
					const { data: usersData } = await getAppUsersList(token, activeApplication?.id);
					setApplicationUsers(usersData.data);
				} catch (error: any) {
					setApplicationUsers(null);
				}
			} else {
				setApplicationUsers(null);
			}
		})();

		return () => {
			// cancel the subscription
			setApplicationUsers(null);
		};
	}, [activeApplication, getToken, setApplicationUsers]);

	return (
		<FullHeightContainer>
			{(isBigDesktop || isDesktop) && (
				<StyledOverflowCarousel>
					{navigationButtonsData.map(
						({ key, text, sectionId, icon, visible }) =>
							(visible &&
								sectionId !== HomePageLayoutTabs.Dashboard &&
								sectionId !== HomePageLayoutTabs.Users &&
								sectionId !== HomePageLayoutTabs.Schemas && (
									<Button
										data-testid={key}
										key={key}
										text={text}
										onClick={() => {
											setActiveTab(sectionId);
										}}
										small={isMobile || isTablet}
										type={sectionId !== activeTab ? "tertiary" : "secondary"}
										ssrIcon={icon}
									/>
								)) ||
							(visible && sectionId === HomePageLayoutTabs.Dashboard && (
								<ButtonDropDown
									id="dashboard-menu-btn"
									key={key}
									text={text}
									small={isMobile || isTablet}
									type={
										activeTab === HomePageLayoutTabs.Dashboard || activeTab === HomePageLayoutTabs.MarketDashboard
											? "secondary"
											: "tertiary"
									}
									ssrIcon={icon}
									open={dashboardMenuOpen}
									setMenuOpen={setDashboardMenuOpen}
									className={"marginRight: 0.5rem"}
								>
									<ListViewItem
										data-testid="dashboard-list-viewitem-marketDashboard"
										id="dashboard-list-viewitem-marketDashboard"
										key="dashboard-list-viewitem-marketDashboard"
										control="radiobutton"
										controlProps={
											{
												value: "marketDashboard",
												checked: activeTab === "marketDashboard",
											} as any
										}
										inset
										title="Market dashboard"
										disabled={!activeApplication?.showMarketDashboard}
										onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
									/>
									<ListViewItem
										data-testid="dashboard-list-viewitem-dashboard"
										id="dashboard-list-viewitem-dashboard"
										key="dashboard-list-viewitem-dashboard"
										control="radiobutton"
										controlProps={
											{
												value: "dashboard",
												checked: activeTab === "dashboard",
											} as any
										}
										inset
										title="Global dashboard"
										disabled={!activeApplication?.showDashboard}
										onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
									/>
								</ButtonDropDown>
							)) ||
							(visible && sectionId === HomePageLayoutTabs.Users && (
								<>
									<ButtonDropDown
										data-testid="users-menu-btn"
										id="users-menu-btn"
										key={key}
										text={text}
										small={isMobile || isTablet}
										type={
											activeTab === HomePageLayoutTabs.Users || activeTab === HomePageLayoutTabs.UsersRequested
												? "secondary"
												: "tertiary"
										}
										ssrIcon={icon}
										open={userMenuOpen}
										setMenuOpen={setUserMenuOpen}
										showCounter={applicationUsers && applicationUsers.requests && applicationUsers.requests.length > 0}
										counter={applicationUsers?.requests?.length}
										className={"marginRight: 0.5rem"}
									>
										<ListViewItem
											data-testid="users-list-viewitem-usersonboarded"
											id="users-list-viewitem-usersonboarded"
											key="users-list-viewitem-usersonboarded"
											control="radiobutton"
											controlProps={
												{
													value: "users",
													checked: activeTab === "users",
												} as any
											}
											inset
											title="Users onboarded"
											onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
										/>
										<ListViewItem
											data-testid="users-list-viewitem-usersrequested"
											id="users-list-viewitem-usersrequested"
											key="users-list-viewitem-usersrequested"
											control="radiobutton"
											controlProps={
												{
													value: "usersRequested",
													checked: activeTab === "usersRequested",
												} as any
											}
											inset
											title={
												<UserRequestedWrapperMenu>
													Users requests
													{applicationUsers && applicationUsers.requests && applicationUsers.requests.length > 0 && (
														<Counter>{applicationUsers.requests.length}</Counter>
													)}
												</UserRequestedWrapperMenu>
											}
											onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
										/>
									</ButtonDropDown>
								</>
							)) ||
							(visible && sectionId === HomePageLayoutTabs.Schemas && (
								<ButtonDropDown
									data-testid="schemas-menu-btn"
									id="schemas-menu-btn"
									key={key}
									text={text}
									small={isMobile || isTablet}
									type={
										activeTab === HomePageLayoutTabs.Schemas ||
										activeTab === HomePageLayoutTabs.SchemaBuilder ||
										activeTab === HomePageLayoutTabs.UiSchemaBuilder
											? "secondary"
											: "tertiary"
									}
									ssrIcon={icon}
									open={schemaMenuOpen}
									setMenuOpen={setSchemaMenuOpen}
									className={"marginRight: 0.5rem"}
								>
									<ListViewItem
										data-testid="schema-builder"
										id="schema-builder"
										key="schema-builder"
										control="radiobutton"
										controlProps={
											{
												value: HomePageLayoutTabs.SchemaBuilder,
												checked: activeTab === HomePageLayoutTabs.SchemaBuilder,
											} as any
										}
										inset
										title="Schema Builder"
										onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
									/>
									<ListViewItem
										data-testid="ui-schema-builder"
										id="ui-schema-builder"
										key="ui-schema-builder"
										control="radiobutton"
										controlProps={
											{
												value: HomePageLayoutTabs.UiSchemaBuilder,
												checked: activeTab === HomePageLayoutTabs.UiSchemaBuilder,
											} as any
										}
										inset
										title="Layout Builder"
										onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
									/>
									<ListViewItem
										data-testid="json-schemas"
										id="json-schemas"
										key="json-schemas"
										control="radiobutton"
										controlProps={
											{
												value: HomePageLayoutTabs.Schemas,
												checked: activeTab === HomePageLayoutTabs.Schemas,
											} as any
										}
										inset
										title="JSON Schemas"
										onChange={(event) => handleClickDashboardMenuItem((event.target as any)?.value)}
									/>
								</ButtonDropDown>
							))
					)}
				</StyledOverflowCarousel>
			)}
			<StyledSectionWrapper>{SectionToRender}</StyledSectionWrapper>
		</FullHeightContainer>
	);
};

export default HomePageLayout;
