import * as React from "react";
import styled from "styled-components";

import Card from "@ingka/card";

import { Paragraph, StyledAnchor } from "../styles";
import { SLACK_LINK } from "../../utils/constants";

const StyledDiv = styled.div`
	height: 100vh;
`;

const Wrapper = styled.div`
	padding-top: 8rem;
`;

const TitleWrapper = styled(Card)`
	span {
		display: block;
		border: 0;
	}
`;

const StyledParagraph = styled(Paragraph)`
	margin-top: 2rem;
`;
const H1 = styled.h1(({ margin }: { margin?: string }) => (margin ? `margin: ${margin};` : ""));

const ErrorPage: React.FC = () => {
	return (
		<StyledDiv>
			<Wrapper>
				<div>
					<H1 margin="0">Sorry, the requested page was not found</H1>
					<StyledParagraph>
						Please try again later or contact us in{" "}
						<StyledAnchor href={SLACK_LINK} target="_blank">
							#cssom-flagga
						</StyledAnchor>{" "}
						for any questions!
					</StyledParagraph>
				</div>
			</Wrapper>
		</StyledDiv>
	);
};

export default ErrorPage;
