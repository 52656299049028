/* eslint-disable indent */
import * as React from "react";
import useAuthentication from "./useAuthentication";
import { cancelNewApplicationRequest } from "../services/users";

const useRemoveUserNewApplicationRequest = () => {
	const { getToken, handleLogout } = useAuthentication();
	const [loading, setLoading] = React.useState<boolean>(false);

	const removeUserRequestedNewApplications = React.useCallback(
		async (appId: string) => {
			try {
				setLoading(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const { data } = await cancelNewApplicationRequest(authHeader, appId);
				setLoading(false);
				return data?.message;
			} catch (error: any) {
				if (error) {
					setLoading(false);
					if (error?.response?.status == 401) {
						handleLogout();
					}
					return "Cannot get requested new applictions";
				}
			}
		},
		[getToken, handleLogout]
	);

	return { removeUserRequestedNewApplications, loading };
};

export default useRemoveUserNewApplicationRequest;
