import * as React from "react";
import { IconProps } from "./types";

export const Incorrect: React.FC<IconProps> = ({ width, height, colour }) => {
	return (
		<svg
			width={`${width}` || "24"}
			height={`${height}` || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 12c0 4.4183-3.5817 8-8 8-1.8487 0-3.551-.6271-4.9056-1.6801L18.3199 7.0944C19.3729 8.449 20 10.1513 20 12zM5.6801 16.9056 16.9056 5.6801C15.551 4.6271 13.8487 4 12 4c-4.4183 0-8 3.5817-8 8 0 1.8487.627 3.551 1.6801 4.9056zM22 12c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10C2 6.4771 6.4771 2 12 2c5.5228 0 10 4.4771 10 10z"
				fill={colour || "#111"}
			/>
		</svg>
	);
};
