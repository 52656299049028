import * as React from "react";
import assert from "assert";

import useGetUiSchemas from "../../hooks/useGetUiSchemas";
import Layout from "./Layout";
import { Values, CurrentValues, CurrentUiSchema, FormValid, FormErrors, ShouldValid, UiSchemaError } from "./types";
import { applicationIdRegExp } from "../../utils/regularExpressions";
import { NewSchemaFormFieldNames } from "./constants";
import useAuthentication from "../../hooks/useAuthentication";
import { updateSchemaData } from "./utils";
import useStore, { activeApplicationSelector } from "../../store";

const Schemas: React.FC = () => {
	const activeApplication = useStore(activeApplicationSelector);
	const { data: uiSchemas, loading: uiSchemasLoading } = useGetUiSchemas({
		getUiSchemasData: true,
		getSchemaData: true,
		appId: activeApplication?.id || "",
	});
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [newUiSchameName, setNewUiSchemaName] = React.useState<string>("");
	const [newUiSchameValue, setNewUiSchemaValue] = React.useState<string>("");
	const [values, setValues] = React.useState<Values | undefined>(undefined);
	const [formValid, setFormValid] = React.useState<FormValid>({
		nameValid: false,
		uiSchemaValid: false,
	});
	const [formErrors, setFormError] = React.useState<FormErrors>({
		nameError: "",
		uiSchemaError: "",
	});
	const [shouldValid, setShouldValid] = React.useState<ShouldValid>({
		shouldValidName: false,
		shouldValidUiSchema: false,
	});
	const [currentValues, setCurrentValues] = React.useState<CurrentValues | undefined>(undefined);
	const [saveBtnLoading, setSaveBtnLoading] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState("");
	const [uiSchemaErr, setUiSchemaErr] = React.useState<UiSchemaError | undefined>(undefined);
	const [schemaErr, setSchemaErr] = React.useState<string>("");

	const isNewApplication = React.useMemo(
		() => currentValues?.schema === "" || !currentValues?.uiSchemas.some((data) => data.uiSchemaName === "ui-schema"),
		[currentValues?.schema, currentValues?.uiSchemas]
	);

	const { getToken } = useAuthentication();
	const [showReasonModal, setShowReasonModal] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>("");

	React.useEffect(() => {
		if (uiSchemas?.schema && !uiSchemasLoading) {
			setValues({
				schema: uiSchemas.schema,
				uiSchemas: uiSchemas["ui-schemas"].map(({ uiSchemaName, uiSchema }) => {
					return { uiSchemaName, uiSchema: uiSchema };
				}),
			});
		}
		setCurrentValues({
			schema: JSON.stringify(uiSchemas?.schema, null, 2) || "",
			uiSchemas:
				uiSchemas?.["ui-schemas"].map(({ uiSchemaName, uiSchema }) => ({
					uiSchemaName,
					uiSchema: JSON.stringify(uiSchema, null, 2),
				})) || [],
		});
	}, [uiSchemas, uiSchemasLoading]);

	const validate = React.useCallback(
		({ value, name }) => {
			if (name === NewSchemaFormFieldNames.UiSchemaName) {
				if (value.trim().toLowerCase() === "schema" || value.trim().toLowerCase() === "ui-schema") {
					setFormValid({ ...formValid, nameValid: false });
					setShouldValid({ ...shouldValid, shouldValidName: true });
					setFormError({
						...formErrors,
						nameError: "Default schema and ui-schema names cannot be used for custom ui schema.",
					});
				} else if (!new RegExp(applicationIdRegExp).test(value)) {
					setFormValid({ ...formValid, nameValid: false });
					setShouldValid({ ...shouldValid, shouldValidName: true });
					setFormError({
						...formErrors,
						nameError: "only alphabets and - allowed",
					});
				} else {
					if (currentValues?.uiSchemas.some(({ uiSchemaName }) => uiSchemaName === value)) {
						setFormValid({ ...formValid, nameValid: false });
						setShouldValid({ ...shouldValid, shouldValidName: true });
						setFormError({
							...formErrors,
							nameError: "Schema name already exist",
						});
					} else {
						setFormValid({ ...formValid, nameValid: true });
					}
				}
			}
			if (name === NewSchemaFormFieldNames.UiSchemaValue) {
				try {
					JSON.parse(value);
					setFormValid({ ...formValid, uiSchemaValid: true });
				} catch (err) {
					console.log(err);
					setFormValid({ ...formValid, uiSchemaValid: false });
					setShouldValid({ ...shouldValid, shouldValidUiSchema: true });
					setFormError({
						...formErrors,
						uiSchemaError: "Check UI-Schema",
					});
				}
			}
		},
		[currentValues?.uiSchemas, formErrors, formValid, shouldValid]
	);

	const onValueChange = React.useCallback(
		(value: string, name: string, isSchema: boolean) => {
			if (currentValues) {
				if (isSchema) {
					try {
						JSON.parse(value);
						setSchemaErr("");
					} catch (err: any) {
						setSchemaErr(err?.message);
					}
					setCurrentValues({ ...currentValues, schema: value });
				} else {
					const _value = {
						uiSchemaName: name,
						uiSchema: value,
					};
					try {
						JSON.parse(value);
						setUiSchemaErr(undefined);
					} catch (err: any) {
						setUiSchemaErr({
							index: currentValues.uiSchemas.findIndex(({ uiSchemaName }) => uiSchemaName === name),
							errMsg: err.message,
						});
					}
					const tmpArr = currentValues.uiSchemas;
					const val = tmpArr.map((item) => (item.uiSchemaName === name ? _value : item));
					const newVal = val;

					setCurrentValues(newVal ? { schema: currentValues.schema, uiSchemas: newVal } : currentValues);
				}
			}
		},
		[currentValues]
	);

	const hasChanged = React.useMemo(() => {
		if (!currentValues || uiSchemaErr || schemaErr) {
			return false;
		}

		if (currentValues?.schema === "" || !currentValues?.uiSchemas.length) {
			return false;
		}

		try {
			assert.deepStrictEqual(
				{
					schema: JSON.stringify(values?.schema, null, 2),
					uiSchemas: values?.uiSchemas.map(({ uiSchemaName, uiSchema }) => ({
						uiSchemaName,
						uiSchema: JSON.stringify(uiSchema, null, 2),
					})),
				},
				currentValues
			);
			return false;
		} catch (e) {
			return true;
		}
	}, [currentValues, schemaErr, uiSchemaErr, values?.schema, values?.uiSchemas]);

	const changedProperty = (oldValue: any, newValue: any) => {
		try {
			assert.deepStrictEqual(oldValue, newValue);
			return false;
		} catch (e) {
			return true;
		}
	};

	const onSave = React.useCallback(async () => {
		if (currentValues && !uiSchemaErr && !schemaErr) {
			try {
				setSaveBtnLoading(true);
				setShowReasonModal(false);

				let schemaData = {} as any;
				const isSchemaChanged = changedProperty(JSON.stringify(values?.schema, null, 2), currentValues?.schema);

				const UISchemasChanged = currentValues?.uiSchemas?.map((currentUiSchema) => {
					if (values && values?.uiSchemas) {
						const oldUISchema = values?.uiSchemas?.find(
							(oldSchema) => oldSchema.uiSchemaName === currentUiSchema.uiSchemaName
						);
						if (oldUISchema) {
							const oldUISchemaObject = JSON.parse(JSON.stringify(oldUISchema));
							oldUISchemaObject.uiSchema = JSON.stringify(oldUISchema?.uiSchema, null, 2);
							const isUISchemaChanged = changedProperty(oldUISchemaObject, currentUiSchema);
							if (isUISchemaChanged) {
								return currentUiSchema.uiSchemaName;
							}
						} else {
							return currentUiSchema.uiSchemaName;
						}
					} else {
						return currentUiSchema.uiSchemaName;
					}
				});
				const uiSchemas = currentValues.uiSchemas
					.filter((uiSchema) => UISchemasChanged.includes(uiSchema.uiSchemaName))
					.reduce(
						(obj, { uiSchemaName, uiSchema }) =>
							Object.assign(obj, {
								[uiSchemaName]: JSON.parse(uiSchema),
							}),
						{}
					);

				if (uiSchemas) {
					if (isSchemaChanged) {
						schemaData = { schema: JSON.parse(currentValues?.schema), ...uiSchemas };
					} else {
						schemaData = { ...uiSchemas };
					}
				}

				const newData = await updateSchemaData({
					getToken,
					activeApplication,
					setToastMessage,
					schemaData,
					reason,
					setSaveBtnLoading,
				});
				if (newData && newData.schema) {
					setValues({
						schema: newData.schema,
						uiSchemas: newData.uiSchemas.map(({ uiSchemaName, uiSchema }) => {
							return { uiSchemaName, uiSchema: uiSchema };
						}),
					});
					setCurrentValues({
						schema: JSON.stringify(newData.schema, null, 2),
						uiSchemas: newData.uiSchemas.map(({ uiSchemaName, uiSchema }) => ({
							uiSchemaName,
							uiSchema: JSON.stringify(uiSchema, null, 2),
						})),
					});
					setSaveBtnLoading(false);
					setToastMessage("Succesfully updated the values");
				}
			} catch (err) {
				setSaveBtnLoading(false);
				console.error(err);
			}
		}
	}, [currentValues, uiSchemaErr, schemaErr, values, getToken, activeApplication, reason]);

	const onAdd = React.useCallback(
		(e) => {
			e.preventDefault();
			if (isNewApplication) {
				setFormValid({ ...formValid, nameValid: true });
				setNewUiSchemaName("ui-schema");
				currentValues &&
					setCurrentValues({
						schema: currentValues.schema,
						uiSchemas: [...currentValues.uiSchemas, { uiSchemaName: newUiSchameName, uiSchema: newUiSchameValue }],
					});
				setShowModal(false);
			} else if (formValid.nameValid && formValid.uiSchemaValid) {
				currentValues &&
					setCurrentValues({
						schema: currentValues.schema,
						uiSchemas: [...currentValues.uiSchemas, { uiSchemaName: newUiSchameName, uiSchema: newUiSchameValue }],
					});
				setShowModal(false);
			}
		},
		[currentValues, formValid, isNewApplication, newUiSchameName, newUiSchameValue]
	);

	const onModalClose = React.useCallback(() => {
		setShouldValid({ shouldValidName: false, shouldValidUiSchema: false });
		setFormValid({ nameValid: false, uiSchemaValid: false });
		setShowModal(false);
	}, []);

	return (
		<Layout
			loading={uiSchemasLoading}
			onValueChange={onValueChange}
			hasChanged={hasChanged}
			onSave={onSave}
			onAdd={onAdd}
			showModal={showModal}
			setShowModal={setShowModal}
			setNewUiSchemaValue={setNewUiSchemaValue}
			setNewUiSchemaName={setNewUiSchemaName}
			formValid={formValid}
			formErrors={formErrors}
			shouldValid={shouldValid}
			validate={validate}
			onModalClose={onModalClose}
			isNewApplication={isNewApplication}
			activeApplication={activeApplication}
			currentValues={currentValues}
			saveBtnLoading={saveBtnLoading}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			uiSchemaErr={uiSchemaErr}
			schemaErr={schemaErr}
			showReasonModal={showReasonModal}
			setShowReasonModal={setShowReasonModal}
			setReason={setReason}
		/>
	);
};

export default Schemas;
