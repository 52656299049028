import * as React from "react";
import styled from "styled-components";

import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";

import { ManageUserModalButtonWrapper } from "../../styles";
import ListView, { ListViewItem } from "@ingka/list-view";
import { ApplicationUserData } from "../../../services/users";

import { Application } from "../../../store";
import { StyledCommercialMessage, StyledModalHeader } from "../../styles";

import Avatar from "@ingka/avatar";
import InlineMessage from "@ingka/inline-message";
import { sortMarketUsers } from "../utils";

interface ModalLayoutProps {
	activeMarket: string | null;
	getCountryName: (country: string) => string;
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	marketUsersList: ApplicationUserData | undefined;
	activeApplication: Application | undefined;
}

const StyledModalBody = styled(ModalBody)`
	padding: 1rem 3rem;
	.switch {
		padding-top: 0.5rem;
	}
	.inline-message {
		margin: 1rem 0;
	}
`;

const StyledMessage = styled(StyledCommercialMessage)`
	margin-left: 0.5rem;
`;

const StyledTitleMessage = styled(StyledCommercialMessage)`
	margin-top: 1rem;
`;

const StyledListView = styled(ListView)`
	padding: 0rem;

	.list-view-item {
		border-bottom: 0rem !important;
	}
`;

const ListItemWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	border-bottom: 1px rgb(var(--colour-neutral-3, 223, 223, 223)) solid;
`;

const StyledAvatar = styled(Avatar)`
	width: 3rem;
`;

const Layout: React.FC<ModalLayoutProps> = ({
	activeMarket,
	getCountryName,
	showModal,
	setShowModal,
	marketUsersList,
	activeApplication,
}) => {
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
					<Sheets
						footer={<ManageUserModalButtonWrapper></ManageUserModalButtonWrapper>}
						header={<StyledModalHeader title={`Users assigned to ${activeMarket && getCountryName(activeMarket)}`} />}
						size="small"
					>
						<StyledModalBody>
							{marketUsersList?.onboardedUsers &&
								marketUsersList.onboardedUsers.filter((user) => user["isApplicationAdmin"]).length > 0 && (
									<StyledTitleMessage message="Admins" variant="family" subtle />
								)}
							<StyledListView id="admins" title="admins">
								{marketUsersList?.onboardedUsers &&
									marketUsersList.onboardedUsers
										.filter((user) => user["isApplicationAdmin"])
										.sort(sortMarketUsers)
										.map((user) => (
											<ListItemWrapper>
												<StyledAvatar
													screenReaderText={user.name}
													text={user.name.split("(")[0].replace(/[^A-Z]/g, "")}
												/>
												<ListViewItem
													id={user.id}
													key={user.id}
													inset={false}
													title={user.name}
													description={user.email}
													emphasised
													image={undefined}
													controlIcon="chevronRightSmall"
												/>
											</ListItemWrapper>
										))}
								{!marketUsersList?.onboardedUsers ||
									(marketUsersList.onboardedUsers.length === 0 && (
										<InlineMessage title="No users were assigned to this market" variant="informative" />
									))}
							</StyledListView>
							{marketUsersList?.onboardedUsers &&
								marketUsersList.onboardedUsers.filter((user) => !user["isApplicationAdmin"]).length > 0 && (
									<StyledTitleMessage message="Maintainers" variant="nlp" subtle />
								)}
							<StyledListView id="onboarded" title="marinatiners">
								{marketUsersList?.onboardedUsers &&
									marketUsersList.onboardedUsers
										.filter((user) => !user["isApplicationAdmin"])
										.sort(sortMarketUsers)
										.map((user) => (
											<ListItemWrapper>
												<StyledAvatar
													screenReaderText={user.name}
													text={user.name.split("(")[0].replace(/[^A-Z]/g, "")}
												/>
												<ListViewItem
													id={user.id}
													key={user.id}
													inset={false}
													title={user.name}
													description={user.email}
													emphasised
													image={undefined}
													controlIcon="chevronRightSmall"
												/>
											</ListItemWrapper>
										))}
								{!marketUsersList?.onboardedUsers ||
									(marketUsersList.onboardedUsers.length === 0 && (
										<InlineMessage title="No users were assigned to this market" variant="informative" />
									))}
							</StyledListView>
						</StyledModalBody>
					</Sheets>
				</Modal>
			</form>
		</>
	);
};

export default Layout;
