import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { CustomNavigationClient } from "./utils/CustomNavigationClient";
import FlaggaLayout from "./components/FlaggaLayout";
import LogoutPage from "./routes/logoutPage";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Login from "./routes/login";
import Home from "./routes/home";
import Onboarding from "./routes/onboarding";
import Users from "./routes/users";

import DashboardPage from "./routes/dashboardPage";
import ManageApplicationRequestsPage from "./routes/ApplicationRequestPage";
import Consent from "./routes/consent";
import {
	ROUTE_DASHBOARD,
	ROUTE_HOME,
	ROUTE_LOGIN,
	ROUTE_LOGOUT,
	ROUTE_REQUESTS,
	ROUTE_EMAIL_CONSENT,
	ROUTE_MAINTENANCE,
	isInMaintenanceMode,
	ROUTE_ONBOARDING,
	ROUTE_NEW_USER,
	ROUTE_USERS
} from "./utils/constants";

import InactivityModal from "./components/InactivityModal/InactivityModal";
import FloatingHelpButton from "./components/FloatingHelpButton/FloatingHelpButton";
import useStore, { userSelector } from "./store";
import Maintenance from "./routes/maintenance";
import NewUser from "./routes/newUser";

const App: React.FC<{ msalInstance: PublicClientApplication }> = ({ msalInstance }) => {
	const navigate = useNavigate();
	const navigationClient = new CustomNavigationClient(navigate);
	msalInstance.setNavigationClient(navigationClient);
	const currentUser = useStore(userSelector);

	React.useEffect(() => {
		isInMaintenanceMode && navigate(ROUTE_MAINTENANCE);
	}, [navigate]);

	return (
		<MsalProvider instance={msalInstance}>
			<FlaggaLayout>
				<Routes>
					<Route path="*" element={<ErrorPage />} />
					<Route path={ROUTE_HOME} element={<Home />} />
					<Route path={ROUTE_LOGIN} element={<Login />} />
					<Route path={ROUTE_REQUESTS} element={<ManageApplicationRequestsPage />} />
					<Route path={ROUTE_DASHBOARD} element={<DashboardPage />} />
					<Route path={ROUTE_LOGOUT} element={<LogoutPage />} />
					<Route path={ROUTE_EMAIL_CONSENT} element={<Consent />} />
					<Route path={ROUTE_MAINTENANCE} element={<Maintenance />} />
					<Route path={ROUTE_NEW_USER} element={<NewUser />} />
					<Route path={ROUTE_ONBOARDING} element={<Onboarding />} />
					<Route path={ROUTE_USERS} element={<Users />} />
				</Routes>
				{currentUser && <InactivityModal />}
				{currentUser && <FloatingHelpButton />}
			</FlaggaLayout>
		</MsalProvider>
	);
};

export default App;
