import * as React from "react";
import { Validation } from "./types";

const useForm = (marketData?: string[]) => {
	const [value, setValue] = React.useState("");
	const [validation, setValidation] = React.useState<Validation>({ id: "country-code-error", msg: "", type: "error" });
	const [shouldValidate, setShouldValidate] = React.useState<boolean>(false);
	const [valid, setValid] = React.useState<boolean>(true);

	const validate = React.useCallback(
		(value: string) => {
			if (!new RegExp(/^[a-zA-Z0-9]+$/).test(value)) {
				if (!value.length) {
					setValid(false);
					setShouldValidate(true);
					setValidation({
						...validation,
						msg: "Please enter Country Code",
						type: "error",
					});
				} else {
					setValid(false);
					setShouldValidate(true);
					setValidation({
						...validation,
						msg: "Country Code can only contains alphabets and numbers",
						type: "error",
					});
				}
			} else if (value.length < 2) {
				setValid(false);
				setShouldValidate(true);
				setValidation({ ...validation, msg: "Country Code cannot have less than 2 letters", type: "error" });
			} else if (value.length > 2) {
				setValid(false);
				setShouldValidate(true);
				setValidation({ ...validation, msg: "Country Code cannot have more than 2 letters", type: "error" });
			} else if (marketData?.some((market) => market.toLocaleLowerCase() === value.toLocaleLowerCase())) {
				setValid(false);
				setShouldValidate(true);
				setValidation({ ...validation, msg: "Country Code already exist", type: "error" });
			} else {
				setValid(true);
				setValidation({
					...validation,
					msg: "Success",
					type: "success",
				});
			}
		},
		[marketData, validation]
	);

	const handleChange = React.useCallback(
		(value: string) => {
			validate(value);
			setValue(value.toUpperCase());
		},
		[validate]
	);

	return { value, valid, validation, handleChange, shouldValidate, setValue, setValid, setShouldValidate };
};

export default useForm;
