import * as React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps } from "@jsonforms/core";
import Tabs from "@ingka/tabs";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

import OrderPanel, { OrderTab } from "./OrderPanel";
import TemplatesPanel, { TemplatesTab } from "./TemplatesPanel";
import { AddressOrder, Order, Templates } from "../../../types/configuration";

const DeliveryDetailsControl: React.FC<ControlProps> = ({ data, handleChange, path, enabled }) => {
	const onOrderChange = React.useCallback(
		(value: Order[]) => {
			const nextValue = {
				...data,
				order: value,
			};

			handleChange(path, nextValue);
		},
		[data, path, handleChange]
	);

	const onTemplatesChange = React.useCallback(
		(value: Templates) => {
			const nextValue = {
				...data,
				templates: value,
			};

			handleChange(path, nextValue);
		},
		[data, path, handleChange]
	);

	if (!data) {
		return <p>No Data</p>;
	}

	return (
		<DndProvider backend={Backend}>
			<Tabs
				tabs={[<OrderTab />, <TemplatesTab />]}
				tabPanels={[
					<OrderPanel
						key="order-panel"
						order={(data as AddressOrder).order}
						onOrderChange={onOrderChange}
						enabled={enabled}
					/>,
					<TemplatesPanel
						key="template-panel"
						templates={(data as AddressOrder).templates}
						onTemplatesChange={onTemplatesChange}
						enabled={enabled}
					/>,
				]}
				defaultActiveTab="orderPanel"
			/>
		</DndProvider>
	);
};

export default withJsonFormsControlProps(DeliveryDetailsControl);
