import * as React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "@ingka/toast";
import styled from "styled-components";
import { useTable } from "react-table";
import TableCell from "../FeaturesOverviewTable/TableCell";
import { TableWrapper } from "../styles";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";
import Loading, { LoadingBall } from "@ingka/loading";
import { LoadingWrapper, FullHeightContainer } from "../styles";
import AddApplicationModal from "../Applications/AddApplicationModal/AddApplicationModal";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import { Value } from "../Applications/AddApplicationModal/types";
import DeclineApplicationModal from "./DeclineApplicationModal/DeclineApplicationModal";
import checkmark from "@ingka/ssr-icon/paths/checkmark";
import trashCan from "@ingka/ssr-icon/paths/trash-can";

interface ManageApplicationRequestsLayoutProps {
	loading: boolean;
	data: any[]; //TODO type
	toastMessage: string;
	setToastMessage: (value: string) => void;
	setShowAddModal: (value: boolean) => void;
	showAddModal: boolean;
	setShowDeclineModal: (value: boolean) => void;
	showDeclineModal: boolean;
	handleCreate: (data: any) => void;
	handlDecline: (data: any) => void;
	value: Value;
	declineAppId: string;
}

const ActionButtonWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem;
`;

const Layout: React.FC<ManageApplicationRequestsLayoutProps> = ({
	loading,
	data,
	toastMessage,
	setShowAddModal,
	showAddModal,
	setShowDeclineModal,
	showDeclineModal,
	handleCreate,
	value,
	handlDecline,
	setToastMessage,
	declineAppId,
}) => {
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const navigate = useNavigate();
	if (!isFlaggaAdmin) {
		navigate("/");
	}

	const columns = React.useMemo(
		() => [
			{ id: "id", Header: "ID", accessor: "appId", filter: "fuzzyText" },
			{ Header: "Name", accessor: "appName", filter: "fuzzyText" },
			{ Header: "Admin Email", accessor: "email", filter: "fuzzyText" },
			{ Header: "Has Read Documentation", accessor: "hasReadDocumentation" },
			{ Header: "Show Dashboard", accessor: "showDashboard" },
			{ Header: "Show Market Dashboard", accessor: "showMarketDashboard" },
			{ Header: "Allow ReadOnly", accessor: "allowReadOnly" },
			{ Header: "ClientId", accessor: "clientId" },
			{ Header: "Plan", accessor: "plan" },
			{ Header: "Status", accessor: "status", filter: "fuzzyText" },
			{
				Header: "Action/Reason",
				accessor: "action",
			},
		],
		[]
	);

	const { headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
	});

	if (loading) {
		return (
			<FullHeightContainer>
				<LoadingWrapper>
					<Loading labelPosition="center" text="Loading Requests" labelTransitions>
						<LoadingBall color="emphasised" />
					</Loading>
				</LoadingWrapper>
			</FullHeightContainer>
		);
	}

	if (!loading && !data.length) {
		return (
			<FullHeightContainer>
				<LoadingWrapper>
					<InlineMessage title="No Data" body={"Seems like you don't have any data"} variant="negative" />
				</LoadingWrapper>
			</FullHeightContainer>
		);
	}

	return (
		<FullHeightContainer>
			<h2>Application Requests</h2>
			<TableWrapper>
				<table className="table">
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th className="table-head" {...column.getHeaderProps()}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{rows.map((row: any) => {
							prepareRow(row);
							return (
								<tr className="table-row" {...row.getRowProps()}>
									{row.cells.map((cell: any) => (
										<td cellId={cell.column.id} className="table-cell" {...cell.getCellProps()}>
											{cell.column.id != "action" && (
												<TableCell value={cell.value != null ? cell.value.toString() : ""}></TableCell>
											)}
											{cell.column.id == "action" && row.original.status == "declined" && (
												<TableCell
													value={row.original.reason != null ? row.original.reason.toString() : ""}
												></TableCell>
											)}
											{cell.column.id === "action" && row.original.status == "pending" && (
												<ActionButtonWrapper>
													{" "}
													<Button
														text="Accept"
														iconPosition="leading"
														type="emphasised"
														small
														ssrIcon={checkmark}
														onClick={() => {
															handleCreate(row.original);
														}}
													/>
													<Button
														text="Decline"
														small
														iconPosition="leading"
														type="tertiary"
														ssrIcon={trashCan}
														onClick={() => {
															handlDecline(row.original.appId);
														}}
													/>
												</ActionButtonWrapper>
											)}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</TableWrapper>
			<AddApplicationModal showModal={showAddModal} setShowModal={setShowAddModal} valueFromRequest={value} />
			<DeclineApplicationModal
				showModal={showDeclineModal}
				setShowModal={setShowDeclineModal}
				declineAppId={declineAppId}
			/>

			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</FullHeightContainer>
	);
};

export default Layout;
