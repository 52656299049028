import React from "react";
import { RoleData } from "../RequestNewRoleModal";
import { UserPermissions } from "../../../utils/constants";
import Layout from "./Layout";
import { UserApplication } from "../../../store";
import { UserMarketPermissions } from "../../../services/users";

interface ReadOnlyRoleSelctorProps {
	rolesData: RoleData[];
	setSelectedRole: (role: UserPermissions | null) => void;
	selectedRole: UserPermissions | null;
	requestingRole: boolean;
	readOnlyToMaintainerMarkets: string[];
	setReadOnlyToMaintainerMarkets: (data: string[]) => void;
	app: UserApplication & {
		id: string;
	};
}

const ReadOnlyRoleSelector: React.FC<ReadOnlyRoleSelctorProps> = ({
	rolesData,
	setSelectedRole,
	requestingRole,
	selectedRole,
	app,
	readOnlyToMaintainerMarkets,
	setReadOnlyToMaintainerMarkets,
}) => {
	return (
		<Layout
			rolesData={rolesData}
			setSelectedRole={setSelectedRole}
			requestingRole={requestingRole}
			selectedRole={selectedRole}
			app={app}
			readOnlyToMaintainerMarkets={readOnlyToMaintainerMarkets}
			setReadOnlyToMaintainerMarkets={setReadOnlyToMaintainerMarkets}
		/>
	);
};

export default ReadOnlyRoleSelector;
