import { GroupLayout, LayoutProps, rankWith, uiTypeIs } from "@jsonforms/core";
import { withJsonFormsLayoutProps } from "@jsonforms/react";
import { Box, Card, CardContent, CardHeader, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { EditorLayout } from "../model/uischema";
import { DroppableLayout } from "./DroppableLayout";

const useStyles = makeStyles((theme) => ({
	groupLabel: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "baseline",
		gap: theme.spacing(1),
	},
	labelPlaceholder: {
		fontStyle: "italic",
		fontWeight: "lighter",
		color: "#9e9e9e",
	},
	groupLabelInput: {
		fontSize: theme.typography.h6.fontSize,
	},
	textEllipsis: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
}));

const Group: React.FC<LayoutProps> = (props) => {
	const { uischema } = props;
	const groupLayout = uischema as GroupLayout & EditorLayout;
	const classes = useStyles();
	return (
		<Card>
			<CardHeader
				component={() => (
					<Box className={classes.groupLabel}>
						<Typography>Label:</Typography>
						<Typography
							className={`${groupLayout.label ? "" : classes.labelPlaceholder} ${classes.textEllipsis}`}
							variant="h6"
						>
							{groupLayout.label ?? "no label"}
						</Typography>
					</Box>
				)}
			></CardHeader>
			<CardContent>
				<DroppableLayout {...props} layout={groupLayout} direction={"column"} />
			</CardContent>
		</Card>
	);
};

export const DroppableGroupLayoutRegistration = {
	tester: rankWith(45, uiTypeIs("Group")),
	renderer: withJsonFormsLayoutProps(Group),
};
