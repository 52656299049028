import React from "react";
import { useExportUiSchema } from "../../../core/util/hooks";
import { jsonToText } from "../../../core/util";

export const PreviewJSON: React.FC = () => {
	const uischema = useExportUiSchema();

	return (
		<pre data-testid="uischema-json-preview">{jsonToText(uischema)}</pre>
	);
};
