import * as React from "react";

import useAuthentication from "./useAuthentication";
import { cancelMarketsRequest } from "../services/users";

const useCancelMarketsRequest = () => {
	const { getToken, handleLogout } = useAuthentication();
	const [loading, setLoading] = React.useState(false);

	const cancelMarkets = React.useCallback(
		async (appId?: string) => {
			try {
				if (appId) {
					setLoading(true);
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					const { data } = await cancelMarketsRequest(authHeader, appId);
					return data?.message;
				}
			} catch (error: any) {
				if (error) {
					if (error?.response?.status == 401) {
						handleLogout();
					}
					return error?.response?.data?.message as string;
				}
			} finally {
				setLoading(false);
			}
		},
		[getToken, handleLogout]
	);

	return { cancelMarkets, loading };
};

export default useCancelMarketsRequest;
