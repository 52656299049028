import * as React from "react";
import styled from "styled-components";
import { useTable, useSortBy, useFlexLayout, Column, TableOptions, useColumnOrder } from "react-table";
import { useSticky } from "react-table-sticky";

import { colourTextAndIcon2 } from "@ingka/variables/colours-css";
import InlineMessage from "@ingka/inline-message";
import Skeleton from "@ingka/skeleton";
import Search from "@ingka/search";

import useGetMarkets from "../../hooks/useGetMarkets";
import useStore, { ApplicationData, activeApplicationSelector, setActiveApplicationSelector } from "../../store";
import Layout from "./Layout";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import { DashboardData, DashboardKeys, getDashboardData } from "../../services/dashboard";
import {
	getGroups,
	getHiddenColumns,
	orderColumnsByPinning,
	exportGlobalDashboardTable,
	createExportableData,
} from "./utils";
import TableCell from "./TableCell";
import useActiveApp from "../../hooks/useActiveApp";
import { FullHeightContainer } from "../styles";
import useAuthentication from "../../hooks/useAuthentication";
import { defaultColumnIndex, othersColumnIndex } from "../../utils/constants";

interface FeatersOverviewTableProps {
	applicationsData?: ApplicationData[];
	applicationsLoading: boolean;
}

const MarketsWrapper = styled.div`
	font-weight: 700;
	font-size: 0.75rem;
	color: ${colourTextAndIcon2};
`;

const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const StyledSearch = styled(Search)`
	border-radius: 0;
`;

const columnsLoading = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const rowsLoading = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const FeaturesOverviewTable: React.FC<FeatersOverviewTableProps> = ({ applicationsData, applicationsLoading }) => {
	const activeApplication = useStore(activeApplicationSelector);
	const setActiveApplication = useStore(setActiveApplicationSelector);
	const { activeApp: activeAppParam, setActiveApp: setActiveAppParam } = useActiveApp();

	const { getCountryName } = useGetCountryName();
	const [columnFilterModal, setColumnFilterModal] = React.useState<boolean>(false);
	const [hasGroups, setHasGroups] = React.useState<boolean>(false);

	const [dashboardTableData, setDashboardTableData] = React.useState<DashboardData | undefined>(undefined);
	const [dashboardDataLoading, setDashBoardDataLoading] = React.useState<boolean>(true);
	const [toastMessage, setToastMessage] = React.useState<string>("");

	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		if (activeApplication) {
			(async () => {
				try {
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					setDashBoardDataLoading(true);
					const { data: dashboardData } = await getDashboardData(authHeader, activeApplication.id);
					setDashboardTableData(dashboardData?.data);
				} catch (error: any) {
					if (error) {
						setDashboardTableData(undefined);
						setDashBoardDataLoading(false);
						if (error?.response?.status == 401) {
							handleLogout();
						}
					}
				} finally {
					setDashBoardDataLoading(false);
				}
			})();

			return () => {
				setDashboardTableData(undefined);
				setDashBoardDataLoading(false);
			};
		}
	}, [activeApplication, getToken, handleLogout]);

	const { data: marketsData, loading: marketsLoading, getMarketsData } = useGetMarkets();

	const loading = React.useMemo(() => dashboardDataLoading || marketsLoading, [dashboardDataLoading, marketsLoading]);

	const [tableData, setTableData] = React.useState<object[] | undefined>([]); //object[] | undefined

	React.useEffect(() => {
		if (activeApplication?.id) {
			getMarketsData(activeApplication.id);
		}
	}, [activeApplication?.id, getMarketsData]);

	const getChildColumns = React.useCallback(
		(dashboardTableData: DashboardData, groupId: string) =>
			dashboardTableData.keys
				.reduce((accumulator: any[], currentValue: DashboardKeys) => {
					if (currentValue?.groupId?.toLocaleLowerCase() === groupId.toLocaleLowerCase()) {
						accumulator.push({
							Header: currentValue["flagga-label"],
							description: currentValue.description,
							columnIndex: typeof currentValue.columnIndex == "number" ? currentValue.columnIndex : defaultColumnIndex,
							accessor: currentValue.key,
							width: 80,
							Cell: (params: any) => <TableCell value={params.value} />,
							isVisible: currentValue?.isVisible === false ? false : true,
						});
					} else if (!currentValue?.groupId && groupId === "Other") {
						accumulator.push({
							Header: currentValue["flagga-label"],
							description: currentValue.description,
							columnIndex: typeof currentValue.columnIndex == "number" ? currentValue.columnIndex : defaultColumnIndex,
							accessor: currentValue.key,
							width: 80,
							Cell: (params: any) => <TableCell value={params.value} />,
							isVisible: currentValue?.isVisible === false ? false : true,
						});
					}
					return accumulator;
				}, [])
				.sort((a, b) => {
					if (a.columnIndex > b.columnIndex) {
						return 1;
					} else if (a.columnIndex < b.columnIndex) {
						return -1;
					} else if (a["Header"].replace(/\s/g, "").toLowerCase() < b["Header"].replace(/\s/g, "").toLowerCase()) {
						return -1;
					} else if (a["Header"].replace(/\s/g, "").toLowerCase() > b["Header"].replace(/\s/g, "").toLowerCase()) {
						return 1;
					}
					return 0;
				}),
		[]
	);

	const handleFilterMarket = React.useCallback(
		(value: string) => {
			if (dashboardTableData && marketsData) {
				const dataToFilter = [...(marketsData?.permittedMarkets || []), ...(marketsData.otherMarkets || [])]
					?.map((market) => {
						let data = {};
						dashboardTableData.keys.forEach((column: any) => {
							data = {
								...data,
								[column.key]: `${
									dashboardTableData.marketsData[market]?.find((data: any) => data.key === column.key)?.value
								}`,
							};
						});
						return {
							markets: getCountryName(market),
							...data,
						};
					})
					.sort((a, b) => {
						const nameA = a.markets.toUpperCase();
						const nameB = b.markets.toUpperCase();
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}

						return 0;
					});

				if (value == "" || value == undefined) {
					setTableData(dataToFilter);
				} else {
					setTableData(dataToFilter.filter((item) => item.markets.toLowerCase().startsWith(value.toLowerCase())));
				}
			} else {
				setTableData([]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dashboardTableData, marketsData]
	);

	const getGroupingData = React.useCallback(
		(groupIds: string[], dashboardTableData: DashboardData) =>
			groupIds.map((groupId) => {
				return {
					Header: groupId,
					columns: getChildColumns(dashboardTableData, groupId),
				};
			}),
		[getChildColumns]
	);

	const MarketColumn = React.useMemo(
		() => ({
			accessor: "markets",
			Header: (
				<StyledSearch
					placeholder="Search by country name"
					id="search"
					onSearch={(e) => {
						handleFilterMarket(e.target.value);
					}}
					onChange={(e) => {
						handleFilterMarket(e.target.value);
					}}
					onClear={(e) => {
						handleFilterMarket(e.target.value);
					}}
				/>
			),
			width: 280,
			isVisible: true,
			Cell: (props: any) => <MarketsWrapper>{props.value}</MarketsWrapper>,
		}),
		[handleFilterMarket]
	);

	const columnData = React.useCallback(
		(dashboardTableData: DashboardData) =>
			dashboardTableData.keys.map((data) => ({
				Header: data["flagga-label"],
				description: data.description,
				accessor: data.key,
				groupIdIndex: data.groupIdIndex,
				columnIndex: data.columnIndex,
				width: 120,
				Cell: (params: any) => <TableCell value={params.value} />,
				isVisible: data?.isVisible === false ? false : true,
			})),
		[]
	);

	const getColumnData = React.useCallback(
		(dashboardTableData: DashboardData) => [
			{ ...MarketColumn, sticky: "left", groupIdIndex: -1 },
			...columnData(dashboardTableData),
		],
		[MarketColumn, columnData]
	);

	const columnGroupingData = React.useMemo(() => {
		if (dashboardTableData) {
			let groupIds: string[], groupIdIndex: { [key: string]: number };
			let groupInfo = getGroups(dashboardTableData);
			groupIds = groupInfo.groupIds;
			groupIdIndex = groupInfo.groupIdIndex;

			if (groupIds.length) {
				setHasGroups(true);
				if (!groupIds.includes("Other")) {
					groupIds.push("Other");
				}
				groupIdIndex["Other"] = othersColumnIndex;

				groupIds = groupIds.sort((a, b) => {
					if (groupIdIndex[a] > groupIdIndex[b]) {
						return 1;
					} else if (groupIdIndex[a] < groupIdIndex[b]) {
						return -1;
					} else if (a.toLowerCase() < b.toLowerCase()) {
						return -1;
					} else if (a.toLowerCase() > b.toLowerCase()) {
						return 1;
					}
					return 0;
				});

				return [
					{
						Header: " ",
						groupIdIndex: -1,
						columns: [MarketColumn],
						sticky: "left",
					},
					...getGroupingData(groupIds, dashboardTableData),
				];
			} else return getColumnData(dashboardTableData);
		}
		return [];
	}, [MarketColumn, dashboardTableData, getColumnData, getGroupingData]);

	const exportTable = React.useCallback(() => {
		if (dashboardTableData && tableData) {
			const exportRows = createExportableData(dashboardTableData, tableData);
			exportGlobalDashboardTable(
				exportRows,
				`FLAGGA_Global_Dashboard_${new Date().toLocaleDateString()}.xlsx`,
				columnGroupingData
			);
		} else {
			setToastMessage("No data to export");
		}
	}, [dashboardTableData, tableData, columnGroupingData]);

	React.useEffect(() => {
		if (loading) {
			let loadingRow = {};
			setTableData(
				rowsLoading.map(() => {
					columnsLoading.forEach((num) => {
						loadingRow = { ...loadingRow, [`col${num}`]: "" };
					});
					return loadingRow;
				})
			);
		} else if (dashboardTableData && marketsData && !loading) {
			setTableData(
				[...(marketsData?.permittedMarkets || []), ...(marketsData.otherMarkets || [])]
					?.map((market) => {
						let data = {};
						dashboardTableData.keys.forEach((column: any) => {
							data = {
								...data,
								[column.key]: `${
									dashboardTableData.marketsData[market]?.find((data: any) => data.key === column.key)?.value
								}`,
							};
						});
						return {
							markets: getCountryName(market),
							...data,
						};
					})
					.sort((a, b) => {
						const nameA = a.markets.toUpperCase();
						const nameB = b.markets.toUpperCase();
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}

						return 0;
					})
			);
		} else {
			setTableData([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardTableData, loading, marketsData]);

	const columns = React.useMemo<Column<object>[]>(() => {
		if (loading) {
			return columnsLoading.map((num) => ({
				accessor: `col${num}`,
				Header: <Skeleton />,
				sticky: num === 1 ? "left" : false,
				width: 120,
				Cell: () =>
					num === 1 ? <Skeleton width="20rem" height="1rem" /> : <Skeleton width="1.5rem" height="1.5rem" />,
			}));
		}
		if (dashboardTableData) {
			return [...columnGroupingData];
		}
		return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnGroupingData, dashboardTableData, loading]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		allColumns,
		visibleColumns,
		setColumnOrder,
	} = useTable(
		{
			columns,
			data: tableData,
			autoResetSortBy: false,
			sortType: "basic",
			initialState: {
				hiddenColumns: getHiddenColumns(columns),
			},
		} as TableOptions<object>,
		useSortBy,
		useFlexLayout,
		useColumnOrder,
		useSticky
	);

	React.useEffect(() => {
		if (dashboardTableData && !loading) {
			const cols = orderColumnsByPinning(visibleColumns);
			setColumnOrder(cols);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardTableData, loading]);

	React.useEffect(() => {
		if (!activeApplication && activeAppParam && applicationsData?.some((app) => app.id === activeAppParam)) {
			setActiveApplication(applicationsData?.find((app) => app.id === activeAppParam) || null);
		}
	}, [activeAppParam, activeApplication, applicationsData, setActiveApplication]);

	const allColumnsGroup = React.useMemo(() => {
		if (hasGroups) {
			return allColumns.reduce((obj, column) => {
				if (column.parent?.id) {
					if (Object.keys(obj).includes(column.parent?.id)) {
						obj[column.parent?.id].push(column);
					} else {
						obj[column.parent?.id] = [];
						obj[column.parent?.id].push(column);
					}
				} else {
					obj[column.id] = column;
				}
				return obj;
			}, {} as any);
		} else {
			return allColumns;
		}
	}, [allColumns, hasGroups]);

	if (!dashboardTableData && !loading) {
		<FullHeightContainer>
			<CenterContainer>
				<InlineMessage title="No Data" body={"Something went wrong"} variant="cautionary" />
			</CenterContainer>
		</FullHeightContainer>;
	}

	if (!activeApplication && !loading) {
		return (
			<FullHeightContainer>
				<CenterContainer>
					<InlineMessage
						title="No Selected Applications"
						body={
							"Seems like you don't have any active application or you don't have access to the current active application."
						}
						variant="cautionary"
					/>
				</CenterContainer>
			</FullHeightContainer>
		);
	}

	return (
		<Layout
			columnFilterModal={columnFilterModal}
			loading={loading}
			setColumnFilterModal={setColumnFilterModal}
			getTableProps={getTableProps}
			headerGroups={headerGroups}
			getTableBodyProps={getTableBodyProps}
			rows={rows}
			prepareRow={prepareRow}
			allColumns={allColumns}
			activeApplication={activeApplication}
			allColumnsGroup={allColumnsGroup}
			hasGroups={hasGroups}
			exportGlobalDashboardTable={exportTable}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
		/>
	);
};

export default FeaturesOverviewTable;
