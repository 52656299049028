import * as React from "react";
import RequireAuth from "../components/RequireAuth";
import NewUser from "../components/NewUser";

const onboarding: React.FC = () => {
	return (
		<RequireAuth>
			<NewUser />
		</RequireAuth>
	);
};

export default onboarding;
