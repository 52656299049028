import {
	ArraySchemaFieldOption,
	ArrayValidSchemaField,
	BoolSchemaFieldOption,
	BoolValidSchemaField,
	CommonSchemaFieldOption,
	CommonValidSchemaField,
	IntegerSchemaFieldOption,
	IntegerValidSchemaField,
	NumberSchemaFieldOption,
	NumberValidSchemaField,
	ObjectSchemaFieldOption,
	ObjectValidSchemaField,
	Schema,
	SchemaMenuOption,
	SchemaType,
	SchemaTypeOption,
	StringSchemaFieldOption,
	StringValidSchemaField,
	oneOfSchemaFieldOption,
	oneOfValidSchemaField,
	SchemaTypeArrayOption,
} from "./types";

export const defaultSchema: Schema = {
	type: "object",
	properties: {},
};

export const schemaTypes: SchemaTypeOption[] = [
	{
		value: "string",
		label: "Text",
	},
	{
		value: "integer",
		label: "Integer",
	},
	{
		value: "number",
		label: "Decimal",
	},
	{
		value: "boolean",
		label: "Boolean",
	},
	{
		value: "object",
		label: "Object",
	},
	{
		value: "array",
		label: "Array",
	},
	{
		value: "oneOf",
		label: "Select",
	},
];

export const schemaTypesArray: SchemaTypeArrayOption[] = [
	{
		value: "string",
		label: "Text",
	},
	{
		value: "integer",
		label: "Integer",
	},
	{
		value: "number",
		label: "Decimal",
	},
	{
		value: "object",
		label: "Object",
	},
];

export const formatOptions = [
	{
		value: "date",
		label: "Date",
	},
	{
		value: "date-time",
		label: "DateTime",
	},
	{
		value: "time",
		label: "Time",
	},
	{
		value: "email",
		label: "Email",
	},
	{
		value: "hostname",
		label: "Hostname",
	},
	{
		value: "ipv4",
		label: "IPV4",
	},
	{
		value: "ipv6",
		label: "IPV6",
	},
	{
		value: "uri",
		label: "URI",
	},
];

const commonValidProperties: CommonValidSchemaField[] = ["description", "type", "title"];

export const stringValidSchemaProperties: StringValidSchemaField[] = [
	...commonValidProperties,
	"enum",
	"format",
	"maxLength",
	"minLength",
	"pattern",
];

export const numberValidSchemaProperties: NumberValidSchemaField[] = [
	...commonValidProperties,
	"maximum",
	"minimum",
	"multipleOf",
];

export const integerValidSchemaProperties: IntegerValidSchemaField[] = [
	...commonValidProperties,
	"maximum",
	"minimum",
	"multipleOf",
];

export const boolValidSchemaProperties: BoolValidSchemaField[] = [...commonValidProperties];

export const oneOfValidSchemaProperties: oneOfValidSchemaField[] = ["oneOf", "description"];

export const arrayValidSchemaProperties: ArrayValidSchemaField[] = [
	...commonValidProperties,
	"maxItems",
	"minItems",
	"uniqueItems",
	"items",
];

export const objectValidSchemaProperties: ObjectValidSchemaField[] = [
	...commonValidProperties,
	"required",
	"properties",
];

const commonSchemaOptions: CommonSchemaFieldOption[] = [{ value: "description", label: "Description", type: "text" }];

export const stringSchemaOptions: StringSchemaFieldOption[] = [
	{
		value: "default",
		label: "Default Value",
		type: "text",
	},
	{ value: "minLength", label: "Minimum Length", type: "number" },
	{ value: "maxLength", label: "Maximum Length", type: "number" },
	{ value: "enum", label: "Enum Options", type: "multi_creatable" },
	{ value: "pattern", label: "Regular expression", type: "text" },
	{
		value: "format",
		label: "Format",
		type: "select",
		optionList: formatOptions,
	},
	...commonSchemaOptions,
];

export const oneOfOptions: oneOfSchemaFieldOption[] = [
	{
		value: "default",
		label: "Default Value",
		type: "text",
	},
	...commonSchemaOptions,
];

export const numberSchemaOptions: NumberSchemaFieldOption[] = [
	{
		value: "default",
		label: "Default Value",
		type: "number",
	},
	{ value: "minimum", label: "Minimum Value", type: "number" },
	{ value: "maximum", label: "Maximum Value", type: "number" },
	{ value: "multipleOf", label: "Multiple of", type: "number" },
	...commonSchemaOptions,
];

export const integerSchemaOptions: IntegerSchemaFieldOption[] = [
	{
		value: "default",
		label: "Default Value",
		type: "number",
	},
	{ value: "minimum", label: "Minimum", type: "number" },
	{ value: "maximum", label: "Maximum", type: "number" },
	{ value: "multipleOf", label: "Multiple of", type: "number" },
	...commonSchemaOptions,
];

export const boolSchemaOptions: BoolSchemaFieldOption[] = [
	{
		value: "default",
		label: "Default Value",
		type: "boolean",
	},
	...commonSchemaOptions,
];

export const objectSchemaOptions: ObjectSchemaFieldOption[] = [
	{ value: "required", label: "Required Fields", type: "required" },
	...commonSchemaOptions,
];

export const arraySchemaOptions: ArraySchemaFieldOption[] = [
	{ value: "minItems", label: "Minimun number of items", type: "number" },
	{ value: "maxItems", label: "Maximum number of items", type: "number" },
	{ value: "uniqueItems", label: "Unique items", type: "boolean" },
];

export const typeToOptions: Record<SchemaType, SchemaMenuOption[]> = {
	string: stringSchemaOptions,
	integer: integerSchemaOptions,
	number: numberSchemaOptions,
	boolean: boolSchemaOptions,
	array: arraySchemaOptions,
	object: objectSchemaOptions,
	oneOf: oneOfOptions,
};

export const typeToValidFields: Record<SchemaType, string[]> = {
	string: stringValidSchemaProperties,
	integer: integerValidSchemaProperties,
	number: numberValidSchemaProperties,
	boolean: boolValidSchemaProperties,
	object: objectValidSchemaProperties,
	array: arrayValidSchemaProperties,
	oneOf: oneOfValidSchemaProperties,
};
