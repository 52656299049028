import * as React from "react";
import Layout from "./Layout";
import useGetUpdatesCount from "../../hooks/useGetUpdatesCount";
import useStore, { activeApplicationSelector, activeMarketSelector } from "../../store";

interface DashboardTileProps {
	showGlobal: boolean;
}

const Dashboard: React.FC<DashboardTileProps> = ({ showGlobal }) => {
	const activeApplication = useStore(activeApplicationSelector);
	const activeMarket = useStore(activeMarketSelector);
	const [activeTertial, setActiveTertial] = React.useState<string>("T1 2024");
	const { data: updateCounter, loadingTile: loadingTile, loadingCounter: loadingCounter, error: updateCounterError } = useGetUpdatesCount(activeApplication, activeMarket, activeTertial, showGlobal);

	const [ updateCount, setUpdateCount ] = React.useState<number | undefined>(undefined);

	React.useEffect(() => {
		if (updateCounterError) {
			setUpdateCount(undefined);
		} else {
			setUpdateCount(updateCounter);
		}
	}, [updateCounter, updateCounterError]);

	return (
		<Layout
			activeMarket={activeMarket}
			activeTertial={activeTertial}
			setActiveTertial={setActiveTertial}
			updateCount={updateCount}
			updateCounterTileLoading={loadingTile}
			updateCounterOnlyLoading={loadingCounter}
		/>
	);
};

export default Dashboard;