import * as React from "react";

import { applicationIdRegExp, emailRegExp } from "../../../utils/regularExpressions";
import { ApplicationValidation, Valid, Value } from "./types";
import { useIsFlaggaAdmin } from "../../../hooks/useIsFlaggaAdmin";

const useApplicationForm = () => {
	const { currentUser, isFlaggaAdmin } = useIsFlaggaAdmin();

	const [value, setValue] = React.useState<Value>({
		appId: "",
		appName: "",
		email: isFlaggaAdmin || !currentUser ? "" : currentUser.user.email,
		showDashboard: false,
		showMarketDashboard: false,
		allowReadOnly: false,
		showAdoptionRate:false,
		hasReadDocumentation: isFlaggaAdmin ? true : false,
		plan: "",
		
	});
	const [validation, setValidation] = React.useState<ApplicationValidation>({
		appId: { id: "appIdError", msg: "", type: "" },
		appName: { id: "appnameError", msg: "", type: "" },
		email: { id: "emailError", msg: "", type: "" },
		hasReadDocumentation: { id: "hasReadDocumentationError", msg: "", type: "" },
	});
	const [valid, setValid] = React.useState<Valid>({
		appId: true,
		appName: true,
		email: true,
		hasReadDocumentation: true,
	});
	const [shouldValidate, setShouldValidate] = React.useState<Valid>({
		appId: false,
		appName: false,
		email: false,
		hasReadDocumentation: false,
	});

	const validate = React.useCallback(
		(value: string | boolean | string[], name: string) => {
			switch (name) {
				case "applicationId":
					if (!new RegExp(applicationIdRegExp).test(value as string)) {
						if (value === "") {
							setShouldValidate({ ...shouldValidate, appId: true });
							setValid({ ...valid, appId: false });
							setValidation({
								...validation,
								appId: {
									...validation.appId,
									msg: "Enter Application ID",
								},
							});
						} else {
							setShouldValidate({ ...shouldValidate, appId: true });
							setValid({ ...valid, appId: false });
							setValidation({
								...validation,
								appId: {
									...validation.appId,
									msg: "Application ID Should not include spaces, numbers or spectial characters",
								},
							});
						}
					} else {
						setValid({ ...valid, appId: true });
					}
					break;
				case "applicationName":
					if (value === "") {
						setShouldValidate({ ...shouldValidate, appName: true });
						setValid({ ...valid, appName: false });
						setValidation({
							...validation,
							appName: {
								...validation.appName,
								msg: "Enter Application Name",
							},
						});
					} else {
						setValid({ ...valid, appName: true });
					}
					break;
				case "hasReadDocumentation":
					if (!value) {
						setShouldValidate({ ...shouldValidate, hasReadDocumentation: true });
						setValid({ ...valid, hasReadDocumentation: false });
						setValidation({
							...validation,
							hasReadDocumentation: {
								...validation.hasReadDocumentation,
								msg: "Please check the option",
							},
						});
					} else {
						setValid({ ...valid, hasReadDocumentation: true });
					}
					break;
				case "email":
					if (!new RegExp(emailRegExp).test(value as string)) {
						if (value === "") {
							setShouldValidate({ ...shouldValidate, email: true });
							setValid({ ...valid, email: false });
							setValidation({
								...validation,
								email: {
									...validation.email,
									msg: "Enter Email",
								},
							});
						} else {
							setShouldValidate({ ...shouldValidate, email: true });
							setValid({ ...valid, email: false });
							setValidation({
								...validation,
								email: {
									...validation.email,
									msg: "Enter correct Email",
								},
							});
						}
					} else {
						setValid({ ...valid, email: true });
					}
					break;
				default:
					break;
			}
		},
		[shouldValidate, valid, validation]
	);

	const handleChange = React.useCallback(
		(val: string | boolean | string[], name: string) => {
			validate(val, name);
			switch (name) {
				case "applicationId":
					setValue({ ...value, appId: val as string });
					break;
				case "applicationName":
					setValue({ ...value, appName: val as string });
					break;
				case "clientIds":
					setValue({ ...value, clientIds: val as string[] });
					break;
				case "email":
					setValue({ ...value, email: val as string });
					break;
				case "showDashboard":
					setValue({ ...value, showDashboard: !value.showDashboard });
					break;
				case "showMarketDashboard":
					setValue({ ...value, showMarketDashboard: !value.showMarketDashboard });
					break;
				case "allowReadOnly":
					setValue({ ...value, allowReadOnly: !value.allowReadOnly });
					break;
				case "showAdoptionRate":
					setValue({ ...value, showAdoptionRate: !value.showAdoptionRate });
					break;
				case "hasReadDocumentation":
					setValue({ ...value, hasReadDocumentation: val as boolean });
					break;
				case "plan":
					setValue({ ...value, plan: val as string });
					break;
				default:
					break;
			}
		},
		[validate, value]
	);

	return {
		value,
		valid,
		validation,
		validate,
		handleChange,
		shouldValidate,
		setValidation,
		setShouldValidate,
		setValue,
	};
};

export default useApplicationForm;
