/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import styled from "styled-components";

import laptop from "@ingka/ssr-icon/paths/laptop";
import { ListViewItem } from "@ingka/list-view";
import Tooltip from "@ingka/tooltip";
import Button from "@ingka/button";
import plusSmall from "@ingka/ssr-icon/paths/plus-small";
import touchScreen from "@ingka/ssr-icon/paths/hand-touch";
import handStrikethrough from "@ingka/ssr-icon/paths/hand-strikethrough";
import trashCan from "@ingka/ssr-icon/paths/trash-can";

import Modal, { ModalBody, Sheets } from "@ingka/modal";

import FlagsRow from "../../FLagsRow";
import { StyledCommercialMessage, StyledDivider, StyledListView, StyledModalHeader } from "../../styles";
import { UserApplication } from "../../../store";
import { UserPermissions } from "../../../utils/constants";
import { isUserHasMarkets } from "../../../utils/common";
import RequestNewMarketAccess from "../../RequestNewMarketAccess";
import RequestNewRoleModal from "../../RequestNewRoleModal";

interface UserAppOverviewPropsLayout {
	application?: UserApplication & { id: string };
	onClickRequestMarkets: () => void;
	requestMarketsModal: boolean;
	setRequestMarketsModal: (val: boolean) => void;
	showRequestNewRoleModal: boolean;
	setShowRequestNewRoleModal: (val: boolean) => void;
	onClickCancelMarketRequest: () => void;
	onClickCancelRoleRequest: () => void;
	cancellingMarkets: boolean;
	cancellingRoles: boolean;
	setToast?: (val: string) => void;
}

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const MarketsWrapper = styled.div`
	margin-top: 2rem;
`;

const RoleWraper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const StyledButton = styled(Button)`
	flex: 0 50%;
`;

const StyledListViewItem = styled(ListViewItem)`
	flex: 1.6 1 0%;
`;

const RequestedMarketsTitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const PermissionsActionsWrapper = styled.div`
	margin-top: 0.5rem;
	display: flex;
	gap: 0.5rem;
`;

const Layout: React.FC<UserAppOverviewPropsLayout> = ({
	application,
	onClickRequestMarkets,
	requestMarketsModal,
	setRequestMarketsModal,
	setToast,
	showRequestNewRoleModal,
	setShowRequestNewRoleModal,
	onClickCancelMarketRequest,
	cancellingMarkets,
	onClickCancelRoleRequest,
	cancellingRoles,
}) => {
	return (
		<>
			{application && (
				<StyledListView id={"user-application-overview"}>
					<>
						<RoleWraper>
							<StyledListViewItem
								leadingIcon={laptop}
								emphasised
								showDivider={false}
								description={
									<>
										Role:{" "}
										{application.permissions === "admin" ? (
											<StyledCommercialMessage message="Admin" variant="family" subtle />
										) : application.permissions === UserPermissions.Maintainer &&
										  !Object.keys(application?.markets || {}).length ? (
											<StyledCommercialMessage message="Read Only" variant="new" subtle />
										) : (
											application.permissions === UserPermissions.Maintainer &&
											isUserHasMarkets(application.markets) && (
												<StyledCommercialMessage message="Maintainer" variant="nlp" subtle />
											)
										)}
									</>
								}
								addOn={
									<>
										{application?.requestedPermissions && (
											<>
												Requested Role:{" "}
												{application?.requestedPermissions === "admin" ? (
													<StyledCommercialMessage message="Admin" variant="family" subtle />
												) : application?.requestedPermissions === UserPermissions.ReadOnly ? (
													<StyledCommercialMessage message="Read Only" variant="new" subtle />
												) : (
													application?.requestedPermissions === UserPermissions.Maintainer && (
														<StyledCommercialMessage message="Maintainer" variant="nlp" subtle />
													)
												)}
											</>
										)}
										<PermissionsActionsWrapper>
											<StyledButton
												small
												id="change-role-button"
												type="emphasised"
												text="Change Role"
												ssrIcon={touchScreen}
												onClick={() => setShowRequestNewRoleModal(true)}
												disabled={cancellingRoles}
											/>
											{application?.requestedPermissions && (
												<StyledButton
													small
													id="cancel-change-role-button"
													text="Cancel Change Role"
													ssrIcon={handStrikethrough}
													onClick={onClickCancelRoleRequest}
													loading={cancellingRoles}
												/>
											)}
										</PermissionsActionsWrapper>
									</>
								}
								controlIcon="chevronRightSmall"
								title={application.applicationName}
							/>
						</RoleWraper>
						{application.permissions === UserPermissions.Maintainer && isUserHasMarkets(application.markets) && (
							<>
								{<StyledDivider />}
								{
									<>
										<MarketsWrapper>
											<RequestedMarketsTitleWrapper>
												<TextWrapper>
													<p>Market Assigned for this application</p>
												</TextWrapper>
												<div>
													<Tooltip tooltipText="Request Markets">
														<Button
															id="request-markets-button"
															ssrIcon={plusSmall}
															iconOnly
															type="emphasised"
															small
															onClick={onClickRequestMarkets}
														/>
													</Tooltip>
												</div>
											</RequestedMarketsTitleWrapper>
											<FlagsRow
												markets={Object.keys(application?.markets || {})}
												size={125}
												tooltipBoundaryContainerID="user-application-overview"
											/>
										</MarketsWrapper>
										<StyledDivider />
									</>
								}
								{
									<MarketsWrapper>
										<RequestedMarketsTitleWrapper>
											<TextWrapper>
												<p>Markets Requested for this application</p>
											</TextWrapper>
											{application?.requestedMarkets?.length && (
												<Tooltip tooltipText="Remove Request">
													<Button
														id="cancel-markets-request-button"
														ssrIcon={trashCan}
														type="emphasised"
														small
														iconOnly
														loading={cancellingMarkets}
														onClick={onClickCancelMarketRequest}
													/>
												</Tooltip>
											)}
										</RequestedMarketsTitleWrapper>
										<FlagsRow
											markets={application?.requestedMarkets ? application?.requestedMarkets : []}
											size={125}
											tooltipBoundaryContainerID="user-application-overview"
										/>
										<StyledDivider />
									</MarketsWrapper>
								}
							</>
						)}
					</>
				</StyledListView>
			)}
			{application?.id && (
				<Modal
					focusLockProps={{ locked: false }}
					visible={requestMarketsModal}
					handleCloseBtn={() => setRequestMarketsModal(false)}
				>
					<Sheets
						footer={null}
						header={
							<StyledModalHeader
								title={`Request Markets in ${application?.applicationName}`}
								backBtnClick={() => setRequestMarketsModal(false)}
							/>
						}
					>
						<ModalBody>
							<RequestNewMarketAccess
								appId={application?.id}
								onModalClose={() => setRequestMarketsModal(false)}
								setToast={setToast}
							/>
						</ModalBody>
					</Sheets>
				</Modal>
			)}
			{application && (
				<RequestNewRoleModal
					app={application}
					showRequestNewRoleModal={showRequestNewRoleModal}
					setShowRequestNewRoleModal={setShowRequestNewRoleModal}
				/>
			)}
		</>
	);
};

export default Layout;
