import * as React from "react";

interface UseIntervalProps {
    setShowInactivityModal: (val: boolean) => void;
    showInactivityModal: boolean;
    handleLogout: () => void;
    callback: any;
    delay: any;
}

const useInterval = ({setShowInactivityModal, showInactivityModal, handleLogout, callback, delay}: UseIntervalProps) => {
	const savedCallback = React.useRef(callback);
    
	// Remember the latest callback.
	React.useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
    
	// Set up the interval.
	React.useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		} else if (delay === null && showInactivityModal) {
			setShowInactivityModal(false);
			handleLogout();
		}
	}, [delay]);
};

export default useInterval;
