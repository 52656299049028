import React from "react";

import Toast from "@ingka/toast";

import ContentBox from "../ContentBox";
import RequestNewApplicationAccess from "../RequestNewApplicationAccess";
import styled from "styled-components";

const ContentWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface JoinExistingApplicationProps {
	toast: string;
	setToast: (str: string) => void;
}

const Layout: React.FC<JoinExistingApplicationProps> = ({ toast, setToast }) => {
	return (
		<ContentWrapper>
			<ContentBox>
				<RequestNewApplicationAccess setToast={setToast} onSuccessRedirectRoute="/new-user" />
				<Toast
					text={toast}
					isOpen={!!toast}
					onCloseRequest={() => setToast("")}
					ariaLabelCloseBtn="Dismiss notification"
				/>
			</ContentBox>
		</ContentWrapper>
	);
};

export default Layout;
