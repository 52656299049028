/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import useGetMarkets from "../../hooks/useGetMarkets";
import useStore, { ApplicationData, activeApplicationSelector } from "../../store";
import Layout from "./Layout";
import { MarketDashboardData, getMarketDashboardChartsData, getMarketDashboardData } from "../../services/dashboard";
import { getGroups, getLastUpdateDate, sortTableData } from "./utils";
import InlineMessage from "@ingka/inline-message";
import useAuthentication from "../../hooks/useAuthentication";

interface FeatersOverviewTableProps {
	applicationsData?: ApplicationData[];
	applicationsLoading: boolean;
	activeMarket: string | null;
	lastUpdateDate: string;
	setLastUpdateDate: (value: string) => void;
	setLastUpdateDateLoading: (value: boolean) => void;
}

const CenterContainer = styled.div`
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const MarketFeaturesOverviewTable: React.FC<FeatersOverviewTableProps> = ({
	activeMarket,
	lastUpdateDate,
	setLastUpdateDate,
	setLastUpdateDateLoading,
}) => {
	const activeApplication = useStore(activeApplicationSelector);
	const [hasGroups, setHasGroups] = React.useState<boolean>(false);
	const [tabsData, setTabsData] = React.useState<any>([]);
	const [activeTab, setActiveTab] = React.useState<string>("");
	const [searchPropertyValue, setSearchPropertyValue] = React.useState<string>("");

	const [marketDashboardTableData, setMarketDashboardTableData] = React.useState<MarketDashboardData | undefined>(
		undefined
	);
	const [marketDashboardChartsData, setMarketDashboardChartsData] = React.useState<any>(undefined);

	const [tableData, setTableData] = React.useState<MarketDashboardData["marketData"] | undefined>([]);
	const [dashboardDataLoading, setDashBoardDataLoading] = React.useState<boolean>(true);
	const [dashboardChartsDataLoading, setDashboardChartsDataLoading] = React.useState<boolean>(true);

	const loading = React.useMemo(
		() =>
			dashboardDataLoading ||
			(marketDashboardTableData?.showAdoptionRate ? dashboardChartsDataLoading : false) ||
			false,
		[dashboardDataLoading, dashboardChartsDataLoading, marketDashboardTableData?.showAdoptionRate]
	);
	const [showMarketUsersModal, setShowMarketUsersModal] = React.useState<boolean>(false);
	const [showSeachBar, setShowSeachBar] = React.useState<boolean>(false);

	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		if (activeApplication && activeMarket) {
			(async () => {
				try {
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					setDashBoardDataLoading(true);
					let { data: dashboardData } = await getMarketDashboardData(authHeader, activeApplication.id, activeMarket);

					//check and create missing properties for values

					dashboardData?.data.marketData.forEach((tableItem: any) => {
						let valueKeys = [] as string[];
						if (
							tableItem.value &&
							Array.isArray(tableItem.value) &&
							tableItem.value[0] &&
							typeof tableItem.value[0] === "object"
						) {
							for (let i = 0; i < tableItem.value.length; i++) {
								if (Object.keys(tableItem.value[i]).length > valueKeys.length) {
									valueKeys = Object.keys(tableItem.value[i]);
								}
							}
							valueKeys.forEach((key) => {
								tableItem.value.forEach((tableItemValue: any) => {
									if (!tableItemValue[key]) {
										tableItemValue[key] = "";
									}
								});
							});

							//sort values
							if (Array.isArray(tableItem.value)) {
								tableItem.value = tableItem.value.map((tableItemValue: any) => {
									return Object.fromEntries(Object.entries(tableItemValue).sort());
								});
							}
						}
					});

					setMarketDashboardTableData(JSON.parse(JSON.stringify(dashboardData?.data)));
				} catch (error: any) {
					if (error) {
						console.log(error);
						setMarketDashboardTableData(undefined);
						setDashBoardDataLoading(false);
						if (error?.response?.status == 401) {
							handleLogout();
						}
					}
				} finally {
					setLastUpdateDateLoading(false);
					setDashBoardDataLoading(false);
				}
			})();

			return () => {
				setMarketDashboardTableData(undefined);
				setDashBoardDataLoading(false);
			};
		}
	}, [activeApplication, activeMarket, getToken, handleLogout, setLastUpdateDateLoading]);

	React.useEffect(() => {
		if (marketDashboardTableData?.marketData && marketDashboardTableData?.marketData.length > 0) {
			const lastUpdateDate = getLastUpdateDate(marketDashboardTableData.marketData);
			lastUpdateDate
				? setLastUpdateDate(dayjs(lastUpdateDate).format("DD/MM/YYYY HH:mm").toString())
				: setLastUpdateDate("-");
		} else {
			setLastUpdateDate("-");
		}
	}, [marketDashboardTableData, setLastUpdateDate, setMarketDashboardTableData]);

	const assignChartColor = React.useCallback((plotsData: any) => {
		return plotsData.map((plotData: any) => {
			return {
				...plotData,
				series: [Math.round(plotData.series[0])],
				colorBar: plotData.series[0] >= 70 ? "#C5FFC8" : plotData.series[0] < 25 ? "#FFE5D3" : "#DAD7FE",
			};
		});
	}, []);

	React.useEffect(() => {
		if (activeApplication && activeMarket && marketDashboardTableData?.showAdoptionRate) {
			(async () => {
				try {
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					setDashboardChartsDataLoading(true);
					let { data: dashboardChartsData } = await getMarketDashboardChartsData(
						authHeader,
						activeApplication.id,
						activeMarket
					);

					if (dashboardChartsData) {
						dashboardChartsData.data = assignChartColor(dashboardChartsData?.data);
						setMarketDashboardChartsData(dashboardChartsData?.data);
					}
				} catch (error: any) {
					if (error) {
						setMarketDashboardChartsData(undefined);
						setDashboardChartsDataLoading(false);
						if (error?.response?.status == 401) {
							handleLogout();
						}
					}
				} finally {
					setDashboardChartsDataLoading(false);
				}
			})();

			return () => {
				setMarketDashboardChartsData(undefined);
				setDashboardChartsDataLoading(false);
			};
		}
	}, [
		marketDashboardTableData?.showAdoptionRate,
		activeApplication,
		activeMarket,
		assignChartColor,
		getToken,
		handleLogout,
	]);

	React.useEffect(() => {
		if (marketDashboardTableData) {
			let groupIds: string[], groupIdIndex: { [key: string]: number };
			let groupInfo = getGroups(marketDashboardTableData);
			groupIds = groupInfo.groupIds;
			groupIdIndex = groupInfo.groupIdIndex;
			if (groupIds.length) {
				setHasGroups(true);
				groupIds = groupIds.sort((a, b) => {
					if (groupIdIndex[a] > groupIdIndex[b]) {
						return 1;
					} else if (groupIdIndex[a] < groupIdIndex[b]) {
						return -1;
					} else if (a.toLowerCase() < b.toLowerCase()) {
						return -1;
					} else if (a.toLowerCase() > b.toLowerCase()) {
						return 1;
					}
					return 0;
				});
				setActiveTab(groupIds[0]);
			}
			setTabsData(groupInfo);
		}
	}, [marketDashboardTableData, setMarketDashboardTableData]);

	React.useEffect(() => {
		if (loading) {
			setTableData([]);
		}
		if (tabsData?.groupIds && tabsData?.groupIds.length && marketDashboardTableData) {
			if (!searchPropertyValue) {
				if (activeTab === "Other") {
					setTableData(
						marketDashboardTableData.marketData
							.filter((item) => item.groupId === "" || !item.groupId)
							?.sort(sortTableData)
					);
				} else {
					setTableData(
						marketDashboardTableData.marketData.filter((item) => item.groupId === activeTab)?.sort(sortTableData)
					);
				}
			}
		} else if (marketDashboardTableData) {
			setTableData(marketDashboardTableData.marketData?.sort(sortTableData));
		} else {
			setTableData([]);
		}
	}, [
		loading,
		activeTab,
		setActiveTab,
		marketDashboardTableData,
		setMarketDashboardTableData,
		searchPropertyValue,
		tabsData?.groupIds,
	]);

	React.useEffect(() => {
		if (searchPropertyValue) {
			setTableData(tableData?.filter((item) => item.key.includes(searchPropertyValue.toLocaleLowerCase())));
		}
	}, [searchPropertyValue, tableData]);

	if (!marketDashboardTableData && !loading && !marketDashboardChartsData) {
		return (
			<CenterContainer>
				<InlineMessage title="No Data" body={"No market specific data to display"} variant="cautionary" />
			</CenterContainer>
		);
	}

	if (!activeApplication && !loading) {
		return (
			<CenterContainer>
				<InlineMessage
					title="No Selected Applications"
					body={
						"Seems like you don't have any active application or you don't have access to the current active application."
					}
					variant="cautionary"
				/>
			</CenterContainer>
		);
	}

	return (
		<Layout
			loading={loading}
			activeApplication={activeApplication}
			marketData={marketDashboardTableData?.marketData}
			showAdoptionRate={marketDashboardTableData?.showAdoptionRate}
			hasGroups={hasGroups}
			tabsData={tabsData}
			setActiveTab={setActiveTab}
			tableData={tableData}
			activeTab={activeTab}
			activeMarket={activeMarket}
			showMarketUsersModal={showMarketUsersModal}
			setShowMarketUsersModal={setShowMarketUsersModal}
			showSeachBar={showSeachBar}
			setShowSeachBar={setShowSeachBar}
			searchPropertyValue={searchPropertyValue}
			setSearchPropertyValue={setSearchPropertyValue}
			marketDashboardChartsData={marketDashboardChartsData}
		/>
	);
};

export default MarketFeaturesOverviewTable;
