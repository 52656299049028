import { JsonSchema, UISchemaElement } from "@jsonforms/core";
import { AxiosResponse } from "axios";
import axios from "../../configs/axios";
import { AuthHeader } from "../../types";
import { Market } from "../../store";
interface JsonFormData {
	schema: JsonSchema;
	uiSchema: UISchemaElement;
	schemaValue: any;
	permissions: string;
	readOnly: boolean;
}
interface SchemaResponseData {
	data: JsonFormData;
	message: string;
}

export const getSchemas = async (header: AuthHeader, application: string, market: string) => {
	const data: AxiosResponse<SchemaResponseData, AuthHeader> = await axios.get(
		`/application/${application}/market/${market}/schema`,
		{
			...header,
		}
	);
	return data;
};

//TODO: Provide types
export const updateSchemas = async (
	header: AuthHeader,
	application: string,
	market: string,
	value: any,
	multipleMarketFlag: boolean,
	selectedMarkets: (string | null)[],
	uiSchemaName?: string,
	reason?: string
) => {
	let url = multipleMarketFlag
		? `/application/${application}/schema/value?markets=${selectedMarkets.join(",")}`
		: `/application/${application}/market/${market}/schema/value`;
	const data = await axios.post(
		url,
		{ value, reason, ...(uiSchemaName && { uiSchemaName }) },
		{
			...header,
		}
	);
	return data;
};

export const getScheduledChanges = async (
	header: AuthHeader,
	application: string,
) => {
	const data = await axios.get(
		`/application/${application}/schema/value/schedule/list`,
		{
			...header,
		}
	);
	return data;
};

export const removeScheduledChanges = async (
	header: AuthHeader,
	application: string,
	id: string
) => {
	const data = await axios.delete(
		`/application/${application}/schema/value/schedule/${id}`,
		{
			...header,
		}
	);
	return data;
};


export const scheduleChanges = async (
	header: AuthHeader,
	application: string,
	market: Market,
	value: any,
	uiSchemaName: string | undefined,
	date: string,
	reason: string,
	sendCopyEmail: boolean
) => {
	const data = await axios.post(
		`/application/${application}/schema/value/schedule?markets=${market}`,
		{ value, reason, uiSchemaName, date, sendCopyEmail },
		{
			...header,
		}
	);
	return data;
};


export const updateScheduledChanges = async (
	header: AuthHeader,
	application: string,
	updateId: string,
	date: string,
) => {
	const data = await axios.patch(
		`/application/${application}/schema/value/schedule/${updateId}/update`,
		{ date },
		{
			...header,
		}
	);
	return data;
};