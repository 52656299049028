/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import styled from "styled-components";
import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { Editor } from "./Editor";

export interface EditorTab {
	name: string;
	Component: React.ComponentType;
}

interface EditorPanelProps {
	editorTabs?: EditorTab[];
	editorRenderers: JsonFormsRendererRegistryEntry[];
}

const EditorPanelWrapper = styled.div`
	height: 100%;
`;

const OtherPanelsWrapper = styled.div`
	margin: 1rem;
`;

export const EditorPanel: React.FC<EditorPanelProps> = ({ editorTabs, editorRenderers }) => {
	const tabs = React.useMemo(
		() => [
			<Tab key="editor" text="Editor" tabPanelId="editorPanel" />,
			...(editorTabs
				? editorTabs.map((tab) => <Tab key={`tab-${tab.name}`} text={tab.name} tabPanelId={`${tab.name}Panel`} />)
				: []),
		],
		[editorTabs]
	);

	const tabPanels = React.useMemo(
		() => [
			<TabPanel key="editor" tabPanelId="editorPanel">
				<Editor editorRenderers={editorRenderers} />
			</TabPanel>,
			...(editorTabs
				? editorTabs.map((tab) => (
						<TabPanel key={`tab-${tab.name}`} tabPanelId={`${tab.name}Panel`}>
							<OtherPanelsWrapper>
								<tab.Component />
							</OtherPanelsWrapper>
						</TabPanel>
				  ))
				: []),
		],
		[editorRenderers, editorTabs]
	);
	return (
		<EditorPanelWrapper>
			<Tabs className="editor-tabs" tabs={tabs} tabPanels={tabPanels} defaultActiveTab="editorPanel" />
		</EditorPanelWrapper>
	);
};
