import * as React from "react";
import useAuthentication from "./useAuthentication";
import { UserData, getAppAdmins } from "../services/users";

const useGetAdmins = (applicationId: string | undefined) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<UserData[] | undefined>(undefined);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const { getToken } = useAuthentication();

	React.useEffect(() => {
		(async () => {
			if (applicationId) {
				try {
					const token = await getToken();
					setLoading(true);
					let { data: usersData } = await getAppAdmins(token, applicationId);
					setData(usersData.data);
				} catch (error: any) {
					setData(undefined);
					setError("Can't fetch admins, something went wrong");
				} finally {
					setLoading(false);
				}
			} else {
				setData(undefined);
			}
		})();
	}, [getToken, applicationId]);

	return { data, loading, error };
};

export default useGetAdmins;