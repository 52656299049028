import * as React from "react";
import { User } from "../store";
import useAuthentication from "./useAuthentication";
import { getLoggedInUser } from "../services/users";
import { ResponseMessage } from "../utils/constants";

const useGetLoggedInUser = () => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [data, setData] = React.useState<User | undefined>(undefined);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const { getToken, handleLogout } = useAuthentication();

	const getUser = React.useCallback(async () => {
		try {
			setLoading(true);
			const token = await getToken();
			const { data: userData } = await getLoggedInUser(token);
			if (userData?.message.toLowerCase() === ResponseMessage.Success) {
				setData(userData?.data);
			}
		} catch (error: any) {
			if (error) {
				setData(undefined);
				setError(error?.response?.data?.message as string);
				if (error?.response?.status == 401) {
					handleLogout();
				}
			}
		} finally {
			setLoading(false);
		}
	}, [getToken, handleLogout]);

	return { data, loading, error, getUser };
};

export default useGetLoggedInUser;
