import * as React from "react";
import { IconProps } from "./types";

export const SortAscending: React.FC<IconProps> = ({ height, width, colour }) => {
	return (
		<svg
			width={`${width}` || "24"}
			height={`${height}` || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.0005 9.8284 3.172 11.6569l-1.4142-1.4143L6.0004 6l4.2427 4.2426-1.4142 1.4143-1.8284-1.8284V18h-2V9.8284zM22 6H12v2h10V6zm-10 5h7v2h-7v-2zm0 5h4v2h-4v-2z"
				fill={colour || "#111"}
			/>
		</svg>
	);
};
