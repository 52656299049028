import * as React from "react";

import ManageApplicationRequests from "../components/ManageApplicationRequests/ManageApplicationRequests";
import RequireAuth from "../components/RequireAuth";

const ManageApplicationRequestsPage: React.FC = () => {
	return (
		<RequireAuth>
			<ManageApplicationRequests />
		</RequireAuth>
	);
};

export default ManageApplicationRequestsPage;
