import { AxiosResponse } from "axios";
import axios from "../../configs/axios";

import { AuthHeader } from "../../types";
import { FeedbackData } from "../../utils/types";

interface FeedbackResponseData {
	message: string;
	errors?: string[];
}

export const addFeedback = async (header: AuthHeader, feedback: FeedbackData) => {
	const feedbackResponse: AxiosResponse<FeedbackResponseData> = await axios.post(
		"/feedback",
		{ ...feedback },
		{
			...header,
		}
	);
	return feedbackResponse;
};