import * as React from "react";
import styled from "styled-components";

import Select, { Option as SelectOption } from "@ingka/select";
import InputField from "@ingka/input-field";
import InlineMessage from "@ingka/inline-message";
import Switch from "@ingka/switch";

import { SwitchWraper } from "./Layout";
import { ApplicationData } from "../../../store";

import {
	ClientIdsAddAction,
	ClientIdsContainer,
	ClientIdsHeaderInnerContainer,
	ClientIdsInnerContainer,
	ClientIdsInput,
	ClientIdsList,
	ExpandedClientIdsContainer,
	GreyPlaceholderText,
} from "../../styles";
import Text from "@ingka/text";
import Button from "@ingka/button";
import plus from "@ingka/ssr-icon/paths/plus";
import TrashCan from "@ingka/ssr-icon/paths/trash-can";

const StyledInput = styled(InputField)`
	padding-top: 1rem;
`;

const StyledWrapper = styled.div`
	margin-bottom: 2rem;
`;

interface UpdateApplicationLayoutProps {
	applications: ApplicationData[] | null;
	setUpdateAppSelected: (data?: ApplicationData) => void;
	updateAppSelected?: ApplicationData;
}

const UpdateApplicationLayout: React.FC<UpdateApplicationLayoutProps> = ({
	applications,
	setUpdateAppSelected,
	updateAppSelected,
}) => {
	return (
		<>
			<StyledWrapper>
				<InlineMessage
					variant="cautionary"
					body={
						<>
							<strong>Note: </strong>Please make sure that you dont have any "read only" user in your application before
							disabling read only.
						</>
					}
				/>
			</StyledWrapper>
			<Select
				id="applications"
				label="Applications"
				value={updateAppSelected?.id || undefined}
				onChange={(e) =>
					setUpdateAppSelected(
						JSON.parse(JSON.stringify(applications?.find((data) => data.id === e.target.value))) || undefined
					)
				}
			>
				{applications?.map((application) => (
					<SelectOption key={application?.id} value={application?.id} name={application?.name} />
				))}
			</Select>
			<br />
			<ClientIdsContainer>
				<ClientIdsHeaderInnerContainer>
					<ClientIdsAddAction>
						<Text bodySize="l">Application Client Id</Text>
					</ClientIdsAddAction>
				</ClientIdsHeaderInnerContainer>
				{(!updateAppSelected?.clientIds || updateAppSelected?.clientIds?.length === 0) && (
					<GreyPlaceholderText>No client ID has been added</GreyPlaceholderText>
				)}
				<ExpandedClientIdsContainer>
					{updateAppSelected?.clientIds?.map((clientId, index) => (
						<ClientIdsInnerContainer>
							<ClientIdsInput
								req={false}
								id={`clientId-${index + 1}`}
								value={updateAppSelected?.clientIds?.[index] || ""}
								label={`Client ID ${index + 1}`}
								type="text"
								onChange={({ target: { value: val } }) => {
									if (!updateAppSelected?.clientIds) {
										updateAppSelected.clientIds = [];
									}
									updateAppSelected.clientIds[index] = val;
									setUpdateAppSelected({ ...updateAppSelected });
								}}
							/>
							<Button
								type="tertiary"
								style={{ marginTop: "1rem" }}
								onClick={() => {
									updateAppSelected.clientIds?.splice(index, 1);
									if (updateAppSelected?.clientIds?.length === 0) {
										if (!applications?.find((data) => data.id === updateAppSelected.id)?.clientIds) {
											delete updateAppSelected?.clientIds;
										}
									}
									setUpdateAppSelected({ ...updateAppSelected });
								}}
								iconOnly
								small
								ssrIcon={TrashCan}
							/>
						</ClientIdsInnerContainer>
					))}
					<br />
					<Button
						type="tertiary"
						data-testid="add-client-id-update"
						text="Add client id"
						onClick={() => {
							if (updateAppSelected) {
								if (!updateAppSelected.clientIds) {
									updateAppSelected.clientIds = [];
								}
								updateAppSelected.clientIds.push("");
								setUpdateAppSelected({ ...updateAppSelected });
							}
						}}
						small
					/>
				</ExpandedClientIdsContainer>
			</ClientIdsContainer>
			<SwitchWraper>
				<Switch
					value={updateAppSelected?.showDashboard + ""}
					checked={!!updateAppSelected?.showDashboard}
					id="show-in-dashboard"
					label="Enable Global Dashboard"
					disabled={!updateAppSelected}
					onChange={() =>
						updateAppSelected &&
						setUpdateAppSelected({ ...updateAppSelected, showDashboard: !updateAppSelected?.showDashboard })
					}
				/>
			</SwitchWraper>
			<SwitchWraper>
				<Switch
					value={updateAppSelected?.showMarketDashboard + ""}
					checked={!!updateAppSelected?.showMarketDashboard}
					id="show-in-market-dashboard"
					label="Enable Market Dashboard"
					disabled={!updateAppSelected}
					onChange={() =>
						updateAppSelected &&
						setUpdateAppSelected({ ...updateAppSelected, showMarketDashboard: !updateAppSelected?.showMarketDashboard })
					}
				/>
			</SwitchWraper>
			<SwitchWraper>
				<Switch
					data-testid="showAdoptionRate"
					value={updateAppSelected?.showAdoptionRate + ""}
					checked={!!updateAppSelected?.showAdoptionRate}
					id="show-adoption-rate"
					label="Show Adoption Rate"
					onChange={() => {
						updateAppSelected &&
							setUpdateAppSelected({ ...updateAppSelected, showAdoptionRate: !updateAppSelected?.showAdoptionRate });
					}}
				/>
			</SwitchWraper>

			<SwitchWraper>
				<Switch
					value={updateAppSelected?.allowReadOnly + ""}
					checked={!!updateAppSelected?.allowReadOnly}
					id="allow-read-only"
					label="Allow Read Only"
					disabled={!updateAppSelected}
					onChange={() =>
						updateAppSelected &&
						setUpdateAppSelected({ ...updateAppSelected, allowReadOnly: !updateAppSelected?.allowReadOnly })
					}
				/>
			</SwitchWraper>
		</>
	);
};

export default UpdateApplicationLayout;
