import * as React from "react";
import useSelector, { setUserSelector, userSelector } from "../../../store";
import Layout from "./Layout";
import useCancelMarketsRequest from "../../../hooks/useCancelMarketsRequest";
import { ResponseMessage } from "../../../utils/constants";
import useGetLoggedInUser from "../../../hooks/useGetLoggedInUser";
import useCancelRoleRequest from "../../../hooks/useCancelRoleRequest";

interface UserAppOverviewProps {
	appId?: string;
	setToast?: (val: string) => void;
	setShowUserAppOverviewModal?: (val: boolean) => void;
}

const UserApplicationOverview: React.FC<UserAppOverviewProps> = ({ appId, setToast, setShowUserAppOverviewModal }) => {
	const [requestMarketsModal, setRequestMarketsModal] = React.useState<boolean>(false);
	const [showRequestNewRoleModal, setShowRequestNewRoleModal] = React.useState<boolean>(false);
	const [cancellingRoleReq, setCancellingRoleReq] = React.useState<boolean>(false);
	const [cancellingMarketsReq, setCancellingMarketsReq] = React.useState<boolean>(false);

	const currentUser = useSelector(userSelector);
	const setCurrentUser = useSelector(setUserSelector);

	const { getUser, data: userData } = useGetLoggedInUser();
	const { cancelMarkets, loading: cancellingMarkets } = useCancelMarketsRequest();
	const { cancelRole, loading: cancellingRoles } = useCancelRoleRequest();

	const application = React.useMemo(() => {
		if (currentUser && appId && currentUser.user.applications[appId]) {
			return { ...currentUser.user.applications[appId], id: appId };
		}
	}, [appId, currentUser]);

	const onClickRequestMarkets = React.useCallback(() => {
		setRequestMarketsModal(true);
	}, []);

	const onClickCancelMarketRequest = React.useCallback(async () => {
		if (appId) {
			const response = await cancelMarkets(appId);
			if (response?.toLowerCase() === ResponseMessage.Success) {
				setCancellingMarketsReq(true);
				await getUser();
				setCancellingMarketsReq(false);
				setToast && setToast("Successfully cancelled markets request.");
			} else {
				setToast && response && setToast(response);
			}
		}
	}, [appId, cancelMarkets, getUser, setToast]);

	const onClickCancelRoleRequest = React.useCallback(async () => {
		if (appId) {
			const response = await cancelRole(appId);
			if (response?.toLowerCase() === ResponseMessage.Success) {
				setCancellingRoleReq(true);
				await getUser();
				setCancellingRoleReq(false);
				setToast && setToast("Successfully cancelled new role request.");
			} else {
				setToast && response && setToast(response);
			}
		}
	}, [appId, cancelRole, getUser, setToast]);

	React.useEffect(() => {
		if (userData) {
			setCurrentUser(userData);
		}
	}, [userData, setCurrentUser]);

	return (
		<Layout
			application={application}
			onClickRequestMarkets={onClickRequestMarkets}
			requestMarketsModal={requestMarketsModal}
			setRequestMarketsModal={setRequestMarketsModal}
			setToast={setToast}
			showRequestNewRoleModal={showRequestNewRoleModal}
			setShowRequestNewRoleModal={setShowRequestNewRoleModal}
			onClickCancelMarketRequest={onClickCancelMarketRequest}
			cancellingMarkets={cancellingMarkets || cancellingMarketsReq}
			onClickCancelRoleRequest={onClickCancelRoleRequest}
			cancellingRoles={cancellingRoles || cancellingRoleReq}
		/>
	);
};

export default UserApplicationOverview;
