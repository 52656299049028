import * as React from "react";

import Layout from "./Layout";
import { addFeedback } from "../../services/feedback";
import useAuthentication from "../../hooks/useAuthentication";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import useStore, { isApplicationAdminSelector } from "../../store";
import { FeedbackData } from "../../utils/types";

interface AddFeedbackProps {
    showModalFromFloatingMenu?: boolean;
	setShowModalFromFloatingMenu?: (val: boolean) => void;
	showRating: boolean;
}

const Feedback: React.FC<AddFeedbackProps> = ({ showModalFromFloatingMenu, setShowModalFromFloatingMenu, showRating }) => {
	const [showFeedbackModal, setShowFeedbackModal] = React.useState<boolean>(false);
	const [showAddFeedbackModal, setShowAddFeedbackModal] = React.useState<boolean>(false);
	const [feedbackScore, setFeedbackScore] = React.useState<number>(0);
	const [feedbackPage, setFeedbackPage] = React.useState<string>("");
	const [feedbackText, setFeedbackText] = React.useState<string>("");
	const [sendFeedbackLoading, setSendFeedbackLoading] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const isAppAdmin = useStore(isApplicationAdminSelector);
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const isAdmin = React.useMemo(() => isFlaggaAdmin || isAppAdmin, [isAppAdmin, isFlaggaAdmin]);

	const { getToken } = useAuthentication();

	React.useEffect(() => {
		if (showModalFromFloatingMenu) {
			setShowAddFeedbackModal(showModalFromFloatingMenu);
		}
	}, [showModalFromFloatingMenu]);

	const sendFeedback = async () => {
		try {
			setSendFeedbackLoading(true);
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};

			let feedbackObject = {} as FeedbackData;

			if (!showModalFromFloatingMenu) {
				feedbackObject.feedback = feedbackText;
				feedbackObject.page = feedbackPage;
				feedbackObject.score = feedbackScore;
			} else {
				feedbackObject.feedback = feedbackText;
			}
		
			const response = await addFeedback(authHeader, feedbackObject);

			if (response.status === 200) {
				setToastMessage(response.data.message);
			} else {
				setToastMessage("There was an issue while sending the feedback. Please try again");
			}
			setSendFeedbackLoading(false);
			setShowAddFeedbackModal(false);
			if (setShowModalFromFloatingMenu) {
				setShowModalFromFloatingMenu(false);
			}
			setFeedbackText("");
		} 
		catch (err: any) {
			console.log(err);
			setSendFeedbackLoading(false);
			let errMsg = err.message;
			if (err.response && err.response.data && err.response.data.message) {
				errMsg += ` ${err.response.data.message}`;
			}
			setToastMessage(errMsg);
		}
	};

	return (
		<Layout
			showFeedbackModal={showFeedbackModal}
			setShowFeedbackModal={setShowFeedbackModal}
			showAddFeedbackModal={showAddFeedbackModal}
			setShowAddFeedbackModal={setShowAddFeedbackModal}
			sendFeedback={sendFeedback}
			setFeedbackScore={setFeedbackScore}
			setFeedbackPage={setFeedbackPage}
			feedbackText={feedbackText}
			setFeedbackText={setFeedbackText}
			sendFeedbackLoading={sendFeedbackLoading}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			isAdmin={isAdmin}
			setShowModalFromFloatingMenu={setShowModalFromFloatingMenu}
			showRating={showRating}
		/>
	);
};

export default Feedback;