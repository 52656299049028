import * as React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlElement, ControlProps } from "@jsonforms/core";
import TextArea from "@ingka/text-area";
import { VisibilityWrapper } from "../../styles";
import FormField from "@ingka/form-field";
import parse from "html-react-parser";
import { findAndTransformHTMLString } from "../utils";
import Tooltip from "@ingka/tooltip";

interface TextAreaControlProps extends ControlProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const TextAreaControl: React.FC<TextAreaControlProps> = ({
	id,
	data,
	schema,
	handleChange,
	path,
	label,
	description,
	uischema,
	errors,
	required,
	...props
}) => {
	const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			handleChange(path, e.target.value);
		},
		[path, handleChange]
	);

	const errMessage =
		required && errors.length
			? `${label} ${errors}`
			: required
			? `${label} is a required property`
			: `${label} ${errors}`;

	const textArea = React.useMemo(
		() => (
			<TextArea
				id={path}
				label={label}
				onChange={onChange}
				defaultValue={schema?.default || undefined}
				value={data || ""}
				disabled={!props.enabled}
			/>
		),
		[data, label, onChange, path, props.enabled, schema?.default]
	);

	return (
		<VisibilityWrapper visible={props.visible}>
			<FormField
				fieldHelper={{
					msg: uischema?.subText ? <div>{parse(findAndTransformHTMLString(uischema.subText))}</div> : "",
					id: "input-control-msg",
				}}
				valid={required ? !errors && data : !errors}
				shouldValidate={errors.length > 0 || (required && !data)}
				validation={{
					id: "error-msg-id",
					msg: errMessage,
					type: "error",
				}}
			>
				{description ? <Tooltip tooltipText={description}>{textArea}</Tooltip> : textArea}
			</FormField>
		</VisibilityWrapper>
	);
};

export default withJsonFormsControlProps(TextAreaControl);
