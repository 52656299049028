import { getKeysInSchema } from "../../utils/helpers";
import { Schema } from "../../utils/types";

const isValidUTF8 = (str: string) => {
	try {
		new TextEncoder().encode(str);
		return true;
	} catch (e) {
		return false;
	}
};

export const isUniqueSchemaKey = (key: string, schema: Schema) => {
	const schemaKeys = getKeysInSchema(schema);
	return schemaKeys.length && schemaKeys.includes(key);
};

export const validatePropertyKey = (key: string) => {
	// Check if the key is valid UTF-8
	if (!isValidUTF8(key)) {
		return { isValid: false, msg: "Not a valid UTF-8" };
	}

	// Check if the key length is within the Firestore limit (1,500 bytes)
	if (new TextEncoder().encode(key).length > 1500) {
		return { isValid: false, msg: "length must be within 1500 bytes" };
	}

	// check if string do not contain any special any character or spaces except - or _
	if (/[^\w-]/.test(key)) {
		return { isValid: false, msg: "special character or spaces not allowed" };
	}

	if (["items", "oneOf", "manyOf", "properties"].includes(key)) {
		return { isValid: false, msg: `${key} is a reserved keyword` };
	}

	// The key is valid
	return { isValid: true, msg: "" };
};
