import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { SEARCH_PARAM_ACTIVE_APP } from "../utils/constants";

const useActiveApp = () => {
	const [searchParams, setSearchParam] = useSearchParams();

	const activeApp = searchParams.get(SEARCH_PARAM_ACTIVE_APP);

	const setActiveApp = React.useCallback(
		(appId: string) => {
			Object.fromEntries(searchParams.entries());
			setSearchParam({ ...Object.fromEntries(searchParams.entries()), app: appId });
		},
		[searchParams, setSearchParam]
	);

	return { activeApp, setActiveApp };
};

export default useActiveApp;
