import { updateUserData, UserApplicationData, UserData } from "../../services/users";

interface UpdateUserParams {
	token: string;
	user: UserApplicationData;
	userApplication: any;
	requestType?: "application" | "markets";
}

export const updateUser = async ({ user, token, userApplication, requestType = "application" }: UpdateUserParams) => {
	try {
		const { data: updatedData } = await updateUserData(token, userApplication, user, requestType);
		return updatedData;
	} catch (err: any) {
		if (err?.response.status === 404) {
			console.log(err);
		}
		if (
			err?.response &&
			err?.response.data &&
			err?.response.data.errors &&
			err?.response.data.errors[0] &&
			err?.response.data.errors[0].message
		) {
			throw new Error(err?.response.data.errors[0].message);
		}
		throw new Error(err?.response.data.message);
	}
};

export enum userType {
	all = "All users",
	readOnly = "Read Only",
	maintainer = "Maintainer",
	admin = "Admin",
}
