import * as React from "react";
import dayjs from "dayjs";
import parse from "html-react-parser";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlElement, ControlProps } from "@jsonforms/core";
import FormField from "@ingka/form-field";
import Tooltip from "@ingka/tooltip";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel } from "@mui/material";

import { datePickerInputFieldStyles } from "../../styles";
import { findAndTransformHTMLString } from "../utils";

interface InputProps extends ControlProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const TimeFieldControl: React.FC<InputProps> = ({
	data,
	handleChange,
	path,
	description,
	enabled,
	label,
	uischema,
	errors,
	required,
}) => {
	const onChange = React.useCallback(
		(val) => {
			handleChange(path, dayjs(val).format("HH:mm:00").toString());
		},
		[handleChange, path]
	);

	const errMessage =
		required && errors.length
			? `${label} ${errors}`
			: required
			? `${label} is a required property`
			: `${label} ${errors}`;

	const timeField = React.useMemo(
		() => (
			<>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<InputLabel
						htmlFor="date-input"
						sx={{
							fontSize: "0.875rem",
							lineHeight: "1.571",
							color: "rgb(72, 72, 72)",
							letterSpacing: "0",
							// eslint-disable-next-line quotes
							fontFamily: `"Noto IKEA", "Noto Sans", "Roboto", "Open Sans", "sans-serif"`,
						}}
					>
						{label}
					</InputLabel>
					<TimeField
						label=""
						disabled={!enabled}
						format="HH:mm"
						value={data ? dayjs(new Date(`2020-01-01T${data}`)) : null}
						onChange={(newValue) => onChange(newValue)}
						variant="outlined"
						slotProps={{
							textField: {
								error: false,
								sx: {
									...datePickerInputFieldStyles,
									fieldset: {
										margin: 0,
									},
								},
							},
						}}
					/>
				</LocalizationProvider>
			</>
		),
		[label, enabled, data, onChange]
	);
	return (
		<>
			<FormField
				fieldHelper={{
					msg: uischema?.subText ? <div>{parse(findAndTransformHTMLString(uischema.subText))}</div> : "",
					id: "input-control-msg",
				}}
				valid={required ? !errors && data : !errors}
				shouldValidate={errors.length > 0 || (required && !data)}
				validation={{
					id: "error-msg-id",
					msg: errMessage,
					type: "error",
				}}
			>
				{description ? <Tooltip tooltipText={description}>{timeField}</Tooltip> : timeField}
			</FormField>
		</>
	);
};

export default withJsonFormsControlProps(TimeFieldControl);
