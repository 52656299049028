import React, { ReactElement } from "react";
import { useNavigate } from "react-router";

import Layout from "./Layout";
import AdminName from "./StepsComponents/AdminName";
import ApplicationName from "./StepsComponents/ApplicationName";
import SelectRole from "./StepsComponents/SelectRole";
import Dashboard from "./StepsComponents/Dashboard";
import RequestOverview from "./StepsComponents/RequestOverview";
import useApplicationForm from "../../hooks/useApplicationForm";
import { ApiResponseMessages } from "../Schemas/constants";
import useStore, { setUserSelector } from "../../store";
import useRequestCreateNewApplication from "../../hooks/useRequestCreateNewApplication";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";
import { ROUTE_NEW_USER } from "../../utils/constants";

export interface CreateNewApplicationSteps {
	index: number;
	id: string;
	label: string;
	element: ReactElement<any, any>;
	skipable: boolean;
	isValid: boolean;
}

const CreateNewApplicationForm = () => {
	const [activeStep, setActiveStep] = React.useState<number>(0);
	const navigate = useNavigate();
	const [toast, setToast] = React.useState<string>("");
	const { value, valid, validation, handleChange, shouldValidate } = useApplicationForm();

	const { requestCreateApplication, loading: loadingCreateNewApp } = useRequestCreateNewApplication();
	const { getUser, loading: userLoading, data: userData } = useGetLoggedInUser();

	const setUser = useStore(setUserSelector);

	const onCreateApplication = React.useCallback(async () => {
		if (value.hasReadDocumentation) {
			const createApplicationResponse = await requestCreateApplication(value);
			if (createApplicationResponse.toLowerCase() === ApiResponseMessages.Success.toLowerCase()) {
				setToast("Successfully requested new application");
				await getUser();
				navigate(ROUTE_NEW_USER);
			} else {
				console.log(createApplicationResponse);
				setToast(createApplicationResponse || "Something went wrong while creating application.");
			}
		}
	}, [getUser, navigate, requestCreateApplication, value]);

	React.useEffect(() => {
		if (userData) {
			setUser(userData);
		}
	}, [userData, setUser]);

	const CREATE_NEW_APPLICATION_STEPS: CreateNewApplicationSteps[] = [
		{
			index: 0,
			id: "step1",
			label: "Admin Name",
			element: (
				<AdminName
					value={value}
					valid={valid}
					shouldValidate={shouldValidate}
					validation={validation}
					handleChange={handleChange}
				/>
			),
			skipable: false,
			isValid: !!valid.hasReadDocumentation && value.email !== "",
		},
		{
			index: 1,
			id: "step2",
			label: "Application name and ID",
			element: (
				<ApplicationName
					value={value}
					valid={valid}
					shouldValidate={shouldValidate}
					validation={validation}
					handleChange={handleChange}
				/>
			),
			skipable: false,
			isValid: valid.appName,
		},
		{
			index: 2,
			id: "step3",
			label: "Required different roles",
			element: (
				<SelectRole
					value={value}
					valid={valid}
					shouldValidate={shouldValidate}
					validation={validation}
					handleChange={handleChange}
				/>
			),
			skipable: true,
			isValid: true,
		},
		{
			index: 3,
			id: "step4",
			label: "Allow dashboard",
			element: (
				<Dashboard
					value={value}
					valid={valid}
					shouldValidate={shouldValidate}
					validation={validation}
					handleChange={handleChange}
				/>
			),
			skipable: true,
			isValid: true,
		},
		{
			index: 4,
			id: "step5",
			label: "Send request",
			element: <RequestOverview value={value} />,
			skipable: false,
			isValid: true,
		},
	];

	const isFinalStep = React.useMemo(
		() => activeStep === CREATE_NEW_APPLICATION_STEPS.length - 1,
		[CREATE_NEW_APPLICATION_STEPS.length, activeStep]
	);

	const onClickNext = React.useCallback(() => {
		if (!isFinalStep) {
			setActiveStep((prev) => prev + 1);
		} else onCreateApplication();
	}, [isFinalStep, onCreateApplication]);

	const onBack = React.useCallback(() => {
		if (activeStep !== 0) {
			setActiveStep((prev) => prev - 1);
		}
	}, [activeStep]);

	return (
		<Layout
			steps={CREATE_NEW_APPLICATION_STEPS}
			activeStep={activeStep}
			onClickNext={onClickNext}
			isFinalStep={isFinalStep}
			onBack={onBack}
			userLoading={userLoading}
			loadingCreateNewApp={loadingCreateNewApp}
			toast={toast}
			setToast={setToast}
		/>
	);
};

export default CreateNewApplicationForm;
