import { useState, useEffect } from "react";
import { breakpointM, breakpointL, breakpointXl } from "@ingka/variables/design-tokens";
import React from "react";

/**
 * In @ingka/variables": "^2.1.1
 * breakpointM = 37.5em
 * breakpointL = 56.25em
 * breakpointXl = 75em
 */

// 1em = 16px
const EM_PX_RATIO = 16;

interface Sizes {
	innerHeight: number;
	innerWidth: number;
	outerHeight: number;
	outerWidth: number;
}

interface ResponsiveSizes {
	isBigDesktop: boolean;
	isDesktop: boolean;
	isTablet: boolean;
	isMobile: boolean;
}

const getSize = (): Sizes => {
	if (typeof window === "undefined") {
		return {} as Sizes;
	}

	const { innerHeight, innerWidth, outerHeight, outerWidth } = window;

	return {
		innerHeight,
		innerWidth,
		outerHeight,
		outerWidth,
	};
};

const convertEmToPixels = (em: string): number => {
	const emToNumber = parseFloat(em);

	return Math.round(emToNumber * EM_PX_RATIO);
};

export const breakpoints = {
	tablet: `@media (min-width: ${breakpointM})`,
	desktop: `@media (min-width: ${breakpointL})`,
	bigDesktop: `@media (min-width: ${breakpointXl})`,
};

const mapSizesToProps = ({ innerWidth }: Sizes): ResponsiveSizes => {
	const breakpointMInPixels = convertEmToPixels(breakpointM);
	const breakpointLInPixels = convertEmToPixels(breakpointL);
	const breakpointXLInPixels = convertEmToPixels(breakpointXl);

	return {
		isMobile: innerWidth < breakpointMInPixels,
		isTablet: innerWidth >= breakpointMInPixels && innerWidth < breakpointLInPixels,
		isDesktop: innerWidth >= breakpointLInPixels && innerWidth < breakpointXLInPixels,
		isBigDesktop: innerWidth >= breakpointXLInPixels,
	};
};

const useWindowSize = (): ResponsiveSizes => {
	const [windowSize, setWindowSize] = useState(mapSizesToProps(getSize()));

	const handleResize = React.useCallback((): void => {
		if (typeof window !== "undefined") {
			setWindowSize(mapSizesToProps(getSize()));
		}
	}, []);

	useEffect((): (() => void) => {
		if (typeof window !== "undefined") {
			window.addEventListener("resize", handleResize);
		}
		return (): void => {
			if (typeof window !== "undefined") {
				window.removeEventListener("resize", handleResize);
			}
		};
	}, [handleResize]);

	return windowSize;
};

export default useWindowSize;
