import { AxiosResponse } from "axios";
import { Values } from "../../components/Schemas/types";
import axios from "../../configs/axios";

import { AuthHeader } from "../../types";
import {
	NewApplicationData,
	ApplicationRequestData,
	FlaggaApplicationData,
	NewApplicationRequestData,
	NewApplicationResponseData,
	UiSchemaListData,
	UiSchemaQuery,
	UpdateUiSchemasRequestData,
	UpdateUiSchemasResponsetData,
	UpdateApplicationData,
	NewApplicationByUser,
	DeclineApplicationResponseData,
	ApplicationRequestResponseData,
	SchemaNameList,
} from "./types";

interface QueryParams {
	getAll?: boolean;
	header?: AuthHeader;
}

export const getApplications = async ({ header, getAll }: QueryParams) => {
	const data: AxiosResponse<FlaggaApplicationData, ApplicationRequestData> = await axios.get("/applications", {
		...header,
		params: {
			getAll,
		},
	});
	return data;
};

export const addApplication = async (header: AuthHeader, value: NewApplicationData) => {
	const data: AxiosResponse<NewApplicationResponseData, NewApplicationRequestData> = await axios.post(
		"/application",
		{ ...value },
		{
			...header,
		}
	);
	return data;
};

export const updateApplication = async (header: AuthHeader, application: string, value: UpdateApplicationData) => {
	const data: AxiosResponse<NewApplicationResponseData, NewApplicationRequestData> = await axios.patch(
		`/application/${application}`,
		{ ...value },
		{
			...header,
		}
	);
	return data;
};

export const getUiSchemaList = async (header: AuthHeader, application: string, queryParams?: UiSchemaQuery) => {
	const data: AxiosResponse<UiSchemaListData, AuthHeader> = await axios.get(`/application/${application}/schemas`, {
		...header,
		params: queryParams,
	});
	return data;
};

export const updateSchemas = async (header: AuthHeader, value: Values, application?: string, reason?: string) => {
	const data: AxiosResponse<UpdateUiSchemasResponsetData, UpdateUiSchemasRequestData> = await axios.post(
		`/application/${application}/schema`,
		{ ...value, reason },
		{
			...header,
		}
	);
	return data;
};

export const getApplicationAccess = async (header: AuthHeader, application: string, markets: string[]) => {
	const data: AxiosResponse<FlaggaApplicationData, AuthHeader> = await axios.get(
		`/application/${application}/access/request`,
		{
			...header,
			...(markets.length && { params: { markets: markets.join(",") } }),
		}
	);
	return data;
};

export const getApplicationRequests = async (header: AuthHeader) => {
	const data: AxiosResponse<ApplicationRequestResponseData, AuthHeader> = await axios.get("/application/requests", {
		...header,
	});
	return data;
};

export const declineApplicationRequest = async (header: AuthHeader, requestId: string, reason: string) => {
	const data: AxiosResponse<DeclineApplicationResponseData, AuthHeader> = await axios.post(
		`/application/request/${requestId}/decline`,
		{ reason },
		{
			...header,
		}
	);
	return data;
};

export const requestNewApplicationByUser = async (header: AuthHeader, value: NewApplicationByUser) => {
	const data: AxiosResponse<NewApplicationResponseData, AuthHeader> = await axios.post(
		"/application/request",
		{ ...value },
		{
			...header,
		}
	);
	return data;
};

export const getApplicationFromOtherEnv = async (header: AuthHeader, application: string, env: string) => {
	const data: AxiosResponse<SchemaNameList, ApplicationRequestData> = await axios.get(
		`/application/${application}/check/env/${env}`,
		{
			...header,
		}
	);
	return data;
};

export const importSchemaAndUISchema = async (
	header: AuthHeader,
	application: string,
	schemaList: string[],
	env: string
) => {
	const data: AxiosResponse<FlaggaApplicationData, ApplicationRequestData> = await axios.post(
		`/application/${application}/schema/env/import`,
		{ schemaList, env },
		{
			...header,
		}
	);
	return data;
};

export const deleteApplication = async (header: AuthHeader, application: string, delete_backup: boolean) => {
	const data: AxiosResponse<FlaggaApplicationData, ApplicationRequestData> = await axios.delete(
		`/application/${application}?delete_backup=${delete_backup}`,
		{
			...header,
		}
	);
	return data;
};
