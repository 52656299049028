import * as React from "react";
import Onboarding from "../components/Onboarding";
import RequireAuth from "../components/RequireAuth";

const onboarding: React.FC = () => {
	return (
		<RequireAuth>
			<Onboarding />
		</RequireAuth>
	);
};

export default onboarding;
