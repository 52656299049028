import * as React from "react";
import Layout from "./Layout";
import { useIsFlaggaAdmin } from "../../../hooks/useIsFlaggaAdmin";
import useStore, { activeApplicationSelector, isApplicationAdminSelector, userSelector, showManageScheduledChangesModalSelector, setShowManageScheduledChangesModalSelector } from "../../../store";
import { getScheduledChanges, removeScheduledChanges, updateScheduledChanges } from "../../../services/schemas";
import useAuthentication from "../../../hooks/useAuthentication";

const ManageScheduledChangesModal: React.FC = () => {
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const { getToken } = useAuthentication();
	const activeApplication = useStore(activeApplicationSelector);
	const [scheduledChanges, setScheduledChanges] = React.useState([]);
	const [toastMessage, setToastMessage] = React.useState("");
	const [updateScheduledChangesLoading, setUpdateScheduledChangesLoading] = React.useState(false);
	const [getScheduledChangesLoading, setGetScheduledChangesLoading] = React.useState(false);
	const isAppAdmin = useStore(isApplicationAdminSelector);
	const currentUser = useStore(userSelector);
	const setShowModal = useStore(setShowManageScheduledChangesModalSelector);
	const showModal = useStore(showManageScheduledChangesModalSelector);

	const fetchScheduledChanges = React.useCallback(async () => {
		setGetScheduledChangesLoading(true);
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		if (activeApplication) {
			const { data } = await getScheduledChanges(authHeader, activeApplication.id);
			if (data) {
				data.data.sort((a: any, b: any) => a.updateAt._seconds - b.updateAt._seconds);
				setScheduledChanges(data.data);
			} else {
				setScheduledChanges([]);
			}
			setGetScheduledChangesLoading(false);
		}
	}, [activeApplication, getToken]);

	React.useEffect(() => {
		try {
			if (showModal) {
				fetchScheduledChanges();
			} else {
				setScheduledChanges([]);
			}
		} catch (err) {
			setGetScheduledChangesLoading(false);
			console.log(err);
		}
	}, [showModal, getToken, fetchScheduledChanges]);

	const onRemoveScheduledChanges = React.useCallback(
		async (scheduledUpdateId) => {
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				if (activeApplication) {
					const response = await removeScheduledChanges(authHeader, activeApplication.id, scheduledUpdateId);
					if (response.status === 200) {
						setToastMessage("Scheduled change was removed successfully");
						setShowModal(false);
					} else {
						setToastMessage("There was an error while removing scheduled changes. Please try again");
					}
				}
			} catch (err: any) {
				console.log(err);
				let errMsg = err.response?.data?.message;
				setToastMessage(errMsg);
			}
		},
		[activeApplication, getToken, setShowModal]
	);

	const onUpdateScheduledChanges = React.useCallback(
		async (scheduledUpdateId: string, updatedDate: string) => {
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				if (activeApplication) {
					const response = await updateScheduledChanges(
						authHeader,
						activeApplication.id,
						scheduledUpdateId,
						updatedDate
					);
					if (response.status === 200) {
						setToastMessage("Date was updated successfully");
						setShowModal(false);
					} else {
						setToastMessage("There was an error while updating date for scheduled changes. Please try again");
					}
					setUpdateScheduledChangesLoading(false);
				}
			} catch (err: any) {
				setUpdateScheduledChangesLoading(false);
				console.log(err);
				let errMsg = err.response?.data?.message;
				setToastMessage(errMsg);
			}
		},
		[activeApplication, getToken, setShowModal]
	);

	return (
		<Layout
			currentUser={currentUser}
			isAdmin={isAppAdmin || isFlaggaAdmin}
			isFlaggaAdmin={isFlaggaAdmin}
			showModal={showModal}
			setShowModal={setShowModal}
			scheduledChanges={scheduledChanges}
			onRemoveScheduledChanges={onRemoveScheduledChanges}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			onUpdateScheduledChanges={onUpdateScheduledChanges}
			updateScheduledChangesLoading={updateScheduledChangesLoading}
			setUpdateScheduledChangesLoading={setUpdateScheduledChangesLoading}
			getScheduledChangesLoading={getScheduledChangesLoading}
		/>
	);
};

export default ManageScheduledChangesModal;
