import * as React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

import useStore, {
	activeApplicationSelector,
	applicationsSelector,
	isApplicationAdminSelector,
	marketsSelector,
	setActiveMarketSelector,
	setApplicationsLoadingSelector,
	setApplicationsSelector,
	setIsApplicationAdminSelector,
	setMarketsLoadingSelector,
	setMarketsSelector,
	userSelector,
	activeMarketSelector,
} from "../../store";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import Layout from "./Layout";
import useGetApplications from "../../hooks/useGetApplications";
import useGetMarkets from "../../hooks/useGetMarkets";
import useWindowSize from "../../hooks/useWindowSize";
import useGetScheduledChanges from "../../hooks/useGetScheduledChanges";

const Header = () => {
	const activeApplication = useStore(activeApplicationSelector);
	const currentUser = useStore(userSelector);
	const applications = useStore(applicationsSelector);
	const markets = useStore(marketsSelector);
	const isApplicationAdmin = useStore(isApplicationAdminSelector);
	const setApplications = useStore(setApplicationsSelector);
	const setActiveMarket = useStore(setActiveMarketSelector);
	const setIsApplicationAdmin = useStore(setIsApplicationAdminSelector);
	const setmarkets = useStore(setMarketsSelector);
	const setMarketsLoading = useStore(setMarketsLoadingSelector);
	const setApplicationsLoadig = useStore(setApplicationsLoadingSelector);
	const activeMarket = useStore(activeMarketSelector);

	const {
		data: applicationsData,
		loading: applicationsLoading,
		error: applicationsError,
		getApplicationsData,
	} = useGetApplications();
	const { data: marketsData, loading: marketsLoading, error: marketError, getMarketsData } = useGetMarkets();
	const isAuthenticated = useIsAuthenticated();
	const { pathname } = useLocation();
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const { isMobile, isTablet, isDesktop, isBigDesktop } = useWindowSize();

	const [showProfileModal, setShowProfileModal] = React.useState(false);
	const [showAppsSettingseModal, setShowAppsSettingsModal] = React.useState(false);
	const [toastMessage, setToastMessage] = React.useState<string>("");

	const { data: scheduledChanges } = useGetScheduledChanges(activeApplication);
	const [showScheduledChangesHeader, setShowScheduledChangesHeader] = React.useState(false);

	React.useEffect(() => {
		getApplicationsData();
	}, [getApplicationsData]);

	React.useEffect(() => {
		if (activeApplication?.id) {
			getMarketsData(activeApplication.id);
		}
	}, [activeApplication?.id, getApplicationsData, getMarketsData]);

	React.useEffect(() => {
		setMarketsLoading(marketsLoading);
		setApplicationsLoadig(applicationsLoading);
	}, [applicationsLoading, marketsLoading, setApplicationsLoadig, setMarketsLoading]);

	const [showMenuModal, setShowMenuModal] = React.useState(false);

	React.useEffect(() => {
		if (applicationsData && !applicationsLoading) {
			setApplications(applicationsData);
		} else {
			if (applicationsError) {
				setToastMessage(applicationsError);
			}
			setApplications(null);
		}
	}, [applicationsData, applicationsError, applicationsLoading, setApplications]);

	React.useEffect(() => {
		if (marketsData && !marketsLoading) {
			setmarkets(marketsData);
			setIsApplicationAdmin(marketsData.isAdmin);
		} else {
			if (marketError) {
				setToastMessage(marketError);
			}
			setmarkets(null);
			setIsApplicationAdmin(false);
			setActiveMarket(null);
		}
	}, [marketError, marketsData, marketsLoading, setActiveMarket, setIsApplicationAdmin, setmarkets]);

	React.useEffect(() => {
		if (scheduledChanges && scheduledChanges.data && activeMarket && activeApplication) {
			//if user is applicaiton admin
			if (currentUser?.user?.applications?.[activeApplication.id]?.permissions === "admin") {
				let showBanner = false;
				for (let i = 0; i < scheduledChanges.data.length; i++) {
					if (scheduledChanges.data[i].markets.includes(activeMarket)) {
						showBanner = true;
						break;
					}
				}
				setShowScheduledChangesHeader(showBanner);
			} else if (
				currentUser?.user?.applications?.[activeApplication.id]?.permissions === "maintainer" &&
				currentUser?.user?.applications?.[activeApplication.id]?.markets
			) {
				let showBanner = false;
				let assignedUserMarketKeys = Object.keys(currentUser?.user?.applications?.[activeApplication.id]?.markets);
				for (let i = 0; i < assignedUserMarketKeys.length; i++) {
					for (let k = 0; k < scheduledChanges.data.length; k++) {
						if (
							scheduledChanges.data[k].markets.includes(assignedUserMarketKeys[i]) &&
							scheduledChanges.data[k].markets.includes(activeMarket) &&
							scheduledChanges.data[k].uiSchemaName ===
								currentUser?.user?.applications?.[activeApplication.id]?.markets[assignedUserMarketKeys[i]]
						) {
							showBanner = true;
							break;
						}
					}
					if (showBanner) {
						break;
					}
				}

				setShowScheduledChangesHeader(showBanner);
			}
		} else {
			setShowScheduledChangesHeader(false);
		}
	}, [scheduledChanges, activeMarket, activeApplication, currentUser?.user]);

	return (
		<Layout
			isAuthenticated={isAuthenticated}
			activeApplication={activeApplication}
			isFlaggaAdmin={isFlaggaAdmin}
			applications={applications || undefined}
			applicationsLoading={applicationsLoading}
			marketsLoading={marketsLoading}
			marketsData={markets || undefined}
			isApplicationAdmin={isApplicationAdmin}
			showProfileModal={showProfileModal}
			showMenuModal={showMenuModal}
			setShowProfileModal={setShowProfileModal}
			showAppsSettingseModal={showAppsSettingseModal}
			setShowAppsSettingsModal={setShowAppsSettingsModal}
			setShowMenuModal={setShowMenuModal}
			currentUser={currentUser || undefined}
			isMobile={isMobile}
			isTablet={isTablet}
			isDesktop={isDesktop}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			pathname={pathname}
			isBigDesktop={isBigDesktop}
			showScheduledChangesHeader={showScheduledChangesHeader}
		/>
	);
};

export default Header;
