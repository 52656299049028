import FormField from "@ingka/form-field";
import * as React from "react";
import { ActionsWrapper, LoadingWrapper, SaveButtonWrapper, StyledModalHeader, IconButtonWrapper } from "../styles";
import Loading, { LoadingBall } from "@ingka/loading";
import Select, { Option as SelectOption } from "@ingka/select";
import Button from "@ingka/button";
import Toast from "@ingka/toast";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import TextArea from "@ingka/text-area";
import { SchemaService } from "../ui-schema-builder/core/api/schemaService";
import { JsonFormsEditor, defaultSchemaDecorators, defaultSchemaProviders } from "../ui-schema-builder";
import styled from "styled-components";
import Tooltip from "@ingka/tooltip";
import NewUiSchemaModal from "./newUiSchemaModal";
import plus from "@ingka/ssr-icon/paths/plus";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import { ApplicationUiSchema } from "../../services/applications/types";
import WarningModal from "../WarningModal/WarningModal";
import arrowCloudOut from "@ingka/ssr-icon/paths/arrow-cloud-out";
import ImportSchemaModal from "../Schema/ImportSchemaModal";
import Pill from "@ingka/pill";

export const StyledFormField = styled(FormField)`
	/* margin-bottom: 2rem; */
`;

const SchemaSelectWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 2.5rem;
`;

const SchemaHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const StyledModal = styled(Modal)`
	.modal-footer {
		display: none;
	}

	.prompt__content {
		padding: 0;
	}

	.modal-header {
		display: none;
	}

	.prompt__heading {
		display: none;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
`;

interface SchemaBuilderLayout {
	loading: boolean;
	selectedUiSchema: string;
	setSelectedUiSchema: (value: string) => void;
	setToastMessage: (value: string) => void;
	uiSchemaNames: string[] | undefined;
	setNewUISchemaName: (value: string) => void;
	hasChanged: boolean;
	setShowReasonModal: (val: boolean) => void;
	saveBtnLoading: boolean;
	onSave: () => void;
	toastMessage: string;
	showReasonModal: boolean;
	setReason: (val: string) => void;
	schemaService: SchemaService;
	setCurrentUiSchema: (val: any) => void;
	showNewUiSchemaModal: boolean;
	setShowNewUiSchemaModal: (val: boolean) => void;
	newUiSchemaCurrentName: string;
	setNewUiSchemaCurrentName: (val: string) => void;
	currentUiSchemas: ApplicationUiSchema[];
	setCurrentUiSchemas: (val: any) => void;
	showRemoveSchemaModal: boolean;
	setShowRemoveSchemaModal: (val: boolean) => void;
	onRemoveSchema: () => void;
	updateSchemaLoading: boolean;
	schemasLengthChanged: boolean;
	preliminarySelectedUiSchema: string;
	setPreliminarySelectedUiSchema: (value: string) => void;
	showSaveChangesModal: boolean;
	setShowSaveChangesModal: (val: boolean) => void;
	originalUiSchemas: ApplicationUiSchema[];
	onSaveNew: () => void;
	currentUiSchema: any;
	showAddChangesModal: boolean;
	setShowAddChangesModal: (value: boolean) => void;
	setNewSchemaAdded: (value: boolean) => void;
	showImportSchemaModal: boolean;
	setShowImportSchemaModal: (value: boolean) => void;
	setImportedSchema: (val: boolean) => void;
	onCancelChanges: () => void;
	setForceReset: (value: boolean) => void;
	forceReset: boolean;
}

const Layout: React.FC<SchemaBuilderLayout> = ({
	loading,
	selectedUiSchema,
	setSelectedUiSchema,
	uiSchemaNames,
	setNewUISchemaName,
	schemaService,
	setCurrentUiSchema,
	hasChanged,
	setShowReasonModal,
	saveBtnLoading,
	toastMessage,
	setToastMessage,
	showReasonModal,
	onSave,
	setReason,
	showNewUiSchemaModal,
	setShowNewUiSchemaModal,
	currentUiSchemas,
	setCurrentUiSchemas,
	showRemoveSchemaModal,
	setShowRemoveSchemaModal,
	onRemoveSchema,
	updateSchemaLoading,
	schemasLengthChanged,
	preliminarySelectedUiSchema,
	setPreliminarySelectedUiSchema,
	showSaveChangesModal,
	setShowSaveChangesModal,
	originalUiSchemas,
	onSaveNew,
	currentUiSchema,
	showAddChangesModal,
	setShowAddChangesModal,
	setNewSchemaAdded,
	showImportSchemaModal,
	setShowImportSchemaModal,
	setImportedSchema,
	onCancelChanges,
	setForceReset,
	forceReset
}) => {
	if (loading || updateSchemaLoading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Schemas and UI-Schemas" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}
	return (
		<>
			<SchemaHeaderWrapper>
				<h2>Layout Builder</h2>
				<Tooltip tooltipText={"Add UI-schema"}>
					<Button
						ssrIcon={plus}
						onClick={() => {
							setShowNewUiSchemaModal(true);
						}}
						iconOnly
						type="emphasised"
						small
					/>
				</Tooltip>
				
			</SchemaHeaderWrapper>
			<SchemaSelectWrapper>
				<>
					<FormField shouldValidate={false}>
						<Select
							data-testid="uischema"
							id="uischema"
							label="Select Ui Schema to modify"
							value={selectedUiSchema}
							onChange={({ target: { value } }) => {
								if (schemasLengthChanged || hasChanged) {
									setShowSaveChangesModal(true);
									setPreliminarySelectedUiSchema(value);
								} else {
									setSelectedUiSchema(value);
									setNewSchemaAdded(false);
								}
							}}
						>
							{uiSchemaNames?.map((name) => (
								<SelectOption key={name} value={name} name={name} />
							))}
						</Select>
					</FormField>
				</>
			</SchemaSelectWrapper>

			<JsonFormsEditor
				schemaService={schemaService}
				schemaProviders={defaultSchemaProviders}
				schemaDecorators={defaultSchemaDecorators}
				setCurrentUiSchema={setCurrentUiSchema}
				setForceReset={setForceReset}
				forceReset={forceReset}
			/>
			<ActionsWrapper>
				<SaveButtonWrapper>	
					<Button
						data-testid="save-ui-schema"
						id="save-ui-schema"
						text="Save Changes"
						type="emphasised"
						disabled={!hasChanged}
						onClick={() => {
							if (currentUiSchema) {
								setShowReasonModal(true);
							} else {
								setShowAddChangesModal(true);
							}
						}}
						loading={saveBtnLoading}
					/>
					<Button
						data-testid="cancel-ui-schema"
						id="cancel-ui-schema"
						text="Cancel"
						type="secondary"
						disabled={!hasChanged}
						onClick={() => onCancelChanges()}
					/>
				</SaveButtonWrapper>
				<IconButtonWrapper>
					<Tooltip position="top" tooltipText="Import schema & ui-schema">
						<Pill
							data-testid="importSchema"
							ssrIcon={arrowCloudOut}
							onClick={() => setShowImportSchemaModal(true)}
							iconOnly
						/>
					</Tooltip>
					<Tooltip tooltipText={"Remove UI-schema"}>
						<Pill
							onClick={() => setShowRemoveSchemaModal(true)}
							iconOnly
							ssrIcon={trashCan}
							disabled={selectedUiSchema === "ui-schema"}
						/>
					</Tooltip>
				</IconButtonWrapper>
			</ActionsWrapper>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>

			<Modal
				focusLockProps={{ locked: false }}
				visible={showReasonModal}
				escapable={false}
				handleCloseBtn={() => setShowReasonModal(false)}
			>
				<Prompt
					data-testid="reason-for-changes-modal"
					id="reason-for-changes-modal"
					title=""
					titleId={"modalTitleId"}
					header={<StyledModalHeader title="Reason for changes" />}
					footer={
						<ModalFooter>
							<Button
								id="reason-cancel-button"
								text="Cancel"
								type="secondary"
								onClick={() => setShowReasonModal(false)}
							/>
							<Button id="reason-save-button" text="Save changes" type="emphasised" onClick={() => onSave()} />
						</ModalFooter>
					}
				>
					<p>Please write the reason for changes (optional)</p>
					<TextArea id="reason-text-area" label="" onChange={(ev) => setReason(ev.target.value)} rows={6} />
				</Prompt>
			</Modal>
			<NewUiSchemaModal
				showNewUiSchemaModal={showNewUiSchemaModal}
				setShowNewUiSchemaModal={setShowNewUiSchemaModal}
				setNewUISchemaName={setNewUISchemaName}
				uiSchemaNames={uiSchemaNames}
				currentUiSchemas={currentUiSchemas}
				setCurrentUiSchemas={setCurrentUiSchemas}
				setSelectedUiSchema={setSelectedUiSchema}
				setNewSchemaAdded={setNewSchemaAdded}
			/>
			<WarningModal
				modalId="remove-schema-modal"
				modalTitle="Warning"
				showWarningModal={showRemoveSchemaModal}
				setShowWarningModal={setShowRemoveSchemaModal}
				children={
					<>
						<p>
							Are you sure you want to remove <strong>{selectedUiSchema}</strong>?
						</p>
						<ButtonWrapper>
							<Button
								id="remove-cancel-button"
								text="Cancel"
								type="emphasised"
								small
								onClick={() => setShowRemoveSchemaModal(false)}
							/>
							<Button id="remove-schema-button" text="Remove" type="secondary" small onClick={() => onRemoveSchema()} />
						</ButtonWrapper>
					</>
				}
				variant="cautionary"
				dismissable={true}
			/>
			<WarningModal
				modalId="warning-save-changes-schema-modal"
				modalTitle="Unsaved changes"
				showWarningModal={showSaveChangesModal}
				setShowWarningModal={setShowSaveChangesModal}
				children={
					<>
						<p>Please note that all unsaved changed will be lost</p>
						<ButtonWrapper>
							<Button
								id="no-save-changes-button"
								text="Proceed without saving"
								small
								type="secondary"
								onClick={() => {
									setShowSaveChangesModal(false);
									setSelectedUiSchema(preliminarySelectedUiSchema);
									setCurrentUiSchemas(originalUiSchemas);
									setNewSchemaAdded(false);
								}}
							/>
							<Button
								id="save-changes-button"
								text="Save changes"
								small
								type="emphasised"
								onClick={() => {
									if (currentUiSchema) {
										onSaveNew();
										setSelectedUiSchema(preliminarySelectedUiSchema);
									} else {
										setShowSaveChangesModal(false);
										setShowAddChangesModal(true);
									}
								}}
							/>
						</ButtonWrapper>
					</>
				}
				variant="cautionary"
				dismissable={true}
			/>
			<WarningModal
				modalId="warning-add-changes-schema-modal"
				modalTitle="No elements added"
				showWarningModal={showAddChangesModal}
				setShowWarningModal={setShowAddChangesModal}
				children={
					<>
						<p>To save ui schema, please add at least one element</p>
						<ButtonWrapper>
							<Button
								id="add-changes-button"
								text="OK"
								small
								type="emphasised"
								onClick={() => setShowAddChangesModal(false)}
							/>
						</ButtonWrapper>
					</>
				}
				variant="cautionary"
				dismissable={true}
			/>
			<ImportSchemaModal
				showModal={showImportSchemaModal}
				setShowModal={setShowImportSchemaModal}
				setToastMessage={setToastMessage}
				setImportedSchema={setImportedSchema}
			/>
		</>
	);
};

export default Layout;
