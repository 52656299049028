import * as React from "react";
import { GroupLayout as IGroupLayout, RendererProps } from "@jsonforms/core";
import styled from "styled-components";

import { ResolvedJsonFormsDispatch, useJsonForms, withJsonFormsLayoutProps } from "@jsonforms/react";
import { Paragraph, ElementWrapper } from "../../styles";
import { breakpointL } from "@ingka/variables/design-tokens";
import parse from "html-react-parser";
import { findAndTransformHTMLString } from "../../renderers/utils";

type GroupLayoutSchema = IGroupLayout & {
	subText?: string;
};

const StyledHeading = styled.h3`
	margin: 0 0 0.25rem 0;
`;

export const StyledDiv = styled.div`
	padding: 1.5rem 1.5rem 1.5rem 0;
	width: 75%;

	h2 {
		margin-bottom: 0.5rem;
		h2: {
			margin-top: 0.75rem;
		}
	}

	.group-layout {
		width: 100%;
	}

	@media (width < ${breakpointL}) {
		width: 100vw;
	}
`;

const GroupLayout: React.FC<RendererProps> = ({ visible, uischema, schema, path, enabled }) => {
	const { label, elements, subText } = uischema as GroupLayoutSchema;
	const { renderers, cells } = useJsonForms();

	if (!visible) {
		return null;
	}

	return (
		<StyledDiv className="group-layout">
			{label && <StyledHeading>{label}</StyledHeading>}
			{subText && <Paragraph>{parse(findAndTransformHTMLString(subText))}</Paragraph>}
			<ElementWrapper>
				{elements.map((child, index) => (
					<ResolvedJsonFormsDispatch
						renderers={renderers}
						cells={cells}
						uischema={child}
						schema={schema}
						path={path}
						key={`${path}-${index}`}
						enabled={enabled}
					/>
				))}
			</ElementWrapper>
		</StyledDiv>
	);
};

export default withJsonFormsLayoutProps(GroupLayout);
