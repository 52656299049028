import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import GroupLayout, { groupLayoutTester } from "./GroupLayout";
import VerticalLayout, { verticalLayoutTester } from "./VerticalLayout";
import HorizontalLayout, { horizontalLayoutTester } from "./HorizontalLayout";
import ArrayLayout, { arrayLayoutTester } from "./ArrayLayout";
import CollapsibleArrayLayout, { collapsibleArrayLayoutTester } from "./CollapsibleArrayLayout";
import PrimitiveArrayLayout, { primitiveArrayLayoutTester } from "./PremitiveArrayLayout";
import CategoryLayout, { categoryLayoutTester } from "./CategoryLayout.tsx";

const layouts: JsonFormsRendererRegistryEntry[] = [
	{ tester: groupLayoutTester, renderer: GroupLayout },
	{ tester: verticalLayoutTester, renderer: VerticalLayout },
	{ tester: horizontalLayoutTester, renderer: HorizontalLayout },
	{ tester: arrayLayoutTester, renderer: ArrayLayout },
	{ tester: collapsibleArrayLayoutTester, renderer: CollapsibleArrayLayout },
	{ tester: primitiveArrayLayoutTester, renderer: PrimitiveArrayLayout },
	{ tester: categoryLayoutTester, renderer: CategoryLayout },
];

export default layouts;
