import React from "react";
import _ from "lodash/fp";

import FormField from "@ingka/form-field";
import Select, { Option } from "@ingka/select";

import { schemaTypesArray } from "../../utils/constants";
import { SchemaType } from "../../utils/types";
import styled from "styled-components";

interface Props {
	type: SchemaType;
	onChange: (type: SchemaType) => void;
}

const SelectWrapper = styled.div`
	width: 10rem;
`;

const SchemaTypesSelect: React.FC<Props> = ({ type, onChange }) => {
	const arrayOptions = schemaTypesArray;
	return (
		<SelectWrapper>
			<FormField>
				<Select
					id={Math.random().toString()}
					label={"type"}
					onChange={(e: any) => onChange(e.target.value)}
					value={schemaTypesArray.find((data) => data.value === type)?.value}
				>
					{arrayOptions.map(({ value, label }, index) => (
						<Option value={value} name={label} key={index} />
					))}
				</Select>
			</FormField>
		</SelectWrapper>
	);
};

export default SchemaTypesSelect;
