import * as React from "react";
import styled from "styled-components";

import { colourSemanticPositive, colourSemanticNegative, colourNeutral5 } from "@ingka/variables/colours-css";

import { ApplicationData } from "../../store";
import FeaturesOverviewTable from "../FeaturesOverviewTable";
import { ContentWrapper } from "../styles";
import { CheckmarkCircle, CrossCircle, Incorrect } from "../../assets/icons";

interface DashboardLayoutProps {
	applicationsData?: ApplicationData[];
	applicationsLoading: boolean;
	isFlaggaAdmin: boolean;
}

const DashboardContainer = styled.div`
	grid-row: 1;
	grid-column: 1/14;
	text-overflow: ellipsis;
`;

const ApplicationsWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const DashboardLegend = styled.div`
	display: flex;
	justify-content: end;
	margin-bottom: 0.5rem;
`;

const DashboardLegendItem = styled.div`
	display: flex;
	gap: 0.2rem;
	align-items: center;
	margin: 0.5rem;
`;

const Layout: React.FC<DashboardLayoutProps> = ({ applicationsData, applicationsLoading, isFlaggaAdmin }) => {
	return (
		<ContentWrapper>
			<DashboardContainer>
				<FeaturesOverviewTable applicationsData={applicationsData} applicationsLoading={applicationsLoading} />
				<DashboardLegend>
					<DashboardLegendItem>
						<CheckmarkCircle height={24} width={24} colour={colourSemanticPositive} /> - Enabled
					</DashboardLegendItem>
					<DashboardLegendItem>
						<CrossCircle height={24} width={24} colour={colourSemanticNegative} /> - Disabled
					</DashboardLegendItem>
					<DashboardLegendItem>
						<Incorrect height={24} width={24} colour={colourNeutral5} /> - Not launched
					</DashboardLegendItem>
				</DashboardLegend>
			</DashboardContainer>
		</ContentWrapper>
	);
};

export default Layout;
