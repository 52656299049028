import * as React from "react";

import HomePageLayout from "../HomePageLayout";

interface FlaggaContentProps {
	showAdminLayout: boolean;
}

const FlaggaContent: React.FC<FlaggaContentProps> = ({ showAdminLayout }) => {
	return <HomePageLayout showAdminLayout={showAdminLayout} />;
};

export default FlaggaContent;
