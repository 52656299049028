/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import styled from "styled-components";

import Button from "@ingka/button";
import Text from "@ingka/text";
import ListView, { ListViewItem } from "@ingka/list-view";
import laptop from "@ingka/ssr-icon/paths/laptop";
import cross from "@ingka/ssr-icon/paths/cross";
import arrowLeftFromBase from "@ingka/ssr-icon/paths/arrow-left-from-base";
import Modal, { Sheets, ModalBody, ModalFooter, Prompt } from "@ingka/modal";
import documentUpload from "@ingka/ssr-icon/paths/document-upload";
import Toast from "@ingka/toast";
import { colourSemanticInformative, colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";
import person from "@ingka/ssr-icon/paths/person";
import mail from "@ingka/ssr-icon/paths/mail";
import Tooltip from "@ingka/tooltip";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import Loading, { LoadingBall } from "@ingka/loading";

import { LoadingWrapper, StyledCommercialMessage, StyledDivider, StyledModalHeader } from "../styles";
import { ROUTE_LOGOUT, UserPermissions } from "../../utils/constants";

import { User, UserApplication } from "../../store/types";
import { isUserHasMarkets } from "../../utils/common";
import { ModalButtonWrapper } from "../styles";
import FlagsRow from "../FLagsRow";
import UserApplicationOverview from "./UserApplicationOverview";
import useSelector, { userSelector } from "../../store";

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1rem;
`;

const StyledListView = styled(ListView)`
	padding-left: 0rem;
`;

const CustomModalHeader = styled.div`
	background-color: ${colourSemanticInformative};
	.btn__inner {
		background-color: ${colourSemanticInformative} !important;
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
`;

const StyledHeader = styled.span`
	color: ${colourStaticIkeaBrandYellow};
	font-weight: 700;
	margin: 1rem 0.5rem;
	margin-inline-start: 3rem;
`;

const StyledWhiteHeader = styled.span`
	color: white;
	font-weight: 700;
`;

const StyledHeaderWrapper = styled.div`
	margin: 1rem 0.5rem;
	text-align: center;
	width: 100%;
`;

const StyledButton = styled(Button)`
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

const RequestedApplicationsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
`;

interface ProfileLayoutProps {
	setShowModal: (value: boolean) => void;
	showModal: boolean;
	currentUser: User | null;
	handleRemoveMe: () => void;
	setShowModalPrompt: (value: boolean) => void;
	showModalPrompt: boolean;
	toast: string;
	setToast: (val: string) => void;
	onUserApplicationClick: (value?: string) => void;
	showUserAppOverviewModal: boolean;
	setShowUserAppOverviewModal: (value: boolean) => void;
	userApplicationSelected?: UserApplication & { id: string };
	onCancelApplicationRequest: (appId: string | undefined, type: string | undefined) => void;
	setCurrentUser: (data: User | null) => void;
	cancellingApplicationRequest: boolean;
	userLoading: boolean;
	cancellingApplication: { appId: string; type: "new" | "existing" } | undefined;
	showCancelAppRequestModal: boolean;
	setShowCancelAppRequestModal: (val: boolean) => void;
	setCancellingApplication: (val: { appId: string; type: "new" | "existing" } | undefined) => void;
}

const ProfileLayout: React.FC<ProfileLayoutProps> = ({
	showModal,
	setShowModal,
	currentUser,
	handleRemoveMe,
	setShowModalPrompt,
	showModalPrompt,
	toast,
	setToast,
	onUserApplicationClick,
	showUserAppOverviewModal,
	setShowUserAppOverviewModal,
	userApplicationSelected,
	onCancelApplicationRequest,
	cancellingApplicationRequest,
	userLoading,
	setCurrentUser,
	cancellingApplication,
	showCancelAppRequestModal,
	setShowCancelAppRequestModal,
	setCancellingApplication,
}) => {
	const user = useSelector(userSelector);
	return (
		<div>
			<Modal
				focusLockProps={{ locked: false }}
				data-testid="closeButton"
				visible={showModal}
				handleCloseBtn={() => {
					setShowModal(false);
				}}
			>
				<Sheets
					footer={
						<ModalButtonWrapper>
							<Button
								type="secondary"
								data-testid="Logout"
								text="Logout"
								ssrIcon={arrowLeftFromBase}
								href={ROUTE_LOGOUT}
							/>
							<Button
								text="Remove me from Flagga"
								data-testid="RemoveMeFromFlagga"
								type="tertiary"
								onClick={() => setShowModalPrompt(true)}
							/>
						</ModalButtonWrapper>
					}
					header={
						<CustomModalHeader>
							<StyledHeaderWrapper>
								<StyledHeader>
									<StyledWhiteHeader>Hej! </StyledWhiteHeader>
									{user?.user.name.split(" ")[0]}
								</StyledHeader>
							</StyledHeaderWrapper>
							<StyledButton
								type="tertiary"
								ssrIcon={cross}
								onClick={() => {
									setShowModal(false);
								}}
								iconOnly
							></StyledButton>
						</CustomModalHeader>
					}
				>
					<ModalBody>
						{userLoading ? (
							<LoadingWrapper>
								<Loading labelPosition="center" text="Loading Profile" labelTransitions>
									<LoadingBall color="emphasised" />
								</Loading>
							</LoadingWrapper>
						) : (
							<>
								<StyledListView id="user-info">
									<ListViewItem key={currentUser?.user.name} leadingIcon={person} title={currentUser?.user.name} />
									<ListViewItem key={currentUser?.user.email} leadingIcon={mail} title={currentUser?.user.email} />
								</StyledListView>
								{!currentUser?.user["flagga-admin"] && (
									<>
										{currentUser?.user?.applications && !!Object.keys(currentUser?.user?.applications).length && (
											<Text headingSize="s">User applications</Text>
										)}
										{currentUser?.user?.applications && !!Object.keys(currentUser?.user?.applications).length && (
											<StyledListView id="user-applications">
												<>
													{Object.keys(currentUser?.user?.applications).map((app, index) => (
														<ListViewItem
															leadingIcon={laptop}
															showDivider={
																index === Object.keys(currentUser?.user?.applications).length - 1 ? true : false
															}
															description={
																<>
																	Role:{" "}
																	{currentUser?.user?.applications[app]?.permissions === UserPermissions.Admin ? (
																		<StyledCommercialMessage message="Admin" variant="family" subtle />
																	) : currentUser?.user?.applications[app]?.permissions ===
																			UserPermissions.Maintainer &&
																	  !Object.keys(currentUser?.user?.applications[app]?.markets || {}).length ? (
																		<StyledCommercialMessage message="Read Only" variant="new" subtle />
																	) : (
																		currentUser?.user?.applications[app]?.permissions === UserPermissions.Maintainer &&
																		isUserHasMarkets(currentUser?.user?.applications[app]?.markets) && (
																			<StyledCommercialMessage message="Maintainer" variant="nlp" subtle />
																		)
																	)}
																</>
															}
															addOn={
																currentUser?.user?.applications[app]?.permissions === UserPermissions.Maintainer &&
																Object.keys(currentUser?.user?.applications[app]?.markets || {}).length ? (
																	<FlagsRow
																		markets={Object.keys(currentUser?.user?.applications[app]?.markets || {})}
																		size={100}
																		tooltipBoundaryContainerID={"user-applications"}
																		truncateAfter={5}
																	/>
																) : undefined
															}
															control={"navigational"}
															onClick={() => onUserApplicationClick(app)}
															controlIcon="chevronRightSmall"
															title={currentUser?.user?.applications[app]?.applicationName || app}
														/>
													))}
												</>
											</StyledListView>
										)}
										{!!currentUser?.user.requestedApplications?.length && (
											<Text headingSize="s">Requests to existing applications</Text>
										)}
										{!!currentUser?.user.requestedApplications?.length && (
											<StyledListView size="small" id="user-requested-applications">
												{currentUser?.user?.requestedApplications.map((app, index) => (
													<>
														<RequestedApplicationsWrapper>
															<ListViewItem
																leadingIcon={documentUpload}
																showDivider={false}
																title={<>{app["friendly-name"] || app.application}</>}
															/>
															<Tooltip tooltipText="Cancel Request">
																<Button
																	id="cancel-application-request-button"
																	ssrIcon={trashCan}
																	onClick={() => {
																		setCancellingApplication({ appId: app.application, type: "existing" });
																		setShowCancelAppRequestModal(true);
																	}}
																	type="tertiary"
																	xsmallIconOnly
																	iconOnly
																	small
																	disabled={cancellingApplicationRequest}
																	loading={
																		cancellingApplicationRequest && cancellingApplication?.appId === app.application
																	}
																	text="Remove Request"
																/>
															</Tooltip>
														</RequestedApplicationsWrapper>
														{currentUser?.user?.requestedApplications &&
															(index === currentUser?.user?.requestedApplications?.length - 1 ? true : false) && (
																<StyledDivider />
															)}
													</>
												))}
											</StyledListView>
										)}

										{!!currentUser?.user.newApplicationRequests?.length && (
											<Text headingSize="s">Requests for new applications</Text>
										)}
										{!!currentUser?.user.newApplicationRequests?.length && (
											<StyledListView size="small" id="user-new-requested-applications">
												{currentUser?.user?.newApplicationRequests.map((app, index) => (
													<>
														<RequestedApplicationsWrapper>
															<ListViewItem
																leadingIcon={documentUpload}
																showDivider={false}
																title={<>{app.appName || app.appId}</>}
															/>
															<Tooltip tooltipText="Cancel Request">
																<Button
																	id="cancel-application-request-button"
																	ssrIcon={trashCan}
																	onClick={() => {
																		console.log(app);
																		setCancellingApplication({ appId: app.appId, type: "new" });
																		setShowCancelAppRequestModal(true);
																	}}
																	type="tertiary"
																	xsmallIconOnly
																	iconOnly
																	small
																	disabled={cancellingApplicationRequest}
																	loading={cancellingApplicationRequest && cancellingApplication?.appId === app.appId}
																	text="Remove Request"
																/>
															</Tooltip>
														</RequestedApplicationsWrapper>
														{currentUser?.user?.newApplicationRequests &&
															(index === currentUser?.user?.newApplicationRequests?.length - 1 ? true : false) && (
																<StyledDivider />
															)}
													</>
												))}
											</StyledListView>
										)}
									</>
								)}
							</>
						)}
					</ModalBody>
				</Sheets>
			</Modal>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showModalPrompt}
				handleCloseBtn={() => setShowModalPrompt(false)}
			>
				<Prompt
					aria-label="Accessibility header for a modal"
					footer={
						<ModalFooter>
							<Button text="Stay with Flagga" type="emphasised" />
							<Button text="Remove my access" onClick={() => handleRemoveMe()} />
						</ModalFooter>
					}
					title="Do you want to remove your access to Flagga?"
					header={null}
					titleId={"removeAccessTitle"}
				></Prompt>
			</Modal>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showUserAppOverviewModal}
				handleCloseBtn={() => setShowUserAppOverviewModal(false)}
			>
				<Sheets
					footer={null}
					header={
						<StyledModalHeader
							title={`${userApplicationSelected?.applicationName} details`}
							backBtnClick={() => setShowUserAppOverviewModal(false)}
						/>
					}
				>
					<ModalBody>
						<UserApplicationOverview
							appId={userApplicationSelected?.id}
							setToast={setToast}
							setShowUserAppOverviewModal={setShowUserAppOverviewModal}
						/>
					</ModalBody>
				</Sheets>
			</Modal>
			<Toast
				text={toast}
				isOpen={!!toast}
				onCloseRequest={() => setToast("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showCancelAppRequestModal}
				handleCloseBtn={() => setShowCancelAppRequestModal(false)}
			>
				<Prompt
					aria-label="Accessibility header for a modal"
					footer={
						<ModalFooter>
							<Button
								id="confirm-cancel-request-button"
								text="Cancel Request"
								onClick={() => {
									onCancelApplicationRequest(cancellingApplication?.appId, cancellingApplication?.type);
								}}
							/>
							<Button
								id="close-modal-button"
								text="Close"
								type="emphasised"
								onClick={() => setShowCancelAppRequestModal(false)}
							/>
						</ModalFooter>
					}
					header={<StyledModalHeader title="Cancel Application Request!" />}
					title={undefined}
					titleId={"cancelApplicationTitle"}
				>
					<p>
						Do you want to cancel request to access{" "}
						<strong>
							{cancellingApplication?.type === "existing" &&
								(currentUser?.user.requestedApplications?.find(
									(app) => app.application === cancellingApplication?.appId
								)?.["friendly-name"] ||
									cancellingApplication)}

							{cancellingApplication?.type === "new" &&
								(currentUser?.user.newApplicationRequests?.find((app) => app.appId === cancellingApplication?.appId)
									?.appName ||
									cancellingApplication)}
						</strong>
						?
					</p>
				</Prompt>
			</Modal>
		</div>
	);
};

export default ProfileLayout;
