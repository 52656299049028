import * as React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlElement, ControlProps } from "@jsonforms/core";
import InputField from "@ingka/input-field";
import { VisibilityWrapper } from "../../styles";
import FormField from "@ingka/form-field";
import parse from "html-react-parser";
import { findAndTransformHTMLString } from "../utils";
import Tooltip from "@ingka/tooltip";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SSRIcon from "@ingka/ssr-icon";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import styled from "styled-components";
import { StyledLabel } from "../../styles";


interface InputProps extends ControlProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const StyledLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const StyledSSRIcon = styled(SSRIcon)`
	color: rgb(var(--colour-semantic-informative,0,88,163));
`;

const StyledList = styled.ul`
	margin-top: 0rem;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	li {
		font-family: Noto IKEA,Noto Sans,Roboto,Open Sans,sans-serif;
		font-size: .875rem;
		line-height: 1.571;
	}
`;


const InputControl: React.FC<InputProps> = ({
	id,
	data,
	schema,
	handleChange,
	path,
	label,
	description,
	uischema,
	errors,
	required,
	...props
}) => {
	const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			handleChange(path, e.target.value);
		},
		[path, handleChange]
	);

	const errMessage =
		required && errors.length
			? `${label} ${errors}`
			: required
				? `${label} is a required property`
				: `${label} ${errors}`;

	const input = React.useMemo(
		() => (
			<InputField
				id={path}
				label={label}
				type="text"
				onChange={onChange}
				defaultValue={schema?.default || undefined}
				value={data || ""}
				disabled={!props.enabled}
			/>
		),
		[data, label, onChange, path, props.enabled, schema?.default]
	);

	return (
		<VisibilityWrapper visible={props.visible}>
			<FormField
				fieldHelper={{
					msg: uischema?.subText ? <div>{parse(findAndTransformHTMLString(uischema.subText))}</div> : "",
					id: "input-control-msg",
				}}
				valid={required ? !errors && data : !errors}
				shouldValidate={errors.length > 0 || (required && !data)}
				validation={{
					id: "error-msg-id",
					msg: errMessage,
					type: "error",
				}}
			>
				{description ? <Tooltip tooltipText={description}>{input}</Tooltip> : input}
			</FormField>
			{uischema && uischema.scope.includes("subText") && (
				<Accordion
					style={{ marginTop: "1em", marginBottom: "1em" }}
				>
					<AccordionSummary 
						expandIcon={<ExpandMoreIcon />}>
						<Typography>{
							<StyledLabelWrapper>
								<StyledSSRIcon paths={informationCircle}/>
								<StyledLabel>Styled description</StyledLabel>
							</StyledLabelWrapper>
						}
						</Typography>
					</AccordionSummary>
					<StyledAccordionDetails>
						<StyledList>
							<li>
								*text*: <strong>text</strong>
							</li>
							<li>
								**text**: <i>text</i>
							</li>
							<li>
								__text__: <u>text</u>
							</li>
							<li>
								~~text~~: <s>text</s>
							</li>
							<li>
								<span>
									{"text <>new line"}: text
									<br />
									new line
								</span>
							</li>
							<li>
								[link name](link url): <a href="#">link name</a>
							</li>
							<li>
								[[text1; text2]]:{" "}
								<ul>
									<li>text1</li>
									<li>text2</li>
								</ul>
							</li>
						</StyledList>
					</StyledAccordionDetails>
				</Accordion>
			)}
		</VisibilityWrapper>
	);
};

export default withJsonFormsControlProps(InputControl);
