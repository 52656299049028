import React from "react";
import Layout from "./Layout";
import { UiSchemaData } from "../../../../services/applications/types";
import { useGetCountryName } from "../../../../hooks/useGetCountryName";
import { UserMarketPermissions } from "../../../../services/users";

interface MarketsAndSchemasSelectorProps {
	showModal: boolean;
	setShowModal: (val: boolean) => void;
	maintainerMarkets: UserMarketPermissions;
	setMaintainerMarkets: (data: UserMarketPermissions) => void;
	markets?: string[];
	schemasData?: UiSchemaData;
}

const MarketsAndSchemasSelector: React.FC<MarketsAndSchemasSelectorProps> = ({
	showModal,
	setShowModal,
	markets,
	schemasData,
	maintainerMarkets,
	setMaintainerMarkets,
}) => {
	const [uiSchemaSelected, setUiSchemaSelected] = React.useState<string>("");
	const [marketsSelected, setMarketsSelected] = React.useState<string[]>([]);

	const { getCountryName } = useGetCountryName();

	const onAdd = React.useCallback(() => {
		const marketsAdded = marketsSelected.reduce((accumulator, currentValue) => {
			accumulator[currentValue] = uiSchemaSelected;
			return accumulator;
		}, {} as UserMarketPermissions);
		setMaintainerMarkets({ ...maintainerMarkets, ...marketsAdded });
		setUiSchemaSelected("");
		setMarketsSelected([]);
		setShowModal(false);
	}, [maintainerMarkets, marketsSelected, setMaintainerMarkets, setShowModal, uiSchemaSelected]);

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			markets={markets}
			setUiSchemaSelected={setUiSchemaSelected}
			schemasData={schemasData}
			uiSchemaSelected={uiSchemaSelected}
			marketsSelected={marketsSelected}
			setMarketsSelected={setMarketsSelected}
			getCountryName={getCountryName}
			onAdd={onAdd}
		/>
	);
};

export default MarketsAndSchemasSelector;
