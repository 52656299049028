import React from "react";
import _ from "lodash/fp";

import FormField from "@ingka/form-field";
import Select, { Option } from "@ingka/select";

import { schemaTypes } from "../../utils/constants";
import { SchemaType } from "../../utils/types";
import styled from "styled-components";

interface Props {
	type: SchemaType;
	onChange: (type: SchemaType) => void;
	schemakey?: string;
}

const SelectWrapper = styled.div`
	width: 10rem;
	margin-bottom: 1.25rem;
`;

const SchemaTypesSelect: React.FC<Props> = ({ type, onChange, schemakey }) => {
	const options = schemaTypes;

	return (
		<SelectWrapper>
			<FormField>
				<Select
					id={Math.random().toString()}
					label={"type"}
					onChange={(e: any) => onChange(e.target.value)}
					value={
						schemakey === "__root__"
							? schemaTypes.find((data) => data.value === "object")?.value
							: type != "oneOf"
							? schemaTypes.find((data) => data.value === type)?.value
							: schemaTypes.find((data) => data.value === "oneOf")?.value
					}
					disabled={schemakey === "__root__" ? true : false}
				>
					{options.map(({ value, label }, index) => (
						<Option value={value} name={label} key={index} />
					))}
				</Select>
			</FormField>
		</SelectWrapper>
	);
};

export default SchemaTypesSelect;
