import * as React from "react";
import Modal, { ModalBody, Sheets } from "@ingka/modal";
import styled from "styled-components";

import { StyledModalHeader } from "../../../styles";
import Accordion, { AccordionItem } from "@ingka/accordion";
import TextArea from "@ingka/text-area";

interface AppsSettingsLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
}

const StyledTextArea = styled(TextArea)`
	textarea {
		height: 15rem;
	}
	padding-bottom: 1rem;
`;

const StyledAccordionItem = styled(AccordionItem)`
	.accordion__content {
		padding-top: 0rem;
	}
`;

const ShowRuleModal: React.FC<AppsSettingsLayoutProps> = ({ showModal, setShowModal }) => {
	return (
		<>
			<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
				<Sheets footer={null} header={<StyledModalHeader title="Rule examples" />}>
					<ModalBody>
						<Accordion>
							<StyledAccordionItem id="hide-rule" caption="HIDE" title="">
								<StyledTextArea
									readOnly
									label=""
									id="example-hide-rule"
									value={JSON.stringify(
										{
											effect: "HIDE",
											condition: {
												scope: "#/properties/counter",
												schema: { const: 10 },
											},
										},
										null,
										2
									)}
								/>
							</StyledAccordionItem>
							<StyledAccordionItem id="show-rule" caption="SHOW" title="">
								<StyledTextArea
									readOnly
									label=""
									id="example-show-rule"
									value={JSON.stringify(
										{
											effect: "SHOW",
											condition: {
												scope: "#/properties/counter",
												schema: { not: { const: 10 } },
											},
										},
										null,
										2
									)}
								/>
							</StyledAccordionItem>
							<StyledAccordionItem id="disable-rule" caption="DISABLE" title="">
								<StyledTextArea
									readOnly
									label=""
									id="example-disable-rule"
									value={JSON.stringify(
										{
											effect: "DISABLE",
											condition: {
												scope: "#/properties/counter",
												schema: { minimum: 1, exclusiveMaximum: 10 },
											},
										},
										null,
										2
									)}
								/>
							</StyledAccordionItem>
							<StyledAccordionItem id="enable-rule" caption="ENABLE" title="">
								<StyledTextArea
									readOnly
									label=""
									id="example-enable-rule"
									value={JSON.stringify(
										{
											effect: "ENABLE",
											condition: {
												scope: "#",
												schema: {
													properties: {
														stringArray: { contains: { const: "Foo" } },
													},
													required: ["stringArray", "otherProperty"],
												},
											},
										},
										null,
										2
									)}
								/>
							</StyledAccordionItem>
						</Accordion>
					</ModalBody>
				</Sheets>
			</Modal>
		</>
	);
};

export default ShowRuleModal;
