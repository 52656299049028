import * as React from "react";
import assert from "assert";
import { ErrorObject } from "ajv";
import dayjs, { Dayjs } from "dayjs";
import { cloneDeep, merge } from "lodash/fp";

import { getControlPath } from "@jsonforms/core";
import { materialRenderers } from "@jsonforms/material-renderers";

import Renderers from "../renderers";
import Layouts from "../layouts";
import useGetSchemas from "../../hooks/useGetSchemas";
import useStore, {
	activeApplicationSelector,
	activeMarketSelector,
	isApplicationAdminSelector,
	selectedMarketsSelector,
	setMultipleMarketUpdateFlagSelector,
	multipleMarketUpdateFlagSelector,
	setselectedMarketsSelector,
	marketsSelector,
	userSelector,
	setActiveMarketSelector,
} from "../../store";
import useAuthentication from "../../hooks/useAuthentication";
import { getUiSchemas, saveSchemaValue, getAllScopes } from "./utils";
import Layout from "./Layout";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import { ApplicationUiSchema } from "../../services/applications/types";
import { getObjectChanges } from "../../utils/common";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import { scheduleChanges } from "../../services/schemas";
import useLocalStorage from "../../hooks/useLocalStorage";
import { filterElementsByLabel } from "./utils";

export const renderers = [...materialRenderers, ...Renderers, ...Layouts];

interface CustomErrorObject extends ErrorObject {
	instancePath?: string;
}

const ConfigurationViewer = () => {
	const {
		data: schemaData,
		setData: setSchemaData,
		loading: schemasLoading,
		originalValue,
		setOriginalValue,
	} = useGetSchemas();

	const [formValue, setFormValue] = React.useState<any>(undefined);
	const [originalValueBackUp, setOriginalValueBackUp] = React.useState<any>(undefined);
	const [uiSchemasData, setUiSchemasData] = React.useState<ApplicationUiSchema[] | undefined>(undefined);
	const [uiSchemasLoading, setUiSchemasLoading] = React.useState<boolean>(false);
	const [selectedUiSchema, setSelectedUiSchema] = React.useState<ApplicationUiSchema | undefined>(undefined);
	const [originalUiSchema, setOriginalUiSchema] = React.useState<ApplicationUiSchema | undefined>(undefined);
	const [currentData, setCurrentData] = React.useState<any>(undefined);
	const [toastMessage, setToastMessage] = React.useState("");
	const [hasChanged, setHasChanged] = React.useState<boolean>(false);
	const [saveBtnLoading, setSaveBtnLoading] = React.useState<boolean>(false);
	const { getToken, handleLogout } = useAuthentication();
	const activeApplication = useStore(activeApplicationSelector);
	const activeMarket = useStore(activeMarketSelector);
	const selectedMarkets = useStore(selectedMarketsSelector);
	const isAppAdmin = useStore(isApplicationAdminSelector);
	const setMultipleMarketUpdateFlag = useStore(setMultipleMarketUpdateFlagSelector);
	const setselectedMarkets = useStore(setselectedMarketsSelector);
	const currentUser = useStore(userSelector);
	const multipleMarketFlag = useStore(multipleMarketUpdateFlagSelector);
	const marketsData = useStore(marketsSelector);
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const { getCountryName } = useGetCountryName();
	const isAdmin = React.useMemo(() => isFlaggaAdmin || isAppAdmin, [isAppAdmin, isFlaggaAdmin]);
	const loading = React.useMemo(
		() => schemasLoading || (isAdmin && uiSchemasLoading),
		[isAdmin, schemasLoading, uiSchemasLoading]
	);
	const [JsonFormErrors, setJsonFormErrors] = React.useState<CustomErrorObject[]>([]);
	const [JsonFormUISchemaErrors, setJsonFormUISchemaErrors] = React.useState<CustomErrorObject[]>([]);
	const isValid = JsonFormUISchemaErrors.length === 0 || false;

	const [showReasonModal, setShowReasonModal] = React.useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false);
	const [showWarningModal, setShowWarningModal] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>("");
	const [showScheduleChangesModal, setShowScheduleChangesModal] = React.useState<boolean>(false);
	const [schedulingComment, setSchedulingComment] = React.useState<string>("");
	const [sendCopyEmail, setSendCopyEmail] = React.useState<boolean>(false);
	const [sendScheduleChangesLoading, setSendScheduleChangesLoading] = React.useState<boolean>(false);

	// const coeff = 1000 * 60 * 15;
	// const rounded = new Date(Math.ceil(new Date().getTime() / coeff) * coeff);
	const [scheduleDate, setScheduleDate] = React.useState<Dayjs | null>(null);
	const [scheduleToastMessage, setScheduleToastMessage] = React.useState<string>("");
	const [showUnsavedChangesModal, setShowUnsavedChangesModal] = React.useState<boolean>(false);
	const [uiSchema, setUiSchema] = React.useState<ApplicationUiSchema | undefined>(undefined);
	const setActiveMarket = useStore(setActiveMarketSelector);
	const [unsavedChangesValue, setUnsavedChangesValue] = useLocalStorage("unsavedChanges", "");
	const [searchUserValue, setSearchUserValue] = React.useState<string>("");
	const [showNoResult, setShowNoResult] = React.useState<boolean>(false);

	React.useEffect(() => {
		setShowNoResult(false);
		setSearchUserValue("");
		if (originalUiSchema) {
			setSelectedUiSchema(originalUiSchema);
		}
	}, [activeMarket, originalUiSchema]);

	React.useEffect(() => {
		setOriginalValueBackUp(originalValue);
		setFormValue(originalValue);
		setCurrentData(originalValue);
	}, [originalValue]);

	React.useEffect(() => {
		if (unsavedChangesValue) {
			setShowUnsavedChangesModal(true);
		}
	}, [unsavedChangesValue]);

	React.useEffect(() => {
		setFormValue(multipleMarketFlag ? {} : originalValueBackUp);
	}, [multipleMarketFlag, originalValueBackUp]);

	const updateMarketsSelection = (e: any) => {
		if (e.target.checked) {
			if (e.target.value != "all") {
				setselectedMarkets([...selectedMarkets, e.target.value]);
			} else {
				setselectedMarkets(marketsData?.permittedMarkets ? marketsData.permittedMarkets : []);
			}
		} else {
			if (e.target.value != "all") {
				setselectedMarkets(selectedMarkets.filter((item) => item != e.target.value));
			} else {
				setselectedMarkets([]);
			}
		}
	};

	React.useEffect(() => {
		const UISchemaScopes = selectedUiSchema && getAllScopes(selectedUiSchema!["uiSchema"]);
		const UISchemaErrors = [] as CustomErrorObject[];

	
		JsonFormErrors.map((error: CustomErrorObject) => {
			let controlPath = getControlPath(error as any);

			let splitControlPath = controlPath.split(".");
			controlPath = splitControlPath && splitControlPath.length > 1 ? controlPath.split(".")[1] : controlPath;

			if (
				UISchemaScopes?.some((scope: string) => scope.includes(controlPath)) &&
				UISchemaErrors.filter((err) => err.instancePath === error.instancePath).length === 0
			) {
				UISchemaErrors.push(error);
			} else if (UISchemaScopes?.includes(`#/properties/${splitControlPath?.[0]}`)) {
				UISchemaErrors.push(error);
			}

		});

		setJsonFormUISchemaErrors(UISchemaErrors);
	}, [JsonFormErrors, selectedUiSchema]);

	/*This use effect should only run on change of multiple market flag.
	It Stores orignal value backup to restore on switching off flag 
	and sets currentData*/
	React.useEffect(() => {
		if (multipleMarketFlag) {
			const value = getObjectChanges(originalValue, currentData || {});
			setOriginalValueBackUp(currentData);
			setCurrentData(value);
		} else if (typeof originalValueBackUp === "object") {
			setCurrentData(originalValueBackUp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multipleMarketFlag]);

	React.useEffect(() => {
		const getUiSchemasData = async () => {
			if (isAdmin) {
				await getUiSchemas({
					activeApplication,
					getToken,
					setUiSchemasLoading,
					setUiSchemasData,
					handleLogout,
				});
			}
		};
		getUiSchemasData();
	}, [activeApplication, getToken, handleLogout, isAdmin]);

	React.useEffect(() => {
		if (isAdmin && uiSchemasData) {
			const uiSchema = uiSchemasData.find((data) => data.uiSchemaName === "ui-schema" && data);
			setSelectedUiSchema(uiSchema);
			setOriginalUiSchema(cloneDeep(uiSchema));
		}
	}, [isAdmin, uiSchemasData]);

	React.useEffect(() => {
		try {
			assert.deepStrictEqual(originalValue, currentData);
			setHasChanged(false);
		} catch (e) {
			setHasChanged(true);
		}
	}, [currentData, originalValue]);

	const onUpdate = React.useCallback((formData) => {
		setJsonFormErrors(formData.errors);
		setCurrentData(formData.data);
	}, []);

	const onUISchemaChanged = React.useCallback(() => {
		setCurrentData(originalValue);
		setHasChanged(false);
	}, [originalValue]);

	const onSearchConfiguration = React.useCallback(
		(searchInput: string) => {
			setShowNoResult(false);
			if (searchInput && originalUiSchema && originalUiSchema.uiSchema?.elements) {
				const filteredUISchemaResult = filterElementsByLabel(originalUiSchema?.uiSchema, searchInput);
				if (filteredUISchemaResult.length > 0) {
					const filteredUISchema = {
						uiSchemaName: originalUiSchema.uiSchemaName,
						uiSchema: filteredUISchemaResult[0],
					} as ApplicationUiSchema;
					setSelectedUiSchema(filteredUISchema);
				} else {
					setShowNoResult(true);
				}
			} else if (searchInput === "") {
				setSelectedUiSchema(originalUiSchema);
			}
		},
		[originalUiSchema]
	);

	const onReset = React.useCallback(() => {
		setCurrentData(originalValue);
		setHasChanged(false);
		setSearchUserValue("");
		onSearchConfiguration("");
	}, [onSearchConfiguration, originalValue]);

	const onClearSearch = React.useCallback(() => {
		setSearchUserValue("");
		setShowNoResult(false);
		setSelectedUiSchema(originalUiSchema);
	}, [originalUiSchema]);

	const onSave = React.useCallback(async () => {
		if (!currentData) {
			return;
		}
		const value = getObjectChanges(formValue, currentData || {});
		setShowReasonModal(false);

		if (Object.keys(value).length) {
			await saveSchemaValue({
				activeApplication,
				activeMarket,
				value,
				setSchemaData,
				setOriginalValue,
				getToken,
				handleLogout,
				uiSchemaName: selectedUiSchema?.uiSchemaName,
				setSaveBtnLoading,
				setToastMessage,
				multipleMarketFlag,
				selectedMarkets,
				reason,
				currentUser,
				setUnsavedChangesValue,
			});
		} else {
			console.error("Invalid Value");
			setToastMessage("Invalid Value");
		}
		setselectedMarkets([]);
		setMultipleMarketUpdateFlag(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentData,
		formValue,
		activeApplication,
		activeMarket,
		setSchemaData,
		setOriginalValue,
		getToken,
		handleLogout,
		selectedUiSchema?.uiSchemaName,
		multipleMarketFlag,
		selectedMarkets,
		reason,
	]);

	const sendScheduleChanges = async () => {
		setSendScheduleChangesLoading(true);
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		const value = getObjectChanges(formValue, currentData || {});

		if (
			activeApplication &&
			((isAdmin && selectedUiSchema?.uiSchemaName) ||
				currentUser?.user?.applications?.[activeApplication.id]?.markets?.[activeMarket || ""])
		) {
			try {
				const { data } = await scheduleChanges(
					authHeader,
					activeApplication.id,
					activeMarket,
					value,
					selectedUiSchema?.uiSchemaName || undefined,
					dayjs(scheduleDate).format(),
					schedulingComment,
					sendCopyEmail
				);
				setScheduleToastMessage(data.message);
				setSendScheduleChangesLoading(false);
				setShowScheduleChangesModal(false);
				setScheduleDate(null);
				setSchedulingComment("");
				setSendCopyEmail(false);
			} catch (error: any) {
				console.error(error);
				let errMsg = error.response?.data?.message;
				if (
					error.response.data &&
					error.response.data.errors &&
					error.response.data.errors[0] &&
					error.response.data.errors[0].message
				) {
					errMsg = error.response.data.errors[0].message;
				}
				setScheduleToastMessage(errMsg);
				setSendScheduleChangesLoading(false);
				setSendCopyEmail(false);
			}
		}
	};

	const restoreUnsavedChanges = React.useCallback(() => {
		if (unsavedChangesValue && typeof unsavedChangesValue == "object") {
			setActiveMarket((unsavedChangesValue as any).activeMarket);
			setSelectedUiSchema(
				uiSchemasData?.find((data) => data.uiSchemaName === (unsavedChangesValue as any).uiSchemaName && data)
			);

			setOriginalValueBackUp(merge(originalValue, JSON.parse((unsavedChangesValue as any).value)));
			setFormValue(merge(originalValue, JSON.parse((unsavedChangesValue as any).value)));
			setCurrentData(merge(originalValue, JSON.parse((unsavedChangesValue as any).value)));

			setUnsavedChangesValue("");
		}
	}, [originalValue, setActiveMarket, setUnsavedChangesValue, uiSchemasData, unsavedChangesValue]);

	return (
		<Layout
			schemaData={schemaData}
			loading={loading}
			hasChanged={hasChanged}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			renderers={renderers}
			onSave={onSave}
			onUpdate={onUpdate}
			isAdmin={isAdmin}
			uiSchemas={uiSchemasData}
			selectedUiSchema={selectedUiSchema}
			setSelectedUiSchema={setSelectedUiSchema}
			currentData={currentData}
			saveBtnLoading={saveBtnLoading}
			multipleMarketFlag={multipleMarketFlag}
			setMultipleMarketUpdateFlag={setMultipleMarketUpdateFlag}
			updateMarketsSelection={updateMarketsSelection}
			selectedMarkets={selectedMarkets}
			marketsData={marketsData}
			getCountryName={getCountryName}
			isValid={isValid}
			showReasonModal={showReasonModal}
			setShowReasonModal={setShowReasonModal}
			setReason={setReason}
			showErrorModal={showErrorModal}
			setShowErrorModal={setShowErrorModal}
			JsonFormUISchemaErrors={JsonFormUISchemaErrors}
			showWarningModal={showWarningModal}
			setShowWarningModal={setShowWarningModal}
			onUISchemaChanged={onUISchemaChanged}
			showScheduleChangesModal={showScheduleChangesModal}
			setShowScheduleChangesModal={setShowScheduleChangesModal}
			schedulingComment={schedulingComment}
			setSchedulingComment={setSchedulingComment}
			sendCopyEmail={sendCopyEmail}
			setSendCopyEmail={setSendCopyEmail}
			sendScheduleChangesLoading={sendScheduleChangesLoading}
			setSendScheduleChangesLoading={setSendScheduleChangesLoading}
			sendScheduleChanges={sendScheduleChanges}
			scheduleDate={scheduleDate}
			setScheduleDate={setScheduleDate}
			scheduleToastMessage={scheduleToastMessage}
			setScheduleToastMessage={setScheduleToastMessage}
			showUnsavedChangesModal={showUnsavedChangesModal}
			setShowUnsavedChangesModal={setShowUnsavedChangesModal}
			restoreUnsavedChanges={restoreUnsavedChanges}
			uiSchema={uiSchema}
			setUiSchema={setUiSchema}
			currentUser={currentUser}
			setSearchUserValue={setSearchUserValue}
			showNoResult={showNoResult}
			setOriginalUiSchema={setOriginalUiSchema}
			searchUserValue={searchUserValue}
			onSearchConfiguration={onSearchConfiguration}
			onClearSearch={onClearSearch}
			onReset={onReset}
		/>
	);
};

export default ConfigurationViewer;
