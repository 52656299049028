import * as React from "react";
import styled from "styled-components";

import { Paragraph, StyledAnchor } from "../styles";
import {
	colourSemanticInformative,
	colourStaticIkeaBrandYellow,
	colourStaticWhite,
} from "@ingka/variables/colours-css";
import { SLACK_LINK } from "../../utils/constants";

const StyledDiv = styled.div`
	background-color: ${colourSemanticInformative};
	color: white;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* padding-top: 8rem; */
`;

const TitleWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

const StyledParagraph = styled(Paragraph)`
	margin-top: 2rem;
`;

const H1 = styled.h1`
	${({ margin }: { margin?: string }) => (margin ? `margin: ${margin};` : "")};
	color: ${({ color }: { color?: string }) => (color ? color : colourStaticWhite)};
`;

const Layput: React.FC = () => {
	return (
		<StyledDiv>
			<Wrapper>
				<TitleWrapper>
					<H1 color={colourStaticIkeaBrandYellow} margin="0">
						Maintenance
					</H1>
					<H1 margin="0">is going on</H1>
				</TitleWrapper>
				<StyledParagraph>
					Please contact us on{" "}
					<StyledAnchor
						color={colourStaticIkeaBrandYellow}
						href={SLACK_LINK}
						target="_blank"
					>
						#cssom-flagga
					</StyledAnchor>{" "}
					for any questions!
				</StyledParagraph>
			</Wrapper>
		</StyledDiv>
	);
};

export default Layput;
