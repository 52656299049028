import * as React from "react";
import useAuthentication from "./useAuthentication";
import { getApplicationBackupData } from "../services/backupAndRestore";

interface ApplicationBackupHookParams {
	application?: string;
	restoreDate?: string;
}

const useGetApplicationBackupData = ({ application, restoreDate }: ApplicationBackupHookParams) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<string[] | undefined>(undefined);
	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		if (application && restoreDate) {
			(async () => {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				try {
					setLoading(true);
					const { data: appBackupData } = await getApplicationBackupData(authHeader, application, restoreDate);
					const hasData = appBackupData.data.length;
					setData(hasData ? appBackupData.data : undefined);
				} catch (error: any) {
					if (error) {
						setData(undefined);
						setLoading(false);
						if (error?.response?.status == 401) {
							handleLogout();
						}
					}
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [application, getToken, handleLogout, restoreDate]);

	return { data, setData, loading };
};

export default useGetApplicationBackupData;
