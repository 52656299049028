import * as React from "react";
import { getApplications } from "../services/applications";
import { ApplicationData } from "../store";
import useAuthentication from "./useAuthentication";

interface GetApplications {
	getAll?: boolean;
}

const useGetApplications = () => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<ApplicationData[] | undefined>(undefined);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const { getToken, handleLogout } = useAuthentication();

	const getApplicationsData = React.useCallback(
		async (getAll: boolean = false) => {
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};

				setLoading(true);
				const { data: applicationsData } = await getApplications({ getAll, header: authHeader });
				const hasApplicationsData = applicationsData.data.applications.length;
				setData(hasApplicationsData ? applicationsData.data.applications : undefined);
			} catch (error: any) {
				if (error) {
					setData(undefined);
					setLoading(false);
					if (error?.response?.data?.message === "something went wrong") {
						setError("Can't fetch Applications, something went wrong");
					} else setError(error?.response?.data?.message as string);
					if (error?.response?.status == 401) {
						handleLogout();
					}
				}
			} finally {
				setLoading(false);
			}
		},
		[getToken, handleLogout]
	);

	return { data, loading, error, getApplicationsData };
};

export default useGetApplications;
