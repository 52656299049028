import * as React from "react";

import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import useStore, { isApplicationAdminSelector, userSelector } from "../../store";
import FlaggaContent from "./FlaggaContent";
import { useNavigate } from "react-router-dom";
import { ROUTE_NEW_USER } from "../../utils/constants";

const Flagga = () => {
	const navigate = useNavigate();
	const currentUser = useStore(userSelector);
	const isApplicationAdmin = useStore(isApplicationAdminSelector);

	const { isFlaggaAdmin } = useIsFlaggaAdmin();

	const hasApplications = React.useMemo(() => currentUser?.user.hasApplications, [currentUser]);

	const showAdminLayout = React.useMemo(() => isFlaggaAdmin || isApplicationAdmin, [isApplicationAdmin, isFlaggaAdmin]);

	React.useEffect(() => {
		!hasApplications && navigate(ROUTE_NEW_USER);
	}, [hasApplications, navigate]);

	return <>{hasApplications ? <FlaggaContent showAdminLayout={showAdminLayout} /> : null}</>;
};

export default Flagga;
