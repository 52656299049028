import React, { useState, useEffect, useRef } from "react";

import Layout from "./Layout";
import useAuthentication from "../../hooks/useAuthentication";
import useInterval from "../../hooks/useInterval";
import useWindowSize from "../../hooks/useWindowSize";

const INACTIVITY_TIME = 29 * 60 * 1000;
const INACTIVITY_TIME_REMAINING = 59;

const STATUS = {
	STARTED: "Started",
	STOPPED: "Stopped",
};

const InactivityModal: React.FC = () => {
	const [secondsRemaining, setSecondsRemaining] = React.useState(INACTIVITY_TIME_REMAINING);
	const [showInactivityModal, setShowInactivityModal] = React.useState(false);
	const [status, setStatus] = useState(STATUS.STOPPED);
	const secondsToDisplay = secondsRemaining % 60;
	const { isMobile, isTablet, isDesktop, isBigDesktop } = useWindowSize();

	const { handleLogout } = useAuthentication();

	useInterval({
		setShowInactivityModal: setShowInactivityModal,
		showInactivityModal: showInactivityModal,
		handleLogout: handleLogout,
		callback: () => {
			if (secondsRemaining > 0) {
				setSecondsRemaining(secondsRemaining - 1);
			} else {
				setStatus(STATUS.STOPPED);
			}
		},
		delay: status === STATUS.STARTED ? 1000 : null
	});

	useEffect(() => {
		let time: any;
		window.onload = resetTimer;
		// DOM Events
		if (!showInactivityModal) {
			if (isMobile || isTablet) {
				document.ontouchmove = resetTimer;
			} else {
				document.onmousemove = resetTimer;
				document.onkeydown = resetTimer;
			}
		}
    
		function displayInactivityModal() {
			setSecondsRemaining(INACTIVITY_TIME_REMAINING);
			setStatus(STATUS.STARTED);
			setShowInactivityModal(true);
		}
    
		function resetTimer() {
			clearTimeout(time);
			time = setTimeout(displayInactivityModal, INACTIVITY_TIME);
		}
	}, []);

	return (
		<Layout
			secondsToDisplay={secondsToDisplay}
			showInactivityModal={showInactivityModal}
			setShowInactivityModal={setShowInactivityModal}
		/>
	);
};

export default InactivityModal;
