import * as React from "react";

import Button from "@ingka/button";
import { ListViewItem } from "@ingka/list-view";
import { ListBoxGroup } from "@ingka/list-box";
import styled from "styled-components";

import { MarketsData } from "../../hooks/useGetMarkets";
import { HomePageLayoutTabs } from "../../utils/constants";
import { marketsByName } from "../../utils/common";
import PillDropDown from "../PillDropDown/PillDropDown";
import { getImage } from "../../utils/markets";

interface MarketLayoutProps {
	loading: boolean;
	setActiveMarket: (data: string) => void;
	activeMarket: string | null;
	activeTab: string | null;
	isAdmin: boolean;
	isAppAdmin: boolean;
	isFlaggaAdmin: boolean;
	setActiveTab: (value: string) => void;
	multipleMarketFlag: boolean;
	setMultipleMarketFlag: (multipleMarketUpdateFlag: boolean) => void;
	getCountryName: (country: string) => string;
	marketsData?: MarketsData;
}

const StyledListViewItem = styled(ListViewItem)`
	.list-view-item__image {
		width: 2rem;
		height: 2rem;
	}
`;

const Layout: React.FC<MarketLayoutProps> = ({
	loading,
	setActiveMarket,
	activeMarket,
	marketsData,
	activeTab,
	isAdmin,
	isAppAdmin,
	isFlaggaAdmin,
	setActiveTab,
	multipleMarketFlag,
	getCountryName,
}) => {
	if (!loading && !marketsData?.permittedMarkets && !marketsData?.otherMarkets) {
		return <Button text={"Oops! No Markets"} type="danger" small disabled />;
	}

	return (
		<>
			{!multipleMarketFlag && (
				<>
					<PillDropDown
						id="markets-dropdown"
						value={activeMarket || undefined}
						disabled={
							isAdmin &&
							activeTab !== HomePageLayoutTabs.Configuration &&
							activeTab !== HomePageLayoutTabs.MarketDashboard
						}
						onChange={(e) => {
							setActiveMarket(e.target.value === "Choose an option" ? null : e.target.value);
							(isFlaggaAdmin || isAppAdmin) && setActiveTab(HomePageLayoutTabs.Configuration);
						}}
					>
						{isAdmin ? (
							marketsData?.permittedMarkets &&
							marketsByName(marketsData.permittedMarkets, getCountryName).map((market) => (
								<StyledListViewItem
									inset
									id={market.code}
									key={market.code}
									name={`${market.name} (${market.code})`}
									showDivider={true}
									title={`${market.name} (${market.code})`}
									control="radiobutton"
									image={getImage(market.code)}
									controlProps={
										{
											value: market.code,
											checked: Boolean(activeMarket === market.code),
										} as any
									}
								/>
							))
						) : (
							<>
								{marketsData?.permittedMarkets?.length ? (
									<>
										<ListBoxGroup groupName="Allowed Markets" showDivider />
										{marketsByName(marketsData.permittedMarkets, getCountryName).map((market) => (
											<ListViewItem
												inset
												id={market.code}
												key={market.code}
												name={`${market.name} (${market.code})`}
												showDivider={true}
												title={`${market.name} (${market.code})`}
												control="radiobutton"
												controlProps={
													{
														value: market.code,
														checked: Boolean(activeMarket === market.code),
													} as any
												}
											/>
										))}
										{marketsData?.otherMarkets?.length && (
											<>
												<ListBoxGroup groupName="Other Markets" />
												{marketsData?.otherMarkets &&
													marketsByName(marketsData.otherMarkets, getCountryName).map((market) => (
														<ListViewItem
															inset
															id={market.code}
															key={market.code}
															name={`${market.name} (${market.code})`}
															showDivider={true}
															title={`${market.name} (${market.code})`}
															control="radiobutton"
															controlProps={
																{
																	value: market.code,
																	checked: Boolean(activeMarket === market.code),
																} as any
															}
														/>
													))}
											</>
										)}
									</>
								) : (
									marketsData?.otherMarkets?.length &&
									marketsByName(marketsData.otherMarkets, getCountryName).map((market) => (
										<ListViewItem
											inset
											id={market.code}
											key={market.code}
											name={`${market.name} (${market.code})`}
											showDivider={true}
											title={`${market.name} (${market.code})`}
											control="radiobutton"
											controlProps={
												{
													value: market.code,
													checked: Boolean(activeMarket === market.code),
												} as any
											}
										/>
									))
								)}
							</>
						)}
					</PillDropDown>
				</>
			)}
		</>
	);
};

export default Layout;
