import * as React from "react";
import styled from "styled-components";

import Select, { Option as SelectOption } from "@ingka/select";
import Button from "@ingka/button";
import Modal, { ModalBody, ModalFooter, Prompt, Sheets } from "@ingka/modal";
import InlineMessage from "@ingka/inline-message";
import Switch from "@ingka/switch";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import Accordion, { AccordionItem } from "@ingka/accordion";

import { AutocompleteStyles, CenterButtonWrapper, ManageUserModalButtonWrapper } from "../../styles";
import { UserApplicationData, UserData } from "../../../services/users";
import AddMarketModal from "../AddMarketModal/AddMarketModal";
import { ApplicationUiSchema } from "../../../services/applications/types";
import { Application } from "../../../store";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Toast from "@ingka/toast";
import { debounce } from "lodash";
import { StyledModalHeader } from "../../styles";

interface ModalLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	modalHeading: string;
	modalButtonLabel: string;
	showAddMaketModal: boolean;
	setShowAddMarketModal: (value: boolean) => void;
	setSelectedUser: (data?: UserApplicationData) => void;
	activeApplication: Application;
	onChangeMarket: (prevMarket: string, newMarket: string) => void;
	onChangeSchema: (selectMarket: string, newUiSchema: string) => void;
	addingNewUser: boolean;
	setAddingNewUser: (value: boolean) => void;
	onChangePermission: (permission: string) => void;
	onChangeCanEnableBoolean: (value: boolean) => void;
	onFinalSubmit: () => void;
	onRemoveMarket: (marketData: string) => void;
	isAdmin: boolean;
	onChangeUser: (userEmail: string) => void;
	currentUserSelected: boolean;
	hasChangedUser: boolean;
	selectedUser?: UserApplicationData;
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	userSuggestions: UserData[] | undefined;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	handleClearURLProps: () => void;
	onRemoveUserAccess?: () => void;
	showModalRemoveUserAccessPrompt: boolean;
	setShowModalRemoveUserAccessPrompt: (value: boolean) => void;
	selectedMarkets?: string[];
	setSelectedMarkets: (value?: string[]) => void;
	onboardingUser?: boolean;
	getSuggestions: (input: string, excludeUsers: boolean) => void;
	noOptionsText: string;
	actionInProgress?: boolean;
	setNoOptionsText: (value: string) => void;
}

const UserMarketsListWrapper = styled.div`
	padding-top: 1rem;
	.select:first-child {
		padding-bottom: 1rem;
	}
`;

const AccordionItemWrapper = styled.div`
	display: flex;
	position: relative;
	.accordion__item {
		width: 100%;
	}
	.btn {
		padding-top: 0.25rem;
		position: absolute;
		right: 1.5rem;
	}
`;

const StyledModalBody = styled(ModalBody)`
	padding: 1rem 3rem;
	.switch {
		padding-top: 0.5rem;
	}
	.inline-message {
		margin: 1rem 0;
	}
`;

const StyledInputLabel = styled.label`
	font-size: 0.875rem;
	line-height: 1.571;
`;

const StyledPrompt = styled(Prompt)`
	h2:first-child {
		margin: 0;
	}
	.prompt__content {
		padding: 0;
	}
`;

const StyledRemoveUserAccessPrompt = styled(Prompt)`
	h2:first-child {
		font-size: 1.3rem;
	}
`;

const Layout: React.FC<ModalLayoutProps> = ({
	showModal,
	setShowModal,
	modalHeading,
	modalButtonLabel,
	userSuggestions,
	showAddMaketModal,
	setShowAddMarketModal,
	marketsData,
	uiSchemas,
	setSelectedUser,
	selectedUser,
	activeApplication,
	onChangeMarket,
	onChangeSchema,
	addingNewUser,
	setAddingNewUser,
	onChangePermission,
	onChangeCanEnableBoolean,
	onFinalSubmit,
	onRemoveMarket,
	isAdmin,
	onChangeUser,
	currentUserSelected,
	toastMessage,
	setToastMessage,
	handleClearURLProps,
	onRemoveUserAccess,
	showModalRemoveUserAccessPrompt,
	setShowModalRemoveUserAccessPrompt,
	selectedMarkets,
	setSelectedMarkets,
	hasChangedUser,
	onboardingUser,
	getSuggestions,
	noOptionsText,
	actionInProgress,
	setNoOptionsText,
}) => {
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onFinalSubmit();
				}}
			>
				<Modal
					focusLockProps={{ locked: false }}
					visible={showModal}
					handleCloseBtn={() => {
						setNoOptionsText("Please type at least 3 characters.");
						!actionInProgress && setShowModal(false);
					}}
					onModalClosed={() => {
						setAddingNewUser(false);
						setSelectedUser(undefined);
						handleClearURLProps();
					}}
					escapable={!actionInProgress}
				>
					<Sheets
						footer={
							<ManageUserModalButtonWrapper>
								<Button
									id="approve-request-btn"
									disabled={onboardingUser ? false : !selectedUser || !hasChangedUser}
									text={modalButtonLabel}
									type="emphasised"
									htmlType="submit"
									loading={actionInProgress}
								/>
								{!(addingNewUser || onboardingUser) && (
									<Button
										disabled={!selectedUser}
										text="Remove user"
										type="secondary"
										onClick={() => setShowModalRemoveUserAccessPrompt(true)}
									/>
								)}
							</ManageUserModalButtonWrapper>
						}
						header={<StyledModalHeader title={modalHeading} />}
						size="medium"
					>
						<StyledModalBody>
							{addingNewUser ? (
								<>
									<StyledInputLabel htmlFor="adminInput" title="Available Users">
										Available Users
									</StyledInputLabel>
									<Autocomplete
										id="usersInput"
										onInputChange={debounce((event, value) => {
											getSuggestions(value?.toLowerCase(), true);
										}, 300)}
										options={
											userSuggestions ? userSuggestions.map((user) => `${user.name}${" ("}${user.email}${")"}`) : []
										}
										renderInput={(params) => (
											<TextField {...params} placeholder="Please enter user name/email" variant="outlined" />
										)}
										onChange={(_, value) => {
											if (value) onChangeUser(value);
											else setSelectedUser(undefined);
										}}
										noOptionsText={noOptionsText}
										sx={AutocompleteStyles}
									/>
								</>
							) : (
								<InlineMessage id="selected-user-name" body={selectedUser?.email} title={selectedUser?.name} />
							)}
							<Switch
								id="isAdmin"
								label="User is Application admin"
								disabled={!selectedUser || currentUserSelected || actionInProgress}
								value={(activeApplication && selectedUser?.applications?.[activeApplication?.id]?.permissions) || ""}
								checked={isAdmin ? true : false}
								onChange={(e) => {
									onChangePermission(e.target.value);
								}}
							/>
							{currentUserSelected && (
								<p>
									<strong>Note: </strong>Cannot change permission for yourself
								</p>
							)}
							{!isAdmin && (
								<Switch
									id="isOnlyEnableBoolean"
									label="User can only enable boolean properties"
									disabled={!selectedUser || currentUserSelected || actionInProgress}
									value={""}
									checked={
										!!activeApplication &&
										!!selectedUser?.applications?.[activeApplication?.id]?.canOnlyEnableProperties
									}
									onChange={(e) => {
										onChangeCanEnableBoolean(
											!!activeApplication &&
												!!selectedUser?.applications?.[activeApplication?.id]?.canOnlyEnableProperties
										);
									}}
								/>
							)}
							{activeApplication && selectedUser?.applications?.[activeApplication?.id]?.markets && (
								<UserMarketsListWrapper>
									<h3>{onboardingUser ? "Markets Requested by User" : "Markets Assigned to User"}</h3>
									<Accordion size="small">
										{Object.entries(selectedUser?.applications?.[activeApplication?.id]?.markets || {}).map(
											([market, uiSchema], index) => (
												<AccordionItemWrapper>
													<AccordionItem
														disabled={actionInProgress}
														key={index}
														id={market}
														title={market}
														caption={uiSchema as string}
													>
														<Select
															id="select-markets"
															label="Market"
															value={market}
															onChange={(e) => {
																onChangeMarket(market, e.target.value);
															}}
															disabled={false}
														>
															{
																/* filter out already assigned markets */
																[
																	market,
																	...(marketsData?.filter(
																		(market) =>
																			!Object.keys(
																				selectedUser.applications[activeApplication?.id]?.markets || {}
																			).includes(market)
																	) || []),
																]?.map((marketName) => (
																	<SelectOption key={marketName} value={marketName} name={marketName} />
																))
															}
														</Select>
														<Select
															id="select-uiSchema"
															label="UI-Schema"
															value={(uiSchema as string) || ""}
															onChange={(e) => {
																onChangeSchema(market, e.target.value);
															}}
															disabled={false}
														>
															{uiSchemas?.map((schema) => (
																<SelectOption
																	key={schema.uiSchemaName}
																	value={schema.uiSchemaName}
																	name={schema.uiSchemaName}
																/>
															))}
														</Select>
													</AccordionItem>
													<Button
														type="tertiary"
														text="Remove Market"
														onClick={() => {
															onRemoveMarket(market);
														}}
														iconOnly
														small
														ssrIcon={trashCan}
													/>
												</AccordionItemWrapper>
											)
										)}
									</Accordion>
								</UserMarketsListWrapper>
							)}
							<CenterButtonWrapper>
								<Button
									id="add-market-btn"
									type="secondary"
									text="Add Market"
									disabled={!selectedUser || actionInProgress}
									onClick={() => selectedUser && setShowAddMarketModal(true)}
								/>
							</CenterButtonWrapper>
						</StyledModalBody>
					</Sheets>
				</Modal>
			</form>
			<AddMarketModal
				showAddMarketModal={showAddMaketModal}
				setShowAddMarketModal={setShowAddMarketModal}
				marketsData={
					/* filter out already assigned markets */
					marketsData?.filter(
						(market) =>
							!Object.keys(selectedUser?.applications?.[activeApplication?.id || ""]?.markets || {}).includes(market) &&
							!(selectedUser?.applications?.[activeApplication?.id || ""]?.existingMarkets || [])?.includes(market)
					)
				}
				uiSchemas={uiSchemas}
				setSelectedUser={setSelectedUser}
				selectedUser={selectedUser}
				selectedMarkets={selectedMarkets}
				setSelectedMarkets={setSelectedMarkets}
			/>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				ariaLabelCloseBtn="Dismiss notification"
				onCloseRequest={() => setToastMessage("")}
			/>
			{activeApplication && (
				<Modal
					focusLockProps={{ locked: false }}
					visible={showModalRemoveUserAccessPrompt}
					handleCloseBtn={() => setShowModalRemoveUserAccessPrompt(false)}
				>
					<StyledRemoveUserAccessPrompt
						aria-label="Accessibility header for a modal"
						className="example-prompt-override"
						header={null}
						footer={
							<ModalFooter>
								<Button data-testid="close" text="Close" type="emphasised" />
								<Button
									data-testid="removeAccess"
									text="Remove access"
									onClick={() => {
										setShowModalRemoveUserAccessPrompt(false);
										onRemoveUserAccess && onRemoveUserAccess();
									}}
								/>
							</ModalFooter>
						}
						title={`Do you want to remove ${selectedUser?.name}'s access to ${activeApplication!.name}?`}
						titleId="example-prompt-override-title"
					></StyledRemoveUserAccessPrompt>
				</Modal>
			)}
		</>
	);
};

export default Layout;
