import * as React from "react";

import Modal, { ModalBody, ModalFooter, Sheets } from "@ingka/modal";
import styled from "styled-components";
import Hyperlink from "@ingka/hyperlink";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";
import TextArea from "@ingka/text-area";
import FormField from "@ingka/form-field";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import { StyledModalHeader } from "../styles";
import { SLACK_LINK } from "../../utils/constants";

const StyledTextArea = styled(TextArea)`
	label {
		display: flex;
	}
`;

const StyledHyperlink = styled(Hyperlink)`
	color: #0158a3;

	&:hover {
		color: #0158a3;
		text-decoration: none !important;
	}
`;

interface AddFeedbackModalProps {
	showAddFeedbackModal: boolean | undefined;
	setShowAddFeedbackModal: (val: boolean) => void;
	sendFeedback: () => void;
	feedbackText: string;
	setFeedbackText: (val: string) => void;
	sendFeedbackLoading: boolean;
	setShowModalFromFloatingMenu?: (val: boolean) => void;
}

const AddFeedbackModal: React.FC<AddFeedbackModalProps> = ({
	showAddFeedbackModal,
	setShowAddFeedbackModal,
	sendFeedback,
	feedbackText,
	setFeedbackText,
	sendFeedbackLoading,
	setShowModalFromFloatingMenu,
}) => {
	return (
		<Modal
			focusLockProps={{ locked: false }}
			visible={showAddFeedbackModal}
			escapable={false}
			handleCloseBtn={() => {
				if (setShowModalFromFloatingMenu) {
					setShowModalFromFloatingMenu(false);
				}
				setShowAddFeedbackModal(false);
			}}
		>
			<Sheets
				id="new-ui-schema-modal"
				title=""
				header={<StyledModalHeader title="Open feedback" />}
				footer={
					<ModalFooter>
						<Button small text="Send" loading={sendFeedbackLoading} type="emphasised" onClick={sendFeedback} />
					</ModalFooter>
				}
			>
				<ModalBody>
					<FormField>
						<StyledTextArea
							id="open-feedback"
							label="How are we doing?"
							rows={8}
							value={feedbackText}
							onChange={(e) => {
								setFeedbackText(e.target.value);
							}}
						/>
					</FormField>
					<InlineMessage
						body={
							<>
								If you'd like a response, please{" "}
								<StyledHyperlink url={SLACK_LINK} newWindow>
									contact us
								</StyledHyperlink>{" "}
								instead. Don't share your personal information.
							</>
						}
						subtle
						title="Title"
						ssrIcon={informationCircle}
						variant="informative"
					/>
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default AddFeedbackModal;
