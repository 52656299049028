import * as React from "react";
import styled from "styled-components";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, ControlElement, JsonSchema4, JsonSchema7 } from "@jsonforms/core";

import Select, { Option as SelectOption } from "@ingka/select";

import { VisibilityWrapper } from "../../styles";
import FormField from "@ingka/form-field";

import parse from "html-react-parser";
import { findAndTransformHTMLString } from "../utils";
import Tooltip from "@ingka/tooltip";

const StyledFormField = styled(FormField)`
	padding-bottom: 1rem;
`;

interface SelectProps extends ControlProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const SelectControl: React.FC<SelectProps> = ({
	id,
	data,
	handleChange,
	path,
	label,
	enabled,
	schema,
	uischema,
	description,
	...props
}) => {
	const onChange = React.useCallback(
		(e) => {
			if (e.target.value === "Choose an option") {
				return;
			}
			handleChange(path, e.target.value);
		},
		[handleChange, path]
	);

	const select = React.useMemo(
		() => (
			<Select
				id={label}
				label={label}
				value={data || schema.default || undefined}
				disabled={!enabled}
				onChange={(e) => {
					onChange(e);
				}}
			>
				{schema.enum &&
					schema?.enum?.map((data: string | { const: string; title: string }) => {
						if (schema.type === "string" || typeof data === "string") {
							return (
								<SelectOption id={data as string} key={data as string} value={data as string} name={data as string} />
							);
						}
						return <SelectOption id={data.const} key={data.const} value={data.const} name={data.title} />;
					})}

				{schema.oneOf?.map((data: JsonSchema4 | JsonSchema7) => {
					return <SelectOption id={data.const} key={data.const} value={data.const} name={data.title} />;
				})}
			</Select>
		),
		[data, enabled, label, onChange, schema.default, schema.enum, schema.oneOf, schema.type]
	);
	return (
		<VisibilityWrapper visible={props.visible}>
			<StyledFormField
				fieldHelper={{
					msg: uischema?.subText ? <div>{parse(findAndTransformHTMLString(uischema.subText))}</div> : "",
					id: "input-control-msg",
				}}
			>
				{description ? <Tooltip tooltipText={description}>{select}</Tooltip> : select}
			</StyledFormField>
		</VisibilityWrapper>
	);
};

export default withJsonFormsControlProps(SelectControl);
