import React from "react";
import { handleEnterPress, handleStringChange } from "./Input.helpers";
import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";

interface Props {
	onChange: (text: string) => void;
	placeholder?: string;
	value?: string;
	label?: string;
	type?: string;
	disabled?: boolean;
	invalidKeys?: string[];
}

const Input: React.FunctionComponent<Props> = ({
	onChange,
	placeholder,
	value = "",
	label,
	type = "text",
	disabled,
	invalidKeys
}) => {
	const [localVal, setLocalVal] = React.useState<string>("");

	React.useEffect(() => {
		setLocalVal(value);
	}, [value]);

	const onChangeValue = () => {
		if (localVal === value) return;

		onChange(localVal);
	};

	return (
		<div>
			<FormField
				fieldHelper={{
					id: "helper-msg-id",
					msg: placeholder ? placeholder : "",
				}}
				validation={{
					msg: "Value already exists",
					type: "error"
				}}
				shouldValidate={invalidKeys?.includes(localVal)}
				valid={invalidKeys?.length === 0}
			>
				<InputField
					label={label}
					id={"localVal"}
					type={type as "text" | "password" | "number"}
					value={localVal}
					onChange={handleStringChange(setLocalVal, type)}
					onBlur={onChangeValue}
					placeholder={placeholder}
					disabled={disabled}
				/>
			</FormField>
		</div>
	);
};

export default Input;
