import * as React from "react";
import Layout from "./Layout";
import useSelector, { setUserSelector, userSelector } from "../../store";
import useAuthentication from "../../hooks/useAuthentication";
import { getRequestingMarkets, requestMarkets } from "../../services/markets";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import { ResponseMessage, UserPermissions } from "../../utils/constants";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";

interface RequestNewMarketAccessProps {
	setToast?: (val: string) => void;
	appId?: string;
	onModalClose?: () => void;
}

export interface MaintainerApplications {
	id: string;
	name: string;
}

const RequestNewMarketAccess: React.FC<RequestNewMarketAccessProps> = ({ setToast, appId, onModalClose }) => {
	const [selectedApp, setSelectedApp] = React.useState<string | undefined>(undefined);
	const [selectedMarkets, setSelectedMarkets] = React.useState<string[]>([]);
	const [marketsDataLoading, setMarketsDataLoading] = React.useState<boolean>(false);
	const [marketsData, setMarketsData] = React.useState<{ key: string; name: string }[]>([]);
	const [requestingMarketsLoading, setRequestingMarketsLoading] = React.useState<boolean>(false);
	const { getToken, handleLogout } = useAuthentication();
	const { getCountryName } = useGetCountryName();

	const { getUser, data: currentUserData } = useGetLoggedInUser();

	const userData = useSelector(userSelector);
	const setUser = useSelector(setUserSelector);

	const applications = React.useMemo(() => {
		const userApps: MaintainerApplications[] = [];
		if (userData?.user.applications) {
			Object.entries(userData?.user.applications).forEach(
				(app) =>
					app[1].permissions === UserPermissions.Maintainer &&
					userApps.push({
						id: app[0],
						name: app[1].applicationName,
					})
			);
		}
		return userApps;
	}, [userData?.user.applications]);

	const onAppChange = React.useCallback(
		async ({ target: { value } }) => {
			if (value) {
				try {
					setSelectedApp(value);
					setMarketsDataLoading(true);
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					const { data: marketData } = await getRequestingMarkets(authHeader, value);
					const markets = marketData?.data
						? marketData?.data?.map((market) => ({
								key: market,
								name: getCountryName(market),
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  }))
						: [];
					setMarketsData(markets);
					setSelectedMarkets([]);
				} catch (error: any) {
					if (error?.response?.status == 401) {
						handleLogout();
					} else {
						setToast && setToast(error?.response?.data?.message as string);
					}
				} finally {
					setMarketsDataLoading(false);
				}
			}
		},
		[getCountryName, getToken, handleLogout, setToast]
	);

	React.useEffect(() => {
		if (!appId && applications.length === 1) {
			onAppChange({ target: { value: applications[0].id } });
		} else {
			onAppChange({ target: { value: appId } });
		}
	}, [appId, applications, onAppChange]);

	const onRequestMarkets = React.useCallback(async () => {
		try {
			if (selectedMarkets.length) {
				setRequestingMarketsLoading(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				let data;
				if (appId) {
					const { data: _data } = await requestMarkets(authHeader, appId, selectedMarkets);
					data = _data;
				} else if (selectedApp) {
					const { data: _data } = await requestMarkets(authHeader, selectedApp, selectedMarkets);
					data = _data;
				}
				if (data?.message === ResponseMessage.Success) {
					setSelectedApp(undefined);
					setSelectedMarkets([]);
					setToast && setToast("Markets request successfull");
					await getUser();
					onModalClose && onModalClose();
				}
			}
		} catch (error: any) {
			if (error?.response?.status == 401) {
				handleLogout();
			} else {
				setToast && setToast(error?.response?.data?.message as string);
			}
		} finally {
			setRequestingMarketsLoading(false);
		}
	}, [appId, getToken, getUser, handleLogout, onModalClose, selectedApp, selectedMarkets, setToast]);

	React.useEffect(() => {
		if (currentUserData) {
			setUser(currentUserData);
		}
	}, [setUser, currentUserData]);

	return (
		<Layout
			userApplications={applications}
			selectedApp={selectedApp}
			onAppChange={onAppChange}
			marketsData={marketsData}
			marketsDataLoading={marketsDataLoading}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			onRequestMarkets={onRequestMarkets}
			requestingMarketsLoading={requestingMarketsLoading}
			appId={appId}
		/>
	);
};

export default RequestNewMarketAccess;
