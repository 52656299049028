import Choice, { ChoiceItem } from "@ingka/choice";
import React from "react";
import styled from "styled-components";
import { RoleData } from "../RequestNewRoleModal";
import { UserPermissions } from "../../../utils/constants";
import Button from "@ingka/button";
import AddMarketModal from "../../ManageUsers/AddMarketModal/AddMarketModal";
import { MarketsData } from "../../../hooks/useGetMarkets";
import { UiSchemaData } from "../../../services/applications/types";
import MarketsAndSchemaSelector from "./MarketsAndSchemasOverview.tsx/MarketsAndSchemasOverview";
import { UserApplication } from "../../../store";
import { UserMarketPermissions } from "../../../services/users";

interface AdminRoleSelctorLayoutProps {
	rolesData: RoleData[];
	selectedRole: UserPermissions | null;
	setSelectedRole: (role: UserPermissions | null) => void;
	requestingRole: boolean;
	showAddMaketModal: boolean;
	setShowAddMarketModal: (val: boolean) => void;
	marketsLoading: boolean;
	schemasDataLoading: boolean;
	maintainerMarkets: UserMarketPermissions;
	setMaintainerMarkets: (data: UserMarketPermissions) => void;
	setIsEditingMarkets: (data: boolean) => void;
	marketsData?: MarketsData;
	schemasData?: UiSchemaData;
	app?: UserApplication & {
		id: string;
	};
}

const ChoiceWrapper = styled.div`
	.choice {
		display: flex;
		gap: 0.5rem;
	}
	.choice__list-item {
		width: 50%;
	}
`;

const StyledChoiceItem = styled(ChoiceItem)`
	min-height: 10rem;
	max-height: 10rem;
`;

const Layout: React.FC<AdminRoleSelctorLayoutProps> = ({
	rolesData,
	setSelectedRole,
	requestingRole,
	selectedRole,
	showAddMaketModal,
	setShowAddMarketModal,
	marketsData,
	marketsLoading,
	schemasData,
	schemasDataLoading,
	app,
	setMaintainerMarkets,
	maintainerMarkets,
	setIsEditingMarkets,
}) => {
	return (
		<>
			<ChoiceWrapper>
				<Choice deselectable onSelect={(e) => setSelectedRole(e)}>
					{rolesData?.map((roleData) => (
						<StyledChoiceItem
							disabled={requestingRole}
							title={roleData?.role}
							id={roleData?.id}
							caption={roleData?.description}
							selected={selectedRole === roleData.id}
							data-testid={`choice-role-${roleData?.id}`}
						/>
					))}
				</Choice>
			</ChoiceWrapper>
			{app?.permissions === UserPermissions.Admin && selectedRole === UserPermissions.Maintainer && (
				<MarketsAndSchemaSelector
					marketsData={marketsData}
					schemasData={schemasData}
					maintainerMarkets={maintainerMarkets}
					setMaintainerMarkets={setMaintainerMarkets}
					setIsEditingMarkets={setIsEditingMarkets}
				/>
			)}
		</>
	);
};

export default Layout;
