import React from "react";
import styled from "styled-components";

import Avatar from "@ingka/avatar";
import { ListViewItem } from "@ingka/list-view";

import ContentBox from "../../../ContentBox/ContentBox";
import { StyledListView } from "../../../styles";
import { Value } from "../../../../utils/types";
import { getInitialsFromEmail } from "../../../../utils/common";

const UserInfoWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	margin-bottom: 1rem;
`;

const Paragraph = styled.p`
	margin: 0;
`;

const StyledListViewItem = styled(ListViewItem)`
	.list-view-item__default {
		padding-top: 0;
		min-height: 0;
	}
`;

const NoListItem = styled.li`
	list-style-type: none;
`;

const StyledUl = styled.ul`
	padding-left: 1rem;
`;

interface RequestOverviewLayoutProps {
	value: Value;
}

const Layout: React.FC<RequestOverviewLayoutProps> = ({ value }) => {
	return (
		<>
			<ContentBox>
				<h2>Request for new application</h2>
				<UserInfoWrapper>
					<Avatar
						screenReaderText={""}
						text={getInitialsFromEmail(value.email).toUpperCase()}
						size="medium"
						variant="primary"
					/>
					<div>
						<span>
							<strong>{value.userName}</strong>
						</span>
						<span>
							<Paragraph>{value.email}</Paragraph>
						</span>
					</div>
				</UserInfoWrapper>
				<StyledListView id="request-overview" showDivider={false}>
					<StyledListViewItem emphasised id="application-id" title="Application ID:" description={value.appId} />
					<StyledListViewItem emphasised id="application-name" title="Application Name:" description={value.appName} />
					<StyledListViewItem emphasised id="application-plan" title="Plan:" description={value.plan || "-"} />
					<StyledListViewItem
						emphasised
						id="application-client-ids"
						title="Client Ids:"
						description={
							<StyledUl>
								{value.clientIds && value.clientIds.length ? (
									value.clientIds?.map((id) => <li id={id}>{id}</li>)
								) : (
									<NoListItem id="no-client-id">-</NoListItem>
								)}
							</StyledUl>
						}
					/>
					<StyledListViewItem
						emphasised
						id="application-roles"
						title="Roles for this application:"
						description={
							<StyledUl>
								<li id="roleAdmin">Admin</li>
								<li id="roleMaintainer">Maintainer</li>
								{value.allowReadOnly && <li id="roleReadOnly">Read Only</li>}
							</StyledUl>
						}
					/>
					<StyledListViewItem
						emphasised
						id="application-dashboards"
						title="Dashboard for this application:"
						description={
							<StyledUl>
								{value.showDashboard ? (
									<li id="show-dashboard-global">Global Dashboard</li>
								) : (
									<NoListItem id="no-dashboard">-</NoListItem>
								)}
								{value.showMarketDashboard && <li id="show-dashboard-market">Market Dashboard</li>}
							</StyledUl>
						}
					/>
				</StyledListView>
			</ContentBox>
		</>
	);
};

export default Layout;
