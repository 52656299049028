export const findAndTransformHTMLString = (text: string) => {
	const boldRegex = /\*([^*]+)\*/g;
	const italicRegex = /\*\*(.*?)\*\*/g;
	const strikethroughRegex = /~~([^*]+)~~/g;
	const underlineRegex = /__([^*]+)__/g;
	const newLineRegex = /<>/g;

	text = text.replace(italicRegex, "<i>$1</i>");
	text = text.replace(boldRegex, "<b>$1</b>");
	text = text.replace(strikethroughRegex, "<s>$1</s>");
	text = text.replace(underlineRegex, "<u>$1</u>");
	text = text.replace(newLineRegex, "<br>");

	const listRegex = /\[\[([^*]+)\]\]/g;

	let listMatches;
	if ((listMatches = listRegex.exec(text)) !== null) {
		const listItemsMatch = listMatches[1];
		const listItems = listItemsMatch?.split(";");
		if (listItems) {
			let htmlForList = `<ul>${listItems.map((listItem) => `<li>${listItem}</li>`)}</ul>`;
			htmlForList = htmlForList.replaceAll(",", "");
			text = text.replace(`[[${listItemsMatch}]]`, htmlForList);
		}
	}

	const linkRegex = /\[([^\]]+)]\s?\((https:\/\/[^\s)]+)\)/g;

	let matches;
	if ((matches = linkRegex.exec(text)) !== null) {
		const linkText = matches[1];
		const url = matches[2];
		text = text.replace(`[${linkText}]`, `<a href=${url} target="_blank">${linkText}</a>`);
		text = text.replace(`(${url})`, "");
	}
	return text;
};