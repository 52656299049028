import * as React from "react";
import styled from "styled-components";
import { ErrorObject } from "ajv";

import { JsonForms } from "@jsonforms/react";
import { materialCells } from "@jsonforms/material-renderers";
import { JsonFormsCore, JsonFormsRendererRegistryEntry } from "@jsonforms/core";

import { breakpointL } from "@ingka/variables/design-tokens";
import { ListViewItem } from "@ingka/list-view";
import Loading, { LoadingBall } from "@ingka/loading";
import Toast from "@ingka/toast";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";
import FormField from "@ingka/form-field";
import Select, { Option as SelectOption } from "@ingka/select";
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets, Prompt } from "@ingka/modal";
import TextArea from "@ingka/text-area";

import { getImage } from "../../utils/markets";
import { ConfigurationWrapper, LoadingWrapper, ActionsWrapper, SaveButtonWrapper, IconButtonWrapper } from "../styles";
import { SchemaData } from "../../hooks/useGetSchemas";
import { ApplicationUiSchema } from "../../services/applications/types";
import { MarketsData } from "../../hooks/useGetMarkets";
import ScheduleChangesModal from "./ScheduleChangesModal";
import { Dayjs } from "dayjs";
import { StyledModalHeader } from "../styles";
import { User } from "../../store/types";
import useLocalStorage from "../../hooks/useLocalStorage";
import Search from "@ingka/search";
import Pill from "@ingka/pill";
import pencilIcon from "@ingka/ssr-icon/paths/pencil";
import calanderIcon from "@ingka/ssr-icon/paths/calendar";
import Tooltip from "@ingka/tooltip";



interface CustomErrorObject extends ErrorObject {
	instancePath?: string;
}

const StyledMarketCheckboxWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const StyledButton = styled(Button)`
	margin-top: 1rem;
	max-width: 50%;
	left: 25%;
	margin-bottom: 4%;
`;

const StyledSelect = styled(Select)`
	width: 20rem;
	@media only screen and (max-width: ${breakpointL}) {
		width: 100%;
	} ;
`;

const StyledList = styled.ul`
	overflow-wrap: break-word;
`;

const StyledListViewItem = styled(ListViewItem)`
	.list-view-item__wrapper {
		padding: 0.5rem 0 0.5rem 0;
	}
	.list-view-item__image {
		width: 2rem;
		height: 2rem;
	}
	.checkbox input[type="checkbox"]:checked ~ .checkbox__symbol {
		background: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
		border-color: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
	}
`;

const StyledModalBody = styled(ModalBody)`
	padding-top: 0.5rem;
`;

const StyledInlineMessage = styled(InlineMessage)`
	margin-bottom: 0.5rem;
`;

const StyledSearchInlineMessage = styled(InlineMessage)`
	width: fit-content;
	margin-bottom: 1rem;
`;

const StyledSearch = styled(Search)`
	height: 3rem;
	margin-top: 1.5rem;
`;

const ConfigurationHeaderWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

interface ConfigurationLayoutProps {
	loading: boolean;
	hasChanged: boolean;
	renderers: JsonFormsRendererRegistryEntry[];
	onSave: () => void;
	onUpdate: (data: JsonFormsCore) => void;
	isAdmin: boolean;
	setSelectedUiSchema: (data?: ApplicationUiSchema) => void;
	selectedUiSchema?: ApplicationUiSchema;
	uiSchemas?: ApplicationUiSchema[];
	currentData: any;
	saveBtnLoading: boolean;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	multipleMarketFlag: boolean;
	setMultipleMarketUpdateFlag: (multipleMarketFlag: boolean) => void;
	updateMarketsSelection: (e: any) => void;
	selectedMarkets: (string | null)[];
	marketsData: MarketsData | null;
	getCountryName: (country: string) => string;
	schemaData?: SchemaData;
	isValid: boolean;
	showReasonModal: boolean;
	setShowReasonModal: (val: boolean) => void;
	setReason: (val: string) => void;
	showErrorModal: boolean;
	setShowErrorModal: (val: boolean) => void;
	JsonFormUISchemaErrors: CustomErrorObject[];
	showWarningModal: boolean;
	setShowWarningModal: (value: boolean) => void;
	onUISchemaChanged: () => void;
	showScheduleChangesModal: boolean;
	setShowScheduleChangesModal: (val: boolean) => void;
	schedulingComment: string;
	setSchedulingComment: (comment: string) => void;
	sendCopyEmail: boolean;
	setSendCopyEmail: (val: boolean) => void;
	sendScheduleChangesLoading: boolean;
	setSendScheduleChangesLoading: (val: boolean) => void;
	sendScheduleChanges: () => void;
	scheduleDate: Dayjs | null;
	setScheduleDate: (val: Dayjs | null) => void;
	scheduleToastMessage: string;
	setScheduleToastMessage: (value: string) => void;
	showUnsavedChangesModal: boolean;
	setShowUnsavedChangesModal: (value: boolean) => void;
	restoreUnsavedChanges: () => void;
	uiSchema: any;
	setUiSchema: (val: any) => void;
	currentUser: User | null;
	setSearchUserValue: (value: string) => void;
	showNoResult: boolean;
	setOriginalUiSchema: (val: ApplicationUiSchema | undefined) => void;
	searchUserValue?: string;
	onSearchConfiguration: (input: string) => void;
	onClearSearch: () => void;
	onReset: () => void;
}

const Layout: React.FC<ConfigurationLayoutProps> = ({
	schemaData,
	loading,
	hasChanged,
	renderers,
	onSave,
	onUpdate,
	isAdmin,
	setSelectedUiSchema,
	selectedUiSchema,
	uiSchemas,
	currentData,
	saveBtnLoading,
	toastMessage,
	setToastMessage,
	multipleMarketFlag,
	setMultipleMarketUpdateFlag,
	updateMarketsSelection,
	marketsData,
	selectedMarkets,
	getCountryName,
	isValid,
	showReasonModal,
	setShowReasonModal,
	setReason,
	showErrorModal,
	setShowErrorModal,
	JsonFormUISchemaErrors,
	showWarningModal,
	setShowWarningModal,
	onUISchemaChanged,
	showScheduleChangesModal,
	setShowScheduleChangesModal,
	schedulingComment,
	setSchedulingComment,
	sendCopyEmail,
	setSendCopyEmail,
	sendScheduleChangesLoading,
	setSendScheduleChangesLoading,
	sendScheduleChanges,
	scheduleDate,
	setScheduleDate,
	scheduleToastMessage,
	setScheduleToastMessage,
	showUnsavedChangesModal,
	setShowUnsavedChangesModal,
	restoreUnsavedChanges,
	uiSchema,
	setUiSchema,
	currentUser,
	setSearchUserValue,
	showNoResult,
	setOriginalUiSchema,
	searchUserValue,
	onSearchConfiguration,
	onClearSearch,
	onReset,
}) => {
	const [unsavedChangesValue, setUnsavedChangesValue] = useLocalStorage("unsavedChanges", "");

	if (loading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading configuration" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	if (!schemaData?.schema || !schemaData?.uiSchema || !schemaData.schemaValue) {
		return (
			<LoadingWrapper>
				<InlineMessage
					title="No Configuration"
					body={"Seems like you don't any configuration yet..."}
					variant="negative"
				/>
			</LoadingWrapper>
		);
	}

	return (
		<ConfigurationWrapper>
			{isAdmin && uiSchemas && (
				<ConfigurationHeaderWrapper>
					<FormField
						valid={!!selectedUiSchema}
						shouldValidate={!selectedUiSchema}
						validation={
							!selectedUiSchema ? { msg: "Please Select a UI-Schema", id: "ui-schema-error", type: "error" } : undefined
						}
						fieldHelper={{ msg: "UI-Schema to show Configuration", id: "ui-schema-msg" }}
					>
						<StyledSelect
							id="ui-schemas-selector"
							label="UI-Schemas"
							value={selectedUiSchema?.uiSchemaName}
							onChange={(e) => {
								onClearSearch();
								const newSelectedUISchema = uiSchemas?.find(
									(data) => data.uiSchemaName === (e.target.value as string) && data
								);
								if (hasChanged) {
									setShowWarningModal(true);
									setUiSchema(newSelectedUISchema);
								} else {
									setSelectedUiSchema(newSelectedUISchema);
									setOriginalUiSchema(newSelectedUISchema);
								}
							}}
						>
							{uiSchemas?.map((uiSchema, index) => (
								<SelectOption
									id={`${uiSchema.uiSchemaName}-${index}`}
									key={`${uiSchema.uiSchemaName}-${index}`}
									value={uiSchema.uiSchemaName}
									name={uiSchema.uiSchemaName}
								/>
							))}
						</StyledSelect>
					</FormField>
					<StyledSearch
						id="configurationSearch"
						value={searchUserValue}
						onChange={(e, data) => {
							setSearchUserValue(data.value);
							onSearchConfiguration(data.value);
						}}
						onSearch={(_, { value }) => onSearchConfiguration(value)}
						onClear={() => onClearSearch()}
					/>
				</ConfigurationHeaderWrapper>
			)}
			{searchUserValue && (
				<StyledSearchInlineMessage title="All your changes will be applied on saving including those that are not currently visible due to filter" />
			)}
			{showNoResult && (
				<LoadingWrapper>
					<InlineMessage
						title="No Results"
						body={"No Properties found matching the search criteria"}
						variant="negative"
					/>
				</LoadingWrapper>
			)}
			{!showNoResult &&
				(isAdmin ? (
					multipleMarketFlag ? (
						selectedUiSchema && (
							<div>
								<JsonForms
									schema={schemaData?.schema}
									uischema={selectedUiSchema.uiSchema}
									data={currentData}
									renderers={renderers}
									cells={materialCells}
									onChange={onUpdate}
									readonly={schemaData?.readOnly}
								/>
							</div>
						)
					) : (
						selectedUiSchema && (
							<div>
								<JsonForms
									schema={schemaData?.schema}
									uischema={selectedUiSchema.uiSchema}
									data={currentData}
									renderers={renderers}
									cells={materialCells}
									onChange={onUpdate}
									readonly={schemaData?.readOnly}
								/>
							</div>
						)
					)
				) : (
					<div>
						<JsonForms
							schema={schemaData?.schema}
							uischema={schemaData?.uiSchema}
							data={currentData}
							renderers={renderers}
							cells={materialCells}
							onChange={onUpdate}
							readonly={schemaData?.readOnly}
						/>
					</div>
				))}

			<ActionsWrapper>
				<SaveButtonWrapper>
					<Button
						id="save-configuration-changes"
						text="Save Changes"
						type="emphasised"
						disabled={!hasChanged || multipleMarketFlag}
						onClick={() => {
							if (isValid) {
								setShowReasonModal(true);
							} else {
								setShowErrorModal(true);
							}
						}}
						loading={saveBtnLoading}
					/>
					<Button
						id="revert-changes"
						text="Cancel"
						type="secondary"
						disabled={!hasChanged}
						onClick={() => {
							onReset();
						}}
					/>
				</SaveButtonWrapper>
				<IconButtonWrapper>
					<Tooltip position="top" tooltipText="Update Multiple Markets">
						<Pill
							id="update-configuration-multiple-markets"
							iconOnly={true}
							ssrIcon={pencilIcon}
							disabled={!isAdmin || !hasChanged || multipleMarketFlag || saveBtnLoading }
							onClick={() => {
								if (isValid) {
									setMultipleMarketUpdateFlag(true);
								} else {
									setShowErrorModal(true);
								}
							}}
						/>
					</Tooltip>
					<Tooltip position="top" tooltipText="Schedule Changes">
						<Pill
							id="reschedule-configuration-changes"
							iconOnly={true}
							disabled={!hasChanged }
							ssrIcon={calanderIcon}
							onClick={() => {
								setShowScheduleChangesModal(true);
							}}
						/>
					</Tooltip>
				</IconButtonWrapper>
			</ActionsWrapper>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>

			<Modal
				focusLockProps={{ locked: false }}
				visible={multipleMarketFlag}
				handleCloseBtn={() => {
					setMultipleMarketUpdateFlag(false);
				}}
				onModalClosed={() => {}}
			>
				<Sheets
					footer={
						<>
							{" "}
							<StyledButton
								id="save-multiple-markets-button"
								text="Save Changes"
								type="emphasised"
								onClick={() => {
									setShowReasonModal(true);
								}}
								loading={saveBtnLoading}
							/>
						</>
					}
					size="small"
					header={<StyledModalHeader title={"Select Markets"} />}
				>
					<StyledModalBody>
						<ul>
							{multipleMarketFlag && (
								<>
									<StyledInlineMessage
										title="While updating arrays, entire array value will be overwritten for all markets"
										variant="cautionary"
									/>
									<StyledListViewItem
										id="all"
										emphasised
										leadingIconPosition="center"
										control="checkbox"
										controlProps={
											{
												value: "all",
												checked:
													marketsData?.permittedMarkets &&
													selectedMarkets.length === marketsData?.permittedMarkets.length
														? true
														: false,
											} as any
										}
										onChange={updateMarketsSelection}
										title={"Select All"}
									/>
								</>
							)}
							<StyledMarketCheckboxWrapper>
								{multipleMarketFlag &&
									marketsData?.permittedMarkets?.map((market) => {
										return (
											<StyledListViewItem
												id={market}
												image={getImage(market)}
												leadingIconPosition="center"
												control="checkbox"
												controlProps={{ value: market, checked: selectedMarkets?.includes(market) || false } as any}
												onChange={updateMarketsSelection}
												title={
													<>
														<strong>{market}</strong> | {getCountryName(market)}
													</>
												}
											/>
										);
									})}
							</StyledMarketCheckboxWrapper>
						</ul>
					</StyledModalBody>
				</Sheets>
			</Modal>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showReasonModal}
				escapable={false}
				handleCloseBtn={() => setShowReasonModal(false)}
			>
				<Prompt
					data-testid="reason-for-changes-modal"
					id="reason-for-changes-modal"
					title={null}
					titleId={"modalTitleId"}
					header={<StyledModalHeader title="Please write a reason for change" />}
					footer={
						<ModalFooter>
							<Button
								data-testid="reason-cancel-button"
								id="reason-save-button"
								text="Save changes"
								type="emphasised"
								onClick={() => onSave()}
							/>
							<Button
								data-testid="reason-cancel-button"
								id="reason-cancel-button"
								text="Cancel"
								type="secondary"
								onClick={() => setShowReasonModal(false)}
							/>
						</ModalFooter>
					}
				>
					<TextArea id="reason-text-area" label="" onChange={(ev) => setReason(ev.target.value)} />
				</Prompt>
			</Modal>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showErrorModal}
				escapable={false}
				handleCloseBtn={() => setShowErrorModal(false)}
			>
				<Prompt
					title=""
					titleId={"modalTitleId"}
					header={<StyledModalHeader title="Error while updating configuration" />}
					footer={
						<ModalFooter>
							<Button text="OK" type="emphasised" onClick={() => setShowErrorModal(false)} />
						</ModalFooter>
					}
				>
					<p>Following errors occured while validating schema:</p>
					<StyledList>
						{JsonFormUISchemaErrors.map((schemaError) => (
							<li>
								{schemaError.keyword === "required"
									? schemaError.message
									: schemaError.schemaPath.includes("oneOf")
										? `Select in ${schemaError.instancePath} is required`
										: `${schemaError.schemaPath} - ${schemaError.message}`}
							</li>
						))}
					</StyledList>
				</Prompt>
			</Modal>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showWarningModal}
				escapable={false}
				handleCloseBtn={() => setShowWarningModal(false)}
			>
				<Prompt
					title="Warning"
					titleId={"modalTitleId"}
					footer={
						<ModalFooter>
							<Button
								text="OK"
								type="emphasised"
								onClick={() => {
									setSelectedUiSchema(uiSchema);
									setShowWarningModal(false);
									onUISchemaChanged();
								}}
							/>
							<Button text="Cancel" type="emphasised" onClick={() => setShowWarningModal(false)} />
						</ModalFooter>
					}
					header={null}
				>
					<p>Are you sure you want to change ui-schema? All unsaved changes to current ui-schema will be lost.</p>
				</Prompt>
			</Modal>
			{unsavedChangesValue && currentUser?.user.id === (unsavedChangesValue as any).userId && (
				<Modal
					focusLockProps={{ locked: false }}
					visible={showUnsavedChangesModal}
					escapable={false}
					handleCloseBtn={() => {
						setUnsavedChangesValue("");
						setShowUnsavedChangesModal(false);
					}}
				>
					<Prompt
						title=""
						titleId={"modalTitleId"}
						header={<StyledModalHeader title="Changes were unsaved" />}
						footer={
							<ModalFooter>
								<Button
									text="Continue to work with changes"
									type="emphasised"
									onClick={() => {
										setShowUnsavedChangesModal(false);
										restoreUnsavedChanges();
									}}
								/>
								<Button
									text="Remove unsaved changes"
									type="secondary"
									onClick={() => {
										setUnsavedChangesValue("");
										setShowUnsavedChangesModal(false);
									}}
								/>
							</ModalFooter>
						}
					>
						{unsavedChangesValue && typeof unsavedChangesValue === "object" && (
							<>
								<span>You have unsaved changes for </span>
								<strong>{(unsavedChangesValue as any).activeApplicationId} </strong>
								<span>for </span>
								<strong>{(unsavedChangesValue as any).activeMarket}</strong>
								<span> market for </span>
								<strong>{(unsavedChangesValue as any).uiSchemaName}</strong>.
								<span> Do you want to continue working it?</span>
							</>
						)}
					</Prompt>
				</Modal>
			)}
			<ScheduleChangesModal
				showScheduleChangesModal={showScheduleChangesModal}
				setShowScheduleChangesModal={setShowScheduleChangesModal}
				schedulingComment={schedulingComment}
				setSchedulingComment={setSchedulingComment}
				sendCopyEmail={sendCopyEmail}
				setSendCopyEmail={setSendCopyEmail}
				sendScheduleChangesLoading={sendScheduleChangesLoading}
				setSendScheduleChangesLoading={setSendScheduleChangesLoading}
				sendScheduleChanges={sendScheduleChanges}
				scheduleDate={scheduleDate}
				setScheduleDate={setScheduleDate}
				scheduleToastMessage={scheduleToastMessage}
				setScheduleToastMessage={setScheduleToastMessage}
			/>
		</ConfigurationWrapper>
	);
};
export default Layout;
