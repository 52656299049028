import * as React from "react";

import { Applications } from "../NewUser/types";
import Layout from "./Layout";
import useAuthentication from "../../hooks/useAuthentication";
import useStore, { applicationsSelector, setUserSelector, userSelector } from "../../store";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import { getApplicationAccess } from "../../services/applications";
import useGetApplications from "../../hooks/useGetApplications";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";
import { Routes } from "../../utils/types";
import { useNavigate } from "react-router";
import useGetMarkets from "../../hooks/useGetMarkets";

interface RequestNewApplicationAccessProps {
	showRequestAppModal?: boolean;
	setShowRequestAppModal?: (value: boolean) => void;
	setToast: (val: string) => void;
	onSuccessRedirectRoute?: Routes;
}

const RequestNewApplicationAccess: React.FC<RequestNewApplicationAccessProps> = ({
	showRequestAppModal,
	setShowRequestAppModal,
	setToast,
	onSuccessRedirectRoute,
}) => {
	const [selectedApplication, setSelectedApplication] = React.useState<Applications | undefined>(undefined);
	const { getUser, loading: userLoading, data: currentUser } = useGetLoggedInUser();
	const { data: marketsData, loading: marketsLoading, getMarketsData } = useGetMarkets();
	const [accessReqLoading, setAccessReqLoading] = React.useState<boolean>(false);
	const [selectedMarkets, setSelectedMarkets] = React.useState<string[]>([]);
	const { getToken, handleLogout } = useAuthentication();
	const onBoardedApplications = useStore(applicationsSelector);
	const setUser = useStore(setUserSelector);
	const { getCountryName } = useGetCountryName();

	const navigate = useNavigate();

	const { data, loading, getApplicationsData } = useGetApplications();

	React.useEffect(() => {
		getApplicationsData(true);
	}, [getApplicationsData]);

	const [appsForRequest, setAppsForRequest] = React.useState<Applications[] | undefined>(undefined);

	React.useEffect(() => {
		if (!currentUser) {
			getUser();
		}
	}, [currentUser, getUser]);

	React.useEffect(() => {
		if (data) {
			setAppsForRequest(
				data?.filter((app) => {
					if (
						!onBoardedApplications?.some((i) => i?.id === app?.id) &&
						!currentUser?.user?.requestedApplications?.some((i) => i?.application === app?.id)
					) {
						return app;
					}
				})
			);
		}
	}, [data, onBoardedApplications, currentUser?.user?.requestedApplications]);

	React.useEffect(() => {
		if (selectedApplication?.id) {
			getMarketsData(selectedApplication.id, true);
		}
	}, [getCountryName, getMarketsData, getToken, selectedApplication]);

	const onRequestAccess = React.useCallback(async () => {
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		if (selectedApplication) {
			try {
				setAccessReqLoading(true);
				const { data: response } = await getApplicationAccess(authHeader, selectedApplication.id, selectedMarkets);
				if (response?.message?.toLowerCase() === "success") {
					setToast("Request Access Successfull");
					await getUser();
					onSuccessRedirectRoute && navigate(onSuccessRedirectRoute);
					setSelectedApplication(undefined);
					showRequestAppModal && setShowRequestAppModal && setShowRequestAppModal(false);
				}
			} catch (error: any) {
				if (error) {
					console.log({ error });
					const response = JSON.parse(error?.request?.response);
					setToast(
						response?.errors && response?.errors[0] && response?.errors[0].message
							? response?.errors[0].message
							: response.message
					);
					if (error?.response?.status == 401) {
						handleLogout();
					}
				}
			} finally {
				setAccessReqLoading(false);
			}
		}
	}, [
		getToken,
		getUser,
		handleLogout,
		navigate,
		onSuccessRedirectRoute,
		selectedApplication,
		selectedMarkets,
		setShowRequestAppModal,
		setToast,
		showRequestAppModal,
	]);

	React.useEffect(() => {
		if (currentUser) {
			setUser(currentUser);
		}
	}, [currentUser, setUser]);

	return (
		<Layout
			selectedApplication={selectedApplication}
			setSelectedApplication={setSelectedApplication}
			data={appsForRequest}
			onRequestAccess={onRequestAccess}
			accessReqLoading={accessReqLoading}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			marketsData={
				marketsData?.otherMarkets?.length
					? marketsData?.otherMarkets.map((market) => ({
							key: market,
							name: getCountryName(market),
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }))
					: []
			}
			applicationsLoading={loading || userLoading}
			marketsDataLoading={marketsLoading}
		/>
	);
};

export default RequestNewApplicationAccess;
