import { AxiosResponse } from "axios";
import axios from "../../configs/axios";
import { AuthHeader } from "../../types";

export interface MarketsData {
	permittedMarkets: string[];
	otherMarkets?: string[];
}
interface MarketResponseData {
	data: { markets: MarketsData; admin: boolean };
}
interface RequestingMarketsResponseData {
	data: string[];
}
interface Data {
	value: Object;
}
interface UpdateMarketResponse {
	message: string;
	data: Data;
}

interface RemoveMarketResponse {
	data: {
		message: string;
	};
}

interface RequestMarketsResponse {
	message: string;
}

interface MarketRequestData extends AuthHeader {}

export const getMarkets = async (header: AuthHeader, application: String, isOnboarding?: Boolean) => {
	const getMarketsUrl = isOnboarding
		? `/application/${application}/markets?onboard=true`
		: `/application/${application}/markets`;
	const data: AxiosResponse<MarketResponseData, MarketRequestData> = await axios.get(getMarketsUrl, {
		...header,
	});
	return data;
};

export const getRequestingMarkets = async (header: AuthHeader, application: String) => {
	const data: AxiosResponse<RequestingMarketsResponseData, MarketRequestData> = await axios.get(
		`/application/${application}/markets/access/request`,
		{
			...header,
		}
	);
	return data;
};

export const updateMarkets = async (header: AuthHeader, application: string, market: string, value: any) => {
	const data: AxiosResponse<UpdateMarketResponse, AuthHeader> = await axios.post(
		`/application/${application}/market/${market}/schema/value`,
		{ value },
		{
			...header,
		}
	);
	return data;
};
export const requestMarkets = async (header: AuthHeader, application: string, markets: string[]) => {
	const data: AxiosResponse<RequestMarketsResponse, AuthHeader> = await axios.post(
		`/application/${application}/markets/access/request`,
		{ markets },
		{
			...header,
		}
	);
	return data;
};

export const removeMarkets = async (header: AuthHeader, application: string, markets: string) => {
	const data: AxiosResponse<RemoveMarketResponse, AuthHeader> = await axios.delete(
		`/application/${application}/market/?markets=${markets}`,
		{
			...header,
		}
	);
	return data;
};


export const copyMarketValues = async (header: AuthHeader, marketFrom: string, marketsTo: string, application: string) => {
	const data: AxiosResponse<RequestMarketsResponse, AuthHeader> = await axios.post(
		`/application/${application}/market/schema/value/copy`,
		{ marketFrom, marketsTo },
		{
			...header,
		}
	);
	return data;
};
