import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "@ingka/page-container/dist/style.css";
import "@ingka/action-list/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/inline-text/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/link-list/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/toast/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/card/dist/style.css";
import "@ingka/typography/dist/style.css";
import "@ingka/accordion/dist/style.css";
import "@ingka/loading/dist/style.css";
import "@ingka/table/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/skeleton/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/banner/dist/style.css";
import "@ingka/toggle/dist/style.css";
import "@ingka/tooltip/dist/style.css";
import "@ingka/tag/dist/style.css";
import "@ingka/commercial-message/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/list-view/dist/style.css";
import "@ingka/list-box/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/text/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/status/dist/style.css";
import "@ingka/avatar/dist/style.css";
import "@ingka/product-identifier/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/badge/dist/style.css";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<BrowserRouter>
		<App msalInstance={msalInstance} />
	</BrowserRouter>,
	document.getElementById("root")
);

reportWebVitals();
