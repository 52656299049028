import * as React from "react";
import Modal, { ModalBody, Sheets } from "@ingka/modal";
import documentAdd from "@ingka/ssr-icon/paths/document-add";
import plus from "@ingka/ssr-icon/paths/plus";
import documentUpdate from "@ingka/ssr-icon/paths/document-pencil";
import arrowPairSquarepath from "@ingka/ssr-icon/paths/arrow-pair-squarepath";
import handWithKey from "@ingka/ssr-icon/paths/hand-with-key";
import people from "@ingka/ssr-icon/paths/people";
import { ListViewItem } from "@ingka/list-view";
import Toast from "@ingka/toast";

import { StyledDivider, StyledListView, StyledModalHeader } from "../../styles";
import AddApplicationModal from "../../Applications/AddApplicationModal/AddApplicationModal";
import BackupAndRestoreModal from "../../BackupAndRestoreModal/BackupAndRestoreModal";
import { ROUTE_REQUESTS, ROUTE_USERS } from "../../../utils/constants";
import ManageScheduledChangesModal from "../ManageScheduledChangesModal/ManageScheduledChangesModal";
import RequestNewApplicationAccess from "../../RequestNewApplicationAccess";
import createNew from "@ingka/ssr-icon/paths/create-new";

import clock from "@ingka/ssr-icon/paths/clock";
import AdminsModal from "../AdminsModal/AdminsModal";
import styled from "styled-components";

import useStore, { activeApplicationSelector, setShowManageScheduledChangesModalSelector } from "../../../store";

interface AppsSettingsLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	isFlaggaAdmin: boolean;
	isApplicationAdmin: boolean;
	showBackupAndRestoreModal: boolean;
	setShowBackupAndRestoreModal: (value: boolean) => void;
	showAddUpdateDeleteAppModal: boolean;
	setShowAddUpdateDeleteAppModal: (value: boolean) => void;
	showRequestAppModal: boolean;
	setShowRequestAppModal: (value: boolean) => void;
	toast: string;
	setToast: (val: string) => void;
	showCreateNewApplicationModal: boolean;
	setShowCreateNewApplicationModal: (value: boolean) => void;
	showAdminsModal: boolean;
	setShowAdminsModal: (value: boolean) => void;
}

const StyledHeader = styled.h3`
	margin-top: 1.5rem;
`;

const Layout: React.FC<AppsSettingsLayoutProps> = ({
	showModal,
	setShowModal,
	isFlaggaAdmin,
	isApplicationAdmin,
	showBackupAndRestoreModal,
	setShowBackupAndRestoreModal,
	showAddUpdateDeleteAppModal,
	setShowAddUpdateDeleteAppModal,
	showRequestAppModal,
	setShowRequestAppModal,
	toast,
	setToast,
	showCreateNewApplicationModal,
	setShowCreateNewApplicationModal,
	showAdminsModal,
	setShowAdminsModal,
}) => {
	const activeApplication = useStore(activeApplicationSelector);
	const setShowManageScheduledChangesModal = useStore(setShowManageScheduledChangesModalSelector);

	return (
		<>
			<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
				<Sheets
					id="apps-settings-modal-sheet"
					footer={null}
					header={<StyledModalHeader title="Applications Settings" />}
				>
					<ModalBody>
						{!isFlaggaAdmin && (
							<>
								<StyledListView id="request-access" showDivider={false}>
									<>
										<ListViewItem
											title={"Request Access to Existing Applications"}
											id={"request-access-to-existing-applications"}
											controlIcon="chevronRight"
											emphasised
											control="navigational"
											showDivider={true}
											leadingIcon={plus}
											onClick={() => {
												setShowRequestAppModal(true);
											}}
										/>
										<ListViewItem
											title={"Create New Application"}
											id={"create-new-application"}
											controlIcon="chevronRight"
											emphasised
											control="navigational"
											showDivider={true}
											leadingIcon={createNew}
											onClick={() => {
												setShowCreateNewApplicationModal(true);
											}}
										/>
										<ListViewItem
											title="Manage scheduled changes"
											control="navigational"
											name="manage-scheduled-changes"
											id="manage-scheduled-changes"
											controlIcon="chevronRight"
											showDivider={true}
											leadingIcon={clock}
											emphasised
											onClick={() => {
												setShowManageScheduledChangesModal(true);
											}}
										/>
										<ListViewItem
											title="Application Administrators"
											control="navigational"
											name="manage-scheduled-changes"
											id="manage-scheduled-changes"
											controlIcon="chevronRight"
											showDivider={true}
											leadingIcon={people}
											emphasised
											onClick={() => {
												setShowAdminsModal(true);
											}}
										/>
									</>
								</StyledListView>
							</>
						)}

						{(isFlaggaAdmin || isApplicationAdmin) && <StyledHeader>Admin Options</StyledHeader>}
						<StyledListView id="appliactions-settings" showDivider={false}>
							{isApplicationAdmin && !isFlaggaAdmin && (
								<>
									<ListViewItem
										key="update-application"
										title="Update App"
										control="navigational"
										name="update-application"
										id="update-application"
										controlIcon="chevronRight"
										leadingIcon={documentUpdate}
										emphasised
										onClick={() => {
											setShowAddUpdateDeleteAppModal(true);
										}}
									/>
								</>
							)}
							{isFlaggaAdmin && (
								<>
									<ListViewItem
										title="Add / Update / Delete Application"
										control="navigational"
										name="add-and-update-application"
										id="add-and-update-application"
										controlIcon="chevronRight"
										leadingIcon={documentAdd}
										emphasised
										onClick={() => {
											setShowAddUpdateDeleteAppModal(true);
										}}
									/>
									<ListViewItem
										title="Application Requests"
										control="link"
										name="application-requests"
										id="application-requests"
										leadingIcon={handWithKey}
										emphasised
										href={ROUTE_REQUESTS}
									/>
									<ListViewItem
										title="Users"
										control="link"
										name="all-flagga-users"
										id="all-flagga-users"
										leadingIcon={people}
										emphasised
										href={ROUTE_USERS}
									/>
								</>
							)}

							{(isApplicationAdmin || isFlaggaAdmin) && (
								<ListViewItem
									title="Backup and Restore Application"
									control="navigational"
									name="backup-and-restore-application"
									id="backup-and-restore-application"
									controlIcon="chevronRight"
									leadingIcon={arrowPairSquarepath}
									emphasised
									onClick={(e) => {
										setShowBackupAndRestoreModal(true);
									}}
								/>
							)}
						</StyledListView>
					</ModalBody>
				</Sheets>
			</Modal>
			<AddApplicationModal showModal={showAddUpdateDeleteAppModal} setShowModal={setShowAddUpdateDeleteAppModal} />
			<BackupAndRestoreModal showModal={showBackupAndRestoreModal} setShowModal={setShowBackupAndRestoreModal} />
			<ManageScheduledChangesModal />
			<AdminsModal
				activeApplication={activeApplication}
				showModal={showAdminsModal}
				setShowModal={setShowAdminsModal}
			/>
			<AddApplicationModal
				showModal={showCreateNewApplicationModal}
				setShowModal={setShowCreateNewApplicationModal}
				createNewApp={true}
			/>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showRequestAppModal}
				handleCloseBtn={() => setShowRequestAppModal(false)}
			>
				<Sheets
					id="request-access-to-existing-application-modal"
					footer={null}
					header={<StyledModalHeader title="Request Access to Existing Application" />}
				>
					<ModalBody>
						<RequestNewApplicationAccess
							showRequestAppModal={showRequestAppModal}
							setShowRequestAppModal={setShowRequestAppModal}
							setToast={setToast}
						/>
					</ModalBody>
				</Sheets>
			</Modal>
			<Toast
				id="apps-settings-toast"
				text={toast}
				isOpen={!!toast}
				onCloseRequest={() => setToast("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default Layout;
