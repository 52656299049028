import * as React from "react";
import { RendererProps } from "@jsonforms/core";
import { ResolvedJsonFormsDispatch, useJsonForms, withJsonFormsLayoutProps } from "@jsonforms/react";
import { GroupLayout as IGroupLayout } from "@jsonforms/core/lib/models/uischema";
import { HorizontalLayoutWrapper } from "../../styles";

const HorizontalLayout: React.FC<RendererProps> = ({ uischema, path, schema, visible }) => {
	const { elements } = uischema as IGroupLayout;
	const { renderers, cells } = useJsonForms();

	if (!visible) {
		return null;
	}

	return (
		<HorizontalLayoutWrapper>
			{elements.map((child, index) => (
				<ResolvedJsonFormsDispatch
					renderers={renderers}
					cells={cells}
					uischema={child}
					schema={schema}
					path={path}
					key={`${path}-${index}`}
				/>
			))}
		</HorizontalLayoutWrapper>
	);
};

export default withJsonFormsLayoutProps(HorizontalLayout);
