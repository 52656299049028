import * as React from "react";
import { getUiSchemaList } from "../services/applications";
import { UiSchemaData } from "../services/applications/types";
import useStore, { activeApplicationSelector } from "../store";
import useAuthentication from "./useAuthentication";

interface GetUiSchemasArgs {
	appId: string;
	getUiSchemasData?: boolean;
	getSchemaData?: boolean;
	importedSchema?: boolean;
}

const useGetUiSchemas = ({ getUiSchemasData, getSchemaData, appId }: GetUiSchemasArgs) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<UiSchemaData | undefined>(undefined);
	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		const getUiSchema = async () => {
			try {
				if (appId) {
					setLoading(true);
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					const { data: UiSchemaData } = await getUiSchemaList(authHeader, appId, {
						uiSchema: getUiSchemasData,
						getSchema: getSchemaData,
					});
					setData(UiSchemaData.data);
				}
			} catch (error) {
				//TODO: handle error
				setData(undefined);
				setLoading(false);
				console.error(error);
				// handleLogout();
			} finally {
				setLoading(false);
			}
		};
		getUiSchema();
	}, [appId, getSchemaData, getToken, getUiSchemasData, handleLogout]);

	return { data, loading };
};

export default useGetUiSchemas;
