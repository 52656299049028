import * as React from "react";
import styled from "styled-components";

import Tooltip from "@ingka/tooltip";

import { getImage } from "../utils/markets";
import { useGetCountryName } from "../hooks/useGetCountryName";

interface FlagsRowPros {
	markets: string[];
	size: size;
	tooltipBoundaryContainerID?: string;
	truncateAfter?: number;
}

interface MarketImageProps {
	height: size;
	width: size;
}

type size = 25 | 50 | 75 | 100 | 125 | 150 | 200 | 250 | 300 | 400 | 550 | 750 | 1000 | 1350;

const MarketImage = styled.img<MarketImageProps>`
	height: ${({ height }) => (height ? height / 100 + "rem" : "0")};
	width: ${({ width }) => (width ? width / 100 + "rem" : "0")};
	margin-right: 0.5rem;
	margin-top: 0.5rem;
`;

const MarketsWrapper = styled.div`
	display: flex;
	align-items: center;
	overflow: auto;
	flex-wrap: wrap;
`;

const StyledParagraph = styled.p`
	margin: 0;
`;

const FlagsRow: React.FC<FlagsRowPros> = ({ markets, size, tooltipBoundaryContainerID, truncateAfter }) => {
	const marketsData = [];
	const { getCountryName } = useGetCountryName();
	for (let i = 0; i < markets.length; i++) {
		if (truncateAfter ? i < truncateAfter : i < 999) {
			marketsData.push(
				<MarketImage src={getImage(markets[i]) || getImage("xx")} title={markets[i]} width={size} height={size} />
			);
		} else {
			marketsData.push(
				<StyledParagraph>
					<strong>...</strong>
				</StyledParagraph>
			);
			break;
		}
	}

	const marketsTooltipText = (_markets: string[]) =>
		_markets
			.map((market) => `${getCountryName(market)}(${market})`)
			.toString()
			.replaceAll(",", ", ")
			.replace(/,(?=[^,]+$)/, " and ");

	return marketsData.length ? (
		<Tooltip boundaryContainerID={tooltipBoundaryContainerID} position="top" tooltipText={marketsTooltipText(markets)}>
			<MarketsWrapper>{marketsData.map((market) => market)}</MarketsWrapper>
		</Tooltip>
	) : (
		<StyledParagraph>-</StyledParagraph>
	);
};

export default FlagsRow;
