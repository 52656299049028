import * as React from "react";
import styled from "styled-components";

export type Spacings = 25 | 50 | 75 | 100 | 125 | 150 | 200 | 250 | 300 | 400 | 550 | 750 | 1000 | 1350;

export interface SpacingProps {
	top?: Spacings;
	bottom?: Spacings;
	left?: Spacings;
	right?: Spacings;
}

const toRem = (spacing?: Spacings): string => {
	if (!spacing) {
		return "0";
	}

	return `${spacing / 100}rem`;
};

const createPadding = ({ top, right, bottom, left }: SpacingProps): string =>
	[top, right, bottom, left].map(toRem).join(" ");

export const StyledContainer = styled.div<SpacingProps>`
	padding: ${(props): string => createPadding(props)};
`;

const Spacing: React.FC<SpacingProps> = ({ children, ...spacing }) => (
	<StyledContainer {...spacing}>{children}</StyledContainer>
);

export default Spacing;
