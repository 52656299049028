import * as React from "react";
import useAuthentication from "./useAuthentication";
import { getUsersList, UserData } from "../services/users";


const useGetAllUsers = () => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | undefined>(undefined);
    const [allUsers, setAllUsers] = React.useState<UserData[]>([]);
	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		(async () => {
            try {
                setLoading(true);
                const token = await getToken();
                const { data: data } = await getUsersList(token);
                setAllUsers(data?.data.users || []);
            } catch (error: any) {
                if (error) {
                    setAllUsers([]);
                    setError(error?.response?.data?.message as string);
                    if (error?.response?.status == 401) {
                        handleLogout();
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [getToken, handleLogout]);

	return { loading, allUsers, error };
};

export default useGetAllUsers;
