import React from "react";
import Layout from "./Layout";

const JoinExistingApplication = () => {
	const [toast, setToast] = React.useState<string>("");

	return <Layout toast={toast} setToast={setToast} />;
};

export default JoinExistingApplication;
