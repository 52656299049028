/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import assert from "assert";
import { useSearchParams } from "react-router-dom";

import useStore, {
	activeApplicationSelector,
	userSelector,
	setApplicationUsersSelector,
	applicationUsersSelector,
} from "../../store";
import useAuthentication from "../../hooks/useAuthentication";
import {
	getUsersList,
	UserData,
	getAppUsersList,
	ApplicationOnboardedUsers,
	UserApplicationData,
} from "../../services/users";
import Layout from "./Layout";
import useGetMarkets from "../../hooks/useGetMarkets";
import { updateUser, userType } from "./utils";
import { ResponseMessage, UserPermissions } from "../../utils/constants";
import useGetUiSchemasName from "./useGetUiSchemasName";

const ManageUsers: React.FC = () => {
	const activeApplication = useStore(activeApplicationSelector);
	const { data: marketsData, loading: marketsLoading, getMarketsData } = useGetMarkets();
	const { data: uiSchemas, loading: uiSchemasLoading } = useGetUiSchemasName();
	const [usersLoading, setUsersLoading] = React.useState<boolean>(true);
	const [appUsersLoading, setAppUsersLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<UserData[]>([]);
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [modalHeading, setModalHeading] = React.useState<string>("");
	const [modalButtonLabel, setModalButtonLabel] = React.useState<string>("");
	const [selectedUser, setSelectedUser] = React.useState<UserApplicationData | undefined>(undefined);
	const [addingNewUser, setAddingNewUser] = React.useState<boolean>(false);
	const [onboardingUser, setOnboardingUser] = React.useState<boolean>(false);
	const currentUser = useStore(userSelector);
	const { getToken, handleLogout } = useAuthentication();
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const action = new URLSearchParams(location.search).get("action");
	const [searchParams, setSearchParam] = useSearchParams();
	const [toggleUserTypeBtnIndex, setToggleUserTypeBtnIndex] = React.useState<number>(0);
	const [searchUserValue, setSearchUserValue] = React.useState<string>("");
	const [selectedMarkets, setSelectedMarkets] = React.useState<string[] | undefined>([]);
	const [showRemoveUsersModal, setShowRemoveUsersModal] = React.useState<boolean>(false);
	const [removeUsersLoading, setRemoveUsersLoading] = React.useState<boolean>(false);

	const setApplicationUsers = useStore(setApplicationUsersSelector);
	const applicationUsers = useStore(applicationUsersSelector);

	const loading = React.useMemo(
		() => marketsLoading || uiSchemasLoading || usersLoading,
		[marketsLoading, uiSchemasLoading, usersLoading]
	);

	const currentUserIsAdmin = React.useMemo(
		() =>
			(!!activeApplication &&
				!!currentUser &&
				currentUser.user.applications?.[activeApplication.id]?.permissions === UserPermissions.Admin) ||
			currentUser?.user["flagga-admin"],
		[activeApplication, currentUser]
	);

	React.useEffect(() => {
		if (activeApplication?.id) {
			getMarketsData(activeApplication.id);
		}
	}, [activeApplication?.id, getMarketsData]);

	React.useEffect(() => {
		if (!currentUserIsAdmin) {
			setSearchParam({});
		}
	}, [currentUserIsAdmin, setSearchParam]);

	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			try {
				setUsersLoading(true);
				const { data: usersData } = await getUsersList(token, { application: activeApplication?.id || "" });
				setData(usersData.data.users.length ? usersData.data.users : []);
			} catch (error: any) {
				setData([]);
				if (error?.response?.status == 401) {
					handleLogout();
				}
			} finally {
				setUsersLoading(false);
			}
		})();

		return () => {
			// cancel the subscription
			setData([]);
			setUsersLoading(false);
		};
	}, [getToken, handleLogout, activeApplication?.id]);

	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			try {
				setAppUsersLoading(true);
				const { data: usersData } = await getAppUsersList(token, activeApplication?.id);
				setApplicationUsers(usersData.data);
			} catch (error: any) {
				setApplicationUsers(null);
				if (error?.response?.status == 401) {
					handleLogout();
				}
			} finally {
				setAppUsersLoading(false);
				setToggleUserTypeBtnIndex(Object.keys(userType).indexOf("all"));
			}
		})();

		return () => {
			// cancel the subscription
			setApplicationUsers(null);
			setAppUsersLoading(false);
		};
	}, [activeApplication, getToken, handleLogout, setApplicationUsers]);

	const currentUserSelected = React.useMemo(
		() => !!selectedUser && !!currentUser && currentUser.user.id === selectedUser.id,
		[currentUser, selectedUser]
	);

	const usersInApplication = React.useMemo(() => {
		let usersOnboarded = applicationUsers?.onboardedUsers;
		if (toggleUserTypeBtnIndex === Object.keys(userType).indexOf("maintainer")) {
			usersOnboarded = usersOnboarded?.filter(
				(user: ApplicationOnboardedUsers) =>
					user.applications[activeApplication!.id]?.markets &&
					Object.keys(user.applications[activeApplication!.id]?.markets!).length &&
					user.applications[activeApplication!.id]?.permissions === "maintainer"
			);
		} else if (toggleUserTypeBtnIndex === Object.keys(userType).indexOf("admin")) {
			usersOnboarded = usersOnboarded?.filter((user: ApplicationOnboardedUsers) => user.isApplicationAdmin);
		} else if (toggleUserTypeBtnIndex === Object.keys(userType).indexOf("readOnly")) {
			usersOnboarded = usersOnboarded?.filter((user: ApplicationOnboardedUsers) =>
				user.applications[activeApplication!.id]?.markets
					? !Object.keys(user.applications[activeApplication!.id]?.markets!).length
					: user.applications[activeApplication!.id]?.permissions === "maintainer"
			);
		}

		if (searchUserValue) {
			usersOnboarded = usersOnboarded?.filter(
				(user: ApplicationOnboardedUsers) =>
					user.name.toLowerCase().includes(searchUserValue.toLowerCase()) ||
					user.email.toLowerCase().includes(searchUserValue.toLowerCase())
			);
		}
		return usersOnboarded || [];
	}, [applicationUsers?.onboardedUsers, toggleUserTypeBtnIndex, searchUserValue, activeApplication]);

	const hasChangedUser = React.useMemo(() => {
		try {
			assert.deepStrictEqual(selectedUser || {}, data?.find((user) => user.id === selectedUser?.id) || {});
			return false;
		} catch (e) {
			return true;
		}
	}, [data, selectedUser]);

	const _marketsData = React.useMemo(() => {
		if (activeApplication && selectedUser) {
			const currentUserMarket = Object.keys(selectedUser?.applications?.[activeApplication?.id]?.markets || {});
			return marketsData?.permittedMarkets;
		}
	}, [selectedUser, activeApplication, marketsData]);

	const isAdmin = React.useMemo(
		() =>
			!!activeApplication &&
			!!selectedUser &&
			selectedUser.applications?.[activeApplication.id]?.permissions === UserPermissions.Admin,
		[activeApplication, selectedUser]
	);

	const handleClearURLProps = React.useCallback(() => {
		const params = Object.fromEntries(searchParams.entries());
		params.action && delete params.action;
		params.email && delete params.email;
		setSearchParam({ ...params });
	}, [searchParams, setSearchParam]);

	const onRemoveMultipleUserAccess = React.useCallback(
		async (selectedUsers) => {
			setRemoveUsersLoading(true);
			let aPromises = [] as any;
			const token = await getToken();
			selectedUsers.forEach((selectedUser: any) => {
				let userUpdateInfo = {
					user: selectedUser.original,
					userApplication: {
						[activeApplication!.id]: null,
					},
					token,
				};
				aPromises.push(updateUser(userUpdateInfo));
			});
			Promise.all(aPromises)
				.then(async () => {
					const { data: usersData } = await getUsersList(token, { application: activeApplication?.id || "" });
					setData(usersData.data.users.length ? usersData.data.users : []);
					const { data: usersAppData } = await getAppUsersList(token, activeApplication?.id);
					setApplicationUsers(usersAppData.data);
					setToastMessage("User(s) were removed from the application.");
				})
				.catch((err) => {
					setToastMessage(err.message);
				})
				.finally(() => {
					setRemoveUsersLoading(false);
					setShowRemoveUsersModal(false);
				});
		},
		[activeApplication, getToken, setApplicationUsers]
	);

	const onRemoveUserAccess = React.useCallback(async () => {
		if (selectedUser) {
			const token = await getToken();
			let userUpdateInfo = {
				user: selectedUser,
				userApplication: {
					[activeApplication!.id]: null,
				},
				token,
			};

			const response = await updateUser(userUpdateInfo);
			if (response.message === ResponseMessage.Success || response.message === "Success") {
				const { data: usersData } = await getUsersList(token, { application: activeApplication?.id || "" });
				setData(usersData.data.users.length ? usersData.data.users : []);
				const { data: usersAppData } = await getAppUsersList(token, activeApplication?.id);
				setApplicationUsers(usersAppData.data);
				if (action) {
					handleClearURLProps();
				}
				setShowModal(false);
				setToastMessage("User was removed from the application.");
			}
		}
	}, [action, activeApplication, getToken, handleClearURLProps, selectedUser, setApplicationUsers]);

	const onFinalSubmit = React.useCallback(async () => {
		const isAppAdmin = selectedUser?.applications?.[activeApplication!.id]?.permissions === "admin";

		const hasMarketsAssigned = activeApplication?.allowReadOnly
			? true
			: selectedUser?.applications?.[activeApplication!.id]?.markets &&
			  Object.keys(selectedUser?.applications?.[activeApplication!.id]?.markets || {}).length > 0;

		if (selectedUser && activeApplication && (isAppAdmin || hasMarketsAssigned)) {
			setUsersLoading(true);
			try {
				let activeAppForUser = selectedUser.applications?.[activeApplication.id];

				if (!activeAppForUser) {
					activeAppForUser = {
						permissions: UserPermissions.Maintainer,
					};
				}

				if (activeAppForUser) {
					activeAppForUser.permissions =
						selectedUser?.applications?.[activeApplication!.id]?.permissions || UserPermissions.Maintainer;
					if (activeAppForUser.markets && Object.keys(activeAppForUser.markets).length === 0) {
						delete activeAppForUser.markets;
					}
				}

				const token = await getToken();
				let userUpdateInfo = {
					user: selectedUser,
					userApplication: {
						[activeApplication.id]: activeAppForUser,
					},
					token,
				};

				const response = await updateUser(userUpdateInfo);
				if (response.message === ResponseMessage.Success || response.message === "Success") {
					const { data: usersData } = await getUsersList(token, { application: activeApplication?.id || "" });
					setData(usersData.data.users.length ? usersData.data.users : []);
					const { data: usersAppData } = await getAppUsersList(token, activeApplication?.id);
					setApplicationUsers(usersAppData.data);
					if (action) {
						handleClearURLProps();
					}
					setSelectedUser(undefined);
					setShowModal(false);
					setSearchUserValue("");
					setToastMessage("Updated Successfully.");
				}
			} catch (err: any) {
				setToastMessage(err.message);
			} finally {
				setUsersLoading(false);
			}
		} else if (selectedUser && activeApplication && !activeApplication.allowReadOnly) {
			setToastMessage("This application doesn't allow read only mode. Please assign UI-schema at least for one market");
		}
	}, [selectedUser, activeApplication, getToken, action, handleClearURLProps, setApplicationUsers]);

	return (
		<Layout
			loading={loading}
			data={data}
			usersInApplication={usersInApplication}
			showModal={showModal}
			setShowModal={setShowModal}
			modalHeading={modalHeading}
			setModalHeading={setModalHeading}
			modalButtonLabel={modalButtonLabel}
			setModalButtonLabel={setModalButtonLabel}
			marketsData={_marketsData}
			uiSchemas={uiSchemas}
			selectedUser={selectedUser}
			setSelectedUser={setSelectedUser}
			addingNewUser={addingNewUser}
			setAddingNewUser={setAddingNewUser}
			onFinalSubmit={onFinalSubmit}
			isAdmin={isAdmin}
			currentUserSelected={currentUserSelected}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			handleClearURLProps={handleClearURLProps}
			appUsersLoading={appUsersLoading}
			setAppUsersLoading={setAppUsersLoading}
			activeApplication={activeApplication}
			onRemoveUserAccess={onRemoveUserAccess}
			toggleUserTypeBtnIndex={toggleUserTypeBtnIndex}
			setToggleUserTypeBtnIndex={setToggleUserTypeBtnIndex}
			setSearchUserValue={setSearchUserValue}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			hasChangedUser={hasChangedUser}
			onboardingUser={onboardingUser}
			setOnboardingUser={setOnboardingUser}
			showRemoveUsersModal={showRemoveUsersModal}
			setShowRemoveUsersModal={setShowRemoveUsersModal}
			onRemoveMultipleUserAccess={onRemoveMultipleUserAccess}
			removeUsersLoading={removeUsersLoading}
		/>
	);
};

export default ManageUsers;
