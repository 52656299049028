import * as React from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { SEARCH_PARAM_ACTIVE_TAB } from "../utils/constants";

const useTabs = () => {
	const [searchParams, setSearchParam] = useSearchParams();
	const location = useLocation();
	const navigate = useNavigate();

	const activeTab = searchParams.get(SEARCH_PARAM_ACTIVE_TAB);

	const setActiveTab = React.useCallback(
		(tab: string) => {
			if (location.pathname !== "/requests") {
				setSearchParam({ ...Object.fromEntries(searchParams.entries()), activeTab: tab });
			} else {
				navigate(`/?activeTab=${tab}`);
			}
		},
		[location.pathname, navigate, searchParams, setSearchParam]
	);
	return { activeTab, setActiveTab };
};

export default useTabs;
