/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from "react";
import _ from "lodash/fp";
import styled from "styled-components";

import { colourNeutral7 } from "@ingka/variables/colours-css";
import CommercialMessage from "@ingka/commercial-message";
import Tooltip from "@ingka/tooltip";
import Button from "@ingka/button";
import chevronDown from "@ingka/ssr-icon/paths/chevron-down";
import chevronUp from "@ingka/ssr-icon/paths/chevron-up";

import { Schema } from "../../utils/types";
import * as helpers from "../../utils/helpers";
import { SchemaBox } from "../SchemaBox";
import { SchemaControls, SchemaArrayControls } from "./SchemaControls";
import { Input } from "../Input";
import { DeleteButton } from "../Buttons";
import { sortObjectByKey } from "../../../Schema/utils";

type Props = {
	schema: Schema;
	schemakey?: string;
	isRequired?: boolean;
	onChangeKey?: (key: string) => void;
	onDelete?: (key: string) => void;
	onChange?: (schema: Schema) => void;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
	schemaKeys?: string[];
	showAllProperties: boolean;
	setShowAllProperties?: (vaL: boolean) => void;
	hasChanged: boolean;
};

const StyledOptionLabel = styled.label`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

interface CollapsibleWrapperProps {
	isCollapsed: boolean;
}

const CollapsibleWrapper = styled.div<CollapsibleWrapperProps>`
	display: ${({ isCollapsed }) => (isCollapsed ? "none" : "block")}; ;
`;

const ControllsWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const RequiredIndicatorWrapper = styled.div`
	margin-bottom: 1.25rem;
`;

const ExpandButtonWrapper = styled.div`
	padding-bottom: 1.25rem;
`;

const SchemaCreator: React.FunctionComponent<Props> = ({
	schema,
	onChangeKey = _.noop,
	onChange = _.noop,
	onDelete = _.noop,
	schemakey = "__root__",
	isRequired,
	setInvalidKeys,
	invalidKeys,
	schemaKeys,
	showAllProperties,
	setShowAllProperties,
	hasChanged,
}: Props) => {
	const [isCollapsed, setIsCollapsed] = React.useState<boolean>(schemakey === "__root__" ? false : true);

	React.useEffect(() => {
		if (schemakey !== "__root__") {
			if (showAllProperties) {
				setIsCollapsed(false);
			} else {
				setIsCollapsed(true);
			}
		}
	}, [schemakey, showAllProperties]);

	React.useEffect(() => {
		if (
			schema &&
			schema !== null &&
			helpers.isSchemaObject(schema) &&
			schema.properties &&
			Object.keys((schema?.properties as any) || {})?.length > 1 &&
			!hasChanged
		) {
			schema.properties = sortObjectByKey(schema.properties);
		}
	}, [hasChanged, schema]);

	const isSchemaHaveSingleProperty = React.useMemo(() => {
		if (
			schema &&
			schema !== null &&
			helpers.isSchemaObject(schema) &&
			Object.keys((schema?.properties as any) || {})?.length > 1
		) {
			return true;
		}
		return false;
	}, [schema]);

	const isArray = React.useMemo(() => {
		if (schema && schema !== null && helpers.isSchemaArray(schema)) {
			return true;
		} else return false;
	}, [schema]);

	const schemaPropertyKeys: string[] = React.useMemo(
		() => (helpers.isSchemaObject(schema) ? helpers.getKeysInSchema(schema) : schemaKeys),
		[schema, schemaKeys]
	);

	return (
		<div>
			<ControllsWrapper>
				<SchemaControls
					schema={schema}
					schemakey={schemakey}
					isCollapsed={isCollapsed}
					onChangeKey={schemakey !== "__root__" ? onChangeKey : undefined}
					onAdd={
						helpers.isSchemaObject(schema)
							? () => {
									setIsCollapsed((c) => !c && c);
									onChange(helpers.addSchemaProperty(schema));
							  }
							: helpers.isSelectSchema(schema)
							? () => {
									setIsCollapsed((c) => !c && c);
									onChange(helpers.addSelectOptions(schema));
							  }
							: undefined
					}
					onAddDashboard={
						helpers.isSchemaObject(schema)
							? undefined
							: () => {
									helpers.addDashboardOptions(schema);
							  }
					}
					onCollapse={
						helpers.isSchemaObject(schema) || helpers.isSchemaArray(schema) || helpers.isSelectSchema(schema)
							? () => setIsCollapsed((c) => !c)
							: undefined
					}
					onDelete={
						schemakey !== "__root__"
							? () => {
									return onDelete(schemakey);
							  }
							: undefined
					}
					onChange={onChange}
					invalidKeys={invalidKeys}
					setInvalidKeys={setInvalidKeys}
					schemaKeys={schemaPropertyKeys}
				/>
				{schemakey === "__root__" && (
					<ExpandButtonWrapper>
						<Tooltip
							position="top"
							tooltipText={!showAllProperties ? "Expand all properties" : "Collapse all properties"}
						>
							<Button
								data-testid="expandAll"
								small
								iconPosition="trailing"
								text={!showAllProperties ? "Expand" : "Collapse"}
								ssrIcon={!showAllProperties ? chevronDown : chevronUp}
								onClick={() => setShowAllProperties && setShowAllProperties(!showAllProperties)}
								type="secondary"
							/>
						</Tooltip>
					</ExpandButtonWrapper>
				)}
				{isRequired && (
					<RequiredIndicatorWrapper>
						<CommercialMessage subtle message="Required" />
					</RequiredIndicatorWrapper>
				)}
			</ControllsWrapper>
			<CollapsibleWrapper isCollapsed={isCollapsed}>
				{helpers.isSchemaObject(schema) && helpers.hasSchemaProperties(schema) && (
					<SchemaBox height={isSchemaHaveSingleProperty ? undefined : 22} isRoot={schemakey === "__root__"}>
						<SchemaObjectProperties
							onChangeKey={(oldkey, newkey) => onChange(helpers.renameSchemaProperty(oldkey, newkey, schema))}
							onDelete={(key) => onChange(helpers.deleteSchemaProperty(key)(schema))}
							onChange={(key, s) => onChange(helpers.setSchemaProperty(key)(s, schema))}
							schema={schema}
							invalidKeys={invalidKeys}
							setInvalidKeys={setInvalidKeys}
							schemaKeys={schemaPropertyKeys}
							showAllProperties={showAllProperties}
							hasChanged={hasChanged}
						/>
					</SchemaBox>
				)}
				{helpers.isSchemaArray(schema) && (
					<SchemaBox height={isArray ? 20 : undefined}>
						<SchemaArrayItems
							schemakey={schemakey}
							schema={helpers.getSchemaItems(schema)}
							onChange={(s) => onChange(helpers.setSchemaItems(s, schema))}
							invalidKeys={invalidKeys}
							setInvalidKeys={setInvalidKeys}
							showAllProperties={showAllProperties}
							hasChanged={hasChanged}
						/>
					</SchemaBox>
				)}
				{helpers.isSelectSchema(schema) && (
					<SchemaBox>
						<SchemaSelectOptions
							schemakey={schemakey}
							schema={schema}
							invalidKeys={invalidKeys}
							setInvalidKeys={setInvalidKeys}
							onChange={(key, s) => {
								onChange(helpers.setSchemaTypeAndRemoveWrongFields("oneOf", schema));
							}}
						/>
					</SchemaBox>
				)}
			</CollapsibleWrapper>
		</div>
	);
};

export default SchemaCreator;

interface ArrayProps {
	schema: Schema;
	onChange: (schema: Schema) => void;
	schemakey?: string;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
	showAllProperties: boolean;
	hasChanged: boolean;
}

const SchemaArrayItemsObjectWrapper = styled.div`
	margin-top: 0.5rem;
`;

const SchemaArrayControlsWrapper = styled.div`
	margin-left: 2.5rem;
`;

const SchemaArrayItems: React.FC<ArrayProps> = ({
	schema,
	onChange,
	schemakey,
	setInvalidKeys,
	invalidKeys,
	showAllProperties,
	hasChanged,
}) => {
	const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);

	React.useEffect(() => {
		if (showAllProperties) {
			setIsCollapsed(false);
		} else {
			setIsCollapsed(true);
		}
	}, [showAllProperties]);

	return (
		<SchemaArrayControlsWrapper>
			<div>
				<SchemaArrayControls
					schemakey={schemakey}
					schema={schema}
					onChange={onChange}
					onAdd={
						helpers.isSchemaObject(schema)
							? () => {
									setIsCollapsed((c) => !c && c);
									onChange(helpers.addSchemaProperty(schema));
							  }
							: helpers.isSelectSchema(schema)
							? () => {
									setIsCollapsed((c) => !c && c);
									onChange(helpers.addSelectOptions(schema));
							  }
							: undefined
					}
					onCollapse={
						helpers.isSchemaObject(schema) || helpers.isSchemaArray(schema) || helpers.isSelectSchema(schema)
							? () => setIsCollapsed((c) => !c)
							: undefined
					}
					isCollapsed={isCollapsed}
				/>
			</div>
			<CollapsibleWrapper isCollapsed={isCollapsed}>
				{helpers.isSchemaObject(schema) && helpers.hasSchemaProperties(schema) && (
					<SchemaBox height={22}>
						<SchemaArrayItemsObjectWrapper>
							<SchemaObjectProperties
								onChangeKey={(oldkey, newkey) => onChange(helpers.renameSchemaProperty(oldkey, newkey, schema))}
								onDelete={(key) => {
									onChange(helpers.deleteSchemaProperty(key)(schema));
								}}
								onChange={(key, s) => onChange(helpers.setSchemaProperty(key)(s, schema))}
								schema={schema}
								setInvalidKeys={setInvalidKeys}
								invalidKeys={invalidKeys}
								showAllProperties={showAllProperties}
								hasChanged={hasChanged}
							/>
						</SchemaArrayItemsObjectWrapper>
					</SchemaBox>
				)}
				{helpers.isSchemaArray(schema) && (
					<SchemaBox height={22}>
						<SchemaArrayItems
							schema={helpers.getSchemaItems(schema)}
							onChange={(s) => onChange(helpers.setSchemaItems(s, schema))}
							invalidKeys={invalidKeys}
							setInvalidKeys={setInvalidKeys}
							showAllProperties={showAllProperties}
							hasChanged={hasChanged}
						/>
					</SchemaBox>
				)}
				{helpers.isSelectSchema(schema) && (
					<SchemaBox>
						<SchemaSelectOptions
							schemakey={schemakey || ""}
							schema={schema}
							invalidKeys={invalidKeys}
							setInvalidKeys={setInvalidKeys}
							onChange={(key, s) => {
								onChange(helpers.setSchemaTypeAndRemoveWrongFields("oneOf", schema));
							}}
						/>
					</SchemaBox>
				)}
			</CollapsibleWrapper>
		</SchemaArrayControlsWrapper>
	);
};

interface SelectProps {
	schema: Schema;
	onChange: (key: string, schema: Schema) => void;
	schemakey: string;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
}

const SchemaSelectOptions: React.FC<SelectProps> = ({ schema, onChange, schemakey, setInvalidKeys, invalidKeys }) => {
	return (
		<SelectSchemaProperties
			onChange={onChange}
			schemakey={schemakey}
			schema={schema}
			setInvalidKeys={setInvalidKeys}
			invalidKeys={invalidKeys}
		/>
	);
};

interface selectProps {
	schema: Schema;
	onDelete: (key: string) => void;
	onChangeKey: (oldKey: string, newKey: string) => void;
	onChange: (key: string, schema: Schema) => void;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
	schemaKeys?: string[];
	showAllProperties: boolean;
	hasChanged: boolean;
}

const SchemaObjectProperties: React.FC<selectProps> = ({
	schema,
	onChangeKey,
	onDelete,
	onChange,
	setInvalidKeys,
	invalidKeys,
	schemaKeys,
	showAllProperties,
	hasChanged,
}) => {
	return (
		<ul>
			{_.entries(helpers.getSchemaProperties(schema)).map(([key, s]) => (
				<li key={key} style={{ listStyleType: "none" }}>
					<SchemaCreator
						schema={s as Schema}
						schemakey={key}
						isRequired={helpers.isFieldRequired(key)(schema)}
						onDelete={onDelete}
						onChangeKey={(newKey) => onChangeKey(key, newKey)}
						onChange={(newSchema) => onChange(key, newSchema)}
						setInvalidKeys={setInvalidKeys}
						invalidKeys={invalidKeys}
						schemaKeys={schemaKeys}
						showAllProperties={showAllProperties}
						hasChanged={hasChanged}
					/>
				</li>
			))}
		</ul>
	);
};

interface SelectSchemaProps {
	schema: Schema;
	schemakey: string;
	onChange: (key: string, schema: Schema) => void;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
}

const SelectSchemaPropertiesLayout = styled.div`
	display: flex;
`;

const SelectSchemaPropertiesWrapper = styled.ul`
	display: grid;
`;

const SelectSchemaPropertiesItems = styled.div`
	display: flex;
	gap: 1rem;
`;

const SvgWrapper = styled.div`
	height: auto;
	width: 3rem;
`;

const Svg = styled.svg`
	width: 1.5rem;
	height: 100%;
	overflow: initial;
`;

const OptionsWrapper = styled.div`
	display: flex;
`;

const SelectSchemaProperties: React.FC<SelectSchemaProps> = ({
	schema,
	schemakey,
	onChange,
	setInvalidKeys,
	invalidKeys,
}) => {
	const rows = schema.oneOf as any[];
	const [height, setHeight] = React.useState<number | undefined>(undefined);
	const ref = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (ref.current && ref.current !== null) {
			setHeight(ref?.current?.clientHeight);
		}
	}, []);

	return (
		<SelectSchemaPropertiesLayout>
			<SelectSchemaPropertiesWrapper>
				{rows?.map((item, index) => (
					<OptionsWrapper key={item?.const}>
						<SvgWrapper>
							<Svg height={height}>
								<line stroke={colourNeutral7} strokeWidth={1} x1="-13" y1="60%" x2="19" y2="60%" />
							</Svg>
						</SvgWrapper>
						<div ref={ref}>
							<strong>
								<StyledOptionLabel>
									<p>Option {index + 1}.</p>
									<DeleteButton
										onClick={() => {
											if (Array.isArray(schema.oneOf)) {
												schema.oneOf.splice(index, 1);
											}
											onChange(schemakey, schema);
										}}
										title={"delete"}
									/>
								</StyledOptionLabel>
							</strong>
							<SelectSchemaPropertiesItems>
								<Input
									placeholder={"Title"}
									label={"Title"}
									type="text"
									value={item.title}
									onChange={(userInput: any) => {
										if (Array.isArray(schema.oneOf)) {
											schema.oneOf[index].title = userInput;
										}
										onChange(schemakey, schema);
									}}
								/>
								<Input
									placeholder={"Value"}
									label={"Value"}
									type="text"
									value={item.const}
									invalidKeys={invalidKeys}
									onChange={(userInput: any) => {
										if (Array.isArray(schema.oneOf) && !schema.oneOf.find((e) => e.const === userInput)) {
											schema.oneOf[index].const = userInput;
											setInvalidKeys && setInvalidKeys([]);
										} else if (setInvalidKeys) {
											invalidKeys ? setInvalidKeys([...invalidKeys, userInput]) : setInvalidKeys([userInput]);
										}
										onChange(schemakey, schema);
									}}
								/>
							</SelectSchemaPropertiesItems>
						</div>
					</OptionsWrapper>
				))}
			</SelectSchemaPropertiesWrapper>
		</SelectSchemaPropertiesLayout>
	);
};
