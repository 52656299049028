import React from "react";
import { RoleData } from "../RequestNewRoleModal";
import { UserPermissions } from "../../../utils/constants";
import Layout from "./Layout";

interface MaintainerRoleSelctorProps {
	rolesData: RoleData[];
	setSelectedRole: (role: UserPermissions | null) => void;
	selectedRole: UserPermissions | null;
	requestingRole: boolean;
}

const MaintainerRoleSelector: React.FC<MaintainerRoleSelctorProps> = ({
	rolesData,
	setSelectedRole,
	requestingRole,
	selectedRole,
}) => {
	return (
		<Layout
			rolesData={rolesData}
			setSelectedRole={setSelectedRole}
			requestingRole={requestingRole}
			selectedRole={selectedRole}
		/>
	);
};

export default MaintainerRoleSelector;
