import React from "react";
import styled from "styled-components";

import { ListViewItem } from "@ingka/list-view";
import documentUpload from "@ingka/ssr-icon/paths/document-upload";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";

import { UserRequestedApplications } from "../../../store";
import { StyledListView } from "../../styles";

interface ExistingApplicationsRequestedProps {
	cancelExistingAppRequest: (appId: string) => void;
	cancelExistingAppRequestLoading: boolean;
	existingAppRequestRemoving: string;
	userLoading: boolean;
	existingAppRequests?: UserRequestedApplications[];
}

const ListWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-around;
	padding: 0 1rem 0 1rem;
`;

const StyledButton = styled(Button)`
	margin-left: 1rem;
`;

const MessageWrapper = styled.div`
	padding: 0 1rem 0 1rem;
`;

const ExistingApplicationsRequested: React.FC<ExistingApplicationsRequestedProps> = ({
	existingAppRequests,
	cancelExistingAppRequest,
	cancelExistingAppRequestLoading,
	existingAppRequestRemoving,
	userLoading,
}) => {
	return existingAppRequests?.length ? (
		<StyledListView id={"existing-application-requests"} size="small">
			{existingAppRequests?.map((app) => (
				<ListWrapper>
					<ListViewItem
						size="small"
						emphasised
						leadingIcon={documentUpload}
						id={app.application}
						title={app["friendly-name"]}
						showDivider={false}
						disabled={
							(cancelExistingAppRequestLoading || userLoading) && existingAppRequestRemoving === app.application
						}
					/>
					<StyledButton
						iconOnly
						small
						ssrIcon={trashCan}
						type="tertiary"
						onClick={() => cancelExistingAppRequest(app.application)}
						loading={(cancelExistingAppRequestLoading || userLoading) && existingAppRequestRemoving === app.application}
					/>
				</ListWrapper>
			))}
		</StyledListView>
	) : (
		<MessageWrapper>
			<InlineMessage title={"No application!"} body="Seems like you didn't requested access for any application yet." />
		</MessageWrapper>
	);
};

export default ExistingApplicationsRequested;
