import * as React from "react";
import styled from "styled-components";

import Button from "@ingka/button";
import information from "@ingka/ssr-icon/paths/document-pencil";
import ListBox from "@ingka/list-box";
import ListView, { ListViewItem } from "@ingka/list-view";
import { colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";
import cross from "@ingka/ssr-icon/paths/cross";
import play from "@ingka/ssr-icon/paths/play";
import wrench from "@ingka/ssr-icon/paths/wrench";
import documentCheckmark from "@ingka/ssr-icon/paths/document-checkmark";
import { Backdrop } from "@mui/material";

import Feedback from "../Feedback/Feedback";
import { FLAGGA_VIDEO_ONBOARDING_URL, DIGITAL_BUSINESS_SUPPORT_URL } from "../../utils/constants";

const FloatingButton = styled(Button)`
	position: fixed;
	z-index: 100;
	bottom: 0.80rem;
	right: 3rem;
	box-shadow: 0px 0px 0px 1px rgba(var(--colour-neutral-4, 204, 204, 204), 0.24),
		0px 4px 8px 0px rgba(var(--colour-static-black, 17, 17, 17), 0.14);
`;

const StyledListBox = styled(ListBox)`
	position: fixed;
	bottom: 6.8rem;
	right: 5rem;
	z-index: 100;
	.list-box__body {
		padding-block: 0rem !important;
	}
`;

const CustomModalHeader = styled.div`
	background-color: ${colourStaticIkeaBrandYellow};
	.btn__inner {
		background-color: ${colourStaticIkeaBrandYellow} !important;
	}
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
`;

const StyledHeaderText = styled.span`
	font-weight: 700;
	margin: 1rem 0.5rem;
	margin-inline-start: 2rem;
	text-align: center;
	width: 100%;
`;

const StyledListView = styled(ListView)`
	padding: 0rem 1rem;
`;

interface FloatingMenuProps {
	showFloatingMenu: boolean;
	setShowFloatingMenu: (value: boolean) => void;
	showFeedbackModal: boolean;
	setShowFeedbackModal: (value: boolean) => void;
}

const Layout: React.FC<FloatingMenuProps> = ({
	showFloatingMenu,
	setShowFloatingMenu,
	showFeedbackModal,
	setShowFeedbackModal,
}) => (
	<>
		<FloatingButton
			iconOnly
			expanding={true}
			title="Information"
			type="emphasised"
			ssrIcon={information}
			onMouseEnter={() => setShowFloatingMenu(true)}
		/>
		<Backdrop sx={{ "z-index": 100 }} open={showFloatingMenu} onClick={() => setShowFloatingMenu(false)} />
		<StyledListBox open={showFloatingMenu} className="list-box-story">
			<CustomModalHeader>
				<StyledHeaderText>Welcome to Flagga</StyledHeaderText>
				<Button type="tertiary" ssrIcon={cross} iconOnly small onClick={() => setShowFloatingMenu(false)}></Button>
			</CustomModalHeader>
			<StyledListView id="floating-menu-popup" showDivider={false} size="small">
				<ListViewItem
					key="feedback"
					inset
					title="Feedback"
					control="navigational"
					controlIcon="chevronRightSmall"
					emphasised
					leadingIcon={documentCheckmark}
					name="Feedback-option-review"
					onClick={() => {
						setShowFeedbackModal(true);
						setShowFloatingMenu(false);
					}}
				/>
				<ListViewItem
					key="feature-improvements"
					inset
					title="Feature improvements"
					control="navigational"
					controlIcon="chevronRightSmall"
					emphasised
					leadingIcon={wrench}
					name="feature-imrpovements"
					onClick={() => {
						setShowFloatingMenu(false);
						open(DIGITAL_BUSINESS_SUPPORT_URL, "_blank");
					}}
				/>
				<ListViewItem
					id="onboarding-video"
					inset
					title="Onboarding video"
					control="navigational"
					emphasised
					controlIcon="chevronRightSmall"
					leadingIcon={play}
					name="Feedback-option-video"
					onClick={() => {
						setShowFloatingMenu(false);
						open(FLAGGA_VIDEO_ONBOARDING_URL, "_blank");
					}}
				/>
			</StyledListView>
		</StyledListBox>
		<Feedback
			showRating={false}
			showModalFromFloatingMenu={showFeedbackModal}
			setShowModalFromFloatingMenu={setShowFeedbackModal}
		/>
	</>
);

export default Layout;
