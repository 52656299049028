import React from "react";
import Layout from "./Layout";
import { ValidationProps } from "../../types";

interface SelectRoleProps extends ValidationProps {}

const SelectRole: React.FC<SelectRoleProps> = ({ value, valid, validation, handleChange, shouldValidate }) => {
	return (
		<Layout
			value={value}
			valid={valid}
			validation={validation}
			handleChange={handleChange}
			shouldValidate={shouldValidate}
		/>
	);
};

export default SelectRole;
