import { AxiosError } from "axios";
import { addApplication, updateApplication, deleteApplication } from "../../../services/applications";
import { ApplicationData } from "../../../store";
import { Errors, Value } from "./types";

interface AddNewApplication {
	authHeader: {
		headers: {
			authorization: string;
		};
	};
	formValue: Value;
	setShowModal: (value: boolean) => void;
	setErrors: (data: Errors[]) => void;
}

interface UpdateApplication {
	authHeader: {
		headers: {
			authorization: string;
		};
	};
	updateAppSelected: ApplicationData;
	setErrors: (data: Errors[]) => void;
}

type error = { message: string; errors: Errors[] };

export const addNewApplication = async ({ authHeader, formValue, setShowModal, setErrors }: AddNewApplication) => {
	try {
		const value = {
			id: formValue.appId,
			name: formValue.appName,
			clientIds: formValue.clientIds,
			admin: { email: formValue.email },
			showDashboard: formValue.showDashboard,
			showMarketDashboard: formValue.showMarketDashboard,
			allowReadOnly: formValue.allowReadOnly,
			showAdoptionRate: formValue.showAdoptionRate,
		};
		const { data: response } = await addApplication(authHeader, value);
		return response;
	} catch (err: any) {
		//TODO:Handle Errors
		const error = err as AxiosError;
		if ((error.response?.data as error)?.errors?.length) {
			setErrors((error.response?.data as error)?.errors);
		}
		throw new Error(err?.response.data.message);
	}
};

export const updateApplicationData = async ({ authHeader, updateAppSelected, setErrors }: UpdateApplication) => {
	try {
		const application = updateAppSelected.id;
		const value = {
			allowReadOnly: updateAppSelected.allowReadOnly,
			showDashboard: updateAppSelected.showDashboard,
			showMarketDashboard: updateAppSelected.showMarketDashboard,
			clientIds: updateAppSelected.clientIds,
			showAdoptionRate: updateAppSelected.showAdoptionRate

		};
		const { data: response } = await updateApplication(authHeader, application, value);
		return response;
	} catch (err: any) {
		//TODO:Handle Errors
		const error = err as AxiosError;
		if ((error.response?.data as error)?.errors?.length) {
			setErrors((error.response?.data as error)?.errors);
		}
		throw new Error(err?.response.data.message);
	}
};

export const deleteApplicationData = async (
	authHeader: { headers: { authorization: string } },
	application: ApplicationData,
	delete_backup?: boolean
) => {
	try {
		const deleteBackup = delete_backup || false;
		const applicationId = application.id;

		const { data: response } = await deleteApplication(authHeader, applicationId, deleteBackup);
		return response;
	} catch (err: any) {
		const error = err as AxiosError;
		if ((error.response?.data as error)?.errors?.length) {
			throw new Error((error.response?.data as error)?.errors[0].message);
		}
	}
};
