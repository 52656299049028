import * as React from "react";
import styled from "styled-components";

import plus from "@ingka/ssr-icon/paths/plus";
import {
	ArrayLayoutProps,
	composePaths,
	createDefaultValue,
	isObjectArrayControl,
	RankedTester,
	rankWith,
} from "@jsonforms/core";
import { withJsonFormsArrayLayoutProps } from "@jsonforms/react";
import Button from "@ingka/button";

import CardRenderer from "./CardRenderer";
import { CenterButtonWrapper } from "../../styles";

const StyledDiv = styled.div`
	margin-top: 1px;
	padding: 1.5rem 0rem 1.5rem 0rem;
	flex-grow: 1;
`;

const StyledHeading = styled.h3`
	margin: 0 0 0.25rem 0;
`;

const StyledParagraph = styled.p`
	margin-top: 0rem;
`;

const ErrorParagraph = styled.p`
	color: rgb(224, 7, 81);
`;

const ParagraphWrapper = styled.div`
	text-align: center;
`;

export const ArrayLayoutRenderer = ({
	uischema,
	schema,
	renderers,
	cells,
	data,
	path,
	addItem,
	minItems,
	removeItems,
	enabled,
	moveDown,
	moveUp,
}: ArrayLayoutProps) => {
	let elements = uischema.options?.["detail"] ? uischema.options?.["detail"]["elements"] : [];
	const isEmpty = data === 0;

	const addItemCb = React.useCallback(
		(p: string, value: any) => {
			return addItem(p, value);
		},
		[addItem]
	);

	const removeItemsCb = React.useCallback(
		(path: string, toDelete: number[]) => {
			return removeItems ? removeItems(path, toDelete) : () => {};
		},
		[removeItems]
	);

	const onMoveUp = React.useCallback(
		(path: string, toMove: number) => {
			return moveUp && moveUp(path, toMove);
		},
		[moveUp]
	);

	const onMoveDown = React.useCallback(
		(path: string, toMove: number) => {
			return moveDown && moveDown(path, toMove);
		},
		[moveDown]
	);

	if (!elements) {
		return <ErrorParagraph>No renderer found</ErrorParagraph>;
	}

	const toRender = Array(data)
		.fill(0)
		.map((_, i) => {
			return (
				<CardRenderer
					key={i}
					index={i}
					schema={schema}
					uischema={uischema}
					path={composePaths(path, `${i}`)}
					renderers={renderers}
					cells={cells}
					onRemove={removeItemsCb(path, [i])}
					elements={elements}
					enabled={enabled}
					onMoveDown={onMoveDown(path, i)}
					onMoveUp={onMoveUp(path, i)}
					data={data}
					removable={uischema.options?.removable}
				/>
			);
		});
	return (
		<StyledDiv>
			{uischema.options?.label && <StyledHeading>{uischema.options?.label}</StyledHeading>}
			{uischema.options?.subText && <StyledParagraph>{uischema.options?.subText}</StyledParagraph>}
			{isEmpty && minItems && (
				<ParagraphWrapper>
					<ErrorParagraph>Please add at least {minItems} item</ErrorParagraph>
				</ParagraphWrapper>
			)}
			{isEmpty && !minItems && (
				<ParagraphWrapper>
					<p>No Data</p>
				</ParagraphWrapper>
			)}
			{toRender}
			<CenterButtonWrapper>
				<Button
					id={`add-${uischema.options?.label || "item"}`}
					type="secondary"
					onClick={addItemCb(path, createDefaultValue(schema))}
					text="Add Item"
					ssrIcon={plus}
					iconPosition="trailing"
					small
					disabled={!enabled || uischema.options?.addable === false}
				/>
			</CenterButtonWrapper>
		</StyledDiv>
	);
};

export const arrayLayoutTester: RankedTester = rankWith(4, isObjectArrayControl);

export default withJsonFormsArrayLayoutProps(ArrayLayoutRenderer);
