import * as React from "react";
import styled from "styled-components";

import ListBox from "@ingka/list-box";
import Pill from "@ingka/pill";
import chevronUpSmall from "@ingka/ssr-icon/paths/chevron-up-small";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import { breakpointL } from "@ingka/variables/design-tokens";

import { StyledListView } from "../styles";

export interface PillDropDownProps {
	children: React.ReactNode;
	id: string;
	value?: string;
	onChange: (...args: any[]) => any;
	disabled?: boolean;
	className?: string;
}

const ListViewWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledListBox = styled(ListBox)`
	margin-top: 0.5rem;
	z-index: 1000;
	@media (width < ${breakpointL}) {
		width: 70%;
		right: 2rem;
	}
`;

const PillDropDown: React.FC<PillDropDownProps> = ({ children, id, value, onChange, disabled, className }) => {
	const [selected, setSelected] = React.useState<boolean>(false);
	const [open, setOpen] = React.useState<boolean>(false);
	const [valueLable, setValueLable] = React.useState<string | undefined>(undefined);
	const ref = React.useRef<any>(null);

	const handleClick = React.useCallback(() => {
		setSelected(!selected);
		setOpen(!open);
	}, [open, selected]);

	const handleClose = React.useCallback(() => {
		setSelected(false);
		setOpen(false);
	}, []);

	const handleChange = React.useCallback(
		(...args: any[]) => {
			onChange(...args);
			handleClose();
		},
		[handleClose, onChange]
	);

	const closeOpenMenus = React.useCallback(
		(e) => {
			if (!ref?.current?.contains(e.target)) {
				handleClose();
			}
		},
		[handleClose]
	);

	React.useEffect(() => {
		document.addEventListener("mousedown", closeOpenMenus);
		return () => {
			document.removeEventListener("mousedown", closeOpenMenus);
		};
	}, [closeOpenMenus]);

	const getChildrensWithProps: any = React.useCallback(
		(_child: any) => {
			return React.Children.map(_child, (child: any) => {
				if (child?.props?.children) {
					return React?.Children?.map(child?.props?.children, (__child: any) => getChildrensWithProps(__child));
				} else if (React.isValidElement(child)) {
					return React.cloneElement(child, {
						onChange: handleChange,
					} as any);
				} else return child;
			});
		},
		[handleChange]
	);

	const childrenWithProps = React.useMemo(() => getChildrensWithProps(children), [children, getChildrensWithProps]);

	const setValueFn = React.useCallback(
		(child: any) => {
			React?.Children?.forEach(child, (_child: any) => {
				if (_child?.props?.controlProps?.value === value) {
					setValueLable(_child.props?.name as string);
				} else if (_child?.props?.children) {
					React?.Children?.forEach(_child?.props?.children, (node: any) => setValueFn(node));
				}
			});
		},
		[value]
	);

	React.useEffect(() => {
		if (value) {
			setValueFn(children);
		}
	}, [children, setValueFn, value]);

	return (
		<div className={className ? className : ""}>
			<Pill
				id={id}
				label={valueLable}
				onClick={handleClick}
				selected={selected}
				ssrIcon={selected ? chevronUpSmall : chevronDownSmall}
				iconPosition="trailing"
				disabled={disabled}
				size="small"
			/>
			<StyledListBox open={open}>
				<ListViewWrapper ref={ref}>
					<StyledListView id={`${id}-list-box`} showDivider size="small">
						{childrenWithProps}
					</StyledListView>
				</ListViewWrapper>
			</StyledListBox>
		</div>
	);
};

export default PillDropDown;
