import { ControlElement, JsonSchema, Layout } from "@jsonforms/core";
import { assign } from "lodash";

import { SchemaElement } from "../core/model";
import { EditorUISchemaElement } from "../core/model/uischema";
import { PropertySchemas, PropertySchemasDecorator } from "./propertiesService";

export const multilineStringOptionDecorator: PropertySchemasDecorator = (
	schemas: PropertySchemas,
	uiElement: EditorUISchemaElement,
	schemaElement?: SchemaElement
) => {
	if (schemaElement?.schema.type === "string" && !schemaElement?.schema.format && uiElement.type === "Control") {
		addSchemaOptionsProperty(schemas.schema, {
			multi: { type: "boolean" },
		});
		(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/options/properties/multi"));
	}
	return schemas;
};

export const labelUIElementDecorator: PropertySchemasDecorator = (
	schemas: PropertySchemas,
	uiElement: EditorUISchemaElement
) => {
	if (uiElement?.type === "Label") {
		assign(schemas.schema.properties, { text: { type: "string" } });

		(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/text"));
	}
	return schemas;
};
export const ruleDecorator: PropertySchemasDecorator = (schemas: PropertySchemas) => {
	assign(schemas.schema.properties, {
		rule: {
			type: "object",
		},
	});
	(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/rule"));
	return schemas;
};

export const labelDecorator: PropertySchemasDecorator = (
	schemas: PropertySchemas,
	uiElement: EditorUISchemaElement
) => {
	if (["Group", "Control", "Categorization", "Category"].includes(uiElement?.type)) {
		if (!schemas.schema.properties) {
			schemas.schema.properties = {};
		}
		assign(schemas.schema.properties, { label: { type: "string" } });

		(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/label"));
	}
	return schemas;
};

export const subTextDecorator: PropertySchemasDecorator = (
	schemas: PropertySchemas,
	uiElement: EditorUISchemaElement
) => {
	if (["Group", "Control"].includes(uiElement?.type)) {
		if (!schemas.schema.properties) {
			schemas.schema.properties = {};
		}
		assign(schemas.schema.properties, { subText: { type: "string" } });

		(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/subText"));
	}
	return schemas;
};

export const addSchemaOptionsProperty = (
	schema: JsonSchema,
	newOption: {
		[property: string]: JsonSchema;
	}
) => {
	if (!schema.properties) {
		schema.properties = {};
	}
	if (!schema.properties.options) {
		schema.properties.options = {
			type: "object",
			properties: {},
		};
	}
	assign(schema.properties.options.properties, newOption);
};

export const createPropertyControl = (controlScope: string): ControlElement => ({
	type: "Control",
	scope: controlScope,
});

export const elementLabelPropDecorator: PropertySchemasDecorator = (
	schemas: PropertySchemas,
	uiElement: EditorUISchemaElement
) => {
	if (["Control"].includes(uiElement?.type) && uiElement?.options?.detail) {
		if (!schemas.schema.properties) {
			schemas.schema.properties = {};
		}
		assign(schemas.schema.properties, { elementLabelProp: { type: "string" } });

		(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/elementLabelProp"));
	}

	return schemas;
};

export const addressOrderDecorator: PropertySchemasDecorator = (
	schemas: PropertySchemas,
	uiElement: EditorUISchemaElement
) => {
	//@ts-ignore
	const scopeSplit = uiElement?.scope?.split("/") || null;
	const isAddressOrder = scopeSplit ? scopeSplit?.[scopeSplit.length - 1] === "addressOrder" : false;
	//@ts-ignore
	if (["Control"].includes(uiElement?.type) && isAddressOrder) {
		if (!schemas.schema.properties) {
			schemas.schema.properties = {};
		}
		assign(schemas.schema.properties, { addressOrder: { type: "boolean" } });

		(schemas.uiSchema as Layout).elements.push(createPropertyControl("#/properties/addressOrder"));
	}
	return schemas;
};

export const defaultSchemaDecorators: PropertySchemasDecorator[] = [
	labelDecorator,
	subTextDecorator,
	labelUIElementDecorator,
	elementLabelPropDecorator,
	addressOrderDecorator,
	ruleDecorator
];

