import * as React from "react";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";
import { UserData, getUsersList, removeUser } from "../../services/users";
import Layout from "./Layout";
import useStore, { userSelector } from "../../store";
import { ROUTE_HOME } from "../../utils/constants";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import useGetAllUsers from "../../hooks/useGetAllUsers";

const Users: React.FC = () => {
	const { getToken, handleLogout } = useAuthentication();
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [searchUserValue, setSearchUserValue] = React.useState<string>("");
	const [showAllApplications, setShowAllApplications] = React.useState<boolean>(false);
	const [showRemoveUserModal, setShowRemoveUserModal] = React.useState<boolean>(false);
	const [removeUserLoading, setRemoveUserLoading] = React.useState<boolean>(false);
	const [userIdToDelete, setUserIdToDelete] = React.useState<string>("");
	const currentUser = useStore(userSelector);
	const navigate = useNavigate();
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const { allUsers: allUsers, loading: loading } = useGetAllUsers();
	const [allFlaggaUsersOriginal, setAllFlaggaUsersOriginal] = React.useState<UserData[]>([]);
	const [allFlaggaUsers, setAllFlaggaUsers] = React.useState<UserData[]>([]);

	React.useEffect(() => {
		!isFlaggaAdmin && navigate(ROUTE_HOME);
	}, [isFlaggaAdmin]);

	React.useEffect(() => {
		setAllFlaggaUsersOriginal(allUsers);
		setAllFlaggaUsers(allUsers);
	}, [allUsers]);

	React.useEffect(() => {
		if (searchUserValue) {
			const filteredUsers = allFlaggaUsersOriginal.filter((user) => {
				return user.name?.toLowerCase().includes(searchUserValue.toLowerCase());
			});
			setAllFlaggaUsers(filteredUsers);
		} else {
			setAllFlaggaUsers(allFlaggaUsersOriginal);
		}
		return () => {
			// cancel the subscription
			setAllFlaggaUsers([]);
		};
	}, [searchUserValue]);

	const applicationsLastAdmin = React.useMemo(() => {
		const currentUser = allFlaggaUsersOriginal.find((user) => user.id === userIdToDelete);
		if (currentUser?.applications) {
			let userApplicationsAdmin = [];
			//check if user is admin
			for (let i = 0; i < Object.keys(currentUser?.applications).length; i++) {
				let appName = Object.keys(currentUser?.applications)[i];

				if (currentUser?.applications[appName].permissions && currentUser?.applications[appName].permissions === "admin") {
					userApplicationsAdmin.push(appName);
				}
			}
			if (userApplicationsAdmin.length === 0) {
				return;
			} else {
				let userApplicationsLastAdmin = [];
				for (let j = 0; j < userApplicationsAdmin.length; j++) {
					let appName = userApplicationsAdmin[j];
					//find application admins
					const applicationAdmins = allFlaggaUsersOriginal.filter((user) => {
						if (user.applications) {
							return Object.keys(user.applications).includes(appName) && user.id !== userIdToDelete && user.applications[appName].permissions && user.applications[appName].permissions === "admin";
						}
					});
					if (applicationAdmins.length === 0) {
						userApplicationsLastAdmin.push(appName);
					} 
				}
				return userApplicationsLastAdmin;
			}
		} else {
			return;
		}

	}, [userIdToDelete, allFlaggaUsersOriginal]);

	const onDeleteUser = React.useCallback(async () => {
		if (userIdToDelete) {
			setRemoveUserLoading(true);
			const token = await getToken();
			const response = await removeUser(token, userIdToDelete);
			
			if (response.status === 200) {
				setShowRemoveUserModal(false);
				if (currentUser?.user.id === userIdToDelete) {
					handleLogout();
					return;
				}
				const { data: data } = await getUsersList(token);
				setAllFlaggaUsers(data?.data.users || []);
				setAllFlaggaUsersOriginal(data?.data.users || []);
				setUserIdToDelete("");
				setToastMessage("User was removed from the FLAGGA");
			} else {
				setToastMessage("Something went wrong. Please try again later");
			}
		}
	}, [userIdToDelete, getToken]);

	return (
		<Layout
			loading={loading}
			data={allFlaggaUsers || []}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			setSearchUserValue={setSearchUserValue}
			showAllApplications={showAllApplications}
			setShowAllApplications={setShowAllApplications}
			showRemoveUserModal={showRemoveUserModal}
			setShowRemoveUserModal={setShowRemoveUserModal}
			removeUserLoading={removeUserLoading}
			onDeleteUser={onDeleteUser}
			userIdToDelete={userIdToDelete}
			setUserIdToDelete={setUserIdToDelete}
			applicationsLastAdmin={applicationsLastAdmin}
		/>
	);
};

export default Users;
