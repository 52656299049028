import React from "react";

import Modal, { ModalBody, ModalFooter, Sheets } from "@ingka/modal";
import styled from "styled-components";
import Button from "@ingka/button";
import Select, { Option } from "@ingka/select";
import FormField from "@ingka/form-field";
import Text from "@ingka/text";
import { ListViewItem } from "@ingka/list-view";

import { StyledListView, StyledModalHeader } from "../../../styles";
import { UiSchemaData } from "../../../../services/applications/types";
import { getImage } from "../../../../utils/markets";
import { UserMarketPermissions } from "../../../../services/users";

interface MarketsAndSchemasSelectorLayoutProps {
	showModal: boolean;
	setShowModal: (val: boolean) => void;
	setUiSchemaSelected: (val: string) => void;
	uiSchemaSelected: string;
	marketsSelected: string[];
	setMarketsSelected: (val: string[]) => void;
	getCountryName: (country: string) => string;
	onAdd: () => void;
	markets?: string[];
	schemasData?: UiSchemaData;
}

const StyledListViewItem = styled(ListViewItem)`
	.list-view-item__wrapper {
		padding: 0.5rem 0 0.5rem 0;
	}
	.list-view-item__image {
		width: 2rem;
		height: 2rem;
	}
	.checkbox input[type="checkbox"]:checked ~ .checkbox__symbol {
		background: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
		border-color: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
	}
`;

const Layout: React.FC<MarketsAndSchemasSelectorLayoutProps> = ({
	showModal,
	setShowModal,
	markets,
	setUiSchemaSelected,
	schemasData,
	uiSchemaSelected,
	marketsSelected,
	setMarketsSelected,
	getCountryName,
	onAdd,
}) => {
	return (
		<Modal handleCloseBtn={() => setShowModal(false)} visible={showModal}>
			<Sheets
				header={<StyledModalHeader title="Select markets & ui-schema" />}
				footer={
					<ModalFooter>
						<Button
							text={"OK"}
							disabled={!uiSchemaSelected || !marketsSelected.length}
							type="emphasised"
							onClick={onAdd}
						/>
					</ModalFooter>
				}
			>
				<ModalBody>
					<FormField>
						<Select
							id={"select-ui-schema"}
							onChange={(e) => {
								setUiSchemaSelected(e.target.value === "Choose an option" ? "" : e.target.value);
							}}
							value={uiSchemaSelected}
						>
							{schemasData?.["ui-schemas"].map((schema) => (
								<Option id={schema.uiSchemaName} value={schema.uiSchemaName} name={schema.uiSchemaName} />
							))}
						</Select>
					</FormField>
					{markets?.length ? (
						<StyledListView id="select-marekets" size="small">
							{markets && markets.length > 0 && (
								<StyledListViewItem
									id="select-all"
									emphasised
									leadingIconPosition="center"
									control="checkbox"
									controlProps={
										{
											checked: markets && marketsSelected && marketsSelected.length === markets.length ? true : false,
										} as any
									}
									onChange={(e) => {
										if ((e.target as any)?.checked) {
											setMarketsSelected(markets);
										} else {
											setMarketsSelected([]);
										}
									}}
									size="large"
									title={"Select All"}
								/>
							)}
							{markets?.map((market) => {
								return (
									<StyledListViewItem
										id={market}
										image={getImage(market)}
										leadingIconPosition="center"
										control="checkbox"
										controlProps={
											{
												checked: marketsSelected?.includes(market) || false,
											} as any
										}
										onChange={(e) => {
											if ((e.target as any)?.checked) {
												setMarketsSelected(marketsSelected ? [...marketsSelected, market] : [market]);
											} else {
												marketsSelected = marketsSelected ? [...marketsSelected, market] : [market];
												setMarketsSelected(marketsSelected.filter((_market) => _market !== market));
											}
										}}
										size="large"
										title={
											<>
												<strong>{market}</strong> | {getCountryName(market)}
											</>
										}
									/>
								);
							})}
						</StyledListView>
					) : (
						<Text tagName="span" bodySize="m">
							No markets to show
						</Text>
					)}
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default Layout;
