/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import checkmark from "@ingka/ssr-icon/paths/checkmark";
import Avatar from "@ingka/avatar";
import arrowLeftToLine from "@ingka/ssr-icon/paths/arrow-left-to-line";
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left";
import arrowRightToLine from "@ingka/ssr-icon/paths/arrow-right-to-line";
import arrowRight from "@ingka/ssr-icon/paths/arrow-right";
import InlineMessage from "@ingka/inline-message";
import Loading, { LoadingBall } from "@ingka/loading";
import CommercialMessage from "@ingka/commercial-message";
import Modal, { Prompt } from "@ingka/modal";

import { ApplicationUiSchema } from "../../services/applications/types";
import { ApplicationOnboardedUsers, ApplicationUserRequests, UserApplicationData } from "../../services/users";
import { User } from "../../store";
import { LoadingWrapper, StyledCommercialMessage } from "../styles";
import ManageUsersModal from "../ManageUsers/ManageUserModal.tsx/ManageUsersModal";
import { TableWrapper } from "../styles";
import DeclineAccessRequestModal from "../ManageUsers/DeclineAccessRequestModal/DeclineAccessRequestModal";
import { Application } from "../../store/types";
import { getImage } from "../../utils/markets";
import useStore, { applicationUsersSelector } from "../../store";

import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
	CellContext,
} from "@tanstack/react-table";
import { ModalPrompt } from "./types";
import { UserPermissions } from "../../utils/constants";
import Toggle from "@ingka/toggle";
import { userRequestType } from "./utils";

const StyledMessage = styled(StyledCommercialMessage)`
	margin-left: 0.5rem;
`;

const StyledFooter = styled.div`
	display: flex;
	gap: 1rem;
	padding: 0.5rem;
	justify-content: end;
`;

const StyledFooterPageInfo = styled.span`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const StyledTableWrapper = styled(TableWrapper)`
	margin-top: 20px;
	border-right: transparent;
	border-left: transparent;
	border-top: transparent;
	border-bottom: transparent;
	.table-head,
	.table-cell {
		text-align: unset;
		margin: 0;
		padding: 0.5rem;
		border-right: transparent !important;
		border-left: transparent !important;
		border-bottom: transparent !important;
		background-color: white;
	}
`;

const MarketImage = styled.img`
	height: 25px;
	width: 25px;
	margin-right: 0.2rem;
`;

const StyledMarketIconWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;
`;

const StyledHeader = styled.h2`
	padding-top: 0.3rem;
`;

const StyledActionWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 1rem;
`;

const StyledAvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const RequestTypeMessage = styled(CommercialMessage)`
	font-size: 0.7rem;
`;

const StyledPrompt = styled(Prompt)`
	h2:first-child {
		margin: 0;
	}
	.prompt__content {
		padding: 0;
	}
`;

const StyledLoadingWrapper = styled(LoadingWrapper)`
	align-items: center;
`;

const StyledToggle = styled(Toggle)`
	margin-top: 1.5rem;
	margin-bottom: 2.5rem;
	display: flex;
	flex-grow: 0.5;
`;

interface ManageUsersLayoutProps {
	loading: boolean;
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	modalHeading: string;
	setModalHeading: (value: string) => void;
	modalButtonLabel: string;
	setModalButtonLabel: (value: string) => void;
	setSelectedUser: (data?: UserApplicationData) => void;
	addingNewUser: boolean;
	setAddingNewUser: (value: boolean) => void;
	onFinalSubmit: () => void;
	isAdmin: boolean;
	currentUserSelected: boolean;
	currentUser: User | null;
	usersInApplication: ApplicationOnboardedUsers[];
	toastMessage: string;
	setToastMessage: (value: string) => void;
	handleClearURLProps: () => void;
	appUsersLoading: boolean;
	applicationAccessRequest: ApplicationUserRequests[];
	setAppUsersLoading: (value: boolean) => void;
	activeApplication: Application;
	showDeclineModal: boolean;
	setShowDeclineModal: (value: boolean) => void;
	setRequestEmail: (value: string) => void;
	requestEmail: string | undefined;
	currentUserIsAdmin: boolean | undefined;
	setSelectedMarkets: (value?: string[]) => void;
	hasChangedUser: boolean;
	onboardingUser: boolean;
	setOnboardingUser: (value: boolean) => void;
	setSelectedRequestType: (request?: "application" | "markets" | "role") => void;
	selectedRequestType?: "application" | "markets" | "role";
	actionInProgress?: boolean;
	selectedUser?: UserApplicationData;
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	selectedMarkets?: string[];
	prompt: ModalPrompt;
	toggleUserTypeBtnIndex: number;
	setToggleUserTypeBtnIndex: (value: number) => void;
}

const Layout: React.FC<ManageUsersLayoutProps> = ({
	usersInApplication,
	setShowModal,
	showModal,
	modalHeading,
	setModalHeading,
	modalButtonLabel,
	setModalButtonLabel,
	setShowDeclineModal,
	showDeclineModal,
	marketsData,
	uiSchemas,
	setSelectedUser,
	selectedUser,
	addingNewUser,
	setAddingNewUser,
	onFinalSubmit,
	isAdmin,
	currentUserSelected,
	toastMessage,
	setToastMessage,
	handleClearURLProps,
	appUsersLoading,
	applicationAccessRequest,
	requestEmail,
	setRequestEmail,
	activeApplication,
	selectedMarkets,
	setSelectedMarkets,
	hasChangedUser,
	onboardingUser,
	setOnboardingUser,
	setSelectedRequestType,
	selectedRequestType,
	actionInProgress,
	prompt,
	toggleUserTypeBtnIndex,
	setToggleUserTypeBtnIndex,
	loading,
}) => {
	const applicationUsers = useStore(applicationUsersSelector);
	const columns = React.useMemo<ColumnDef<ApplicationUserRequests>[]>(
		() => [
			{
				header: "Avatar",
				id: "avatar-column",
				accessorKey: "name",
				cell: ({ row }: CellContext<ApplicationUserRequests, unknown>) => {
					const rowValue = row.getValue("avatar-column") as string;
					const userName = rowValue.split("(")[0].split(" ");
					return (
						<StyledAvatarWrapper>
							<Avatar
								screenReaderText={rowValue}
								text={`${userName[0].substring(0, 1)}${userName[1].substring(0, 1)}`}
							/>
						</StyledAvatarWrapper>
					);
				},
			},
			{
				header: "Name",
				accessorKey: "name",
				id: "name-column",
				cell: ({ row }: CellContext<ApplicationUserRequests, unknown>) => {
					const rowValue = row.getValue("name-column") as string;
					return <strong>{rowValue.split("(")[0]}</strong>;
				},
			},
			{
				header: "Role",
				accessorKey: "requestedMarkets",
				id: "role-column",
				cell: ({ row }: CellContext<ApplicationUserRequests, unknown>) => {
					const rowValue = row.getValue("role-column") as any;
					const requestedRole = row.original?.requestedRole;
					if (requestedRole) {
						{
							return requestedRole === UserPermissions.Admin ? (
								<StyledCommercialMessage message="Admin" variant="family" subtle />
							) : requestedRole === UserPermissions.ReadOnly ? (
								<StyledCommercialMessage message="Read Only" variant="new" subtle />
							) : requestedRole === UserPermissions.Maintainer ? (
								<StyledCommercialMessage message="Maintainer" variant="nlp" subtle />
							) : (
								<p>-</p>
							);
						}
					} else {
						if (rowValue && rowValue.length > 0 && rowValue[0] !== "") {
							return <StyledMessage message="Maintainer" variant="nlp" subtle />;
						} else {
							return <StyledMessage message="Read Only" variant="new" subtle />;
						}
					}
				},
			},
			{
				header: "Mail",
				id: "email-column",
				accessorKey: "email",
			},
			{
				header: "Request",
				id: "request-type",
				accessorKey: "requestType",
				cell: ({ row }: CellContext<ApplicationUserRequests, unknown>) => {
					const rowValue = row.getValue("request-type") as any;

					if (rowValue === "application") {
						return <RequestTypeMessage message="Application Request" />;
					} else if (rowValue === "markets") {
						return <RequestTypeMessage message="Markets Request" variant="family" />;
					} else if (rowValue === "role") {
						return <RequestTypeMessage message="Role Request" variant="nlp" />;
					}
				},
			},
			{
				header: "Markets",
				id: "markets-column",
				accessorKey: "requestedMarkets",
				cell: ({ row }: CellContext<ApplicationUserRequests, unknown>) => {
					const rowValue = row.getValue("markets-column") as any;
					if (rowValue && rowValue.length) {
						const markets = [];
						for (let i = 0; i < rowValue.length; i++) {
							if (rowValue[i]) {
								if (i < 5) {
									markets.push(<MarketImage src={getImage(rowValue[i]) || getImage("xx")} title={rowValue[i]} />);
								} else {
									markets.push(<p>...</p>);
									break;
								}
							} else {
								markets.push("-");
								break;
							}
						}
						return <StyledMarketIconWrapper>{markets}</StyledMarketIconWrapper>;
					} else {
						return "-";
					}
				},
			},
			{
				header: "Action",
				id: "action-column",
				accessorKey: "email",
				cell: ({ row }: CellContext<ApplicationUserRequests, unknown>) => {
					const rowValue = row.getValue("action-column") as string;
					const requestType = row.getValue("request-type") as "application" | "markets" | "role";
					const roleRequested = row.original.requestedRole;
					return (
						<StyledActionWrapper>
							<Button
								id={`accept-application-btn-${rowValue.split("@")[0]}`}
								text="Accept"
								iconPosition="leading"
								type="emphasised"
								small
								ssrIcon={checkmark}
								onClick={() => {
									setModalHeading(requestType === "application" ? "Onboard New User" : "Update User");
									setModalButtonLabel(requestType === "application" ? "Onboard User" : "Update User");

									// let UserData = data?.find((userData) => userData.email === rowValue);
									let userRequestData = applicationAccessRequest?.find((userData) => userData.email === rowValue);
									if (activeApplication && uiSchemas) {
										setSelectedUser({
											email: userRequestData?.email || "",
											id: userRequestData?.id || "",
											applications: {
												[activeApplication.id]: {
													...(requestType === "application" && { permissions: "maintainer" }),
													markets:
														userRequestData?.requestedMarkets &&
														!(
															requestType === "role" &&
															roleRequested &&
															(roleRequested === UserPermissions.Admin || roleRequested === UserPermissions.ReadOnly)
														)
															? userRequestData.requestedMarkets
																	.map((item: string) => {
																		return {
																			[item]:
																				uiSchemas.find((item) => item.uiSchemaName === "uischema")?.uiSchemaName ||
																				uiSchemas[0].uiSchemaName,
																		};
																	})
																	.reduce((acc: object, obj: any) => Object.assign(acc, obj), {})
															: undefined,
													...(requestType === "markets" && { existingMarkets: userRequestData?.existingMarkets }),
													...(requestType === "role" &&
														roleRequested &&
														roleRequested === UserPermissions.Admin && { permissions: UserPermissions.Admin }),
													...(requestType === "role" &&
														roleRequested &&
														roleRequested === UserPermissions.Maintainer && {
															permissions: UserPermissions.Maintainer,
														}),
													...(requestType === "role" &&
														roleRequested &&
														roleRequested === UserPermissions.ReadOnly && {
															permissions: UserPermissions.ReadOnly,
														}),
												},
											},
										});
									} else {
										setSelectedUser(undefined);
									}
									setSelectedRequestType(requestType);
									setAddingNewUser(false);
									setOnboardingUser(true);
									setShowModal(true);
								}}
							/>
							<Button
								id="decline-request-btn"
								text="Decline"
								small
								iconPosition="leading"
								type="tertiary"
								ssrIcon={trashCan}
								onClick={() => {
									setShowDeclineModal(true);
									setRequestEmail(rowValue);
									setSelectedRequestType(requestType);
								}}
							/>
						</StyledActionWrapper>
					);
				},
			},
		],
		[
			activeApplication,
			applicationAccessRequest,
			setAddingNewUser,
			setModalButtonLabel,
			setModalHeading,
			setOnboardingUser,
			setRequestEmail,
			setSelectedRequestType,
			setSelectedUser,
			setShowDeclineModal,
			setShowModal,
			uiSchemas,
		]
	);

	const table = useReactTable({
		data: applicationAccessRequest,
		columns,
		initialState: {
			pagination: {
				pageSize: 10,
			},
		},
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	if (loading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Users" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	if (!loading && applicationUsers?.requests?.length === 0) {
		return (
			<StyledLoadingWrapper>
				<InlineMessage title="No Requests!" body={"Seems like you don't have any requests"} variant="informative" />
			</StyledLoadingWrapper>
		);
	}

	return (
		<>
			<StyledHeader>Users Requests ({applicationUsers?.requests?.length})</StyledHeader>
			<StyledToggle
				id="user-requests-toggle"
				onClick={(_, index: number) => setToggleUserTypeBtnIndex(index)}
				buttons={Object.values(userRequestType).map((requestType: string, index: number) => {
					let usersRequestsType = applicationUsers?.requests?.filter(
						(userRequest) => userRequest.requestType === Object.keys(userRequestType)[index]
					);
					if (usersRequestsType && usersRequestsType.length > 0) {
						return { text: `${requestType} (${usersRequestsType.length})` };
					} else {
						return { text: `${requestType}`, disabled: true};
					}
				})}
				fluid={false}
				activeIndex={toggleUserTypeBtnIndex ? toggleUserTypeBtnIndex : 99}
			/>

			{applicationUsers?.requests && applicationUsers?.requests?.length > 0 && (
				<StyledTableWrapper>
					<table className="table">
						<thead className="table-header">
							{table.getHeaderGroups().map((headerGroup) => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<th key={header.id} colSpan={header.colSpan}>
												{header.isPlaceholder ? null : (
													<>{flexRender(header.column.columnDef.header, header.getContext())}</>
												)}
											</th>
										);
									})}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row) => {
								return (
									<tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<td key={cell.id} style={{ textAlign: "center" }}>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					{applicationAccessRequest.length > 10 && (
						<StyledFooter>
							<Button
								iconOnly
								small
								type="tertiary"
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
								ssrIcon={arrowLeftToLine}
							></Button>
							<Button
								small
								text="Previous"
								type="tertiary"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
								ssrIcon={arrowLeft}
							></Button>
							<StyledFooterPageInfo>
								<div>Page</div>
								<strong>
									{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
								</strong>
							</StyledFooterPageInfo>
							<Button
								small
								text="Next"
								type="tertiary"
								iconPosition="trailing"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
								ssrIcon={arrowRight}
							></Button>
							<Button
								small
								iconOnly
								type="tertiary"
								onClick={() => table.setPageIndex(table.getPageCount() - 1)}
								disabled={!table.getCanNextPage()}
								ssrIcon={arrowRightToLine}
							></Button>
							<select
								value={table.getState().pagination.pageSize}
								onChange={(e) => {
									table.setPageSize(Number(e.target.value));
								}}
							>
								{[10, 20, 30].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										Show {pageSize}
									</option>
								))}
							</select>
						</StyledFooter>
					)}
				</StyledTableWrapper>
			)}
			<ManageUsersModal
				showModal={showModal}
				setShowModal={setShowModal}
				modalHeading={modalHeading}
				setModalHeading={setModalHeading}
				modalButtonLabel={modalButtonLabel}
				setModalButtonLabel={setModalButtonLabel}
				marketsData={marketsData}
				uiSchemas={uiSchemas}
				setSelectedUser={setSelectedUser}
				selectedUser={selectedUser}
				addingNewUser={addingNewUser}
				setAddingNewUser={setAddingNewUser}
				onFinalSubmit={onFinalSubmit}
				isAdmin={isAdmin}
				currentUserSelected={currentUserSelected}
				toastMessage={toastMessage}
				setToastMessage={setToastMessage}
				usersInApplication={usersInApplication}
				handleClearURLProps={handleClearURLProps}
				selectedUserEmail={selectedUser?.email}
				selectedMarkets={selectedMarkets}
				setSelectedMarkets={setSelectedMarkets}
				hasChangedUser={hasChangedUser}
				onboardingUser={onboardingUser}
				actionInProgress={actionInProgress}
			/>
			<DeclineAccessRequestModal
				showModal={showDeclineModal}
				setShowModal={setShowDeclineModal}
				appId={activeApplication?.id}
				email={requestEmail}
				selectedRequestType={selectedRequestType}
				setToastMsg={setToastMessage}
			></DeclineAccessRequestModal>

			<Modal
				focusLockProps={{ locked: false }}
				visible={prompt.show}
				handleCloseBtn={() => prompt.onClose()}
				escapable={false}
			>
				<StyledPrompt footer={null} header={null} title={null} titleId="">
					<InlineMessage
						variant="cautionary"
						body={prompt.body as React.ReactElement<any>}
						title={prompt.title}
						actions={[{ text: "OK", onClick: () => prompt.onClose() }]}
					/>
				</StyledPrompt>
			</Modal>
		</>
	);
};

export default Layout;
