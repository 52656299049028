import * as React from "react";

import useGetApplications from "../../hooks/useGetApplications";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import Layout from "./Layout";
import useStore, { activeMarketSelector } from "../../store";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import { getImage } from "../../utils/markets";

const MarketDashboard: React.FC = () => {
	const { data: applicationsData, loading: applicationsLoading, getApplicationsData } = useGetApplications();
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const activeMarket = useStore(activeMarketSelector);
	const { getCountryName } = useGetCountryName();
	const image = getImage(activeMarket);
	const [lastUpdateDate, setLastUpdateDate] = React.useState<string>("");
	const [lastUpdateDateLoading, setLastUpdateDateLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		getApplicationsData();
	}, [getApplicationsData]);

	return (
		<Layout
			applicationsData={applicationsData}
			applicationsLoading={applicationsLoading}
			isFlaggaAdmin={isFlaggaAdmin}
			activeMarket={activeMarket}
			getCountryName={getCountryName}
			image={image}
			lastUpdateDate={lastUpdateDate}
			setLastUpdateDate={setLastUpdateDate}
			lastUpdateDateLoading={lastUpdateDateLoading}
			setLastUpdateDateLoading={setLastUpdateDateLoading}
		/>
	);
};

export default MarketDashboard;
