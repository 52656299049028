import React from "react";

import Layout from "./Layout";

const FloatingHelpButton: React.FC = () => {
	const [showFloatingMenu, setShowFloatingMenu] = React.useState<boolean>(false);
	const [showFeedbackModal, setShowFeedbackModal] = React.useState<boolean>(false);

	return (
		<>
			<Layout
				showFloatingMenu={showFloatingMenu}
				setShowFloatingMenu={setShowFloatingMenu}
				showFeedbackModal={showFeedbackModal}
				setShowFeedbackModal={setShowFeedbackModal}
			/>
		</>
	);
};

export default FloatingHelpButton;
