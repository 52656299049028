import * as React from "react";
import cloneDeep from "lodash/cloneDeep";
import { AddressOrder, Templates } from "../../../types/configuration";

// DisplayOrder Helpers
export const swap = <T>(arr: T[], currentIndex: number, nextIndex: number): T[] => {
	const copy = cloneDeep(arr);
	const value = copy[currentIndex];
	const swapTarget = copy[nextIndex];
	copy[nextIndex] = value;
	copy[currentIndex] = swapTarget;
	return copy;
};

export const inBounds = (index: number, len: number): boolean => index >= 0 && index < len;

// Templates Helpers
export type TemplateKey = keyof AddressOrder["templates"];
export type TemplatesState = {
	[key in keyof AddressOrder["templates"]]: string;
};
export type Entry = [string, string];

export const getTemplateKeys = (templates: AddressOrder["templates"]): TemplateKey[] =>
	Object.keys(templates) as TemplateKey[];

export const createInitialState = (templates: AddressOrder["templates"]) => () =>
	getTemplateKeys(templates).reduce<TemplatesState>(
		(result: TemplatesState, key: TemplateKey) => ({
			...result,
			[key]: (templates[key] ?? []).join(" | "),
		}),
		{} as TemplatesState
	);

export const getEntry = (e: React.ChangeEvent<HTMLInputElement>): Entry => {
	return [e.target.name, e.target.value];};

export const update =
	(data: TemplatesState) =>
		(entry: Entry): TemplatesState =>
			Object.assign({}, data, { [entry[0]]: entry[1] });

type RunAllFunction<T> = (arg: T) => void;
export const runAll =
	<T>(...fns: RunAllFunction<T>[]) =>
		(arg: T) =>
			fns.forEach((fn) => fn(arg));

export const normalizeTemplate = (state: TemplatesState): Templates =>
	(Object.keys(state) as TemplateKey[]).reduce<Templates>(
		(result: Templates, key: TemplateKey) => ({
			...result,
			[key]: (state[key] ?? []).split(" | "),
		}),
		{} as Templates
	);
