import * as React from "react";

import arrowUpArrowDown from "@ingka/ssr-icon/paths/arrow-up-arrow-down";
import { ActionListItem } from "@ingka/action-list";

import { useDrag, useDrop, DropTargetMonitor, XYCoord } from "react-dnd";
import styled from "styled-components";

const StyledListItem = styled(ActionListItem)`
	.action-list-item__action:not(:disabled) {
		cursor: grab;
	}
`;

interface OrderItemProps {
	id: string;
	label: string;
	index: number;
	moveItem: (dragIndex: number, hoverIndex: number) => void;
	enabled: boolean;
}

interface DragItem {
	id: string;
	index: number;
	type: String;
}

const HoverType = "order-item";

const OrderItem: React.FC<OrderItemProps> = ({ id, index, label, moveItem, enabled }) => {
	const ref = React.useRef<HTMLDivElement>(null);
	//@ts-ignore
	const [{ handlerId }, dropRef] = useDrop({
		accept: HoverType,
		collect: (monitor) => ({
			handlerId: monitor.getHandlerId(),
		}),
		//@ts-ignore
		hover(item: DragItem, monitor: DropTargetMonitor) {
			if (!ref.current) {
				return;
			}

			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current.getBoundingClientRect();

			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveItem(dragIndex, hoverIndex);

			item.index = hoverIndex;
		},
	});
	//@ts-ignore
	const [{ isDragging }, dragRef] = useDrag({
		
		//@ts-ignore
		item: { id, index,type: HoverType },
		canDrag: enabled,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	dragRef(dropRef(ref));

	return (
		<div ref={ref} data-handler-id={handlerId} style={{ opacity: isDragging ? 0 : 1 }}>
			<StyledListItem label={label} caption="Drag to reorder" ssrIcon={arrowUpArrowDown} disabled={!enabled} />
		</div>
	);
};

export default OrderItem;
