import styled from "styled-components";
import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";
import * as React from "react";
import { HomePageLayoutTabs } from "../../../utils/constants";

import clipboardCheckmark from "@ingka/ssr-icon/paths/clipboard-checkmark";
import display from "@ingka/ssr-icon/paths/display";
import globe from "@ingka/ssr-icon/paths/globe";
import people from "@ingka/ssr-icon/paths/people";
import dataChart from "@ingka/ssr-icon/paths/data-chart";

import useTabs from "../../../hooks/useTabs";
import Accordion, { AccordionItem } from "@ingka/accordion";
import ListView, { ListViewItem } from "@ingka/list-view";
import { ListBoxGroup } from "@ingka/list-box";
import SSRIcon from "@ingka/ssr-icon";

interface MenuLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	showAdminLayout: boolean;
}

const StyledSheets = styled(Sheets)`
	top: 0;
	width: 100%;
	max-height: fit-content;
	border-radius: 8px;
	animation: left;

	.modal-body {
		padding-top: 0;
		padding-bottom: 2rem;
	}

	ul {
		padding: 0;
	}
`;

const StyledListBoxGroup = styled(ListBoxGroup)`
	padding-top: 0rem;
`;

const StyledAccordionItem = styled(AccordionItem)`
	border-bottom: 1px rgb(var(--colour-neutral-3, 223, 223, 223)) solid;

	.accordion-item-header {
		border-top: none !important;
	}

	.accordion-item-header__title {
		margin-left: 2.4rem;
		font-size: 0.875rem;
		line-height: 1.571;
		text-decoration: none;
		font-weight: 400;
	}

	.list-box__group {
		border-top: 1px rgb(var(--colour-neutral-3, 223, 223, 223)) solid;
	}

	.list-view-item:last-child {
		border-bottom: none;
	}
`;

const AccordionItemWrapper = styled.div`
	position: relative;

	#custom-header-accordion-item {
		margin-top: 2rem;
		position: absolute;
	}
`;

const StyledAccordion = styled(Accordion)`
	.accordion__item--active > .accordion__content {
		padding: 0rem;
	}
`;

const Layout: React.FC<MenuLayoutProps> = ({ showModal, setShowModal, showAdminLayout }) => {
	const { activeTab, setActiveTab } = useTabs();
	const navigationListData = React.useMemo(() => {
		const navigationListItems = [
			{
				key: "configuration",
				text: "Configuration",
				sectionId: HomePageLayoutTabs.Configuration,
				icon: clipboardCheckmark,
				visible: true,
			},
			{
				key: "schemas",
				text: "Schemas",
				sectionId: HomePageLayoutTabs.Schemas,
				icon: display,
				visible: showAdminLayout,
			},
			{ key: "markets", text: "Markets", sectionId: HomePageLayoutTabs.Markets, icon: globe, visible: showAdminLayout },
			{ key: "dashboard", text: "Dashboard", sectionId: HomePageLayoutTabs.Dashboard, icon: dataChart, visible: true },
			{ key: "users", text: "Users", sectionId: HomePageLayoutTabs.Users, icon: people, visible: showAdminLayout },
		];
		return navigationListItems;
	}, [showAdminLayout]);

	return (
		<div>
			<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
				<StyledSheets footer={null} header={<ModalHeader title="Menu" />}>
					<ModalBody>
						<ListView className="demo-action-list" id="unique-id">
							{navigationListData.map((listItem, index) => {
								if (listItem.key !== "dashboard" && listItem.key !== "users") {
									return (
										<ListViewItem
											onClick={(e) => {
												setActiveTab(listItem.sectionId);
												setShowModal(false);
											}}
											title={listItem.text}
											leadingIcon={listItem.icon}
											control="navigational"
										/>
									);
								} else if (listItem.key === "dashboard") {
									return (
										<>
											<StyledAccordion id="menu-list">
												<AccordionItemWrapper>
													<SSRIcon id="custom-header-accordion-item" paths={dataChart} viewBox="0 0 24 24" />
													<StyledAccordionItem id={`menuItem-${index}`} title={listItem.text}>
														<div>
															<ListView id="test" size="small">
																<StyledListBoxGroup />
																<ListViewItem
																	inset
																	title="Market dashboard"
																	control="radiobutton"
																	name="market-dashboard-menu"
																	id="market-dashboard-menu"
																	onChange={(e) => {
																		setActiveTab(HomePageLayoutTabs.MarketDashboard);
																		setShowModal(false);
																	}}
																	controlProps={
																		{
																			value: "marketDashboard",
																			checked: activeTab === "marketDashboard",
																		} as any
																	}
																/>
																<ListViewItem
																	inset
																	title="Global dashboard"
																	control="radiobutton"
																	name="dashboard-menu"
																	id="dashboard-menu"
																	onChange={(e) => {
																		setActiveTab(HomePageLayoutTabs.Dashboard);
																		setShowModal(false);
																	}}
																	controlProps={
																		{
																			value: "dashboard",
																			checked: activeTab === "dashboard",
																		} as any
																	}
																/>
															</ListView>
														</div>
													</StyledAccordionItem>
												</AccordionItemWrapper>
											</StyledAccordion>
										</>
									);
								} else if (listItem.key === "users") {
									return (
										<>
											<StyledAccordion id="menu-users-list">
												<AccordionItemWrapper>
													<SSRIcon id="custom-header-accordion-item" paths={people} viewBox="0 0 24 24" />
													<StyledAccordionItem id={`menuItem-${index}`} title={listItem.text}>
														<div>
															<ListView id="test" size="small">
																<StyledListBoxGroup />
																<ListViewItem
																	inset
																	title="Users onboarded"
																	control="radiobutton"
																	name="users-onboarded-menu"
																	id="users-onboarded-menu"
																	onChange={(e) => {
																		setActiveTab(HomePageLayoutTabs.Users);
																		setShowModal(false);
																	}}
																	controlProps={
																		{
																			value: "usersonboarded",
																			checked: activeTab === "usersOnboarded",
																		} as any
																	}
																/>
																<ListViewItem
																	inset
																	title="Users requests"
																	control="radiobutton"
																	name="users-requested-menu"
																	id="requested-menu"
																	onChange={(e) => {
																		setActiveTab(HomePageLayoutTabs.UsersRequested);
																		setShowModal(false);
																	}}
																	controlProps={
																		{
																			value: "usersRequested",
																			checked: activeTab === "usersRequested",
																		} as any
																	}
																/>
															</ListView>
														</div>
													</StyledAccordionItem>
												</AccordionItemWrapper>
											</StyledAccordion>
										</>
									);
								}
							})}
						</ListView>
					</ModalBody>
				</StyledSheets>
			</Modal>
		</div>
	);
};

export default Layout;
