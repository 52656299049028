import * as React from "react";
import Modal, { ModalFooter, Prompt, ModalHeader } from "@ingka/modal";
import InlineMessage, { InlineMessageVariant } from "@ingka/inline-message";
import styled from "styled-components";

const StyledModal = styled(Modal)`
	.modal-footer {
		display: none;
	}

	.prompt__content {
		padding: 0;
	}

	.modal-header {
		display: none;
	}

	.prompt__heading {
		display: none;
	}
`;

interface WarningModalLayoutProps {
	modalId: string;
	modalTitle?: string;
	showWarningModal: boolean;
	setShowWarningModal: (val: boolean) => void;
	children: any;
	variant: InlineMessageVariant;
	dismissable: boolean;
}

const Layout: React.FC<WarningModalLayoutProps> = ({
	modalId,
	modalTitle,
	showWarningModal,
	setShowWarningModal,
	children,
	variant,
	dismissable,
}) => {
	return (
		<StyledModal visible={showWarningModal} escapable={false} handleCloseBtn={() => setShowWarningModal(false)}>
			<Prompt
				id={modalId}
				title={modalTitle}
				titleId={"modalTitleId"}
				header={<ModalHeader />}
				footer={<ModalFooter children={undefined} />}
			>
				<InlineMessage
					title={modalTitle}
					body={children}
					variant={variant}
					dismissable={dismissable}
					onDismissClick={() => setShowWarningModal(false)}
				/>
			</Prompt>
		</StyledModal>
	);
};

export default Layout;
