import * as React from "react";

import Button from "@ingka/button";
import Modal, { ModalBody, Sheets } from "@ingka/modal";

import { ModalButtonWrapper } from "../../styles";
import MarketsAndSchemas from "./MarketsAndSchemas";
import { ApplicationUiSchema } from "../../../services/applications/types";
import { AddMarketFormErrors, AddMarketFormShouldValidate, AddMarketFormValid } from "./types";
import { UserData } from "../../../services/users";
import { StyledModalHeader } from "../../styles";

interface AddMarketModalLayoutProps {
	showAddMarketModal: boolean;
	setShowAddMarketModal: (value: boolean) => void;
	valid: AddMarketFormValid;
	setValid: (data: AddMarketFormValid) => void;
	shouldValidate: AddMarketFormShouldValidate;
	setShouldValidate: (data: AddMarketFormShouldValidate) => void;
	formError: AddMarketFormErrors;
	validate: (name: string, value: string) => void;
	setSelectedUser: (data?: UserData) => void;
	onAddMarket: () => void;
	setSelectedUiSchema: (value?: string) => void;
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	selectedMarkets?: string[];
	getCountryName: (country: string) => string;
	setSelectedMarkets: (value?: string[]) => void;
}

const Layout: React.FC<AddMarketModalLayoutProps> = ({
	showAddMarketModal,
	setShowAddMarketModal,
	marketsData,
	uiSchemas,
	valid,
	setValid,
	shouldValidate,
	setShouldValidate,
	formError,
	validate,
	setSelectedUser,
	setSelectedUiSchema,
	onAddMarket,
	selectedMarkets,
	getCountryName,
	setSelectedMarkets,
}) => {
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (valid.uiSchema) {
					onAddMarket();
				} else {
					validate("uiSchema", "Choose an option");
				}
			}}
		>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showAddMarketModal}
				handleCloseBtn={() => {
					setShowAddMarketModal(false);
				}}
				onModalClosed={() => {
					setValid({ market: false, uiSchema: false });
					setShouldValidate({ market: false, uiSchema: false });
					setSelectedUiSchema(undefined);
					setSelectedMarkets(undefined);
				}}
			>
				<Sheets
					footer={
						<ModalButtonWrapper>
							<Button id="add-markets-to-request-btn" text={"Add Markets"} type="emphasised" htmlType="submit" />
						</ModalButtonWrapper>
					}
					size="small"
					header={<StyledModalHeader title={"Select Markets"} />}
				>
					<ModalBody>
						<MarketsAndSchemas
							marketsData={marketsData}
							uiSchemas={uiSchemas}
							valid={valid}
							shouldValidate={shouldValidate}
							formError={formError}
							validate={validate}
							setSelectedUser={setSelectedUser}
							setSelectedUiSchema={setSelectedUiSchema}
							selectedMarkets={selectedMarkets}
							setSelectedMarkets={setSelectedMarkets}
							getCountryName={getCountryName}
						/>
					</ModalBody>
				</Sheets>
			</Modal>
		</form>
	);
};

export default Layout;
