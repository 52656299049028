import React from "react";
import Layout from "./Layout";
import { Value } from "../../../../utils/types";

interface RequestOverviewProps {
	value: Value;
}

const RequestOverview: React.FC<RequestOverviewProps> = ({ value }) => {
	return <Layout value={value} />;
};

export default RequestOverview;
