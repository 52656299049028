import * as React from "react";
import useAuthentication from "./useAuthentication";
import { BackupDates, getApplicationBackupDates } from "../services/backupAndRestore";

interface ApplicationBackupHookParams {
	application?: string;
}

const useGetApplicationBackupDates = () => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<BackupDates[] | undefined>(undefined);
	const { getToken, handleLogout } = useAuthentication();

	const getBackupDates = React.useCallback(
		(application?: string) => {
			if (application) {
				(async () => {
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					try {
						setLoading(true);
						const { data: appBackupDates } = await getApplicationBackupDates(authHeader, application);
						const hasDates = appBackupDates.data.length;
						setData(hasDates ? appBackupDates.data : undefined);
					} catch (error: any) {
						if (error) {
							setData(undefined);
							setLoading(false);
							if (error?.response?.status == 401) {
								handleLogout();
							}
						}
					} finally {
						setLoading(false);
					}
				})();
			}
		},
		[getToken, handleLogout]
	);

	return { data, loading, getBackupDates };
};

export default useGetApplicationBackupDates;
