import styled from "styled-components";
import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";
import * as React from "react";

import Accordion, { AccordionItem } from "@ingka/accordion";
import { colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";
import TextArea from "@ingka/text-area";
import FormField from "@ingka/form-field";

import { getImage } from "../../../utils/markets";
import Toast from "@ingka/toast";
import ManageSchedulingDateTimePicker from "./ManageSchedulingDateTimePicker";
import Avatar from "@ingka/avatar";
import { colourNeutral3 } from "@ingka/variables/colours-css";
import Loading, { LoadingBall } from "@ingka/loading";
import { User } from "../../../store";
import Status from "@ingka/status";

const StyledModalHeader = styled(ModalHeader)`
	background-color: ${colourStaticIkeaBrandYellow};
`;

const ScheduledChangesActionWrapper = styled.div`
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;

const StyledTextArea = styled(TextArea)`
	padding-top: 1.5rem;
	width: 100%;
	border-bottom: 1px solid ${colourNeutral3};
	padding-bottom: 1.5rem;
`;

const MarketImage = styled.img`
	height: 25px;
	width: 25px;
	margin-right: 0.2rem;
`;

const StyledAccordion = styled(Accordion)`
	.accordion__item--active > .accordion__content {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}
`;

const StyledAvatar = styled(Avatar)`
	width: 2.8rem;
`;

const StyledUserInfoWrapper = styled.div`
	display: flex;
	gap: 1rem;
	border-bottom: 1px solid ${colourNeutral3};
	padding-bottom: 1.5rem;
`;

const StyledUserNameWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledBlockWrapper = styled.div`
	border-bottom: 1px solid ${colourNeutral3};
	padding-bottom: 1.5rem;
`;

interface ManageScheduledChangesLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	isFlaggaAdmin: boolean;
	scheduledChanges: any;
	onRemoveScheduledChanges: (id: string) => void;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	onUpdateScheduledChanges: (id: string, date: string) => void;
	updateScheduledChangesLoading: boolean;
	setUpdateScheduledChangesLoading: (value: boolean) => void;
	getScheduledChangesLoading: boolean;
	isAdmin: boolean;
	currentUser: User | null;
}

const Layout: React.FC<ManageScheduledChangesLayoutProps> = ({
	showModal,
	setShowModal,
	isFlaggaAdmin,
	scheduledChanges,
	onRemoveScheduledChanges,
	toastMessage,
	setToastMessage,
	onUpdateScheduledChanges,
	updateScheduledChangesLoading,
	setUpdateScheduledChangesLoading,
	getScheduledChangesLoading,
	isAdmin,
	currentUser,
}) => {
	return (
		<>
			<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
				<Sheets footer={null} header={<StyledModalHeader title="Manage scheduled changes" />}>
					<ModalBody>
						{getScheduledChangesLoading && (
							<Loading text="Loading data">
								<LoadingBall color="emphasised" size="large" />
							</Loading>
						)}
						{scheduledChanges && scheduledChanges.length > 0 && (
							<StyledAccordion>
								{scheduledChanges &&
									scheduledChanges.map((change: any) => {
										const userName = change.user.split("@")[0].split(".");
										return (
											<AccordionItem
												id={change.id}
												caption={
													<>
														<span>{change.reason}</span>
														{change.status === "pending" && (
															<Status small label="Pending" statusColor="orange" statusDotPosition="leading" />
														)}
														{change.status === "failed" && (
															<Status small label="Failed" statusColor="red" statusDotPosition="leading" />
														)}
													</>
												}
												title={new Date(change.updateAt["_seconds"] * 1000).toUTCString()}
											>
												<h4>Changes scheduled by:</h4>
												<StyledUserInfoWrapper>
													<StyledAvatar
														screenReaderText={userName}
														variant="primary"
														text={`${userName[0].substring(0, 1).toUpperCase()}${userName[1]
															.substring(0, 1)
															.toUpperCase()}`}
													/>
													<StyledUserNameWrapper>
														<span>{userName[0].charAt(0).toUpperCase() + userName[0].slice(1)}</span>
														<span>{change.user}</span>
													</StyledUserNameWrapper>
												</StyledUserInfoWrapper>
												<StyledBlockWrapper>
													<h4>Scheduled in the following markets:</h4>
													{change.markets.map((market: any) => (
														<MarketImage src={getImage(market) || getImage("xx")} />
													))}
												</StyledBlockWrapper>
												<StyledBlockWrapper>
													<h4>Name of ui-schema:</h4>
													{change.uiSchemaName}
												</StyledBlockWrapper>
												<FormField>
													<StyledTextArea
														label="Changed Value"
														rows={6}
														id="text-area-id"
														value={JSON.stringify(change.value)}
													/>
												</FormField>
												<ScheduledChangesActionWrapper>
													<ManageSchedulingDateTimePicker
														isAdmin={isAdmin}
														currentUser={currentUser}
														onRemoveScheduledChanges={onRemoveScheduledChanges}
														scheduledChangeId={change.id}
														lastUpdatedBy={change.user}
														scheduledTime={change.updateAt["_seconds"] * 1000}
														onUpdateScheduledChanges={onUpdateScheduledChanges}
														updateScheduledChangesLoading={updateScheduledChangesLoading}
														setUpdateScheduledChangesLoading={setUpdateScheduledChangesLoading}
													/>
												</ScheduledChangesActionWrapper>
											</AccordionItem>
										);
									})}
							</StyledAccordion>
						)}
						{!scheduledChanges ||
							(scheduledChanges.length === 0 && !getScheduledChangesLoading && <h4>No changes were scheduled</h4>)}
					</ModalBody>
				</Sheets>
			</Modal>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				ariaLabelCloseBtn="Dismiss notification"
				onCloseRequest={() => setToastMessage("")}
			/>
		</>
	);
};

export default Layout;
