import * as React from "react";
import styled from "styled-components";

import TextArea from "@ingka/text-area";
import Text from "@ingka/text";
import Accordion, { AccordionItem } from "@ingka/accordion";
import Loading, { LoadingBall } from "@ingka/loading";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";
import Toast from "@ingka/toast";
import FormField from "@ingka/form-field";

import { CurrentValues, FormErrors, FormValid, ShouldValid, UiSchemaError } from "./types";
import { ActionsWrapper, LoadingWrapper, SaveButtonWrapper, StyledModalHeader } from "../styles";
import AddSchemaModal from "./AddSchemaModal";
import { Application } from "../../store";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import { breakpointL } from "@ingka/variables/design-tokens";

interface SchemasLayout {
	loading: boolean;
	onValueChange: (value: string, name: string, isSchema: boolean) => void;
	hasChanged: boolean;
	onSave: () => void;
	onAdd: React.FormEventHandler<HTMLFormElement>;
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	setNewUiSchemaValue: (value: string) => void;
	setNewUiSchemaName: (value: string) => void;
	formValid: FormValid;
	formErrors: FormErrors;
	shouldValid: ShouldValid;
	validate: (val: { name: string; value: string }) => void;
	onModalClose: () => void;
	isNewApplication: boolean;
	activeApplication: Application;
	saveBtnLoading: boolean;
	toastMessage: string;
	setToastMessage: (val: string) => void;
	schemaErr: string;
	uiSchemaErr?: UiSchemaError;
	currentValues?: CurrentValues;
	showReasonModal: boolean;
	setShowReasonModal: (val: boolean) => void;
	setReason: (val: string) => void;
}

const StyledTextArea = styled(TextArea)`
	margin-bottom: 1rem;
	textarea {
		height: 60vw;
	}
`;

const UiSchemaSection = styled.div`
	padding: 0;
`;

const NoUiSchemaWrapper = styled.div`
	text-align: center;
	padding: 2rem 0;
`;

const AddButtonWrapper = styled.div`
	margin-top: 2rem;

	@media only screen and (max-width: ${breakpointL}) {
		display: flex;
		justify-content: center;
	} ;
`;

const StyledSchemaWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-column-gap: 8rem;

	@media only screen and (max-width: ${breakpointL}) {
		display: flex;
		flex-direction: column;
	} ;
`;

const Layout: React.FC<SchemasLayout> = ({
	loading,
	onValueChange,
	hasChanged,
	onSave,
	onAdd,
	showModal,
	setShowModal,
	setNewUiSchemaValue,
	setNewUiSchemaName,
	shouldValid,
	formErrors,
	formValid,
	validate,
	onModalClose,
	isNewApplication,
	activeApplication,
	currentValues,
	saveBtnLoading,
	toastMessage,
	setToastMessage,
	uiSchemaErr,
	schemaErr,
	showReasonModal,
	setShowReasonModal,
	setReason,
}) => {
	if (loading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Schemas and UI-Schemas" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	if (!loading && !currentValues) {
		return (
			<LoadingWrapper>
				<InlineMessage
					title="No Schemas or UI-Schemas found"
					body={"Seems like you don't have access to any Schemas or UI-Schemas"}
					variant="negative"
				/>
			</LoadingWrapper>
		);
	}

	return (
		<>
			<StyledSchemaWrapper>
				<div>
					<p>Schema</p>
					<Accordion size="small">
						<AccordionItem
							key={"schema-data"}
							id={"schema-data"}
							title={"Schema"}
							caption={`Schema for ${activeApplication?.name}`}
						>
							<FormField
								valid={!schemaErr}
								shouldValidate={!!schemaErr}
								validation={{ msg: schemaErr || "", id: "schema-error", type: "error" }}
							>
								<StyledTextArea
									label=""
									id="text-area-id"
									disabled={saveBtnLoading}
									value={currentValues?.schema}
									onChange={(e) => {
										onValueChange(e.target.value, "", true);
									}}
								/>
							</FormField>
						</AccordionItem>
					</Accordion>
				</div>
				<div>
					{currentValues?.uiSchemas.length ? (
						<div>
							<p>UI Schemas</p>
							<Accordion size="small">
								{currentValues?.uiSchemas.map((data, index) => (
									<AccordionItem key={data.uiSchemaName} id={data.uiSchemaName} title={data.uiSchemaName}>
										<FormField
											valid={uiSchemaErr?.index !== index}
											shouldValidate={!!uiSchemaErr}
											validation={{ msg: uiSchemaErr?.errMsg || "", id: "ui-schema-error", type: "error" }}
										>
											<StyledTextArea
												label={""}
												key={`${data.uiSchemaName}-key`}
												id={data.uiSchemaName}
												value={data.uiSchema}
												disabled={saveBtnLoading}
												onChange={(e) => {
													onValueChange(e.target.value, data.uiSchemaName, false);
												}}
											/>
										</FormField>
									</AccordionItem>
								))}
							</Accordion>
						</div>
					) : (
						<NoUiSchemaWrapper>
							<p>No UI-Schema found please add a new UI-Schema</p>
						</NoUiSchemaWrapper>
					)}
					<AddButtonWrapper>
						<Button data-testid="addUiSchema" text="Add UI-Schema" onClick={() => setShowModal(true)} />
					</AddButtonWrapper>
				</div>
			
				<ActionsWrapper>
					<SaveButtonWrapper>
						<Button
							data-testid="saveChanges"
							text="Save Changes"
							disabled={!hasChanged}
							type="emphasised"
							onClick={() => setShowReasonModal(true)}
							loading={saveBtnLoading}
						/>
					</SaveButtonWrapper>
				</ActionsWrapper>
			
			</StyledSchemaWrapper>
			<AddSchemaModal
				showModal={showModal}
				onAdd={onAdd}
				setNewUiSchemaValue={setNewUiSchemaValue}
				setNewUiSchemaName={setNewUiSchemaName}
				shouldValid={shouldValid}
				formErrors={formErrors}
				formValid={formValid}
				validate={validate}
				onModalClose={onModalClose}
				isNewApplication={isNewApplication}
			/>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showReasonModal}
				escapable={false}
				handleCloseBtn={() => setShowReasonModal(false)}
			>
				<Prompt
					title=""
					titleId={"modalTitleId"}
					header={<StyledModalHeader title="Reason for changes" />}
					footer={
						<ModalFooter>
							<Button data-testid="cancel" text="Cancel" type="secondary" onClick={() => setShowReasonModal(false)} />
							<Button data-testid="savechanges" text="Save changes" type="emphasised" onClick={() => onSave()} />
						</ModalFooter>
					}
				>
					<p>Please write the reason for changes (optional)</p>
					<FormField>
						<TextArea id="reason-text-area" label="" onChange={(ev) => setReason(ev.target.value)} rows={6} />
					</FormField>
					<InlineMessage
						body={
							<>
								<Text>It seems like you have done some changes in Schema.</Text>
								<Text>
									If the properties are <strong>removed from schema</strong>, it will be{" "}
									<strong>removed from all the markets data</strong>.
								</Text>
							</>
						}
						variant="cautionary"
						subtle
					/>
				</Prompt>
			</Modal>
		</>
	);
};

export default Layout;
