import React from "react";

import { ListViewItem } from "@ingka/list-view";
import globe from "@ingka/ssr-icon/paths/globe";
import dataChart from "@ingka/ssr-icon/paths/data-chart";

import ContentBox from "../../../ContentBox/ContentBox";
import { StyledListView } from "../../../styles";
import { ValidationProps } from "../../types";

interface DashboardLayoutProps extends ValidationProps {}

const Layout: React.FC<DashboardLayoutProps> = ({ value, valid, validation, handleChange, shouldValidate }) => {
	return (
		<ContentBox>
			<StyledListView id="dashborad-enabler">
				<ListViewItem
					id="dashboard-market"
					title="Market Dashboard"
					emphasised
					description="Users can review specific market with adoption rate."
					control="switch"
					leadingIcon={dataChart}
					onChange={() => {
						handleChange("", "showMarketDashboard");
					}}
					controlProps={{ checked: value.showMarketDashboard || false, value: "" } as any}
				/>
				<ListViewItem
					id="dashboard-global"
					title="Global Dashboard"
					emphasised
					description="You can review all the markets."
					control="switch"
					leadingIcon={globe}
					onChange={() => {
						handleChange("", "showDashboard");
					}}
					controlProps={{ checked: value.showDashboard || false } as any}
				/>
			</StyledListView>
		</ContentBox>
	);
};

export default Layout;
