import * as React from "react";
import assert from "assert";
import styled from "styled-components";
import FormField from "@ingka/form-field";
import useGetUiSchemas from "../../hooks/useGetUiSchemas";
import Layout from "./Layout";
import useAuthentication from "../../hooks/useAuthentication";
import { updateSchemaData } from "./utils";
import useStore, { activeApplicationSelector } from "../../store";
import { SchemaService } from "../ui-schema-builder/core/api/schemaService";
import { ApplicationUiSchema } from "../../services/applications/types";
import { Schema as TypeforSchema } from "../SchemaBuilder/utils/types";

export const StyledFormField = styled(FormField)`
	margin-bottom: 2rem;
`;

const UiSchemas: React.FC = () => {
	const [importedSchema, setImportedSchema] = React.useState<boolean>(false);
	const activeApplication = useStore(activeApplicationSelector);
	const { data: Schemas, loading: uiSchemasLoading } = useGetUiSchemas({
		appId: activeApplication?.id || "",
		getUiSchemasData: true,
		getSchemaData: true,
		importedSchema,
	});
	const [currentUiSchemas, setCurrentUiSchemas] = React.useState<ApplicationUiSchema[]>([]);
	const [originalUiSchemas, setOriginalUiSchemas] = React.useState<ApplicationUiSchema[]>([]);
	const [currentUiSchema, setCurrentUiSchema] = React.useState<any>(undefined);
	const [orignalUiSchema, setOrignalUiSchema] = React.useState<any>(undefined);
	const [selectedUiSchema, setSelectedUiSchema] = React.useState<string>("ui-schema");
	const [preliminarySelectedUiSchema, setPreliminarySelectedUiSchema] = React.useState<string>("ui-schema");
	const [newUISchemaName, setNewUISchemaName] = React.useState<string | undefined>(undefined);
	const [hasChanged, setHasChanged] = React.useState<boolean>(false);
	const [saveBtnLoading, setSaveBtnLoading] = React.useState<boolean>(false);
	const [updateSchemaLoading, setUpdateSchemaLoading] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState("");
	const { getToken } = useAuthentication();
	const [showReasonModal, setShowReasonModal] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>("");
	const [showNewUiSchemaModal, setShowNewUiSchemaModal] = React.useState<boolean>(false);
	const [showRemoveSchemaModal, setShowRemoveSchemaModal] = React.useState<boolean>(false);
	const [newUiSchemaCurrentName, setNewUiSchemaCurrentName] = React.useState<string>("");
	const [showSaveChangesModal, setShowSaveChangesModal] = React.useState<boolean>(false);
	const [showAddChangesModal, setShowAddChangesModal] = React.useState<boolean>(false);
	const [newSchemaAdded, setNewSchemaAdded] = React.useState<boolean>(false);
	const [showImportSchemaModal, setShowImportSchemaModal] = React.useState<boolean>(false);
	const [forceReset, setForceReset] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (Schemas && Schemas["ui-schemas"]) {
			setCurrentUiSchemas(Schemas["ui-schemas"]);
			setOriginalUiSchemas(Schemas["ui-schemas"]);
		}
	}, [Schemas]);

	let schemasLengthChanged = React.useMemo(() => {
		return currentUiSchemas?.length !== originalUiSchemas.length;
	}, [currentUiSchemas, originalUiSchemas]);

	let uiSchemaNames = React.useMemo(() => {
		return currentUiSchemas?.map(({ uiSchemaName, uiSchema }: any) => {
			return uiSchemaName;
		});
	}, [currentUiSchemas]);

	let schemaService: SchemaService = React.useMemo(() => {
		return {
			getSchema: async () => Schemas?.schema || {},
			getUiSchema: async () => currentUiSchemas?.find((item) => item.uiSchemaName === selectedUiSchema)?.uiSchema,
		};
	}, [currentUiSchemas, selectedUiSchema, Schemas?.schema]);

	React.useEffect(() => {
		setCurrentUiSchema(currentUiSchemas?.find((item) => item.uiSchemaName === selectedUiSchema)?.uiSchema);
		setOrignalUiSchema(currentUiSchemas?.find((item) => item.uiSchemaName === selectedUiSchema)?.uiSchema);
	}, [currentUiSchemas, selectedUiSchema]);

	React.useEffect(() => {
		if (!newSchemaAdded) {
			try {
				assert.deepStrictEqual(currentUiSchema, orignalUiSchema);
				setHasChanged(false);
			} catch (e) {
				setHasChanged(true);
			}
		} else {
			setHasChanged(true);
		}
	}, [currentUiSchema, orignalUiSchema, newSchemaAdded]);

	const onSave = React.useCallback(async () => {
		if (currentUiSchema) {
			try {
				setSaveBtnLoading(true);
				setShowReasonModal(false);

				let schemaData = {} as any;

				let schemaKey = selectedUiSchema || "";

				schemaData = { [schemaKey]: currentUiSchema };

				const newData = await updateSchemaData({
					getToken,
					activeApplication,
					setToastMessage,
					schemaData,
					reason,
					setSaveBtnLoading,
				});

				if (newData && newData.schema) {
					setSaveBtnLoading(false);
					setToastMessage("Succesfully updated the values");
					setOriginalUiSchemas(newData.uiSchemas);
					setCurrentUiSchemas(newData.uiSchemas);
					setHasChanged(false);
					setNewSchemaAdded(false);
				}
			} catch (err: any) {
				setSaveBtnLoading(false);
				console.error(err);
			}
		}
	}, [currentUiSchema, selectedUiSchema, getToken, activeApplication, reason]);

	const onSaveNew = React.useCallback(async () => {
		try {
			setShowSaveChangesModal(false);
			setUpdateSchemaLoading(true);

			let schemaData = {} as any;

			let schemaKey = selectedUiSchema || "";

			schemaData = { [schemaKey]: currentUiSchema || {} };

			const newData = await updateSchemaData({
				getToken,
				activeApplication,
				setToastMessage,
				schemaData,
				reason: `UI schema ${selectedUiSchema} created`,
			});

			if (newData && newData.schema) {
				setCurrentUiSchemas(newData["uiSchemas"]);
				setOriginalUiSchemas(newData["uiSchemas"]);
				setUpdateSchemaLoading(false);
				setToastMessage("Succesfully updated the values");
				setNewSchemaAdded(false);
			}
		} catch (err: any) {
			setUpdateSchemaLoading(false);
			console.error(err);
		}
	}, [selectedUiSchema, currentUiSchema, getToken, activeApplication]);

	const onRemoveSchema = React.useCallback(async () => {
		try {
			setShowRemoveSchemaModal(false);
			setUpdateSchemaLoading(true);

			let schemaData = {} as any;
			let schemaKey = selectedUiSchema || "";

			schemaData = { [schemaKey]: null };

			const newData = await updateSchemaData({
				getToken,
				activeApplication,
				setToastMessage,
				schemaData,
				reason: `UI schema ${selectedUiSchema} removed`,
			});
			setUpdateSchemaLoading(false);
			if (newData && newData.schema) {
				setCurrentUiSchemas(newData["uiSchemas"]);
				setOriginalUiSchemas(newData["uiSchemas"]);
				setSelectedUiSchema("ui-schema");
				setToastMessage("UI schema was removed");
			}
		} catch (err) {
			setUpdateSchemaLoading(false);
			console.error(err);
		}
	}, [activeApplication, getToken, selectedUiSchema]);

	const onCancelChanges = React.useCallback(() => {
		setForceReset(true);
	}, []);


	return (
		<Layout
			loading={uiSchemasLoading}
			selectedUiSchema={selectedUiSchema}
			setSelectedUiSchema={setSelectedUiSchema}
			uiSchemaNames={uiSchemaNames}
			setNewUISchemaName={setNewUISchemaName}
			schemaService={schemaService}
			setCurrentUiSchema={setCurrentUiSchema}
			hasChanged={hasChanged}
			setShowReasonModal={setShowReasonModal}
			saveBtnLoading={saveBtnLoading}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			showReasonModal={showReasonModal}
			onSave={onSave}
			setReason={setReason}
			showNewUiSchemaModal={showNewUiSchemaModal}
			setShowNewUiSchemaModal={setShowNewUiSchemaModal}
			newUiSchemaCurrentName={newUiSchemaCurrentName}
			setNewUiSchemaCurrentName={setNewUiSchemaCurrentName}
			currentUiSchemas={currentUiSchemas}
			setCurrentUiSchemas={setCurrentUiSchemas}
			showRemoveSchemaModal={showRemoveSchemaModal}
			setShowRemoveSchemaModal={setShowRemoveSchemaModal}
			onRemoveSchema={onRemoveSchema}
			updateSchemaLoading={updateSchemaLoading}
			schemasLengthChanged={schemasLengthChanged}
			preliminarySelectedUiSchema={preliminarySelectedUiSchema}
			setPreliminarySelectedUiSchema={setPreliminarySelectedUiSchema}
			showSaveChangesModal={showSaveChangesModal}
			setShowSaveChangesModal={setShowSaveChangesModal}
			originalUiSchemas={originalUiSchemas}
			onSaveNew={onSaveNew}
			currentUiSchema={currentUiSchema}
			showAddChangesModal={showAddChangesModal}
			setShowAddChangesModal={setShowAddChangesModal}
			setNewSchemaAdded={setNewSchemaAdded}
			showImportSchemaModal={showImportSchemaModal}
			setShowImportSchemaModal={setShowImportSchemaModal}
			setImportedSchema={setImportedSchema}
			onCancelChanges={onCancelChanges}
			setForceReset={setForceReset}
			forceReset={forceReset}
		/>
	);
};

export default UiSchemas;
