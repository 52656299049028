/* eslint-disable quotes */
import * as React from "react";
import dayjs from "dayjs";
import parse from "html-react-parser";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlElement, ControlProps } from "@jsonforms/core";
import FormField from "@ingka/form-field";
import Tooltip from "@ingka/tooltip";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormHelperText, InputLabel } from "@mui/material";

import { datePickerInputFieldStyles } from "../../styles";
import { findAndTransformHTMLString } from "../utils";

interface InputProps extends ControlProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const DateTimePickerControl: React.FC<InputProps> = ({
	data,
	handleChange,
	path,
	label,
	enabled,
	description,
	uischema,
	errors,
	required,
}) => {
	const onChange = React.useCallback(
		(val) => {
			handleChange(path, dayjs(val).format("YYYY-MM-DDTHH:mm:00Z").toString());
		},
		[path, handleChange]
	);

	const errMessage =
		required && errors.length
			? `${label} ${errors}`
			: required
			? `${label} is a required property`
			: `${label} ${errors}`;

	const dateTimePicker = React.useMemo(
		() => (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<InputLabel
					htmlFor="date-input"
					sx={{
						fontSize: "0.875rem",
						lineHeight: "1.571",
						color: "rgb(72, 72, 72)",
						letterSpacing: "0",
						// eslint-disable-next-line quotes
						fontFamily: `"Noto IKEA", "Noto Sans", "Roboto", "Open Sans", "sans-serif"`,
					}}
				>
					{label}
				</InputLabel>
				<DateTimePicker
					label=""
					disabled={!enabled}
					value={data ? dayjs(data) : null}
					format="YYYY-MM-DD HH:mm"
					onChange={(newValue) => onChange(newValue)}
					slotProps={{
						textField: {
							variant: "outlined",
							sx: {
								...datePickerInputFieldStyles,
								fieldset: {
									margin: 0,
								},
							},
						},
					}}
				/>
				<FormHelperText
					sx={{
						fontFamily: `"Noto IKEA", "Noto Sans", "Roboto", "Open Sans", "sans-serif"`,
						color: "rgb(var(--colour-text-and-icon-3, 118, 118, 118))",
					}}
				>
					Time is selected in local timezone
				</FormHelperText>
			</LocalizationProvider>
		),
		[label, enabled, data, onChange]
	);
	return (
		<>
			<FormField
				fieldHelper={{
					msg: uischema?.subText ? <div>{parse(findAndTransformHTMLString(uischema.subText))}</div> : "",
					id: "input-control-msg",
				}}
				valid={required ? !errors && data : !errors}
				shouldValidate={errors.length > 0 || (required && !data)}
				validation={{
					id: "error-msg-id",
					msg: errMessage,
					type: "error",
				}}
			>
				{description ? <Tooltip tooltipText={description}>{dateTimePicker}</Tooltip> : dateTimePicker}
			</FormField>
		</>
	);
};

export default withJsonFormsControlProps(DateTimePickerControl);
