import * as React from "react";
import EmailConsent from "../components/EmailConsent";
import RequireAuth from "../components/RequireAuth/RequireAuth";

const Consent: React.FC = () => {
	return (
		<RequireAuth>
			<EmailConsent />
		</RequireAuth>
	);
};

export default Consent;
