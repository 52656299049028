import React from "react";
import _ from "lodash/fp";

import plus from "@ingka/ssr-icon/paths/plus";
import Button from "@ingka/button";
import Tooltip from "@ingka/tooltip";

type Props = {
	onClick?: () => void;
	title?: string;
};

const AddButton: React.FunctionComponent<Props> = ({ onClick = _.noop, title }) => {
	return (
		<Tooltip tooltipText={title ? title : ""}>
			<Button onClick={onClick} type="tertiary" iconOnly ssrIcon={plus} small />
		</Tooltip>
	);
};

export default AddButton;
