import * as React from "react";
import Layout from "./Layout";

interface MenuModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	showAdminLayout: boolean;
}

const MenuModal: React.FC<MenuModalProps> = ({ showModal, setShowModal, showAdminLayout }) => {
	return <Layout showModal={showModal} setShowModal={setShowModal} showAdminLayout={showAdminLayout} />;
};

export default MenuModal;
