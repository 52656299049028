import * as React from "react";
import assert from "assert";

import useGetUiSchemas from "../../hooks/useGetUiSchemas";
import Layout from "./Layout";
import { Values, CurrentValues } from "./types";
import useAuthentication from "../../hooks/useAuthentication";
import { updateSchemaData } from "./utils";
import useStore, { activeApplicationSelector } from "../../store";
import { Schema as TypeforSchema } from "../SchemaBuilder/utils/types";
import { isInvalidSchema } from "./utils";
import { findRemovedAndAddedSchemaProperties } from "../../utils/common";
import { getMarketData } from "../ManageMarket/utils";
import { sortObjectByKey } from "./utils";

export interface AddedAndRemovedProperties {
	added: string[];
	removed: string[];
}

const Schema: React.FC = () => {
	const [importedSchema, setImportedSchema] = React.useState<boolean>(false);

	const activeApplication = useStore(activeApplicationSelector);
	const { data: Schemas, loading: uiSchemasLoading } = useGetUiSchemas({
		appId: activeApplication?.id || "",
		getUiSchemasData: true,
		getSchemaData: true,
		importedSchema,
	});

	const [marketData, setMarketData] = React.useState<string[]>([]);
	const [values, setValues] = React.useState<Values | undefined>(undefined);
	const [currentSchema, setCurrentSchema] = React.useState<TypeforSchema>({});
	const [currentValues, setCurrentValues] = React.useState<CurrentValues | undefined>(undefined);
	const [saveBtnLoading, setSaveBtnLoading] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState("");

	const isNewApplication = React.useMemo(() => currentValues?.schema === "", [currentValues?.schema]);

	const { getToken } = useAuthentication();
	const [showReasonModal, setShowReasonModal] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>("");
	const [showImportSchemaModal, setShowImportSchemaModal] = React.useState<boolean>(false);
	const [invalidKeys, setInvalidKeys] = React.useState<string[]>([]);
	const [showAllProperties, setShowAllProperties] = React.useState<boolean>(false);

	React.useEffect(() => {
		setShowAllProperties(false);

		async function fetchMarkets() {
			if (activeApplication) {
				const marketData = await getMarketData({ application: activeApplication?.id, getToken });
				if (marketData?.data.markets.permittedMarkets) {
					setMarketData(marketData?.data.markets.permittedMarkets);
				} else {
					setMarketData([]);
				}
			}
		}
		fetchMarkets();
	}, [activeApplication, activeApplication?.id, getToken]);

	React.useEffect(() => {
		if (Schemas?.schema && !uiSchemasLoading) {
			setValues({
				schema: JSON.parse(JSON.stringify(Schemas?.schema, null, 2) || ""),
			});
		}
		setCurrentValues({
			schema: JSON.stringify(Schemas?.schema, null, 2) || "",
		});
		setCurrentSchema((Schemas?.schema as TypeforSchema) || {});
	}, [Schemas, uiSchemasLoading]);

	const hasChanged = React.useMemo(() => {
		if (!currentSchema) {
			return false;
		}

		if (currentSchema?.schema === "") {
			return false;
		}

		if (!values?.schema) {
			return false;
		}

		try {
			assert.deepStrictEqual(
				{
					schema: values?.schema,
				},
				{ schema: currentSchema }
			);
			return false;
		} catch (e) {
			return true;
		}
	}, [currentSchema, values?.schema]);

	const onSave = React.useCallback(async () => {
		if (currentValues) {
			try {
				setShowReasonModal(false);
				setSaveBtnLoading(true);

				const schemaData = { schema: currentSchema };
				if (schemaData.schema) {
					if (schemaData.schema && isInvalidSchema(schemaData.schema)) {
						setToastMessage("Schema has some error(s). Please fix them and try again.");
						setSaveBtnLoading(false);
						return;
					}
				}

				const newData = await updateSchemaData({
					getToken,
					activeApplication,
					setToastMessage,
					schemaData,
					reason,
					setSaveBtnLoading,
				});

				if (newData && newData?.schema) {
					if (newData?.schema.properties) {
						newData.schema.properties = sortObjectByKey(newData.schema.properties);
					}

					setValues({
						schema: newData.schema,
					});
					setCurrentValues({
						schema: JSON.stringify(newData?.schema, null, 2) || "",
					});
					setCurrentSchema((newData?.schema as TypeforSchema) || {});
					setSaveBtnLoading(false);
					setToastMessage("Succesfully updated the values");
				}
			} catch (err) {
				setSaveBtnLoading(false);
				console.error(err);
			}
		}
	}, [activeApplication, currentSchema, currentValues, getToken, reason]);

	const addedAndRemovedProperties = React.useMemo(() => {
		return values?.schema && currentSchema && findRemovedAndAddedSchemaProperties(values?.schema, currentSchema);
	}, [currentSchema, values?.schema]);

	return (
		<>
			<Layout
				loading={uiSchemasLoading}
				hasChanged={hasChanged}
				onSave={onSave}
				isNewApplication={isNewApplication}
				currentValues={currentValues}
				saveBtnLoading={saveBtnLoading}
				toastMessage={toastMessage}
				setToastMessage={setToastMessage}
				showReasonModal={showReasonModal}
				setShowReasonModal={setShowReasonModal}
				setReason={setReason}
				setCurrentSchema={setCurrentSchema}
				currentSchema={currentSchema}
				addedAndRemovedProperties={addedAndRemovedProperties}
				showImportSchemaModal={showImportSchemaModal}
				setShowImportSchemaModal={setShowImportSchemaModal}
				marketData={marketData}
				setImportedSchema={setImportedSchema}
				invalidKeys={invalidKeys}
				setInvalidKeys={setInvalidKeys}
				showAllProperties={showAllProperties}
				setShowAllProperties={setShowAllProperties}
				values={values}
			/>
		</>
	);
};

export default Schema;
