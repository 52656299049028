import * as React from "react";
import styled from "styled-components";

import Select, { Option as SelectOption } from "@ingka/select";
import Switch from "@ingka/switch";

import { SwitchWraper } from "./Layout";
import { ApplicationData } from "../../../store";
import InlineMessage from "@ingka/inline-message";

const StyledWrapper = styled.div`
	margin-bottom: 2rem;
`;

interface DeleteApplicationLayoutProps {
	applications: ApplicationData[] | null;
	setSelectedAppToDelete: (data?: ApplicationData) => void;
	selectedAppToDelete?: ApplicationData | undefined;
	isDeleteApplicationBackup: boolean | undefined;
	setIsDeleteApplicationBackup: (value: boolean) => void;
	isDeleteButtonDisabled: boolean;
	deleteInProgress: boolean;
}

const DeleteApplicationLayout: React.FC<DeleteApplicationLayoutProps> = ({
	applications,
	setSelectedAppToDelete,
	selectedAppToDelete,
	isDeleteApplicationBackup,
	setIsDeleteApplicationBackup,
	isDeleteButtonDisabled,
	deleteInProgress,
}) => {
	const [isBackupDisabled, setIsBackupDisabled] = React.useState<boolean>(false);

	const onChangeBackup = () => {
		setIsDeleteApplicationBackup(!isDeleteApplicationBackup);
	};

	const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setIsBackupDisabled(!isBackupDisabled);
		const selectedAppId = e.target.value;
		const selectedApp = applications?.find((application) => application.id === selectedAppId);
		setSelectedAppToDelete(selectedApp);
	};

	return (
		<>
			<StyledWrapper>
				<InlineMessage
					variant="cautionary"
					body={
						<>
							<strong>Note: </strong>Please ensure that you select the "Delete backup data" option if you wish to delete
							the backup from Firestore storage.
						</>
					}
				/>
			</StyledWrapper>
			<Select
				id="applications"
				label="Applications"
				value={selectedAppToDelete?.id || ""}
				onChange={(e) => onChangeSelect(e)}
				disabled={deleteInProgress}
			>
				{applications?.map((application) => (
					<SelectOption key={application.id} value={application.id} name={application.name} />
				))}
			</Select>
			<SwitchWraper>
				<Switch
					value={"backup"}
					checked={isDeleteApplicationBackup}
					id="include-backup"
					label="Delete backup data"
					disabled={isDeleteButtonDisabled || deleteInProgress}
					onChange={onChangeBackup}
				/>
			</SwitchWraper>
		</>
	);
};

export default DeleteApplicationLayout;
