import * as React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

import plus from "@ingka/ssr-icon/paths/plus";
import {
	ArrayLayoutProps,
	composePaths,
	createDefaultValue,
	isObjectArrayWithNesting,
	RankedTester,
	rankWith,
	ControlElement,
} from "@jsonforms/core";
import { withJsonFormsArrayLayoutProps } from "@jsonforms/react";
import Button from "@ingka/button";

import CardRenderer from "./CardRenderer";
import { CenterButtonWrapper } from "../../styles";
import { findAndTransformHTMLString } from "../../renderers/utils";

const StyledDiv = styled.div`
	margin: 1.5rem 0;
	flex-grow: 1;
`;

const StyledHeading = styled.h3`
	margin: 0 0 0.25rem 0;
`;

const StyledParagraph = styled.p`
	margin-top: 0rem;
`;

const ErrorParagraph = styled.p`
	color: red;
`;

const ParagraphWrapper = styled.div`
	text-align: center;
`;

interface CustomArrayLayoutProps extends ArrayLayoutProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

export const CollapsibleArrayLayout = ({
	uischema,
	schema,
	renderers,
	cells,
	data,
	path,
	addItem,
	removeItems,
	enabled,
	moveDown,
	moveUp,
}: CustomArrayLayoutProps) => {
	const elements = uischema.options?.["detail"] ? uischema.options?.["detail"]["elements"] : [];

	const isEmpty = data === 0;

	const addItemCb = React.useCallback(
		(p: string, value: any) => {
			return addItem(p, value);
		},
		[addItem]
	);

	const removeItemsCb = React.useCallback(
		(path: string, toDelete: number[]) => {
			return removeItems ? removeItems(path, toDelete) : () => {};
		},
		[removeItems]
	);

	const onMoveUp = React.useCallback(
		(path: string, toMove: number) => {
			return moveUp && moveUp(path, toMove);
		},
		[moveUp]
	);

	const onMoveDown = React.useCallback(
		(path: string, toMove: number) => {
			return moveDown && moveDown(path, toMove);
		},
		[moveDown]
	);

	if (!elements) {
		return <ErrorParagraph>No renderer found</ErrorParagraph>;
	}

	const toRender = Array(data)
		.fill(0)
		.map((_, i) => {
			return (
				<CardRenderer
					key={i}
					index={i}
					schema={schema}
					uischema={uischema}
					path={composePaths(path, `${i}`)}
					renderers={renderers}
					cells={cells}
					onRemove={removeItemsCb(path, [i])}
					elements={elements}
					onMoveDown={onMoveDown(path, i)}
					onMoveUp={onMoveUp(path, i)}
					data={data}
					removable={uischema.options?.removable}
					enabled={enabled}
				/>
			);
		});

	return (
		<StyledDiv>
			{uischema.label && <StyledHeading>{uischema.label}</StyledHeading>}
			{uischema.subText && <StyledParagraph>{parse(findAndTransformHTMLString(uischema.subText))}</StyledParagraph>}
			{isEmpty && (
				<ParagraphWrapper>
					<p>No Data</p>
				</ParagraphWrapper>
			)}
			{toRender}
			<CenterButtonWrapper>
				<Button
					id={`add-${uischema.options?.label || "item"}`}
					type="secondary"
					onClick={addItemCb(path, createDefaultValue(schema))}
					text="Add Item"
					ssrIcon={plus}
					iconPosition="trailing"
					small
					disabled={!enabled || uischema.options?.addable === false}
				/>
			</CenterButtonWrapper>
		</StyledDiv>
	);
};

export const collapsibleArrayLayoutTester: RankedTester = rankWith(5, isObjectArrayWithNesting);

export default withJsonFormsArrayLayoutProps(CollapsibleArrayLayout);
