import * as React from "react";
import useStore, { User, UserApplication, setUserSelector, userSelector } from "../../store";
import ProfileLayout from "./ProfileLayout";
import useAuthentication from "../../hooks/useAuthentication";
import { removeMe } from "../../services/users";
import { ApiResponseMessages } from "../Schemas/constants";
import useCancelApplicationRequest from "../../hooks/useCancelApplicationRequest";
import { ResponseMessage } from "../../utils/constants";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";

interface ModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
}

const ProfileModal: React.FC<ModalProps> = ({ showModal, setShowModal }) => {
	const setCurrentUser = useStore(setUserSelector);
	const currentUser = useStore(userSelector);

	const { getToken, handleLogout } = useAuthentication();
	const { cancelApplicationRequest, loading: cancellingApplicationRequest } = useCancelApplicationRequest();
	const { getUser, data: userData, loading: userLoading } = useGetLoggedInUser();
	const [cancellingApplication, setCancellingApplication] = React.useState<
		{ appId: string; type: "new" | "existing" } | undefined
	>(undefined);

	const [showModalPrompt, setShowModalPrompt] = React.useState<boolean>(false);
	const [showCancelAppRequestModal, setShowCancelAppRequestModal] = React.useState<boolean>(false);
	const [toast, setToast] = React.useState<string>("");
	const [userApplicationSelected, setUserApplicationSelected] = React.useState<
		(UserApplication & { id: string }) | undefined
	>(undefined);
	const [showUserAppOverviewModal, setShowUserAppOverviewModal] = React.useState<boolean>(false);

	const handleRemoveMe = async () => {
		try {
			const token = await getToken();
			const { data } = await removeMe(token);
			if (data && data.message === ApiResponseMessages.Success) {
				handleLogout();
			}
		} catch (err: any) {
			if (err) {
				console.log(err);
			}
		}
	};

	const onUserApplicationClick = React.useCallback(
		(app?: string) => {
			if (app && currentUser?.user?.applications?.[app]) {
				setUserApplicationSelected(() => ({ ...currentUser?.user?.applications?.[app], id: app }));
				setShowUserAppOverviewModal(true);
			}
		},
		[currentUser?.user?.applications]
	);

	const onCancelApplicationRequest = React.useCallback(
		async (appId: string | undefined, type: string | undefined) => {
			if (appId && type) {
				setShowCancelAppRequestModal(false);
				const cancelAppMessage = await cancelApplicationRequest(appId, type);
				if (cancelAppMessage?.toLocaleLowerCase() === ResponseMessage.Success) {
					setToast("Application request cancelled successfully");
					await getUser();
				} else {
					cancelAppMessage && setToast(cancelAppMessage);
				}
			}
		},
		[cancelApplicationRequest, getUser]
	);

	React.useEffect(() => {
		if (userData) {
			setCurrentUser(userData);
		}
	}, [userData, setCurrentUser]);

	return (
		<ProfileLayout
			showModal={showModal}
			setShowModal={setShowModal}
			currentUser={currentUser}
			handleRemoveMe={handleRemoveMe}
			showModalPrompt={showModalPrompt}
			setShowModalPrompt={setShowModalPrompt}
			toast={toast}
			setToast={setToast}
			onUserApplicationClick={onUserApplicationClick}
			showUserAppOverviewModal={showUserAppOverviewModal}
			setShowUserAppOverviewModal={setShowUserAppOverviewModal}
			userApplicationSelected={userApplicationSelected}
			onCancelApplicationRequest={onCancelApplicationRequest}
			cancellingApplicationRequest={cancellingApplicationRequest}
			userLoading={userLoading}
			setCurrentUser={setCurrentUser}
			cancellingApplication={cancellingApplication}
			showCancelAppRequestModal={showCancelAppRequestModal}
			setShowCancelAppRequestModal={setShowCancelAppRequestModal}
			setCancellingApplication={setCancellingApplication}
		/>
	);
};

export default ProfileModal;
