/* eslint-disable indent */
import * as React from "react";
import useAuthentication from "./useAuthentication";
import { getScheduledChanges } from "../services/schemas";

const useGetScheduledChanges = (activeApplication: any) => {
	const [data, setData] = React.useState<any | undefined>(undefined);
	const { getToken } = useAuthentication();

	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			if (activeApplication) {
				try {
					const { data } = await getScheduledChanges(authHeader, activeApplication.id);
					setData(data);
				} catch (error: any) {
					setData(undefined);
				}
			} else {
				setData(undefined);
			}
		})();

		return () => {
			setData(undefined);
		};

	}, [activeApplication, getToken]);

	return { data };
};

export default useGetScheduledChanges;
