import _ from "lodash/fp";
import { typeToOptions, typeToValidFields } from "./constants";
import { Schema, SchemaType } from "./types";
import { isArray } from "lodash";

export const getAllSchemaKeys = _.keys;

export const getSchemaField = _.get;

export const getSchemaFields = _.pick;

export const getSchemaType = (schema: Schema) => {
	let type;
	if (schema?.oneOf && !schema.type) {
		type = "oneOf";
	} else {
		type = getSelectTypes(schema);
	}
	return type;
};

export const getSelectTypes = getSchemaField("type");

export const getSchemaProperty = (key: string) => getSchemaField(["properties", key]);

export const getSchemaProperties = getSchemaField("properties");

export const getSchemaItems = getSchemaField("items");

export const getSchemaoneOf = getSchemaField("oneOf");

export const getSchemaRequired = getSchemaField("required");

export const getSchemaRequiredProperties = (schema: Schema) => {
	const required = getSchemaRequired(schema);
	const properties = getSchemaProperties(schema);
	return getSchemaFields(required, properties);
};

export const setSchemaField = _.set;

export const setSchemaType = setSchemaField("type");

export const setSchemaProperties = setSchemaField("properties");

export const setSchemaProperty = (key: string) => {
	return setSchemaField(["properties", key]);
};

export const setSchemaSelect = (key: string, type: string) => setSchemaField([type]);

export const setSchemaItems = setSchemaField("items");

export const deleteSchemaField = _.unset;

export const deleteSchemaProperty = (key: string) => {
	return deleteSchemaField(["properties", key]);
};

export const addSchemaProperty = (schema: Schema) => {
	const schemaCpy = _.cloneDeep(schema);
	const props: any = schemaCpy.properties;
	const newProps = { [`T${_.now()}`]: {}, ...props };
	return { ...schemaCpy, properties: newProps };

	// Do not remove maybe needed later
	// return setSchemaProperty(`__${_.now()}__`)({}, schema);
};

export const addSelectOptions = (schema: Schema) => {
	if (isArray(schema.oneOf)) {
		const schemaCpy: Schema = _.cloneDeep(schema);
		(schemaCpy.oneOf as any).push({ const: "", title: "" });
		return schemaCpy;
	}
	return schema;
};

export const addDashboardOptions = (schema: Schema) => {
	const dashboardProperties = {
		dashboard: {
			label: "label",
			market: false,
			global: false,
			isVisible: false,
			groupId: "groupId",
			columnIndex: 999,
			groupIdIndex: 999,
		},
	};
	return _.assign(schema, dashboardProperties);
};
export const removeDashboardOptions = (schema: Schema) => {
	const { dashboard, ...rest } = schema;
	return rest;
};

export const setDashboardProperty = (schema: Schema, dashboardProperty: string, value: any) => {
	if (typeof schema.dashboard == "object" && schema.dashboard) {
		//@ts-ignore
		schema.dashboard[dashboardProperty] = value;
	}
	return schema;
};

export const getKeysInSchema = (schema: Schema) =>
	_.flow(
		getSchemaProperties,
		_.entries,
		_.map(([k, v]: any) => k)
	)(schema);

export const renameSchemaField = (oldKey: string, newKey: string) => {
	//Rename field key but maintains object "order"
	return _.flow([_.entries, _.map(([k, v]: any) => ({ [k === oldKey ? newKey : k]: v })), _.reduce(_.assign, {})]);
};

export const renameSchemaProperty = (oldKey: string, newKey: string, schema: Schema) =>
	_.flow([getSchemaProperties, renameSchemaField(oldKey, newKey), (p) => setSchemaProperties(p, schema)])(schema);

export const isSchemaObject = (schema: Schema) => {
	return getSchemaType(schema) === "object";
};

export const isSchemaArray = (schema: Schema) => getSchemaType(schema) === "array";

export const isSelectSchema = (schema: Schema) => {
	return (schema && typeof getSchemaType(schema) === "undefined" && schema.oneOf) || getSchemaType(schema) == "oneOf";
};

export const isFieldRequired = (key: string) => _.flow([getSchemaRequired, _.contains(key)]);

export const hasSchemaProperties = (schema: Schema) => !_.isEmpty(getSchemaProperties(schema));

export const hasSchemaItems = (schema: Schema) => !_.isEmpty(getSchemaItems(schema));

export const getSchemaMenuOptions = (type: SchemaType) => _.get(type, typeToOptions);

export const findOption = (value: string) => _.find(["value", value]);

export const optionsToStrings = _.map("value");

export const stringsToOptions = _.map((s) => ({ label: s, value: s }));

export const schemaFieldAsOption = (key: string) => {
	return { value: key, label: key };
};

export const fieldsToOptions = _.flow([_.entries, _.map(([key]) => schemaFieldAsOption(key))]);

export const schemaPropertiesAsOptions = _.flow([getSchemaProperties, fieldsToOptions]);

export const schemaRequiredPropertiesAsOptions = _.flow(getSchemaRequiredProperties, fieldsToOptions);

export const getValidFields = (type: SchemaType) => _.get(type, typeToValidFields);

export const removeWrongFields = (schema: Schema) => {
	const type = getSchemaType(schema);
	if (type === "oneOf") {
		if (!schema?.oneOf) {
			schema.oneOf = [] as { [key: string]: string }[];
		}
	}
	const fields = getValidFields(type);
	return getSchemaFields(fields, schema);
};

export const setSchemaTypeAndRemoveWrongFields = _.flow([setSchemaType, removeWrongFields]);
