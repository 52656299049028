import React from "react";

import { RoleData } from "../RequestNewRoleModal";
import { UserPermissions } from "../../../utils/constants";
import Layout from "./Layout";
import useGetMarkets from "../../../hooks/useGetMarkets";
import useGetUiSchemas from "../../../hooks/useGetUiSchemas";
import { UserApplication } from "../../../store";
import { UserMarketPermissions } from "../../../services/users";

interface AdminRoleSelctorProps {
	rolesData: RoleData[];
	setSelectedRole: (role: UserPermissions | null) => void;
	selectedRole: UserPermissions | null;
	requestingRole: boolean;
	maintainerMarkets: UserMarketPermissions;
	setMaintainerMarkets: (data: UserMarketPermissions) => void;
	setIsEditingMarkets: (data: boolean) => void;
	app?: UserApplication & {
		id: string;
	};
}

const AdminRoleSelector: React.FC<AdminRoleSelctorProps> = ({
	rolesData,
	setSelectedRole,
	requestingRole,
	selectedRole,
	app,
	maintainerMarkets,
	setMaintainerMarkets,
	setIsEditingMarkets,
}) => {
	const [showAddMaketModal, setShowAddMarketModal] = React.useState<boolean>(false);

	const { data: marketsData, loading: marketsLoading, getMarketsData } = useGetMarkets();
	const { data: schemasData, loading: schemasDataLoading } = useGetUiSchemas({ appId: app?.id || "" });

	React.useEffect(() => {
		if (app?.id) {
			getMarketsData(app.id, false);
		}
	}, [app?.id, getMarketsData]);

	return (
		<Layout
			rolesData={rolesData}
			setSelectedRole={setSelectedRole}
			requestingRole={requestingRole}
			selectedRole={selectedRole}
			showAddMaketModal={showAddMaketModal}
			setShowAddMarketModal={setShowAddMarketModal}
			marketsData={marketsData}
			marketsLoading={marketsLoading}
			schemasData={schemasData}
			schemasDataLoading={schemasDataLoading}
			app={app}
			maintainerMarkets={maintainerMarkets}
			setMaintainerMarkets={setMaintainerMarkets}
			setIsEditingMarkets={setIsEditingMarkets}
		/>
	);
};

export default AdminRoleSelector;
