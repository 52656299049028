import * as React from "react";

import { ApplicationUiSchema } from "../../../services/applications/types";
import { UserData, ApplicationOnboardedUsers, UserApplicationData, UserSearchQuery } from "../../../services/users";
import useStore, { activeApplicationSelector } from "../../../store";
import { UserMarketPermissions } from "../../../services/users";
import { UserPermissions } from "../../../utils/constants";
import Layout from "./Layout";
import { emailFromUserInfoRegExp } from "../../../utils/regularExpressions";
import useAuthentication from "../../../hooks/useAuthentication";
import { getUsersList } from "../../../services/users";
import useGetUserSuggestions from "../../../hooks/useGetUserSuggestions";

interface ModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	modalHeading: string;
	modalButtonLabel: string;
	setSelectedUser: (data?: UserApplicationData) => void;
	addingNewUser: boolean;
	setAddingNewUser: (value: boolean) => void;
	onFinalSubmit: () => void;
	isAdmin: boolean;
	currentUserSelected: boolean;
	setModalButtonLabel: (value: string) => void;
	setModalHeading: (value: string) => void;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	handleClearURLProps: () => void;
	selectedUserEmail: string | undefined;
	onRemoveUserAccess?: () => void;
	setSelectedMarkets: (value?: string[]) => void;
	hasChangedUser: boolean;
	selectedUser?: UserApplicationData;
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	usersInApplication?: ApplicationOnboardedUsers[] | null;
	selectedMarkets?: string[];
	onboardingUser?: boolean;
	actionInProgress?: boolean;
}

const ManageUsersModal: React.FC<ModalProps> = ({
	showModal,
	setShowModal,
	modalHeading,
	modalButtonLabel,
	marketsData,
	uiSchemas,
	setSelectedUser,
	selectedUser,
	addingNewUser,
	setAddingNewUser,
	onFinalSubmit,
	isAdmin,
	currentUserSelected,
	setModalButtonLabel,
	setModalHeading,
	toastMessage,
	setToastMessage,
	usersInApplication,
	handleClearURLProps,
	selectedUserEmail,
	onRemoveUserAccess,
	selectedMarkets,
	setSelectedMarkets,
	hasChangedUser,
	onboardingUser,
	actionInProgress,
}) => {
	const { getToken, handleLogout } = useAuthentication();
	//const [userSuggestions, setUserSuggestions] = React.useState<UserData[]>([]);
	const [showAddMaketModal, setShowAddMarketModal] = React.useState<boolean>(false);
	//const [noOptionsText, setNoOptionsText] = React.useState<string>("Please type at least 3 characters.");
	const activeApplication = useStore(activeApplicationSelector);
	const [showModalRemoveUserAccessPrompt, setShowModalRemoveUserAccessPrompt] = React.useState<boolean>(false);

	const {
		getSuggestions,
		data: userSuggestions,
		setData: setUserSuggestions,
		error,
		loading,
		noOptionsText,
		setNoOptionsText,
	} = useGetUserSuggestions();

	const onChangeMarket = React.useCallback(
		(prevValue: string, newValue: string) => {
			if (activeApplication && newValue !== "Choose an option") {
				const markets = selectedUser?.applications?.[activeApplication.id].markets;
				const updatedMarkets = Object.entries(markets || {}).map(([market, uiSchema]) => {
					if (market === prevValue) {
						return [newValue, uiSchema];
					}
					return [market, uiSchema];
				});

				const marketsObj = updatedMarkets.reduce(
					(obj, [market, uiSchema]) => ({
						...obj,
						[market as string]: uiSchema as string,
					}),
					{}
				);

				selectedUser &&
					activeApplication &&
					setSelectedUser({
						...selectedUser,
						applications: {
							...selectedUser.applications,
							[activeApplication.id]: {
								...selectedUser.applications?.[activeApplication.id],
								markets: marketsObj,
							},
						},
					});
			}
		},
		[activeApplication, selectedUser, setSelectedUser]
	);

	const onChangeSchema = React.useCallback(
		(selectMarket: string, newUiSchema: string) => {
			if (activeApplication && newUiSchema !== "Choose an option") {
				const markets = selectedUser?.applications?.[activeApplication.id].markets;

				const updatedMarkets = Object.entries(markets || {}).map(([market, uiSchema]) => {
					if (market === selectMarket) {
						return [market, newUiSchema];
					}
					return [market, uiSchema];
				});

				const marketsObj: UserMarketPermissions = updatedMarkets.reduce(
					(obj, [market, uiSchema]) => ({
						...obj,
						[market as string]: uiSchema as string,
					}),
					{}
				);

				selectedUser &&
					activeApplication &&
					setSelectedUser({
						...selectedUser,
						applications: {
							...selectedUser.applications,
							[activeApplication.id]: {
								permissions: selectedUser.applications?.[activeApplication.id].permissions,
								markets: marketsObj,
							},
						},
					});
			}
		},
		[activeApplication, selectedUser, setSelectedUser]
	);

	const onChangePermission = React.useCallback(
		(permission: string) => {
			selectedUser &&
				activeApplication &&
				setSelectedUser({
					...selectedUser,
					applications: {
						...selectedUser.applications,
						[activeApplication.id]: {
							...selectedUser.applications?.[activeApplication.id],
							permissions: permission === UserPermissions.Admin ? UserPermissions.Maintainer : UserPermissions.Admin,
						},
					},
				});
		},
		[selectedUser, activeApplication, setSelectedUser]
	);

	const onChangeCanEnableBoolean = React.useCallback(
		(canOnlyEnableProperties: boolean) => {
			selectedUser &&
				activeApplication &&
				setSelectedUser({
					...selectedUser,
					applications: {
						...selectedUser.applications,
						[activeApplication.id]: {
							...selectedUser.applications?.[activeApplication.id],
							canOnlyEnableProperties: !canOnlyEnableProperties,
						},
					},
				});
		},
		[selectedUser, activeApplication, setSelectedUser]
	);

	const onRemoveMarket = React.useCallback(
		(marketName: string) => {
			if (activeApplication && selectedUser) {
				const markets = selectedUser?.applications?.[activeApplication.id].markets;

				const newMarkets = Object.entries(markets || {}).filter(
					([market, uiSchema]) => market !== marketName && [market, uiSchema]
				);

				const marketsObj = newMarkets.reduce(
					(obj, [market, uiSchema]) => ({
						...obj,
						[market as string]: uiSchema as string,
					}),
					{}
				);

				setSelectedUser({
					...selectedUser,
					applications: {
						...selectedUser.applications,
						[activeApplication.id]: {
							...selectedUser.applications?.[activeApplication.id],
							markets: marketsObj,
						},
					},
				});
			}
		},
		[activeApplication, selectedUser, setSelectedUser]
	);

	const onChangeUser = React.useCallback(
		(userInfo) => {
			const userEmail = userInfo.split(emailFromUserInfoRegExp).find((item: string) => item.includes("@"));
			if (userSuggestions && activeApplication) {
				const selectedUser = userSuggestions.find((user) => user.email === userEmail);
				if (selectedUser) {
					const userData = {
						...selectedUser,
						applications: {
							...selectedUser?.applications,
							[activeApplication.id]: {
								...selectedUser?.applications?.[activeApplication.id],
								permissions: isAdmin ? UserPermissions.Admin : UserPermissions.Maintainer,
							},
						},
					};
					setSelectedUser(userData);
				}
			}
		},
		[userSuggestions, activeApplication, isAdmin, setSelectedUser]
	);

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			modalHeading={modalHeading}
			modalButtonLabel={modalButtonLabel}
			userSuggestions={userSuggestions}
			showAddMaketModal={showAddMaketModal}
			setShowAddMarketModal={setShowAddMarketModal}
			marketsData={marketsData}
			uiSchemas={uiSchemas}
			setSelectedUser={setSelectedUser}
			selectedUser={selectedUser}
			activeApplication={activeApplication}
			onChangeMarket={onChangeMarket}
			onChangeSchema={onChangeSchema}
			addingNewUser={addingNewUser}
			setAddingNewUser={setAddingNewUser}
			onChangePermission={onChangePermission}
			onChangeCanEnableBoolean={onChangeCanEnableBoolean}
			onFinalSubmit={onFinalSubmit}
			onRemoveMarket={onRemoveMarket}
			isAdmin={isAdmin}
			onChangeUser={onChangeUser}
			currentUserSelected={currentUserSelected}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			handleClearURLProps={handleClearURLProps}
			onRemoveUserAccess={onRemoveUserAccess}
			showModalRemoveUserAccessPrompt={showModalRemoveUserAccessPrompt}
			setShowModalRemoveUserAccessPrompt={setShowModalRemoveUserAccessPrompt}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			hasChangedUser={hasChangedUser}
			onboardingUser={onboardingUser}
			getSuggestions={getSuggestions}
			noOptionsText={noOptionsText}
			actionInProgress={actionInProgress}
			setNoOptionsText={setNoOptionsText}
		/>
	);
};

export default ManageUsersModal;
