import * as React from "react";
import { useNavigate } from "react-router";

import { useMsal } from "@azure/msal-react";

import useStore, { resetStateSelector } from "../../store";
import LoadingLayout from "../LoadingLayout";
import { ROUTE_HOME } from "../../utils/constants";

const Logout: React.FC = () => {
	const { instance } = useMsal();
	const navigate = useNavigate();
	const resetState = useStore(resetStateSelector);

	React.useEffect(() => {
		instance
			.logoutRedirect({
				onRedirectNavigate: () => false,
			})
			.catch((error) => console.log("logoutError", error));
		resetState();
		sessionStorage.clear();
		navigate(ROUTE_HOME, { replace: true });
	}, [instance, navigate, resetState]);

	return <LoadingLayout />;
};

export default Logout;
