import * as React from "react";
import styled from "styled-components";

import plus from "@ingka/ssr-icon/paths/plus";
import {
	ArrayLayoutProps,
	composePaths,
	createDefaultValue,
	isPrimitiveArrayControl,
	RankedTester,
	rankWith,
} from "@jsonforms/core";
import { withJsonFormsArrayLayoutProps } from "@jsonforms/react";
import Button from "@ingka/button";

import PrimitiveArrayRanderer from "./PrimitiveArrayRanderer";
import parse from "html-react-parser";
import { findAndTransformHTMLString } from "../../renderers/utils";
import { ControlElement } from "@jsonforms/core";

interface CustomArrayLayoutProps extends ArrayLayoutProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const StyledDiv = styled.div`
	padding: 1.5rem 1.5rem 1.5rem 0;
`;

const StyledHeading = styled.h3`
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
`;

const StyledParagraph = styled.p`
	margin-top: 0rem;
`;

const ParagraphWrapper = styled.div`
	text-align: center;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const PrimitiveArrayLayoutRenderer = ({
	uischema,
	schema,
	renderers,
	cells,
	data,
	path,
	addItem,
	removeItems,
	moveDown,
	moveUp,
	enabled,
}: CustomArrayLayoutProps) => {
	const isEmpty = data === 0;

	const addItemCb = React.useCallback(
		(p: string, value: any) => {
			return addItem(p, value);
		},
		[addItem]
	);

	const removeItemsCb = React.useCallback(
		(path: string, toDelete: number[]) => {
			return removeItems ? removeItems(path, toDelete) : () => {};
		},
		[removeItems]
	);

	const onMoveUp = React.useCallback(
		(path: string, toMove: number) => {
			return moveUp && moveUp(path, toMove);
		},
		[moveUp]
	);

	const onMoveDown = React.useCallback(
		(path: string, toMove: number) => {
			return moveDown && moveDown(path, toMove);
		},
		[moveDown]
	);

	const toRender = Array(data)
		.fill(0)
		.map((_, i) => {
			return (
				<PrimitiveArrayRanderer
					key={i}
					index={i}
					schema={schema}
					uischema={uischema}
					path={composePaths(path, `${i}`)}
					renderers={renderers}
					cells={cells}
					onRemove={removeItemsCb(path, [i])}
					enabled={enabled}
					data={data}
					onMoveDown={onMoveDown(path, i)}
					onMoveUp={onMoveUp(path, i)}
					removable={uischema.options?.removable}
				/>
			);
		});

	return (
		<StyledDiv>
			{uischema.label && <StyledHeading>{uischema.label}</StyledHeading>}
			{uischema.subText && <StyledParagraph>{parse(findAndTransformHTMLString(uischema.subText))}</StyledParagraph>}
			{isEmpty && (
				<ParagraphWrapper>
					<p>No Data</p>
				</ParagraphWrapper>
			)}
			{toRender}
			<ButtonWrapper>
				<Button
					type="secondary"
					onClick={addItemCb(path, createDefaultValue(schema))}
					text="Add Item"
					ssrIcon={plus}
					iconPosition="trailing"
					small
					disabled={!enabled || uischema.options?.addable === false}
				/>
			</ButtonWrapper>
		</StyledDiv>
	);
};

export const primitiveArrayLayoutTester: RankedTester = rankWith(4, isPrimitiveArrayControl);

export default withJsonFormsArrayLayoutProps(PrimitiveArrayLayoutRenderer);
