import * as React from "react";
import { IconProps } from "./types";

export const CrossCircle: React.FC<IconProps> = ({ height, width, colour }) => {
	return (
		<svg
			width={`${width}` || "24"}
			height={`${height}` || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m14.6667 15.9998-2.6666-2.6665L9.3333 16 8 14.6667 10.6668 12 8 9.3333 9.3333 8l2.6668 2.6667 2.6666-2.6665L16 9.3335 13.3334 12 16 14.6665l-1.3333 1.3333z"
				fill={colour || "#111"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 12c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10C2 6.4771 6.4771 2 12 2c5.5228 0 10 4.4771 10 10zm-2 0c0 4.4183-3.5817 8-8 8s-8-3.5817-8-8 3.5817-8 8-8 8 3.5817 8 8z"
				fill={colour || "#111"}
			/>
		</svg>
	);
};
