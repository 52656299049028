import * as React from "react";

import { LinkListItem, LinkListItemProps } from "@ingka/link-list";

export interface LinkListItemProxyProps extends LinkListItemProps {
	active?: boolean;
	className?: string;
}

// We don't actually use active here, we use it in our styled-component
// But we get errors if we pass "active" to LinkListItem since they spread it
// on an a-tag
const LinkListItemProxy: React.FC<LinkListItemProxyProps> = ({ className, active, ...props }) => (
	<div className={className}>
		<LinkListItem {...props} />
	</div>
);

export default LinkListItemProxy;
