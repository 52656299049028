import React from "react";

import Layout from "./Layout";

import { InlineMessageVariant } from "@ingka/inline-message";

interface WarningModalProps {
	modalId: string;
	modalTitle?: string;
    showWarningModal: boolean;
    setShowWarningModal: (val: boolean) => void;
	children: any;
	variant: InlineMessageVariant
	dismissable: boolean;
}

const WarningModal: React.FC<WarningModalProps> = ({
	modalId,
	modalTitle,
	showWarningModal,
	setShowWarningModal,
	children,
	variant,
	dismissable
}) => {
	return (
		<Layout
			modalId={modalId}
			modalTitle={modalTitle}
			showWarningModal={showWarningModal}
			setShowWarningModal={setShowWarningModal}
			children={children}
			variant={variant}
			dismissable={dismissable}
		/>
	);
};

export default WarningModal;