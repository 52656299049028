import * as React from "react";
import styled from "styled-components";

import Text from "@ingka/text";
import Skeleton from "@ingka/skeleton";
import PillDropDown from "../PillDropDown/PillDropDown";
import { ListViewItem } from "@ingka/list-view";
import { tertials } from "../../utils/constants";

interface DashboardTileProps {
	activeMarket: string | null;
	activeTertial: string;
	setActiveTertial: (data: string) => void;
	updateCount: number | undefined;
	updateCounterTileLoading: boolean;
	updateCounterOnlyLoading: boolean;
}

const StyledTile = styled.div`
	margin-left: 1rem;
	width: fit-content;
	background-color: #e3f5ff;
	padding: 0rem 1.2rem 1.2rem 1.2rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	height: 6rem;
`;

const StyledSkeletonCounter = styled(Skeleton)`
	margin-top: 0.5rem;
	width: 16rem;
`;

const StyledTileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	height: 2.5rem;
`;

const StyledPillDropDown = styled(PillDropDown)`
	padding-top: 0.5rem;

	.list-box {
		width: auto;
	}

	.list-box__body {
		padding-block: 0rem;
	}
`;

const StyledSkeleton = styled(Skeleton)`
	width: 16rem;
	border-radius: 1rem;
	margin-left: 1rem;
`;

const StyledHeaderText = styled(Text)`
	padding-top: 1.2rem;
	height: 2.5rem;
`;

const Layout: React.FC<DashboardTileProps> = ({
	activeMarket,
	activeTertial,
	setActiveTertial,
	updateCount,
	updateCounterTileLoading,
	updateCounterOnlyLoading
}) => {
	return (
		<>
			{updateCounterTileLoading && <StyledSkeleton width="20rem" height="5.5rem"></StyledSkeleton>}
			{!updateCounterTileLoading && <StyledTile>
				<StyledTileHeader>
					<StyledHeaderText headingSize="xs">Number of updates</StyledHeaderText>
					<StyledPillDropDown
						id="applications-dropdown"
						value={activeTertial}
						onChange={(e) => {
							setActiveTertial(e.target.value);
						}}
					>
						{tertials?.map(
							(tertial) =>
								<ListViewItem
									inset
									size="small"
									showDivider={true}
									title={tertial}
									control="radiobutton"
									name={tertial}
									id={tertial}
									controlProps={
                    {value: tertial, checked: Boolean(activeTertial === tertial),
                    } as any}/>
						)}
					</StyledPillDropDown>
            
				</StyledTileHeader>
				{!updateCounterOnlyLoading &&
                <Text headingSize="m">{updateCount}</Text>
				}
				{updateCounterOnlyLoading &&
                <StyledSkeletonCounter width="2rem" height="2rem"></StyledSkeletonCounter>
				}
			</StyledTile>
			}
		</>
	);
};

export default Layout;