import * as React from "react";

import { ListViewItem } from "@ingka/list-view";
import Pill from "@ingka/pill";
import Button from "@ingka/button";

import { Application, Market } from "../../store";
import PillDropDown from "../PillDropDown/PillDropDown";

interface ApplicationLayoutProps {
	loading: boolean;
	setActiveApplication: (data: Application) => void;
	activeApplication: Application | null;
	applicationsData?: Application[];
	setActiveMarket: (data: Market) => void;
	setselectedMarkets: (e: any) => void;
	setMultipleMarketFlag: (multipleMarketUpdateFlag: boolean) => void;
	setActiveApp: (appId: string) => void;
}

const Layout: React.FC<ApplicationLayoutProps> = ({
	loading,
	applicationsData,
	setActiveApplication,
	activeApplication,
	setActiveMarket,
	setselectedMarkets,
	setMultipleMarketFlag,
	setActiveApp,
}) => {
	if (!applicationsData && !loading) {
		return null;
	}

	return (
		<>
			{applicationsData && applicationsData?.length > 1 ? (
				<PillDropDown
					id="applications-dropdown"
					value={activeApplication ? activeApplication.id : undefined}
					onChange={(e) => {
						applicationsData &&
							setActiveApp(
								applicationsData.find((data) => data?.id === e.target.value)?.id || (applicationsData[0]?.id as string)
							);
						applicationsData &&
							setActiveApplication(applicationsData.find((data) => data?.id === e.target.value) || null);
						setActiveMarket(null);
						setselectedMarkets([]);
						setMultipleMarketFlag(false);
					}}
				>
					{applicationsData?.map(
						(application) =>
							application && (
								<ListViewItem
									inset
									showDivider={true}
									title={application?.name}
									control="radiobutton"
									name={application?.name}
									id={application?.id}
									controlProps={
										{
											value: application?.id,
											checked: Boolean(activeApplication?.id === application.id),
										} as any
									}
								/>
							)
					)}
				</PillDropDown>
			) : (
				<Pill label={activeApplication?.name} size="small" />
			)}
		</>
	);
};

export default Layout;
