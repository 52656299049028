import * as React from "react";
import { getUiSchemaList } from "../../services/applications";
import { ApplicationUiSchema } from "../../services/applications/types";
import useStore, { activeApplicationSelector } from "../../store";
import useAuthentication from "../../hooks/useAuthentication";

const useGetUiSchemasName = () => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [data, setData] = React.useState<ApplicationUiSchema[] | undefined>(undefined);
	const activeApplication = useStore(activeApplicationSelector);
	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		const getUiSchema = async () => {
			try {
				if (activeApplication) {
					setLoading(true);
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					const { data: UiSchemaData } = await getUiSchemaList(authHeader, activeApplication.id);
					setData(UiSchemaData.data["ui-schemas"]);
				}
			} catch (error) {
				//TODO: handle error
				setData(undefined);
				setLoading(false);
				console.error(error);
				// handleLogout();
			} finally {
				setLoading(false);
			}
		};
		getUiSchema();
	}, [activeApplication, getToken, handleLogout]);

	return { data, loading };
};

export default useGetUiSchemasName;
