import React from "react";
import styled from "styled-components";

interface LabelProps {
	disabled?: boolean;
}
const StyledLabel = styled.label<LabelProps>`
	${({ disabled }) => disabled && "color: rgb(var(--colour-interactive-disabled-1, 223, 223, 223))"}
`;

const Label: React.FC<LabelProps> = ({ children, disabled }) => (
	<StyledLabel disabled={disabled}>{children}</StyledLabel>
);

export default Label;
