import styled from "styled-components";

import LinkList from "@ingka/link-list";
import {
	colourNeutral3,
	colourSemanticInformative,
	colourTextAndIcon2,
	colourTextAndIcon3,
	colourStaticGrey,
	colourStaticIkeaBrandYellow,
	colourNeutral5,
	colourInteractiveSecondaryBgPressed,
} from "@ingka/variables/colours-css";
import Banner from "@ingka/banner";
import Select from "@ingka/select";
import Text from "@ingka/text";
import CommercialMessage from "@ingka/commercial-message";
import { ModalHeader } from "@ingka/modal";
import ListView from "@ingka/list-view";
import { breakpointL } from "@ingka/variables/design-tokens";

import LinkListItemProxy, { LinkListItemProxyProps } from "./Proxy/LinkListItem/LinkListItem";
import { headerAndFooterHeight } from "../utils/variables";
import InputField from "@ingka/input-field";

export const FullHeightContainer = styled.div`
	height: calc(100vh - ${headerAndFooterHeight});
`;

export const ContentWrapper = styled.div`
	//display: flex;
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - ${headerAndFooterHeight});
`;

export const Centered = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ConfigurationWrapper = styled.div`
	grid-row: 1;
	grid-column: 1/3;
	text-overflow: ellipsis;
`;

export const ActionsWrapper = styled.div`
	position: fixed;
	bottom: 0rem;
	align-items: center;
	width: 100%;
	background-color: #fff;
	height: 5.5rem;
	display: flex;
	gap: 20rem;
	z-index: 100;
`;

export const SaveButtonWrapper = styled.div`
	bottom: 3rem;
	align-items: center;
	display: flex;
	gap: 1rem;
`;

export const LoadingWrapper = styled(ConfigurationWrapper)`
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TypingH1 = styled.h1``;

export const Sidebar = styled.div`
	grid-column: 1;
	grid-row: 1;
	margin: 0.625rem;
	padding: 0.625rem;
`;

export const Paragraph = styled.p`
	margin-top: 0;
`;

export const ElementWrapper = styled.div`
	margin-top: 1.5rem;
`;

export const HorizontalLayoutWrapper = styled.div`
	display: flex;
	gap: 0.75rem;
	align-items: start;
	flex-direction: row;
`;

export const VerticalLayoutWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: ${breakpointL}) {
		width: 100vw;
	} ;
`;

export const CheckboxControlWrapper = styled.div`
	margin: 0.25rem 0;
`;

type VisibilityProps = { visible: boolean };

export const VisibilityWrapper = styled.div<VisibilityProps>`
	visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
	flex: 1;
	.tooltip {
		display: block;
	}
	.tooltip__custom-trigger-wrapper {
		display: block;
	}
`;

export const SideBarWrapper = styled.div`
	grid-row: 1;
	grid-column: 1/4;
	margin: 0;
	min-height: 100vh;
`;

export const StyledLinkList = styled(LinkList)`
	text-align: left;
	margin-top: 3rem;
`;

export const StyledLinkListItem = styled(LinkListItemProxy)<LinkListItemProxyProps>`
	.link-list__item-title,
	.link-list__item-icon {
		color: ${({ active }) => (active ? colourTextAndIcon2 : colourTextAndIcon3)};
	}
`;

export const FooterContent = styled.div`
	width: 100%;
	margin-bottom: 5.5rem;
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
	border-top: 1px solid ${colourNeutral3};
`;

export const StyledSelect = styled(Select)`
	select {
		padding: 1.125rem 3rem 0.25rem 0.25rem;
	}
`;

export const StyledDivider = styled.hr`
	border-block-start: 1px solid ${colourNeutral3};
	border-left: none;
	border-right: none;
	border-bottom: none;
`;

export const StyledDividerVirtical = styled.hr`
	border-top: none;
	border-left: 1px solid ${colourNeutral3};
	border-right: none;
	border-bottom: none;
	height: 100%;
`;

export const StyledBanner = styled(Banner)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
`;

export const ModalButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 1.5rem;
	.btn {
		width: 100vw;
	}
`;

export const ManageUserModalButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1.5rem;
	gap: 1rem;
`;

export const CenterButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;
	margin-bottom: 1rem;
`;

interface AnchorProps {
	color?: string;
}

export const StyledAnchor = styled.a<AnchorProps>`
	display: inline-flex;
	overflow: hidden;
	color: ${({ color }) => (color ? color : colourSemanticInformative)};
	text-decoration: underline;

	&:hover,
	&:visited,
	&:active {
		text-decoration: none;
		color: inherit;
		outline: 0;
		cursor: pointer;
		color: ${({ color }) => (color ? color : colourSemanticInformative)};
	}

	&:focus {
		outline: 1px dotted ${colourNeutral3};
	}
`;

export const AutocompleteStyles = {
	"& .MuiOutlinedInput-root": { padding: "0" },
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "2px solid rgb(var(--colour-interactive-emphasised-border-selected, 0, 88, 163))",
	},

	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid rgb(var(--colour-interactive-subtle-border-default, 146, 146, 146))",
	},
	"& .MuiOutlinedInput-root:hover": {
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "2px solid rgb(var(--colour-interactive-emphasised-border-selected, 0, 88, 163))",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid rgb(var(--colour-interactive-subtle-border-default, 146, 146, 146))",
		},
	},
	"& .MuiOutlinedInput-root .MuiAutocomplete-input": {
		flexGrow: "1",
		border: "0",
		background: "none",
		padding: "0",
		paddingInlineStart: "0.5rem",
		paddingInlineEnd: "0.5rem",
		fontSize: "1rem",
		lineHeight: "1.5",
		height: "3rem",
		overflow: "hidden",
		order: 3,
		outline: "none",
		borderRadius: "4px",
		color: "rgb(var(--colour-text-and-icon-1, 17, 17, 17))",
	},
};

export const MultiSelectStyles = {
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid rgb(var(--colour-interactive-subtle-border-default, 146, 146, 146))",
	},

	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "2px solid rgb(var(--colour-interactive-emphasised-border-selected, 0, 88, 163))",
	},

	"& .MuiOutlinedInput-root .MuiSelect-select": {
		flexGrow: "1",
		border: "0",
		background: "none",
		padding: "0",
		paddingInlineStart: "0.5rem",
		paddingInlineEnd: "0.5rem",
		fontSize: "1rem",
		lineHeight: "1.5",
		height: "3rem",
		overflow: "hidden",
		order: 3,
		outline: "none",
		borderRadius: "4px",
		color: "rgb(var(--colour-text-and-icon-1, 17, 17, 17))",
	},

	"& .MuiSelect-select": {
		paddingInlineStart: "0.5rem",
	},

	"&.Mui-disabled .MuiOutlinedInput-notchedOutline ": {
		border: "1px solid rgb(var(--colour-interactive-disabled-1, 223, 223, 223))",
	},
};

export const datePickerInputFieldStyles = {
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "2px solid rgb(var(--colour-interactive-emphasised-border-selected, 0, 88, 163))",
	},

	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid rgb(var(--colour-interactive-subtle-border-default, 146, 146, 146))",
	},
	"& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
		boxShadow: "none",
		border: "1px solid rgb(var(--colour-interactive-disabled-1, 223, 223, 223))",
	},
	"& .MuiOutlinedInput-root:hover": {
		"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "2px solid rgb(var(--colour-interactive-emphasised-border-selected, 0, 88, 163))",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "1px solid rgb(var(--colour-interactive-subtle-border-default, 146, 146, 146))",
		},
	},
	"& .MuiOutlinedInput-root .Mui-disabled:hover": {
		"& .MuiOutlinedInput-notchedOutline": {
			boxShadow: "none",
			border: "1px solid rgb(var(--colour-interactive-disabled-1, 223, 223, 223))",
		},
	},
	"& .MuiOutlinedInput-root": {
		flexGrow: "1",
		border: "0",
		background: "none",
		padding: "0",
		paddingInlineEnd: "0.85rem",
		fontSize: "1rem",
		lineHeight: "1.5",
		height: "3rem",
		overflow: "hidden",
		outline: "none",
		borderRadius: "4px",
		color: "rgb(var(--colour-text-and-icon-1, 17, 17, 17))",
	},
	"& .MuiTouchRipple-root": {
		display: "none",
	},
	"& .MuiInputBase-input": {
		padding: "0.75rem 0.5rem",
	},
};

interface TableWrapperProps {
	bodyHeight?: string | null;
}

export const TableWrapper = styled.div<TableWrapperProps>`
	overflow: auto;
	margin-bottom: 0.5rem;
	border: 1px solid ${colourStaticGrey};
	.table {
		th {
			text-align: center;
		}
		width: 100%;
		text-align: center;
		border-spacing: 0;
		.table-header {
			position: sticky;
			z-index: 4;
			top: 0;
			background-color: white;
			.table-row {
				:first-child {
					.table-head {
						svg {
							display: none;
						}
					}
				}
			}
		}
		.table-row {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
		.table-head:hover {
			.title-wrapper {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.icons-wrapper {
				display: flex;
			}
		}
		.table-head,
		.table-cell {
			text-align: unset;
			margin: 0;
			padding: 0.5rem;
			border-bottom: 1px solid ${colourStaticGrey};
			border-right: 1px solid ${colourStaticGrey};
			background-color: white;
		}
	}
`;

export const StyledCommercialMessage = styled(CommercialMessage)`
	font-size: 0.7rem;
	${({ subtle, variant }) => {
		if (subtle && variant === "family") {
			return `
    background-color: rgba(0, 124, 193, 0.2);
	padding: 0.3333em 0.5em;
    line-height: 1.1233;
  `;
		} else if (subtle && variant === "new") {
			return `
    background-color: rgba(202, 80, 8, 0.2);
	padding: 0.3333em 0.5em;
    line-height: 1.1233;
  `;
		} else if (subtle && variant === "nlp") {
			return `
	color: rgb(10, 138, 0);
    background-color: rgba(10, 138, 0, 0.2);
	padding: 0.3333em 0.5em;
    line-height: 1.1233;
  `;
		}
	}}
`;

export const StyledListView = styled(ListView)`
	padding: 0px;
`;

interface ModalHeaderProps {
	color?: string;
}

export const StyledModalHeader = styled(ModalHeader)<ModalHeaderProps>`
	background-color: ${({ color }) => color || colourStaticIkeaBrandYellow};
	.btn__inner {
		background-color: ${({ color }) => color || colourStaticIkeaBrandYellow} !important;
	}
	.modal-header__title {
		margin: 0;
	}
`;

export const StyledLabel = styled.span`
	font-family: Noto IKEA, Noto Sans, Roboto, Open Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.571;
`;

export const ClientIdsContainer = styled.div`
	border: 1px solid ${colourInteractiveSecondaryBgPressed};
	border-radius: 0.5rem;
	padding-left: 1rem;
	padding-top: 1rem;
`;

export const ClientIdsHeaderInnerContainer = styled.div`
	display: flex;
	align-items: center;
	height: 1.37rem;
`;

export const ClientIdsAddAction = styled.div`
	width: 90%;
`;

export const GreyPlaceholderText = styled(Text)`
	color: ${colourNeutral5};
`;

export const ClientIdsList = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
`;

export const ExpandedClientIdsContainer = styled.div`
	margin-bottom: 1rem;
`;

export const ClientIdsInput = styled(InputField)`
	width: 80%;
`;

export const ClientIdsInnerContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 0.5em;
`;

export const IconButtonWrapper = styled.div`
	position: fixed;
	right: 10rem;
	bottom: 0.8rem;
	align-items: center;
	display: flex;
	gap: 1rem;
`;
