import * as React from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import Checkbox from "@ingka/checkbox";
import { CheckboxControlWrapper } from "../../styles";
import { ControlElement, ControlProps } from "@jsonforms/core";
import parse from "html-react-parser";
import { findAndTransformHTMLString } from "../utils";
import FormField from "@ingka/form-field";
import Tooltip from "@ingka/tooltip";
import { useIsUserCanOnlyEnableProperties } from "../../../hooks/useIsUserCanOnlyEnableProperties";

interface CheckboxProps extends ControlProps {
	uischema: {
		subText?: string;
	} & ControlElement;
}

const CheckboxControl: React.FC<CheckboxProps> = ({
	data,
	handleChange,
	path,
	label,
	enabled,
	schema,
	uischema,
	description,
}) => {
	const [canEnableOnly, setCanEnableOnly] = React.useState(false);

	const userCanOnlyEnableProperties = useIsUserCanOnlyEnableProperties();

	const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			if (userCanOnlyEnableProperties && !e.target.checked && data) {
				setCanEnableOnly(true);
				return;
			}
			handleChange(path, e.target.checked);
		},
		[userCanOnlyEnableProperties, data, handleChange, path]
	);
	const checkbox = React.useMemo(
		() => (
			<Checkbox
				id={path}
				label={label === "Multi" ? "Is TextArea" : label}
				onChange={onChange}
				disabled={!enabled}
				value={data || ""}
				defaultChecked={!path.includes("readOnly") && !path.includes("multi")}
				checked={data || false}
			/>
		),
		[data, enabled, label, onChange, path]
	);
	return (
		<CheckboxControlWrapper>
			<FormField
				fieldHelper={{
					msg: uischema?.subText ? (
						<div>{parse(findAndTransformHTMLString(uischema.subText))}</div>
					) : label === "Multi" ? (
						"Enable this checkbox if this field can have a long text"
					) : (
						""
					),
					id: "input-control-msg",
				}}
			>
				{canEnableOnly ? (
					<Tooltip
						tooltipText={
							"You don't have permisson to disable properties. Please cancel the changes if you don't want to save this changes."
						}
					>
						{checkbox}
					</Tooltip>
				) : description ? (
					<Tooltip tooltipText={description}>{checkbox}</Tooltip>
				) : (
					checkbox
				)}
			</FormField>
		</CheckboxControlWrapper>
	);
};

const CheckBoxControlComp = React.memo(CheckboxControl);

export default withJsonFormsControlProps(CheckBoxControlComp);
