/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";
import Loading, { LoadingBall } from "@ingka/loading";
import { ApplicationUiSchema } from "../../services/applications/types";
import { UserData, ApplicationOnboardedUsers, UserApplicationData } from "../../services/users";
import { 
	LoadingWrapper, 
	StyledCommercialMessage, 
	ActionsWrapper, 
	SaveButtonWrapper 
} from "../styles";
import ManageUsersModal from "./ManageUserModal.tsx/ManageUsersModal";
import { TableWrapper, StyledModalHeader } from "../styles";
import { Application } from "../../store/types";
import Search from "@ingka/search";
import Checkbox from "@ingka/checkbox";
import Toggle from "@ingka/toggle";
import { userType } from "./utils";
import pencil from "@ingka/ssr-icon/paths/pencil";
import globe from "@ingka/ssr-icon/paths/globe";
import arrowLeftToLine from "@ingka/ssr-icon/paths/arrow-left-to-line";
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left";
import arrowRightToLine from "@ingka/ssr-icon/paths/arrow-right-to-line";
import arrowRight from "@ingka/ssr-icon/paths/arrow-right";
import sortAscending from "@ingka/ssr-icon/paths/sort-ascending";
import sortDescending from "@ingka/ssr-icon/paths/sort-descending";
import { getImage } from "../../utils/markets";
import Avatar from "@ingka/avatar";
import SSRIcon from "@ingka/ssr-icon";
import Modal, { ModalFooter, Prompt } from "@ingka/modal";

import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
	CellContext,
	HeaderContext,
	getSortedRowModel,
	SortingState,
} from "@tanstack/react-table";

const StyledMessage = styled(StyledCommercialMessage)`
	margin-left: 0.5rem;
`;

const StyledTableWrapper = styled(TableWrapper)`
	margin-top: 20px;
	border-right: transparent;
	border-left: transparent;
	border-top: transparent;
	.table-head,
	.table-cell {
		text-align: unset;
		margin: 0;
		padding: 0.5rem;
		border-right: transparent !important;
		border-left: transparent !important;
		background-color: white;
	}
`;

const StyledSearch = styled(Search)`
	display: flex;
`;

const StyledToggle = styled(Toggle)`
	margin-top: 1.5rem;
	margin-bottom: 2.5rem;
	display: flex;
	flex-grow: 0.5;
`;

const MarketImage = styled.img`
	height: 25px;
	width: 25px;
	margin-right: 0.2rem;
`;

const StyledMarketIconWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
`;

const StyledHeaderActionsWrapper = styled.div`
	display: flex;
	gap: 1rem;
	padding-top: 0.3rem;
`;

const StyledHeader = styled.h2`
	padding-top: 0.3rem;
`;

const StyledFooter = styled.div`
	display: flex;
	gap: 1rem;
	padding: 0.5rem;
	justify-content: end;
`;

const StyledFooterPageInfo = styled.span`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const StyledAvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const SortedColumnHeader = styled.div`
	cursor: pointer;
`;

interface ManageUsersLayoutProps {
	loading: boolean;
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	modalHeading: string;
	setModalHeading: (value: string) => void;
	modalButtonLabel: string;
	setModalButtonLabel: (value: string) => void;
	setSelectedUser: (data?: UserApplicationData) => void;
	addingNewUser: boolean;
	setAddingNewUser: (value: boolean) => void;
	onFinalSubmit: () => void;
	isAdmin: boolean;
	currentUserSelected: boolean;
	usersInApplication: ApplicationOnboardedUsers[];
	toastMessage: string;
	setToastMessage: (value: string) => void;
	handleClearURLProps: () => void;
	appUsersLoading: boolean;
	setAppUsersLoading: (value: boolean) => void;
	activeApplication: Application;
	onRemoveUserAccess: () => void;
	toggleUserTypeBtnIndex: number;
	setToggleUserTypeBtnIndex: (value: number) => void;
	setSearchUserValue: (value: string) => void;
	setSelectedMarkets: (value?: string[]) => void;
	hasChangedUser: boolean;
	selectedUser?: UserApplicationData;
	data: UserData[];
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	selectedMarkets?: string[];
	onboardingUser: boolean;
	setOnboardingUser: (value: boolean) => void;
	showRemoveUsersModal: boolean;
	setShowRemoveUsersModal: (value: boolean) => void;
	onRemoveMultipleUserAccess: (value: any[]) => void;
	removeUsersLoading: boolean;
}

const Layout: React.FC<ManageUsersLayoutProps> = ({
	loading,
	data,
	usersInApplication,
	setShowModal,
	showModal,
	modalHeading,
	setModalHeading,
	modalButtonLabel,
	setModalButtonLabel,
	marketsData,
	uiSchemas,
	setSelectedUser,
	selectedUser,
	addingNewUser,
	setAddingNewUser,
	onFinalSubmit,
	isAdmin,
	currentUserSelected,
	toastMessage,
	setToastMessage,
	handleClearURLProps,
	appUsersLoading,
	setAppUsersLoading,
	activeApplication,
	onRemoveUserAccess,
	toggleUserTypeBtnIndex,
	setToggleUserTypeBtnIndex,
	setSearchUserValue,
	selectedMarkets,
	setSelectedMarkets,
	hasChangedUser,
	onboardingUser,
	setOnboardingUser,
	showRemoveUsersModal,
	setShowRemoveUsersModal,
	onRemoveMultipleUserAccess,
	removeUsersLoading,
}) => {
	const [rowSelection, setRowSelection] = React.useState({});
	const [sorting, setSorting] = React.useState<SortingState>([
		{
			id: "name-column",
			desc: false,
		},
	]);

	React.useEffect(() => {
		setRowSelection({});
		setSorting([
			{
				id: "name-column",
				desc: false,
			},
		]);
	}, [activeApplication, usersInApplication]);

	const columns = React.useMemo<ColumnDef<ApplicationOnboardedUsers>[]>(
		() => [
			{
				id: "select",
				header: ({ table }: HeaderContext<ApplicationOnboardedUsers, unknown>) => (
					<Checkbox
						id="selectAll"
						value="select-all"
						checked={table.getIsAllRowsSelected()}
						indeterminate={table.getIsSomeRowsSelected()}
						onChange={table.getToggleAllRowsSelectedHandler()}
					/>
				),
				cell: ({ row }: CellContext<ApplicationOnboardedUsers, unknown>) => (
					<Checkbox
						id="select-user"
						value="select-user"
						checked={row.getIsSelected()}
						disabled={!row.getCanSelect()}
						indeterminate={row.getIsSomeSelected()}
						onChange={row.getToggleSelectedHandler()}
					/>
				),
			},
			{
				header: "Avatar",
				id: "avatar-column",
				enableSorting: false,
				accessorKey: "name",
				cell: ({ row }: CellContext<ApplicationOnboardedUsers, unknown>) => {
					const rowValue = row.getValue("avatar-column") as string;
					const userName = rowValue?.split("(")[0]?.split(" ");
					return (
						<StyledAvatarWrapper>
							<Avatar
								screenReaderText={rowValue}
								text={`${userName?.[0].substring(0, 1)}${userName?.[1].substring(0, 1)}`}
							/>
						</StyledAvatarWrapper>
					);
				},
			},
			{
				header: "Name",
				accessorKey: "name",
				id: "name-column",
				cell: ({ row }: CellContext<ApplicationOnboardedUsers, unknown>) => {
					const rowValue = row.getValue("name-column") as string;
					return <strong>{rowValue?.split("(")[0]}</strong>;
				},
			},
			{
				header: "Role",
				accessorKey: `applications.${activeApplication?.id}`,
				id: "role-column",
				enableSorting: false,
				cell: ({ row }: CellContext<ApplicationOnboardedUsers, unknown>) => {
					const rowValue = row.getValue("role-column") as any;
					if (rowValue && rowValue["permissions"] && rowValue["permissions"] === "admin") {
						return <StyledMessage message="Admin" variant="family" subtle />;
					} else if (rowValue && rowValue["markets"] && Object.keys(rowValue["markets"]).length) {
						return <StyledMessage message="Maintainer" variant="nlp" subtle />;
					} else {
						return <StyledMessage message="Read Only" variant="new" subtle />;
					}
				},
			},
			{
				header: "Mail",
				id: "email-column",
				accessorKey: "email",
			},
			{
				header: "Markets",
				id: "markets-column",
				enableSorting: false,
				accessorKey: `applications.${activeApplication?.id}`,
				cell: ({ row }: CellContext<ApplicationOnboardedUsers, unknown>) => {
					const rowValue = row.getValue("markets-column") as any;
					if (rowValue && rowValue["permissions"] && rowValue.permissions === "admin") {
						return <SSRIcon paths={globe} viewBox="0 0 24 24" />;
					} else if (rowValue && rowValue.markets && typeof rowValue.markets === "object") {
						const markets = [];
						for (let i = 0; i < Object.keys(rowValue.markets).length; i++) {
							if (i < 5) {
								markets.push(
									<MarketImage
										src={getImage(Object.keys(rowValue.markets)[i]) || getImage("xx")}
										title={Object.keys(rowValue.markets)[i]}
									/>
								);
							} else {
								markets.push(<p>...</p>);
								break;
							}
						}
						return <StyledMarketIconWrapper>{markets}</StyledMarketIconWrapper>;
					} else {
						return "-";
					}
				},
			},
			{
				header: "Action",
				id: "action-column",
				enableSorting: false,
				accessorKey: "id",
				cell: ({ row }: CellContext<ApplicationOnboardedUsers, unknown>) => {
					const rowValue = row.getValue("action-column") as string;
					return (
						<div>
							<Button
								text="Edit"
								small
								iconPosition="leading"
								type="tertiary"
								ssrIcon={pencil}
								onClick={() => {
									setModalHeading("Update User");
									setModalButtonLabel("Update User");
									setSelectedUser(data?.find((userData) => userData.id === rowValue));
									setAddingNewUser(false);
									setOnboardingUser(false);
									setShowModal(true);
								}}
							/>
						</div>
					);
				},
			},
		],
		[
			activeApplication?.id,
			data,
			setAddingNewUser,
			setModalButtonLabel,
			setModalHeading,
			setOnboardingUser,
			setSelectedUser,
			setShowModal,
		]
	);

	const table = useReactTable({
		data: usersInApplication,
		columns,
		initialState: {
			pagination: {
				pageSize: 10,
			},
		},
		state: {
			rowSelection,
			sorting,
		},
		onSortingChange: setSorting,
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	if (loading || appUsersLoading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Users" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	if (!loading && !appUsersLoading && (!data || data?.length === 0)) {
		return (
			<LoadingWrapper>
				<InlineMessage title="No Users" body={"Seems like you don't have any users onboarded"} variant="informative" />
			</LoadingWrapper>
		);
	}

	return (
		<>
			<StyledHeaderWrapper>
				<StyledHeader>Total Users Onboarded ({usersInApplication ? usersInApplication.length : 0})</StyledHeader>
				<StyledSearch
					id="search"
					onSearch={(_, { value }) => setSearchUserValue(value)}
					onChange={(_, { value }) => setSearchUserValue(value)}
					onClear={() => setSearchUserValue("")}
				/>
			</StyledHeaderWrapper>
			<StyledToggle
				iconOnly={false}
				onClick={(_, index: number) => setToggleUserTypeBtnIndex(index)}
				buttons={Object.values(userType).map((userType: string) => ({ text: userType }))}
				fluid={false}
				activeIndex={toggleUserTypeBtnIndex}
			/>
			<StyledTableWrapper>
				<table className="table" id="usersOnboardedTable">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<th key={header.id} colSpan={header.colSpan}>
											{header.column.getCanSort() ? (
												<SortedColumnHeader
													onClick={header.column.getToggleSortingHandler()}
													title={
														header.column.getCanSort()
															? header.column.getNextSortingOrder() === "asc"
																? "Sort ascending"
																: header.column.getNextSortingOrder() === "desc"
																? "Sort descending"
																: "Clear sort"
															: undefined
													}
												>
													{flexRender(header.column.columnDef.header + " ", header.getContext())}
													{{
														asc: <SSRIcon paths={sortAscending} viewBox="0 0 24 24" />,
														desc: <SSRIcon paths={sortDescending} viewBox="0 0 24 24" />,
													}[header.column.getIsSorted() as string] ?? null}
												</SortedColumnHeader>
											) : (
												<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
											)}
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td key={cell.id} style={{ textAlign: "center" }}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				{usersInApplication.length > 10 && (
					<StyledFooter>
						<Button
							iconOnly
							small
							type="tertiary"
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}
							ssrIcon={arrowLeftToLine}
						></Button>
						<Button
							small
							text="Previous"
							type="tertiary"
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
							ssrIcon={arrowLeft}
						></Button>
						<StyledFooterPageInfo>
							<div>Page</div>
							<strong>
								{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
							</strong>
						</StyledFooterPageInfo>
						<Button
							small
							text="Next"
							type="tertiary"
							iconPosition="trailing"
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
							ssrIcon={arrowRight}
						></Button>
						<Button
							small
							iconOnly
							type="tertiary"
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
							ssrIcon={arrowRightToLine}
						></Button>
						<select
							value={table.getState().pagination.pageSize}
							onChange={(e) => {
								table.setPageSize(Number(e.target.value));
							}}
						>
							{[10, 20, 30].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
					</StyledFooter>
				)}
			</StyledTableWrapper>
			<ActionsWrapper>
				<SaveButtonWrapper>
					<Button
						onClick={() => {
							setModalHeading("Add new User");
							setModalButtonLabel("Add User");
							setAddingNewUser(true);
							setOnboardingUser(false);
							setShowModal(true);
						}}
						text="Onboard new user"
						type="emphasised"
						title="Onboard new user"
					/>
					<Button
						text="Remove users"
						data-testid="revmoveUsers"
						onClick={() => setShowRemoveUsersModal(true)}
						type="secondary"
						title="Remove user(s)"
						disabled={table.getSelectedRowModel().flatRows.length === 0}
					/>
				</SaveButtonWrapper>
			</ActionsWrapper>
			<ManageUsersModal
				showModal={showModal}
				setShowModal={setShowModal}
				modalHeading={modalHeading}
				setModalHeading={setModalHeading}
				modalButtonLabel={modalButtonLabel}
				setModalButtonLabel={setModalButtonLabel}
				marketsData={marketsData}
				uiSchemas={uiSchemas}
				setSelectedUser={setSelectedUser}
				selectedUser={selectedUser}
				addingNewUser={addingNewUser}
				setAddingNewUser={setAddingNewUser}
				onFinalSubmit={onFinalSubmit}
				isAdmin={isAdmin}
				currentUserSelected={currentUserSelected}
				toastMessage={toastMessage}
				setToastMessage={setToastMessage}
				usersInApplication={usersInApplication}
				handleClearURLProps={handleClearURLProps}
				selectedUserEmail={selectedUser?.email}
				onRemoveUserAccess={onRemoveUserAccess}
				selectedMarkets={selectedMarkets}
				setSelectedMarkets={setSelectedMarkets}
				hasChangedUser={hasChangedUser}
				onboardingUser={onboardingUser}
			/>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showRemoveUsersModal}
				handleCloseBtn={() => setShowRemoveUsersModal(false)}
				escapable={false}
			>
				<Prompt
					header={<StyledModalHeader title="Remove users" />}
					footer={
						<ModalFooter>
							<Button text="Close" type="emphasised" />
							<Button
								data-testid="RemoveUsers"
								text="Remove user(s)"
								loading={removeUsersLoading}
								onClick={() => {
									onRemoveMultipleUserAccess(table.getSelectedRowModel().flatRows);
								}}
							/>
						</ModalFooter>
					}
					title=""
					titleId={"RemoveUsersTitle"}
				>
					<p>
						Do you want to remove selected user(s) access to <strong>{activeApplication!.name}</strong>?
					</p>
				</Prompt>
			</Modal>
		</>
	);
};

export default Layout;
