import React from "react";

import Layout from "./Layout";
import { UiSchemaData } from "../../../../services/applications/types";
import { MarketsData } from "../../../../hooks/useGetMarkets";
import { UserMarketPermissions } from "../../../../services/users";
import { useGetCountryName } from "../../../../hooks/useGetCountryName";

interface MarketsAndSchemaSelectorProps {
	maintainerMarkets: UserMarketPermissions;
	setMaintainerMarkets: (data: UserMarketPermissions) => void;
	setIsEditingMarkets: (data: boolean) => void;
	schemasData?: UiSchemaData;
	marketsData?: MarketsData;
}

const MarketsAndSchemaSelector: React.FC<MarketsAndSchemaSelectorProps> = ({
	schemasData,
	marketsData,
	maintainerMarkets,
	setMaintainerMarkets,
	setIsEditingMarkets,
}) => {
	const [isEditing, setIsEditing] = React.useState<boolean>(false);
	const [isAdding, setIsAdding] = React.useState<boolean>(false);
	const [uiSchemaEditing, setUiSchemaEditing] = React.useState<UserMarketPermissions>({});
	const { getCountryName } = useGetCountryName();

	React.useEffect(() => {
		setIsEditingMarkets(isEditing);
	}, [isEditing, setIsEditingMarkets]);

	const isValid = React.useMemo(
		() => !!Object.keys(uiSchemaEditing).length && !Object.values(uiSchemaEditing).includes("Choose an option"),
		[uiSchemaEditing]
	);

	const onEdit = React.useCallback(() => {
		setUiSchemaEditing(maintainerMarkets as UserMarketPermissions);
		setIsEditing(true);
	}, [maintainerMarkets]);

	const onSave = React.useCallback(() => {
		setMaintainerMarkets(uiSchemaEditing);
		setIsEditing(false);
	}, [setMaintainerMarkets, uiSchemaEditing]);

	return (
		<Layout
			isEditing={isEditing}
			setIsEditing={setIsEditing}
			isAdding={isAdding}
			setIsAdding={setIsAdding}
			schemasData={schemasData}
			marketsData={marketsData}
			maintainerMarkets={maintainerMarkets}
			setMaintainerMarkets={setMaintainerMarkets}
			getCountryName={getCountryName}
			uiSchemaEditing={uiSchemaEditing}
			setUiSchemaEditing={setUiSchemaEditing}
			onEdit={onEdit}
			onSave={onSave}
			isValid={isValid}
		/>
	);
};

export default MarketsAndSchemaSelector;
