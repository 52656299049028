import React from "react";
import styled from "styled-components";

import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import { ListItemText, MenuItem, OutlinedInput } from "@mui/material";

import { OverflowCarousel } from "@ingka/carousel";
import SSRIcon from "@ingka/ssr-icon";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import Checkbox from "@ingka/checkbox";
import Skeleton from "@ingka/skeleton";

import { getImage } from "../../../utils/markets";
import { MultiSelectStyles } from "../../styles";
import { UserMarketPermissions } from "../../../services/users";

const StyledSelectLabel = styled.p`
	font-size: 0.875rem;
	line-height: 1.571;
	margin: 0 0 0.125rem 0;
`;

const SelectValueItemWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: rgba(var(--colour-static-ikea-family, 223, 223, 223, 1));
	border-radius: 12px;
	padding: 0 0.5rem 0 0.5rem;
	min-width: 3.4rem;
	margin-right: 0.25rem;
`;

const SelectImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 0.2rem;
`;

const StyledSSRIcon = styled(SSRIcon)`
	margin-right: 0.7rem;
`;

const StyledImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 10px;
`;

interface MaintainerMarketSelectorLayoutProps {
	marketsDataLoading: boolean;
	marketsData?: string[];
	selectedMarkets: string[];
	requestingRole: boolean;
	setSelectedMarkets: (val: string[]) => void;
	getCountryName: (val: string) => string;
}

const Layout: React.FC<MaintainerMarketSelectorLayoutProps> = ({
	marketsDataLoading,
	selectedMarkets,
	marketsData,
	setSelectedMarkets,
	getCountryName,
	requestingRole,
}) => {
	const handleChange = (event: SelectChangeEvent<typeof selectedMarkets>) => {
		const {
			target: { value },
		} = event;
		setSelectedMarkets(typeof value === "string" ? value.split(",") : value);
	};

	if (marketsDataLoading) {
		return <Skeleton height="8rem" />;
	}

	return (
		<div>
			<p>Select markets for which you want to request Maintainer role</p>
			<StyledSelectLabel>Select Markets</StyledSelectLabel>
			<MuiSelect
				labelId="select-market-checkbox-label"
				id="select-market-multiple-checkbox"
				displayEmpty
				renderValue={(selected: string[]) => {
					if (selected.length === 0) {
						return (
							<MenuItem key="multi-select-placeholder" disabled sx={{ padding: 0 }} value="">
								Choose an option
							</MenuItem>
						);
					}

					return (
						<OverflowCarousel hasNoControls>
							{selected.map((market: string) => (
								<SelectValueItemWrapper>
									<SelectImage src={getImage(market) || getImage("xx")} />
									<span>{market}</span>
								</SelectValueItemWrapper>
							))}
						</OverflowCarousel>
					);
				}}
				multiple
				value={selectedMarkets}
				disabled={marketsDataLoading || requestingRole}
				onChange={handleChange}
				input={<OutlinedInput />}
				style={{ width: "100%", height: "3rem" }}
				sx={{
					...MultiSelectStyles,
				}}
				IconComponent={() => <StyledSSRIcon paths={chevronDownSmall} viewBox="0 0 21 21" />}
				MenuProps={{
					PaperProps: {
						sx: {
							maxHeight: "20rem",
						},
					},
				}}
				inputProps={{ placeholder: "Choose an option" }}
			>
				{marketsData?.length ? (
					marketsData.map((market) => (
						<MenuItem disableRipple key={market} value={market}>
							<StyledImage src={getImage(market) || getImage("xx")} />
							<ListItemText primary={`${getCountryName(market)} (${market})`} />
							<Checkbox checked={selectedMarkets?.indexOf(market) > -1 || false} id={market} value={market} />
						</MenuItem>
					))
				) : (
					<MenuItem disabled>
						<p>Seems like the application does not have any markets</p>
					</MenuItem>
				)}
			</MuiSelect>
		</div>
	);
};

export default Layout;
