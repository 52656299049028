import * as React from "react";
import styled from "styled-components";

import { ApplicationData } from "../../store";
import { ContentWrapper } from "../styles";
import MarketFeaturesOverviewTable from "../MarketFeaturesOverviewTable";
import Text from "@ingka/text";
import Skeleton from "@ingka/skeleton";
import DashboardTile from "../DashboardTile/index";

interface DashboardLayoutProps {
	applicationsData?: ApplicationData[];
	applicationsLoading: boolean;
	isFlaggaAdmin: boolean;
	activeMarket: string | null;
	getCountryName: (country: string) => string;
	image: string | undefined;
	lastUpdateDate: string;
	setLastUpdateDate: (data: string) => void;
	lastUpdateDateLoading: boolean;
	setLastUpdateDateLoading: (loading: boolean) => void;
}

const MarketDashboardHeader = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const CountryFlag = styled.img`
	height: 3rem;
	width: 3rem;
`;

const MarketDashboardContainer = styled.div`
	display: flex;
`;

const StyledTile = styled.div`
	margin-left: 1rem;
	width: fit-content;
	background-color: #e3f5ff;
	padding: 0rem 1.2rem 1.2rem 1.2rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	height: 6rem;
`;

const StyledSkeleton = styled(Skeleton)`
	width: 16rem;
	border-radius: 1rem;
	margin-left: 1rem;
`;

const StyledHeaderText = styled(Text)`
	padding-top: 1.2rem;
	height: 2.5rem;
`;

const Layout: React.FC<DashboardLayoutProps> = ({
	applicationsData,
	applicationsLoading,
	activeMarket,
	getCountryName,
	image,
	lastUpdateDate,
	setLastUpdateDate,
	lastUpdateDateLoading,
	setLastUpdateDateLoading
}) => {
	return (
		<ContentWrapper>
			{activeMarket && (
				<>
					<MarketDashboardHeader>
						{image && <CountryFlag alt="Country flag" src={image} />}
						<h1>{getCountryName(activeMarket)}</h1>
						{lastUpdateDate && !lastUpdateDateLoading && (
							<StyledTile>
								<StyledHeaderText headingSize="xs">Recent update</StyledHeaderText>
								<Text headingSize="m">{lastUpdateDate}</Text>
							</StyledTile>
						)}
						{lastUpdateDateLoading && <StyledSkeleton width="15rem" height="5.5rem"></StyledSkeleton>}
						<DashboardTile showGlobal={false}/>
					</MarketDashboardHeader>
					<MarketDashboardContainer>
						<MarketFeaturesOverviewTable
							applicationsData={applicationsData}
							applicationsLoading={applicationsLoading}
							activeMarket={activeMarket}
							lastUpdateDate={lastUpdateDate}
							setLastUpdateDate={setLastUpdateDate}
							setLastUpdateDateLoading={setLastUpdateDateLoading}
						/>
					</MarketDashboardContainer>
				</>
			)}
		</ContentWrapper>
	);
};

export default Layout;
