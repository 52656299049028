import * as React from "react";
import styled from "styled-components";

import Modal, { ModalBody, Sheets } from "@ingka/modal";

import { ManageUserModalButtonWrapper } from "../../styles";
import ListView, { ListViewItem } from "@ingka/list-view";
import { UserData } from "../../../services/users";
import { Application } from "../../../store";
import { StyledModalHeader } from "../../styles";
import Avatar from "@ingka/avatar";
import InlineMessage from "@ingka/inline-message";
import { sortMarketUsers } from "../../MarketFeaturesOverviewTable/utils";
import FormField from "@ingka/form-field";
import Select, { Option as SelectOption } from "@ingka/select";
import Skeleton from "@ingka/skeleton";
import Toast from "@ingka/toast";

interface ModalLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	adminsList: UserData[] | undefined;
	activeApplication: Application | undefined;
	selectedApplicationId?: string;
	setSelectedApplicationId: (applicationId: string | undefined) => void;
	userApplications: any | undefined;
	adminsListLoading: boolean;
	toast: string;
	setToast: (val: string) => void;
}

const StyledModalBody = styled(ModalBody)`
	padding: 1rem 3rem;
	.switch {
		padding-top: 0.5rem;
	}
	.inline-message {
		margin: 1rem 0;
	}
`;

const StyledFormField = styled(FormField)`
	margin-top: 1rem;
`;

const StyledListView = styled(ListView)`
	padding: 0rem;

	.list-view-item {
		border-bottom: 0rem !important;
	}
`;

const ListItemWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	border-bottom: 1px rgb(var(--colour-neutral-3, 223, 223, 223)) solid;
`;

const StyledAvatar = styled(Avatar)`
	width: 3rem;
`;

const SkeletonContentWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const SkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 1rem 0 1rem 0;
`;

const StyledSkeletonAvatar = styled(Skeleton)`
	height: 2.5rem;
	border-radius: 50%;
`;

const SkeletonInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Layout: React.FC<ModalLayoutProps> = ({
	showModal,
	setShowModal,
	adminsList,
	activeApplication,
	selectedApplicationId,
	setSelectedApplicationId,
	userApplications,
	adminsListLoading,
	toast,
	setToast,
}) => {
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
					<Sheets
						footer={<ManageUserModalButtonWrapper></ManageUserModalButtonWrapper>}
						header={
							<StyledModalHeader
								title={
									userApplications && Object.keys(userApplications).length > 1
										? "Administrators"
										: `Administrators of the ${activeApplication?.name}`
								}
							/>
						}
						size="small"
					>
						<StyledModalBody>
							{userApplications && Object.keys(userApplications).length > 1 && (
								<StyledFormField>
									<Select
										id="all-users-application"
										label="Select Application"
										value={selectedApplicationId}
										onChange={(e) => {
											if (e.target.value !== "Choose an option") {
												setSelectedApplicationId(e.target.value);
											} else {
												setSelectedApplicationId(undefined);
											}
										}}
									>
										{Object.keys(userApplications)?.map((applicationId) => (
											<SelectOption
												id={applicationId}
												key={applicationId}
												value={applicationId}
												name={userApplications[applicationId].applicationName}
											/>
										))}
									</Select>
								</StyledFormField>
							)}
							<StyledListView id="admins" title="admins">
								{!adminsListLoading &&
									adminsList &&
									adminsList.sort(sortMarketUsers).map((admin) => (
										<ListItemWrapper>
											<StyledAvatar
												screenReaderText={admin.name ? admin.name : "No name"}
												text={admin.name?.split("(")[0].replace(/[^A-Z]/g, "")}
											/>
											<ListViewItem
												id={admin.id}
												key={admin.id}
												inset={false}
												title={admin.name}
												description={admin.email}
												emphasised
												image={undefined}
												controlIcon="chevronRightSmall"
											/>
										</ListItemWrapper>
									))}
								{adminsListLoading &&
									[0, 1, 2].map((_) => (
										<SkeletonWrapper>
											<SkeletonContentWrapper>
												<StyledSkeletonAvatar width="2.5rem" />
												<SkeletonInfoWrapper>
													<Skeleton width="8rem" />
													<Skeleton width="16rem" />
												</SkeletonInfoWrapper>
											</SkeletonContentWrapper>
											<Skeleton width="100%" height="0.05rem" />
										</SkeletonWrapper>
									))}
								{!adminsList ||
									(adminsList.length === 0 && selectedApplicationId && (
										<InlineMessage title="No admins in this application" variant="informative" />
									))}
							</StyledListView>
							{selectedApplicationId === undefined && (
								<InlineMessage title="Please select an application" variant="informative" />
							)}
						</StyledModalBody>
					</Sheets>
				</Modal>
				<Toast
					text={toast}
					isOpen={!!toast}
					onCloseRequest={() => setToast("")}
					ariaLabelCloseBtn="Dismiss notification"
				/>
			</form>
		</>
	);
};

export default Layout;
