import * as React from "react";

import useStore, {
	activeMarketSelector,
	isApplicationAdminSelector,
	setActiveMarketSelector,
	setActiveTabSelector,
	multipleMarketUpdateFlagSelector,
	setMultipleMarketUpdateFlagSelector,
} from "../../store";

import { MarketsData } from "../../hooks/useGetMarkets";
import Layout from "./Layout";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import useTabs from "../../hooks/useTabs";
import useActiveMarket from "../../hooks/useActiveMarket";

interface MarketsProps {
	marketsLoading: boolean;
	marketsData?: MarketsData;
}

const Markets: React.FC<MarketsProps> = ({ marketsData, marketsLoading }) => {
	const setActiveMarket = useStore(setActiveMarketSelector);
	const activeMarket = useStore(activeMarketSelector);
	const { activeTab } = useTabs();
	const { activeMarketParams, setActiveMarketParams } = useActiveMarket();
	const isAppAdmin = useStore(isApplicationAdminSelector);
	const setActiveTab = useStore(setActiveTabSelector);
	const multipleMarketFlag = useStore(multipleMarketUpdateFlagSelector);
	const setMultipleMarketFlag = useStore(setMultipleMarketUpdateFlagSelector);
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	const { getCountryName } = useGetCountryName();

	const isAdmin = React.useMemo(() => isFlaggaAdmin || isAppAdmin, [isAppAdmin, isFlaggaAdmin]);

	React.useEffect(() => {
		if (marketsData) {
			if (!activeMarket) {
				if (activeMarketParams) {
					if (marketsData?.permittedMarkets?.length) {
						setActiveMarket(
							marketsData.permittedMarkets.find((market) => market === activeMarketParams.toUpperCase()) ||
								marketsData.permittedMarkets[0]
						);
					} else if (marketsData?.otherMarkets?.length) {
						setActiveMarket(
							marketsData.otherMarkets.find((market) => market === activeMarketParams.toUpperCase()) ||
								marketsData.otherMarkets[0]
						);
					}
				} else {
					if (marketsData?.permittedMarkets?.length) {
						setActiveMarket(marketsData.permittedMarkets[0]);
					} else if (marketsData?.otherMarkets?.length) {
						setActiveMarket(marketsData.otherMarkets[0]);
					}
				}
			} else {
				setActiveMarketParams(activeMarket);
			}
		}
	}, [activeMarket, activeMarketParams, marketsData, marketsLoading, setActiveMarket, setActiveMarketParams]);

	return (
		<Layout
			loading={marketsLoading}
			setActiveMarket={setActiveMarket}
			activeMarket={activeMarket}
			marketsData={marketsData}
			activeTab={activeTab}
			isAdmin={isAdmin}
			isAppAdmin={isAppAdmin}
			isFlaggaAdmin={isFlaggaAdmin}
			setActiveTab={setActiveTab}
			multipleMarketFlag={multipleMarketFlag}
			setMultipleMarketFlag={setMultipleMarketFlag}
			getCountryName={getCountryName}
		/>
	);
};

export default Markets;
