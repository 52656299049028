import React from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";

import { colourStaticGrey } from "@ingka/variables/colours-css";
import Badge from "@ingka/badge";

import { DndItems } from "../../core/dnd";
import { SchemaIcon } from "../../core/icons";
import {
	getChildren,
	getLabel,
	getPath,
	isArrayElement,
	isObjectElement,
	SchemaElement,
} from "../../core/model/schema";
import { EditorUISchemaElement } from "../../core/model/uischema";
import { createControl } from "../../core/util/generators/uiSchema";
import { StyledTreeItem, StyledTreeView } from "./Tree";
import Tooltip from "@ingka/tooltip";
import Button from "@ingka/button";
import ButtonDropDown from "../../../ButtonDropDown/ButtonDropDown";
import { ListViewItem } from "@ingka/list-view";
import arrowUpArrowDown from "@ingka/ssr-icon/paths/arrow-up-arrow-down";

import { useSearchElement, useSearchElementParents } from "../../core/context";

interface SchemaTreeItemProps {
	schemaElement: SchemaElement;
}

const StyledButtonDropDown = styled(ButtonDropDown)`
	.list-box {
		width: 10rem !important;
	}
`;

const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0.15rem 0 0 0;
`;

const Paragraph = styled.p`
	margin: 0;
	flex-grow: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 3rem;
`;

const StyledBadgeWrapper = styled.div`
	position: fixed;
	right: 0;
	margin-bottom: 0.4rem;
`;

const SchemaTreeItem: React.FC<SchemaTreeItemProps> = ({ schemaElement }) => {
	const uiSchemaElement: EditorUISchemaElement = createControl(schemaElement);
	const [searchElement, setSearchElement] = useSearchElement();
	const [, setSearchElementParent] = useSearchElementParents();

	//@ts-ignore
	const scopeSplit = uiSchemaElement?.scope?.split("/") || null;
	const isAddressOrder = scopeSplit ? scopeSplit?.[scopeSplit.length - 1] === "addressOrder" : false;

	//@ts-ignore
	const [{ isDragging }, drag] = useDrag({
		item: DndItems.newUISchemaElement(uiSchemaElement, schemaElement.uuid),
		canDrag: () => {
			return schemaElement.schema.type !== "object" || isAddressOrder;
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	React.useEffect(() => {
		if (isDragging) {
			setSearchElement(undefined);
			setSearchElementParent([]);
		}
	}, [isDragging, setSearchElement, setSearchElementParent]);

	const schemaElementPath = getPath(schemaElement);

	return (
		<div ref={drag} data-cy={`${schemaElementPath}-source`} style={{ opacity: 0.95 }}>
			<StyledTreeItem
				key={schemaElementPath}
				nodeId={schemaElementPath}
				label={
					<LabelWrapper>
						<Paragraph>{`${getLabel(schemaElement)}`}</Paragraph>
						{schemaElement.linkedUISchemaElements?.size !== undefined && schemaElement.linkedUISchemaElements?.size !== 0 && (
							<StyledBadgeWrapper data-cy={`${schemaElementPath}-count`}>
								<Tooltip
									data-cy={`${schemaElementPath}-tooltip`}
									position="leading"
									tooltipText={`Property used: ${schemaElement.linkedUISchemaElements?.size.toString()} time(s)`}
								>
									<Badge colour="grey" label={schemaElement.linkedUISchemaElements?.size.toString()} size="small" />
								</Tooltip>
							</StyledBadgeWrapper>
						)}
					</LabelWrapper>
				}
				onLabelClick={() => {
					if (schemaElement.type !== "object") {
						setSearchElement(undefined);
						setSearchElementParent([]);
						const searhingEl = new Set(schemaElement.linkedUISchemaElements);
						schemaElement?.uuid && searhingEl?.add(schemaElement?.uuid);
						schemaElement?.uuid && setSearchElement({ uuid: schemaElement.uuid });
					}
				}}
				icon={
					<Tooltip tooltipText={schemaElement.type === "other" ? "select" : schemaElement.type}>
						<SchemaIcon type={schemaElement.type} />
					</Tooltip>
				}
				isDragging={isDragging}
			>
				{schemaElement.type != "other" &&
					!isAddressOrder &&
					getChildrenToRender(schemaElement).map((child) => (
						<SchemaTreeItem schemaElement={child} key={getPath(child)} />
					))}
			</StyledTreeItem>
		</div>
	);
};

const getChildrenToRender = (schemaElement: SchemaElement) => {
	return getChildren(schemaElement).flatMap((child) => {
		// if the child is the only item of an array, use its children instead
		if (isObjectElement(child) && isArrayElement(child.parent) && child.parent.items === child) {
			return getChildren(child);
		}
		return [child];
	});
};

const HeadingWrapper = styled.div`
	display: flex;
	padding-left: 1rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid ${colourStaticGrey};
	border-top: 1px solid ${colourStaticGrey};
	align-items: center;
	gap: 0.5rem;

	.list-box {
		width: 10rem !important;
	}
`;
const StyledH3 = styled.h3`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const SchemaTreeView: React.FC<{
	schema: SchemaElement | undefined;
	sortingUISchema: "name" | "type";
	setSortingUISchema: (sorting: "name" | "type") => void;
	hidePalettePanel: boolean;
}> = ({ schema, hidePalettePanel, sortingUISchema, setSortingUISchema }) => {
	const [schemaMenuOpen, setSchemaMenuOpen] = React.useState<boolean>(false);

	return (
		<>
			{!hidePalettePanel && (
				<>
					<HeadingWrapper>
						<StyledH3>Controls</StyledH3>
						<StyledButtonDropDown
							id="sorting-uischema-btn"
							key="sorting-uischema-btn"
							small={false}
							xsmallIconOnly
							ssrIcon={arrowUpArrowDown}
							open={schemaMenuOpen}
							setMenuOpen={setSchemaMenuOpen}
							className={"marginRight: 0.5rem"}
							noArrow={true}
							text="Sorting"
						>
							<ListViewItem
								data-testid="sortingByNameOption"
								id="sortingByNameOption"
								key="sorting-name-option"
								control="radiobutton"
								inset
								title="By Name"
								onChange={() => {
									setSortingUISchema("name");
									setSchemaMenuOpen(false);
								}}
								controlProps={
									{
										value: "name",
										checked: sortingUISchema === "name",
									} as any
								}
							/>
							<ListViewItem
								data-testid="sortingByTypeOption"
								id="sortingByTypeOption"
								key="sorting-type-option"
								control="radiobutton"
								inset
								title="By Type"
								onChange={() => {
									setSortingUISchema("type");
									setSchemaMenuOpen(false);
								}}
								controlProps={
									{
										value: "type",
										checked: sortingUISchema === "type",
									} as any
								}
							/>
						</StyledButtonDropDown>
					</HeadingWrapper>
					{schema !== undefined ? (
						<StyledTreeView defaultExpanded={[""]}>
							<SchemaTreeItem schemaElement={schema} />
						</StyledTreeView>
					) : (
						<NoSchema />
					)}
				</>
			)}
		</>
	);
};

const NoSchema = () => <div>No JSON Schema available</div>;
