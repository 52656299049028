import * as React from "react";
import { IconProps } from "./types";

export const SortDescending: React.FC<IconProps> = ({ height, width, colour }) => {
	return (
		<svg
			width={`${width}` || "24"}
			height={`${height}` || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 6H12v2h10V6zm-10 5h7v2h-7v-2zm0 5h4v2h-4v-2zm-6.9995-1.8284L3.172 12.3431l-1.4142 1.4143L6.0004 18l4.2427-4.2426-1.4142-1.4143-1.8284 1.8284V6h-2v8.1716z"
				fill={colour || "#111"}
			/>
		</svg>
	);
};
