import React from "react";
import ContentBox from "../../../ContentBox/ContentBox";
import { StyledListView } from "../../../styles";
import { ValidationProps } from "../../types";

import { ListViewItem } from "@ingka/list-view";
import person from "@ingka/ssr-icon/paths/person";
import people from "@ingka/ssr-icon/paths/people";
import visiblityShow from "@ingka/ssr-icon/paths/visibility-show";

interface SelectRoleLayoutProps extends ValidationProps {}

const Layout: React.FC<SelectRoleLayoutProps> = ({ value, valid, validation, handleChange, shouldValidate }) => {
	return (
		<ContentBox>
			<StyledListView id={"roles-list"}>
				<ListViewItem
					id="role-admin"
					title="Admin"
					emphasised
					description="Users with this role will have access to all markets and full control of the application."
					control="checkbox"
					controlProps={
						{
							value: "admin",
							checked: true,
							disabled: true,
						} as any
					}
					leadingIcon={person}
				/>
				<ListViewItem
					id="role-maintainer"
					title="Maintainer"
					emphasised
					description="Users with this role have access to specific markets."
					control="checkbox"
					controlProps={
						{
							value: "role-maintainer",
							checked: true,
							disabled: true,
						} as any
					}
					leadingIcon={people}
				/>
				<ListViewItem
					id="role-read-only"
					title="Read only"
					emphasised
					description="Users with this role will have read only access to all markets."
					control="switch"
					showDivider={false}
					controlProps={
						{
							value: "role-read-only",
							checked: value.allowReadOnly,
						} as any
					}
					onChange={() => handleChange("", "allowReadOnly")}
					leadingIcon={visiblityShow}
				/>
			</StyledListView>
		</ContentBox>
	);
};

export default Layout;
