import * as React from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";

import ApplicationCreated from "./ApplicationCreated";
import ExistingApplicationsRequested from "./ExistingApplicationsRequested";
import { RequestedCreateNewApplications, UserRequestedApplications } from "../../../store";

const TabPanelKeys = {
	applicationCreated: "applicationCreated",
	existingApplicationsRequested: "existingApplicationsRequested",
};

interface RequestesProps {
	cancelCreateNewApplicationRequest: (appId: string) => void;
	cancelExistingAppRequest: (appId: string) => void;
	cancelExistingAppRequestLoading: boolean;
	cancelNewlyCreatedAppLoading: boolean;
	createdAppRemoving: string;
	existingAppRequestRemoving: string;
	userLoading: boolean;
	existingAppRequests?: UserRequestedApplications[];
	newAppsRequested?: RequestedCreateNewApplications[];
}

const Requests: React.FC<RequestesProps> = ({
	existingAppRequests,
	newAppsRequested,
	cancelCreateNewApplicationRequest,
	cancelExistingAppRequest,
	cancelExistingAppRequestLoading,
	cancelNewlyCreatedAppLoading,
	createdAppRemoving,
	existingAppRequestRemoving,
	userLoading,
}) => {
	const [activeTab, setActiveTab] = React.useState<string>(TabPanelKeys.applicationCreated);

	const tabPanels = React.useMemo(
		() => [
			<TabPanel key={TabPanelKeys.applicationCreated} tabPanelId={TabPanelKeys.applicationCreated}>
				<ApplicationCreated
					newAppsRequested={newAppsRequested}
					cancelCreateNewApplicationRequest={cancelCreateNewApplicationRequest}
					cancelNewlyCreatedAppLoading={cancelNewlyCreatedAppLoading}
					createdAppRemoving={createdAppRemoving}
					userLoading={userLoading}
				/>
			</TabPanel>,
			<TabPanel
				key={TabPanelKeys.existingApplicationsRequested}
				tabPanelId={TabPanelKeys.existingApplicationsRequested}
			>
				<ExistingApplicationsRequested
					existingAppRequests={existingAppRequests}
					cancelExistingAppRequest={cancelExistingAppRequest}
					cancelExistingAppRequestLoading={cancelExistingAppRequestLoading}
					existingAppRequestRemoving={existingAppRequestRemoving}
					userLoading={userLoading}
				/>
			</TabPanel>,
		],
		[
			cancelCreateNewApplicationRequest,
			cancelExistingAppRequest,
			cancelExistingAppRequestLoading,
			cancelNewlyCreatedAppLoading,
			createdAppRemoving,
			existingAppRequestRemoving,
			existingAppRequests,
			newAppsRequested,
			userLoading,
		]
	);

	const tabs = React.useMemo(
		() => [
			<Tab
				key={TabPanelKeys.applicationCreated}
				tabPanelId={TabPanelKeys.applicationCreated}
				onClick={() => setActiveTab(TabPanelKeys.applicationCreated)}
				text="Application Created"
			/>,
			<Tab
				key={TabPanelKeys.existingApplicationsRequested}
				tabPanelId={TabPanelKeys.existingApplicationsRequested}
				onClick={() => setActiveTab(TabPanelKeys.existingApplicationsRequested)}
				text="Existing application requested"
			/>,
		],
		[]
	);
	return <Tabs activeTab={activeTab} tabPanels={tabPanels} tabs={tabs} />;
};

export default Requests;
