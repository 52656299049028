import * as React from "react";
import styled from "styled-components";

import Skeleton from "@ingka/skeleton";
import InlineMessage from "@ingka/inline-message";
import { colourStaticGrey, colourSemanticInformative } from "@ingka/variables/colours-css";
import Button from "@ingka/button";
import rectangleHorizontalThinTriple from "@ingka/ssr-icon/paths/rectangle-horizontal-thin-tripple";
import arrowDownToBase from "@ingka/ssr-icon/paths/arrow-down-to-base";
import { Tooltip } from "@mui/material";

import { SortAscending, SortDescending } from "../../assets/icons";
import { Application } from "../../store";
import Toast from "@ingka/toast";

import {
	ColumnInstance,
	HeaderGroup,
	Row,
	TableBodyPropGetter,
	TableBodyProps,
	TablePropGetter,
	TableProps,
} from "react-table";

import FilterColumnsModal from "./FilterColumnsModal/FilterColumnsModal";
import { TableWrapper } from "../styles";
import DashboardTile from "../DashboardTile/DashboardTile";

interface FeaturesTableProps {
	loading: boolean;
	columnFilterModal: boolean;
	setColumnFilterModal: (value: boolean) => void;
	getTableProps: (propGetter?: TablePropGetter<object> | undefined) => TableProps;
	headerGroups: HeaderGroup<object>[];
	getTableBodyProps: (propGetter?: TableBodyPropGetter<object> | undefined) => TableBodyProps;
	rows: Row<object>[];
	prepareRow: (row: Row<object>) => void;
	allColumns: ColumnInstance<object>[];
	activeApplication: Application;
	allColumnsGroup: any;
	hasGroups: boolean;
	exportGlobalDashboardTable: () => void;
	toastMessage: string;
	setToastMessage: (value: string) => void;
}

interface ColumnTitleWrapperProps {
	isSorted: boolean;
}

const ColumnTitleWrapper = styled.div<ColumnTitleWrapperProps>`
	font-weight: 700;
	width: 100%;
	${({ isSorted }) =>
		isSorted &&
		`white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;`}
`;

const IconWrapper = styled.div<ColumnContainerProps>`
	display: ${({ isSorted }) => (isSorted ? "flex" : "none")};
	margin-left: auto;
	align-items: center;
	margin-left: 0.3rem;
`;

interface ColumnContainerProps {
	isSorted: boolean;
}

const ColumnContainer = styled.div<ColumnContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface TableCellProps {
	cellId: string;
}

const StyledTable = styled.table``;

const TableCell = styled.td<TableCellProps>`
	display: flex;
	justify-content: ${({ cellId }) => (cellId === "markets" ? "left" : "center")};
	align-items: center;
	min-height: 3rem;
`;

const TableToolbarWrapper = styled.div`
	display: flex;
	border-top: 1px solid ${colourStaticGrey};
	border-left: 1px solid ${colourStaticGrey};
	border-right: 1px solid ${colourStaticGrey};
	padding: 0.5rem;
`;

const ToolbarOptionsWrapper = styled.div`
	margin-left: auto;
`;

const SkeletonWrapper = styled.div`
	padding: 1.5rem 0;
`;

const NoRowWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20rem;
`;

const ToggleColumnsButton = styled(Button)`
	svg:first-child {
		transform: rotate(90deg);
	}
`;

const StyledTableWrapper = styled(TableWrapper)`
	${({ bodyHeight }) =>
		bodyHeight ? `max-height: ${bodyHeight + 18}px; height: calc(100vh - 17rem);` : "min-height: 20rem;"}
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
`;

const Layout: React.FC<FeaturesTableProps> = ({
	loading,
	columnFilterModal,
	setColumnFilterModal,
	getTableProps,
	headerGroups,
	getTableBodyProps,
	rows,
	prepareRow,
	allColumns,
	activeApplication,
	allColumnsGroup,
	hasGroups,
	exportGlobalDashboardTable,
	toastMessage,
	setToastMessage,
}) => {
	const tableRef = React.useRef<any>(null);
	const [tableHeight, setTableHeight] = React.useState<string>("");

	React.useEffect(() => {
		tableRef?.current?.clientHeight ? setTableHeight(tableRef.current.clientHeight) : setTableHeight("320");
	}, [rows]);

	return (
		<>
			{activeApplication &&
				(loading ? (
					<SkeletonWrapper>
						<Skeleton height="2rem" width="34rem" />
					</SkeletonWrapper>
				) : (
					<HeaderWrapper>
						<h2>{`${activeApplication.name} - Global Overview`}</h2>
						<DashboardTile showGlobal={true} />
					</HeaderWrapper>
				))}
			<TableToolbarWrapper>
				<ToolbarOptionsWrapper>
					<ToggleColumnsButton
						type="tertiary"
						text="Toggle Columns"
						small
						disabled={loading || !allColumns.length}
						ssrIcon={rectangleHorizontalThinTriple}
						onClick={() => setColumnFilterModal(true)}
					/>
					<Button
						type="tertiary"
						text="Export"
						small
						disabled={loading || !allColumns.length}
						ssrIcon={arrowDownToBase}
						onClick={() => exportGlobalDashboardTable()}
					/>
				</ToolbarOptionsWrapper>
			</TableToolbarWrapper>
			<StyledTableWrapper bodyHeight={tableHeight}>
				{!allColumns.length && (
					<NoRowWrapper>
						<InlineMessage
							title="No Rows"
							body={"Seems like you don't have dashboard data yet..."}
							variant="cautionary"
						/>
					</NoRowWrapper>
				)}
				<StyledTable ref={tableRef} className="table" {...getTableProps()}>
					<thead className="table-header">
						{headerGroups.map((headerGroup: any) => (
							<tr className="table-row" {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column: any) => (
									<th className="table-head" {...column.getHeaderProps()}>
										<ColumnContainer isSorted={column.isSorted}>
											<Tooltip
												componentsProps={{
													tooltip: {
														sx: {
															bgcolor: "black",
														},
													},
												}}
												title={
													column.description
														? column.description
														: typeof column["Header"] === "string"
														? column["Header"]
														: ""
												}
											>
												<ColumnTitleWrapper className="title-wrapper" isSorted={column.isSorted}>
													{column.render("Header")}
												</ColumnTitleWrapper>
											</Tooltip>
											<IconWrapper
												isSorted={column.isSorted}
												className="icons-wrapper"
												{...column.getSortByToggleProps({ title: undefined })}
											>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<SortDescending height={16} width={16} colour={colourSemanticInformative} />
													) : (
														<SortAscending height={16} width={16} colour={colourSemanticInformative} />
													)
												) : (
													<SortDescending height={16} width={16} />
												)}
											</IconWrapper>
										</ColumnContainer>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row: any) => {
							prepareRow(row);
							return (
								<tr className="table-row" {...row.getRowProps()}>
									{row.cells.map((cell: any) => (
										<TableCell cellId={cell.column.id} className="table-cell" {...cell.getCellProps()}>
											{cell.render("Cell")}
										</TableCell>
									))}
								</tr>
							);
						})}
					</tbody>
				</StyledTable>
			</StyledTableWrapper>
			<FilterColumnsModal
				columnFilterModal={columnFilterModal}
				setColumnFilterModal={setColumnFilterModal}
				allColumns={allColumns}
				allColumnsGroup={allColumnsGroup}
				hasGroups={hasGroups}
			/>
			<Toast
				data-testid="message"
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default Layout;
