import React from "react";

import { getRequestingMarkets } from "../../../services/markets";
import useAuthentication from "../../../hooks/useAuthentication";
import Layout from "./Layout";
import { useGetCountryName } from "../../../hooks/useGetCountryName";

interface MaintainerMarketSelectorProps {
	appId?: string;
	selectedMarkets: string[];
	setSelectedMarkets: (val: string[]) => void;
	requestingRole: boolean;
}

const MaintainerMarketSelector: React.FC<MaintainerMarketSelectorProps> = ({
	appId,
	selectedMarkets,
	setSelectedMarkets,
	requestingRole,
}) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const [data, setData] = React.useState<string[] | undefined>(undefined);
	const { getCountryName } = useGetCountryName();
	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		const getMarketsData = async () => {
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};

				if (appId) {
					setLoading(true);
					const { data: marketData } = await getRequestingMarkets(authHeader, appId);
					if (marketData?.data) {
						setData(marketData?.data);
					}
				}
			} catch (error: any) {
				if (error) {
					setData(undefined);
					setLoading(false);
					if (error?.response?.data?.message === "something went wrong") {
						setError("Can't fetch Markets, something went wrong");
					} else setError(error?.response?.data?.message as string);
					if (error?.response?.status == 401) {
						handleLogout();
					}
				}
			} finally {
				setLoading(false);
			}
		};
		getMarketsData();

		return () => {
			setData(undefined);
			setLoading(false);
		};
	}, [getToken, handleLogout, appId]);

	return (
		<Layout
			marketsDataLoading={loading}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			marketsData={data}
			getCountryName={getCountryName}
			requestingRole={requestingRole}
		/>
	);
};

export default MaintainerMarketSelector;
