import { AxiosResponse } from "axios";
import axios from "../../configs/axios";
import { AuthHeader } from "../../types";

export interface BackupDates {
	date: string;
	haveMultipleBackups: boolean;
}

export interface ApplicationBackupDatesData {
	message: string;
	data: BackupDates[];
}

export interface ApplicationBackupData {
	message: string;
	data: string[];
}

export interface ApplicationRestoreRespose {
	message: string;
}

export interface MarketBackupListResponse {
	data: string[];
}

export const getApplicationBackupDates = async (header: AuthHeader, application: String) => {
	const data: AxiosResponse<ApplicationBackupDatesData, AuthHeader> = await axios.get(
		`/application/${application}/restore/date`,
		{
			...header,
		}
	);
	return data;
};

export const getApplicationBackupData = async (header: AuthHeader, application: String, restoreDate: string) => {
	const data: AxiosResponse<ApplicationBackupData, AuthHeader> = await axios.get(
		`/application/${application}/restore/${restoreDate}`,
		{
			...header,
		}
	);
	return data;
};

export const restoreApplicationData = async (header: AuthHeader, application: String, restoreDate: string) => {
	const data: AxiosResponse<ApplicationRestoreRespose, AuthHeader> = await axios.post(
		"/application/restore",
		{
			appId: application,
			date: restoreDate,
		},
		{
			...header,
		}
	);
	return data;
};

export const restoreApplicationMarketData = async (header: AuthHeader, application: String,market:string | undefined, backupName: string | undefined,
	) => {
	const data: AxiosResponse<ApplicationRestoreRespose, AuthHeader> = await axios.post(
		"/application/restore",
		{
			appId: application,
			backupName: backupName,
			market: market
		},
		{
			...header,
		}
	);
	return data;
};

export const getBackupNames = async (header: AuthHeader, application: String, market: string) => {
	const data: AxiosResponse<MarketBackupListResponse, AuthHeader> = await axios.get(
		`/application/${application}/market/${market}/backups`,
		{
			...header,
		}
	);
	return data;
};

export const backupApplicationData = async (
	header: AuthHeader,
	application: String,
	market?: string,
	backupName?: string
) => {
	const backupReqBody: { appId: String; market?: String; backupName?: String } = {
		appId: application,
	};
	if (market) {
		backupReqBody.market = market;
		if (backupName) {
			backupReqBody.backupName = backupName;
		}
	}
	const data: AxiosResponse<ApplicationRestoreRespose, AuthHeader> = await axios.post(
		"/application/backup",
		backupReqBody,
		{
			...header,
		}
	);
	return data;
};
