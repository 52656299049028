/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useCallback } from "react";
import _ from "lodash/fp";

import InkgaCheckbox from "@ingka/checkbox";
import { default as IngkaSelect, Option as SelectOption } from "@ingka/select";
import { OverflowCarousel } from "@ingka/carousel";
import styled from "styled-components";
import SSRIcon from "@ingka/ssr-icon";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import Switch from "@ingka/switch";
import Button from "@ingka/button";
import InputField from "@ingka/input-field";
import {
	colourSemanticNegative,
	colourStaticDarkGrey,
	colourStaticGrey,
	colourStaticLightGrey,
	colourTextAndIcon5,
} from "@ingka/variables/colours-css";
import ProductIdentifier from "@ingka/product-identifier";
import plusSmall from "@ingka/ssr-icon/paths/plus-small";
import crossSmall from "@ingka/ssr-icon/paths/cross-small";
import FormField from "@ingka/form-field";
import InlineMessage, { InlineMessageProps } from "@ingka/inline-message";

import MuiSelect from "@mui/material/Select";
import { ListItemText, MenuItem, OutlinedInput } from "@mui/material";

import { MultiSelectStyles } from "../../../styles";
import { Schema, SchemaMenuOption } from "../../utils/types";
import * as helpers from "../../utils/helpers";
import { Input } from "../Input";
import { Checkbox } from "../Checkbox";
import { Label } from "../Label";
interface ItemProps {
	onDelete: () => void;
	children: React.ReactNode;
	isEnabled: boolean;
	label?: string;
}

interface ItemWrapperprops {
	isEnabled?: boolean;
}
interface Asdds extends ItemWrapperprops, InlineMessageProps {}

const StyledInlineMessage = styled(InlineMessage)<Asdds>`
	border-top: 1px solid ${colourStaticGrey};
	border-right: 1px solid ${colourStaticGrey};
	border-bottom: 1px solid ${colourStaticGrey};
	${({ isEnabled }) =>
		isEnabled
			? "border-inline-start-color: rgb(0, 88, 163)"
			: `border-inline-start-color: ${colourStaticDarkGrey}; background-color: ${colourStaticLightGrey}; border-right: 1px solid ${colourStaticGrey}`};
`;

const HeadingWrapper = styled.div<ItemWrapperprops>`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
`;

const H6 = styled.h6`
	margin: 0;
`;

const Item: React.FunctionComponent<ItemProps> = ({ children, onDelete, isEnabled, label }) => {
	return (
		<StyledInlineMessage
			isEnabled={isEnabled}
			title={
				<HeadingWrapper isEnabled={isEnabled}>
					<Switch id={"switch"} value={"switc"} onChange={onDelete} checked={isEnabled} />
					<H6>{label}</H6>
				</HeadingWrapper>
			}
			body={<>{children}</>}
		/>
	);
};

type Props = {
	field: SchemaMenuOption;
	schema: Schema;
	onChange: (schema: Schema) => void;
	isEnabled: boolean;
};

export const TextItem: React.FunctionComponent<Props> = ({ field, schema, onChange, isEnabled }: Props) => {
	return (
		<Item
			onDelete={() =>
				isEnabled
					? onChange(helpers.deleteSchemaField(field.value, schema))
					: onChange(helpers.setSchemaField(field.value, undefined, schema))
			}
			isEnabled={isEnabled}
			label={field?.label}
		>
			<Input
				value={helpers.getSchemaField(field.value, schema) as string}
				onChange={(text) => onChange(helpers.setSchemaField(field.value, text, schema))}
				disabled={!isEnabled}
				placeholder={field?.label ? `Please provide ${field?.label.toLowerCase()}` : ""}
			/>
		</Item>
	);
};

export const NumberItem: React.FunctionComponent<Props> = ({ field, schema, onChange, isEnabled }: Props) => {
	return (
		<Item
			onDelete={() =>
				isEnabled
					? onChange(helpers.deleteSchemaField(field.value, schema))
					: onChange(helpers.setSchemaField(field.value, undefined, schema))
			}
			isEnabled={isEnabled}
			label={field?.label}
		>
			<Input
				type={"number"}
				value={helpers.getSchemaField(field.value, schema) as string}
				onChange={(text) => {
					onChange(helpers.setSchemaField(field.value, text === "" ? 0 : parseInt(text, 10), schema));
				}}
				disabled={!isEnabled}
				placeholder={
					field?.label
						? `Please provide ${field?.label.toLowerCase()} ${field?.label.includes("Value") ? "" : "value"}`
						: ""
				}
			/>
		</Item>
	);
};

export const BoolItem: React.FunctionComponent<Props> = ({ field, schema, onChange, isEnabled }: Props) => {
	return (
		<Item
			onDelete={() =>
				isEnabled
					? onChange(helpers.deleteSchemaField(field.value, schema))
					: onChange(helpers.setSchemaField(field.value, false, schema))
			}
			isEnabled={isEnabled}
			label={field?.label}
		>
			<Checkbox
				value={(helpers.getSchemaField(field.value, schema) as boolean) || false}
				onChange={(text) => onChange(helpers.setSchemaField(field.value, text, schema))}
				disabled={!isEnabled}
				placeholder={field?.label ? `Please provide ${field?.label.toLowerCase()}` : ""}
			/>
		</Item>
	);
};

const FlexWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

const OptionWrapper = styled.div`
	display: flex;
	gap: 0.25rem;
	flex-wrap: wrap;
`;

const IconWrapper = styled.div`
	background-color: ${colourStaticLightGrey};
	height: 1.4rem;
	border-left: 1px solid ${colourStaticGrey};
	cursor: pointer;
	display: flex;
	align-items: center;
	:hover {
		background-color: ${colourSemanticNegative};
		path:first-child {
			fill: ${colourTextAndIcon5};
		}
	}
`;

const InputWrapper = styled.div`
	width: 100%;
`;

const AddButtonWrapper = styled.div`
	margin-bottom: 1.25rem;
`;

export const CreatableMultiSelectItem: React.FunctionComponent<Props> = ({
	field,
	schema,
	onChange,
	isEnabled,
}: Props) => {
	const selected = React.useMemo(() => helpers.getSchemaField(field.value, schema) || [], [field.value, schema]);

	const [currentData, setCurrentData] = React.useState<string>("");

	const onAdd = useCallback(() => {
		selected &&
			!(selected as string[])?.includes(currentData) &&
			currentData &&
			onChange(helpers.setSchemaField(field.value, [...(selected as string[]), currentData], schema));
		!(selected as string[])?.includes(currentData) && currentData && setCurrentData("");
	}, [currentData, field.value, onChange, schema, selected]);

	const onRemove = useCallback(
		(value: string) => {
			onChange(helpers.setSchemaField(field.value, _.difference(selected as string[], [value]), schema));
		},
		[field.value, onChange, schema, selected]
	);

	return (
		<Item
			onDelete={() =>
				isEnabled
					? onChange(helpers.deleteSchemaField(field.value, schema))
					: onChange(helpers.setSchemaField(field.value, undefined, schema))
			}
			isEnabled={isEnabled}
			label={field?.label}
		>
			<div>
				<FlexWrapper>
					<InputWrapper>
						<FormField>
							<InputField
								id={field.label}
								type="text"
								value={currentData}
								onChange={(e) => {
									setCurrentData(e.target.value);
								}}
								disabled={!isEnabled}
							/>
						</FormField>
					</InputWrapper>
					<AddButtonWrapper>
						<Button small iconOnly ssrIcon={plusSmall} onClick={onAdd} disabled={!isEnabled} />
					</AddButtonWrapper>
				</FlexWrapper>

				<OptionWrapper>
					{(selected as string[])?.length
						? (selected as string[]).map((val: any) => {
								return (
									<div key={val} style={{ display: "flex" }}>
										<ProductIdentifier key={val} subtle value={val} />
										<IconWrapper onClick={() => onRemove(val)}>
											<SSRIcon paths={crossSmall} color={colourStaticDarkGrey} />
										</IconWrapper>
									</div>
								);
						  })
						: null}
				</OptionWrapper>
			</div>
		</Item>
	);
};

interface SelectedOption {
	value: string;
	label: string;
}

const SelectItemWrapper = styled.div`
	width: 100%;
`;

export const SelectItem: React.FunctionComponent<Props> = ({ field, schema, onChange, isEnabled }: Props) => {
	const options = field.optionList;

	const option = helpers.getSchemaField(field.value, schema);

	const selected = React.useMemo(() => helpers.findOption(option as string)(options), [options, option]);

	return (
		<Item
			onDelete={() =>
				isEnabled
					? onChange(helpers.deleteSchemaField(field.value, schema))
					: onChange(helpers.setSchemaField(field.value, undefined, schema))
			}
			isEnabled={isEnabled}
			label={field?.label}
		>
			<SelectItemWrapper>
				<IngkaSelect
					id={field.value}
					value={(selected as unknown as SelectedOption)?.value}
					onChange={(e: any) => {
						onChange(helpers.setSchemaField(field.value, e.target.value, schema));
					}}
					disabled={!isEnabled}
				>
					{options.map((data: SelectedOption) => (
						<SelectOption id={data.value} value={data.value} name={data.label} />
					))}
				</IngkaSelect>
			</SelectItemWrapper>
		</Item>
	);
};

const SelectValueItemWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: rgba(var(--colour-static-ikea-family, 223, 223, 223, 1));
	border-radius: 12px;
	padding: 0 0.5rem 0 0.5rem;
	min-width: 3.4rem;
	margin-right: 0.25rem;
`;

const StyledSSRIcon = styled(SSRIcon)`
	margin-right: 0.7rem;
`;

const MultiSelectWrapper = styled.div`
	width: 100%;
`;

export const RequiredMultiSelectItem: React.FunctionComponent<Props> = ({ field, schema, onChange, isEnabled }) => {
	const requiredFields = schema?.required as string[];
	const [selectedOptions, setSelectedOptions] = React.useState<string[]>(requiredFields ? requiredFields : []);

	if (!helpers.isSchemaObject(schema) || !helpers.hasSchemaProperties(schema)) return null;

	const allOptions = helpers.schemaPropertiesAsOptions(schema);

	return (
		<Item
			onDelete={() =>
				isEnabled
					? onChange(helpers.deleteSchemaField(field.value, schema))
					: onChange(helpers.setSchemaField(field.value, undefined, schema))
			}
			isEnabled={isEnabled}
			label={field?.label}
		>
			<MultiSelectWrapper>
				<Label disabled={!isEnabled}>{field.label}</Label>
				<MuiSelect
					label={field.label}
					displayEmpty
					multiple
					renderValue={(selected: string[]) => {
						return selected?.length ? (
							<OverflowCarousel hasNoControls>
								{selected.map((data: string) => (
									<SelectValueItemWrapper>
										<span>{data}</span>
									</SelectValueItemWrapper>
								))}
							</OverflowCarousel>
						) : (
							<MenuItem key="multi-select-placeholder" disabled sx={{ padding: 0 }} value="">
								Choose an option
							</MenuItem>
						);
					}}
					value={selectedOptions}
					onChange={({ target: { value } }) => {
						setSelectedOptions(value as string[]);
						onChange(helpers.setSchemaField(field.value, value, schema));
					}}
					input={<OutlinedInput />}
					style={{ width: "100%", height: "3rem" }}
					sx={{
						...MultiSelectStyles,
					}}
					IconComponent={() => <StyledSSRIcon paths={chevronDownSmall} viewBox="0 0 21 21" />}
					MenuProps={{
						PaperProps: {
							sx: {
								maxHeight: "20rem",
							},
						},
					}}
					inputProps={{ placeholder: "Choose an option" }}
					disabled={!isEnabled}
				>
					{allOptions?.map((data: any) => (
						<MenuItem disableRipple key={data.value as string} value={data.value as string}>
							<ListItemText primary={data.value} />
							<InkgaCheckbox
								id={data.value as string}
								checked={selectedOptions?.some((field: any) => field === data.value)}
								value={data.value as string}
								readOnly
							/>
						</MenuItem>
					))}
				</MuiSelect>
			</MultiSelectWrapper>
		</Item>
	);
};
