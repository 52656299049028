import * as React from "react";

import useGetMarkets from "../../hooks/useGetMarkets";
import useAuthentication from "../../hooks/useAuthentication";
import { useGetCountryName } from "../../hooks/useGetCountryName";
import useStore, { activeApplicationSelector, setMarketsSelector } from "../../store";
import useForm from "./useForm";
import { updateMarketData, MarketName } from "./utils";
import Layout from "./Layout";
import { getMarketData, removeMarketsData, copyMarketValuesData } from "./utils";
import { sortMarketUsers } from "../MarketFeaturesOverviewTable/utils";

const ManageMarket: React.FC = () => {
	const activeApplication = useStore(activeApplicationSelector);
	const { data, loading: marketsLoading, getMarketsData } = useGetMarkets();
	const { getCountryName } = useGetCountryName();

	const [marketData, setMarketData] = React.useState<string[] | undefined>(undefined);
	const [marketNameFromData, setMarketNameFromData] = React.useState<MarketName[] | undefined>(undefined);
	const [marketsNameToData, setMarketsNameToData] = React.useState<MarketName[] | undefined>(undefined);
	const setMarkets = useStore(setMarketsSelector);
	const [toastMessage, setToastMessage] = React.useState("");
	const [showAddNewMarketModal, setShowAddNewMarketModal] = React.useState(false);
	const [showRemoveMarketsModal, setShowRemoveMarketsModal] = React.useState(false);
	const [selectedMarkets, setSelectedMarkets] = React.useState<string[] | undefined>(undefined);
	const [showCopyMarketValueModal, setShowCopyMarketValueModal] = React.useState(false);
	const [selectedMarketCopyValueFrom, setSelectedMarketCopyValueFrom] = React.useState<string>("");
	const [selectedMarketsCopyValueTo, setSelectedMarketsCopyValueTo] = React.useState<string[]>([]);
	const [copyingMarketValues, setCopyingMarketValues] = React.useState(false);

	React.useEffect(() => {
		if (activeApplication?.id) {
			getMarketsData(activeApplication?.id);
		}
	}, [activeApplication?.id, getMarketsData]);

	React.useEffect(() => {
		if (data) {
			setMarketData(data.permittedMarkets);
		}
	}, [data]);

	React.useEffect(() => {
		if (marketData) {
			let marketFullData = marketData?.map((market) => {
				return {
					key: market,
					name: getCountryName(market),
				};
			});

			marketFullData.sort(sortMarketUsers);
			setMarketNameFromData(marketFullData);
			setMarketsNameToData(marketFullData);
		}
	}, [marketData, getCountryName]);

	React.useEffect(() => {
		if (selectedMarketCopyValueFrom) {
			setSelectedMarketsCopyValueTo([]);
			setMarketsNameToData(marketNameFromData?.filter((market) => market.key !== selectedMarketCopyValueFrom));
		}
	}, [marketNameFromData, selectedMarketCopyValueFrom, setSelectedMarketCopyValueFrom]);

	const [btnLoading, setBtnLoading] = React.useState<boolean>(false);

	const { value, setValue, valid, shouldValidate, setValid, validation, handleChange, setShouldValidate } =
		useForm(marketData);
	const { getToken, handleLogout } = useAuthentication();

	const onAddMarket = React.useCallback(
		async (e) => {
			e.preventDefault();
			if (value === "") {
				setValid(false);
			} else {
				if (activeApplication) {
					try {
						setBtnLoading(true);
						const updatedData = await updateMarketData({
							value,
							activeApplicationId: activeApplication.id,
							getToken,
							handleLogout,
							setToastMessage,
						});
						setMarketData(updatedData?.data.markets.permittedMarkets);
						setMarkets({
							otherMarkets: updatedData?.data.markets?.otherMarkets?.length
								? updatedData.data.markets?.otherMarkets
								: undefined,
							permittedMarkets: updatedData?.data.markets.permittedMarkets.length
								? updatedData.data.markets.permittedMarkets
								: undefined,
							isAdmin: updatedData?.data.admin || false,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
						});
						setValue("");
						setShouldValidate(false);
						setToastMessage("Successfully added new market");
					} catch (err: any) {
						setToastMessage(err.message);
						console.log(err);
					} finally {
						setBtnLoading(false);
						setShowAddNewMarketModal(false);
					}
				}
			}
		},
		[activeApplication, getToken, handleLogout, setMarkets, setShouldValidate, setValid, setValue, value]
	);

	const onRemoveMarkets = React.useCallback(async () => {
		try {
			if (activeApplication && selectedMarkets) {
				setBtnLoading(true);
				await removeMarketsData({ markets: selectedMarkets.toString(), application: activeApplication?.id, getToken });
				const marketData = await getMarketData({ application: activeApplication?.id, getToken });
				if (marketData?.data.markets.permittedMarkets) {
					setMarketData(marketData?.data.markets.permittedMarkets);
				} else {
					throw new Error("no markets data found");
				}
				setMarkets({
					otherMarkets: marketData?.data.markets?.otherMarkets?.length
						? marketData.data.markets?.otherMarkets
						: undefined,
					permittedMarkets: marketData?.data.markets.permittedMarkets.length
						? marketData.data.markets.permittedMarkets
						: undefined,
					isAdmin: marketData?.data.admin || false,
				});
				setToastMessage("Successfully removed the Market(s)");
				setSelectedMarkets([]);
			}
		} catch (err: any) {
			setToastMessage(err.message);
		} finally {
			setBtnLoading(false);
			setShowRemoveMarketsModal(false);
		}
	}, [activeApplication, getToken, setMarketData, setMarkets, selectedMarkets]);

	const onCopyMarketValues = React.useCallback(async () => {
		try {
			if (activeApplication) {
				setCopyingMarketValues(true);
				const response = await copyMarketValuesData({
					marketFrom: selectedMarketCopyValueFrom,
					marketsTo: selectedMarketsCopyValueTo.toString(),
					application: activeApplication?.id,
					getToken,
				});
				setToastMessage(response?.message || "Action was succesfully done");
			}
		} catch (err: any) {
			setToastMessage(err.message);
		} finally {
			setCopyingMarketValues(false);
			setShowCopyMarketValueModal(false);
			setSelectedMarketCopyValueFrom("");
			setSelectedMarketsCopyValueTo([]);
		}
	}, [activeApplication, selectedMarketCopyValueFrom, selectedMarketsCopyValueTo, getToken]);

	return (
		<Layout
			activeApplication={activeApplication}
			btnLoading={btnLoading}
			marketData={marketData}
			marketsLoading={marketsLoading}
			onAddMarket={onAddMarket}
			valid={valid}
			shouldValidate={shouldValidate}
			validation={validation}
			handleChange={handleChange}
			value={value}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			getCountryName={getCountryName}
			showAddNewMarketModal={showAddNewMarketModal}
			setShowAddNewMarketModal={setShowAddNewMarketModal}
			setValue={setValue}
			setShouldValidate={setShouldValidate}
			onRemoveMarkets={onRemoveMarkets}
			setSelectedMarkets={setSelectedMarkets}
			selectedMarkets={selectedMarkets}
			showRemoveMarketsModal={showRemoveMarketsModal}
			setShowRemoveMarketsModal={setShowRemoveMarketsModal}
			showCopyMarketValueModal={showCopyMarketValueModal}
			setShowCopyMarketValueModal={setShowCopyMarketValueModal}
			marketNameFromData={marketNameFromData}
			marketsNameToData={marketsNameToData}
			selectedMarketCopyValueFrom={selectedMarketCopyValueFrom}
			setSelectedMarketCopyValueFrom={setSelectedMarketCopyValueFrom}
			selectedMarketsCopyValueTo={selectedMarketsCopyValueTo}
			setSelectedMarketsCopyValueTo={setSelectedMarketsCopyValueTo}
			onCopyMarketValues={onCopyMarketValues}
			copyingMarketValues={copyingMarketValues}
		/>
	);
};

export default ManageMarket;
