import * as React from "react";

import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import SSRIcon from "@ingka/ssr-icon";
import { OverflowCarousel } from "@ingka/carousel";
import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { MultiSelectStyles, StyledModalHeader } from "../styles";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import ListItemText from "@mui/material/ListItemText";
import { getImage } from "../../utils/markets";
import Checkbox from "@mui/material/Checkbox";
import { MarketName } from "./utils";

import styled from "styled-components";

const StyledSSRIcon = styled(SSRIcon)`
	margin-right: 0.7rem;
`;

const StyledImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 10px;
`;

const StyledMuiSelectLabel = styled.label`
	font-size: 0.875rem;
	line-height: 1.571;
	margin: 0rem 0rem 0.125rem 0rem;
`;

const StyledMuiHelperText = styled.p`
	font-size: 0.75rem;
	line-height: 1.5;
	color: rgb(var(--colour-text-and-icon-3, 118, 118, 118));
	margin-top: 0.25rem;
	margin-bottom: 1.5rem !important;
`;

const StyledMuiSelectToMarketLabel = styled.label`
	font-size: 0.875rem;
	line-height: 1.571;
	margin: 2rem 2rem 0.125rem 0;
`;

const SelectValueItemWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0.5rem 0 0.5rem;
	max-width: fit-content;
	margin-right: 0.25rem;
`;

const MultiSelectValueItemWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: rgba(var(--colour-static-ikea-family, 223, 223, 223, 1));
	border-radius: 12px;
	padding: 0 0.5rem 0 0.5rem;
	min-width: 3.4rem;
	margin-right: 0.25rem;
`;

const StyledPrompt = styled(Prompt)`
	width: 30rem;
`;

const StyledDiv = styled.div`
	padding-top: 1rem;
`;

interface CopyMarketValueModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	marketNameFromData?: MarketName[];
	marketsNameToData?: MarketName[];
	selectedMarketCopyValueFrom: string;
	setSelectedMarketCopyValueFrom: (value: string) => void;
	selectedMarketsCopyValueTo: string[];
	setSelectedMarketsCopyValueTo: (value: string[]) => void;
	onCopyMarketValues: () => void;
	copyingMarketValues: boolean;
}

const CopyMarketValueModal: React.FC<CopyMarketValueModalProps> = ({
	showModal,
	setShowModal,
	marketNameFromData,
	marketsNameToData,
	selectedMarketCopyValueFrom,
	setSelectedMarketCopyValueFrom,
	selectedMarketsCopyValueTo,
	setSelectedMarketsCopyValueTo,
	onCopyMarketValues,
	copyingMarketValues,
}) => {
	const handleChangeMarketValueFrom = React.useCallback(
		(event: SelectChangeEvent<typeof selectedMarketCopyValueFrom>) => {
			const {
				target: { value },
			} = event;
			setSelectedMarketCopyValueFrom(value);
		},
		[setSelectedMarketCopyValueFrom]
	);

	const handleChangeMarketValueTo = React.useCallback(
		(event: SelectChangeEvent<typeof selectedMarketsCopyValueTo>) => {
			const {
				target: { value },
			} = event;

			setSelectedMarketsCopyValueTo(typeof value === "string" ? value.split(",") : value);
		},
		[setSelectedMarketsCopyValueTo]
	);

	return (
		<Modal
			focusLockProps={{ locked: false }}
			visible={showModal}
			escapable={true}
			handleCloseBtn={() => {
				setShowModal(false);
				setSelectedMarketCopyValueFrom("");
				setSelectedMarketsCopyValueTo([]);
			}}
		>
			<StyledPrompt
				title=""
				titleId={"modalTitleId"}
				header={
					<StyledModalHeader
						id="copy-values-modal-header"
						ariaCloseTxt="Close button text"
						title="Copy Market Values"
					/>
				}
				footer={
					<ModalFooter>
						<Button
							data-testid="copyvaluesbutton"
							text="Copy values"
							loading={copyingMarketValues}
							disabled={!selectedMarketCopyValueFrom || selectedMarketsCopyValueTo.length === 0}
							type="emphasised"
							onClick={() => onCopyMarketValues()}
						/>
					</ModalFooter>
				}
			>
				<StyledDiv>
					<StyledMuiSelectLabel>Select Market from</StyledMuiSelectLabel>
					<MuiSelect
						labelId="select-market-checkbox-label"
						id="select-market-from-multiple"
						displayEmpty
						renderValue={(selectedMarket: string) => {
							if (!selectedMarket) {
								return (
									<MenuItem key="multi-select-placeholder" disabled sx={{ padding: 0 }} value="">
										Choose an option
									</MenuItem>
								);
							}
							return (
								<SelectValueItemWrapper>
									<StyledImage src={getImage(selectedMarket) || getImage("xx")} />
									<ListItemText
										primary={`${
											marketNameFromData?.find((market) => market.key === selectedMarket)?.name
										} (${selectedMarket})`}
									/>
								</SelectValueItemWrapper>
							);
						}}
						value={selectedMarketCopyValueFrom}
						onChange={handleChangeMarketValueFrom}
						input={<OutlinedInput />}
						style={{ width: "100%", height: "3rem" }}
						sx={{
							...MultiSelectStyles,
						}}
						IconComponent={() => <StyledSSRIcon paths={chevronDownSmall} viewBox="0 0 21 21" />}
						MenuProps={{
							PaperProps: {
								sx: {
									maxHeight: "20rem",
								},
							},
						}}
						inputProps={{ placeholder: "Choose an option" }}
					>
						{marketNameFromData &&
							Object.values(marketNameFromData).map((market) => (
								<MenuItem disableRipple key={market.key} value={market.key}>
									<StyledImage src={getImage(market.key) || getImage("xx")} />
									<ListItemText key={market.key} primary={`${market.name} (${market.key})`} />
								</MenuItem>
							))}
					</MuiSelect>
					<StyledMuiHelperText>Choose the market from which you would like to transfer the value.</StyledMuiHelperText>
					<StyledMuiSelectToMarketLabel>Select Market to</StyledMuiSelectToMarketLabel>
					<MuiSelect
						labelId="select-market-checkbox-label"
						id="select-markets-to-multiple"
						displayEmpty
						multiple
						renderValue={(selectedMarkets: string[]) => {
							if (selectedMarkets.length === 0) {
								return (
									<MenuItem key="multi-select-placeholder" disabled sx={{ padding: 0 }} value="">
										Choose an option
									</MenuItem>
								);
							}

							return (
								<OverflowCarousel hasNoControls>
									{selectedMarkets.map((market: string) => (
										<MultiSelectValueItemWrapper>
											<StyledImage src={getImage(market) || getImage("xx")} />
											<span>{market}</span>
										</MultiSelectValueItemWrapper>
									))}
								</OverflowCarousel>
							);
						}}
						value={selectedMarketsCopyValueTo}
						onChange={handleChangeMarketValueTo}
						input={<OutlinedInput />}
						style={{ width: "100%", height: "3rem" }}
						sx={{
							...MultiSelectStyles,
						}}
						IconComponent={() => <StyledSSRIcon paths={chevronDownSmall} viewBox="0 0 21 21" />}
						MenuProps={{
							PaperProps: {
								sx: {
									maxHeight: "20rem",
								},
							},
						}}
						inputProps={{ placeholder: "Choose an option" }}
					>
						{marketsNameToData &&
							Object.values(marketsNameToData).map((market) => (
								<MenuItem disableRipple key={market.key} value={market.key}>
									<StyledImage src={getImage(market.key) || getImage("xx")} />
									<ListItemText primary={`${market.name} (${market.key})`} />
									<Checkbox checked={selectedMarketsCopyValueTo.indexOf(market.key) > -1} />
								</MenuItem>
							))}
					</MuiSelect>
					<StyledMuiHelperText>Choose the market to which you would like to transfer the value.</StyledMuiHelperText>
				</StyledDiv>
			</StyledPrompt>
		</Modal>
	);
};

export default CopyMarketValueModal;
