import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import React, { useState } from "react";

import { useExportSchema, useExportUiSchema } from "../util/hooks";

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			flexGrow: 1,
		},
	})
);

export const Header: React.FC = () => {
	const classes = useStyles();
	const schema = useExportSchema();
	const uiSchema = useExportUiSchema();

	return (
		<h2>UI Schema</h2>
	);
};
