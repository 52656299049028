import React from "react";
import { useNavigate } from "react-router-dom";

import Layout from "./Layout";
import { ROUTE_HOME, isInMaintenanceMode } from "../../utils/constants";

const Maintenance = () => {
	const navigate = useNavigate();

	React.useEffect(() => {
		!isInMaintenanceMode && navigate(ROUTE_HOME);
	}, [navigate]);

	return <Layout />;
};

export default Maintenance;
