import * as React from "react";
import Hyperlink from "@ingka/hyperlink";
import Button from "@ingka/button";
import Toast from "@ingka/toast";

import styled from "styled-components";

import feedbackDissatisfied from "@ingka/ssr-icon/paths/feedback-dissatisfied";
import feedbackHappy from "@ingka/ssr-icon/paths/feedback-happy";
import feedbackNeutral from "@ingka/ssr-icon/paths/feedback-neutral";
import feedbackSad from "@ingka/ssr-icon/paths/feedback-sad";
import feedbackSatisfied from "@ingka/ssr-icon/paths/feedback-satisfied";

import Modal, { ModalFooter, Prompt } from "@ingka/modal";
import AddFeedbackModal from "./AddFeedbackModal";
import { StyledModalHeader } from "../styles";

const FeedbackWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 0.3rem;
`;

const HyperLinkWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 0.7rem;
	flex-wrap: wrap;
	margin-bottom: 2rem;
`;

const StyledModal = styled(Modal)`
	.prompt {
		width: 25rem;
	}

	.modal-footer {
		padding: 1rem;
	}
`;

interface FeedbackLayoutProps {
	showFeedbackModal: boolean;
	setShowFeedbackModal: (val: boolean) => void;
	showAddFeedbackModal: boolean;
	setShowAddFeedbackModal: (val: boolean) => void;
	sendFeedback: () => void;
	setFeedbackScore: (val: number) => void;
	setFeedbackPage: (val: string) => void;
	feedbackText: string;
	setFeedbackText: (val: string) => void;
	sendFeedbackLoading: boolean;
	toastMessage: string;
	setToastMessage: (val: string) => void;
	isAdmin: boolean;
	setShowModalFromFloatingMenu?: (val: boolean) => void;
	showRating: boolean;
}

const Layout: React.FC<FeedbackLayoutProps> = ({
	showFeedbackModal,
	setShowFeedbackModal,
	showAddFeedbackModal,
	setShowAddFeedbackModal,
	sendFeedback,
	setFeedbackScore,
	setFeedbackPage,
	feedbackText,
	setFeedbackText,
	sendFeedbackLoading,
	toastMessage,
	setToastMessage,
	isAdmin,
	setShowModalFromFloatingMenu,
	showRating,
}) => {
	const aHyperLinksText = isAdmin
		? ["Onboarding", "Configuration", "Market", "Schema & UI Schema", "Dashboard", "Other"]
		: ["Onboarding", "Configuration", "Dashboard", "Other"];
	const aFeedbackButtons = [
		{
			title: "Sad",
			icon: feedbackSad,
		},
		{
			title: "Dissatisfied",
			icon: feedbackDissatisfied,
		},
		{
			title: "Neutral",
			icon: feedbackNeutral,
		},
		{
			title: "Satisfied",
			icon: feedbackSatisfied,
		},
		{
			title: "Happy",
			icon: feedbackHappy,
		},
	];

	return (
		<>
			<FeedbackWrapper>
				{showRating &&
					aFeedbackButtons.map((feedbackButton, index) => (
						<Button
							iconOnly
							ssrIcon={feedbackButton["icon"]}
							title={feedbackButton["title"]}
							type="tertiary"
							small
							onClick={() => {
								setFeedbackScore(index + 1);
								setShowFeedbackModal(true);
							}}
						/>
					))}
			</FeedbackWrapper>
			<StyledModal visible={showFeedbackModal} escapable={true} handleCloseBtn={() => setShowFeedbackModal(false)}>
				<Prompt 
					title="" 
					titleId={"modalTitleId"} 
					footer={null} 
					header={
						<StyledModalHeader title="Help us, what can we do better?" />
					}
				>
					<HyperLinkWrapper>
						{aHyperLinksText.map((linkText) => (
							<Hyperlink
								onClick={() => {
									setFeedbackPage(linkText);
									setShowFeedbackModal(false);
									setShowAddFeedbackModal(true);
								}}
								text={linkText}
							/>
						))}
					</HyperLinkWrapper>
				</Prompt>
			</StyledModal>
			<AddFeedbackModal
				showAddFeedbackModal={showAddFeedbackModal}
				setShowAddFeedbackModal={setShowAddFeedbackModal}
				sendFeedback={sendFeedback}
				feedbackText={feedbackText}
				setFeedbackText={setFeedbackText}
				sendFeedbackLoading={sendFeedbackLoading}
				setShowModalFromFloatingMenu={setShowModalFromFloatingMenu}
			/>
			<Toast
				data-testid="message"
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default Layout;
