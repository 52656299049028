import React from "react";
import styled from "styled-components";

import { Step, StepLabel, Stepper } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

import Button from "@ingka/button";
import { colourSemanticInformative } from "@ingka/variables/colours-css";
import arrowRight from "@ingka/ssr-icon/paths/arrow-right";
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left";
import Toast from "@ingka/toast";

import { CreateNewApplicationSteps } from "./CreateNewApplication";

const StyledConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: colourSemanticInformative,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: colourSemanticInformative,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderTopWidth: 3,
	},
}));

const ContentLayout = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1rem;
	padding: 1rem;
	position: relative;
`;

const BackButton = styled(Button)`
	position: absolute;
	bottom: 0;
	left: 0;
`;
const SkipButton = styled(Button)`
	position: absolute;
	bottom: 0;
	right: 0;
`;

interface CreateNewApplicationLayoutProps {
	steps: CreateNewApplicationSteps[];
	activeStep: number;
	onClickNext: () => void;
	isFinalStep: boolean;
	onBack: () => void;
	userLoading: boolean;
	loadingCreateNewApp: boolean;
	toast: string;
	setToast: (str: string) => void;
}

const Layout: React.FC<CreateNewApplicationLayoutProps> = ({
	steps,
	activeStep,
	onClickNext,
	isFinalStep,
	onBack,
	userLoading,
	loadingCreateNewApp,
	toast,
	setToast,
}) => {
	return (
		<>
			<Stepper
				sx={{
					"& .MuiStepLabel-iconContainer .Mui-active": {
						color: colourSemanticInformative,
					},
					"& .MuiStepLabel-iconContainer .Mui-completed": {
						color: colourSemanticInformative,
					},
				}}
				connector={<StyledConnector />}
				activeStep={activeStep}
				alternativeLabel
			>
				{steps.map(({ id, label }) => (
					<Step key={id} disabled={loadingCreateNewApp || userLoading}>
						<StepLabel
							sx={{
								"& .MuiStepLabel-labelContainer .Mui-active": { fontWeight: 700 },
							}}
						>
							{<p>{label}</p>}
						</StepLabel>
					</Step>
				))}
			</Stepper>
			<ContentLayout>
				<>
					{steps[activeStep].element}
					<Button
						type="emphasised"
						text={isFinalStep ? "Send Request" : "Next"}
						ssrIcon={arrowRight}
						iconPosition="trailing"
						onClick={onClickNext}
						disabled={!steps[activeStep].isValid}
						loading={userLoading || loadingCreateNewApp}
					/>
				</>
				{steps[activeStep].skipable && (
					<SkipButton
						text="Skip"
						ssrIcon={arrowRight}
						iconPosition="trailing"
						type="plain"
						disabled={loadingCreateNewApp || userLoading}
						onClick={onClickNext}
					/>
				)}

				{activeStep !== 0 && (
					<BackButton
						text="Back"
						type="plain"
						ssrIcon={arrowLeft}
						onClick={onBack}
						disabled={loadingCreateNewApp || userLoading}
					/>
				)}
			</ContentLayout>
			<Toast
				text={toast}
				isOpen={!!toast}
				ariaLabelCloseBtn="Dismiss notification"
				onCloseRequest={() => setToast("")}
			/>
		</>
	);
};

export default Layout;
