import Choice, { ChoiceItem } from "@ingka/choice";
import React from "react";
import styled from "styled-components";
import { RoleData } from "../RequestNewRoleModal";
import { UserPermissions } from "../../../utils/constants";

interface MaintainerRoleSelctorLayoutProps {
	rolesData: RoleData[];
	setSelectedRole: (role: UserPermissions | null) => void;
	selectedRole: UserPermissions | null;
	requestingRole: boolean;
}

const ChoiceWrapper = styled.div`
	.choice {
		display: flex;
		gap: 0.5rem;
	}
	.choice__list-item {
		width: 50%;
	}
`;
const StyledChoiceItem = styled(ChoiceItem)`
	min-height: 10rem;
	max-height: 10rem;
`;

const Layout: React.FC<MaintainerRoleSelctorLayoutProps> = ({
	rolesData,
	setSelectedRole,
	requestingRole,
	selectedRole,
}) => {
	return (
		<ChoiceWrapper>
			<Choice deselectable onSelect={(e) => setSelectedRole(e)}>
				{rolesData?.map((roleData) => (
					<StyledChoiceItem
						disabled={requestingRole}
						title={roleData?.role}
						id={roleData?.id}
						caption={roleData?.description}
						selected={selectedRole === roleData.id}
						data-testid={`choice-role-${roleData?.id}`}
					/>
				))}
			</Choice>
		</ChoiceWrapper>
	);
};

export default Layout;
