import { getMarkets, removeMarkets, updateMarkets, copyMarketValues } from "../../services/markets";

interface UpdateMarket {
	value: string;
	activeApplicationId: string;
	getToken: () => Promise<string>;
	handleLogout: () => void;
	setToastMessage: (val: string) => void;
}

interface RemoveMarket {
	markets: string;
	application: string;
	getToken: () => Promise<string>;
}

interface CopyMarketValues {
	marketFrom: string;
	marketsTo: string;
	application: string;
	getToken: () => Promise<string>;
}

export interface MarketName {
	key: string;
	name: string;
}

export const updateMarketData = async ({
	value,
	activeApplicationId,
	handleLogout,
	getToken,
	setToastMessage,
}: UpdateMarket) => {
	const token = await getToken();
	const authHeader = {
		headers: {
			authorization: `Bearer ${token}`,
		},
	};
	if (activeApplicationId) {
		try {
			await updateMarkets(authHeader, activeApplicationId, value, {});
			const { data } = await getMarkets(authHeader, activeApplicationId);
			return data;
		} catch (error: any) {
			if (error?.response.status === 404) {
				console.log(error);
			}
			throw new Error(error?.response.data.message);
		}
	}
};

export const removeMarketsData = async ({ markets, application, getToken }: RemoveMarket) => {
	const token = await getToken();
	const authHeader = {
		headers: {
			authorization: `Bearer ${token}`,
		},
	};
	if (application) {
		try {
			await removeMarkets(authHeader, application, markets);
		} catch (err: any) {
			const errResponse = err?.response.data;
			throw new Error(errResponse.errors && errResponse.errors[0] && errResponse.errors[0].message ? errResponse.errors[0].message : errResponse.message);
		}
	}
};

export const copyMarketValuesData = async ({ marketFrom, marketsTo, application, getToken }: CopyMarketValues) => {
	const token = await getToken();
	const authHeader = {
		headers: {
			authorization: `Bearer ${token}`,
		},
	};
	if (application) {
		try {
			const { data } = await copyMarketValues(authHeader, marketFrom, marketsTo, application);
			return data;
		} catch (err: any) {
			const errResponse = err?.response.data;
			throw new Error(errResponse.errors && errResponse.errors[0] && errResponse.errors[0].message ? errResponse.errors[0].message : errResponse.message);
		}
	}
};


export const getMarketData = async ({
	application,
	getToken,
}: {
	application: string;
	getToken: () => Promise<string>;
}) => {
	const token = await getToken();
	const authHeader = {
		headers: {
			authorization: `Bearer ${token}`,
		},
	};
	if (application) {
		try {
			const { data } = await getMarkets(authHeader, application);
			return data;
		} catch (err: any) {
			throw new Error(err);
		}
	}
};
