import * as React from "react";
import styled from "styled-components";

import Pill from "@ingka/pill";

import Button from "@ingka/button";

import { Application } from "../../store";
import Status from "@ingka/status";
import Tabs, { Tab } from "@ingka/tabs";
import people from "@ingka/ssr-icon/paths/people";
import magnifyingGlass from "@ingka/ssr-icon/paths/magnifying-glass";
import Avatar from "@ingka/avatar";
import Chart from "../Chart";
import Accordion, { AccordionItem } from "@ingka/accordion";
import documentPencil from "@ingka/ssr-icon/paths/document-pencil";
import text from "@ingka/ssr-icon/paths/text";
import list from "@ingka/ssr-icon/paths/list";
import database from "@ingka/ssr-icon/paths/database";
import Table, { TableBody } from "@ingka/table";
import Skeleton from "@ingka/skeleton";
import InlineMessage from "@ingka/inline-message";
import MarketUsersModal from "./MarketUsersModal/MarketUsersModal";

import { capitalizeFirstLetter } from "./utils";
import Search from "@ingka/search";
import { OverflowCarousel } from "@ingka/carousel";
import { breakpointL, breakpointM } from "@ingka/variables/design-tokens";
import useWindowSize from "../../hooks/useWindowSize";

interface FeaturesTableProps {
	loading: boolean;
	activeApplication?: Application;
	hasGroups?: boolean;
	marketData: any;
	tabsData: any;
	setActiveTab: (data: string) => void;
	tableData?: any[];
	activeMarket: string | null;
	activeTab: string;
	showMarketUsersModal: boolean;
	setShowMarketUsersModal: (value: boolean) => void;
	showSeachBar: boolean;
	setShowSeachBar: (value: boolean) => void;
	searchPropertyValue: string;
	setSearchPropertyValue: (value: string) => void;
	marketDashboardChartsData: any;
	showAdoptionRate: boolean | undefined;
}

const StyledTable = styled(Table)`
	width: 60vw;
	font-size: 0.875rem;
	line-height: 1.5;

	th {
		padding: 0.75rem 1.5rem 0.75rem 0.75rem !important;
	}
	td {
		padding: 0.75rem 0.5rem 0.75rem 0rem !important;
	}

	table {
		width: 60vw;
	}

	@media only screen and (max-width: ${breakpointL}) {
		width: 80vw;

		table {
			width: 80vw;
		}
	} ;
`;

const StyledTableSecondColumnCell = styled.td`
	text-align: right !important;
	width: 30vw;
	overflow-wrap: anywhere;

	.status--leading {
		justify-content: end;
	}
`;

const SecondColumnCellTable = styled.td`
	display: flex;
	width: auto;
	justify-content: end;
`;

const MarketTableWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: ${breakpointM}) {
		width: calc(100vw - 2.5rem);
	} ;
`;

const TableHeader = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
`;

const TableHeaderButtonsWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

const StyledAccordion = styled(Accordion)`
	margin-top: 2rem;
`;

const AccordionItemWrapper = styled.div`
	display: flex;
	position: relative;
	box-shadow: 0px 0px 10px 0px lightgrey;
	margin-bottom: 1.5rem;
	padding-left: 1rem;
	padding-right: 1rem;

	.accordion__item--active > .accordion__content {
		padding-top: 0rem;
		padding-bottom: 2rem;
	}

	.accordion__heading {
		border-top: none !important;
	}

	.accordion-item-header__title {
		text-decoration: none !important;
	}

	.btn {
		margin-top: 0.25rem;
		position: absolute;
		right: 3.5rem;
	}

	#custom-header > .status {
		margin-top: 0.35rem;
		position: absolute;
		right: 10rem;
	}

	#custom-header:not(:has(> .btn)) > .status {
		right: 6.5rem;
	}

	#custom-header:not(:has(> .avatar)) > .status {
		right: 3.5rem;
	}

	#custom-header:not(:has(> .btn)) > .avatar {
		right: 3.5rem;
	}

	#custom-header > .status .status__dot {
		top: 0.3rem !important;
	}

	#custom-header > .avatar {
		margin-top: 0.5rem;
		position: absolute;
		right: 6.5rem;
	}

	#custom-header > .btn:not(:only-of-type):first-child {
		right: 9.5rem;
	}

	.accordion-item-header__subtitle {
		overflow: hidden;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 50rem;
	}

	.accordion__item--active > * .accordion-item-header__subtitle {
		white-space: break-spaces;
	}
`;

const StyledListWrapper = styled.div`
	display: flex;
	width: auto;
	justify-content: end;
	text-align: left;
`;

const StyledInnerTable = styled.table`
	width: 30vw !important;

	th {
		padding: 0.75rem !important;
		border: 0.5px solid lightgrey;
		text-align: center;
		font-weight: bold;
	}

	td {
		padding: 1rem !important;
		border: 0.5px solid lightgrey;
		text-align: center;
	}

	td:has(:not(table)) {
		padding: 0rem !important;
	}
`;

const StyledInnerColumnTable = styled.table`
	width: 15vw !important;
	table-layout: auto;

	th {
		text-align: center;
		font-weight: bold;
		border-top: 0rem;
		border-bottom: 0rem;
		width: 2rem !important;
		overflow: hidden;
	}

	th:first-child {
		border-right: 0;
		border-left: 0;
	}

	th:last-child {
		border-right: 0;
	}

	td {
		text-align: center;
		width: 2rem !important;
		padding: 0.75rem !important;
		border-bottom: 0rem;
		overflow: hidden;
	}

	tr td:first-child {
		border-left: 0rem;
		border-right: 0rem;
	}

	tr td:last-child {
		border-right: 0;
	}
`;

const CircularSkeleton = styled(Skeleton)`
	border-radius: 100%;
	margin-bottom: 1rem;
`;

const ChartSkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 3rem;
`;

const PaddingTop = styled.div`
	padding-top: 2rem;
`;

const ChartSkeleton = () => {
	return (
		<>
			{[1, 2, 3, 4, 5].map(() => (
				<ChartSkeletonWrapper>
					<CircularSkeleton width="6rem" height="6rem" />
					<Skeleton width="6rem" height="1.4rem" />
				</ChartSkeletonWrapper>
			))}
		</>
	);
};

const FeaturesTableSkeleton = () => {
	return (
		<>
			{[1, 2, 3, 4].map(() => (
				<>
					<PaddingTop />
					<Skeleton width="70vw" height="3.5rem" />
				</>
			))}
		</>
	);
};

const Layout: React.FC<FeaturesTableProps> = ({
	activeApplication,
	hasGroups,
	tabsData,
	setActiveTab,
	tableData,
	activeMarket,
	activeTab,
	loading,
	showMarketUsersModal,
	setShowMarketUsersModal,
	showSeachBar,
	setShowSeachBar,
	searchPropertyValue,
	setSearchPropertyValue,
	marketDashboardChartsData,
	showAdoptionRate,
}) => {
	const tabs = React.useMemo(() => {
		return tabsData?.groupIds?.map((tab: string) => (
			<Tab
				key={tab}
				text={tab}
				type="button"
				tabPanelId={tab}
				onClick={() => {
					setActiveTab(tab);
				}}
			/>
		));
	}, [tabsData, setActiveTab]);

	const { isMobile } = useWindowSize();
	return (
		<>
			<MarketTableWrapper>
				{showAdoptionRate && (
					<>
						<h2>Adoption rate per market</h2>
						<OverflowCarousel controlBtnType="emphasised" id="chart-wrapper">
							{loading ? (
								<ChartSkeleton />
							) : (
								marketDashboardChartsData?.map((chart: any) => (
									<Chart colorBar={chart.colorBar} label={chart.label} series={chart.series} />
								))
							)}
						</OverflowCarousel>
					</>
				)}
				<TableHeader>
					<h2>Overview</h2>
					<TableHeaderButtonsWrapper>
						<Pill iconOnly label="Label" ssrIcon={people} onClick={() => setShowMarketUsersModal(true)} />
					</TableHeaderButtonsWrapper>
					<Search
						ariaLabel="Search for product categories, product names, etc."
						clearBtnText="Clear search field"
						id="search"
						onSearch={(_, { value }) => setSearchPropertyValue(value)}
						onChange={(_, { value }) => setSearchPropertyValue(value)}
						placeholder="Search"
						submitBtnText="Submit"
						onClear={() => setSearchPropertyValue("")}
					/>
				</TableHeader>
				{hasGroups && tabs && <Tabs tabs={tabs} activeTab={activeTab} />}
				{loading ? (
					<FeaturesTableSkeleton />
				) : (
					tableData && (
						<StyledAccordion collapsible={false} size="small">
							{tableData.map((tableItem, index) => (
								<AccordionItemWrapper>
									<AccordionItem
										caption={tableItem.description ? tableItem.description : ""}
										id={index.toString()}
										title={
											tableItem["flagga-label"]
												? capitalizeFirstLetter(tableItem["flagga-label"])
												: capitalizeFirstLetter(tableItem.key)
										}
									>
										<StyledTable>
											<TableBody>
												<tr>
													<td>Last update</td>
													<StyledTableSecondColumnCell>
														{tableItem.timestamp ? new Date(tableItem.timestamp).toLocaleDateString() : "-"}
													</StyledTableSecondColumnCell>
												</tr>
												<tr>
													<td>Comment</td>
													<StyledTableSecondColumnCell>
														{tableItem.reason ? tableItem.reason : "-"}
													</StyledTableSecondColumnCell>
												</tr>
												<tr>
													<td>Email</td>
													<StyledTableSecondColumnCell>
														{tableItem.updatedBy && tableItem.updatedBy.email ? tableItem.updatedBy.email : "-"}
													</StyledTableSecondColumnCell>
												</tr>
												<tr>
													<td>Value</td>

													{typeof tableItem.value === "boolean" && tableItem.value && (
														<StyledTableSecondColumnCell>
															<Status statusColor="green" label="Active" />
														</StyledTableSecondColumnCell>
													)}
													{typeof tableItem.value === "boolean" && !tableItem.value && (
														<StyledTableSecondColumnCell>
															<Status statusColor="red" label="Inactive" />
														</StyledTableSecondColumnCell>
													)}
													{typeof tableItem.value === "string" && (
														<StyledTableSecondColumnCell>
															<span>{tableItem.value}</span>
														</StyledTableSecondColumnCell>
													)}
													{Array.isArray(tableItem.value) &&
														tableItem.value.length > 0 &&
														typeof tableItem.value[0] === "string" && (
															<StyledTableSecondColumnCell>
																<StyledListWrapper>
																	<ul>
																		{tableItem.value.map((item: any) => (
																			<li>{item}</li>
																		))}
																	</ul>
																</StyledListWrapper>
															</StyledTableSecondColumnCell>
														)}
													{Array.isArray(tableItem.value) &&
														tableItem.value.length > 0 &&
														typeof tableItem.value[0] === "object" && (
															<SecondColumnCellTable>
																<StyledInnerTable className="table">
																	<thead>
																		<tr>
																			{Object.keys(tableItem.value[0]).map((valueItemKey) => (
																				<th>{capitalizeFirstLetter(valueItemKey)}</th>
																			))}
																		</tr>
																	</thead>
																	<TableBody>
																		{tableItem.value.map((item: any) => (
																			<tr>
																				{Object.keys(item).map((itemKey) => {
																					if (
																						Array.isArray(item[itemKey]) &&
																						item[itemKey].length > 0 &&
																						typeof item[itemKey][0] === "object"
																					) {
																						return (
																							<td>
																								<StyledInnerColumnTable>
																									<thead>
																										<tr>
																											{Object.keys(item[itemKey][0]).map(
																												(innerTableColumnName: any) => (
																													<th>{capitalizeFirstLetter(innerTableColumnName)}</th>
																												)
																											)}
																										</tr>
																									</thead>
																									<TableBody>
																										{item[itemKey].map((innerTableItem: any) => {
																											return (
																												<tr>
																													{Object.keys(innerTableItem).map(
																														(innerTableColumnName: any) => (
																															<td>{innerTableItem[innerTableColumnName]}</td>
																														)
																													)}
																												</tr>
																											);
																										})}
																									</TableBody>
																								</StyledInnerColumnTable>
																							</td>
																						);
																					} else {
																						return <td>{item[itemKey]}</td>;
																					}
																				})}
																			</tr>
																		))}
																	</TableBody>
																</StyledInnerTable>
															</SecondColumnCellTable>
														)}
												</tr>
											</TableBody>
										</StyledTable>
									</AccordionItem>
									{!isMobile && (
										<div id="custom-header">
											{typeof tableItem.value === "boolean" && tableItem.value && <Status statusColor="green" />}
											{typeof tableItem.value === "boolean" && !tableItem.value && <Status statusColor="red" />}
											{typeof tableItem.value === "string" && <Button type="tertiary" iconOnly small ssrIcon={text} />}
											{Array.isArray(tableItem.value) &&
												tableItem.value.length > 0 &&
												typeof tableItem.value[0] === "string" && (
													<Button type="tertiary" iconOnly small ssrIcon={list} />
												)}
											{Array.isArray(tableItem.value) &&
												tableItem.value.length > 0 &&
												typeof tableItem.value[0] === "object" && (
													<Button type="tertiary" iconOnly small ssrIcon={database} />
												)}
											{tableItem.reason && <Button type="tertiary" iconOnly small ssrIcon={documentPencil} />}
											{tableItem.updatedBy && tableItem.updatedBy.name && (
												<Avatar
													screenReaderText={tableItem.updatedBy.name}
													text={tableItem.updatedBy.name.split("(")[0].replace(/[^A-Z]/g, "")}
													size="small"
												/>
											)}
										</div>
									)}
								</AccordionItemWrapper>
							))}
						</StyledAccordion>
					)
				)}
				{(!loading && !tableData) ||
					(!loading && tableData?.length === 0 && <InlineMessage title="No Data to display" variant="informative" />)}
			</MarketTableWrapper>
			<MarketUsersModal
				activeApplication={activeApplication}
				activeMarket={activeMarket}
				showMarketUsersModal={showMarketUsersModal}
				setShowMarketUsersModal={setShowMarketUsersModal}
			/>
		</>
	);
};

export default Layout;
