import * as React from "react";

import Flagga from "../components/Flagga";
import RequireAuth from "../components/RequireAuth";

const Home: React.FC = () => {
	return (
		<RequireAuth>
			<Flagga />
		</RequireAuth>
	);
};

export default Home;
