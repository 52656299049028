import React from "react";
import Layout from "./Layout";
import { ValidationProps } from "../../types";

interface ApplicationNameProps extends ValidationProps {}

const ApplicationName: React.FC<ApplicationNameProps> = ({
	value,
	valid,
	validation,
	handleChange,
	shouldValidate,
}) => {
	return (
		<Layout
			value={value}
			valid={valid}
			validation={validation}
			handleChange={handleChange}
			shouldValidate={shouldValidate}
		/>
	);
};

export default ApplicationName;
