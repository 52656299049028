import React from "react";
import styled from "styled-components";

const ContentWrapper = styled.div`
	min-width: 32rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	background: rgb(var(--colour-elevation-2, 255, 255, 255));
	box-shadow: 0px 0px 0px 1px rgba(var(--colour-neutral-4, 204, 204, 204), 0.24),
		0px 4px 8px 0px rgba(var(--colour-static-black, 17, 17, 17), 0.14);
	overflow: hidden;
	border-radius: 8px;
	outline: 1px solid rgba(var(--colour-neutral-7, 17, 17, 17), 0.07);
`;

const ContentBox: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <ContentWrapper>{children}</ContentWrapper>;
};

export default ContentBox;
