import * as React from "react";
import Loading, { LoadingBall } from "@ingka/loading";
import styled from "styled-components";

const LoadergWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

const LoadingLayout: React.FC = () => {
	return (
		<LoadergWrapper>
			<Loading labelPosition="center" text="Loading" labelTransitions>
				<LoadingBall color="emphasised" />
			</Loading>
		</LoadergWrapper>
	);
};

export default LoadingLayout;
