import * as React from "react";
import styled from "styled-components";

import InputField from "@ingka/input-field";
import FormField from "@ingka/form-field";
import Button from "@ingka/button";
import Pill from "@ingka/pill";
import Loading, { LoadingBall } from "@ingka/loading";
import copy from "@ingka/ssr-icon/paths/copy";
import Modal, { Prompt, ModalFooter } from "@ingka/modal";
import Tooltip from "@ingka/tooltip";
import { breakpointL } from "@ingka/variables/design-tokens";
import { ListViewItem } from "@ingka/list-view";
import Toast from "@ingka/toast";
import InlineMessage from "@ingka/inline-message";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";

import { 
	CenterButtonWrapper, 
	LoadingWrapper, 
	StyledModalHeader, 
	ActionsWrapper, 
	SaveButtonWrapper,
	IconButtonWrapper
} from "../styles";
import { Application } from "../../store";
import { Validation } from "./types";
import { getImage } from "../../utils/markets";
import CopyMarketValueModal from "./CopyMarketValueModal";
import { MarketName } from "./utils";
import { marketsByName } from "../../utils/common";

const StyledWrapper = styled.ul`
	display: grid;
	column-gap: 3rem;
	row-gap: 1rem;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	padding: 0;

	@media only screen and (max-width: ${breakpointL}) {
		width: 100%;
		display: flex;
		flex-direction: column;
	} ;
`;

const StyledListViewItem = styled(ListViewItem)`
	.list-view-item__wrapper {
		padding-bottom: 0.5rem;
	}
	.list-view-item__image {
		width: 2.5rem;
	}
	.checkbox input[type="checkbox"]:checked ~ .checkbox__symbol {
		background: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
		border-color: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
	}
`;

const StyledLabelTitle = styled.strong`
	font-size: 1.1rem;
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 2.5rem;
`;

const StyledPrompt = styled(Prompt)`
	width: 25rem;
`;

const StyledInlineMessage = styled(InlineMessage)`
	width: 25rem;
`;

interface ManageMarketLayoutProps {
	activeApplication: Application;
	btnLoading: boolean;
	marketsLoading: boolean;
	onAddMarket?: React.FormEventHandler<HTMLFormElement>;
	valid: boolean;
	shouldValidate: boolean;
	validation: Validation;
	handleChange: (value: string) => void;
	value: string;
	toastMessage: string;
	setToastMessage: (val: string) => void;
	getCountryName: (country: string) => string;
	marketData?: string[];
	showAddNewMarketModal: boolean;
	setShowAddNewMarketModal: (value: boolean) => void;
	setValue: (value: string) => void;
	setShouldValidate: (value: boolean) => void;
	onRemoveMarkets: () => void;
	selectedMarkets?: string[];
	setSelectedMarkets: (data?: string[]) => void;
	showRemoveMarketsModal: boolean;
	setShowRemoveMarketsModal: (value: boolean) => void;
	showCopyMarketValueModal: boolean;
	setShowCopyMarketValueModal: (value: boolean) => void;
	marketNameFromData: MarketName[] | undefined;
	marketsNameToData: MarketName[] | undefined;
	selectedMarketCopyValueFrom: string;
	setSelectedMarketCopyValueFrom: (value: string) => void;
	selectedMarketsCopyValueTo: string[];
	setSelectedMarketsCopyValueTo: (value: string[]) => void;
	onCopyMarketValues: () => void;
	copyingMarketValues: boolean;
}

const Layout: React.FC<ManageMarketLayoutProps> = ({
	activeApplication,
	btnLoading,
	marketData,
	marketsLoading,
	onAddMarket,
	valid,
	shouldValidate,
	validation,
	handleChange,
	value,
	toastMessage,
	setToastMessage,
	getCountryName,
	showAddNewMarketModal,
	setShowAddNewMarketModal,
	setValue,
	setShouldValidate,
	onRemoveMarkets,
	selectedMarkets,
	setSelectedMarkets,
	showRemoveMarketsModal,
	setShowRemoveMarketsModal,
	showCopyMarketValueModal,
	setShowCopyMarketValueModal,
	marketNameFromData,
	marketsNameToData,
	selectedMarketCopyValueFrom,
	setSelectedMarketCopyValueFrom,
	selectedMarketsCopyValueTo,
	setSelectedMarketsCopyValueTo,
	onCopyMarketValues,
	copyingMarketValues,
}) => {
	if (marketsLoading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Markets" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	return (
		<>
			<>
				<StyledHeaderWrapper>
					<h2>{`Markets in ${activeApplication?.name}`}</h2>
				</StyledHeaderWrapper>
				{marketData?.length ? (
					<StyledWrapper>
						{marketsByName(marketData, getCountryName).map((market) => (
							<StyledListViewItem
								id={market.code}
								image={getImage(market.code)}
								leadingIconPosition="center"
								control="checkbox"
								controlProps={
									{
										checked: selectedMarkets?.includes(market.code) || false,
									} as any
								}
								onChange={(e) => {
									if ((e.target as any)?.checked) {
										setSelectedMarkets(selectedMarkets ? [...selectedMarkets, market.code] : [market.code]);
									} else {
										setSelectedMarkets(selectedMarkets?.filter((key) => key !== market.code));
									}
								}}
								title={
									<>
										<StyledLabelTitle>{market.name} | </StyledLabelTitle>
										{market.code}
									</>
								}
							/>
						))}
					</StyledWrapper>
				) : (
					<StyledInlineMessage
						ssrIcon={informationCircle}
						title="No Market assigned!"
						variant="informative"
					></StyledInlineMessage>
				)}
			</>
			<ActionsWrapper>
				<SaveButtonWrapper>
					<Button
						data-testid="addNewMarket"
						text="Add new market"
						onClick={() => {
							setValue("");
							setShouldValidate(false);
							setShowAddNewMarketModal(true);
						}}
						type="emphasised"
					/>
					<Button
						data-testid="removeNewMarket"
						text="Remove markets"
						type="secondary"
						disabled={!selectedMarkets || selectedMarkets.length === 0}
						onClick={() => setShowRemoveMarketsModal(true)}
					/>
				</SaveButtonWrapper>
				<IconButtonWrapper>
					<Tooltip position="top" tooltipText="Copy market values">
						<Pill
							data-testid="copyMarketValue"
							id="update-configuration-multiple-markets"
							ssrIcon={copy}
							iconOnly={true}
							disabled={!marketData || marketData.length < 2}
							onClick={() => setShowCopyMarketValueModal(true)}
						/>
					</Tooltip>
				</IconButtonWrapper>
			</ActionsWrapper>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showAddNewMarketModal}
				escapable={true}
				handleCloseBtn={() => setShowAddNewMarketModal(false)}
			>
				<StyledPrompt
					title=""
					titleId={"modalTitleId"}
					header={<StyledModalHeader ariaCloseTxt="Close button text" title="Add new market" />}
					footer={<></>}
				>
					<form onSubmit={onAddMarket}>
						<FormField
							valid={valid}
							shouldValidate={shouldValidate}
							validation={validation}
							fieldHelper={{ msg: "Please enter 2 letters Country Code", id: "country-code-helper" }}
						>
							<InputField
								id={"market-input"}
								value={value}
								label={"Country Code"}
								type="text"
								disabled={btnLoading}
								onChange={({ target: { value } }) => {
									handleChange(value);
								}}
							/>
						</FormField>
						<CenterButtonWrapper>
							<Button
								data-testid="addnewmarket"
								type="secondary"
								text="Add Market"
								disabled={!valid || value === ""}
								htmlType="submit"
								loading={btnLoading}
							/>
						</CenterButtonWrapper>
					</form>
				</StyledPrompt>
			</Modal>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showRemoveMarketsModal}
				escapable={true}
				handleCloseBtn={() => setShowRemoveMarketsModal(false)}
			>
				<StyledPrompt
					title=""
					titleId={"modalTitleId"}
					header={<StyledModalHeader ariaCloseTxt="Close button text" title="Remove markets" />}
					footer={
						<ModalFooter>
							<Button
								data-testid="Remove"
								text="Remove"
								type="secondary"
								onClick={() => onRemoveMarkets()}
								disabled={btnLoading}
							/>
							<Button
								data-testid="Cancel"
								text="Cancel"
								type="emphasised"
								onClick={() => setShowRemoveMarketsModal(false)}
							/>
						</ModalFooter>
					}
				>
					<p>
						Are you sure you want to remove selected market(s){" "}
						<strong>{selectedMarkets?.map((market) => market).join(", ")}</strong> from the application ?
					</p>
				</StyledPrompt>
			</Modal>
			<CopyMarketValueModal
				showModal={showCopyMarketValueModal}
				setShowModal={setShowCopyMarketValueModal}
				marketNameFromData={marketNameFromData}
				marketsNameToData={marketsNameToData}
				selectedMarketCopyValueFrom={selectedMarketCopyValueFrom}
				selectedMarketsCopyValueTo={selectedMarketsCopyValueTo}
				setSelectedMarketCopyValueFrom={setSelectedMarketCopyValueFrom}
				setSelectedMarketsCopyValueTo={setSelectedMarketsCopyValueTo}
				onCopyMarketValues={onCopyMarketValues}
				copyingMarketValues={copyingMarketValues}
			/>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default Layout;
