import * as React from "react";

import Dashboard from "../components/Dashboard";
import RequireAuth from "../components/RequireAuth";

const DashboardPage: React.FC = () => {
	return (
		<RequireAuth>
			<Dashboard />
		</RequireAuth>
	);
};

export default DashboardPage;
