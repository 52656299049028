import { colourStaticGrey } from "@ingka/variables/colours-css";
import { makeStyles } from "@material-ui/core";
import { Hidden } from "@mui/material";
import React from "react";

const useStyles = makeStyles(() => ({
	main: {
		minHeight: 0,
		height: "100vh",
		overflow: "hidden",
	},
	container: {
		display: "grid",
		height: "100vh",
		gridTemplateAreas: "header content footer",
		gridTemplateColumns: "1fr",
		gridTemplateRows: "auto 1fr auto",
		border: `1px solid ${colourStaticGrey}`,
	},
}));

export const Layout: React.FC = ({ children }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.main}>{children}</div>
		</div>
	);
};
