import * as React from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";

import Header from "../Header";
import Footer from "../Footer";
import { loginRequest } from "../../authConfig";
import { getLoggedInUser } from "../../services/users";
import useStore, { setUserSelector, userSelector } from "../../store";
import LoadingLayout from "../LoadingLayout";
import Container from "../Container";
import { ROUTE_EMAIL_CONSENT, ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_MAINTENANCE } from "../../utils/constants";
import { Routes } from "../../utils/types";

const FlaggaLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const setUser = useStore(setUserSelector);
	const navigate = useNavigate();
	const user = useStore(userSelector);
	const [loading, setLoading] = React.useState(false);
	const { instance, accounts, inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const location = useLocation();
	const [unsavedChangesValue, setUnsavedChangesValue] = useLocalStorage("unsavedChanges", "");

	const path = React.useMemo(() => location && location.pathname, [location]);

	const hideHeaderFooterPaths = React.useMemo(
		() => [ROUTE_LOGIN, ROUTE_MAINTENANCE, ROUTE_LOGOUT].includes(path as Routes),
		[path]
	);

	const handleLogout = React.useCallback(() => {
		instance.logoutRedirect();
	}, [instance]);

	React.useEffect(() => {
		if (isAuthenticated && user && !user.user.emailConsentAccepted) {
			navigate(ROUTE_EMAIL_CONSENT);
		}
	}, [isAuthenticated, navigate, user]);

	React.useEffect(() => {
		(async () => {
			if (!user && inProgress === InteractionStatus.None && accounts[0]) {
				instance
					.acquireTokenSilent({ ...loginRequest, account: accounts[0] || null })
					.then(async (accessTokenResponse) => {
						setLoading(true);
						// Acquire token silent success
						let accessToken = accessTokenResponse.idToken;
						// Call your API with token
						const { data: userData } = await getLoggedInUser(accessToken);
						setUser(userData?.data);

						if (unsavedChangesValue && typeof unsavedChangesValue === "object") {
							let dateTime = (unsavedChangesValue as any).dateTime;
							//remove changes after 1 hour
							if ((dateTime - new Date().getTime()) / 1000 / 3600 > 1) {
								setUnsavedChangesValue("");
							}
						}
					})
					.catch((error: any) => {
						if (error instanceof InteractionRequiredAuthError) {
							instance.acquireTokenRedirect({ ...loginRequest, account: accounts[0] || null });
						}
						if (error?.response?.status == 401) {
							setLoading(false);
							handleLogout();
						}
						console.log(error);
					})
					.finally(() => setLoading(false));
			}
		})();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts , handleLogout, inProgress, instance,  setUser, unsavedChangesValue, user]);

	if (loading) {
		return <LoadingLayout />;
	}

	return (
		<>
			{!hideHeaderFooterPaths && <Header />}
			{!hideHeaderFooterPaths ? <Container>{children}</Container> : <>{children}</>}
			{!hideHeaderFooterPaths && <Footer />}
		</>
	);
};

export default FlaggaLayout;
