/* eslint-disable indent */
import * as React from "react";

import useAuthentication from "./useAuthentication";
import { UserData, getUsersList, UserSearchQuery } from "../services/users";
import useStore, { activeApplicationSelector } from "../store";

const useGetUserSuggestions = () => {
	const { getToken, handleLogout } = useAuthentication();
	const [data, setData] = React.useState<UserData[]>([]);
	const [noOptionsText, setNoOptionsText] = React.useState<string>("Please type at least 3 characters.");
	const [error, setError] = React.useState<string | undefined>(undefined);
	const activeApplication = useStore(activeApplicationSelector);
	const [loading, setLoading] = React.useState<boolean>(false);

	const getSuggestions = React.useCallback(
		async (input: string, excludeUsers?: boolean) => {
			if (input?.length >= 3) {
				try {
					setLoading(true);
					const token = await getToken();

					let searchPayload = {
						q: input,
					} as UserSearchQuery;

					if (excludeUsers) {
						searchPayload.excluded_application = activeApplication?.id || "";
					}
					const { data: usersData } = await getUsersList(token, searchPayload);
					setData(usersData.data.users.length ? usersData.data.users : []);
					if (usersData.data.users.length === 0) {
						setNoOptionsText(`No users found with email starting with ${input}.`);
					}
				} catch (error: any) {
					if (error?.response?.status == 401) {
						handleLogout();
					}
					setError("Can't find user something went wrong");
				} finally {
					setLoading(false);
				}
			} else if (input.length < 3) {
				setNoOptionsText("Please type at least 3 characters.");
			}
		},
		[activeApplication?.id, getToken, handleLogout]
	);

	return { getSuggestions, data, error, loading, noOptionsText, setData, setNoOptionsText };
};

export default useGetUserSuggestions;
