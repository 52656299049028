import * as React from "react";

import Loading, { LoadingBall } from "@ingka/loading";
import InlineMessage from "@ingka/inline-message";

import useStore, { activeMarketSelector, applicationsLoadingSelector, marketsLoadingSelector } from "../../store";
import ConfigurationViewer from "../ConfigurationViewer";
import { HomePageLayoutTabs } from "../../utils/constants";
import { LoadingWrapper } from "../styles";
import useTabs from "../../hooks/useTabs";

const ConfigurationPanel = () => {
	const { activeTab } = useTabs();
	const applicationsLoading = useStore(applicationsLoadingSelector);
	const marketsLoading = useStore(marketsLoadingSelector);
	const activeMarket = useStore(activeMarketSelector);
	const showConfiguration = React.useMemo(
		() => activeMarket && activeTab === HomePageLayoutTabs.Configuration,
		[activeMarket, activeTab]
	);

	const loading = React.useMemo(() => applicationsLoading || marketsLoading, [applicationsLoading, marketsLoading]);

	return (
		<>
			{loading ? (
				<LoadingWrapper>
					<Loading labelPosition="center" text="Loading Markets..." labelTransitions>
						<LoadingBall color="emphasised" />
					</Loading>
				</LoadingWrapper>
			) : showConfiguration ? (
				<ConfigurationViewer />
			) : !activeMarket ? (
				<LoadingWrapper>
					<InlineMessage
						title="No Configuration or Markets"
						body={"Seems like you don't any configuration or markets yet..."}
						variant="cautionary"
					/>
				</LoadingWrapper>
			) : null}
		</>
	);
};

export default ConfigurationPanel;
