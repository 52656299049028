import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import useStore, { resetStateSelector, setUserSelector } from "../store";

interface Result {
	handleLogin: () => void;
	handleLogout: () => void;
	getToken: () => Promise<string>;
}

const useAuthentication = (): Result => {
	const { instance, accounts } = useMsal();
	const resetState = useStore(resetStateSelector);
	const setUser = useStore(setUserSelector);

	const getToken = React.useCallback(async () => {
		const token = await instance.acquireTokenSilent({
			...loginRequest,
			account: accounts[0],
		});
		return token.idToken;
	}, [accounts, instance]);

	const handleLogout = React.useCallback(() => {
		sessionStorage.clear();
		resetState();
		setUser(null);
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		});
	}, [instance, resetState, setUser]);

	const handleLogin = () => {
		instance.loginRedirect(loginRequest).catch((e) => {
			console.log(e);
		});
	};
	return { handleLogin, handleLogout, getToken };
};

export default useAuthentication;
