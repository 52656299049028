import create, { StateCreator } from "zustand";
import { persist } from "zustand/middleware";
import { MarketsData } from "../hooks/useGetMarkets";
import { ApplicationData, State, StoreState } from "./types";
import { ApplicationUserData } from "../services/users";

const initialState: State = {
	canWrite: false,
	user: null,
	credential: null,
	activeMarket: null,
	activeApplication: null,
	showGuidanceModal: false,
	validationErrors: [],
	activeTab: null,
	isApplicationAdmin: false,
	applications: null,
	markets: null,
	pathnameFrom: null,
	selectedMarkets: [],
	multipleMarketUpdateFlag: false,
	applicationRequests: [],
	applicationsLoading: false,
	marketsLoading: false,
	routerStatefrom: {
		hash: "",
		key: "",
		pathname: "",
		search: "",
		state: null,
	},
	applicationUsers: null,
	showManageScheduledChangesModal: false,
	forceRerender: false
};

const initialStateCreator: StateCreator<StoreState> = (set, get) => ({
	...initialState,
	setUser: (user) => set({ user }),
	setCanWrite: (writePermission: boolean) => set({ canWrite: writePermission }),
	authenticateUser: ({ credential, user, isNewUser }) => set({ credential, user, showGuidanceModal: !!isNewUser }),
	setActiveMarket: (activeMarket) => set({ activeMarket }),
	setActiveApplication: (activeApplication) => set({ activeApplication }),
	setShowGuidanceModal: (show) => set({ showGuidanceModal: show }),
	reset: () => set(initialState),
	resetValidationErrors: () => set({ validationErrors: [] }),
	addValidationError: (validationError) =>
		set({
			validationErrors: get().validationErrors.concat(validationError),
		}),
	setActiveTab: (activeTab: string) => set({ activeTab }),
	setIsApplicationAdmin: (isApplicationAdmin: boolean) => set({ isApplicationAdmin }),
	setApplications: (applications: ApplicationData[] | null) => set({ applications }),
	setApplicationRequests: (applicationRequests: object[]) => set({ applicationRequests }),
	setMarkets: (markets: MarketsData | null) => set({ markets }),
	setPathnameFrom: (pathnameFrom: String | null) => set({ pathnameFrom }),
	setSelectedMarkets: (selectedMarkets) => set({ selectedMarkets }),
	setMultipleMarketUpdateFlag: (multipleMarketUpdateFlag) => set({ multipleMarketUpdateFlag }),
	setRouterStatefrom: (routerStatefrom) => set({ routerStatefrom }),
	setApplicationsLoading: (applicationsLoading: boolean) => set({ applicationsLoading }),
	setMarketsLoading: (marketsLoading: boolean) => set({ marketsLoading }),
	setApplicationUsers: (applicationUsers: ApplicationUserData | null) => set({ applicationUsers }),
	setShowManageScheduledChangesModal: (showManageScheduledChangesModal: boolean) => set({ showManageScheduledChangesModal }),
	setForceRerender: (forceRerender: boolean) => set({ forceRerender }),
});

const store = create<StoreState>(
	persist(initialStateCreator, {
		name: "store",
		getStorage: () => sessionStorage,
	})
);

export default store;
