import { MarketWithName } from "./markets";
import { JsonSchema } from "@jsonforms/core";
import { sortMarketUsers } from "../components/MarketFeaturesOverviewTable/utils";

export const getObjectChanges = (original: any, updated: any) => {
	const changes: any = {};
	const newKeys = Object.keys(updated).filter((item) => !Object.keys(original).includes(item));

	const typeChangedKeys = Object.keys(updated).filter(
		(item) => Object.keys(original).includes(item) && typeof updated[item] != typeof original[item]
	);

	if (typeChangedKeys?.length) {
		for (let i = 0; i < typeChangedKeys.length; i++) {
			changes[typeChangedKeys[i]] = updated[typeChangedKeys[i]];
		}
	}

	for (let i = 0; i < newKeys.length; i++) {
		changes[newKeys[i]] = updated[newKeys[i]];
	}

	// loop through the properties of the original object
	for (const key in original) {
		// check if the property is an object
		if (
			typeof original[key] === "object" &&
			!Array.isArray(original[key]) &&
			original[key] !== null &&
			!typeChangedKeys.includes(key)
		) {
			// get the changes in the nested object

			const nestedChanges = updated[key] ? getObjectChanges(original[key], updated[key]) : {};

			// add the nested changes to the changes object if there are any
			if (Object.keys(nestedChanges).length > 0) {
				changes[key] = nestedChanges;
			}
		}
		// check if the property has been updated
		else if (original[key] !== updated[key] && !typeChangedKeys.includes(key)) {
			// add the property to the changes object
			changes[key] = updated[key];
		}
	}

	// return the changes object
	return changes;
};

export const sortMarketsByName = (markets: MarketWithName[]) => {
	return markets.sort((a, b) => {
		const nameA = a.name.toUpperCase();
		const nameB = b.name.toUpperCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		return 0;
	});
};

export const marketsByName = (markets: string[], getCountryName: (country: string) => string) => {
	return markets.map((market) => ({ code: market, name: getCountryName(market) })).sort(sortMarketUsers);
};

export const isUserHasMarkets = (markets: { [marketCode: string]: string } | undefined) =>
	markets &&
	Object.entries(markets).reduce((acc, [key, value]) => {
		acc.push({ market: key, uiSchema: value });
		return acc;
	}, [] as { market: string; uiSchema: string }[]).length > 0;

export const replaceAll = (str: String, mapObj: any) => {
	if (Object.keys(mapObj).length) {
		var re =
			Object.keys(mapObj).length > 1 ? new RegExp(Object.keys(mapObj).join("|"), "gi") : Object.keys(mapObj).toString();
		return str.replace(re, (matched) => {
			return mapObj[matched?.toLowerCase()] === null
				? "null"
				: mapObj[matched?.toLowerCase()] === undefined
				? "undefined"
				: mapObj[matched?.toLowerCase()];
		});
	}
	return str;
};

interface Properties {
	[property: string]: JsonSchema;
}

const findRemovedAndAddedProperties: (
	originalProps: Properties,
	updatedProps: Properties,
	path: string
) => { added: string[]; removed: string[] } = (originalProps: Properties, updatedProps: Properties, path: string) => {
	const addedProperties = [];
	const removedProperties = [];

	for (const key in originalProps) {
		if (!Object.prototype.hasOwnProperty.call(updatedProps, key)) {
			// The property key exists in the original but not in the updated schema
			removedProperties.push(`${path}.${key}`);
		} else if (typeof originalProps[key] === "object") {
			// Recursively check nested properties
			const newPath = `${path}.${key}`;
			const { added, removed } = findRemovedAndAddedProperties(
				originalProps[key] as Properties,
				updatedProps[key] as Properties,
				newPath
			);
			addedProperties.push(...added);
			removedProperties.push(...removed);
		}
	}

	for (const key in updatedProps) {
		if (!Object.prototype.hasOwnProperty.call(originalProps, key)) {
			// The property key exists in the updated but not in the original schema
			addedProperties.push(`${path}.${key}`);
		}
	}

	return { added: addedProperties, removed: removedProperties };
};

export const findRemovedAndAddedSchemaProperties = (originalSchema?: JsonSchema, updatedSchema?: JsonSchema) => {
	return findRemovedAndAddedProperties(originalSchema?.properties || {}, updatedSchema?.properties || {}, "root");
};

export const getInitialsFromEmail = (email: string) => {
	const parts = email.split(".");
	const initials = parts[0][0] + parts[1][0];
	return initials;
};
