import React from "react";
import styled from "styled-components";

import Button from "@ingka/button";
import Select, { Option } from "@ingka/select";
import pencil from "@ingka/ssr-icon/paths/pencil";
import plus from "@ingka/ssr-icon/paths/plus";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import Text from "@ingka/text";
import Tooltip from "@ingka/tooltip";

import MarketsAndSchemaSelector from "../MarketsAndSchemasSelector.tsx";
import { UiSchemaData } from "../../../../services/applications/types.js";
import { MarketsData } from "../../../../hooks/useGetMarkets";
import { UserMarketPermissions } from "../../../../services/users/index.js";
import FormField from "@ingka/form-field";

interface MarketsAndSchemaSelectorLayoutProps {
	isEditing: boolean;
	setIsEditing: (val: boolean) => void;
	isAdding: boolean;
	setIsAdding: (val: boolean) => void;
	maintainerMarkets: UserMarketPermissions;
	setMaintainerMarkets: (data: UserMarketPermissions) => void;
	uiSchemaEditing: UserMarketPermissions;
	setUiSchemaEditing: (data: UserMarketPermissions) => void;
	getCountryName: (val: string) => void;
	onEdit: () => void;
	onSave: () => void;
	isValid: boolean;
	schemasData?: UiSchemaData;
	marketsData?: MarketsData;
}

const StyledWraper = styled.div`
	padding: 0.75rem 1rem 0.75rem 1rem;
	border-radius: 8px;
	border: 1px 0px 0px 0px;
	opacity: 0px;
	border: 1px solid var(--Neutrals-neutral-3, rgba(223, 223, 223, 1));
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
`;

const MarketsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: 1rem; */
	margin-bottom: 1rem;
`;

const EditLayout = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const MarketsOverviewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Layout: React.FC<MarketsAndSchemaSelectorLayoutProps> = ({
	isEditing,
	setIsEditing,
	isAdding,
	setIsAdding,
	schemasData,
	marketsData,
	maintainerMarkets,
	setMaintainerMarkets,
	getCountryName,
	onEdit,
	uiSchemaEditing,
	setUiSchemaEditing,
	onSave,
	isValid,
}) => {
	return (
		<>
			<StyledWraper>
				<TitleWrapper>
					<Text tagName="span" bodySize="m">
						<b>Markets and ui-schemas</b>
					</Text>
					<TitleWrapper>
						{!isEditing && (
							<Tooltip tooltipText={"Edit"}>
								<Button
									text="Edit"
									disabled={!Object.keys(maintainerMarkets as UserMarketPermissions).length}
									ssrIcon={pencil}
									type="emphasised"
									iconOnly
									small
									onClick={onEdit}
								/>
							</Tooltip>
						)}
						<Tooltip tooltipText={"Add"}>
							<Button
								text="Add"
								disabled={isEditing}
								type="emphasised"
								ssrIcon={plus}
								iconOnly
								small
								onClick={() => setIsAdding(true)}
							/>
						</Tooltip>
					</TitleWrapper>
				</TitleWrapper>
				<MarketsWrapper>
					<>
						{isEditing &&
							Object.entries(uiSchemaEditing).length &&
							Object.entries(uiSchemaEditing)
								.sort()
								.map(([market, uiSchema]) => (
									<StyledWrapper>
										<EditLayout>
											<FormField
												shouldValidate={uiSchema === "Choose an option"}
												valid={uiSchema !== "Choose an option"}
												validation={{
													type: "error",
													msg: "Please select ui-schema",
													id: "validation-error",
												}}
											>
												<Select
													id={"ui-schema"}
													defaultValue={uiSchema}
													label={
														<Text tagName="span" bodySize="s">
															<b>{market}</b> | {getCountryName(market)}
														</Text>
													}
													onChange={(e) => setUiSchemaEditing({ ...uiSchemaEditing, [market]: e.target.value })}
												>
													{schemasData?.["ui-schemas"].map((val) => (
														<Option key={val.uiSchemaName} value={val.uiSchemaName} name={val.uiSchemaName} />
													))}
												</Select>
											</FormField>
										</EditLayout>
										<Button iconOnly ssrIcon={trashCan} type="tertiary" />
									</StyledWrapper>
								))}
						{!isEditing && (
							<MarketsOverviewWrapper>
								{Object.entries(maintainerMarkets as UserMarketPermissions).length ? (
									Object.entries(maintainerMarkets as UserMarketPermissions)
										.sort()
										.map(([market, uiSchema]) => (
											<div>
												<div>
													<Text tagName="span" bodySize="s">
														<b>{market}</b> | {getCountryName(market)}
													</Text>
												</div>
												<div>
													<Text tagName="span" bodySize="s">
														{uiSchema}
													</Text>
												</div>
											</div>
										))
								) : (
									<Text tagName="span" bodySize="m">
										No markets & ui-schemas selected
									</Text>
								)}
							</MarketsOverviewWrapper>
						)}
					</>
				</MarketsWrapper>
				{isEditing && <Button text={"Save"} disabled={!isValid} small type="emphasised" onClick={onSave} />}
			</StyledWraper>
			<MarketsAndSchemaSelector
				showModal={isAdding}
				setShowModal={setIsAdding}
				markets={marketsData?.permittedMarkets?.filter(
					(market) => !Object.keys(maintainerMarkets as UserMarketPermissions).includes(market)
				)}
				schemasData={schemasData}
				maintainerMarkets={maintainerMarkets as UserMarketPermissions}
				setMaintainerMarkets={setMaintainerMarkets}
			/>
		</>
	);
};

export default Layout;
