import { AxiosResponse } from "axios";
import axios from "../../configs/axios";
import { AuthHeader } from "../../types";

export interface DashboardKeys {
	"flagga-label": string;
	key: string;
	description: string;
	groupId?: string;
	isVisible?: boolean;
	groupIdIndex?: number;
	columnIndex?: number;
}

export interface DashboardData {
	keys: DashboardKeys[];
	marketsData: DashboardMarktes;
}

export interface MarketDashboardData {
	keys: DashboardKeys[];
	marketData: DashboardMarketData[];
	showAdoptionRate: boolean | undefined;
}

export interface MarketDashboardUpdatesCount {
	count: number;
}

interface DashboardMarktes {
	[key: string]: DashboardMarketData[];
}

interface DashboardMarketData {
	"flagga-label": string;
	key: string;
	value: string | boolean;
	groupId?: string;
	updatedBy?: DashboardDataUpdatedBy;
	reason?: string;
}

interface DashboardDataUpdatedBy {
	name: string;
	email: string;
}

interface DashboardDataResponse {
	message: string;
	data: DashboardData;
}

interface MarketDashboardDataResponse {
	message: string;
	data: MarketDashboardData;
}

interface MarketDashboardUpdatesCountResponse {
	message: string;
	data: MarketDashboardUpdatesCount;
}

export interface DashboardRequestData extends AuthHeader {}

export const getDashboardData = async (header: AuthHeader, application: String) => {
	const data: AxiosResponse<DashboardDataResponse, DashboardRequestData> = await axios.get(
		`/application/${application}/dashboard`,
		{
			...header,
		}
	);
	return data;
};

export const getMarketDashboardData = async (header: AuthHeader, application: String, market: String) => {
	const data: AxiosResponse<MarketDashboardDataResponse, DashboardRequestData> = await axios.get(
		`/application/${application}/dashboard/${market}`,
		{
			...header,
		}
	);
	return data;
};

export const getMarketDashboardChartsData = async (header: AuthHeader, application: String, market: String) => {
	const data: AxiosResponse<MarketDashboardDataResponse, DashboardRequestData> = await axios.get(
		`/application/${application}/dashboard/${market}/adoption`,
		{
			...header,
		}
	);
	return data;
};

export const getMarketUpdates = async (header: AuthHeader, application: String, market: String | null, fromDate: any, toDate: any) => {
	let url = `/audit/count?application=${application}&fromDate=${fromDate}&toDate=${toDate}&getUpdatedPropCount=true`;
	if (market) {
		url = `/audit/count?application=${application}&market=${market}&fromDate=${fromDate}&toDate=${toDate}&getUpdatedPropCount=true`;
	}
	const data: AxiosResponse<MarketDashboardUpdatesCountResponse, DashboardRequestData> = await axios.get(
		url,
		{
			...header,
		}
	);
	return data;
};
