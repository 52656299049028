import * as React from "react";
import { IconProps } from "./types";

export const CheckmarkCircle: React.FC<IconProps> = ({ height, width, colour }) => {
	return (
		<svg
			width={`${width}` || "24"}
			height={`${height}` || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18 8.9608 16.5591 7.5l-5.9955 6.0784-3.1227-3.166L6 11.8732 10.5636 16.5 18 8.9608z"
				fill={colour || "#111"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22c5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10zm0-2c4.4183 0 8-3.5817 8-8s-3.5817-8-8-8-8 3.5817-8 8 3.5817 8 8 8z"
				fill={colour || "#111"}
			/>
		</svg>
	);
};
