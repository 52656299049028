import React from "react";
import _ from "lodash/fp";

import { SchemaCreator } from "../SchemaCreator";
import { Schema } from "../../utils/types";
import { defaultSchema } from "../../utils/constants";

type Props = {
	schema: Schema;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
	onChange?: (newSchema: Schema) => void;
	showAllProperties: boolean;
	setShowAllProperties: (val: boolean) => void;
	hasChanged: boolean;
};

const JSONSchemaBuilder: React.FunctionComponent<Props> = ({
	schema,
	onChange,
	setInvalidKeys,
	invalidKeys,
	showAllProperties,
	setShowAllProperties,
	hasChanged,
}: Props) => {
	return (
		<div>
			<SchemaCreator
				setShowAllProperties={setShowAllProperties}
				showAllProperties={showAllProperties}
				schema={!_.isEmpty(schema) ? schema : defaultSchema}
				onChange={onChange}
				invalidKeys={invalidKeys}
				setInvalidKeys={setInvalidKeys}
				hasChanged={hasChanged}
			/>
		</div>
	);
};

export default JSONSchemaBuilder;
