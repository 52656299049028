import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import InputControl, { inputControlTester } from "./InputControl";
import CheckboxControl, { checkboxControlTester } from "./CheckboxControl";
import DeliveryDetailsControl, { deliveryDetailsControlTester } from "./DeliveryDetailsControl";
import selectControlTester from "./SelectControl/selectControlTester";
import SelectControl from "./SelectControl";
import TextAreaControl, { textAreaControlTester } from "./TextAreaControl";
import DatePickerControl, { datePickerControlTester } from "./DatePickerControl";
import DateTimePickerControl, { dateTimePickerControlTester } from "./DateTimePickerControl";
import TimeFieldControl, { timeFieldControlTester } from "./TimeFieldControl";

const renderers: JsonFormsRendererRegistryEntry[] = [
	{ tester: inputControlTester, renderer: InputControl },
	{ tester: checkboxControlTester, renderer: CheckboxControl },
	{ tester: deliveryDetailsControlTester, renderer: DeliveryDetailsControl },
	{ tester: selectControlTester, renderer: SelectControl },
	{ tester: textAreaControlTester, renderer: TextAreaControl },
	{ tester: datePickerControlTester, renderer: DatePickerControl },
	{ tester: dateTimePickerControlTester, renderer: DateTimePickerControl },
	{ tester: timeFieldControlTester, renderer: TimeFieldControl },
];

export default renderers;
