import React from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { PaletteElement } from "../../core/api/paletteService";
import { DndItems } from "../../core/dnd";
import { EditorUISchemaElement } from "../../core/model/uischema";
import { StyledTreeItem, StyledTreeView } from "./Tree";
import { colourStaticGrey, colourNeutral3 } from "@ingka/variables/colours-css";
import Button from "@ingka/button";
import arrowLeftToLineSmall from "@ingka/ssr-icon/paths/arrow-left-to-line-small";
import arrowRightToLineSmall from "@ingka/ssr-icon/paths/arrow-right-to-line-small";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core";

interface UiSchemaTreeItemProps {
	uiSchemaElementProvider: () => EditorUISchemaElement;
	type: string;
	label: string;
	icon?: React.ReactNode;
}

const Paragraph = styled.p`
	margin: 0;
	flex-grow: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 1rem;
`;

const UiSchemaTreeItem: React.FC<UiSchemaTreeItemProps> = ({ uiSchemaElementProvider, type, label, icon }) => {
	//@ts-ignore
	const [{ isDragging }, drag] = useDrag({
		item: DndItems.newUISchemaElement(uiSchemaElementProvider()),
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});
	return (
		<div ref={drag} data-cy={`${type}-source`}>
			<StyledTreeItem
				key={type}
				nodeId={type}
				label={<Paragraph>{label}</Paragraph>}
				icon={icon}
				isDragging={isDragging}
			></StyledTreeItem>
		</div>
	);
};

interface UIElementsTreeProps {
	className?: string;
	elements: PaletteElement[];
	hidePalettePanel: boolean;
	setHidePalettePanel: (hideLayoutPanel: boolean) => void;
}

const HeadingWrapper = styled.div`
	display: flex;
	padding-left: 1rem;
	padding-right: 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid ${colourStaticGrey};
	justify-content: space-between;
	align-items: center;
`;

export const StyledButtonWrapper = styled.div`
	border-left: 1px solid ${colourNeutral3};
`;

const useStyles = makeStyles((theme) => ({
	buttonBorder: {
		borderLeft: `1px solid ${colourNeutral3}`,
	},
	hideButtonBorder: {
		borderLeft: "none",
	},
	buttonMargin: {
		marginLeft: "0.5rem",
	},
	buttonCollapsed: {
		position: "absolute",
		top: "0.5rem",
		right: "0.3rem",
	},
}));

const StyledH3 = styled.h3`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const UIElementsTree: React.FC<UIElementsTreeProps> = ({
	className,
	elements,
	hidePalettePanel,
	setHidePalettePanel,
}) => {
	const classes = useStyles();
	return (
		<div>
			<HeadingWrapper>
				{!hidePalettePanel && <StyledH3 data-testid="headerPropertyPanel">Layouts & Other</StyledH3>}
				<div className={`${hidePalettePanel ? classes.hideButtonBorder : classes.buttonBorder}`}>
					<Tooltip title={hidePalettePanel ? "Show Palette Panel" : "Hide Palette Panel"} placement="top">
						<Button
							data-testid="buttonResizePalettePanel"
							iconOnly
							small
							type="tertiary"
							onClick={() => setHidePalettePanel(!hidePalettePanel)}
							ssrIcon={!hidePalettePanel ? arrowLeftToLineSmall : arrowRightToLineSmall}
							style={{ marginLeft: "0.5rem" }}
							className={`${hidePalettePanel ? classes.buttonCollapsed : ""}`}
						></Button>
					</Tooltip>
				</div>
			</HeadingWrapper>
			{!hidePalettePanel && (
				<StyledTreeView defaultExpanded={[""]}>
					{elements.map(({ type, label, icon, uiSchemaElementProvider }) => (
						<UiSchemaTreeItem
							key={`treeitem-${type}`}
							type={type}
							label={label}
							icon={icon}
							uiSchemaElementProvider={uiSchemaElementProvider}
						/>
					))}
				</StyledTreeView>
			)}
		</div>
	);
};
