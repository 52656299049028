import React from "react";
import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import styled from "styled-components";
import { colourStaticGrey, colourNeutral3 } from "@ingka/variables/colours-css";
import { Properties } from "./Properties";
import { Tooltip } from "@mui/material";
import Button from "@ingka/button";
import arrowLeftToLineSmall from "@ingka/ssr-icon/paths/arrow-left-to-line-small";
import arrowRightToLineSmall from "@ingka/ssr-icon/paths/arrow-right-to-line-small";
import { makeStyles } from "@material-ui/core";

export interface PropertiesPanelProps {
	propertyRenderers: JsonFormsRendererRegistryEntry[];
	hidePropertiesPanel: boolean;
	setHidePropertiesPanel: (hidePropertiesPanel: boolean) => void;
}

const HeadingWrapper = styled.div`
	display: flex;
	padding-left: 1rem;
	padding-right: 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid ${colourStaticGrey};
	justify-content: space-between;
	align-items: center;
`;

const PropertiesWrapper = styled.div`
	margin: 0 1rem 0 1rem;
`;

export const StyledButtonWrapper = styled.div`
	border-left: 1px solid ${colourNeutral3};
`;

const useStyles = makeStyles((theme) => ({
	buttonBorder: {
		borderLeft: `1px solid ${colourNeutral3}`
	},
	hideButtonBorder: {
		borderLeft: "none"
	},
	buttonMargin: {
		marginLeft: "0.5rem"
	},
	buttonCollapsed: {
		position: "absolute",
		top: "0.5rem",
		right: "0.5rem"
	}
}));


export const PropertiesPanel: React.FC<PropertiesPanelProps> = ({ propertyRenderers, hidePropertiesPanel, setHidePropertiesPanel }) => {
	const classes = useStyles();
	return (
		<>
			<HeadingWrapper>
				{!hidePropertiesPanel && <h3 data-testid="headerPropertiesPanel">Properties</h3>}
				<div className={`${hidePropertiesPanel ? classes.hideButtonBorder: classes.buttonBorder}`}>
					<Tooltip title={hidePropertiesPanel ? "Show Properties Panel" : "Hide Properties Panel"} placement="top">
						<Button
							data-testid="buttonResizePropertiesPanel"
							iconOnly
							small
							type="tertiary"
							onClick={() => setHidePropertiesPanel(!hidePropertiesPanel)}
							ssrIcon={hidePropertiesPanel ? arrowLeftToLineSmall : arrowRightToLineSmall}
							style={{marginLeft: "0.5rem"}}
							className={`${hidePropertiesPanel ? classes.buttonCollapsed : ""}`}
						></Button>
					</Tooltip>
				</div>
			</HeadingWrapper>
			{!hidePropertiesPanel && 
				<PropertiesWrapper>
					<Properties propertyRenderers={propertyRenderers} />
				</PropertiesWrapper>
			}
		</>
	);
};
