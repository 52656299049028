import * as React from "react";
import styled from "styled-components";
import FloatingHelpButton from "../FloatingHelpButton";
import useStore, { userSelector } from "../../store";

const ActionsWrapper = styled.div`
	position: fixed;
	padding: 0 2.5rem 0 1.25rem;
	bottom: 0rem;
	align-items: center;
	width: 100%;
	background-color: #fff;
	height:5.5rem;
	z-index: 99;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
`;


const Container: React.FC = ({ children }) => {
	const currentUser = useStore(userSelector);
	return (
		<>
			<main className="page-container">
				<div className="page-container__inner">
					<div className="page-container__main">{children}</div>
				</div>
			</main>
			<ActionsWrapper>
				{currentUser && <FloatingHelpButton />}
			</ActionsWrapper>
		</>
	);
};

export default Container;
