interface Validation {
	id: string;
	link?: string;
	linkText?: string;
	msg: any;
	type: string;
}

export interface ApplicationValidation {
	appId: Validation;
	appName: Validation;
	email: Validation;
	hasReadDocumentation: Validation;
}

export interface Valid {
	appId: boolean;
	appName: boolean;
	email: boolean;
	hasReadDocumentation?: boolean;
}

export interface Value {
	appId: string;
	appName: string;
	email: string;
	clientIds?: string[]| undefined;
	showDashboard: boolean;
	showMarketDashboard: boolean;
	allowReadOnly: boolean;
	showAdoptionRate:boolean,
	hasReadDocumentation?: boolean;
	plan?: string;
}

export interface Errors {
	message: string;
}

export const ACTIVE_TABS = {
	ADD_APPICATION: "ADD_APPLICATION",
	UPDATE_APPLICATION: "UPDATE_APPLICATION",
	DELETE_APPLICATION: "DELETE_APPLICATION",
};
