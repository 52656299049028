import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import { materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { isEqual, omit } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, usePropertiesService, useSchema, useSelection, useUiSchema } from "../../core/context";
import { Actions } from "../../core/model";
import { EditorUISchemaElement } from "../../core/model/uischema";
import { tryFindByUUID } from "../../core/util/schemasUtil";
import { PropertySchemas } from "../propertiesService";
import { Layout } from "@jsonforms/core";
import { addSchemaOptionsProperty, createPropertyControl } from "../schemaDecorators";

export interface PropertiesProps {
	propertyRenderers: JsonFormsRendererRegistryEntry[];
}

export const Properties: React.FC<PropertiesProps> = ({ propertyRenderers }) => {
	const [selection] = useSelection();
	const uiSchema = useUiSchema();
	const schema = useSchema();
	const dispatch = useDispatch();
	const [properties, setProperties] = useState<PropertySchemas>();

	const uiElement: EditorUISchemaElement | undefined = useMemo(
		() => tryFindByUUID(uiSchema, selection?.uuid),
		[selection, uiSchema]
	);

	//@ts-ignore
	if (uiElement?.options?.addressOrder) {
		//@ts-ignore
		uiElement.addressOrder = uiElement?.options?.addressOrder;
		delete uiElement?.options?.addressOrder;
	}

	//@ts-ignore
	if (uiElement?.options?.elementLabelProp) {
		//@ts-ignore
		uiElement.elementLabelProp = uiElement?.options?.elementLabelProp;
		delete uiElement?.options?.elementLabelProp;
	}

	const data = useMemo(() => {
		let originalData = omit(uiElement, ["uuid", "parent", "elements", "linkedSchemaElement", "options.detail"]);
		//@ts-ignore
		if (properties?.schema?.properties?.options?.properties?.addable) {
			if (typeof originalData?.options?.addable === "undefined") {
				if (!originalData.options) {
					originalData.options = {};
				}
				originalData.options.addable = true;
			}
		}
		//@ts-ignore
		if (properties?.schema?.properties?.options?.properties?.removable) {
			if (typeof originalData?.options?.removable === "undefined") {
				if (!originalData.options) {
					originalData.options = {};
				}
				originalData.options.removable = true;
			}
		}
		//@ts-ignore
		if (properties?.schema?.properties?.options?.properties?.readOnly) {
			if (typeof originalData?.options?.readOnly === "undefined") {
				if (!originalData.options) {
					originalData.options = {};
				}
				originalData.options.readOnly = false;
			}
		}
		return originalData;
	}, [uiElement, properties]);

	const updateProperties = useCallback(
		({ data: updatedProperties }) => {
			if (uiElement && !isEqual(data, updatedProperties)) {
				dispatch(Actions.updateUISchemaElement(uiElement.uuid, updatedProperties));
			}
		},
		[data, dispatch, uiElement]
	);
	const propertiesService = usePropertiesService();

	useEffect(() => {
		if (!uiElement) {
			return;
		}

		var linkedSchemaUUID = uiElement.linkedSchemaElement;
		if (!linkedSchemaUUID) {
			let parent = uiElement?.parent;

			while (!linkedSchemaUUID && parent) {
				if (parent.linkedSchemaElement) {
					linkedSchemaUUID = parent.linkedSchemaElement;
				}
				parent = parent.parent;
			}
		}
		const elementSchema = linkedSchemaUUID && schema ? tryFindByUUID(schema, linkedSchemaUUID) : undefined;

		let properties = propertiesService.getProperties(uiElement, elementSchema);
		//add read only flag to all properties
		if (elementSchema?.schema.type && uiElement.type === "Control") {
			properties &&
				addSchemaOptionsProperty(properties.schema, {
					readOnly: { type: "boolean", default: false },
				});
			(properties?.uiSchema as Layout).elements.push(createPropertyControl("#/properties/options/properties/readOnly"));
		}
		//add removable and addable options for arrays
		if (elementSchema?.schema.type === "array" && !elementSchema?.schema.format && uiElement.type === "Control") {
			properties &&
				addSchemaOptionsProperty(properties.schema, {
					removable: { type: "boolean", default: true },
					addable: { type: "boolean", default: true },
				});

			(properties?.uiSchema as Layout).elements.push(
				createPropertyControl("#/properties/options/properties/removable")
			);
			(properties?.uiSchema as Layout).elements.push(createPropertyControl("#/properties/options/properties/addable"));
		}

		if (
			elementSchema?.schema.type === "string" &&
			!elementSchema?.schema.format &&
			uiElement.type === "Control" &&
			properties
		) {
			addSchemaOptionsProperty(properties.schema, {
				multi: { type: "boolean" },
			});
			(properties?.uiSchema as Layout).elements.push(createPropertyControl("#/properties/options/properties/multi"));
		}

		setProperties(properties);
	}, [propertiesService, schema, uiElement]);

	if (!selection) return <NoSelection />;

	return properties ? (
		<JsonForms
			data={data}
			schema={properties.schema}
			uischema={properties.uiSchema}
			onChange={updateProperties}
			renderers={propertyRenderers}
			cells={materialCells}
		/>
	) : (
		<NoProperties />
	);
};
const NoSelection = () => <div>No selection</div>;
const NoProperties = () => <div>Selected element does not have any configurable properties.</div>;
