import * as React from "react";
import { LayoutProps, Resolve, UISchemaElement } from "@jsonforms/core";
import { JsonFormsDispatch, JsonFormsStateContext, withJsonFormsContext } from "@jsonforms/react";
import TrashCan from "@ingka/ssr-icon/paths/trash-can";
import ChevronDown from "@ingka/ssr-icon/paths/chevron-down";
import styled from "styled-components";
import SSRIcon from "@ingka/ssr-icon";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import Button from "@ingka/button";
import Tooltip from "@ingka/tooltip";
import arrowUp from "@ingka/ssr-icon/paths/arrow-up";
import arrowDown from "@ingka/ssr-icon/paths/arrow-down";

interface DispatchPropsOfCardRenderer {
	onRemove(): () => void;
	onMoveUp(): () => void;
	onMoveDown(): () => void;
}

interface CardRendererProps extends LayoutProps, DispatchPropsOfCardRenderer {
	index: number;
	data: number;
	label?: string;
	removable?: boolean;
}

const ItemsWrapper = styled.div`
	margin-bottom: 0.75rem;
	margin-top: 0.75rem;
`;

const StyledButton = styled(Button)`
	margin-right: 0.75rem;
`;

const ItemsRendererWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding-left: 0.1rem;
	.MuiPaper-root {
		box-shadow: none;
		width: 100%;
	}
`;

const ArrowButtonsWrapper = styled.div`
	display: flex;
`;

const StyledAccordion = styled(Accordion)`
	border: 1px solid #dfdfdf;
	.MuiAccordionDetails-root {
		display: block;
	}
	.MuiAccordionSummary-root {
		.MuiAccordionSummary-expandIcon.Mui-expanded {
			transform: none;
			.chevron-down {
				transform: rotate(180deg);
			}

			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
`;

export const CardRenderer = (props: CardRendererProps) => {
	const { uischema, schema, path, renderers, cells, onRemove, index, label, enabled, data, onMoveDown, onMoveUp, removable } =
		props;
	const elements = uischema.options?.["detail"] ? uischema.options?.["detail"]["elements"] : [];

	const itemsToRender = elements.map((element: UISchemaElement | undefined, index: number) => {
		return (
			<JsonFormsDispatch
				schema={schema}
				uischema={element}
				path={path}
				enabled={enabled}
				renderers={renderers}
				cells={cells}
				key={index}
			/>
		);
	});

	return (
		<ItemsWrapper>
			<ItemsRendererWrapper>
				<StyledAccordion>
					<AccordionSummary
						expandIcon={<SSRIcon onClick={() => {}} className="chevron-down" paths={ChevronDown} />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<h3>{label || `Item ${index + 1}`}</h3>
					</AccordionSummary>
					<AccordionDetails>{itemsToRender}</AccordionDetails>
				</StyledAccordion>
				<ArrowButtonsWrapper>
					<div>
						<Tooltip tooltipText="Move up">
							<Button
								type="tertiary"
								onClick={onMoveUp}
								iconOnly
								small
								ssrIcon={arrowUp}
								disabled={index === 0 || !enabled}
								xsmallIconOnly
							/>
						</Tooltip>
					</div>
					<div>
						<Tooltip tooltipText="Move down">
							<Button
								type="tertiary"
								onClick={onMoveDown}
								iconOnly
								small
								ssrIcon={arrowDown}
								disabled={(index === data - 1) || !enabled}
								xsmallIconOnly
							/>
						</Tooltip>
					</div>
				</ArrowButtonsWrapper>
				<Tooltip tooltipText="Remove">
					<StyledButton 
						type="tertiary" 
						onClick={onRemove} 
						iconOnly 
						small 
						ssrIcon={TrashCan} 
						disabled={!enabled || removable === false} 
					/>
				</Tooltip>
			</ItemsRendererWrapper>
		</ItemsWrapper>
	);
};

const _cardRenderer = React.memo(CardRenderer);

const withContextToCardRenderd = (
	Component: React.ComponentType<CardRendererProps>
): React.ComponentType<CardRendererProps> => {
	return ({ ctx, props }: JsonFormsStateContext & CardRendererProps) => {
		const childData = Resolve.data(ctx.core.data, props.path);
		const label: string | undefined =
			childData?.[props.uischema?.options?.elementLabelProp] || childData?.[Object.keys(childData)[0]] || undefined;
		return <Component {...props} label={typeof label === "string" ? label : `Item-${props.index + 1}`} />;
	};
};

const withCustomProps = (Component: React.ComponentType<CardRendererProps>) => {
	return withJsonFormsContext(withContextToCardRenderd(Component));
};

export default withCustomProps(_cardRenderer);
