import * as React from "react";
import styled from "styled-components";

import { LayoutProps, UISchemaElement } from "@jsonforms/core";
import { JsonFormsDispatch, JsonFormsStateContext, withJsonFormsContext } from "@jsonforms/react";
import TrashCan from "@ingka/ssr-icon/paths/trash-can";
import Button from "@ingka/button";
import Tooltip from "@ingka/tooltip";
import arrowUp from "@ingka/ssr-icon/paths/arrow-up";
import arrowDown from "@ingka/ssr-icon/paths/arrow-down";

interface DispatchPropsOfCardRenderer {
	onRemove(): () => void;
	onMoveUp(): () => void;
	onMoveDown(): () => void;
}

interface CardRendererProps extends LayoutProps, DispatchPropsOfCardRenderer {
	index: number;
	data: number;
	removable?: boolean;
}

const ItemsWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.75rem;
	justify-content: center;
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 0.5rem;
`;

const ItemsRendererWrapper = styled.div`
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	padding: 0.75rem;
	width: 100%;
`;

const ArrowButtonsWrapper = styled.div`
	display: flex;
`;

export const CardRenderer = (props: CardRendererProps) => {
	const { uischema, schema, path, renderers, cells, onRemove, enabled, index, data, onMoveDown, onMoveUp, removable } = props;

	const elements = uischema.options?.["detail"] ? uischema.options?.["detail"]["elements"] : [];
	const itemsToRender = elements.map((element: UISchemaElement | undefined, index: number) => {
		return (
			<JsonFormsDispatch
				schema={schema}
				uischema={element}
				path={path}
				enabled={enabled}
				renderers={renderers}
				cells={cells}
				key={index}
			/>
		);
	});
	return (
		<ItemsWrapper>
			<ItemsRendererWrapper>{itemsToRender}</ItemsRendererWrapper>
			<ButtonWrapper>
				<ArrowButtonsWrapper>
					<div>
						<Tooltip tooltipText="Move up">
							<Button
								type="tertiary"
								onClick={onMoveUp}
								iconOnly
								small
								ssrIcon={arrowUp}
								disabled={index === 0 || !enabled}
								xsmallIconOnly
							/>
						</Tooltip>
					</div>
					<div>
						<Tooltip tooltipText="Move down">
							<Button
								type="tertiary"
								onClick={onMoveDown}
								iconOnly
								small
								ssrIcon={arrowDown}
								disabled={(index === data - 1) || !enabled}
								xsmallIconOnly
							/>
						</Tooltip>
					</div>
				</ArrowButtonsWrapper>
				<Tooltip tooltipText="Remove">
					<Button type="tertiary" onClick={onRemove} iconOnly small ssrIcon={TrashCan} disabled={!enabled || removable === false} />
				</Tooltip>
			</ButtonWrapper>
		</ItemsWrapper>
	);
};

const withContextToCardRenderd = (
	Component: React.ComponentType<CardRendererProps>
): React.ComponentType<CardRendererProps> => {
	return ({ ctx, props }: JsonFormsStateContext & CardRendererProps) => {
		return <Component {...props} />;
	};
};

const withCustomProps = (Component: React.ComponentType<CardRendererProps>) => {
	return withJsonFormsContext(withContextToCardRenderd(Component));
};

export default withCustomProps(CardRenderer);
