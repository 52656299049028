/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import InlineMessage from "@ingka/inline-message";
import Loading, { LoadingBall } from "@ingka/loading";
import { UserData } from "../../services/users";
import { 
	LoadingWrapper, 
	StyledCommercialMessage
} from "../styles";
import { TableWrapper, StyledModalHeader } from "../styles";
import Search from "@ingka/search";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import arrowLeftToLine from "@ingka/ssr-icon/paths/arrow-left-to-line";
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left";
import arrowRightToLine from "@ingka/ssr-icon/paths/arrow-right-to-line";
import arrowRight from "@ingka/ssr-icon/paths/arrow-right";
import sortAscending from "@ingka/ssr-icon/paths/sort-ascending";
import sortDescending from "@ingka/ssr-icon/paths/sort-descending";
import SSRIcon from "@ingka/ssr-icon";
import Modal, { ModalFooter, Prompt } from "@ingka/modal";
import Text from "@ingka/text";
import Tooltip from "@ingka/tooltip";
import Hyperlink from "@ingka/hyperlink";


import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable,
	CellContext,
	getSortedRowModel,
	SortingState,
} from "@tanstack/react-table";

const StyledMessage = styled(StyledCommercialMessage)`
	margin-left: 0.5rem;
`;

const StyledTableWrapper = styled(TableWrapper)`
	margin-top: 20px;
	border-right: transparent;
	border-left: transparent;
	border-top: transparent;
	min-height: 25rem;
	.table-head,
	.table-cell {
		text-align: unset;
		margin: 0;
		padding: 0.5rem;
		border-right: transparent !important;
		border-left: transparent !important;
		background-color: white;
	}
`;

const StyledSearch = styled(Search)`
	display: flex;
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
`;

const StyledHeader = styled.h2`
	padding-top: 0.3rem;
`;

const StyledFooter = styled.div`
	display: flex;
	gap: 1rem;
	padding: 0.5rem;
	justify-content: end;
`;

const StyledFooterPageInfo = styled.span`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const SortedColumnHeader = styled.div`
	cursor: pointer;
`;

const StyledHyperlink = styled(Hyperlink)`
	font-size: 0.7rem;
	margin-left: 0.5rem;
`;

const StyledInlineMessage = styled(InlineMessage)`
	margin-top: 1rem;
`;

interface UsersLayoutProps {
	loading: boolean;
	showModal?: boolean;
	setShowModal?: (value: boolean) => void;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	data: UserData[];
	setSearchUserValue: (value: string) => void;
	showAllApplications: boolean;
	setShowAllApplications: (value: boolean) => void;
	showRemoveUserModal: boolean;
	setShowRemoveUserModal: (value: boolean) => void;
	removeUserLoading: boolean;
	onDeleteUser: () => void;
	userIdToDelete: string;
	setUserIdToDelete: (value: string) => void;
	applicationsLastAdmin: string[] | undefined;
}

const Layout: React.FC<UsersLayoutProps> = ({
	loading,
	data,
	setShowModal,
	showModal,
	toastMessage,
	setToastMessage,
	setSearchUserValue,
	showAllApplications,
	setShowAllApplications,
	showRemoveUserModal,
	setShowRemoveUserModal,
	removeUserLoading,
	onDeleteUser,
	userIdToDelete,
	setUserIdToDelete,
	applicationsLastAdmin
}) => {
	const [rowSelection, setRowSelection] = React.useState({});
	const [sorting, setSorting] = React.useState<SortingState>([
		{
			id: "name-column", 
			desc: false
		}
	]);

	React.useEffect(() => {
		setRowSelection({});
		setSorting([{
			id: "name-column", 
			desc: false
		}]);
	}, [data]);

	const columns = React.useMemo<ColumnDef<UserData>[]>(
		() => [
			{
				header: "Name",
				accessorKey: "name",
				id: "name-column",
				cell: ({ row }: CellContext<UserData, unknown>) => {
					const rowValue = row.getValue("name-column") as string;
					return <strong>{rowValue?.split("(")[0]}</strong>;
				},
			},
			{
				header: "Applications",
				size: 200,
				accessorKey: "applications",
				id: "applications",
				enableSorting: false,
				cell: ({ row }: CellContext<UserData, unknown>) => {
					const rowValue = row.getValue("applications") as any;
					let userApplications = [] as any;

					function showApplications() {
						for (let i = 0; i < Object.keys(rowValue).length; i++) {
							let appName = Object.keys(rowValue)[i];
							let tooltipText = "ReadOnly";

							if (rowValue[appName].permissions === "admin") {
								tooltipText = "Admin";
							} else if (rowValue[appName].markets && Object.keys(rowValue[appName].markets).length) {
								tooltipText = "Maintainer";
							}

							if (i < 5 || showAllApplications) {
								userApplications.push(<Tooltip position="top" tooltipText={tooltipText}>
										<StyledMessage message={appName} variant="family" subtle />;
									</Tooltip>);
							} else {
								userApplications.push(<StyledHyperlink 
										text={`+ ${Object.keys(rowValue).length - 5}`}
										onClick={() => setShowAllApplications(true)}
								/>);
									break;
							}

						}
					}


					if (!rowValue) {
						return <StyledMessage message="No applications" variant="new" subtle />;
					} else {
						showApplications();
						return <>
							{userApplications}
							</>;
					}
				},
			},
			{
				header: "Mail",
				id: "email-column",
				accessorKey: "email",
			},
			{
				header: "Last Login",
				id: "last-login",
				enableSorting: false,
				accessorKey: "last-login",
				cell: ({ row }: CellContext<UserData, unknown>) => {
					const rowValue = row.getValue("last-login") as any;
					return (
						<div>
							<Text>
								{rowValue ? new Date(rowValue._seconds * 1000).toLocaleDateString() : "-"}
							</Text>
						</div>
					);
				},
			},
			{
				header: "Action",
				id: "action-column",
				enableSorting: false,
				accessorKey: "id",
				cell: ({ row }: CellContext<UserData, unknown>) => {
					const rowValue = row.getValue("action-column") as string;
					return (
						<div>
							<Button
								id={`remove-user-btn-${rowValue}`}
								text="Remove"
								small
								iconPosition="leading"
								type="tertiary"
								ssrIcon={trashCan}
								onClick={() => {
									setUserIdToDelete(rowValue);
									setShowRemoveUserModal(true);
								}}
							/>
						</div>
					);
				},
			},
		],
		[
			setShowModal, showAllApplications
		]
	);

	const table = useReactTable({
		data: data,
		columns,
		initialState: {
			pagination: {
				pageSize: 10,
			}
		},
		state: {
			rowSelection,
			sorting
		},
		onSortingChange: setSorting,
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	if (loading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Users" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	if (!loading && !data) {
		return (
			<LoadingWrapper>
				<InlineMessage title="No Data" body={"Seems like you don't have any data"} variant="negative" />
			</LoadingWrapper>
		);
	}

	return (
		<>
			<StyledHeaderWrapper>
				<StyledHeader>Total Users ({data ? data.length : 0})</StyledHeader>
				<StyledSearch
					id="search"
					onSearch={(_, { value }) => setSearchUserValue(value)}
					onChange={(_, { value }) => setSearchUserValue(value)}
					onClear={() => setSearchUserValue("")}
				/>
			</StyledHeaderWrapper>
			<StyledTableWrapper>
				<table className="table" id="allFlaggaUsersTable">
					<thead>
						{table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map(header => {
									return (
										<th key={header.id} colSpan={header.colSpan} style={{ width: `${header.getSize()}px` }}>
											{header.column.getCanSort() ? (
												<SortedColumnHeader
													onClick={header.column.getToggleSortingHandler()}
													title={
														header.column.getCanSort()
															? header.column.getNextSortingOrder() === "asc"
															? "Sort ascending"
															: header.column.getNextSortingOrder() === "desc"
																? "Sort descending"
																: "Clear sort"
															: undefined
													}
												>
													{flexRender(
														header.column.columnDef.header + " ",
														header.getContext()
													)}
													{{
														asc: <SSRIcon paths={sortAscending} viewBox="0 0 24 24" />,
														desc: <SSRIcon paths={sortDescending} viewBox="0 0 24 24" />,
													}[header.column.getIsSorted() as string] ?? null}
												</SortedColumnHeader>
											) : <div>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
												</div>}
										</th>
									);
							})}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => {
							return (
								<tr key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<td key={cell.id} style={{ textAlign: "center" }}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				{data.length > 10 && (
					<StyledFooter>
						<Button
							iconOnly
							small
							type="tertiary"
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}
							ssrIcon={arrowLeftToLine}
						></Button>
						<Button
							small
							text="Previous"
							type="tertiary"
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
							ssrIcon={arrowLeft}
						></Button>
						<StyledFooterPageInfo>
							<div>Page</div>
							<strong>
								{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
							</strong>
						</StyledFooterPageInfo>
						<Button
							small
							text="Next"
							type="tertiary"
							iconPosition="trailing"
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
							ssrIcon={arrowRight}
						></Button>
						<Button
							small
							iconOnly
							type="tertiary"
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
							ssrIcon={arrowRightToLine}
						></Button>
						<select
							value={table.getState().pagination.pageSize}
							onChange={(e) => {
								table.setPageSize(Number(e.target.value));
							}}
						>
							{[10, 20, 30].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
					</StyledFooter>
				)}
			</StyledTableWrapper>
			<Modal
				data-testid="remove-user-modal"
				focusLockProps={{ locked: false }}
				visible={showRemoveUserModal}
				handleCloseBtn={() => setShowRemoveUserModal(false)}
				escapable={false}
			>
				<Prompt
					header={<StyledModalHeader title="Remove user" />}
					footer={
						<ModalFooter>
							<Button text="Close" type="emphasised" />
							<Button
								data-testid="remove-user-btn"
								text="Remove user"
								loading={removeUserLoading}
								onClick={() => {
									onDeleteUser();
								}}
							/>
						</ModalFooter>
					}
					title=""
					titleId={"RemoveUsersTitle"}
				>
					<p>
						Do you want to remove <strong>{data.find(user => user.id === userIdToDelete)?.name?.split("(")[0]}</strong> access to Flagga?
						{applicationsLastAdmin && 
							<StyledInlineMessage 
								data-testid="last-admin-warning"
								title="Warning" 
								body={
									<>
										This user is the last admin for the following applications:
										<strong> {applicationsLastAdmin.join(", ")}</strong>
									</>
								}
								variant="cautionary" 
							/>}
					</p>
				</Prompt>
			</Modal>
		</>
	);
};

export default Layout;
