/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_ACTION_ADD_USER, ROUTE_HOME } from "../../utils/constants";
import useStore, {
	activeApplicationSelector,
	applicationUsersSelector,
	setApplicationUsersSelector,
	userSelector,
} from "../../store";
import useAuthentication from "../../hooks/useAuthentication";
import { getAppUsersList, UserApplicationData, ApplicationUserRequests } from "../../services/users";
import Layout from "./Layout";
import useGetMarkets from "../../hooks/useGetMarkets";
import { updateUser } from "./utils";
import { ResponseMessage, UserPermissions } from "../../utils/constants";
import useGetUiSchemasName from "./useGetUiSchemasName";
import { ModalPrompt } from "./types";
import { userRequestType } from "./utils";

const ManageUsersRequested: React.FC = () => {
	const activeApplication = useStore(activeApplicationSelector);
	const { data: marketsData, loading: marketsLoading, getMarketsData } = useGetMarkets();
	const { data: uiSchemas, loading: uiSchemasLoading } = useGetUiSchemasName();
	const [appUsersLoading, setAppUsersLoading] = React.useState<boolean>(true);
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [showDeclineModal, setShowDeclineModal] = React.useState<boolean>(false);
	const [modalHeading, setModalHeading] = React.useState<string>("");
	const [modalButtonLabel, setModalButtonLabel] = React.useState<string>("");
	const [selectedUser, setSelectedUser] = React.useState<UserApplicationData | undefined>(undefined);
	const [addingNewUser, setAddingNewUser] = React.useState<boolean>(false);
	const [onboardingUser, setOnboardingUser] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [requestEmail, setRequestEmail] = React.useState<string | undefined>(undefined);
	const [selectedMarkets, setSelectedMarkets] = React.useState<string[] | undefined>([]);
	const [selectedRequestType, setSelectedRequestType] = React.useState<"application" | "markets" | "role" | undefined>(
		undefined
	);
	const [actionInProgress, setActionInProgress] = React.useState<boolean>(false);
	const [toggleUserTypeBtnIndex, setToggleUserTypeBtnIndex] = React.useState<number>(0);

	const location = useLocation();

	const email = new URLSearchParams(location.search).get("email");

	const action = new URLSearchParams(location.search).get("action");
	const requestType = new URLSearchParams(location.search).get("requestType");

	const [prompt, setPrompt] = React.useState<ModalPrompt>({
		title: "string",
		body: "",
		show: false,
		onClose: () => {},
	});

	const currentUser = useStore(userSelector);

	const { getToken, handleLogout } = useAuthentication();
	const navigate = useNavigate();

	const [searchParams, setSearchParam] = useSearchParams();

	const setApplicationUsers = useStore(setApplicationUsersSelector);
	const applicationUsers = useStore(applicationUsersSelector);

	const loading = React.useMemo(
		() => marketsLoading || uiSchemasLoading || appUsersLoading,
		[marketsLoading, uiSchemasLoading, appUsersLoading]
	);

	const currentUserIsAdmin = React.useMemo(
		() =>
			(!!activeApplication &&
				!!currentUser &&
				currentUser.user.applications?.[activeApplication.id]?.permissions === UserPermissions.Admin) ||
			currentUser?.user["flagga-admin"],
		[activeApplication, currentUser]
	);

	React.useEffect(() => {
		if (activeApplication?.id) {
			getMarketsData(activeApplication.id);
		}
	}, [activeApplication?.id, getMarketsData]);

	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			try {
				setAppUsersLoading(true);
				const { data: usersData } = await getAppUsersList(token, activeApplication?.id);
				setApplicationUsers(usersData.data);
			} catch (error: any) {
				setApplicationUsers(null);
				if (error?.response?.status == 401) {
					handleLogout();
				}
			} finally {
				setAppUsersLoading(false);
				if (
					searchParams &&
					Object.fromEntries(searchParams.entries()) &&
					Object.fromEntries(searchParams.entries()).requestType
				) {
					setToggleUserTypeBtnIndex(
						Object.keys(userRequestType).indexOf(Object.fromEntries(searchParams.entries()).requestType)
					);
				} else {
					setToggleUserTypeBtnIndex(Object.keys(userRequestType).indexOf("application"));
				}
			}
		})();

		return () => {
			// cancel the subscription
			setApplicationUsers(null);
			setAppUsersLoading(false);
		};
	}, [activeApplication, getToken, handleLogout, searchParams, setApplicationUsers]);

	React.useEffect(() => {
		if (!selectedRequestType && requestType && ["application", "markets", "role"].includes(requestType)) {
			setSelectedRequestType(requestType as "application" | "markets" | "role");
		}
	}, [requestType, selectedRequestType]);

	React.useEffect(() => {
		for (let index = 0; index <= Object.values(userRequestType).length; index++) {
			let usersRequestsType = applicationUsers?.requests?.filter(
				(userRequest) => userRequest.requestType === Object.keys(userRequestType)[index]
			);
			if (usersRequestsType && usersRequestsType.length > 0) {
				setToggleUserTypeBtnIndex(index);
				break;
			}
		}
	}, [applicationUsers?.requests]);

	const currentUserSelected = React.useMemo(
		() => !!selectedUser && !!currentUser && currentUser.user.id === selectedUser.id,
		[currentUser, selectedUser]
	);

	const usersInApplication = React.useMemo(() => {
		return applicationUsers?.onboardedUsers || [];
	}, [applicationUsers?.onboardedUsers]);

	const hasChangedUser = true;

	const applicationAccessRequest = React.useMemo(() => {
		let userRequests = applicationUsers?.requests;
		if (toggleUserTypeBtnIndex === Object.keys(userRequestType).indexOf("application")) {
			userRequests = userRequests?.filter(
				(userRequest: ApplicationUserRequests) => userRequest.requestType === "application"
			);
		} else if (toggleUserTypeBtnIndex === Object.keys(userRequestType).indexOf("role")) {
			userRequests = userRequests?.filter((userRequest: ApplicationUserRequests) => userRequest.requestType === "role");
		} else if (toggleUserTypeBtnIndex === Object.keys(userRequestType).indexOf("markets")) {
			userRequests = userRequests?.filter(
				(userRequest: ApplicationUserRequests) => userRequest.requestType === "markets"
			);
		}
		return userRequests || [];
	}, [applicationUsers?.requests, toggleUserTypeBtnIndex]);

	const _marketsData = React.useMemo(() => {
		if (activeApplication && selectedUser) {
			return marketsData?.permittedMarkets;
		}
	}, [selectedUser, activeApplication, marketsData]);

	const isAdmin = React.useMemo(
		() =>
			!!activeApplication &&
			!!selectedUser &&
			selectedUser.applications?.[activeApplication.id]?.permissions === UserPermissions.Admin,
		[activeApplication, selectedUser]
	);

	const checkNewUpdatesForUser = React.useCallback(() => {
		let existingUser = usersInApplication.find((user) => user.email === selectedUser?.email);
		if (selectedUser && existingUser && selectedUser.applications && Object.keys(selectedUser.applications)) {
			for (let i = 0; i < Object.keys(selectedUser.applications).length; i++) {
				let app = Object.keys(selectedUser.applications)[i];
				//first check if the user is read only and want to have one more market but admin removed it
				if (
					existingUser.applications[app] &&
					existingUser.applications[app].permissions === UserPermissions.Maintainer &&
					(!existingUser.applications[app].markets ||
						Object.keys(existingUser.applications[app].markets || {}).length === 0) &&
					selectedUser.applications[app].permissions === UserPermissions.Maintainer &&
					(!selectedUser.applications[app].markets ||
						Object.keys(selectedUser.applications[app].markets || {}).length === 0)
				) {
					return false;
				}
				//check if user is a maintainer and have some markets and applies for another one but admin removed it
				if (
					existingUser.applications[app] &&
					existingUser.applications[app].permissions === UserPermissions.Maintainer &&
					existingUser.applications[app].markets &&
					Object.keys(existingUser.applications[app].markets || {}).length > 0 &&
					selectedUser.applications[app].existingMarkets &&
					(!selectedUser.applications[app].markets ||
						Object.keys(selectedUser.applications[app].markets || {}).length === 0)
				) {
					return false;
				}
				//check if user applies for admin role, bur application admin doesnt approve it
				if (
					existingUser.applications[app] &&
					existingUser.applications[app].permissions === UserPermissions.Maintainer &&
					existingUser.applications[app].requestedPermissions === UserPermissions.Admin &&
					selectedUser.applications[app].permissions === UserPermissions.Maintainer &&
					!selectedUser.applications[app].markets
				) {
					return false;
				}
			}
			return true;
		} else {
			//if user is not existing return true, no check is needed
			return true;
		}
	}, [selectedUser, usersInApplication]);

	const handleClearURLProps = React.useCallback(() => {
		const params = Object.fromEntries(searchParams.entries());
		params.action && delete params.action;
		params.email && delete params.email;
		setSearchParam({ ...params });
	}, [searchParams, setSearchParam]);

	const onFinalSubmit = React.useCallback(async () => {
		const isAppAdmin = selectedUser?.applications?.[activeApplication!.id]?.permissions === "admin";

		const hasMarketsAssigned = activeApplication?.allowReadOnly
			? true
			: selectedUser?.applications?.[activeApplication!.id]?.markets &&
			  Object.keys(selectedUser?.applications?.[activeApplication!.id]?.markets || {}).length > 0;

		let newUpdatesForUser = checkNewUpdatesForUser();
		if (!newUpdatesForUser) {
			setToastMessage(
				"This user already has selected privileges. If you want to decline his request, please use decline option"
			);
			return;
		} else {
			if (selectedUser && activeApplication && (isAppAdmin || hasMarketsAssigned) && selectedRequestType) {
				try {
					setActionInProgress(true);
					let activeAppForUser = selectedUser.applications?.[activeApplication.id];

					if (!activeAppForUser && selectedRequestType === "application") {
						activeAppForUser = {
							permissions: UserPermissions.Maintainer,
						};
					}
					if (activeAppForUser.markets && Object.keys(activeAppForUser.markets).length === 0) {
						delete activeAppForUser.markets;
					}

					if (activeAppForUser && selectedRequestType === "application") {
						activeAppForUser.permissions =
							selectedUser?.applications?.[activeApplication!.id]?.permissions || UserPermissions.Maintainer;
					}

					const token = await getToken();
					const userUpdateInfo = {
						user: selectedUser,
						userApplication: {
							[activeApplication.id]: activeAppForUser,
						},
						token,
						requestType: selectedRequestType,
					};

					const response = await updateUser(userUpdateInfo);
					if (response.message === ResponseMessage.Success || response.message === "Success") {
						const { data: usersAppData } = await getAppUsersList(token, activeApplication?.id);
						setApplicationUsers(usersAppData.data);
						if (action) {
							handleClearURLProps();
						}
						setToastMessage("Updated Successfully.");
						setShowModal(false);
					}
				} catch (err: any) {
					setToastMessage(err.message);
				} finally {
					setActionInProgress(false);
				}
			} else if (selectedUser && activeApplication && !activeApplication.allowReadOnly) {
				setToastMessage(
					"This application doesn't allow read only mode. Please assign UI-schema at least for one market"
				);
			}
		}
	}, [
		selectedUser,
		activeApplication,
		checkNewUpdatesForUser,
		selectedRequestType,
		getToken,
		setApplicationUsers,
		action,
		handleClearURLProps,
	]);

	React.useEffect(() => {
		if (
			activeApplication &&
			uiSchemas &&
			email &&
			action == ROUTE_ACTION_ADD_USER &&
			!appUsersLoading &&
			Array.isArray(applicationUsers?.requests) &&
			applicationUsers?.requests.length
		) {
			setModalHeading(requestType === "application" ? "Onboard New User" : "Update User");
			setModalButtonLabel(requestType === "application" ? "Onboard User" : "Update User");
			let applicationAccessRequest = (applicationUsers?.requests || []) as ApplicationUserRequests[];
			let userRequestData = applicationAccessRequest?.find((userData) => userData.email === email);
			if (activeApplication && uiSchemas && userRequestData) {
				setSelectedUser({
					email: userRequestData?.email || "",
					id: userRequestData?.id || "",
					applications: {
						[activeApplication.id]: {
							...(requestType === "application" && { permissions: "maintainer" }),
							markets: userRequestData?.requestedMarkets
								? userRequestData.requestedMarkets
										.map((item: string) => {
											return {
												[item]:
													uiSchemas.find((item) => item.uiSchemaName === "uischema")?.uiSchemaName ||
													uiSchemas[0].uiSchemaName,
											};
										})
										.reduce((acc: object, obj: any) => Object.assign(acc, obj), {})
								: undefined,
							...(requestType === "markets" && { existingMarkets: userRequestData?.existingMarkets }),
						},
					},
				});
			} else {
				setSelectedUser(undefined);
				if (!userRequestData) {
					setPrompt({
						show: true,
						title: "Request not Found!",
						body: (
							<p>
								Request from <strong>{email}</strong> not found.
							</p>
						),
						onClose: () => {
							setPrompt({ show: false, title: "", body: "", onClose: () => {} });
						},
					});
				}
			}
			if (userRequestData) {
				setSelectedRequestType(requestType as "application" | "markets");
				setAddingNewUser(false);
				setOnboardingUser(true);
				setShowModal(true);
			}
		}
	}, [applicationUsers, appUsersLoading, action, activeApplication, email, requestType, uiSchemas, navigate]);

	return (
		<Layout
			loading={loading}
			usersInApplication={usersInApplication}
			showModal={showModal}
			setShowModal={setShowModal}
			modalHeading={modalHeading}
			setModalHeading={setModalHeading}
			modalButtonLabel={modalButtonLabel}
			setModalButtonLabel={setModalButtonLabel}
			marketsData={_marketsData}
			uiSchemas={uiSchemas}
			selectedUser={selectedUser}
			setSelectedUser={setSelectedUser}
			addingNewUser={addingNewUser}
			setAddingNewUser={setAddingNewUser}
			onFinalSubmit={onFinalSubmit}
			isAdmin={isAdmin}
			currentUserSelected={currentUserSelected}
			currentUser={currentUser}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			handleClearURLProps={handleClearURLProps}
			appUsersLoading={appUsersLoading}
			applicationAccessRequest={applicationAccessRequest}
			setAppUsersLoading={setAppUsersLoading}
			activeApplication={activeApplication}
			showDeclineModal={showDeclineModal}
			setShowDeclineModal={setShowDeclineModal}
			requestEmail={requestEmail}
			setRequestEmail={setRequestEmail}
			currentUserIsAdmin={currentUserIsAdmin}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			hasChangedUser={hasChangedUser}
			onboardingUser={onboardingUser}
			setOnboardingUser={setOnboardingUser}
			setSelectedRequestType={setSelectedRequestType}
			selectedRequestType={selectedRequestType}
			actionInProgress={actionInProgress}
			toggleUserTypeBtnIndex={toggleUserTypeBtnIndex}
			setToggleUserTypeBtnIndex={setToggleUserTypeBtnIndex}
			prompt={prompt}
		/>
	);
};

export default ManageUsersRequested;
