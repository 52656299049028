import * as React from "react";

import Layout from "./Layout";
import { Schema } from "../../utils/types";

interface DashboardModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	schema: Schema;
	onChange: (schema: Schema) => void;
	schemaKey: string;
}

const DashboardModal: React.FC<DashboardModalProps> = ({ showModal, setShowModal, schema, onChange, schemaKey }) => {
	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			schema={schema}
			onChange={onChange}
			schemaKey={schemaKey}
		/>
	);
};

export default DashboardModal;
