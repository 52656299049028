/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";

import useStore, { Application, userSelector } from "../../../store";
import { UserApplications, UserData } from "../../../services/users";
import Layout from "./Layout";
import useGetAdmins from "../../../hooks/useGetAdmins";

interface AdminsModalProps {
	activeApplication: Application | undefined;
	showModal: boolean;
	setShowModal: (value: boolean) => void;
}

const AdminsModal: React.FC<AdminsModalProps> = ({ activeApplication, showModal, setShowModal }) => {
	const currentUser = useStore(userSelector);

	const [adminsListLoading, setAdminsListLoading] = React.useState<boolean>(false);
	const [userApplications, setUserApplications] = React.useState<UserApplications | undefined>(undefined);
	const [selectedApplicationId, setSelectedApplicationId] = React.useState<string | undefined>(undefined);
	const {
		data: applicationAdminsData,
		loading: applicationAdminsLoading,
		error: applicationAdminsError,
	} = useGetAdmins(selectedApplicationId);
	const [adminsList, setAdminsList] = React.useState<UserData[] | undefined>(applicationAdminsData);
	const [toast, setToast] = React.useState<string>("");

	React.useEffect(() => {
		setAdminsListLoading(applicationAdminsLoading);
		setAdminsList(applicationAdminsData);

		if (applicationAdminsError) {
			setToast(applicationAdminsError);
		}
	}, [applicationAdminsData, applicationAdminsError, applicationAdminsLoading]);

	React.useEffect(() => {
		setUserApplications(currentUser?.user?.applications ? currentUser.user.applications : undefined);
	}, [currentUser?.user?.applications]);

	React.useEffect(() => {
		setSelectedApplicationId(activeApplication?.id);
	}, [activeApplication?.id]);

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			adminsList={adminsList}
			activeApplication={activeApplication}
			selectedApplicationId={selectedApplicationId}
			setSelectedApplicationId={setSelectedApplicationId}
			userApplications={userApplications}
			adminsListLoading={adminsListLoading}
			toast={toast}
			setToast={setToast}
		/>
	);
};

export default AdminsModal;
