/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";

import { TabContent } from "../../core/components";
import { usePaletteService, useSchema } from "../../core/context";
import { SchemaElement } from "../../core/model";
import { SchemaTreeView } from "./SchemaTree";
import { UIElementsTree } from "./UIElementsTree";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
	palettePanel: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
}));

export interface PaletteTab {
	name: string;
	Component: React.ReactElement;
}

export interface PalettePanelProps {
	paletteTabs?: PaletteTab[];
	sortingUISchema: "name" | "type";
	setSortingUISchema: (sorting: "name" | "type") => void;
	hidePalettePanel: boolean;
	setHidePalettePanel: (hidePalettePanel: boolean) => void;
}

export const defaultPalettePanelTabs: PaletteTab[] = [
	//{ name: "UI Schema", Component: <UISchemaPanel /> },
];

export const PalettePanel: React.FC<PalettePanelProps> = ({ paletteTabs, sortingUISchema, setSortingUISchema, hidePalettePanel, setHidePalettePanel }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue);
	};
	const schema: SchemaElement | undefined = useSchema();
	const paletteService = usePaletteService();
	const classes = useStyles();

	return (
		<div className={classes.palettePanel}>
			<UIElementsTree 
				elements={paletteService.getPaletteElements()} 
				hidePalettePanel={hidePalettePanel} 
				setHidePalettePanel={setHidePalettePanel}
			/>
			<SchemaTreeView 
				schema={schema} 
				hidePalettePanel={hidePalettePanel}
				sortingUISchema={sortingUISchema} 
				setSortingUISchema={setSortingUISchema} 
			/>
		</div>
	);
};
