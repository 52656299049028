import React from "react";
import styled from "styled-components";

import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";
import Button from "@ingka/button";

import { validatePropertyKey } from "./utils";
import { Schema } from "../../utils/types";
import WarningModal from "../../../WarningModal";

interface Props {
	onChange: (text: string) => void;
	placeholder?: string;
	value?: string;
	label?: string;
	type?: string;
	disabled?: boolean;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
	schemaKeys?: string[];
}

interface Validation {
	id: string;
	msg: string;
	type: string;
}

export interface InputValidationType {
	shouldValidate: boolean;
	valid: boolean;
	validation: Validation;
}

interface WarningModalData {
	open: boolean;
	title: string;
	body: string | React.ReactElement<any>;
}

const StyledFormField = styled(FormField)`
	margin-bottom: 2.5rem;
	&.form-field--error {
		margin-bottom: 1.25rem;
	}
`;

const InputWrapper = styled.div`
	min-width: 16rem;
`;

const WARNING_MODAL_INITIAL_DATA = { open: false, title: "", body: "" };

const PropertyKeyInput: React.FunctionComponent<Props> = ({
	onChange,
	placeholder,
	value = "",
	label,
	type = "text",
	disabled,
	setInvalidKeys,
	invalidKeys,
	schemaKeys,
}) => {
	const [localVal, setLocalVal] = React.useState<string>("");
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [warningModal, setWarningModal] = React.useState<WarningModalData>(WARNING_MODAL_INITIAL_DATA);
	const [inputValidation, setInputValidation] = React.useState<InputValidationType>({
		shouldValidate: false,
		valid: true,
		validation: {
			id: "",
			msg: "",
			type: "",
		},
	});

	React.useEffect(() => {
		setLocalVal(value);
	}, [value]);

	React.useEffect(() => {
		const propertyValidation = validatePropertyKey(type === "number" ? localVal.replace(/\D/g, "") : localVal || "");
		if (!propertyValidation.isValid) {
			setInputValidation({
				shouldValidate: true,
				valid: false,
				validation: {
					id: "property-key-error-msg",
					msg: propertyValidation.msg,
					type: "error",
				},
			});
		} else if (propertyValidation.isValid) {
			setInputValidation({
				shouldValidate: false,
				valid: true,
				validation: {
					id: "property-key-error-msg",
					msg: propertyValidation.msg,
					type: "error",
				},
			});
		}
	}, [localVal, type]);

	const onChangeValue = React.useCallback(() => {
		if (localVal === value) {
			return;
		}

		if (localVal === "") {
			setWarningModal({
				open: true,
				title: "Empty keys not allowed!",
				body: <p>Please provide a unique key. Press ok to restore the previous value of the key.</p>,
			});
			return;
		}

		if (schemaKeys?.includes(localVal)) {
			setWarningModal({
				open: true,
				title: "Key Already Exists!",
				body: (
					<p>
						The key <strong>{localVal}</strong> already exists! Press ok to restore the previous value of the key.
					</p>
				),
			});
			return;
		}

		const errorKeys = invalidKeys;
		if (setInvalidKeys) {
			if (!inputValidation.valid) {
				setInvalidKeys([...(errorKeys || []), localVal]);
			} else {
				const newData = errorKeys?.filter((data) => data !== value);
				setInvalidKeys(newData || []);
			}
		}

		onChange(localVal);
	}, [localVal, value, schemaKeys, invalidKeys, setInvalidKeys, onChange, inputValidation.valid]);

	const handleStringChange = React.useCallback((handler: (value: string) => void, type: string) => {
		return (event: React.FormEvent<HTMLElement>): void => {
			handler(
				type === "number" && !/^\d+$/.test((event.target as HTMLInputElement).value)
					? (event.target as HTMLInputElement).value.replace(/\D/g, "")
					: (event.target as HTMLInputElement).value || ""
			);
		};
	}, []);

	return (
		<>
			<InputWrapper>
				<StyledFormField
					shouldValidate={inputValidation.shouldValidate}
					valid={inputValidation.valid}
					validation={inputValidation.validation}
					fieldHelper={{
						id: "helper-msg-id",
						msg: placeholder ? placeholder : "",
					}}
				>
					<InputField
						ref={inputRef}
						label={label}
						id={"localVal"}
						type={type as "text" | "password" | "number"}
						value={localVal}
						onChange={handleStringChange(setLocalVal, type)}
						onBlur={onChangeValue}
						placeholder={placeholder}
						disabled={disabled}
					/>
				</StyledFormField>
			</InputWrapper>
			<WarningModal
				modalTitle={warningModal.title}
				showWarningModal={warningModal.open}
				setShowWarningModal={() => {}}
				modalId="rename-property-modal"
				variant="cautionary"
				dismissable={false}
			>
				<>
					{warningModal.body}
					<Button
						small
						text="OK"
						onClick={() => {
							onChange(value);
							setLocalVal(value);
							setWarningModal(WARNING_MODAL_INITIAL_DATA);
							setTimeout(() => {
								if (inputRef.current && inputRef.current !== null) {
									inputRef?.current?.focus();
								}
							}, 500);
						}}
					/>
				</>
			</WarningModal>
		</>
	);
};

export default PropertyKeyInput;
