import React from "react";
import _ from "lodash/fp";
import chevronUpSmall from "@ingka/ssr-icon/paths/chevron-up-small";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import Button from "@ingka/button";

type Props = {
	onClick?: () => void;
	isCollapsed?: boolean;
	title?: string;
};

const CollapseButton: React.FunctionComponent<Props> = ({ isCollapsed = false, onClick = _.noop, title }) => {
	return (
		<Button
			onClick={onClick}
			type="tertiary"
			small
			iconOnly
			ssrIcon={isCollapsed ? chevronDownSmall : chevronUpSmall}
		/>
	);
};

export default CollapseButton;
