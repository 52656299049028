import { MarketDashboardData, DashboardKeys } from "../../services/dashboard";
import { defaultColumnIndex } from "../../utils/constants";

export const getGroups = (dashboardTableData: MarketDashboardData) => {

	let groupIds: string[] = [];
	let groupIdIndex: { [key: string]: number } = {};
	
	if (dashboardTableData.keys.some((key) => key.groupId && key.groupId.length > 0)) {
		dashboardTableData.keys.map((data: DashboardKeys) => {
			if (data?.groupId && !groupIds.includes(data.groupId)) {
				groupIds.push(data.groupId);
				groupIdIndex[data.groupId] = typeof data.groupIdIndex == "number" ? data.groupIdIndex : defaultColumnIndex;
			} else if (data.groupId && groupIdIndex[data.groupId] == defaultColumnIndex ) {
				groupIdIndex[data.groupId] = typeof data.groupIdIndex == "number" ? data.groupIdIndex : defaultColumnIndex;
			} else if ((!data?.groupId || data?.groupId === "") && !groupIds.includes("Other")) {
				groupIds.push("Other");
			}
		});
	}
	return { groupIds, groupIdIndex };
};

export const capitalizeFirstLetter = (label: string) => {
	return label.charAt(0).toUpperCase() + label.slice(1);
};

export const getLastUpdateDate = (array: any) => {
	const sortArray = array.filter((item: any) => item.timestamp).sort(function(x: any, y: any){
		if (x.timestamp && y.timestamp) {
			return x.timestamp - y.timestamp;
		} 
	});
	return sortArray[sortArray.length - 1] ? sortArray[sortArray.length - 1].timestamp : null;
};

export const sortTableData = (a: any, b: any) => {
	if (a["flagga-label"] < b["flagga-label"]) {
		return -1;
	}
	if (a["flagga-label"] > b["flagga-label"]) {
		return 1;
	}
	return 0;
};

export const sortMarketUsers = (a: any, b: any) => {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
};