import { getUiSchemaList } from "../../services/applications";
import { updateSchemas } from "../../services/schemas";
import { Application } from "../../store";
import { getSchemas } from "../../services/schemas";
import { SchemaData } from "../../hooks/useGetSchemas";
import { ApplicationUiSchema } from "../../services/applications/types";
import { User } from "../../store/types";

interface SaveSchema {
	activeApplication: Application | null;
	activeMarket: string | null;
	value: any;
	setSchemaData: (data: any) => void;
	getToken: () => Promise<string>;
	handleLogout: () => void;
	setOriginalValue: (data: any) => void;
	setSaveBtnLoading: (value: boolean) => void;
	setToastMessage: (value: string) => void;
	uiSchemaName?: string;
	multipleMarketFlag: boolean;
	selectedMarkets: (string | null)[];
	reason?: string;
	currentUser?: User | null;
	setUnsavedChangesValue: (data: any) => void;
}

interface GetUiSchema {
	activeApplication: Application | null;
	getToken: () => Promise<string>;
	setUiSchemasLoading: (value: boolean) => void;
	setUiSchemasData: (data: any) => void;
	handleLogout: () => void;
}

interface AnyObject {
    [key: string]: any;
}

export const getUiSchemas = async ({
	activeApplication,
	getToken,
	setUiSchemasLoading,
	setUiSchemasData,
	handleLogout,
}: GetUiSchema) => {
	try {
		if (activeApplication) {
			setUiSchemasLoading(true);
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			const { data: UiSchemaData } = await getUiSchemaList(authHeader, activeApplication.id, {
				uiSchema: true,
			});
			const hasUiSchemas = UiSchemaData.data["ui-schemas"].length;
			setUiSchemasData(hasUiSchemas ? UiSchemaData.data["ui-schemas"] : undefined);
		}
	} catch (error: any) {
		//TODO: handle error
		console.error(error);
		setUiSchemasData(undefined);
		if (error?.response?.status == 401) {
			handleLogout();
		}
	} finally {
		setUiSchemasLoading(false);
	}
};

export const saveSchemaValue = async ({
	activeApplication,
	activeMarket,
	value,
	setSchemaData,
	handleLogout,
	setOriginalValue,
	getToken,
	uiSchemaName,
	setSaveBtnLoading,
	setToastMessage,
	multipleMarketFlag,
	selectedMarkets,
	reason,
	currentUser,
	setUnsavedChangesValue
}: SaveSchema) => {

	if (activeApplication && activeMarket) {
		try {
			setSaveBtnLoading(true);
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			const { data: updatedData } = await updateSchemas(
				authHeader,
				activeApplication.id,
				activeMarket,
				value,
				multipleMarketFlag,
				selectedMarkets,
				uiSchemaName,
				reason
			);
			const schemaValue = updatedData.data?.value;
			if (!multipleMarketFlag) {
				setSchemaData((prevData: any) => ({ ...prevData, schemaValue }));
				const newObj: any = {};
				Object.keys(schemaValue || {}).forEach((key) => {
					newObj[key] = schemaValue[key];
				});
				setOriginalValue(newObj);
			} else {
				const { data: schemaData } = await getSchemas(authHeader, activeApplication.id, activeMarket);
				const newObj: any = {};
				Object.keys(schemaData.data?.schemaValue || {}).forEach((key) => {
					newObj[key] = schemaData.data?.schemaValue[key];
				});
				setOriginalValue(newObj);
			}
			setToastMessage("Configuration updated Sucessfully");
		} catch (error: any) {
			setToastMessage(error?.response?.data?.message);
			if (error?.response?.status == 401) {
				setUnsavedChangesValue({
					"activeApplicationId": activeApplication.id,
					"activeMarket": activeMarket,
					"value": JSON.stringify(value),
					"uiSchemaName": uiSchemaName,
					"userId": currentUser?.user?.id,
					"dateTime": new Date().getTime()
				});
				handleLogout();
			}
		} finally {
			setSaveBtnLoading(false);
		}
	}
};

export const getAllScopes = (uiSchema: any) => {
	const scopes = [] as any;

	function findSchemaScopes(uiSchema: any) {
		if (Object.prototype.hasOwnProperty.call(uiSchema, "scope")) {
			scopes.push(uiSchema["scope"]);
		}
		if (Object.prototype.hasOwnProperty.call(uiSchema, "elements")) {
			for (const key in uiSchema.elements) {
				findSchemaScopes(uiSchema.elements[key]);
			}
		}
	}

	findSchemaScopes(uiSchema);
	return scopes;
};

export const filterElementsByLabel = (obj: any, label: any): any[] => {
	let filtered: any[] = [];

	// Check if object is an array
	if (Array.isArray(obj)) {
		for (let i = 0; i < obj.length; i++) {
			const temp = filterElementsByLabel(obj[i], label);
			filtered.push(...temp); // Add all filtered elements to the result array
		}
	} 
	// Check if object is an object
	else if (obj !== null && typeof obj === "object") {
		if ((obj.label && obj.label.toLowerCase().includes(label.toLowerCase())) || (obj.subText && obj.subText.toLowerCase().includes(label.toLowerCase()))) {
			// Add the object to the filtered array
			filtered.push({ ...obj });
		} else {
			// Recursive call for nested elements
			const children = obj.elements ? filterElementsByLabel(obj.elements, label) : [];
			if (children.length > 0) {
				// Add the parent object if any child matches
				filtered.push({ ...obj, elements: children });
			}
		}
	}
	return filtered;
};