import * as React from "react";

export const useGetCountryName = () => {
	const countryName = React.useMemo(() => new Intl.DisplayNames(["en"], { type: "region" }), []);

	const getCountry = React.useCallback((country: string) => countryName.of(country), [countryName]);

	const getCountryName = React.useCallback(
		(country: string): string => {
			try {
				if (country === "R1") {
					return "Country Zero";			}
				const countryName = getCountry(country);
				if (!countryName) {
					return country;
				}
				return countryName === "Unknown Region" ? country : countryName;
			}
			catch(err) {
				return "Unknown Region";
			}
		},
		[getCountry]
	);

	return { getCountryName };
};
