import * as React from "react";
import styled from "styled-components";

import Button from "@ingka/button";
import { colourNeutral3, colourStaticDarkGrey } from "@ingka/variables/colours-css";
import Toast from "@ingka/toast";
import { breakpointL } from "@ingka/variables/design-tokens";
import plus from "@ingka/ssr-icon/paths/plus";
import documentAdd from "@ingka/ssr-icon/paths/document-add";

import { StyledDivider } from "../styles";
import { RequestedCreateNewApplications, User, UserRequestedApplications } from "../../store/types";
import { headerAndFooterHeight } from "../../utils/variables";
import Requests from "./Requests";
import Badge from "@ingka/badge";
import { ApplicationData } from "../../store";
import Skeleton from "@ingka/skeleton";
import { ActionsWrapper, SaveButtonWrapper, IconButtonWrapper } from "../styles";

interface NewUserLayoutProps {
	currentUser: User | null;
	showApplicationModal: boolean;
	setShowApplicationModal: (val: boolean) => void;
	toast: string;
	hasRequests: boolean;
	cancelCreateNewApplicationRequest: (appId: string) => void;
	cancelExistingAppRequest: (appId: string) => void;
	setToast: (val: string) => void;
	cancelExistingAppRequestLoading: boolean;
	cancelNewlyCreatedAppLoading: boolean;
	onCreateNewAppClick: () => void;
	onJoinExistingAppClick: () => void;
	createdAppRemoving: string;
	existingAppRequestRemoving: string;
	userLoading: boolean;
	existingAppRequests?: UserRequestedApplications[];
	newAppsRequested?: RequestedCreateNewApplications[];
	applications: ApplicationData[] | undefined;
	applicationsLoading: boolean;
}

export const StyledButton = styled(Button)`
	width: fit-content;
	margin-left: 2em;
`;
interface ContainerProps {
	hasRequests?: boolean;
}

const LeftContainer = styled.div<ContainerProps>`
	@media (max-width: ${breakpointL}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid ${colourNeutral3};
		margin-bottom: 2rem;
		padding: 2rem;
	}
	@media (min-width: ${breakpointL}) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		grid-row: 1;
		grid-column: 1/7;
	}
`;
const ContentWrapper = styled.div`
	@media (max-width: ${breakpointL}) {
		display: block;
	}
	@media (min-width: ${breakpointL}) {
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		grid-column-gap: 1.25rem;
	}
`;

const RightContainer = styled.div<ContainerProps>`
	@media (max-width: ${breakpointL}) {
		display: block;
		padding: 0rem 1rem;
	}
	@media (min-width: ${breakpointL}) {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		grid-row: 1;
		grid-column: 7/13;
		min-height: calc(100vh - ${headerAndFooterHeight});
	}
`;

const RequestedApplicationContainer = styled.div<ContainerProps>`
	height: 35rem;
	@media (max-width: ${breakpointL}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid ${colourNeutral3};
		margin-bottom: 2rem;
		padding: 2rem;
	}
	@media (min-width: ${breakpointL}) {
		grid-row: 1;
		grid-column: 1/6;
		border: 1px solid ${colourNeutral3};
		border-radius: 8px;
		margin: 1rem 0 1rem 0;
		padding: 0 1rem 0 1rem;
	}
`;

const H3 = styled.h3`
	margin-top: 0;
	color: ${colourStaticDarkGrey};
`;

const StyledH2 = styled.h2`
	margin: 0;
`;

const ContentBox = styled.div`
	width: 100%;
	padding: 2rem 0 2rem 0;
	gap: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: rgb(var(--colour-elevation-2, 255, 255, 255));
	border-radius: 8px;
	box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
	height: 16rem;
`;

const TextBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ApplicationBadgesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 0.5rem;
`;

const StyledLabel = styled.label`
	font-size: 0.7rem;
`;

const StyledParagraph = styled.p`
	text-align: center;
`;

const Layout: React.FC<NewUserLayoutProps> = ({
	toast,
	setToast,
	existingAppRequests,
	newAppsRequested,
	hasRequests,
	cancelCreateNewApplicationRequest,
	cancelExistingAppRequest,
	cancelNewlyCreatedAppLoading,
	cancelExistingAppRequestLoading,
	onJoinExistingAppClick,
	onCreateNewAppClick,
	createdAppRemoving,
	existingAppRequestRemoving,
	userLoading,
	applications,
	applicationsLoading
}) => {

	const applicationBadges = React.useMemo(() => {
		let appBadges = [] as any;
		if (!applications && !applicationsLoading) return appBadges;
		if (!applications && applicationsLoading) {
			appBadges.push(
				<>
					<Skeleton height="2rem" width="6rem" />
					<Skeleton height="2rem" width="6rem" />
					<Skeleton height="2rem" width="6rem" />
					<Skeleton height="2rem" width="6rem" />
				</>

			);
			return appBadges;
		}
		if (applications) {
			for (let i = 0; i < applications.length; i++) {
				if (i < 4) {
					appBadges.push(
					<Badge
						colour="grey"
						label={applications[i].name}
						size="medium"
					/>);
				} else {
					appBadges.push(
						<StyledLabel>+{applications.length - 4} more</StyledLabel>
					);
					break;
				}
			}
		}
		return appBadges;
	}, [applications, applicationsLoading]);

	return (
		<>
			<StyledH2>Hej!</StyledH2>
			<H3>Start creating your own application or request for application</H3>
			<StyledDivider />
			<ContentWrapper>
				{hasRequests && (
					<RequestedApplicationContainer>
						<Requests
							newAppsRequested={newAppsRequested}
							existingAppRequests={existingAppRequests}
							cancelCreateNewApplicationRequest={cancelCreateNewApplicationRequest}
							cancelExistingAppRequest={cancelExistingAppRequest}
							cancelNewlyCreatedAppLoading={cancelNewlyCreatedAppLoading}
							cancelExistingAppRequestLoading={cancelExistingAppRequestLoading}
							createdAppRemoving={createdAppRemoving}
							existingAppRequestRemoving={existingAppRequestRemoving}
							userLoading={userLoading}
						/>
					</RequestedApplicationContainer>
				)}
				{!hasRequests && 
					<>
						<LeftContainer>
							<ContentBox>
								<TextBox>
									<StyledH2>Join existing application</StyledH2>
									<StyledParagraph>Join existing applications below by clicking the request access button</StyledParagraph>
									<ApplicationBadgesWrapper>
										{applicationBadges}
									</ApplicationBadgesWrapper>
								</TextBox>
								<div>
									<Button
										type="emphasised"
										ssrIcon={documentAdd}
										small
										text="Request access"
										onClick={onJoinExistingAppClick}
									/>
								</div>
							</ContentBox>
						</LeftContainer>
						<RightContainer>
							<ContentBox>
								<TextBox>
									<StyledH2>Create new application</StyledH2>
									<StyledParagraph>Want to onboard your application/product into FLAGGA?<br/>
									Start by creating a new Flagga application request</StyledParagraph>
								</TextBox>
								<div>
									<Button type="emphasised" ssrIcon={plus} small text="Create" onClick={onCreateNewAppClick} />
								</div>
							</ContentBox>
						</RightContainer>
					</>
				}
			</ContentWrapper>
			{hasRequests && <ActionsWrapper>
				<SaveButtonWrapper>
					<Button
						type="emphasised"
						ssrIcon={documentAdd}
						text="Request access"
						onClick={onJoinExistingAppClick}
					/>
					<Button
						type="secondary"
						ssrIcon={plus}
						text="Create application request"
						onClick={onCreateNewAppClick}
					/>
				</SaveButtonWrapper>
			</ActionsWrapper>
			}
			<Toast
				text={toast}
				isOpen={!!toast}
				onCloseRequest={() => setToast("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default Layout;
