import styled from "styled-components";
import { colourStaticWhite, colourSemanticInformative } from "@ingka/variables/colours-css";

export const Counter = styled.span`
	background-color: ${colourSemanticInformative};
	color: ${colourStaticWhite};
	border-radius: 50px;
	line-height: 0.95rem;
	margin-top: 0;
	margin-bottom: 0;
	right: 0;
	top: 0;
	min-width: 19px;
	height: 19px;
	text-align: center;
	font-size: 0.65rem;
	font-weight: 400;
	padding: 2px;
`;
