import { SchemaTabs } from "./types";

export enum NewSchemaFormFieldNames {
	UiSchemaName = "uiSchemaName",
	UiSchemaValue = "uiSchemaValue",
}

export enum ApiResponseMessages {
	Success = "Success",
}

export const TAB_SCHEMA_BUILDER: SchemaTabs = "schema-builder";
export const TAB_SCHEMA_JSON: SchemaTabs = "schema-json";
