import * as React from "react";
import Modal, { ModalFooter, Prompt } from "@ingka/modal";
import Button from "@ingka/button";

interface InactivityModalProps {
	secondsToDisplay: number;
	showInactivityModal: boolean;
	setShowInactivityModal: (val: boolean) => void;
}

const Layout: React.FC<InactivityModalProps> = ({ secondsToDisplay, showInactivityModal, setShowInactivityModal }) => {
	return (
		<Modal
			focusLockProps={{ locked: false }}
			visible={showInactivityModal}
			escapable={true}
			handleCloseBtn={() => setShowInactivityModal(false)}
		>
			<Prompt
				title="Warning"
				titleId={"modalTitleId"}
				footer={
					<ModalFooter>
						<Button text="Continue" type="emphasised" onClick={() => setShowInactivityModal(false)} />
					</ModalFooter>
				}
				header={null}
			>
				<p>
					You session will expire in {secondsToDisplay} seconds due to inactivity.
					<br></br>
					If you want to continue session please click continue
				</p>
			</Prompt>
		</Modal>
	);
};

export default Layout;
