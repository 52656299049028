import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { SEARCH_PARAM_ACTIVE_MARKET } from "../utils/constants";

const useActiveMarket = () => {
	const [searchParams, setSearchParam] = useSearchParams();

	const activeMarketParams = searchParams.get(SEARCH_PARAM_ACTIVE_MARKET);

	const setActiveMarketParams = React.useCallback(
		(market: string) => {
			setSearchParam({ ...Object.fromEntries(searchParams.entries()), market: market });
		},
		[searchParams, setSearchParam]
	);

	return { activeMarketParams, setActiveMarketParams };
};

export default useActiveMarket;
