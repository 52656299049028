/* eslint-disable indent */
import * as React from "react";
import useStore, { userSelector } from "../store";
import useAuthentication from "./useAuthentication";
import { UserPermissions } from "../utils/constants";
import { getRequestRoles } from "../services/users";

export interface RolesData {
	roles: UserPermissions[];
}

const useGetRoles = (appId?: string) => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const [data, setData] = React.useState<UserPermissions[] | undefined>(undefined);
	const user = useStore(userSelector);
	const { getToken, handleLogout } = useAuthentication();

	React.useEffect(() => {
		const getRoles = async () => {
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};

				if (appId) {
					setLoading(true);
					const { data: rolesData } = await getRequestRoles(authHeader, appId);
					setData(rolesData ? rolesData : undefined);
				}
			} catch (error: any) {
				if (error) {
					setData(undefined);
					setError(error?.response?.data?.message as string);
					if (error?.response?.status == 401) {
						handleLogout();
					}
				}
			} finally {
				setLoading(false);
			}
		};
		getRoles();

		return () => {
			setData(undefined);
			setLoading(false);
		};
	}, [user, appId, getToken, handleLogout]);

	return { data, loading, setData, error };
};

export default useGetRoles;
