import React from "react";
import styled from "styled-components";
import _ from "lodash/fp";
import Button from "@ingka/button";

import { colourNeutral7 } from "@ingka/variables/colours-css";

import { Input } from "../Input";
import { Checkbox } from "../Checkbox";
import { SchemaTypesSelect } from "../Select";
import { SchemaArrayTypesSelect } from "../Select";
import * as helpers from "../../utils/helpers";
import { Schema } from "../../utils/types";
import { AddButton, CollapseButton, DeleteButton, MenuButton } from "../Buttons";
import { SchemaMenu } from "../SchemaMenu";
import { Modal } from "../Modal";
import { DashboardModal } from "../DashboardModal";
import DashboardButton from "../Buttons/DashboardButton";
import WarningModal from "../../../WarningModal";
import { PropertyKeyInput } from "../PropertyKeyInput";

const SchemaControllWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const SchemaControllButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.25rem;
	margin-bottom: 1.25rem;
`;

const SchemaControllInputsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
`;

interface Props {
	schema: Schema;
	schemakey: string;
	onDelete?: () => void;
	onAdd?: () => void;
	isCollapsed?: boolean;
	onCollapse?: () => void;
	onChangeKey?: (key: string) => void;
	onChange: (schema: Schema) => void;
	onAddDashboard?: (key: string) => void;
	setInvalidKeys?: React.Dispatch<string[]>;
	invalidKeys?: string[];
	schemaKeys?: string[];
}

const SchemaControlsLayout = styled.div`
	display: flex;
`;

const SvgWrapper = styled.div`
	width: 4rem;
`;

const Svg = styled.svg`
	width: 1.5rem;
	height: 5rem;
	overflow: initial;
`;

const ControlsSvg = styled.svg`
	width: 0.5rem;
	height: 2rem;
	overflow: initial;
`;

const ModalButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
`;

const CollapseButtonWrapper = styled.div`
	margin-bottom: 1.25rem;
`;

export const SchemaControls: React.FC<Props> = ({
	schema,
	schemakey,
	isCollapsed,
	onDelete,
	onChange,
	onChangeKey,
	onAdd,
	onCollapse,
	onAddDashboard,
	setInvalidKeys,
	invalidKeys,
	schemaKeys,
}) => {
	const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
	const [dashboardModalOpen, setDashboardModalOpen] = React.useState<boolean>(false);
	const [warningModalOpen, setWarningModalOpen] = React.useState<boolean>(false);

	return (
		<SchemaControlsLayout>
			{!_.isFunction(onCollapse) && (
				<SvgWrapper>
					<Svg>
						<line stroke={colourNeutral7} strokeWidth={1} x1="-13" y1="60%" x2="100%" y2="60%" />
					</Svg>
				</SvgWrapper>
			)}
			<SchemaControllWrapper>
				{_.isFunction(onCollapse) ? (
					<>
						{schemakey !== "__root__" ? (
							<div style={{ width: "0.5rem" }}>
								<ControlsSvg>
									<line stroke={colourNeutral7} strokeWidth={1} x1={"-13"} y1="25%" x2="100%" y2="25%" />
								</ControlsSvg>
							</div>
						) : (
							<ControlsSvg />
						)}
						<CollapseButtonWrapper>
							<CollapseButton onClick={onCollapse} isCollapsed={isCollapsed} title={"collapse"} />
						</CollapseButtonWrapper>
					</>
				) : null}
				<SchemaControllInputsWrapper>
					<SchemaTypesSelect
						schemakey={schemakey}
						type={helpers.getSchemaType(schema)}
						onChange={(t) => onChange(helpers.setSchemaTypeAndRemoveWrongFields(t, schema))}
					/>
					{_.isFunction(onChangeKey) ? (
						<PropertyKeyInput
							value={schemakey}
							onChange={onChangeKey}
							label={"key"}
							invalidKeys={invalidKeys}
							setInvalidKeys={setInvalidKeys}
							schemaKeys={schemaKeys}
						/>
					) : null}
				</SchemaControllInputsWrapper>
				<SchemaControllButtonWrapper>
					<MenuButton onClick={() => setIsMenuOpen((o) => !o)} title={"Extra Options"} />
					{_.isFunction(onAdd) ? <AddButton onClick={onAdd} title={"Add Property"} /> : null}
					{_.isFunction(onAddDashboard) ? (
						<DashboardButton
							onClick={() => {
								setDashboardModalOpen(true);
							}}
							title={"Dashboard Configuration"}
						/>
					) : null}
					{_.isFunction(onDelete) ? <DeleteButton onClick={() => setWarningModalOpen(true)} title={"Remove"} /> : null}
				</SchemaControllButtonWrapper>
				<Modal visible={isMenuOpen} onClose={() => setIsMenuOpen(false)} title={`Extra fields: ${schemakey}`}>
					<SchemaMenu schema={schema} onChange={onChange} />
				</Modal>
				{dashboardModalOpen && (
					<DashboardModal
						schema={schema}
						schemaKey={schemakey}
						showModal={dashboardModalOpen}
						setShowModal={setDashboardModalOpen}
						onChange={onChange}
					/>
				)}
				{warningModalOpen && (
					<WarningModal
						modalTitle="Remove property!"
						showWarningModal={warningModalOpen}
						setShowWarningModal={setWarningModalOpen}
						modalId="remove-property-modal"
						variant="cautionary"
						dismissable={true}
					>
						<>
							<p>
								Are you sure you want to remove <strong>{schemakey}</strong> property?
							</p>
							<ModalButtonWrapper>
								<Button small text="Cancel" type="emphasised" onClick={() => setWarningModalOpen(false)} />
								<Button small text="OK" onClick={onDelete} />
							</ModalButtonWrapper>
						</>
					</WarningModal>
				)}
			</SchemaControllWrapper>
		</SchemaControlsLayout>
	);
};

interface ArrayProps {
	schema: Schema;
	onChange: (schema: Schema) => void;
	schemakey?: string;
	onAdd?: () => void;
	isCollapsed?: boolean;
	onCollapse?: () => void;
}

const SchemaArrayControlsWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const SchemaArrayControlsButtonsWrapper = styled.div`
	margin-left: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.25rem;
`;

export const SchemaArrayControls: React.FunctionComponent<ArrayProps> = ({
	schema,
	onChange,
	onAdd,
	isCollapsed,
	onCollapse,
	schemakey,
}) => {
	const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
	const [height, setHeight] = React.useState<number | undefined>();
	const ref = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (ref.current && ref.current !== null) {
			setHeight(ref?.current?.clientHeight);
		}
	}, []);

	return (
		<SchemaArrayControlsWrapper>
			{!_.isFunction(onCollapse) && (
				<SvgWrapper>
					<Svg height={height}>
						<line stroke={colourNeutral7} strokeWidth={1} x1="-13" y1="50%" x2="100%" y2="50%" />
					</Svg>
				</SvgWrapper>
			)}
			{_.isFunction(onCollapse) ? (
				<>
					{schemakey !== "__root__" ? (
						<div style={{ width: "0.5rem" }}>
							<ControlsSvg>
								<line stroke={colourNeutral7} strokeWidth={1} x1={"-13"} y1="49%" x2="100%" y2="49%" />
							</ControlsSvg>
						</div>
					) : (
						<ControlsSvg className="controll-svg" />
					)}
					<CollapseButton onClick={onCollapse} isCollapsed={isCollapsed} title={"collapse"} />
				</>
			) : null}
			<div ref={ref}>
				<SchemaArrayTypesSelect
					type={helpers.getSchemaType(schema)}
					onChange={(t) => onChange(helpers.setSchemaTypeAndRemoveWrongFields(t, schema))}
				/>
			</div>
			<SchemaArrayControlsButtonsWrapper>
				<MenuButton onClick={() => setIsMenuOpen((o) => !o)} title={"extraOptions"} />
				{_.isFunction(onAdd) ? <AddButton onClick={onAdd} title={"add"} /> : null}
			</SchemaArrayControlsButtonsWrapper>
			<Modal visible={isMenuOpen} onClose={() => setIsMenuOpen(false)} title={"Extra fields"}>
				<SchemaMenu schema={schema} onChange={onChange} />
			</Modal>
		</SchemaArrayControlsWrapper>
	);
};

interface DashboardProps {
	schema: Schema;
	onChange: (schema: Schema, dashboardProperty: string, value: any) => void;
}

export const DashboardControl: React.FunctionComponent<DashboardProps> = ({ schema, onChange }) => {
	let dashboard = schema.dashboard as any;
	return (
		<div>
			<label>Dashboard</label>
			<br />
			<Input
				value={dashboard?.label || ""}
				onChange={(e) => {
					onChange(schema, "label", e);
				}}
				placeholder={"Label"}
				label={"Label"}
			/>
			<hr />
			<Input
				value={dashboard?.groupId || ""}
				onChange={(e) => {
					onChange(schema, "groupId", e);
				}}
				placeholder={"Group Id"}
				label={"Group Id"}
			/>
			<br />
			<Checkbox
				label="Is Visible"
				value={dashboard?.isVisible || false}
				onChange={(e) => {
					onChange(schema, "isVisible", e);
				}}
			/>
			<br />
			<Checkbox
				label="Market Dashboard"
				value={dashboard?.market || false}
				onChange={(e) => {
					onChange(schema, "market", e);
				}}
			/>
			<br />
			<Checkbox
				label="Global Dashboard"
				value={dashboard?.global || false}
				onChange={(e) => {
					onChange(schema, "global", e);
				}}
			/>
			<br />
			<Input
				label={"groupIdIndex"}
				type={"number"}
				value={dashboard?.groupIdIndex || 999}
				onChange={(e) => {
					onChange(schema, "groupIdIndex", parseInt(e));
				}}
			/>
			<br />
			<Input
				label={"columnIndex"}
				type={"number"}
				value={dashboard?.columnIndex || 999}
				onChange={(e) => {
					onChange(schema, "columnIndex", parseInt(e));
				}}
			/>
		</div>
	);
};
