import * as React from "react";
import { getSchemas } from "../services/schemas";
import useStore, { activeApplicationSelector, activeMarketSelector } from "../store";
import { JsonSchema, UISchemaElement } from "@jsonforms/core";
import useAuthentication from "./useAuthentication";

export interface SchemaData {
	schema: JsonSchema;
	uiSchema: UISchemaElement;
	schemaValue: any;
	permissions: string;
	readOnly: boolean;
}

const useGetSchemas = () => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [originalValue, setOriginalValue] = React.useState({});
	const [data, setData] = React.useState<SchemaData | undefined>(undefined);
	const activeApplication = useStore(activeApplicationSelector);
	const activeMarket = useStore(activeMarketSelector);
	const { getToken, handleLogout } = useAuthentication();
	const forceRerender = useStore((state) => state.forceRerender);
	const setForceRerender = useStore((state) => state.setForceRerender);

	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			try {
				if ((activeApplication && activeMarket) || (activeApplication && activeMarket && forceRerender)) {
					setLoading(true);
					const { data: schemaData } = await getSchemas(authHeader, activeApplication.id, activeMarket);
					const newObj: any = {};
					Object.keys(schemaData.data?.schemaValue || {}).forEach((key) => {
						newObj[key] = schemaData.data?.schemaValue[key];
					});
					setOriginalValue(newObj);
					// if schemaValue is null than we have to provide value as {}, as null is not supported by json-forms value props
					setData(schemaData.data.schemaValue ? schemaData.data : { ...schemaData.data, schemaValue: {} });
					setForceRerender(false);
				}
			} catch (error: any) {
				setData(undefined);
				if (error?.response?.status == 401) {
					handleLogout();
				}
			} finally {
				setLoading(false);
			}
		})();
		return () => {
			// cancel the subscription
			setData(undefined);
			setLoading(false);
		};
	}, [activeApplication, activeMarket, getToken, handleLogout, forceRerender]);

	return { data, loading, setData, originalValue, setOriginalValue };
};

export default useGetSchemas;
