import React from "react";
import _ from "lodash/fp";

import { Schema } from "../../utils/types";
import * as helpers from "../../utils/helpers";
import SchemaMenuList from "./SchemaMenuList";

interface Props {
	schema: Schema;
	onChange: (schema: Schema) => void;
}

const SchemaMenu: React.FunctionComponent<Props> = ({ schema, onChange }) => {
	const type = helpers.getSchemaType(schema) || (schema?.oneOf ? "oneOf" : "object");

	const allOptions = React.useMemo(() => helpers.getSchemaMenuOptions(type), [type]);

	const displayFields = React.useMemo(() => {
		const fields = helpers.getAllSchemaKeys(schema);
		return _.filter((item) => _.includes(item.value, fields), allOptions);
	}, [schema, allOptions]);

	return (
		<div>
			<SchemaMenuList fields={allOptions} schema={schema} onChange={onChange} enabledFields={displayFields} />
		</div>
	);
};

export default SchemaMenu;
