import { Grid, IconButton, makeStyles, Typography, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDrag } from "react-dnd";

import { useDispatch, useSchema, useSearchElement, useSearchElementParents, useSelection } from "../../core/context";
import { DndItems } from "../../core/dnd";
import { SchemaIcon, UISchemaIcon } from "../../core/icons";
import { Actions } from "../../core/model";
import { EditorUISchemaElement, getUISchemaPath, hasChildren } from "../../core/model/uischema";
import { isEditorControl, tryFindByUUID } from "../../core/util/schemasUtil";
import WarningModal from "../../../WarningModal/WarningModal";
import styled from "styled-components";
import Button from "@ingka/button";
import { margin } from "@mui/system";

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
`;

const useEditorElementStyles = makeStyles((theme) => ({
	editorElement: {
		border: "1px solid #d3d3d3",
		padding: theme.spacing(1),
		opacity: 1,
		backgroundColor: "#fafafa",
		width: "100%",
		alignSelf: "baseline",
		overflow: "hidden",
		// minWidth: "fit-content",
	},
	elementDragging: {
		opacity: 0.5,
	},
	elementSelected: {
		border: "1px solid #a9a9a9",
		backgroundColor: "rgba(63, 81, 181, 0.08)",
	},
	elementHeader: {
		"&:hover $elementControls": {
			opacity: 1,
		},
		marginBottom: theme.spacing(0.25),
	},
	elementControls: {
		opacity: 0,
	},
	icon: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(0),
		width: "fit-content",
	},
	rule: {
		fontWeight: "bolder",
		color: theme.palette.text.primary,
		marginRight: theme.spacing(0.5),
	},
	ruleWrapper: {
		display: "flex",
		marginRight: theme.spacing(1),
	},
	pathWrapper: {
		display: "flex",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	ruleEffect: { fontStyle: "italic", color: theme.palette.text.secondary },
	textEllipsis: { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
	elementHeaderLeft: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	booleanElement: {
		"& .MuiFormControlLabel-root": {
			marginLeft: 0,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		"& .MuiTypography-root": {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	},
}));

export interface EditorElementProps {
	wrappedElement: EditorUISchemaElement;
	elementIcon?: React.ReactNode;
}

export const EditorElement: React.FC<EditorElementProps> = ({ wrappedElement, elementIcon, children }) => {
	const ref = useRef<any>(null);
	const schema = useSchema();
	const [selection, setSelection] = useSelection();
	const [searchElement, setSearchElement] = useSearchElement();
	const [searchElementParent, setSearchElementParent] = useSearchElementParents();

	const dispatch = useDispatch();
	const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = React.useState(false);
	const elementSchema = tryFindByUUID(schema, wrappedElement.linkedSchemaElement);
	//@ts-ignore
	const [{ isDragging }, drag, preview] = useDrag({
		item: DndItems.moveUISchemaElement(wrappedElement, elementSchema),
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	});
	const classes = useEditorElementStyles();

	const uiPath = getUISchemaPath(wrappedElement);
	const isSelected = selection?.uuid === wrappedElement.uuid;
	const isSearching = useMemo(
		() => wrappedElement?.linkedSchemaElement && wrappedElement?.linkedSchemaElement === searchElement?.uuid,
		[searchElement?.uuid, wrappedElement?.linkedSchemaElement]
	);

	const traverseParents = useCallback((obj: any, uuids: any[]) => {
		// eslint-disable-next-line no-prototype-builtins
		if (obj && typeof obj === "object" && obj.hasOwnProperty("parent")) {
			uuids.push(obj.uuid); // Outputting the current parent
			traverseParents(obj.parent, uuids); // Recursively call traverseParents with the parent object
		}
	}, []);

	useEffect(() => {
		if (isSearching) {
			const uuids: any[] = [];
			traverseParents(wrappedElement, uuids);
			if (uuids.length) {
				setSearchElementParent(uuids);
			}
		}
	}, [isSearching, setSearchElementParent, traverseParents, wrappedElement]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (isSearching) {
				ref.current?.scrollIntoView && ref.current?.scrollIntoView({ behavior: "smooth" });
				// setSearchElement(undefined);
				setSearchElementParent([]);
			}
		}, 1000);

		// Cleanup function to clear the timeout if the component unmounts
		return () => clearTimeout(timeoutId);
	}, [isSearching, setSearchElement, setSearchElementParent]);

	const isParentOfSearchElement = searchElementParent.includes(wrappedElement.uuid);

	const ruleEffect = wrappedElement.rule?.effect.toLocaleUpperCase();

	const icon =
		elementIcon ??
		(elementSchema ? <SchemaIcon type={elementSchema.type} /> : <UISchemaIcon type={wrappedElement.type} />);

	return (
		<>
			<Grid
				item
				data-cy={`editorElement-${uiPath}`}
				className={`${classes.editorElement} ${isDragging ? classes.elementDragging : ""} ${
					isSelected ? classes.elementSelected : ""
				}
				 ${isSearching ? classes.elementSelected : ""} 
				 ${isParentOfSearchElement && wrappedElement.type === "VerticalLayout" ? classes.elementSelected : ""} ${
					isParentOfSearchElement && wrappedElement.type === "HorizontalLayout" ? classes.elementSelected : ""
				} ${elementSchema?.type === "boolean" ? classes.booleanElement : ""} 
				 `}
				ref={drag}
				onClick={(event) => {
					event.stopPropagation();
					const newSelection = { uuid: wrappedElement.uuid };
					setSelection(newSelection);
				}}
			>
				<Grid
					item
					container
					direction="row"
					wrap="nowrap"
					className={classes.elementHeader}
					data-cy={`editorElement-${uiPath}-header`}
				>
					<Box className={`${classes.elementHeaderLeft} ${classes.textEllipsis}`}>
						<Box className={classes.icon}>{icon}</Box>
						{ruleEffect ? (
							<Box className={classes.ruleWrapper}>
								<Typography variant="subtitle2" className={classes.rule}>
									{"R"}
								</Typography>
								<Typography variant="caption" className={`${classes.ruleEffect}`}>{`(${ruleEffect})`}</Typography>
							</Box>
						) : null}
						{isEditorControl(wrappedElement) && (
							<Box className={classes.pathWrapper}>
								<Typography variant="caption" className={`${classes.ruleEffect}  ${classes.textEllipsis}`}>
									{wrappedElement.scope}
								</Typography>
							</Box>
						)}
					</Box>
					<Grid item container className={classes.elementControls} justifyContent="flex-end" alignItems="center" xs>
						<IconButton
							data-cy={`editorElement-${uiPath}-removeButton`}
							size="small"
							onClick={() => {
								hasChildren(wrappedElement)
									? setOpenConfirmRemoveDialog(true)
									: dispatch(Actions.removeUiSchemaElement(wrappedElement.uuid));
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</Grid>
				{
					//@ts-ignore //custom code codition for address Order to not render children
					wrappedElement.options?.addressOrder != true && wrappedElement?.addressOrder != true && children
				}
			</Grid>
			<WarningModal
				modalId={"remove-elements-dialog"}
				modalTitle={"Warning"}
				showWarningModal={openConfirmRemoveDialog}
				setShowWarningModal={setOpenConfirmRemoveDialog}
				children={
					<>
						<p>Remove element and all its contents from the UI Schema?</p>
						<ButtonWrapper>
							<Button
								id="remove-cancel-button"
								text="Cancel"
								type="emphasised"
								small
								onClick={() => setOpenConfirmRemoveDialog(false)}
							/>
							<Button
								id="remove-schema-button"
								text="Remove"
								type="secondary"
								small
								onClick={() => {
									dispatch(Actions.removeUiSchemaElement(wrappedElement.uuid));
									setOpenConfirmRemoveDialog(false);
								}}
							/>
						</ButtonWrapper>
					</>
				}
				variant="cautionary"
				dismissable={false}
			/>
		</>
	);
};
