import * as React from "react";
import styled from "styled-components";
import { ColumnInstance } from "react-table";

import Modal, { ModalBody, Sheets } from "@ingka/modal";
import { colourStaticGrey } from "@ingka/variables/colours-css";
import Switch from "@ingka/switch";
import Accordion, { AccordionItem } from "@ingka/accordion";

import Spacing from "../../Spacing/Spacing";
import { StyledModalHeader } from "../../styles";

interface FilterColumnsModalProps {
	columnFilterModal: boolean;
	setColumnFilterModal: (value: boolean) => void;
	allColumns: ColumnInstance<object>[];
	allColumnsGroup: any;
	hasGroups: boolean;
}

const SwitchWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const ToggleAllWrapper = styled.div`
	padding: 2rem 2.5rem 2rem 2.5rem;
	border-top: 1px solid ${colourStaticGrey};
`;

const AccordionItemWrapper = styled.div`
	display: flex;
	position: relative;
	.accordion__item {
		width: 100%;
	}
	.accordion-item-header__title-wrapper {
		margin-left: 4rem;
	}
	.toggle-group-switch {
		padding-top: 0.7rem;
		position: absolute;
	}
`;

const FilterColumnsModal: React.FC<FilterColumnsModalProps> = ({
	columnFilterModal,
	setColumnFilterModal,
	allColumns,
	allColumnsGroup,
	hasGroups,
}) => {
	return (
		<Modal
			focusLockProps={{ locked: false }}
			visible={columnFilterModal}
			handleCloseBtn={() => setColumnFilterModal(false)}
		>
			<Sheets
				footer={
					!hasGroups ? (
						<ToggleAllWrapper>
							<Switch
								id={""}
								value={""}
								label="Toggle All Columns"
								onChange={(e) => allColumns.forEach((c) => c.id !== "markets" && c.toggleHidden(!e.target.checked))}
								defaultChecked={true}
							/>
						</ToggleAllWrapper>
					) : null
				}
				size="small"
				header={<StyledModalHeader title="Toggle Columns" />}
			>
				<ModalBody>
					{hasGroups && allColumnsGroup && Object.keys(allColumnsGroup).length ? (
						<Accordion size="small">
							{Object.keys(allColumnsGroup)
								.filter((columnName: string) => columnName.length > 1)
								.map((data) => {
									if (allColumnsGroup[data].length && data) {
										return (
											<AccordionItemWrapper key={data}>
												<Switch
													id={data}
													value={""}
													className="toggle-group-switch"
													onChange={(e) =>
														(allColumnsGroup[data] as ColumnInstance<object>[]).forEach(
															(c) => c.id !== "markets" && c.toggleHidden(!e.target.checked)
														)
													}
													checked={(allColumnsGroup[data] as ColumnInstance<object>[]).some((data) => data.isVisible)}
												/>
												<AccordionItem key={data} id={data} title={data}>
													<SwitchWrapper>
														{(allColumnsGroup[data] as ColumnInstance<object>[]).map((col) => {
															return <Switch label={col.Header || col.id} {...col.getToggleHiddenProps()} />;
														})}
													</SwitchWrapper>
												</AccordionItem>
											</AccordionItemWrapper>
										);
									} else if (allColumnsGroup[data].id !== "markets") {
										return (
											<Spacing bottom={100} top={100}>
												<Switch
													label={allColumnsGroup[data].Header || allColumnsGroup[data].id}
													{...allColumnsGroup[data].getToggleHiddenProps()}
												/>
											</Spacing>
										);
									} else {
										return <></>;
									}
								})}
						</Accordion>
					) : (
						<SwitchWrapper>
							{allColumns.map((column) => {
								return (
									column.id !== "markets" && (
										<Switch label={column.Header || column.id} {...column.getToggleHiddenProps()} />
									)
								);
							})}
						</SwitchWrapper>
					)}
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default FilterColumnsModal;
