import React from "react";
import styled from "styled-components";

import Choice, { ChoiceItem } from "@ingka/choice";

import { RoleData } from "../RequestNewRoleModal";
import { UserPermissions } from "../../../utils/constants";
import MaintainerMarketSelector from "../MaintainerMarketSelector";
import { UserApplication } from "../../../store";

interface ReadOnlyRoleSelctorLayoutProps {
	rolesData: RoleData[];
	setSelectedRole: (role: UserPermissions | null) => void;
	selectedRole: UserPermissions | null;
	requestingRole: boolean;
	readOnlyToMaintainerMarkets: string[];
	setReadOnlyToMaintainerMarkets: (data: string[]) => void;
	app: UserApplication & {
		id: string;
	};
}

const ChoiceWrapper = styled.div`
	.choice {
		display: flex;
		gap: 0.5rem;
	}
	.choice__list-item {
		width: 50%;
	}
`;

const StyledChoiceItem = styled(ChoiceItem)`
	min-height: 10rem;
	max-height: 10rem;
`;

const Layout: React.FC<ReadOnlyRoleSelctorLayoutProps> = ({
	rolesData,
	setSelectedRole,
	requestingRole,
	selectedRole,
	app,
	readOnlyToMaintainerMarkets,
	setReadOnlyToMaintainerMarkets,
}) => {
	return (
		<>
			<ChoiceWrapper>
				<Choice deselectable onSelect={(e) => setSelectedRole(e)}>
					{rolesData?.map((roleData) => (
						<StyledChoiceItem
							disabled={requestingRole}
							title={roleData?.role}
							id={roleData?.id}
							caption={roleData?.description}
							selected={selectedRole === roleData.id}
							data-testid={`choice-role-${roleData?.id}`}
						/>
					))}
				</Choice>
			</ChoiceWrapper>
			{selectedRole === UserPermissions.Maintainer && (
				<MaintainerMarketSelector
					appId={app?.id}
					selectedMarkets={readOnlyToMaintainerMarkets}
					setSelectedMarkets={setReadOnlyToMaintainerMarkets}
					requestingRole={requestingRole}
				/>
			)}
		</>
	);
};

export default Layout;
