/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import person from "@ingka/ssr-icon/paths/person";
import { colourNeutral3 } from "@ingka/variables/colours-css";
import Button from "@ingka/button";
import Skeleton from "@ingka/skeleton";
import Tooltip from "@ingka/tooltip";
import gear from "@ingka/ssr-icon/paths/gear";
import arrowRightFromBase from "@ingka/ssr-icon/paths/arrow-right-from-base";
import Toast from "@ingka/toast";
import menu from "@ingka/ssr-icon/paths/menu";
import MenuModal from "./MenuModal/MenuModal";
import { breakpointL, breakpointM, breakpointXl } from "@ingka/variables/design-tokens";

import IkeaLogo from "../../assets/ikea-logo.svg";
import { HomePageLayoutTabs, ROUTE_HOME, ROUTE_LOGOUT, showPillsPaths } from "../../utils/constants";
import ProfileModal from "../Profile/ProfileModal";
import { Application, ApplicationData, User } from "../../store";
import Applications from "../Applications/Applications";
import { MarketsData } from "../../hooks/useGetMarkets";
import Markets from "../Markets/Markets";
import AppsSettingsModal from "./AppsSettingsModal/AppsSettingsModal";
import useTabs from "../../hooks/useTabs";
import Banner from "@ingka/banner";
import { showBanner } from "../../utils/constants";
import Hyperlink from "@ingka/hyperlink";

import useStore, { setShowManageScheduledChangesModalSelector } from "../../store";

const StyledHeader = styled.nav`
	margin-bottom: 2rem;

	@media (width < ${breakpointM}) {
		padding-top: 1rem;
		margin-bottom: 1rem;
	}
`;

const ContentWrapper = styled.div`
	grid-column: 2/14;
	grid-row: 1;
	display: flex;
	align-items: center;
	padding: 1.5rem 0;
	border-bottom: 1px solid ${colourNeutral3};
	@media (width < ${breakpointM}) {
		padding: 0.5rem 0 0 0;
	}
`;

const StyledImg = styled.img`
	display: flex;
	max-height: 2.5rem;
	height: 2.5rem;
	width: 6rem;
	margin: 0.5rem 1rem 0.5rem 0;
`;
const RightItemsWrapper = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 0.75rem;
	@media (width < ${breakpointXl}) {
		gap: 0.25rem;
	}
`;
const PillsWrapper = styled.div`
	margin-left: 2rem;
	display: flex;
	gap: 1rem;
	align-items: center;

	@media (${breakpointL} <= width < ${breakpointXl}) {
		gap: 0.25rem;
	}
	@media (width < ${breakpointL}) {
		margin-left: 0;
		padding: 1rem 0;
		justify-content: center;
	}
`;

const StyledAnchor = styled(Link)`
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	font-weight: 700;
	color: rgb(var(--colour-text-and-icon-1, 17, 17, 17));
	text-decoration: none;
`;

const StyledListItem = styled.li`
	list-style-type: none;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

const StyledList = styled.ul`
	padding: 0;
	margin: 0;
`;

const StyledH3 = styled.h3`
	margin: 0;
`;

interface HeaderLayoutProps {
	isAuthenticated: boolean;
	activeApplication: Application;
	isFlaggaAdmin: boolean;
	showMenuModal: boolean;
	setShowMenuModal: (value: boolean) => void;
	showProfileModal: boolean;
	setShowProfileModal: (value: boolean) => void;
	applicationsLoading: boolean;
	marketsLoading: boolean;
	isApplicationAdmin: boolean;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	isBigDesktop: boolean;
	showAppsSettingseModal: boolean;
	setShowAppsSettingsModal: (value: boolean) => void;
	toastMessage: string;
	setToastMessage: (val: string) => void;
	pathname: string;
	currentUser?: User;
	applications?: ApplicationData[];
	marketsData?: MarketsData;
	showScheduledChangesHeader?: boolean;
}

const Layout: React.FC<HeaderLayoutProps> = ({
	isAuthenticated,
	activeApplication,
	isFlaggaAdmin,
	applications,
	applicationsLoading,
	marketsLoading,
	marketsData,
	isApplicationAdmin,
	showProfileModal,
	setShowProfileModal,
	currentUser,
	isMobile,
	isTablet,
	isDesktop,
	isBigDesktop,
	showAppsSettingseModal,
	setShowAppsSettingsModal,
	toastMessage,
	setToastMessage,
	pathname,
	showMenuModal,
	setShowMenuModal,
	showScheduledChangesHeader,
}) => {
	const showAdminLayout = React.useMemo(() => isFlaggaAdmin || isApplicationAdmin, [isApplicationAdmin, isFlaggaAdmin]);
	const { activeTab } = useTabs();

	const showHeader = React.useMemo(
		() => (currentUser?.user?.applications || isFlaggaAdmin) && isAuthenticated && showPillsPaths.includes(pathname),
		[currentUser?.user?.applications, isAuthenticated, isFlaggaAdmin, pathname]
	);

	const setShowManageScheduledChangesModal = useStore(setShowManageScheduledChangesModalSelector);

	return (
		<>
			<StyledHeader>
				{showBanner && (
					<Banner
						ariaCloseTxt="Close banner"
						body="Maintenance is planned on 08.10.2023 from 10am - 2pm CET"
						dismissable
					/>
				)}
				{showScheduledChangesHeader && (
					<Banner
						ariaCloseTxt="Close banner"
						dismissable
						body={
							<>
								<span>Changes for configuration were scheduled. Please click </span>
								<Hyperlink colour="white" text="here" onClick={() => setShowManageScheduledChangesModal(true)} />
								<span> to see the changes.</span>
							</>
						}
					/>
				)}
				<div className="page-container">
					<div className="page-container__inner">
						<ContentWrapper className="page-container__main">
							<StyledList>
								<StyledListItem key="flagga-home">
									<StyledAnchor to={ROUTE_HOME}>
										<StyledImg src={IkeaLogo} />
										{!isMobile && !isTablet && !isDesktop && <StyledH3>Flagga Configuration Tool</StyledH3>}
									</StyledAnchor>
								</StyledListItem>
							</StyledList>

							{showHeader && !isMobile && !isTablet && (
								<PillsWrapper>
									{applicationsLoading ? (
										<Skeleton height="2.5rem" width="12rem" />
									) : (
										<Applications applicationsData={applications} applicationsLoading={applicationsLoading} />
									)}
									<div>
										{applicationsLoading || marketsLoading ? (
											<Skeleton height="2.5rem" width="12rem" />
										) : activeApplication &&
										  (activeTab === HomePageLayoutTabs.Configuration ||
												activeTab === HomePageLayoutTabs.MarketDashboard) ? (
											<Markets marketsLoading={marketsLoading} marketsData={marketsData} />
										) : null}
									</div>
								</PillsWrapper>
							)}

							{isAuthenticated && (
								<RightItemsWrapper>
									{currentUser && (
										<>
											{isMobile || isTablet || isDesktop ? (
												<Tooltip tooltipText="Profile" position="bottom">
													<Button
														id="profile-button"
														type="tertiary"
														ssrIcon={person}
														onClick={() => setShowProfileModal(true)}
														small
														iconOnly
													/>
												</Tooltip>
											) : (
												<Button
													id="profile-button"
													type="tertiary"
													text={`Hej! ${currentUser?.user.name.split("(")[0]}`}
													ssrIcon={person}
													onClick={() => setShowProfileModal(true)}
													small
												/>
											)}
										</>
									)}
									<Tooltip tooltipText="Applications Settings" position="bottom">
										<Button
											id="application-settings-button"
											type="tertiary"
											text="Applications Settings"
											ssrIcon={gear}
											onClick={() => setShowAppsSettingsModal(true)}
											small
											iconOnly
										/>
									</Tooltip>
									{(isDesktop || isBigDesktop) && (
										<Tooltip tooltipText="Logout" position="bottom">
											<Button
												id="logout-button"
												type="tertiary"
												text="Logout"
												ssrIcon={arrowRightFromBase}
												href={ROUTE_LOGOUT}
												small
												iconOnly
											/>
										</Tooltip>
									)}
									{(isMobile || isTablet) && (
										<Tooltip tooltipText="Menu" position="bottom">
											<Button
												id="hamburger-menu"
												type="tertiary"
												text="Menu"
												ssrIcon={menu}
												onClick={() => setShowMenuModal(true)}
												small
												iconOnly
											/>
										</Tooltip>
									)}
								</RightItemsWrapper>
							)}
						</ContentWrapper>
					</div>
				</div>
				{(isMobile || isTablet) && showHeader && (
					<div className="page-container">
						{
							<PillsWrapper>
								{applicationsLoading ? (
									<Skeleton height="2.5rem" width="12rem" />
								) : (
									<Applications applicationsData={applications} applicationsLoading={applicationsLoading} />
								)}
								<div>
									{applicationsLoading || marketsLoading ? (
										<Skeleton height="2.5rem" width="12rem" />
									) : activeApplication &&
									  (activeTab === HomePageLayoutTabs.Configuration ||
											activeTab === HomePageLayoutTabs.MarketDashboard) ? (
										<Markets marketsLoading={marketsLoading} marketsData={marketsData} />
									) : null}
								</div>
							</PillsWrapper>
						}
					</div>
				)}
			</StyledHeader>
			{isAuthenticated && <ProfileModal showModal={showProfileModal} setShowModal={setShowProfileModal} />}
			{isAuthenticated && (
				<AppsSettingsModal showModal={showAppsSettingseModal} setShowModal={setShowAppsSettingsModal} />
			)}
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
			{isAuthenticated && (
				<MenuModal showModal={showMenuModal} setShowModal={setShowMenuModal} showAdminLayout={showAdminLayout} />
			)}
		</>
	);
};

export default Layout;
