import * as React from "react";
import Users from "../components/Users";
import RequireAuth from "../components/RequireAuth";

const users: React.FC = () => {
	return (
		<RequireAuth>
			<Users />
		</RequireAuth>
	);
};

export default users;
