import React from "react";
import _ from "lodash/fp";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import Button from "@ingka/button";
import Tooltip from "@ingka/tooltip";

type Props = {
	onClick?: () => void;
	title?: string;
};

const DeleteButton: React.FunctionComponent<Props> = ({ onClick = _.noop, title }: Props) => {
	return (
		<Tooltip tooltipText={title ? title : ""}>
			<Button type="tertiary" onClick={onClick} small iconOnly ssrIcon={trashCan} />
		</Tooltip>
	);
};

export default DeleteButton;
