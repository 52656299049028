import { isCategorization, rankWith, StatePropsOfLayout } from "@jsonforms/core";
import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import React, { useMemo } from "react";

import { useCategorizationService, useSearchElementParents, useSelection } from "../../core/context";
import { CategorizationLayout } from "../model/uischema";
import { createCategory } from "../util/generators/uiSchema";
import { DroppableElementRegistration } from "./DroppableElement";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import plus from "@ingka/ssr-icon/paths/plus";

interface DroppableCategorizationLayoutProps extends StatePropsOfLayout {
	uischema: CategorizationLayout;
}

const DroppableCategorizationLayout: React.FC<DroppableCategorizationLayoutProps> = (props) => {
	const { uischema, schema, path, renderers, cells } = props;

	// ignoring the first selection from the tuple since it is not used
	const [, setSelection] = useSelection();
	const [currentSelectedTab, setCurrentSelectedTab] = React.useState<string | undefined>(undefined);
	let categoriesTabs = [] as any;
	const categorizationService = useCategorizationService();
	const [searchElementParent] = useSearchElementParents();

	const categories = uischema.elements;

	const setSelectedTab = React.useCallback(
		(selectedUuid: string, event?: any) => {
			event?.stopPropagation();

			categorizationService.setTabSelection(uischema, {
				uuid: selectedUuid,
			});
			setSelection({ uuid: selectedUuid });
		},
		[categorizationService, setSelection, uischema]
	);

	// DroppableControl removed itself before dispatching to us, we need
	// to re-add it for our children
	const renderersToUse = useMemo(() => {
		return renderers && [...renderers, DroppableElementRegistration];
	}, [renderers]);

	const handleChange = React.useCallback(
		(event: any, value: any) => {
			if (typeof value === "string") {
				setCurrentSelectedTab(undefined);
				setSelectedTab(value, event);
			}
		},
		[setSelectedTab]
	);

	React.useEffect(() => {
		categories.forEach((el) => {
			const elementUuid = el.uuid;
			if (searchElementParent.includes(elementUuid)) {
				setCurrentSelectedTab(elementUuid);
				setSelectedTab(elementUuid);
			}
		});
	}, [categories, handleChange, searchElementParent, setSelectedTab, uischema.uuid]);

	const addTab = React.useCallback(() => {
		const tab = createCategory("New Tab " + (categories.length + 1));
		tab.parent = uischema;
		categories.push(tab);
	}, [categories, uischema]);

	categoriesTabs = React.useMemo(() => {
		let tabs = categories.map((e: any, idx: number) => (
			<Tab
				data-testid={`category-tab-${idx}`}
				key={idx}
				text={e.label}
				tabPanelId={e.uuid}
				onClick={(ev) => handleChange(ev, e.uuid)}
			/>
		));
		tabs.push(
			<Tab data-testid="add-new-tab" key={categories.length + 1} ssrIcon={plus} text="Add new tab" onClick={addTab} />
		);
		return tabs;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addTab, categories, categories.length, handleChange]);

	return (
		<>
			{categories && (
				<Tabs
					activeTab={currentSelectedTab}
					tabs={categoriesTabs}
					tabPanels={categories.map((e: any, idx: number) => (
						<TabPanel tabPanelId={e.uuid}>
							<JsonFormsDispatch
								schema={schema}
								uischema={categories[idx]}
								path={path}
								renderers={renderersToUse}
								cells={cells}
							/>
						</TabPanel>
					))}
				></Tabs>
			)}
		</>
	);
};

export const DroppableCategorizationLayoutRegistration = {
	tester: rankWith(40, isCategorization), // less than DroppableElement
	renderer: withJsonFormsLayoutProps(DroppableCategorizationLayout as React.FC<StatePropsOfLayout>),
};
