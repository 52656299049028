import * as React from "react";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface ChartProps {
	colorBar: string;
	label: string;
	series: number[];
	height?: number;
	width?: number;
}

const Chart: React.FC<ChartProps> = ({ colorBar, label, series }) => {
	const autoBreak = React.useMemo(() => {
		const maxLength = 18;
		const lines = [];

		for (let word of label.split(" ")) {
			if (lines.length == 0) {
				lines.push(word);
			} else {
				const i: number = lines.length - 1;
				const line: any = lines[i];

				if (line.length + 1 + word.length <= maxLength) {
					lines[i] = `${line} ${word}`;
				} else {
					lines.push(word);
				}
			}
		}
		return lines;
	}, [label]);

	const options: ApexOptions = {
		plotOptions: {
			radialBar: {
				hollow: {
					size: "60%",
					background: colorBar,
					margin: 0,
				},
				track: {
					background: colorBar,
					opacity: 1,
				},
				dataLabels: {
					name: {
						color: "#000000",
						fontSize: "13px",
						offsetY: 80,
					},
					value: {
						color: "#000000",
						fontSize: "20px",
						show: true,
						offsetY: -5,
					},
				},
			},
		},
		fill: {
			colors: [
				function ({ value }: any) {
					if (value < 25) {
						return "#FF3A29";
					} else if (value < 70) {
						return "#4339F2";
					} else {
						return "#38F240";
					}
				},
			],
		},
		stroke: {
			lineCap: "round",
		},
		colors: ["#000000"],
		labels: autoBreak,
	};

	return <ReactApexChart options={options} series={series} type="radialBar" height={180} width={170} />;
};

export default Chart;
