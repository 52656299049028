import * as React from "react";
import Layout from "./Layout";
import { useIsFlaggaAdmin } from "../../../hooks/useIsFlaggaAdmin";
import useStore, { isApplicationAdminSelector } from "../../../store";

interface AppsSettingsModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
}

const AppsSettingsModal: React.FC<AppsSettingsModalProps> = ({ showModal, setShowModal }) => {
	const [showBackupAndRestoreModal, setShowBackupAndRestoreModal] = React.useState(false);
	const [showAddUpdateDeleteAppModal, setShowAddUpdateDeleteAppModal] = React.useState(false);
	const [showRequestAppModal, setShowRequestAppModal] = React.useState(false);
	const [showCreateNewApplicationModal, setShowCreateNewApplicationModal] = React.useState(false);
	const [toast, setToast] = React.useState<string>("");
	const [showAdminsModal, setShowAdminsModal] = React.useState(false);
	const isApplicationAdmin = useStore(isApplicationAdminSelector);
	const { isFlaggaAdmin } = useIsFlaggaAdmin();

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			isFlaggaAdmin={isFlaggaAdmin}
			isApplicationAdmin={isApplicationAdmin}
			showBackupAndRestoreModal={showBackupAndRestoreModal}
			setShowBackupAndRestoreModal={setShowBackupAndRestoreModal}
			showAddUpdateDeleteAppModal={showAddUpdateDeleteAppModal}
			setShowAddUpdateDeleteAppModal={setShowAddUpdateDeleteAppModal}
			showRequestAppModal={showRequestAppModal}
			setShowRequestAppModal={setShowRequestAppModal}
			showCreateNewApplicationModal={showCreateNewApplicationModal}
			setShowCreateNewApplicationModal={setShowCreateNewApplicationModal}
			toast={toast}
			setToast={setToast}
			showAdminsModal={showAdminsModal}
			setShowAdminsModal={setShowAdminsModal}
		/>
	);
};

export default AppsSettingsModal;
