import React from "react";
import styled from "styled-components";

import { Schema, SchemaFieldOptionType, SchemaMenuOption } from "../../utils/types";
import {
	BoolItem,
	CreatableMultiSelectItem,
	NumberItem,
	RequiredMultiSelectItem,
	SelectItem,
	TextItem,
} from "./SchemaMenuItem";

const typeToItem: Record<SchemaFieldOptionType, React.FunctionComponent<any>> = {
	text: (props) => <TextItem {...props} />,
	number: (props) => <NumberItem {...props} />,
	boolean: (props) => <BoolItem {...props} />,
	multi_creatable: (props) => <CreatableMultiSelectItem {...props} />,
	select: (props) => <SelectItem {...props} />,
	required: (props) => <RequiredMultiSelectItem {...props} />,
};

interface Props {
	fields: SchemaMenuOption[];
	enabledFields: SchemaMenuOption[];
	schema: Schema;
	onChange: (schema: Schema) => void;
}

const StyledList = styled.ul`
	margin-top: 2rem;
	padding: 0;
`;

const StyledListItem = styled.li`
	margin-bottom: 2rem;
	list-style-type: none;
`;

const SchemaMenuList: React.FunctionComponent<Props> = ({ fields, schema, onChange, enabledFields }) => {
	return (
		<StyledList>
			{fields?.map((field) => {
				const isEnabled = enabledFields.some((enableField) => enableField.value === field.value);
				return (
					<StyledListItem key={field?.value}>
						{typeToItem[field.type]({ schema, onChange, field, isEnabled })}
					</StyledListItem>
				);
			})}
		</StyledList>
	);
};

export default SchemaMenuList;
