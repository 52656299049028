import flagNotFound from "../assets/flags/xx.svg";
export interface MarketWithName {
	code: string;
	name: string;
}

export const getImage = (market: string | null) => {
	try {
		const img = require(`../assets/flags/${market?.toLowerCase()}.svg`);
		return img;
	} catch (ex) {
		return flagNotFound;
	}
};