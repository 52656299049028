import * as React from "react";
import useAuthentication from "../../../hooks/useAuthentication";
import {
	declineApplicationAccessRequest,
	declineMarketsRequest,
	declineRoleRequest,
	getAppUsersList,
} from "../../../services/users";
import useStore, { setApplicationUsersSelector } from "../../../store";

import Layout from "./Layout";

interface ModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	appId: string | undefined;
	email: string | undefined;
	selectedRequestType?: "application" | "markets" | "role";
	setToastMsg?: (val: string) => void;
}

const DeclineAccessRequestModal: React.FC<ModalProps> = ({
	showModal,
	setShowModal,
	appId,
	email,
	selectedRequestType,
	setToastMsg,
}) => {
	const { getToken, handleLogout } = useAuthentication();
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [loading, setLoading] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>("");
	const setApplicationUsers = useStore(setApplicationUsersSelector);

	const declineAppAccessRequest = React.useCallback(
		async (appId, reason, email) => {
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			try {
				setLoading(true);
				if (selectedRequestType === "application") {
					await declineApplicationAccessRequest(authHeader, email, reason, appId);
				} else if (selectedRequestType === "markets") {
					await declineMarketsRequest(authHeader, email, reason, appId);
				} else if (selectedRequestType === "role") {
					await declineRoleRequest(authHeader, email, reason, appId);
				}
				setToastMessage("Declined successfully.");
				setToastMsg && setToastMsg("Declined successfully.");
				setShowModal(false);
				try {
					const { data: usersData } = await getAppUsersList(token, appId);
					setApplicationUsers(usersData.data);
				} catch (e: any) {
					window.location.reload();
				}
			} catch (err: any) {
				if (err?.response?.status == 401) {
					handleLogout();
				}
				setLoading(false);
				let errMsg = err.message;
				if (err.response && err.response.data && err.response.data.message) {
					errMsg += ` ${err.response.data.message}`;
				}
				setToastMessage(errMsg);
			} finally {
				setLoading(false);
			}
		},
		[getToken, handleLogout, selectedRequestType, setApplicationUsers, setShowModal, setToastMsg]
	);

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			appId={appId}
			email={email}
			setReason={setReason}
			reason={reason}
			loading={loading}
			declineAppAccessRequest={declineAppAccessRequest}
		/>
	);
};

export default DeclineAccessRequestModal;
