import React from "react";

import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";
import Text from "@ingka/text";
import Button from "@ingka/button";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import plusSmall from "@ingka/ssr-icon/paths/plus-small";
import Select, { Option } from "@ingka/select";

import ContentBox from "../../../ContentBox/ContentBox";
import { ValidationProps } from "../../types";
import {
	ClientIdsAddAction,
	ClientIdsContainer,
	ClientIdsHeaderInnerContainer,
	ClientIdsInnerContainer,
	ClientIdsInput,
	ExpandedClientIdsContainer,
	GreyPlaceholderText,
} from "../../../styles";
import { applicationPlanOptions } from "../../../../utils/constants";

interface ApplcationLayoutProps extends ValidationProps {}

const Layout: React.FC<ApplcationLayoutProps> = ({ value, valid, validation, handleChange, shouldValidate }) => {
	return (
		<ContentBox>
			<FormField
				valid={valid.appId}
				shouldValidate={shouldValidate.appId}
				validation={validation.appId}
				fieldHelper={{
					msg: "Application ID, lower case alphabets and - allowed.",
					id: "appIdValIdationHelper",
				}}
			>
				<InputField
					id="application-id"
					label="Application ID *"
					required
					type="text"
					value={value.appId}
					onChange={({ target: { value } }) => {
						handleChange(value, "applicationId");
					}}
				/>
			</FormField>
			<FormField
				valid={valid.appName}
				shouldValidate={shouldValidate.appName}
				validation={validation.appName}
				fieldHelper={{
					msg: "A Friendly Name for your Application.",
					id: "appNameValidationHelper",
				}}
			>
				<InputField
					id="application-name"
					label="Application Name *"
					required
					type="text"
					value={value.appName}
					onChange={({ target: { value } }) => {
						handleChange(value, "applicationName");
					}}
				/>
			</FormField>
			<FormField>
				<Select
					id="plan"
					label="Plan (optional)"
					value={value.plan}
					onChange={({ target: { value } }) => {
						handleChange(value, "plan");
					}}
				>
					{applicationPlanOptions.map((plan) => (
						<Option key={plan.value} value={plan.value} name={plan.text} />
					))}
				</Select>
			</FormField>
			<ClientIdsContainer>
				<ClientIdsHeaderInnerContainer>
					<ClientIdsAddAction>
						<Text bodySize="l">Application Client Ids</Text>
					</ClientIdsAddAction>
				</ClientIdsHeaderInnerContainer>
				{(!value.clientIds || value.clientIds?.length === 0) && (
					<GreyPlaceholderText>No client ID has been added</GreyPlaceholderText>
				)}
				<ExpandedClientIdsContainer>
					{value.clientIds?.map((clientId, index) => (
						<ClientIdsInnerContainer>
							<ClientIdsInput
								req={false}
								id={`clientId-${index + 1}`}
								value={clientId || ""}
								label={`Client ID ${index + 1}`}
								type="text"
								onChange={({ target: { value: val } }) => {
									handleChange(
										value.clientIds?.map((id, index) =>
											value.clientIds?.indexOf(clientId) === index ? val : id
										) as string[],
										"clientId"
									);
								}}
							/>
							<Button
								style={{ marginTop: "1rem" }}
								type="tertiary"
								onClick={() => {
									handleChange(
										value.clientIds?.filter((id, index) => value.clientIds?.indexOf(clientId) !== index) as string[],
										"clientId"
									);
								}}
								iconOnly
								small
								ssrIcon={trashCan}
							/>
						</ClientIdsInnerContainer>
					))}
					<Button
						style={{ marginTop: "1rem" }}
						type="tertiary"
						text="Add client id"
						data-testid="add-client-id-add"
						ssrIcon={plusSmall}
						iconPosition="trailing"
						onClick={() => {
							handleChange([...(value?.clientIds || []), ""], "clientId");
						}}
						small
					/>
				</ExpandedClientIdsContainer>
			</ClientIdsContainer>
		</ContentBox>
	);
};

export default Layout;
