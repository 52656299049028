import * as React from "react";
import _ from "lodash/fp";
import dataChart from "@ingka/ssr-icon/paths/data-chart";
import Button from "@ingka/button";
import Tooltip from "@ingka/tooltip";

type Props = {
	onClick?: () => void;
	title?: string;
};

const DashboardButton: React.FunctionComponent<Props> = ({ onClick = _.noop, title }) => {
	return (
		<Tooltip tooltipText={title ? title : ""}>
			<Button type="tertiary" onClick={onClick} small iconOnly ssrIcon={dataChart} />
		</Tooltip>
	);
};

export default DashboardButton;
