import React from "react";
import styled from "styled-components";

import { colourNeutral7 } from "@ingka/variables/colours-css";

interface SchemBoxProps {
	height?: number;
	isRoot?: boolean;
}

const SvgWrapper = styled.div`
	min-height: 2.6rem;
	width: 0.5rem;
`;

const Svg = styled.svg<SchemBoxProps>`
	width: 100%;
	height: ${({ isRoot }) => (isRoot ? "calc(100% - 5rem)" : "calc(100% - 4rem)")};
	min-height: 2.6rem;
	overflow: initial;
`;

const SchemaBoxWrapper = styled.div`
	display: flex;
`;

const SchemaBox: React.FunctionComponent<SchemBoxProps> = ({ children, height, isRoot }) => {
	return (
		<SchemaBoxWrapper>
			<SvgWrapper>
				<Svg height={height} isRoot={isRoot}>
					<line stroke={colourNeutral7} strokeWidth={1} x1={"35"} y1="-18" x2={"35"} y2="100%" />
					{/* DO NOT REMOVE MAYBE NEEDED LATER

					<line stroke={colourNeutral7} strokeWidth={1} x1={"29"} y1="99.99%" x2="100%" y2="99.99%" />  
					*/}
				</Svg>
			</SvgWrapper>
			<div>{children}</div>
		</SchemaBoxWrapper>
	);
};

export default SchemaBox;
