import "./JsonFormsEditor.css";
import "react-reflex/styles.css";

import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import { makeStyles } from "@material-ui/core";
import React, { ComponentType, useEffect, useReducer, useState } from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import { CategorizationService, CategorizationServiceImpl } from "./core/api/categorizationService";
import { DefaultPaletteService, PaletteService } from "./core/api/paletteService";
import { EmptySchemaService, SchemaService } from "./core/api/schemaService";
import { Header, Layout, Footer } from "./core/components";
import { EditorContextInstance } from "./core/context";
import { Actions, editorReducer } from "./core/model";
import { SelectedElement } from "./core/selection";
import { tryFindByUUID } from "./core/util/schemasUtil";
import { defaultEditorRenderers, defaultEditorTabs, EditorPanel } from "./editor";
import { EditorTab } from "./editor/components/EditorPanel";
import { defaultPalettePanelTabs, PalettePanel, PaletteTab } from "./palette-panel";
import { defaultPropertyRenderers, PropertiesPanel } from "./properties";
import {
	PropertiesService,
	PropertiesServiceImpl,
	PropertySchemasDecorator,
	PropertySchemasProvider,
} from "./properties/propertiesService";
import { buildUiSchema } from "./core/model/uischema";
import { colourNeutral3 } from "@ingka/variables/colours-css";
import { SearchElement, SearchElementParent } from "./core/search";

const useStyles = makeStyles((theme) => ({
	pane: {
		minHeight: "200px",
		height: "100%",
	},
	leftPane: {},
	centerPane: {
		alignItems: "stretch",
	},
	rightPane: {
		minWidth: "fit-content",
	},
	reflexContainer: {
		flex: "1",
		alignItems: "stretch",
	},
}));

interface JsonFormsEditorProps {
	schemaService?: SchemaService;
	schemaProviders: PropertySchemasProvider[];
	schemaDecorators: PropertySchemasDecorator[];
	editorTabs?: EditorTab[] | null;
	paletteService?: PaletteService;
	paletteTabs?: PaletteTab[] | null;
	editorRenderers?: JsonFormsRendererRegistryEntry[];
	propertyRenderers?: JsonFormsRendererRegistryEntry[];

	propertiesServiceProvider?: (
		schemaProviders: PropertySchemasProvider[],
		schemaDecorators: PropertySchemasDecorator[]
	) => PropertiesService;
	categorizationService?: CategorizationService;
	header?: ComponentType | null;
	footer?: ComponentType | null;
	setCurrentUiSchema?: (value: any) => void;
	setForceReset: (value: boolean) => void;
	forceReset: boolean;
}
const defaultSchemaService = new EmptySchemaService();
const defaultPaletteService = new DefaultPaletteService();
const defaultPropertiesService = (
	schemaProviders: PropertySchemasProvider[],
	schemaDecorators: PropertySchemasDecorator[]
) => new PropertiesServiceImpl(schemaProviders, schemaDecorators);
const defaultCategorizationService = new CategorizationServiceImpl();

export const JsonFormsEditor: React.FC<JsonFormsEditorProps> = ({
	schemaService = defaultSchemaService,
	paletteService = defaultPaletteService,
	categorizationService = defaultCategorizationService,
	propertiesServiceProvider = defaultPropertiesService,
	schemaProviders,
	schemaDecorators,
	editorRenderers = defaultEditorRenderers,
	editorTabs: editorTabsProp = defaultEditorTabs,
	paletteTabs = defaultPalettePanelTabs,
	propertyRenderers = defaultPropertyRenderers,
	setCurrentUiSchema,
	setForceReset,
	forceReset,
}) => {
	const [sortingUISchema, setSortingUISchema] = React.useState<"name" | "type">("name");

	const [{ schema, uiSchema }, dispatch] = useReducer(editorReducer, {
		categorizationService: defaultCategorizationService,
	});
	const [selection, setSelection] = useState<SelectedElement>(undefined);
	const [searchElement, setSearchElement] = useState<SearchElement>(undefined);
	const [searchElementParent, setSearchElementParent] = useState<SearchElementParent>([]);

	const [propertiesService] = useState<PropertiesService>(propertiesServiceProvider(schemaProviders, schemaDecorators));
	const editorTabs = editorTabsProp ?? undefined;

	useEffect(() => {
		if (forceReset) {
			schemaService.getUiSchema().then((uiSchema) => dispatch(Actions.setUiSchema(uiSchema)));
			setForceReset(false);
		}
	}, [schemaService, setForceReset, forceReset]);

	useEffect(() => {
		schemaService.getSchema().then((schema) => dispatch(Actions.setSchema(schema, sortingUISchema)));
		schemaService.getUiSchema().then((uiSchema) => dispatch(Actions.setUiSchema(uiSchema)));
	}, [schemaService, sortingUISchema]);

	useEffect(() => {
		setSelection((oldSelection) => {
			if (!oldSelection) {
				return oldSelection;
			}
			const idInNewSchema = tryFindByUUID(uiSchema, oldSelection.uuid);
			if (!idInNewSchema) {
				// element does not exist anymore - clear old selection
				return undefined;
			}
			return oldSelection;
		});
		if (setCurrentUiSchema) {
			setCurrentUiSchema(uiSchema ? buildUiSchema(uiSchema) : undefined);
		}
	}, [setCurrentUiSchema, uiSchema]);

	return (
		<EditorContextInstance.Provider
			value={{
				schema,
				uiSchema,
				dispatch,
				selection,
				setSelection,
				categorizationService,
				schemaService,
				paletteService,
				propertiesService,
				sortingUISchema,
				setSortingUISchema,
				searchElement,
				setSearchElement,
				searchElementParent,
				setSearchElementParent,
			}}
		>
			<DndProvider backend={Backend}>
				<JsonFormsEditorUi
					editorRenderers={editorRenderers}
					editorTabs={editorTabs}
					propertyRenderers={propertyRenderers}
					paletteTabs={paletteTabs ?? undefined}
					sortingUISchema={sortingUISchema}
					setSortingUISchema={setSortingUISchema}
				/>
			</DndProvider>
		</EditorContextInstance.Provider>
	);
};

interface JsonFormsEditorUiProps {
	editorTabs?: EditorTab[];
	editorRenderers: JsonFormsRendererRegistryEntry[];
	propertyRenderers: JsonFormsRendererRegistryEntry[];
	paletteTabs?: PaletteTab[];
	sortingUISchema: "name" | "type";
	setSortingUISchema: (sorting: "name" | "type") => void;
}
const JsonFormsEditorUi: React.FC<JsonFormsEditorUiProps> = ({
	editorTabs,
	editorRenderers,
	propertyRenderers,
	paletteTabs,
	sortingUISchema,
	setSortingUISchema,
}) => {
	const classes = useStyles();
	const [hidePalettePanel, setHidePalettePanel] = React.useState(false);
	const [hidePropertiesPanel, setHidePropertiesPanel] = React.useState(false);

	return (
		<Layout>
			<ReflexContainer orientation="vertical" className={classes.reflexContainer}>
				<ReflexElement size={hidePalettePanel ? 50 : 300} maxSize={hidePalettePanel ? 50 : 999}>
					<div className={`${classes.pane} ${classes.leftPane}`}>
						<PalettePanel
							paletteTabs={paletteTabs}
							hidePalettePanel={hidePalettePanel}
							setHidePalettePanel={setHidePalettePanel}
							sortingUISchema={sortingUISchema}
							setSortingUISchema={setSortingUISchema}
						/>
					</div>
				</ReflexElement>
				<ReflexSplitter
					propagate
					style={{
						borderLeft: `1px solid ${colourNeutral3}`,
						borderRight: "none",
						borderBottom: "none",
						borderTop: "none",
						backgroundColor: "transparent",
						cursor: hidePalettePanel ? "default" : "col-resize",
					}}
				/>
				<ReflexElement>
					<div className={`${classes.pane} ${classes.centerPane}`}>
						<EditorPanel editorTabs={editorTabs} editorRenderers={editorRenderers} />
					</div>
				</ReflexElement>
				<ReflexSplitter
					propagate
					style={{
						borderLeft: `1px solid ${colourNeutral3}`,
						borderRight: "none",
						borderBottom: "none",
						borderTop: "none",
						backgroundColor: "transparent",
						cursor: hidePropertiesPanel ? "default" : "col-resize",
					}}
				/>
				<ReflexElement flex={hidePropertiesPanel ? 0.035 : 0.25} minSize={50} maxSize={hidePropertiesPanel ? 50 : 999}>
					<div className={`${classes.pane} ${classes.rightPane}`}>
						<PropertiesPanel
							propertyRenderers={propertyRenderers}
							hidePropertiesPanel={hidePropertiesPanel}
							setHidePropertiesPanel={setHidePropertiesPanel}
						/>
					</div>
				</ReflexElement>
			</ReflexContainer>
		</Layout>
	);
};
