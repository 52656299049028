import * as React from "react";

import { ApplicationUiSchema } from "../../../services/applications/types";
import { UserApplicationData, UserData, UserMarketPermissions } from "../../../services/users";
import useStore, { activeApplicationSelector } from "../../../store";
import Layout from "./Layout";
import { AddMarketFormErrors, AddMarketFormShouldValidate, AddMarketFormValid } from "./types";
import { useGetCountryName } from "../../../hooks/useGetCountryName";

interface AddMarketModalProps {
	showAddMarketModal: boolean;
	setShowAddMarketModal: (value: boolean) => void;
	setSelectedUser: (data?: UserApplicationData) => void;
	selectedUser?: UserApplicationData;
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	selectedMarkets?: string[];
	setSelectedMarkets: (value?: string[]) => void;
}

const AddMarketModal: React.FC<AddMarketModalProps> = ({
	showAddMarketModal,
	setShowAddMarketModal,
	marketsData,
	uiSchemas,
	setSelectedUser,
	selectedUser,
	selectedMarkets,
	setSelectedMarkets,
}) => {
	const [selectedUiSchema, setSelectedUiSchema] = React.useState<string | undefined>(undefined);
	const [valid, setValid] = React.useState<AddMarketFormValid>({ market: false, uiSchema: false });
	const [shouldValidate, setShouldValidate] = React.useState<AddMarketFormShouldValidate>({
		market: false,
		uiSchema: false,
	});
	const [formError, setFormErrror] = React.useState<AddMarketFormErrors>({ market: "", uiSchema: "" });
	const activeApplication = useStore(activeApplicationSelector);
	const { getCountryName } = useGetCountryName();

	const validate = React.useCallback(
		(fieldName, value) => {
			if (fieldName === "market") {
				if (value === "Choose an option") {
					setValid({ ...valid, market: false });
					setShouldValidate({ ...shouldValidate, market: true });
					setFormErrror({ ...formError, market: "Please Select a Market" });
				} else {
					setValid({ ...valid, market: true });
				}
			}
			if (fieldName === "uiSchema") {
				if (value === "Choose an option") {
					setValid({ ...valid, uiSchema: false });
					setShouldValidate({ ...shouldValidate, uiSchema: true });
					setFormErrror({ ...formError, uiSchema: "Please Select a UI-Schema" });
				} else {
					setValid({ ...valid, uiSchema: true });
				}
			}
		},
		[formError, shouldValidate, valid]
	);

	const onAddMarket = React.useCallback(() => {
		if (selectedMarkets && selectedUiSchema && selectedUser && activeApplication) {
			const existingMarkets = selectedUser?.applications?.[activeApplication.id].markets;
			let assignUserMarkets = {} || undefined;

			assignUserMarkets = selectedMarkets?.reduce(function (previous: UserMarketPermissions, selectedMarket) {
				previous = { ...previous, [selectedMarket]: selectedUiSchema };
				return previous;
			}, {});

			setSelectedUser({
				...selectedUser,
				applications: {
					...selectedUser.applications,
					[activeApplication.id]: {
						...selectedUser.applications?.[activeApplication.id],
						markets: { ...existingMarkets, ...assignUserMarkets },
					},
				},
			});
			setShowAddMarketModal(false);
		}
	}, [selectedMarkets, selectedUiSchema, selectedUser, activeApplication, setSelectedUser, setShowAddMarketModal]);

	return (
		<Layout
			showAddMarketModal={showAddMarketModal}
			setShowAddMarketModal={setShowAddMarketModal}
			marketsData={marketsData}
			uiSchemas={uiSchemas}
			valid={valid}
			shouldValidate={shouldValidate}
			formError={formError}
			validate={validate}
			setValid={setValid}
			setShouldValidate={setShouldValidate}
			setSelectedUser={setSelectedUser}
			setSelectedUiSchema={setSelectedUiSchema}
			onAddMarket={onAddMarket}
			selectedMarkets={selectedMarkets}
			setSelectedMarkets={setSelectedMarkets}
			getCountryName={getCountryName}
		/>
	);
};

export default AddMarketModal;
