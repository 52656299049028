import * as React from "react";
import styled from "styled-components";

import FormField from "@ingka/form-field";
import Select, { Option as SelectOption } from "@ingka/select";
import Button from "@ingka/button";
import SSRIcon from "@ingka/ssr-icon";
import { OverflowCarousel } from "@ingka/carousel";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import Skeleton from "@ingka/skeleton";

import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";

import { MultiSelectStyles } from "../styles";
import { getImage } from "../../utils/markets";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-height: 18rem;
`;

export const StyledButton = styled(Button)`
	width: fit-content;
	margin-left: 2em;
`;

const StyledMuiSelectLabel = styled.p`
	font-size: 0.875rem;
	line-height: 1.571;
	margin: 0 0 0.125rem 0;
`;

const StyledImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 10px;
`;

const StyledSSRIcon = styled(SSRIcon)`
	margin-right: 0.7rem;
`;

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
`;

const SkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	margin: 1rem 0 1rem 0;
`;

const SelectValueItemWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: rgba(var(--colour-static-ikea-family, 223, 223, 223, 1));
	border-radius: 12px;
	padding: 0 0.5rem 0 0.5rem;
	min-width: 3.4rem;
	margin-right: 0.25rem;
`;

const SelectImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 0.2rem;
`;

interface ApplicationData {
	id: string;
	name: string;
}

interface RequestApplicationLayout {
	selectedApplication?: ApplicationData;
	setSelectedApplication: (application?: ApplicationData) => void;
	onRequestAccess: () => void;
	accessReqLoading: boolean;
	data?: ApplicationData[];
	setSelectedMarkets: (val: string[]) => void;
	selectedMarkets: string[];
	marketsData: any[];
	applicationsLoading: boolean;
	marketsDataLoading: boolean;
}

const Layout: React.FC<RequestApplicationLayout> = ({
	selectedApplication,
	setSelectedMarkets,
	setSelectedApplication,
	data,
	accessReqLoading,
	onRequestAccess,
	selectedMarkets,
	marketsData,
	marketsDataLoading,
	applicationsLoading,
}) => {
	const handleChange = (event: SelectChangeEvent<typeof selectedMarkets>) => {
		const {
			target: { value },
		} = event;

		setSelectedMarkets(typeof value === "string" ? value.split(",") : value);
	};

	return (
		<>
			<StyledWrapper>
				<div>
					{applicationsLoading ? (
						<SkeletonWrapper>
							<SkeletonWrapper>
								<Skeleton />
							</SkeletonWrapper>
							<Skeleton width="8rem" />
							<Skeleton height="2.8rem" />
						</SkeletonWrapper>
					) : (
						<>
							<p>Please select application you want to get access in Flagga.</p>
							<FormField>
								<Select
									id="request-application-access"
									label="Select Application"
									value={selectedApplication?.id}
									disabled={accessReqLoading}
									onChange={(e) => {
										setSelectedMarkets([]);
										if (e.target.value === "Choose an option") {
											setSelectedApplication(undefined);
										} else {
											const app = data?.find((application) => application.id === (e.target.value as string));
											if (app?.id) setSelectedApplication({ id: app?.id, name: app?.name });
											else setSelectedApplication(undefined);
										}
									}}
								>
									{data?.map((application) => (
										<SelectOption
											id={application.id}
											key={application.id}
											value={application.id}
											name={application.name}
										/>
									))}
								</Select>
							</FormField>
						</>
					)}
					{selectedApplication && (
						<div>
							{marketsDataLoading || applicationsLoading ? (
								<SkeletonWrapper>
									<Skeleton width="10rem" />
									<Skeleton height="2.8rem" />
								</SkeletonWrapper>
							) : (
								<>
									<StyledMuiSelectLabel
										style={
											!selectedApplication || accessReqLoading
												? { color: "rgb(var(--colour-interactive-disabled-1, 223, 223, 223))" }
												: {}
										}
									>
										Select Market (optional)
									</StyledMuiSelectLabel>
									<MuiSelect
										labelId="select-market-checkbox-label"
										id="select-market-multiple-checkbox"
										displayEmpty
										renderValue={(selected: string[]) => {
											if (selected.length === 0) {
												return (
													<MenuItem key="multi-select-placeholder" disabled sx={{ padding: 0 }} value="">
														Choose an option
													</MenuItem>
												);
											}

											return (
												<OverflowCarousel hasNoControls>
													{selected.map((market: string) => (
														<SelectValueItemWrapper>
															<SelectImage src={getImage(market) || getImage("xx")} />
															<span>{market}</span>
														</SelectValueItemWrapper>
													))}
												</OverflowCarousel>
											);
										}}
										multiple
										value={selectedMarkets}
										disabled={!selectedApplication || accessReqLoading}
										onChange={handleChange}
										input={<OutlinedInput />}
										style={{ width: "100%", height: "3rem" }}
										sx={{
											...MultiSelectStyles,
											...((!selectedApplication || accessReqLoading) && {
												".svg-icon": {
													fill: "rgb(var(--colour-interactive-disabled-1, 223, 223, 223))",
												},
											}),
										}}
										IconComponent={() => <StyledSSRIcon paths={chevronDownSmall} viewBox="0 0 21 21" />}
										MenuProps={{
											MenuListProps: { id: "request-new-application-markets-list" },
											PaperProps: {
												sx: {
													maxHeight: "20rem",
												},
											},
										}}
										inputProps={{ placeholder: "Choose an option" }}
									>
										{marketsData.length ? (
											marketsData.map((market) => (
												<MenuItem id={market.key} disableRipple key={market.key} value={market.key}>
													<StyledImage src={getImage(market.key) || getImage("xx")} />
													<ListItemText primary={`${market.name}(${market.key})`} />
													<Checkbox checked={selectedMarkets.indexOf(market.key) > -1} />
												</MenuItem>
											))
										) : (
											<MenuItem disabled>
												<p>Seems like the application does not have any markets</p>
											</MenuItem>
										)}
									</MuiSelect>
								</>
							)}
						</div>
					)}
				</div>

				<StyledDiv>
					<StyledButton
						text="Request Access"
						type="emphasised"
						disabled={!selectedApplication}
						onClick={onRequestAccess}
						loading={accessReqLoading}
						id="request-access-button"
					/>
				</StyledDiv>
			</StyledWrapper>
		</>
	);
};

export default Layout;
