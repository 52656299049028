import * as React from "react";

import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import styled from "styled-components";
import Button from "@ingka/button";
import InputField from "@ingka/input-field";
import { colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";
import Accordion, { AccordionItem } from "@ingka/accordion";
import Select, { Option as SelectOption } from "@ingka/select";
import { ApplicationUiSchema } from "../../services/applications/types";

interface NewUiSchemaModalProps {
	showNewUiSchemaModal: boolean;
	setShowNewUiSchemaModal: (val: boolean) => void;
	setNewUISchemaName: (val: string) => void;
	uiSchemaNames: string[] | undefined;
	currentUiSchemas: ApplicationUiSchema[];
	setCurrentUiSchemas: (val: any) => void;
	setSelectedUiSchema: (value: string) => void;
	setNewSchemaAdded: (val: boolean) => void;
}

const StyledModalHeader = styled(ModalHeader)`
	background-color: ${colourStaticIkeaBrandYellow};
`;

const StyledAccordionItem = styled(AccordionItem)`
	padding-top: 0rem;
`;

const StyledInputField = styled(InputField)`
	margin-top: 2rem;
`;

const NewUiSchemaModal: React.FC<NewUiSchemaModalProps> = ({
	showNewUiSchemaModal,
	setShowNewUiSchemaModal,
	setNewUISchemaName,
	uiSchemaNames,
	currentUiSchemas,
	setCurrentUiSchemas,
	setSelectedUiSchema,
	setNewSchemaAdded,
}) => {
	const [selectedUiSchemaCopyFrom, setSelectedUiSchemaCopyFrom] = React.useState<string>("ui-schema");
	const newSchemaInputEl = React.useRef(null) as any;
	const newSchemaFromExistingInputEl = React.useRef(null) as any;

	return (
		<Modal
			focusLockProps={{ locked: false }}
			visible={showNewUiSchemaModal}
			escapable={false}
			handleCloseBtn={() => setShowNewUiSchemaModal(false)}
		>
			<Sheets
				id="new-ui-schema-modal"
				title=""
				header={<StyledModalHeader title="New UI-Schema" />}
				footer={
					<ModalFooter>
						<Button
							id="confirm-ui-schema-name"
							text="Create"
							type="emphasised"
							onClick={() => {
								if (newSchemaInputEl?.current?.value) {
									setNewUISchemaName(newSchemaInputEl?.current?.value);
									setCurrentUiSchemas([
										...currentUiSchemas,
										{
											uiSchemaName: newSchemaInputEl?.current?.value,
											uiSchema: undefined,
										},
									]);
									setSelectedUiSchema(newSchemaInputEl?.current?.value);
									setShowNewUiSchemaModal(false);
									setNewSchemaAdded(true);
								}
								if (newSchemaFromExistingInputEl?.current?.value) {
									setNewUISchemaName(newSchemaFromExistingInputEl?.current?.value);
									setCurrentUiSchemas([
										...currentUiSchemas,
										{
											uiSchemaName: newSchemaFromExistingInputEl?.current?.value,
											uiSchema:
												currentUiSchemas.find((schema) => schema["uiSchemaName"] === selectedUiSchemaCopyFrom)
													?.uiSchema || {},
										},
									]);
									setSelectedUiSchema(newSchemaFromExistingInputEl?.current?.value);
									setShowNewUiSchemaModal(false);
									setNewSchemaAdded(true);
								}
							}}
						/>
						<Button
							id="reason-cancel-button"
							text="Cancel"
							type="secondary"
							onClick={() => setShowNewUiSchemaModal(false)}
						/>
					</ModalFooter>
				}
			>
				<ModalBody>
					<Accordion size="small">
						<AccordionItem id="create-new-ui-schema" title={"Create New UI-Schema"}>
							<InputField
								id="new-ui-schema-name"
								label="Please Enter new Ui-Schema name"
								type="text"
								ref={newSchemaInputEl}
							/>
						</AccordionItem>
					</Accordion>
					<Accordion size="small">
						<StyledAccordionItem id="create-new-ui-schema-from-existing" title={"Create from Existing UI-Schema"}>
							<Select
								id="uischema"
								label="Select Ui Schema to copy from"
								value={selectedUiSchemaCopyFrom}
								onChange={({ target: { value } }) => {
									setSelectedUiSchemaCopyFrom(value);
								}}
							>
								{uiSchemaNames?.map((name) => (
									<SelectOption key={name} value={name} name={name} />
								))}
							</Select>
							<StyledInputField
								id="new-ui-schema-name"
								label="Please Enter new Ui-Schema name"
								type="text"
								ref={newSchemaFromExistingInputEl}
							/>
						</StyledAccordionItem>
					</Accordion>
				</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default NewUiSchemaModal;
