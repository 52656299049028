import * as React from "react";
import styled from "styled-components";

import Select, { Option } from "@ingka/select";
import FormField from "@ingka/form-field";
import Skeleton from "@ingka/skeleton";
import Button from "@ingka/button";
import SSRIcon from "@ingka/ssr-icon";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import Checkbox from "@ingka/checkbox";
import { OverflowCarousel } from "@ingka/carousel";
import InlineMessage from "@ingka/inline-message";

import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import { ListItemText, MenuItem, OutlinedInput } from "@mui/material";

import { getImage } from "../../utils/markets";
import { MultiSelectStyles } from "../styles";
import { MaintainerApplications } from "./RequestNewMarketAccess";

interface RequestNewMarketAccessLayoutProps {
	userApplications: MaintainerApplications[];
	onAppChange: ((...args: any[]) => any) | undefined;
	marketsDataLoading: boolean;
	selectedMarkets: string[];
	marketsData: { key: string; name: string }[];
	requestingMarketsLoading: boolean;
	setSelectedMarkets: (val: string[]) => void;
	onRequestMarkets: () => void;
	selectedApp?: string;
	appId?: string;
}

const SelectValueItemWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: rgba(var(--colour-static-ikea-family, 223, 223, 223, 1));
	border-radius: 12px;
	padding: 0 0.5rem 0 0.5rem;
	min-width: 3.4rem;
	margin-right: 0.25rem;
`;
const SelectImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 0.2rem;
`;

const StyledSSRIcon = styled(SSRIcon)`
	margin-right: 0.7rem;
`;

interface SelectLabelProps {
	disabled: boolean;
}

const StyledSelectLabel = styled.p<SelectLabelProps>`
	font-size: 0.875rem;
	line-height: 1.571;
	margin: 0 0 0.125rem 0;
	${({ disabled }) => disabled && "color: rgb(var(--colour-interactive-disabled-1, 223, 223, 223))"}
`;

const SkeletonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	margin: 1rem 0 1rem 0;
`;

const StyledImage = styled.img`
	height: 15px;
	width: 15px;
	margin-right: 10px;
`;

const ButtonWrapper = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Layout: React.FC<RequestNewMarketAccessLayoutProps> = ({
	userApplications,
	selectedApp,
	onAppChange,
	marketsData,
	marketsDataLoading,
	selectedMarkets,
	setSelectedMarkets,
	onRequestMarkets,
	requestingMarketsLoading,
	appId,
}) => {
	const handleChange = (event: SelectChangeEvent<typeof selectedMarkets>) => {
		const {
			target: { value },
		} = event;

		setSelectedMarkets(typeof value === "string" ? value.split(",") : value);
	};
	return (
		<>
			{userApplications.length ? (
				<>
					<FormField>
						{!appId ? (
							<>
								{userApplications?.length > 1 ? (
									<Select
										id={"request-market-applications"}
										label="Select Application"
										onChange={onAppChange}
										disabled={requestingMarketsLoading}
									>
										{userApplications?.map((app) => (
											<Option value={app.id} name={app.name} />
										))}
									</Select>
								) : (
									<>
										<p>You only have one application to request markets!</p>
										<h2>{userApplications[0].name}</h2>
									</>
								)}
							</>
						) : (
							<></>
						)}
					</FormField>
					{marketsDataLoading ? (
						<SkeletonWrapper>
							<Skeleton width="8rem" />
							<Skeleton height="2.8rem" />
						</SkeletonWrapper>
					) : (
						<>
							<StyledSelectLabel disabled={marketsDataLoading || !selectedApp || requestingMarketsLoading}>
								Select Markets
							</StyledSelectLabel>
							<MuiSelect
								labelId="select-market-checkbox-label"
								id="select-market-multiple-checkbox"
								displayEmpty
								renderValue={(selected: string[]) => {
									if (selected.length === 0) {
										return (
											<MenuItem key="multi-select-placeholder" disabled sx={{ padding: 0 }} value="">
												Choose an option
											</MenuItem>
										);
									}

									return (
										<OverflowCarousel hasNoControls>
											{selected.map((market: string) => (
												<SelectValueItemWrapper>
													<SelectImage src={getImage(market) || getImage("xx")} />
													<span>{market}</span>
												</SelectValueItemWrapper>
											))}
										</OverflowCarousel>
									);
								}}
								multiple
								value={selectedMarkets}
								disabled={marketsDataLoading || !selectedApp || requestingMarketsLoading}
								onChange={handleChange}
								input={<OutlinedInput />}
								style={{ width: "100%", height: "3rem" }}
								sx={{
									...MultiSelectStyles,
								}}
								IconComponent={() => <StyledSSRIcon paths={chevronDownSmall} viewBox="0 0 21 21" />}
								MenuProps={{
									MenuListProps: { id: "markets-list" },
									PaperProps: {
										sx: {
											maxHeight: "20rem",
											...(!selectedApp && {
												".svg-icon": {
													fill: "rgb(var(--colour-interactive-disabled-1, 223, 223, 223))",
												},
											}),
										},
									},
								}}
								inputProps={{ placeholder: "Choose an option" }}
							>
								{marketsData.length ? (
									marketsData.map((market) => (
										<MenuItem disableRipple id={market.key} key={market.key} value={market.key}>
											<StyledImage src={getImage(market.key) || getImage("xx")} />
											<ListItemText primary={`${market.name}(${market.key})`} />
											<Checkbox
												checked={selectedMarkets?.indexOf(market.key) > -1 || false}
												id={market.key}
												value={market.key}
											/>
										</MenuItem>
									))
								) : (
									<MenuItem disabled>
										<p>Seems like the application does not have any markets</p>
									</MenuItem>
								)}
							</MuiSelect>
						</>
					)}
					<ButtonWrapper>
						<Button
							id="request-markets-access"
							text="Request Markets Access"
							type="emphasised"
							loading={requestingMarketsLoading}
							disabled={!selectedApp || !selectedMarkets.length}
							onClick={onRequestMarkets}
						/>
					</ButtonWrapper>
				</>
			) : (
				<InlineMessage
					title="No Applications to request markets"
					body="Seems like you are admin in all the applications"
				/>
			)}
		</>
	);
};

export default Layout;
