import React, { useCallback, useEffect, useState } from "react";
import Layout from "./Layout";
import {
	ROLE_DESCRIPTION_ADMIN,
	ROLE_DESCRIPTION_MAINTAINER,
	ROLE_DESCRIPTION_READ_ONLY,
	ResponseMessage,
	UserPermissions,
} from "../../utils/constants";
import useGetRoles from "../../hooks/useGetRoles";
import useStore, { UserApplication, setUserSelector } from "../../store";
import useRequestRoles from "../../hooks/useRequestRole";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";
import { UserMarketPermissions } from "../../services/users";

enum Roles {
	Admin = "Admin",
	Maintainer = "Maintainer",
	ReadOnly = "Read Only",
}

export interface RoleData {
	role?: Roles;
	id: UserPermissions;
	description?: string;
}

interface RequestNewRoleModalProps {
	app?: UserApplication & {
		id: string;
	};
	showRequestNewRoleModal: boolean;
	setShowRequestNewRoleModal: (val: boolean) => void;
}

const RequestNewRoleModal: React.FC<RequestNewRoleModalProps> = ({
	app,
	showRequestNewRoleModal,
	setShowRequestNewRoleModal,
}) => {
	const { data, loading, error, setData } = useGetRoles(showRequestNewRoleModal ? app?.id : undefined);
	const [rolesData, setRolesdata] = useState<RoleData[] | undefined>(undefined);
	const [roleChangeConfirmationModalOpen, setRoleChangeConfirmationModalOpen] = React.useState<boolean>(false);
	const [isEditingMarkets, setIsEditingMarkets] = React.useState<boolean>(false);
	const [selectedRole, setSelectedRole] = useState<UserPermissions | null>(null);
	const [maintainerMarkets, setMaintainerMarkets] = useState<UserMarketPermissions>({});
	const [readOnlyToMaintainerMarkets, setReadOnlyToMaintainerMarkets] = useState<string[]>([]);
	const [requestingRole, setRequestingRole] = useState<boolean>(false);
	const [toast, setToast] = useState<string>("");

	const setUser = useStore(setUserSelector);

	const { requestRole } = useRequestRoles({
		appId: app?.id,
		role: selectedRole,
		markets: Object.keys(maintainerMarkets)?.length
			? maintainerMarkets
			: readOnlyToMaintainerMarkets.length
			? readOnlyToMaintainerMarkets
			: undefined,
	});
	const { getUser, data: userData } = useGetLoggedInUser();

	const getRoleData = useCallback((role: string) => {
		switch (role) {
			case UserPermissions.Admin:
				return { role: Roles.Admin, description: ROLE_DESCRIPTION_ADMIN };
			case UserPermissions.Maintainer:
				return { role: Roles.Maintainer, description: ROLE_DESCRIPTION_MAINTAINER };
			case UserPermissions.ReadOnly:
				return { role: Roles.ReadOnly, description: ROLE_DESCRIPTION_READ_ONLY };
			default:
				return undefined;
		}
	}, []);

	useEffect(() => {
		if (data && app?.id) {
			const roles = data.map((role) => ({
				...getRoleData(role),
				id: role,
			}));
			setRolesdata(roles);
		}
	}, [data, error, getRoleData, app]);

	const onRequestRole = useCallback(async () => {
		setRequestingRole(true);
		if (roleChangeConfirmationModalOpen) setRoleChangeConfirmationModalOpen(false);
		const response = await requestRole();
		if (response?.toLowerCase() === ResponseMessage.Success) {
			await getUser();
			setRolesdata(undefined);
			setData(undefined);
			setSelectedRole(null);
			setMaintainerMarkets({});
			setShowRequestNewRoleModal(false);
			if (app?.permissions === UserPermissions.Admin) {
				setToast("Role changed successfully");
			} else if (app?.permissions === UserPermissions.Maintainer && selectedRole === UserPermissions.ReadOnly) {
				setToast("Role changed successfully");
			} else setToast("Role request was sent successfully");
		} else {
			setToast(response || "Cannot request new role something went wrong");
		}
		setRequestingRole(false);
	}, [
		app?.permissions,
		getUser,
		requestRole,
		roleChangeConfirmationModalOpen,
		selectedRole,
		setData,
		setShowRequestNewRoleModal,
	]);

	React.useEffect(() => {
		if (userData) {
			setUser(userData);
		}
	}, [userData, setUser]);

	const isRoleRequest = React.useMemo(() => {
		if (app?.permissions === UserPermissions.Admin) {
			return false;
		} else if (app?.permissions === UserPermissions.Maintainer && selectedRole === UserPermissions.ReadOnly) {
			return false;
		} else return true;
	}, [app?.permissions, selectedRole]);

	return (
		<Layout
			app={app}
			rolesData={rolesData}
			showRequestNewRoleModal={showRequestNewRoleModal}
			setShowRequestNewRoleModal={setShowRequestNewRoleModal}
			setSelectedRole={setSelectedRole}
			selectedRole={selectedRole}
			setMaintainerMarkets={setMaintainerMarkets}
			maintainerMarkets={maintainerMarkets}
			loading={loading}
			onRequestRole={onRequestRole}
			requestingRole={requestingRole}
			toast={toast}
			setToast={setToast}
			readOnlyToMaintainerMarkets={readOnlyToMaintainerMarkets}
			setReadOnlyToMaintainerMarkets={setReadOnlyToMaintainerMarkets}
			isRoleRequest={isRoleRequest}
			setRoleChangeConfirmationModalOpen={setRoleChangeConfirmationModalOpen}
			roleChangeConfirmationModalOpen={roleChangeConfirmationModalOpen}
			isEditingMarkets={isEditingMarkets}
			setIsEditingMarkets={setIsEditingMarkets}
		/>
	);
};

export default RequestNewRoleModal;
