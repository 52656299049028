import React from "react";
export const handleStringChange = (handler: (value: string) => void, type: string) => {
	return (event: React.FormEvent<HTMLElement>): void => {
		handler(
			type === "number" && !/^\d+$/.test((event.target as HTMLInputElement).value)
				? (event.target as HTMLInputElement).value.replace(/\D/g, "")
				: (event.target as HTMLInputElement).value
		);
	};
};

export const handleEnterPress = (handler: () => void) => {
	return (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === "Enter") {
			handler();
		}
	};
};
