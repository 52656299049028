import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

import useStore, { userSelector } from "../../store";

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const isAuthenticated = useIsAuthenticated();
	const user = useStore(userSelector);
	let location = useLocation();
	const navigate = useNavigate();
	const { inProgress } = useMsal();

	React.useEffect(() => {
		if (inProgress === InteractionStatus.None && !isAuthenticated) {
			// Redirect user to the /login page, but save the current location they were
			// trying to go to when they were redirected. This allows us to send user
			// along to that page after they login, which is a nicer user experience
			// than dropping user off on the home page.
			navigate("/login", { state: { from: location }, replace: true });
		}
	}, [inProgress, isAuthenticated, location, navigate]);

	return <AuthenticatedTemplate>{user && children}</AuthenticatedTemplate>;
};

export default RequireAuth;
