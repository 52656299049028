import * as React from "react";

import useStore, {
	activeApplicationSelector,
	ApplicationData,
	setActiveApplicationSelector,
	setActiveMarketSelector,
	setselectedMarketsSelector,
	setMultipleMarketUpdateFlagSelector,
} from "../../store";
import Layout from "./Layout";
import useActiveApp from "../../hooks/useActiveApp";

interface ApplicationsProps {
	applicationsLoading: boolean;
	applicationsData?: ApplicationData[];
}

const Applications: React.FC<ApplicationsProps> = ({ applicationsData, applicationsLoading }) => {
	const setActiveApplication = useStore(setActiveApplicationSelector);
	const { activeApp, setActiveApp } = useActiveApp();

	const activeApplication = useStore(activeApplicationSelector);
	const setActiveMarket = useStore(setActiveMarketSelector);
	const setselectedMarkets = useStore(setselectedMarketsSelector);
	const setMultipleMarketFlag = useStore(setMultipleMarketUpdateFlagSelector);

	React.useEffect(() => {
		if (applicationsData && !applicationsLoading) {
			setMultipleMarketFlag(false);
			setselectedMarkets([]);

			if ((activeApp && activeApplication && activeApplication.id !== activeApp) || (activeApp && !activeApplication)) {
				setActiveApplication(applicationsData.find((app) => app.id === activeApp) || null);
			} else {
				if (activeApplication) {
					setActiveApp(activeApplication.id);
				} else {
					setActiveApp(applicationsData[0].id);
					setActiveApplication(applicationsData[0]);
				}
			}
		}
	}, [
		activeApp,
		activeApplication,
		applicationsData,
		applicationsLoading,
		setMultipleMarketFlag,
		setselectedMarkets,
		setActiveApp,
		setActiveApplication,
	]);

	return (
		<Layout
			loading={applicationsLoading}
			applicationsData={applicationsData}
			setActiveApplication={setActiveApplication}
			activeApplication={activeApplication}
			setActiveMarket={setActiveMarket}
			setselectedMarkets={setselectedMarkets}
			setMultipleMarketFlag={setMultipleMarketFlag}
			setActiveApp={setActiveApp}
		/>
	);
};

export default Applications;
