import * as React from "react";
import styled from "styled-components";
import InlineMessage from "@ingka/inline-message";
import Button from "@ingka/button";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";

import { headerAndFooterHeight } from "../../utils/variables";
import LoadingLayout from "../LoadingLayout";

interface EmailConsentLayoutProps {
	modalDisagree: boolean;
	setModalDisagree: (val: boolean) => void;
	handleConsent: ({
		emailConsentAccepted,
		isRemoveUser,
	}: {
		emailConsentAccepted: boolean;
		isRemoveUser?: boolean;
	}) => void;
	loading: boolean;
}

const StyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - ${headerAndFooterHeight});
`;

const StyledH3 = styled.h3`
	margin: 0;
`;

const StyledParagraph = styled.p`
	margin: 0;
`;

const ButtonsContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-top: 1rem;
	justify-items: end;
`;

const Layout: React.FC<EmailConsentLayoutProps> = ({ modalDisagree, setModalDisagree, handleConsent, loading }) => {
	if (loading) {
		return <LoadingLayout />;
	}
	return (
		<>
			<StyledDiv>
				<InlineMessage
					title={<StyledH3>Email, First Name and Last Name Consent</StyledH3>}
					body={
						<div>
							<StyledParagraph>
								To be able to identify you, we are storing your email, first name and last name.
								<br />
								You need to agree to continue to Flagga.
							</StyledParagraph>
							<ButtonsContainer>
								<Button
									type="emphasised"
									text="Agree"
									small
									onClick={() => handleConsent({ emailConsentAccepted: true })}
								/>
								<Button text="Disagree" small onClick={() => setModalDisagree(true)} />
							</ButtonsContainer>
						</div>
					}
					ssrIcon={informationCircle}
				/>
			</StyledDiv>
			<Modal
				focusLockProps={{ locked: false }}
				visible={modalDisagree}
				escapable={false}
				handleCloseBtn={() => setModalDisagree(false)}
			>
				<Prompt
					title="Are you sure you don't want us to store your email, first name and last name?"
					titleId={"modalTitleId"}
					header={<ModalHeader ariaCloseTxt="Close prompt" />}
					footer={
						<ModalFooter>
							<Button
								text="Remove Account"
								type="secondary"
								onClick={() => handleConsent({ emailConsentAccepted: false, isRemoveUser: true })}
							/>
							<Button text="Cancel" type="emphasised" onClick={() => setModalDisagree(false)} />
						</ModalFooter>
					}
				>
					<p>
						If you don't want us to store your email, first name and last name, your account will be removed from our
						database.
					</p>
				</Prompt>
			</Modal>
		</>
	);
};

export default Layout;
