/* eslint-disable indent */
import * as React from "react";
import useAuthentication from "./useAuthentication";
import { UserPermissions } from "../utils/constants";
import { UserMarketPermissions, requestNewRole } from "../services/users";

export interface RequestRolesData {
	role: UserPermissions | null;
	appId?: string;
	markets?: UserMarketPermissions | string[];
	setToast?: (toast: string) => void;
}

const useRequestRoles = ({ appId, role, markets }: RequestRolesData) => {
	const { getToken, handleLogout } = useAuthentication();

	const requestRole = React.useCallback(async () => {
		try {
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			if (appId && role) {
				const { data } = await requestNewRole(authHeader, appId, role, markets);
				return data?.message;
			}
		} catch (error: any) {
			if (error) {
				if (error?.response?.status == 401) {
					handleLogout();
				}
				return error?.response?.data?.message as string;
			}
		}
	}, [appId, getToken, handleLogout, markets, role]);

	return { requestRole };
};

export default useRequestRoles;
