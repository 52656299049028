import * as React from "react";
import useAuthentication from "./useAuthentication";
import { getMarketUpdates } from "../services/dashboard";

const useGetUpdatesCount = (application: any, activeMarket: string | null, activeTertial: string, showGlobal: boolean) => {
	const [loadingTile, setLoadingTile] = React.useState<boolean>(true);
	const [loadingCounter, setLoadingCounter] = React.useState<boolean>(true);
	const [data, setData] = React.useState<number | undefined>(undefined);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const { getToken } = useAuthentication();
	
	React.useEffect(() => {
		if (application) {
			(async () => {
				try {
					setLoadingCounter(true);
					let fromDate;
					let toDate;
					const tertialMonth = activeTertial.split(" ")[0];
					const tertialYear = activeTertial.split(" ")[1];
					if (tertialMonth === "T1") {
						fromDate = `${Number(tertialYear) - 1}-09-01 00:00:00`;
						toDate = `${Number(tertialYear) - 1}-12-31 23:59:59`;
					} else if (tertialMonth === "T2") {
						fromDate = `${tertialYear}-01-01 00:00:00`;
						toDate = `${tertialYear}-04-30 23:59:59`;
					} else if (tertialMonth === "T3") {
						fromDate = `${tertialYear}-05-01 00:00:00`;
						toDate = `${tertialYear}-08-31 23:59:59`;
					}
    
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					let market = showGlobal ? null : activeMarket;
					let { data: { data } } = await getMarketUpdates(
						authHeader,
						application.id,
						market,
						fromDate,
						toDate
					);
					
					setData(data.count);
				} catch (error: any) {
					console.log("error", error);
					setError("Can't fetch update counter, something went wrong");
				} finally {
					setLoadingTile(false);
					setLoadingCounter(false);
				}
			})();
		}
	}, [activeMarket, activeTertial, application, getToken, showGlobal]);

	return { data, loadingTile, loadingCounter, error };
};

export default useGetUpdatesCount;