import * as React from "react";

import TextArea from "@ingka/text-area";
import Loading, { LoadingBall } from "@ingka/loading";
import Button from "@ingka/button";
import arrowCloudOut from "@ingka/ssr-icon/paths/arrow-cloud-out";
import InlineMessage from "@ingka/inline-message";
import Toast from "@ingka/toast";
import FormField from "@ingka/form-field";
import Modal, { ModalFooter, Prompt } from "@ingka/modal";
import Text from "@ingka/text";
import Tooltip from "@ingka/tooltip";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";

import { CurrentValues, Values } from "./types";
import {
	ActionsWrapper,
	IconButtonWrapper,
	LoadingWrapper,
	SaveButtonWrapper,
	StyledDivider,
	StyledModalHeader,
} from "../styles";
import { SchemaBuilder } from "../SchemaBuilder";
import { Schema } from "../SchemaBuilder/utils/types";
import { AddedAndRemovedProperties } from "./Schema";
import { replaceAll } from "../../utils/common";
import ImportSchemaModal from "./ImportSchemaModal";
import { findEmptyOneOf } from "./utils";
import WarningModal from "../WarningModal/WarningModal";
import { TAB_SCHEMA_BUILDER, TAB_SCHEMA_JSON } from "./constants";

interface SchemaBuilderLayout {
	loading: boolean;
	hasChanged: boolean;
	onSave: () => void;
	isNewApplication: boolean;
	saveBtnLoading: boolean;
	toastMessage: string;
	setToastMessage: (val: string) => void;
	currentValues?: CurrentValues;
	showReasonModal: boolean;
	setShowReasonModal: (val: boolean) => void;
	setReason: (val: string) => void;
	currentSchema: Schema;
	setCurrentSchema: (schema: Schema) => void;
	addedAndRemovedProperties?: AddedAndRemovedProperties;
	showImportSchemaModal: boolean;
	setShowImportSchemaModal: (val: boolean) => void;
	marketData: string[];
	setImportedSchema: (val: boolean) => void;
	invalidKeys: string[];
	setInvalidKeys: (val: string[]) => void;
	showAllProperties: boolean;
	setShowAllProperties: (val: boolean) => void;
	values?: Values;
}

const Layout: React.FC<SchemaBuilderLayout> = ({
	loading,
	hasChanged,
	onSave,
	currentValues,
	saveBtnLoading,
	toastMessage,
	setToastMessage,
	showReasonModal,
	setShowReasonModal,
	setReason,
	setCurrentSchema,
	currentSchema,
	addedAndRemovedProperties,
	showImportSchemaModal,
	setShowImportSchemaModal,
	marketData,
	setImportedSchema,
	invalidKeys,
	setInvalidKeys,
	showAllProperties,
	setShowAllProperties,
	values,
}) => {
	const [showWarningSelectNoItemsDlg, setShowWarningSelectNoItemsDlg] = React.useState<boolean>(false);

	const tabs = React.useMemo(
		() => [
			<Tab key={TAB_SCHEMA_BUILDER} tabPanelId={TAB_SCHEMA_BUILDER} text="Editor" />,
			<Tab key={TAB_SCHEMA_JSON} tabPanelId={TAB_SCHEMA_JSON} text="JSON" />,
		],
		[]
	);

	const tabPanels = React.useMemo(
		() => [
			<TabPanel key={TAB_SCHEMA_BUILDER} tabPanelId={TAB_SCHEMA_BUILDER}>
				<SchemaBuilder
					showAllProperties={showAllProperties}
					schema={currentSchema}
					onChange={setCurrentSchema}
					setInvalidKeys={setInvalidKeys}
					invalidKeys={invalidKeys}
					setShowAllProperties={setShowAllProperties}
					hasChanged={hasChanged}
				/>
			</TabPanel>,
			<TabPanel key={TAB_SCHEMA_JSON} tabPanelId={TAB_SCHEMA_JSON}>
				<pre data-testid="schema-json-preview">{currentValues?.schema}</pre>
			</TabPanel>,
		],
		[
			currentSchema,
			currentValues?.schema,
			hasChanged,
			invalidKeys,
			setCurrentSchema,
			setInvalidKeys,
			setShowAllProperties,
			showAllProperties,
		]
	);

	if (loading) {
		return (
			<LoadingWrapper>
				<Loading labelPosition="center" text="Loading Schemas and UI-Schemas" labelTransitions>
					<LoadingBall color="emphasised" />
				</Loading>
			</LoadingWrapper>
		);
	}

	if (!loading && !currentValues) {
		return (
			<LoadingWrapper>
				<InlineMessage
					title="No Schemas or UI-Schemas found"
					body={"Seems like you don't have access to any Schemas or UI-Schemas"}
					variant="negative"
				/>
			</LoadingWrapper>
		);
	}

	return (
		<div>
			<h2>Schema Builder</h2>

			<Tabs defaultActiveTab={TAB_SCHEMA_BUILDER} tabPanels={tabPanels} tabs={tabs} />

			<ActionsWrapper>
				<SaveButtonWrapper>
					<Button
						data-testid="SaveChanges"
						text="Save Changes"
						type="emphasised"
						disabled={!hasChanged || !!invalidKeys.length}
						onClick={() => {
							if (findEmptyOneOf(currentSchema)) {
								setShowWarningSelectNoItemsDlg(true);
							} else {
								setShowReasonModal(true);
							}
						}}
						loading={saveBtnLoading}
					/>
					<Button
						data-testid="cancelChanges"
						text="Cancel"
						type="secondary"
						disabled={!hasChanged || !!invalidKeys.length || saveBtnLoading}
						onClick={() => {
							setCurrentSchema(values?.schema as Schema);
						}}
					/>
				</SaveButtonWrapper>
				<IconButtonWrapper>
					<Tooltip position="top" tooltipText="Import schema & ui-schema">
						<Button
							data-testid="importSchema"
							iconOnly
							text="Import"
							ssrIcon={arrowCloudOut}
							disabled={loading}
							onClick={() => setShowImportSchemaModal(true)}
							type="secondary"
						/>
					</Tooltip>
				</IconButtonWrapper>
			</ActionsWrapper>

			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
			<ImportSchemaModal
				showModal={showImportSchemaModal}
				setShowModal={setShowImportSchemaModal}
				setToastMessage={setToastMessage}
				setImportedSchema={setImportedSchema}
			/>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showReasonModal}
				escapable={false}
				handleCloseBtn={() => setShowReasonModal(false)}
			>
				<Prompt
					title=""
					titleId={"modalTitleId"}
					header={<StyledModalHeader title="Reason for changes" />}
					footer={
						<ModalFooter>
							<Button data-testid="cancel" text="Cancel" type="secondary" onClick={() => setShowReasonModal(false)} />
							<Button data-testid="savechanges" text="Save changes" type="emphasised" onClick={() => onSave()} />
						</ModalFooter>
					}
				>
					<p>Please write the reason for changes (optional)</p>
					<FormField>
						<TextArea id="reason-text-area" label="" onChange={(ev) => setReason(ev.target.value)} rows={6} />
					</FormField>

					{addedAndRemovedProperties && addedAndRemovedProperties.removed.length ? (
						<>
							<InlineMessage
								body={
									<>
										{addedAndRemovedProperties?.added?.length ? (
											<>
												<Text>Following properties are added in schema: </Text>
												<ol id="removed-properties">
													{addedAndRemovedProperties.added.map((item) => (
														<li id={item}>
															<strong>{replaceAll(item, { ["root."]: "", [".properties."]: " > " })}</strong>
														</li>
													))}
												</ol>
											</>
										) : null}
										{addedAndRemovedProperties?.removed?.length ? (
											<>
												{!!addedAndRemovedProperties?.added?.length && <StyledDivider />}
												<Text>Following properties are removed from schema: </Text>
												<ol id="removed-properties">
													{addedAndRemovedProperties.removed.map((item) => (
														<li id={item}>
															<strong>{replaceAll(item, { ["root."]: "", [".properties."]: " > " })}</strong>
														</li>
													))}
												</ol>
											</>
										) : null}
										{addedAndRemovedProperties?.removed?.length ? (
											<>
												<StyledDivider />
												<Text>
													If the properties are <strong>removed from schema</strong>, it will be{" "}
													<strong>removed from all the markets data</strong>.
												</Text>
											</>
										) : null}
										{addedAndRemovedProperties?.removed?.length ? (
											<>
												<StyledDivider />
												<Text>
													If the properties are <strong>removed from schema</strong>, it should be{" "}
													<strong>removed from ui-schema</strong> as well.
												</Text>
											</>
										) : null}
									</>
								}
								variant="cautionary"
								subtle
							/>
						</>
					) : null}
				</Prompt>
			</Modal>
			<WarningModal
				modalId={"remove-elements-dialog"}
				modalTitle={"Warning"}
				showWarningModal={showWarningSelectNoItemsDlg}
				setShowWarningModal={setShowWarningSelectNoItemsDlg}
				children={
					<>
						<p>Select has to have at least one item</p>
						<Button
							id="remove-cancel-button"
							text="Close"
							type="emphasised"
							small
							onClick={() => setShowWarningSelectNoItemsDlg(false)}
						/>
					</>
				}
				variant="cautionary"
				dismissable={false}
			/>
		</div>
	);
};

export default Layout;
