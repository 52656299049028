import * as React from "react";
import assert from "assert";

import useApplicationForm from "./useApplicationForm";
import Layout from "./Layout";
import useAuthentication from "../../../hooks/useAuthentication";
import { UserApplicationData } from "../../../services/users";
import { addNewApplication, deleteApplicationData, updateApplicationData } from "./utils";
import { ACTIVE_TABS, Errors, Value } from "./types";
import { ApiResponseMessages } from "../../Schemas/constants";
import useStore, {
	activeApplicationSelector,
	ApplicationData,
	applicationsSelector,
	setActiveApplicationSelector,
	setApplicationsSelector,
	isApplicationAdminSelector,
	setApplicationRequestsSelector,
} from "../../../store";
import { getApplicationRequests } from "../../../services/applications";
import { getApplications, requestNewApplicationByUser } from "../../../services/applications";
import { useIsFlaggaAdmin } from "../../../hooks/useIsFlaggaAdmin";
import { UserData, getUsersList } from "../../../services/users";
import useGetUserSuggestions from "../../../hooks/useGetUserSuggestions";
import { set } from "lodash";

interface ModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	valueFromRequest?: Value;
	createNewApp?: boolean;
}

const AddApplicationModal: React.FC<ModalProps> = ({ showModal, setShowModal, valueFromRequest, createNewApp }) => {
	const [errors, setErrors] = React.useState<Errors[] | undefined>(undefined);
	const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
	const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [showOnlyCreate, setShowOnlyCreate] = React.useState<boolean>(false);
	const [updateAppSelected, setUpdateAppSelected] = React.useState<ApplicationData | undefined>(undefined);

	// Add for delete application
	const [selectedAppToDelete, setSelectedAppToDelete] = React.useState<ApplicationData | undefined>(undefined);
	const [isDeleteApplicaionBackup, setIsDeleteApplicationBackup] = React.useState<boolean>(false);
	const [showDeleteApplicationModal, setShowDeleteApplicationModal] = React.useState<boolean>(false);
	const [deleteInProgress, setDeleteInProgress] = React.useState(false);

	const [activeTab, setActiveTab] = React.useState<string>(ACTIVE_TABS.UPDATE_APPLICATION);

	const applications = useStore(applicationsSelector);
	const activeApplication = useStore(activeApplicationSelector);
	const setActiveApplication = useStore(setActiveApplicationSelector);
	const setApplications = useStore(setApplicationsSelector);
	const isApplicationAdmin = useStore(isApplicationAdminSelector);
	const setApplicationRequests = useStore(setApplicationRequestsSelector);
	const { value, valid, validate, validation, handleChange, shouldValidate, setValue, setShouldValidate } =
		useApplicationForm();
	const { getToken, handleLogout } = useAuthentication();
	const { currentUser, isFlaggaAdmin } = useIsFlaggaAdmin();
	const [deleteApplicationId, setDeleteApplicationId] = React.useState<string>("");
	const [isDeleteApplicationIdValid, setIsDeleteApplicationIdValid] = React.useState<boolean>(true);

	const {
		getSuggestions,
		data: userSuggestions,
		setData: setUserSuggestions,
		error,
		loading,
		noOptionsText,
	} = useGetUserSuggestions();

	const userAdminApps = React.useMemo(() => {
		if (applications && !currentUser?.user?.["flagga-admin"]) {
			if (currentUser?.user?.applications && currentUser?.user?.applications !== null) {
				return applications.filter((item) =>
					Object.keys(currentUser?.user?.applications as object)
						.filter((app) => currentUser?.user?.applications?.[app]?.["permissions"] == "admin")
						.includes(item.id)
				);
			} else {
				return [] as ApplicationData[];
			}
		} else {
			return applications;
		}
	}, [applications, currentUser]);

	const resetValue = React.useCallback(() => {
		if (isFlaggaAdmin) {
			setValue({
				appId: "",
				appName: "",
				email: "",
				showDashboard: false,
				showMarketDashboard: false,
				allowReadOnly: false,
				showAdoptionRate: false,
			});
		} else {
			setValue({
				appId: "",
				appName: "",
				email: !currentUser ? "" : currentUser.user.email,
				showDashboard: false,
				showMarketDashboard: false,
				showAdoptionRate: false,
				allowReadOnly: false,
				plan: "",
				hasReadDocumentation: false,
			});
		}
	}, [currentUser, isFlaggaAdmin, setValue]);

	React.useEffect(() => {
		if (valueFromRequest) {
			setValue(valueFromRequest);
			setShowOnlyCreate(true);
		}
	}, [setValue, valueFromRequest]);

	React.useEffect(() => {
		if (isFlaggaAdmin) {
			setActiveTab(ACTIVE_TABS.ADD_APPICATION);
		}
	}, [isFlaggaAdmin]);

	React.useEffect(() => {
		if (activeTab === ACTIVE_TABS.UPDATE_APPLICATION && activeApplication && showModal && !createNewApp) {
			setUpdateAppSelected(JSON.parse(JSON.stringify(activeApplication)));
		} else if (createNewApp && activeTab === ACTIVE_TABS.UPDATE_APPLICATION && activeApplication && showModal) {
			setActiveTab(ACTIVE_TABS.ADD_APPICATION);
			setShowOnlyCreate(true);
		} else if (activeTab === ACTIVE_TABS.DELETE_APPLICATION && activeApplication && showModal) {
			setSelectedAppToDelete(activeApplication);
		}
	}, [activeApplication, activeTab, showModal, createNewApp]);

	const handlAddApplication = React.useCallback(
		async (e) => {
			e.preventDefault();
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				if (!isFlaggaAdmin) {
					if (value.hasReadDocumentation) {
						setBtnLoading(true);
						const response = await requestNewApplicationByUser(authHeader, value);
						if (response.data.message === ApiResponseMessages.Success) {
							resetValue();
							setShouldValidate({
								appId: false,
								appName: false,
								email: false,
								hasReadDocumentation: false,
							});
							setShowModal(false);
							setToastMessage("Request to add a new application was sent");
						}
					} else if (!value.hasReadDocumentation) {
						validate(value.hasReadDocumentation as boolean, "hasReadDocumentation");
					}
				} else {
					setBtnLoading(true);
					const response = await addNewApplication({
						authHeader,
						formValue: value,
						setShowModal,
						setErrors,
					});
					if (response?.message === ApiResponseMessages.Success || response?.message === "success") {
						const { data: applicationsData } = await getApplications({ header: authHeader });
						const hasApplicationsData = applicationsData.data.applications.length;
						setApplications(hasApplicationsData ? applicationsData.data.applications : null);
						resetValue();
						setShouldValidate({ appId: false, appName: false, email: false });
						setShowModal(false);
						setToastMessage("Successfully Added new Application");
						try {
							const { data: applicationRequests } = await getApplicationRequests(authHeader);
							setApplicationRequests(
								applicationRequests.data?.applicationRequests.length ? applicationRequests.data.applicationRequests : []
							);
						} catch (e: any) {
							window.location.reload();
						}
					}
				}
			} catch (err: any) {
				console.log(err);
				let errMsg = err.message;
				if (err.response && err.response.data && err.response.data.message) {
					errMsg += ` ${err.response.data.message}`;
				}
				setToastMessage(errMsg);
			} finally {
				setBtnLoading(false);
			}
		},
		[
			getToken,
			isFlaggaAdmin,
			resetValue,
			setApplicationRequests,
			setApplications,
			setShouldValidate,
			setShowModal,
			validate,
			value,
		]
	);

	const isButtonDisabled = React.useMemo(() => {
		const isValidForm = !isFlaggaAdmin
			? value.appId !== "" && value.appName !== "" && value.hasReadDocumentation
			: value.appId !== "" && value.appName !== "" && value.email !== "";
		let hasValidClientIds = true;
		if (value?.clientIds?.length) {
			value.clientIds.forEach((clientId) => {
				if (clientId === "") {
					hasValidClientIds = false;
				}
			});
		}

		return !valid.appId || !valid.appName || !valid.email || !isValidForm || !hasValidClientIds || !!errors?.length;
	}, [isFlaggaAdmin, value.appId, value.appName, value.hasReadDocumentation, value.email, JSON.stringify(value.clientIds), valid.appId, valid.appName, valid.email, errors?.length]);

	const isUpdateDisabled = React.useMemo(() => {
		if (applications && updateAppSelected) {
			try {
				if (updateAppSelected?.clientIds?.find((clientId) => clientId === "") != undefined) {
					return true;
				} else {
					assert.deepStrictEqual(
						applications?.find((data) => data.id === updateAppSelected?.id),
						updateAppSelected
					);
					return true;
				}
			} catch (e) {
				return false;
			}
		}
		return true;
	}, [applications, updateAppSelected]);

	const isDeleteButtonDisabled = React.useMemo(() => {
		if (applications && selectedAppToDelete) {
			try {
				assert.deepStrictEqual(
					applications?.find((data) => data.id === selectedAppToDelete?.id),
					selectedAppToDelete
				);
				return false;
			} catch (e) {
				return true;
			}
		}
		return true;
	}, [applications, selectedAppToDelete]);

	const onUpdateApplication = React.useCallback(async () => {
		if (updateAppSelected && !isUpdateDisabled) {
			try {
				setUpdateLoading(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const response = await updateApplicationData({ authHeader, updateAppSelected, setErrors });
				if (response?.message === ApiResponseMessages.Success || response?.message === "success") {
					const { data: applicationsData } = await getApplications({ header: authHeader });
					const hasApplicationsData = applicationsData.data.applications.length;
					setApplications(hasApplicationsData ? applicationsData.data.applications : null);
					if (activeApplication?.id === updateAppSelected.id) {
						setActiveApplication(
							applicationsData.data.applications.find((data) => data.id === activeApplication?.id) || null
						);
					}
					setShowModal(false);
					setToastMessage("Successfully Updated Application");
				}
			} catch (err: any) {
				setToastMessage(err.message);
			} finally {
				setUpdateLoading(false);
			}
		}
	}, [
		activeApplication,
		getToken,
		isUpdateDisabled,
		setActiveApplication,
		setApplications,
		setShowModal,
		updateAppSelected,
	]);

	const onDeleteApplication = React.useCallback(async () => {
		if (selectedAppToDelete && selectedAppToDelete?.id === deleteApplicationId) {
			setShowDeleteApplicationModal(false);
			try {
				setDeleteInProgress(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const response = await deleteApplicationData(authHeader, selectedAppToDelete, isDeleteApplicaionBackup);
				if (response?.message === ApiResponseMessages.Success || response?.message === "success") {
					setSelectedAppToDelete(undefined);
					setIsDeleteApplicationBackup(!isDeleteApplicaionBackup);
					setShowDeleteApplicationModal(false);
					setToastMessage("Successfully Deleted Application");
					window.location.reload();
				}
			} catch (err: any) {
				console.log("errr", err);
				setToastMessage(err.message);
			} finally {
				setDeleteInProgress(false);
			}
		} else {
			setIsDeleteApplicationIdValid(false);
		}
	}, [selectedAppToDelete, deleteApplicationId, getToken, isDeleteApplicaionBackup]);

	return (
		<Layout
			value={value}
			valid={valid}
			validation={validation}
			handleChange={handleChange}
			shouldValidate={shouldValidate}
			handlAddApplication={handlAddApplication}
			showModal={showModal}
			setShowModal={setShowModal}
			isButtonDisabled={isButtonDisabled}
			errors={errors}
			btnLoading={btnLoading}
			applications={userAdminApps}
			activeTab={activeTab}
			setActiveTab={setActiveTab}
			updateAppSelected={updateAppSelected}
			setUpdateAppSelected={setUpdateAppSelected}
			onUpdateApplication={onUpdateApplication}
			isUpdateDisabled={isUpdateDisabled}
			updateLoading={updateLoading}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			isFlaggaAdmin={isFlaggaAdmin}
			isApplicationAdmin={isApplicationAdmin}
			showOnlyCreate={showOnlyCreate}
			getSuggestions={getSuggestions}
			userSuggestions={userSuggestions}
			noOptionsText={noOptionsText}
			resetValue={resetValue}
			// Add for delete application
			setSelectedAppToDelete={setSelectedAppToDelete}
			selectedAppToDelete={selectedAppToDelete}
			onDeleteApplication={onDeleteApplication}
			isDeleteApplicaionBackup={isDeleteApplicaionBackup}
			setIsDeleteApplicationBackup={setIsDeleteApplicationBackup}
			showDeleteApplicationModal={showDeleteApplicationModal}
			setShowDeleteApplicationModal={setShowDeleteApplicationModal}
			isDeleteButtonDisabled={isDeleteButtonDisabled}
			deleteInProgress={deleteInProgress}
			deleteApplicationId={deleteApplicationId}
			setDeleteApplicationId={setDeleteApplicationId}
			isDeleteApplicationIdValid={isDeleteApplicationIdValid}
		/>
	);
};

export default AddApplicationModal;
