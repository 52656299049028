import React from "react";
import Switch from "@ingka/switch";

import { handleChange } from "./Checkbox.helpers";
import FormField from "@ingka/form-field";
import InkgaCheckbox from "@ingka/checkbox";

interface Props {
	value: boolean;
	onChange: (v: any) => void;
	label?: string;
	disabled?: boolean;
	placeholder?: string;
}

const Checkbox: React.FunctionComponent<Props> = ({ label, value, onChange, disabled, placeholder }) => {
	return (
		<FormField
			fieldHelper={{
				msg: placeholder ? placeholder : ""
			}}
		>
			<InkgaCheckbox
				id={label || "checkbox"}
				label={value?.toString() || ""}
				onChange={handleChange(onChange)}
				value={value?.toString() || ""}
				checked={value ? value : false}
				disabled={disabled}
			/>
		
		</FormField>
		
	);
};

export default Checkbox;
