import { and, Categorization, RankedTester, rankWith, Tester, UISchemaElement, uiTypeIs } from "@jsonforms/core";

export const isSingleLevelCategorization: Tester = and(
	uiTypeIs("Categorization"),
	(uischema: UISchemaElement): boolean => {
		const categorization = uischema as Categorization;
		return (
			!!categorization.elements && !!categorization.elements.reduce((acc, e) => acc && e.type === "Category", true)
		);
	}
);

export const categoryLayoutTester: RankedTester = rankWith(3, isSingleLevelCategorization);
