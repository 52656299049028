import { JsonFormsRendererRegistryEntry } from "@jsonforms/core";
import { materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { createTheme, Grid, ThemeProvider } from "@material-ui/core";
import React from "react";

import { useUiSchema } from "../../core/context";
import { useExportSchema } from "../../core/util/hooks";
import { EmptyEditor } from "./EmptyEditor";
import styled from "styled-components";

const theme = createTheme({
	overrides: {
		MuiFormControl: {
			root: {
				overflow: "hidden",
			},
		},
	},
});

const StyledGrid = styled(Grid)`
	padding-left: 2rem;
	padding-right: 2rem;
	min-width: 40rem;
`;

export interface EditorProps {
	editorRenderers: JsonFormsRendererRegistryEntry[];
}

export const Editor: React.FC<EditorProps> = ({ editorRenderers }) => {
	const schema = useExportSchema();
	const uiSchema = useUiSchema();
	return uiSchema ? (
		<StyledGrid container>
			<ThemeProvider theme={theme}>
				<JsonForms data={{}} schema={schema} uischema={uiSchema} renderers={editorRenderers} cells={materialCells} />
			</ThemeProvider>
		</StyledGrid>
	) : (
		<EmptyEditor />
	);
};
