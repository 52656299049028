import * as React from "react";
import { To, useNavigate } from "react-router-dom";

import Layout from "./Layout";
import useAuthentication from "../../hooks/useAuthentication";
import { updateEmailConsent } from "../../services/users";
import { ApiResponseMessages } from "../Schemas/constants";
import useStore, { routerStateFromSelector, setUserSelector, userSelector } from "../../store";
import { ROUTE_HOME, ROUTE_LOGOUT } from "../../utils/constants";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";

const EmailConsent: React.FC = () => {
	const [modalDisagree, setModalDisagree] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	const navigate = useNavigate();

	const routerStateFrom = useStore(routerStateFromSelector);
	const setUser = useStore(setUserSelector);
	const currentUser = useStore(userSelector);

	const { getToken, handleLogout } = useAuthentication();
	const { getUser, data: userData } = useGetLoggedInUser();

	React.useEffect(() => {
		if (currentUser?.user?.emailConsentAccepted) {
			navigate((`${routerStateFrom.pathname}${routerStateFrom.search}` || ROUTE_HOME) as To, { replace: true });
		}
	}, [currentUser, navigate, routerStateFrom.pathname, routerStateFrom.search]);

	React.useEffect(() => {
		return () => {
			setLoading(false);
			setModalDisagree(false);
		};
	}, []);

	const handleConsent = React.useCallback(
		async ({ emailConsentAccepted, isRemoveUser }) => {
			try {
				setLoading(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const { data } = await updateEmailConsent(authHeader, emailConsentAccepted);
				if (data && data.message.toLowerCase() === ApiResponseMessages.Success.toLowerCase()) {
					modalDisagree && setModalDisagree(false);
					if (isRemoveUser) {
						setLoading(false);
						setUser(null);
						navigate(ROUTE_LOGOUT);
					} else {
						await getUser();
					}
				}
			} catch (err: any) {
				if (err) {
					setLoading(false);
					if (err?.response?.status === 401) {
						handleLogout();
					}
				}
			} finally {
				setLoading(false);
			}
		},
		[getToken, getUser, handleLogout, modalDisagree, navigate, setUser]
	);

	React.useEffect(() => {
		if (userData) {
			setUser(userData);
		}
	}, [userData, setUser]);

	return (
		<Layout
			modalDisagree={modalDisagree}
			setModalDisagree={setModalDisagree}
			handleConsent={handleConsent}
			loading={loading}
		/>
	);
};

export default EmailConsent;
