import * as React from "react";
import styled from "styled-components";

import { RendererProps } from "@jsonforms/core";
import { withJsonFormsLayoutProps } from "@jsonforms/react";
import { Categorization } from "@jsonforms/core/lib/models/uischema";
import { MaterialLayoutRenderer, MaterialLayoutRendererProps } from "@jsonforms/material-renderers";
import Pill from "@ingka/pill";
import { colourNeutral3 } from "@ingka/variables/colours-css";
import { OverflowCarousel } from "@ingka/carousel";

const StyledPill = styled(Pill)`
	margin-right: 1rem;
`;

const StyledDivider = styled.hr`
	margin: 0.5rem 0rem;
	height: 1px;
	background-color: ${colourNeutral3};
	border: none;
`;

const CategoryLayout: React.FC<RendererProps> = ({ path, renderers, cells, schema, uischema, visible, enabled }) => {
	const categorization = uischema as Categorization;

	const [activeCategory, setActiveCategory] = React.useState<string | undefined>("0");

	const tabsData = React.useMemo(
		() => categorization.elements?.filter((category) => category.type === "Category"),
		[categorization]
	);

	const childProps: MaterialLayoutRendererProps = React.useMemo(
		() => ({
			elements: tabsData[parseInt(activeCategory || "")]?.elements,
			schema,
			path,
			direction: "column",
			enabled,
			visible,
			renderers,
			cells,
		}),
		[activeCategory, cells, enabled, path, renderers, schema, tabsData, visible]
	);
	return (
		<>
			<OverflowCarousel>
				{tabsData.map((tab, index) => (
					<StyledPill
						id={tab.label}
						label={tab.label}
						size="small"
						selected={activeCategory === String(index) || false}
						onClick={() => {
							setActiveCategory(String(index));
						}}
					/>
				))}
			</OverflowCarousel>
			{childProps.elements && childProps.elements.find((element) => element.type === "Categorization") && (
				<StyledDivider />
			)}
			{activeCategory && <MaterialLayoutRenderer key={`${path}-${activeCategory}`} {...childProps} />}
		</>
	);
};

export default withJsonFormsLayoutProps(CategoryLayout);
