import React from "react";

import Tabs, { Tab, TabPanel } from "@ingka/tabs";

import CreateNewApplication from "../CreateNewApplication";
import JoinExistingApplication from "../JoinExistingApplication";
import { useSearchParams } from "react-router-dom";
import {
	ONBOARDING_ACTION_REQUEST_CREATE_NEW_APP,
	ONBOARDING_ACTION_REQUEST_JOIN_EXISTINF_APP,
} from "../../utils/constants";

type OnbordingTabs = "create-new-app" | "join-existing-app";

const TAB_CREATE_NEW_APPLICATION: OnbordingTabs = "create-new-app";
const TAB_JOIN_EXISTING_APPLICATION: OnbordingTabs = "join-existing-app";

const Onboarding = () => {
	const [activeTab, setActiveTab] = React.useState<OnbordingTabs>(TAB_CREATE_NEW_APPLICATION);

	const [searchParams, setSearchParam] = useSearchParams();

	const searchParam = searchParams.get("action");

	React.useEffect(() => {
		if (searchParam === ONBOARDING_ACTION_REQUEST_CREATE_NEW_APP) {
			setActiveTab(TAB_CREATE_NEW_APPLICATION);
		} else if (searchParam === ONBOARDING_ACTION_REQUEST_JOIN_EXISTINF_APP) {
			setActiveTab(TAB_JOIN_EXISTING_APPLICATION);
		} else {
			setActiveTab(TAB_CREATE_NEW_APPLICATION);
		}
	}, [activeTab, searchParam, searchParams, setSearchParam]);

	const tabPanels = React.useMemo(
		() => [
			<TabPanel key={TAB_CREATE_NEW_APPLICATION} tabPanelId={TAB_CREATE_NEW_APPLICATION}>
				<CreateNewApplication />
			</TabPanel>,
			<TabPanel key={TAB_JOIN_EXISTING_APPLICATION} tabPanelId={TAB_JOIN_EXISTING_APPLICATION}>
				<JoinExistingApplication />
			</TabPanel>,
		],
		[]
	);

	const tabs = React.useMemo(
		() => [
			<Tab
				key={TAB_CREATE_NEW_APPLICATION}
				tabPanelId={TAB_CREATE_NEW_APPLICATION}
				onClick={() =>
					setSearchParam({
						...Object.fromEntries(searchParams.entries()),
						action: ONBOARDING_ACTION_REQUEST_CREATE_NEW_APP,
					})
				}
				text="Create new application"
			/>,
			<Tab
				key={TAB_JOIN_EXISTING_APPLICATION}
				tabPanelId={TAB_JOIN_EXISTING_APPLICATION}
				onClick={() =>
					setSearchParam({
						...Object.fromEntries(searchParams.entries()),
						action: ONBOARDING_ACTION_REQUEST_JOIN_EXISTINF_APP,
					})
				}
				text="Join existing application"
			/>,
		],
		[searchParams, setSearchParam]
	);
	return <Tabs activeTab={activeTab} tabPanels={tabPanels} tabs={tabs} />;
};

export default Onboarding;
