import * as React from "react";
import dayjs, { Dayjs } from "dayjs";

import Layout from "./Layout";
import useStore, { userSelector, setApplicationsSelector, setForceRerenderSelector } from "../../store";
import useGetApplicationBackupDates from "../../hooks/useGetApplicationBackupDates";
import useGetApplicationBackupData from "../../hooks/useGetApplicationBackupData";
import useAuthentication from "../../hooks/useAuthentication";
import {
	restoreApplicationData,
	backupApplicationData,
	getBackupNames,
	restoreApplicationMarketData,
} from "../../services/backupAndRestore";
import { getApplications } from "../../services/applications";
import { BACKUPRESTORETABS } from "./types";
import { getMarkets } from "../../services/markets";

import { useGetCountryName } from "../../hooks/useGetCountryName";
import { set } from "lodash";

interface BackupAndRestoreModalPros {
	showModal: boolean;
	setShowModal: (val: boolean) => void;
}

const BackupAndRestoreModal: React.FC<BackupAndRestoreModalPros> = ({ showModal, setShowModal }) => {
	const [selectedApp, setSelectedApp] = React.useState<string | undefined>(undefined);
	const [selectedDate, setSelectedDate] = React.useState<Dayjs | undefined>(undefined);
	const [highlightedDays, setHighlightedDays] = React.useState<number[]>([]);
	const [selectedBackupData, setSelectedBackupData] = React.useState<string | undefined>(undefined);
	const [restoreInProgress, setRestoreInProgress] = React.useState<boolean>(false);
	const [backupInProgress, setbackupInProgress] = React.useState<boolean>(false);
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [isMarketBackup, setIsMarketBackup] = React.useState<boolean>(false);
	const [marketList, setMarketList] = React.useState<{ id: string; name: string }[]>([]);
	const [backupName, setBackupName] = React.useState<string | undefined>(undefined);
	const [selectedMarket, setSelectedMarket] = React.useState<string | undefined>(undefined);
	const [activeTab, setActiveTab] = React.useState<string>(BACKUPRESTORETABS.RESTORE);
	const [backupNameList, setBackupNameList] = React.useState<string[]>([]);
	const [backupNameListLoading, setBackupNameListLoading] = React.useState<boolean>(false);
	const { getToken } = useAuthentication();
	const setApplications = useStore(setApplicationsSelector);
	const currentUser = useStore(userSelector);
	const setForceRerender = useStore(setForceRerenderSelector);

	const { data: backupDates, loading: backupDatesLoading, getBackupDates } = useGetApplicationBackupDates();

	const { getCountryName } = useGetCountryName();

	const {
		data: backupData,
		setData: setBackupData,
		loading: backupDataLoading,
	} = useGetApplicationBackupData({
		application: selectedApp,
		restoreDate: selectedDate ? dayjs(selectedDate).format("MM-DD-YYYY") : undefined,
	});

	React.useEffect(() => {
		if (backupData && backupData.length === 1) {
			setSelectedBackupData(backupData[0]);
		}
	}, [backupData]);

	React.useEffect(() => {
		if (backupDates) {
			const currentMonthDates: string[] = [];
			backupDates.forEach((data) => {
				if (dayjs(data.date).get("month") === dayjs().get("month") && data.haveMultipleBackups) {
					currentMonthDates.push(data.date);
				}
			});
			setHighlightedDays(currentMonthDates.map((date) => dayjs(date).get("date")));
		}
	}, [backupDates]);

	React.useEffect(() => {
		if (activeTab === BACKUPRESTORETABS.RESTORE && selectedApp) {
			getBackupDates(selectedApp);
		}
	}, [activeTab, getBackupDates, selectedApp]);

	const minDate = React.useMemo(() => (backupDates && backupDates[0].date) || undefined, [backupDates]);

	const maxDate = React.useMemo(
		() => (backupDates && backupDates[backupDates.length - 1].date) || undefined,
		[backupDates]
	);

	const handleAppChange = React.useCallback(
		(e: any) => {
			setSelectedDate(undefined);
			setSelectedBackupData(undefined);
			setBackupData(undefined);
			setBackupNameList([]);
			setBackupName(undefined);
			setSelectedMarket(undefined);
			setIsMarketBackup(false);
			setMarketList([]);
			setSelectedApp(e.target.value);
		},
		[setBackupData]
	);

	const handleDateChange = React.useCallback((date: Dayjs | null) => {
		if (date) setSelectedDate(date);
	}, []);

	const handleMonthChange = React.useCallback(
		(date: Dayjs) => {
			if (backupDates) {
				const currentMonthDates: string[] = [];
				backupDates.forEach((data) => {
					if (dayjs(data.date).get("month") === date.month() && data.haveMultipleBackups) {
						currentMonthDates.push(data.date);
					}
				});
				setHighlightedDays(currentMonthDates.map((date) => dayjs(date).get("date")));
			}
		},
		[backupDates]
	);

	const handleBackupDataChange = (e: any) => {
		setSelectedBackupData(e.target.value === "Choose an option" ? undefined : e.target.value);
	};

	const handleRestoreData = React.useCallback(async () => {
		if (selectedApp && (selectedBackupData || (backupName && selectedMarket))) {
			try {
				setRestoreInProgress(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const restoreResponse = isMarketBackup
					? await restoreApplicationMarketData(authHeader, selectedApp, selectedMarket, backupName)
					: await restoreApplicationData(authHeader, selectedApp, selectedBackupData || "");
				if (restoreResponse.data.message === "success") {
					setToastMessage("Successfully Restore the Application");
					const { data: applicationsData } = await getApplications({ header: authHeader });
					const hasApplicationsData = applicationsData.data.applications.length;
					if (hasApplicationsData && applicationsData.message === "success") {
						setRestoreInProgress(false);
						setShowModal(false);
						setApplications(applicationsData.data.applications);
					}
					setForceRerender(true);
				}
			} catch (e: any) {
				setRestoreInProgress(false);
				setToastMessage(e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message);
				console.log(e);
			}
		}
	}, [
		backupName,
		getToken,
		isMarketBackup,
		selectedApp,
		selectedBackupData,
		selectedMarket,
		setApplications,
		setShowModal,
	]);

	const getMarketBackupList = React.useCallback(
		async (market) => {
			if (selectedApp && market) {
				setBackupNameListLoading(true);
				try {
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					const backupNames = await getBackupNames(authHeader, selectedApp, market);
					setBackupNameList(backupNames.data.data);
				} catch (e: any) {
					setToastMessage(e.message);
					console.log(e);
				} finally {
					setBackupNameListLoading(false);
				}
			}
		},
		[getToken, selectedApp]
	);

	const handleBackUpData = React.useCallback(async () => {
		try {
			if (selectedApp) {
				setbackupInProgress(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const backupResponse = isMarketBackup
					? await backupApplicationData(authHeader, selectedApp, selectedMarket, backupName)
					: await backupApplicationData(authHeader, selectedApp);
				if (backupResponse.data.message === "success") {
					setToastMessage(
						!selectedMarket ? "Successfully created backup" : `Successfully created Backup for ${selectedMarket}`
					);
					setbackupInProgress(false);
					setShowModal(false);
				}
			}
		} catch (e: any) {
			setbackupInProgress(false);
			setToastMessage(e.message);
			console.log(e);
		}
	}, [backupName, getToken, isMarketBackup, selectedApp, selectedMarket, setShowModal]);

	const adminApps = React.useMemo(
		() =>
			Object.entries(currentUser?.user?.applications || {})
				.filter(([key, value]) => value.permissions === "admin")
				.map(([key, value]) => ({ id: key, applicationName: value.applicationName })),

		[currentUser?.user?.applications]
	);

	React.useEffect(() => {
		if (adminApps?.length === 1) {
			setSelectedApp(adminApps[0].id);
		}
	}, [adminApps]);

	React.useEffect(() => {
		const fetchData = async () => {
			if (selectedApp) {
				try {
					const token = await getToken();
					const authHeader = {
						headers: {
							authorization: `Bearer ${token}`,
						},
					};
					let marketList: { id: string; name: string }[] = [];
					const response = await getMarkets(authHeader, selectedApp);
					marketList = response.data.data.markets.permittedMarkets.map((item) => {
						return { id: item, name: getCountryName(item) };
					});
					setMarketList(marketList);
				} catch (e) {
					console.log(e);
				}
			}
		};
		fetchData();
	}, [getCountryName, getToken, selectedApp]);

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			applications={adminApps}
			selectedApp={selectedApp}
			handleDateChange={handleDateChange}
			maxDate={maxDate}
			minDate={minDate}
			selectedDate={selectedDate}
			handleAppChange={handleAppChange}
			highlightedDays={highlightedDays}
			handleMonthChange={handleMonthChange}
			backupData={backupData}
			backupDataLoading={backupDataLoading}
			handleBackupDataChange={handleBackupDataChange}
			backupDatesLoading={backupDatesLoading}
			selectedBackupData={selectedBackupData}
			handleRestoreData={handleRestoreData}
			restoreInProgress={restoreInProgress}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			setActiveTab={setActiveTab}
			activeTab={activeTab}
			backupInProgress={backupInProgress}
			handleBackUpData={handleBackUpData}
			isMarketBackup={isMarketBackup}
			setIsMarketBackup={setIsMarketBackup}
			marketList={marketList}
			selectedMarket={selectedMarket}
			setSelectedMarket={setSelectedMarket}
			backupName={backupName}
			setBackupName={setBackupName}
			getMarketBackupList={getMarketBackupList}
			backupNameList={backupNameList}
			backupNameListLoading={backupNameListLoading}
		/>
	);
};

export default BackupAndRestoreModal;
