import React from "react";
import { ListViewItem } from "@ingka/list-view";
import styled from "styled-components";
import { StyledListView } from "../../styles";
import Button from "@ingka/button";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import documentUpload from "@ingka/ssr-icon/paths/document-upload";
import InlineMessage from "@ingka/inline-message";
import { RequestedCreateNewApplications } from "../../../store";

interface ApplicationCreatedProps {
	cancelCreateNewApplicationRequest: (appId: string) => void;
	cancelNewlyCreatedAppLoading: boolean;
	createdAppRemoving: string;
	userLoading: boolean;
	newAppsRequested?: RequestedCreateNewApplications[];
}

const ListWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-around;
	padding: 0 1rem 0 1rem;
`;

const StyledButton = styled(Button)`
	margin-left: 1rem;
`;

const MessageWrapper = styled.div`
	padding: 0 1rem 0 1rem;
`;

const ApplicationCreated: React.FC<ApplicationCreatedProps> = ({
	newAppsRequested,
	cancelCreateNewApplicationRequest,
	cancelNewlyCreatedAppLoading,
	createdAppRemoving,
	userLoading,
}) => {
	return newAppsRequested?.length ? (
		<StyledListView id={"application-created"} size="small">
			{newAppsRequested?.map((app) => (
				<ListWrapper>
					<ListViewItem
						size="small"
						emphasised
						leadingIcon={documentUpload}
						id={app.appId}
						title={app.appName}
						showDivider={false}
						disabled={(cancelNewlyCreatedAppLoading || userLoading) && createdAppRemoving === app.appId}
					/>
					<StyledButton
						iconOnly
						small
						ssrIcon={trashCan}
						type="tertiary"
						onClick={() => cancelCreateNewApplicationRequest(app.appId)}
						loading={(cancelNewlyCreatedAppLoading || userLoading) && createdAppRemoving === app.appId}
					/>
				</ListWrapper>
			))}
		</StyledListView>
	) : (
		<MessageWrapper>
			<InlineMessage title={"No new application!"} body="Seems like you didn't created any new application yet" />
		</MessageWrapper>
	);
};

export default ApplicationCreated;
