import { getUiSchemaList, updateSchemas } from "../../services/applications";
import { Application, Market } from "../../store";
import { ApiResponseMessages } from "./constants";

interface UpdateSchemaData {
	getToken: () => Promise<string>;
	activeApplication: Application;
	setToastMessage: (val: string) => void;
	schemaData: any;
	reason?: string;
	setSaveBtnLoading: (val: boolean) => void;
}

export const updateSchemaData = async ({
	getToken,
	activeApplication,
	setToastMessage,
	schemaData,
	reason,
	setSaveBtnLoading,
}: UpdateSchemaData) => {
	if (activeApplication) {
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		try {
			const { data } = await updateSchemas(authHeader, schemaData, activeApplication?.id, reason);
			if (data.message === ApiResponseMessages.Success || data.message === "success") {
				const { data: UiSchemaData } = await getUiSchemaList(authHeader, activeApplication?.id, {
					uiSchema: true,
					getSchema: true,
				});
				return { schema: UiSchemaData.data.schema, uiSchemas: UiSchemaData.data["ui-schemas"] };
			}
		} catch (error: any) {
			setSaveBtnLoading(false);
			setToastMessage(error?.response?.data?.message);
			console.error(error);
		}
	}
};
