/* eslint-disable indent */
import * as React from "react";

import useAuthentication from "./useAuthentication";
import { requestNewApplicationByUser } from "../services/applications";
import { Value } from "../utils/types";

const useRequestCreateNewApplication = () => {
	const { getToken, handleLogout } = useAuthentication();
	const [loading, setLoading] = React.useState<boolean>(false);

	const requestCreateApplication = React.useCallback(
		async (value: Value) => {
			try {
				setLoading(true);
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				const { data } = await requestNewApplicationByUser(authHeader, value);
				setLoading(false);
				return data.message;
			} catch (error: any) {
				if (error?.response?.status == 401) {
					handleLogout();
				}
				setLoading(false);
				return error.response;
			}
		},
		[getToken, handleLogout]
	);

	return { requestCreateApplication, loading };
};

export default useRequestCreateNewApplication;
