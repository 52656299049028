import React from "react";
import Layout from "./Layout";
import { ValidationProps } from "../../types";

interface DashboardProps extends ValidationProps {}

const Dashboard: React.FC<DashboardProps> = ({ value, valid, validation, handleChange, shouldValidate }) => {
	return (
		<Layout
			value={value}
			valid={valid}
			validation={validation}
			handleChange={handleChange}
			shouldValidate={shouldValidate}
		/>
	);
};

export default Dashboard;
