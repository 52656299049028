/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";

import { Application } from "../../../store";
import {
	ApplicationUserData,
	getAppUsersMarketList,
} from "../../../services/users";
import Layout from "./Layout";
import useAuthentication from "../../../hooks/useAuthentication";
import { useGetCountryName } from "../../../hooks/useGetCountryName";

interface MarketUsersModalProps {
    activeMarket: string | null;
    activeApplication: Application | undefined;
    showMarketUsersModal: boolean;
    setShowMarketUsersModal: (value: boolean) => void;
}

const MarketUsers: React.FC<MarketUsersModalProps> = ({
	activeMarket,
	activeApplication,
	showMarketUsersModal,
	setShowMarketUsersModal
}) => {
	const [marketUsersList, setMarketUsersList] = React.useState<ApplicationUserData | undefined>(undefined);
	const { getToken } = useAuthentication();
	const { getCountryName } = useGetCountryName();
    
	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			try {
				if (activeApplication && activeMarket) {
					let { data: usersData } = await getAppUsersMarketList(token, activeApplication.id, activeMarket);
					setMarketUsersList(usersData.data);
				}
			} catch (error: any) {
				console.log(error);
				setMarketUsersList(undefined);
			}
		})();
	}, [getToken, activeApplication, activeMarket]);

	return (
		<Layout
			activeMarket={activeMarket}
			getCountryName={getCountryName}
			showModal={showMarketUsersModal}
			setShowModal={setShowMarketUsersModal}
			marketUsersList={marketUsersList}
			activeApplication={activeApplication}
		/>
	);
};

export default MarketUsers;
