import * as React from "react";
import useAuthentication from "../../../hooks/useAuthentication";
import { declineApplicationRequest } from "../../../services/applications";
import useStore, { setApplicationRequestsSelector } from "../../../store";
import { getApplicationRequests } from "../../../services/applications";
import Layout from "./Layout";

interface ModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	declineAppId: string;
}

const DeclineApplicationModal: React.FC<ModalProps> = ({ showModal, setShowModal, declineAppId }) => {
	const { getToken, handleLogout } = useAuthentication();
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [loading, setLoading] = React.useState<boolean>(false);
	const [reason, setReason] = React.useState<string>("");
	const setApplicationRequests = useStore(setApplicationRequestsSelector);

	const declineApp = React.useCallback(
		async (appId, reason) => {
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			try {
				setLoading(true);
				await declineApplicationRequest(authHeader, appId, reason);
				setToastMessage("Declined successfully.");

				try {
					const { data: applicationRequests } = await getApplicationRequests(authHeader);
					setApplicationRequests(
						applicationRequests.data?.applicationRequests.length ? applicationRequests.data.applicationRequests : []
					);
				} catch (e: any) {
					window.location.reload();
				}

				setShowModal(false);
			} catch (err: any) {
				if (err?.response?.status == 401) {
					handleLogout();
				}
				setLoading(false);
				let errMsg = err.message;
				if (err.response && err.response.data && err.response.data.message) {
					errMsg += ` ${err.response.data.message}`;
				}
				setToastMessage(errMsg);
			} finally {
				setLoading(false);
			}
		},
		[getToken, handleLogout, setApplicationRequests, setShowModal]
	);

	return (
		<Layout
			showModal={showModal}
			setShowModal={setShowModal}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			declineAppId={declineAppId}
			setReason={setReason}
			reason={reason}
			loading={loading}
			declineApp={declineApp}
		/>
	);
};

export default DeclineApplicationModal;
