import { createAjv } from "@jsonforms/core";
import { materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import React, { useMemo } from "react";
import { Options } from "ajv";

import { useSchema } from "../../../core/context";
import { generateEmptyData } from "../../../core/model";
import { useExportSchema, useExportUiSchema } from "../../../core/util/hooks";
import { renderers } from "../../../../ConfigurationViewer/ConfigurationViewer";

export const Preview: React.FC = () => {
	const schema = useExportSchema();
	const uischema = useExportUiSchema();
	const editorSchema = useSchema();
	const previewData = useMemo(() => (editorSchema ? generateEmptyData(editorSchema) : {}), [editorSchema]);
	const ajv = createAjv({ useDefaults: true });


	return (
		<JsonForms
			ajv={ajv}
			data={previewData}
			schema={schema}
			uischema={uischema}
			renderers={renderers}
			cells={materialCells}
		/>
	);
};
