import * as React from "react";

import { FooterContent, StyledAnchor } from "../styles";
import Feedback from "../Feedback";
import styled from "styled-components";
import { SLACK_LINK } from "../../utils/constants";

const FeedbackLabel = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 0.2rem;
	margin-bottom: 0.1rem;
`;

const Footer: React.FC = () => {
	
	return (
		<footer>
			<div className="page-container">
				<div className="page-container__inner">
					<FooterContent className="page-container__main">
						<span>
							Please contact us in{" "}
							<StyledAnchor href={SLACK_LINK} target="_blank">
								#cssom-flagga
							</StyledAnchor>{" "}
							for any questions!
						</span>
						<FeedbackLabel>How did we do?</FeedbackLabel>
						<Feedback showRating={true}/>
					</FooterContent>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
