import React from "react";
import styled from "styled-components";

import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import { colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";

const StyledHeader = styled(ModalHeader)`
	background-color: ${colourStaticIkeaBrandYellow};
`;

type Props = {
	visible: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	title?: string;
};

const ModalComponent: React.FunctionComponent<Props> = ({ visible, onClose, children, title }) => {
	return (
		<Modal focusLockProps={{ locked: false }} visible={visible} handleCloseBtn={onClose}>
			<Sheets header={<StyledHeader title={title} />} footer={<ModalFooter children={undefined} />}>
				<ModalBody>{children}</ModalBody>
			</Sheets>
		</Modal>
	);
};

export default ModalComponent;
