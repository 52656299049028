import {
	OnboardingActions,
	RouteActions,
	Routes,
	SearchParamActiveApp,
	SearchParamActiveMarket,
	SearchParamActiveTab,
} from "./types";

export enum HomePageLayoutTabs {
	Configuration = "configuration",
	Schemas = "schemas",
	SchemaBuilder = "schemaBuilder",
	UiSchemaBuilder = "uiSchemaBuilder",
	Markets = "markets",
	Users = "users",
	UsersRequested = "usersRequested",
	Requests = "requests",
	Dashboard = "dashboard",
	MarketDashboard = "marketDashboard",
}

export enum SchemaLayoutTabs {
	schema = "Schema",
	uiSchema = "ui-schemas",
	JsonEditor = "json-editor",
}

export enum UserPermissions {
	Admin = "admin",
	Maintainer = "maintainer",
	ReadOnly = "read-only",
}

export enum ResponseMessage {
	Success = "success",
}

export const ROUTE_HOME: Routes = "/";
export const ROUTE_LOGIN: Routes = "/login";
export const ROUTE_LOGOUT: Routes = "/logout";
export const ROUTE_DASHBOARD: Routes = "/dashboard";
export const ROUTE_REQUESTS: Routes = "/requests";
export const ROUTE_EMAIL_CONSENT: Routes = "/consent";
export const ROUTE_MAINTENANCE: Routes = "/maintenance";
export const ROUTE_ONBOARDING: Routes = "/onboarding";
export const ROUTE_NEW_USER: Routes = "/new-user";
export const ROUTE_USERS: Routes = "/users";

export const ROUTE_ACTION_ADD_USER: RouteActions = "addUser";

export const SEARCH_PARAM_ACTIVE_TAB: SearchParamActiveTab = "activeTab";
export const SEARCH_PARAM_ACTIVE_APP: SearchParamActiveApp = "app";
export const SEARCH_PARAM_ACTIVE_MARKET: SearchParamActiveMarket = "market";

export const FLAGGA_ONBOARDING_DOCUMENTATION_URL =
	"https://confluence.build.ingka.ikea.com/pages/viewpage.action?pageId=533615418";
export const FLAGGA_VIDEO_ONBOARDING_URL =
	"https://confluence.build.ingka.ikea.com/display/FLAGGA/Onboarding+videos?preview=/741170262/741593799/Onboarding%20Video.mp4";
export const FLAGGA_VIDEO_OVERVIEW_PAGE_URL =
	"https://confluence.build.ingka.ikea.com/display/FLAGGA/Onboarding+videos";

export const DIGITAL_BUSINESS_SUPPORT_URL = "https://jira.digital.ingka.com/servicedesk/customer/portal/16/group/1437";

export const ROLE_DESCRIPTION_ADMIN =
	"You will have access to update all markets and have full control of the application.";
export const ROLE_DESCRIPTION_MAINTAINER = "You will have access to all markets but can only update assigned markets.";
export const ROLE_DESCRIPTION_READ_ONLY = "You can see all the markets values but cannot update them.";

export const applicationPlanOptions = [
	{
		text: "Gold",
		value: "gold",
	},
	{
		text: "Silver",
		value: "silver",
	},
	{
		text: "Bronze",
		value: "bronze",
	},
	{
		text: "Gold Cacheable",
		value: "gold-cacheable",
	},
	{
		text: "Silver Cacheable",
		value: "silver-cacheable",
	},
	{
		text: "Bronze Cacheable",
		value: "bronze-cacheable",
	},
	{
		text: "Custom",
		value: "custom",
	},
];

export const defaultColumnIndex = 999;
export const othersColumnIndex = 9999;

export const showPillsPaths: string[] = [ROUTE_HOME];

//make it false when not in maintenance mode
export const isInMaintenanceMode = false;

//to show banner on top
export const showBanner = false;

export const tertials = ["T1 2024", "T2 2024"];

export const ONBOARDING_ACTION_REQUEST_CREATE_NEW_APP: OnboardingActions = "requestCreateNewApplication";
export const ONBOARDING_ACTION_REQUEST_JOIN_EXISTINF_APP: OnboardingActions = "requestJoinExistingApplication";

export const SLACK_LINK = "https://ingka.slack.com/archives/C0453NE8TTP";

export const ENV_LOCAL = "local";
export const ENV_DEV = "dev";
export const ENV_STAGE = "stage";
export const ENV_PROD = "prod";

