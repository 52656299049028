import React, { useContext } from "react";

import { PropertiesService } from "../../properties/propertiesService";
import { CategorizationService } from "../api/categorizationService";
import { PaletteService } from "../api/paletteService";
import { SchemaService } from "../api/schemaService";
import { SchemaElement } from "../model";
import { EditorAction } from "../model/actions";
import { EditorUISchemaElement } from "../model/uischema";
import { SelectedElement } from "../selection";
import { SearchElement, SearchElementParent } from "../search";

export interface EditorContext {
	schemaService: SchemaService;
	paletteService: PaletteService;
	propertiesService: PropertiesService;
	schema: SchemaElement | undefined;
	uiSchema: EditorUISchemaElement | undefined;
	dispatch: (action: EditorAction) => void;
	selection: SelectedElement;
	setSelection: (selection: SelectedElement) => void;
	categorizationService: CategorizationService;
	sortingUISchema: "name" | "type";
	setSortingUISchema: (sorting: "name" | "type") => void;
	searchElement: SearchElement;
	setSearchElement: (searchElement: SearchElement) => void;
	searchElementParent: SearchElementParent;
	setSearchElementParent: (searchElement: SearchElementParent) => void;
}

/**We always use a provider so default can be undefined*/
const defaultContext: any = { undefined };

export const EditorContextInstance = React.createContext<EditorContext>(defaultContext);

export const useEditorContext = (): EditorContext => useContext(EditorContextInstance);

export const useGitLabService = (): SchemaService => {
	const { schemaService } = useEditorContext();
	return schemaService;
};

export const useSchema = (): SchemaElement | undefined => {
	const { schema } = useEditorContext();
	return schema;
};

export const useUiSchema = (): EditorUISchemaElement | undefined => {
	const { uiSchema } = useEditorContext();
	return uiSchema;
};

export const useSelection = (): [SelectedElement, (selection: SelectedElement) => void] => {
	const { selection, setSelection } = useEditorContext();
	return [selection, setSelection];
};

export const useDispatch = (): ((action: EditorAction) => void) => {
	const { dispatch } = useEditorContext();
	return dispatch;
};

export const usePaletteService = (): PaletteService => {
	const { paletteService } = useEditorContext();
	return paletteService;
};

export const usePropertiesService = (): PropertiesService => {
	const { propertiesService } = useEditorContext();
	return propertiesService;
};

export const useCategorizationService = (): CategorizationService => {
	const { categorizationService } = useEditorContext();
	return categorizationService;
};

export const useSearchElement = (): [SearchElement, (searchElement: SearchElement) => void] => {
	const { searchElement, setSearchElement } = useEditorContext();
	return [searchElement, setSearchElement];
};

export const useSearchElementParents = (): [
	SearchElementParent,
	(searchElementParent: SearchElementParent) => void
] => {
	const { searchElementParent, setSearchElementParent } = useEditorContext();
	return [searchElementParent, setSearchElementParent];
};
