import { MarketsData } from "../hooks/useGetMarkets";
import { ApplicationUserData } from "../services/users";
import {
	AddValidationErrorFn,
	Application,
	AuthenticationPayload,
	Market,
	ResetStateFn,
	ResetValidationErrorsFn,
	SetActiveTabFn,
	SetActiveApplicationFn,
	SetIsApplicationAdminFn,
	SetActiveMarketFn,
	SetShowGuidanceModalFn,
	StateSelector,
	StoreState,
	User,
	ValidationError,
	ApplicationData,
	SetApplicationsFn,
	SetMarketsFn,
	SetPathnameFromFn,
	SetSelectedMarketsFn,
	SetMultipleMarketUpdateFlagFn,
	RouterStateFrom,
	SetRouterStateFromFn,
	SetApplicationRequestsFn,
	SetApplicationUsersFN,
	SetShowManageScheduledChangesModalFn,
	SetForceRerenderFn
} from "./types";

export const userSelector = (state: StoreState): User | null => state.user;

export const showGuidanceModalSelector = (state: StoreState): boolean => state.showGuidanceModal;
export const setUserSelector = (state: StoreState): ((user: User | null) => void) => state.setUser;

export const authenticateUserSelector = (state: StoreState): ((payload: AuthenticationPayload) => void) =>
	state.authenticateUser;

export const activeMarketSelector: StateSelector<Market> = (state) => state.activeMarket;

export const setActiveMarketSelector: StateSelector<SetActiveMarketFn> = (state) => state.setActiveMarket;

export const selectedMarketsSelector: StateSelector<Market[]> = (state) => state.selectedMarkets;

export const setselectedMarketsSelector: StateSelector<SetSelectedMarketsFn> = (state) => state.setSelectedMarkets;

export const activeApplicationSelector: StateSelector<Application | null> = (state) => state.activeApplication;

export const validationErrorsSelector: StateSelector<ValidationError[]> = (state) => state.validationErrors;

export const setShowGuidanceModalSelector: StateSelector<SetShowGuidanceModalFn> = (state) =>
	state.setShowGuidanceModal;

export const setActiveApplicationSelector: StateSelector<SetActiveApplicationFn> = (state) =>
	state.setActiveApplication;

export const activeTabSelector: StateSelector<string | null> = (state: StoreState) => state.activeTab;

export const setActiveTabSelector: StateSelector<SetActiveTabFn> = (state) => state.setActiveTab;

export const resetStateSelector: StateSelector<ResetStateFn> = (state) => state.reset;

export const resetValidationErrorsSelector: StateSelector<ResetValidationErrorsFn> = (state) =>
	state.resetValidationErrors;

export const addValidationErrorSelector: StateSelector<AddValidationErrorFn> = (state) => state.addValidationError;

export const setIsApplicationAdminSelector: StateSelector<SetIsApplicationAdminFn> = (state: StoreState) =>
	state.setIsApplicationAdmin;

export const isApplicationAdminSelector = (state: StoreState): boolean => state.isApplicationAdmin;

export const applicationsSelector: StateSelector<ApplicationData[] | null> = (state: StoreState) => state.applications;

export const setApplicationsSelector: StateSelector<SetApplicationsFn> = (state: StoreState) => state.setApplications;

export const marketsSelector: StateSelector<MarketsData | null> = (state: StoreState) => state.markets;

export const setMarketsSelector: StateSelector<SetMarketsFn> = (state: StoreState) => state.setMarkets;

export const pathnameFromSelector: StateSelector<String | null> = (state: StoreState) => state.pathnameFrom;

export const setPathnameFromSelector: StateSelector<SetPathnameFromFn> = (state: StoreState) => state.setPathnameFrom;
export const multipleMarketUpdateFlagSelector: StateSelector<boolean> = (state) => state.multipleMarketUpdateFlag;

export const setMultipleMarketUpdateFlagSelector: StateSelector<SetMultipleMarketUpdateFlagFn> = (state) =>
	state.setMultipleMarketUpdateFlag;

export const routerStateFromSelector: StateSelector<RouterStateFrom> = (state) => state.routerStatefrom;

export const setRouterStateFromSelector: StateSelector<SetRouterStateFromFn> = (state) => state.setRouterStatefrom;

export const applicationRequestsSelector: StateSelector<object[]> = (state: StoreState) => state.applicationRequests;

export const setApplicationRequestsSelector: StateSelector<SetApplicationRequestsFn> = (state: StoreState) =>
	state.setApplicationRequests;

export const applicationsLoadingSelector = (state: StoreState): boolean => state.applicationsLoading;
export const setApplicationsLoadingSelector = (state: StoreState): ((applicationsLoading: boolean) => void) =>
	state.setApplicationsLoading;

export const marketsLoadingSelector = (state: StoreState): boolean => state.marketsLoading;
export const setMarketsLoadingSelector = (state: StoreState): ((marketsLoading: boolean) => void) =>
	state.setMarketsLoading;

export const setApplicationUsersSelector: StateSelector<SetApplicationUsersFN> = (state) => state.setApplicationUsers;

export const applicationUsersSelector: StateSelector<ApplicationUserData | null> = (state: StoreState) => state.applicationUsers;

export const showManageScheduledChangesModalSelector = (state: StoreState): boolean =>  state.showManageScheduledChangesModal;

export const setShowManageScheduledChangesModalSelector: StateSelector<SetShowManageScheduledChangesModalFn> = (state: StoreState) => state.setShowManageScheduledChangesModal;

export const forceRerenderSelector: StateSelector<boolean> = (state) => state.forceRerender;

export const setForceRerenderSelector: StateSelector<SetForceRerenderFn> = (state) => state.setForceRerender;