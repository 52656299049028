import * as React from "react";
import { Tab, TabPanel } from "@ingka/tabs";
import InlineMessage from "@ingka/inline-message";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import InputField from "@ingka/input-field";
import { flow } from "lodash";
import styled from "styled-components";

import { AddressOrder, Templates } from "../../../types/configuration";
import { TemplatesState, createInitialState, getEntry, update, runAll, normalizeTemplate } from "./utils";

export const TemplatesTab: React.FC = () => <Tab key="template" text="Edit templates" tabPanelId="templatesPanel" />;

interface TemplatesPanelProps {
	templates: AddressOrder["templates"];
	onTemplatesChange: (templates: Templates) => void;
	enabled: boolean;
}

const StyledPanel = styled(TabPanel)`
	padding-bottom: 0;
`;

const StyledInlineMessage = styled(InlineMessage)`
	padding-bottom: 2.5rem;
`;

const StyledInput = styled(InputField)<{ noMargin?: boolean }>`
	margin-top: ${({ noMargin }) => (!noMargin ? "1.5rem" : "0")};
`;

const TemplatesPanel: React.FC<TemplatesPanelProps> = ({ templates, onTemplatesChange, enabled }) => {
	const [state, setState] = React.useState<TemplatesState>(createInitialState(templates));

	const onChange = React.useCallback(
		(e) => {
			let updateFlow = flow(getEntry, update(state), runAll(setState, flow(normalizeTemplate, onTemplatesChange)));
			updateFlow(e);
		},
		[state, setState, onTemplatesChange]
	);

	return (
		<StyledPanel key="template" tabPanelId="templatesPanel">
			<StyledInlineMessage
				ssrIcon={informationCircle}
				body='Tip: Separate the values with | to support interpolation, i.e "lorem | ipsum"'
				variant="informative"
				subtle
			/>
			<StyledInput
				id={"address"}
				defaultValue={state.address}
				name="address"
				label={"Address"}
				type="text"
				disabled={!enabled}
				onChange={onChange}
				noMargin
			/>
			<StyledInput
				id={"city"}
				name="city"
				defaultValue={state.city}
				label={"City"}
				type="text"
				disabled={!enabled}
				onChange={onChange}
			/>
			<StyledInput
				id={"name"}
				name="name"
				defaultValue={state.name}
				label={"Name"}
				type="text"
				disabled={!enabled}
				onChange={onChange}
			/>
			<StyledInput
				id={"postcode"}
				name="postcode"
				defaultValue={state.postcode}
				label={"Postal Code"}
				type="text"
				disabled={!enabled}
				onChange={onChange}
			/>
			<StyledInput
				id={"country"}
				name="country"
				defaultValue={state.country}
				label={"Country"}
				type="text"
				disabled={!enabled}
				onChange={onChange}
			/>
			<StyledInput
				id={"privateAddress"}
				name="privateAddress"
				defaultValue={state.privateAddress}
				label={"Private Address"}
				type="text"
				disabled={!enabled}
				onChange={onChange}
			/>
		</StyledPanel>
	);
};

export default TemplatesPanel;
