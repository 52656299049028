import * as React from "react";
import { CheckmarkCircle, CrossCircle, Incorrect } from "../../assets/icons";
import {
	colourSemanticPositive,
	colourSemanticNegative,
	colourNeutral5,
	colourTextAndIcon2,
} from "@ingka/variables/colours-css";

interface TableCellProps {
	value: string | boolean;
}

const TableCell: React.FC<TableCellProps> = ({ value }) => {
	switch (value) {
		case true:
			return <CheckmarkCircle height={24} width={24} colour={colourSemanticPositive} />;
		case false:
			return <CrossCircle height={24} width={24} colour={colourSemanticNegative} />;
		case "true":
			return <CheckmarkCircle height={24} width={24} colour={colourSemanticPositive} />;
		case "false":
			return <CrossCircle height={24} width={24} colour={colourSemanticNegative} />;
		case "undefined":
			return <Incorrect height={24} width={24} colour={colourNeutral5} />;
		default:
			return <>{value}</>;
	}
};

export default TableCell;
