import * as React from "react";
import styled from "styled-components";

import Select, { Option as SelectOption } from "@ingka/select";
import FormField from "@ingka/form-field";
import { ListViewItem } from "@ingka/list-view";

import { ApplicationUiSchema } from "../../../services/applications/types";
import { AddMarketFormErrors, AddMarketFormShouldValidate, AddMarketFormValid } from "./types";
import { UserData } from "../../../services/users";
import { getImage } from "../../../utils/markets";

interface MarketsAndSchemasProps {
	valid: AddMarketFormValid;
	shouldValidate: AddMarketFormShouldValidate;
	formError: AddMarketFormErrors;
	validate: (name: string, value: string) => void;
	setSelectedUser: (data?: UserData) => void;
	setSelectedUiSchema: (value?: string) => void;
	marketsData?: string[];
	uiSchemas?: ApplicationUiSchema[];
	selectedMarkets?: string[];
	setSelectedMarkets: (value?: string[]) => void;
	getCountryName: (country: string) => string;
}

const ComponentWrapper = styled.div`
	padding-top: 2rem;
`;

const StyledListViewItem = styled(ListViewItem)`
	.list-view-item__wrapper {
		padding: 0.5rem 0 0.5rem 0;
	}
	.list-view-item__image {
		width: 2rem;
		height: 2rem;
	}
	.checkbox input[type="checkbox"]:checked ~ .checkbox__symbol {
		background: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
		border-color: rgb(var(--colour-interactive-emphasised-bg-default, 0, 88, 163));
	}
`;

const MarketsAndSchemas: React.FC<MarketsAndSchemasProps> = ({
	uiSchemas,
	marketsData,
	valid,
	shouldValidate,
	formError,
	validate,
	setSelectedUiSchema,
	selectedMarkets,
	getCountryName,
	setSelectedMarkets,
}) => {
	return (
		<ComponentWrapper>
			<FormField
				valid={valid.uiSchema}
				shouldValidate={shouldValidate.uiSchema}
				fieldHelper={{ msg: "Select a UI-Schema", id: "ui-schema-name" }}
				validation={{ msg: formError.uiSchema, id: "ui-schema-error", type: "error" }}
			>
				<Select
					id="select-uiSchema"
					label="UI-Schema"
					onChange={(e) => {
						validate("uiSchema", e.target.value);
						setSelectedUiSchema(e.target.value);
					}}
					disabled={false}
				>
					{uiSchemas?.map((schema) => (
						<SelectOption key={schema.uiSchemaName} value={schema.uiSchemaName} name={schema.uiSchemaName} />
					))}
				</Select>
			</FormField>
			<ul>
				{marketsData && marketsData.length > 0 && (
					<StyledListViewItem
						id="select-all"
						emphasised
						leadingIconPosition="center"
						control="checkbox"
						controlProps={
							{
								checked: marketsData && selectedMarkets && selectedMarkets.length === marketsData.length ? true : false,
							} as any
						}
						onChange={(e) => {
							if ((e.target as any)?.checked) {
								setSelectedMarkets(marketsData);
							} else {
								setSelectedMarkets([]);
							}
						}}
						size="large"
						title={"Select All"}
					/>
				)}
				{marketsData?.map((market) => {
					return (
						<StyledListViewItem
							id={market}
							image={getImage(market)}
							leadingIconPosition="center"
							control="checkbox"
							controlProps={
								{
									checked: selectedMarkets?.includes(market) || false,
								} as any
							}
							onChange={(e) => {
								if ((e.target as any)?.checked) {
									setSelectedMarkets(selectedMarkets ? [...selectedMarkets, market] : [market]);
								} else {
									selectedMarkets = selectedMarkets ? [...selectedMarkets, market] : [market];
									setSelectedMarkets(selectedMarkets.filter((_market) => _market !== market));
								}
							}}
							size="large"
							title={
								<>
									<strong>{market}</strong> | {getCountryName(market)}
								</>
							}
						/>
					);
				})}
			</ul>
		</ComponentWrapper>
	);
};

export default MarketsAndSchemas;
