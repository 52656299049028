import * as React from "react";
import styled from "styled-components";
import InputField from "@ingka/input-field";
import Modal, { ModalBody, Sheets } from "@ingka/modal";
import Toast from "@ingka/toast";
import Button from "@ingka/button";
import { StyledModalHeader } from "../../styles";

const StyledButton = styled(Button)`
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	padding: 1.5rem;
`;

interface ModalLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	appId: string | undefined;
	reason: string;
	loading: boolean;
	email: string | undefined;
	setReason: (value: string) => void;
	declineAppAccessRequest: (appId: string | undefined, reason: string, email: string | undefined) => void;
}

const Layout: React.FC<ModalLayoutProps> = ({
	showModal,
	setShowModal,
	toastMessage,
	setToastMessage,
	appId,
	reason,
	setReason,
	loading,
	declineAppAccessRequest,
	email,
}) => {
	return (
		<>
			<Modal
				focusLockProps={{ locked: false }}
				escapable={!loading}
				visible={showModal}
				handleCloseBtn={() => !loading && setShowModal(false)}
				onModalClosed={() => {}}
			>
				<Sheets
					footer={
						<StyledButton
							id="decline-user-request"
							text="Decline"
							type="emphasised"
							loading={loading}
							onClick={() => {
								declineAppAccessRequest(appId, reason, email);
							}}
						/>
					}
					header={<StyledModalHeader title="Decline Application Request" />}
				>
					<ModalBody>
						<InputField disabled={true} value={appId} id="appId" label="App Id" type="text" />
						<InputField
							req={true}
							value={reason}
							disabled={loading}
							id="reason"
							label="Reason"
							type="text"
							onChange={(e) => {
								setReason(e.target.value);
							}}
						/>
					</ModalBody>
				</Sheets>
			</Modal>

			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				ariaLabelCloseBtn="Dismiss notification"
				onCloseRequest={() => setToastMessage("")}
			/>
		</>
	);
};

export default Layout;
