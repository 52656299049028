import * as React from "react";
import { ControlElement, LayoutProps } from "@jsonforms/core";
import { JsonFormsDispatch, JsonFormsStateContext, withJsonFormsContext } from "@jsonforms/react";
import TrashCan from "@ingka/ssr-icon/paths/trash-can";
import styled from "styled-components";
import Button from "@ingka/button";
import arrowUp from "@ingka/ssr-icon/paths/arrow-up";
import arrowDown from "@ingka/ssr-icon/paths/arrow-down";
import Tooltip from "@ingka/tooltip";

interface DispatchPropsOfCardRenderer {
	onRemove(): () => void;
	onMoveUp(): () => void;
	onMoveDown(): () => void;
}

interface CardRendererProps extends LayoutProps, DispatchPropsOfCardRenderer {
	index: number;
	data: number;
	removable?: boolean;
}

const ItemsWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.75rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 0.5rem;
	margin-bottom: 1.25rem;
	.remove-button {
		padding-left: 0.5rem;
	}
`;

const ArrowButtonsWrapper = styled.div`
	display: flex;
`;

const controlWithoutLabel = (scope: string): ControlElement => ({
	type: "Control",
	scope: scope,
	label: false,
});

export const PrimitiveArrayRanderer = (props: CardRendererProps) => {
	const { schema, path, renderers, cells, onRemove, enabled, index, data, onMoveUp, onMoveDown, removable } = props;

	return (
		<ItemsWrapper>
			<JsonFormsDispatch
				schema={schema}
				uischema={controlWithoutLabel("#")}
				path={path}
				enabled={enabled}
				renderers={renderers}
				cells={cells}
				key={index}
			/>
			<ButtonWrapper>
				<ArrowButtonsWrapper>
					<div>
						<Tooltip tooltipText="Move up">
							<Button
								type="tertiary"
								xsmallIconOnly
								onClick={onMoveUp}
								iconOnly
								small
								ssrIcon={arrowUp}
								disabled={index === 0 || !enabled}
							/>
						</Tooltip>
					</div>
					<div>
						<Tooltip tooltipText="Move down">
							<Button
								type="tertiary"
								onClick={onMoveDown}
								iconOnly
								small
								ssrIcon={arrowDown}
								disabled={index === data - 1 || !enabled}
								xsmallIconOnly
							/>
						</Tooltip>
					</div>
				</ArrowButtonsWrapper>
				<Tooltip tooltipText="Remove">
					<Button
						className="remove-button"
						type="tertiary"
						onClick={onRemove}
						iconOnly
						small
						ssrIcon={TrashCan}
						disabled={!enabled || removable === false}
					/>
				</Tooltip>
			</ButtonWrapper>
		</ItemsWrapper>
	);
};

const withContextToPrimitiveArrayRanderer = (
	Component: React.ComponentType<CardRendererProps>
): React.ComponentType<CardRendererProps> => {
	return ({ ctx, props }: JsonFormsStateContext & CardRendererProps) => {
		return <Component {...props} />;
	};
};

const withCustomProps = (Component: React.ComponentType<CardRendererProps>) => {
	return withJsonFormsContext(withContextToPrimitiveArrayRanderer(Component));
};

export default withCustomProps(PrimitiveArrayRanderer);
