import * as React from "react";
import useAuthentication from "../../hooks/useAuthentication";
import { getApplicationRequests } from "../../services/applications";
import useApplicationForm from "../Applications/AddApplicationModal/useApplicationForm";
import useStore, { setApplicationRequestsSelector, applicationRequestsSelector } from "../../store";

import Layout from "./Layout";

const ManageApplicationRequests: React.FC = () => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [showAddModal, setShowAddModel] = React.useState<boolean>(false);
	const [showDeclineModal, setShowDeclineModal] = React.useState<boolean>(false);
	const data = useStore(applicationRequestsSelector);
	const setData = useStore(setApplicationRequestsSelector);
	const { getToken, handleLogout } = useAuthentication();
	const [toastMessage, setToastMessage] = React.useState<string>("");
	const [declineAppId, setDeclineAppId] = React.useState<string>("");
	const { value, setValue } = useApplicationForm();
	React.useEffect(() => {
		(async () => {
			const token = await getToken();
			const authHeader = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			try {
				setLoading(true);
				const { data: applicationRequests } = await getApplicationRequests(authHeader);
				setData(
					applicationRequests.data?.applicationRequests.length ? applicationRequests.data.applicationRequests : []
				);
			} catch (error: any) {
				setData([]);
				if (error?.response?.status == 401) {
					handleLogout();
				}
			} finally {
				setLoading(false);
			}
		})();

		return () => {
			// cancel the subscription
			setData([]);
			setLoading(false);
		};
	}, [getToken, handleLogout, setData]);

	const handleCreate = React.useCallback(
		async (data) => {
			setShowAddModel(true);
			setValue({
				appId: data.appId,
				appName: data.appName,
				email: data.email,
				clientIds: data.clientIds || [""],
				showDashboard: data.showDashboard || false,
				showMarketDashboard: data.showMarketDashboard || false,
				showAdoptionRate:data.showAdoptionRate || false,
				allowReadOnly: data.allowReadOnly || false,
				hasReadDocumentation: data.hasReadDocumentation || false,
				plan: data.plan,
			});
		},
		[setValue]
	);

	const handlDecline = React.useCallback(async (appId) => {
		setDeclineAppId(appId);
		setShowDeclineModal(true);
		return;
	}, []);

	return (
		<Layout
			loading={loading}
			data={data || []}
			toastMessage={toastMessage}
			setToastMessage={setToastMessage}
			showAddModal={showAddModal}
			setShowAddModal={setShowAddModel}
			handleCreate={handleCreate}
			value={value}
			handlDecline={handlDecline}
			showDeclineModal={showDeclineModal}
			setShowDeclineModal={setShowDeclineModal}
			declineAppId={declineAppId}
		/>
	);
};

export default ManageApplicationRequests;
