import * as React from "react";
import { debounce } from "lodash";

import InputField from "@ingka/input-field";
import Hyperlink from "@ingka/hyperlink";
import Switch from "@ingka/switch";

import {
	Paragraph,
	StyledFormField,
	SwitchWraper,
	StyledCheckbox,
	StyledInputLabel,
	StyledFormFieldWrapper,
} from "./Layout";
import Text from "@ingka/text";
import { ApplicationValidation, Errors, Valid, Value } from "./types";
import Select, { Option as SelectOption } from "@ingka/select";
import { applicationPlanOptions, FLAGGA_ONBOARDING_DOCUMENTATION_URL } from "../../../utils/constants";

import { UserApplicationData, UserData } from "../../../services/users";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
	AutocompleteStyles,
	ClientIdsContainer,
	ClientIdsHeaderInnerContainer,
	ClientIdsAddAction,
	GreyPlaceholderText,
	ClientIdsList,
	ExpandedClientIdsContainer,
	ClientIdsInput,
	ClientIdsInnerContainer,
} from "../../styles";
import Button from "@ingka/button";
import Pill from "@ingka/pill";

import plus from "@ingka/ssr-icon/paths/plus";
import TrashCan from "@ingka/ssr-icon/paths/trash-can";

interface AddApplicationLayoutProps {
	value: Value;
	valid: Valid;
	validation: ApplicationValidation;
	handleChange: (val: string | boolean | string[], name: string) => void;
	shouldValidate: Valid;
	isFlaggaAdmin: boolean;
	errors?: Errors[];
	getSuggestions: (input: string) => void;
	userSuggestions: UserData[] | undefined;
	noOptionsText: string;
}

const AddApplicationLayout: React.FC<AddApplicationLayoutProps> = ({
	value,
	valid,
	validation,
	handleChange,
	shouldValidate,
	errors,
	isFlaggaAdmin,
	getSuggestions,
	userSuggestions,
	noOptionsText,
}) => {
	return (
		<>
			{!isFlaggaAdmin && (
				<StyledFormField
					valid={valid.hasReadDocumentation}
					shouldValidate={shouldValidate.hasReadDocumentation}
					validation={validation.hasReadDocumentation}
					fieldHelper={{ id: "hasReadDocsValIdationHelper" }}
				>
					<StyledCheckbox
						id="hasReadDocumentation"
						label={
							<>
								Yes, I've read the documentation about{" "}
								<Hyperlink text="Flagga Onboarding" url={FLAGGA_ONBOARDING_DOCUMENTATION_URL} newWindow />
							</>
						}
						checked={value.hasReadDocumentation}
						value=""
						onChange={(e) => {
							handleChange(e.target.checked, "hasReadDocumentation");
						}}
					/>
				</StyledFormField>
			)}
			<StyledFormField
				valid={valid.appId}
				shouldValidate={shouldValidate.appId}
				validation={validation.appId}
				fieldHelper={{
					msg: "Application ID, lower case alphabets and - allowed.",
					id: "appIdValIdationHelper",
				}}
			>
				<InputField
					req={true}
					value={value.appId}
					id="applicationId"
					label="Application ID *"
					type="text"
					onChange={({ target: { value } }) => {
						handleChange(value, "applicationId");
					}}
				/>
			</StyledFormField>
			<StyledFormField
				valid={valid.appName}
				shouldValidate={shouldValidate.appName}
				validation={validation.appName}
				fieldHelper={{
					msg: "A Friendly Name for your Application.",
					id: "appNameValidationHelper",
				}}
			>
				<InputField
					req={true}
					value={value.appName}
					id="applicationName"
					label="Application Name *"
					type="text"
					onChange={({ target: { value } }) => {
						handleChange(value, "applicationName");
					}}
				/>
			</StyledFormField>
			{!isFlaggaAdmin && (
				<StyledFormField
					valid={valid.email}
					shouldValidate={shouldValidate.email}
					disabled={true}
					validation={validation.email}
					fieldHelper={{ msg: "Application Admin's Email Address.", id: "emailValidationHelper" }}
				>
					<InputField req={true} id="email" value={value.email} disabled={true} label="Admin Email *" type="text" />
				</StyledFormField>
			)}
			{isFlaggaAdmin && (
				<StyledFormFieldWrapper>
					<StyledInputLabel htmlFor="adminInput" title="Admin email *">
						Admin email *
					</StyledInputLabel>
					<Autocomplete
						id="adminInput"
						data-test-id="admin-email"
						onInputChange={debounce((event, value) => {
							getSuggestions(value?.toLowerCase());
						}, 300)}
						options={userSuggestions ? userSuggestions.map((user) => user.email) : []}
						value={value.email ? value.email : undefined}
						renderInput={(params) => (
							<TextField {...params} placeholder="Please enter user name/email" variant="outlined" />
						)}
						onChange={(_, adminEmailValue) => {
							if (adminEmailValue) handleChange(adminEmailValue, "email");
						}}
						sx={AutocompleteStyles}
						noOptionsText={noOptionsText}
					/>
				</StyledFormFieldWrapper>
			)}
			{!isFlaggaAdmin && (
				<StyledFormField shouldValidate={false}>
					<Select
						id="plan"
						label="Plan (optional)"
						value={value.plan}
						onChange={({ target: { value } }) => {
							handleChange(value, "plan");
						}}
					>
						{applicationPlanOptions.map((plan) => (
							<SelectOption key={plan.value} value={plan.value} name={plan.text} />
						))}
					</Select>
				</StyledFormField>
			)}

			<ClientIdsContainer>
				<ClientIdsHeaderInnerContainer>
					<ClientIdsAddAction>
						<Text bodySize="l">Application Client Id</Text>
					</ClientIdsAddAction>
				</ClientIdsHeaderInnerContainer>
				{(!value.clientIds || value.clientIds?.length === 0) && (
					<GreyPlaceholderText>No client ID has been added</GreyPlaceholderText>
				)}
				<ExpandedClientIdsContainer>
					{value.clientIds?.map((clientId, index) => (
						<ClientIdsInnerContainer>
							<ClientIdsInput
								req={false}
								id={`clientId-${index + 1}`}
								value={value?.clientIds?.[index] || ""}
								label={`Client ID ${index + 1}`}
								type="text"
								onChange={({ target: { value: val } }) => {
									if (!value?.clientIds) {
										value.clientIds = [];
									}
									value.clientIds[index] = val;
									handleChange(value.clientIds, "clientIds");
								}}
							/>
							<Button
								style={{ marginTop: "1rem" }}
								type="tertiary"
								onClick={() => {
									value.clientIds?.splice(index, 1);
									handleChange(value.clientIds || [], "clientIds");
								}}
								iconOnly
								small
								ssrIcon={TrashCan}
							/>
						</ClientIdsInnerContainer>
					))}
					<Button
						style={{ marginTop: "1rem" }}
						type="tertiary"
						text="Add client id"
						data-testid="add-client-id-add"
						onClick={() => {
							if (!value.clientIds) {
								value.clientIds = [];
							}
							value.clientIds.push("");
							handleChange(value.clientIds, "clientIds");
						}}
						small
					/>
				</ExpandedClientIdsContainer>
			</ClientIdsContainer>

			{isFlaggaAdmin && (
				<SwitchWraper>
					<Switch
						data-testid="showDashboard"
						value={""}
						checked={value.showDashboard || false}
						id="show-in-dashboard"
						label="Enable Global Dashboard"
						onChange={() => {
							handleChange("", "showDashboard");
						}}
					/>
				</SwitchWraper>
			)}
			{isFlaggaAdmin && (
				<SwitchWraper>
					<Switch
						data-testid="showMarketDashboard"
						value={""}
						checked={value.showMarketDashboard || false}
						id="show-in-market-dashboard"
						label="Enable Market Dashboard"
						onChange={() => {
							handleChange("", "showMarketDashboard");
						}}
					/>
				</SwitchWraper>
			)}
			{isFlaggaAdmin && (
				<SwitchWraper>
					<Switch
						data-testid="showAdoptionRate"
						value={""}
						checked={value.showAdoptionRate || false}
						id="show-adoption-rate"
						label="Show Adoption Rate"
						onChange={() => {
							handleChange("", "showAdoptionRate");
						}}
					/>
				</SwitchWraper>
			)}
			{isFlaggaAdmin && (
				<SwitchWraper>
					<Switch
						data-testid="allowReadOnly"
						value={""}
						checked={value.allowReadOnly || false}
						id="allow-read-only"
						label="Allow Read Only"
						onChange={() => {
							handleChange("", "allowReadOnly");
						}}
					/>
				</SwitchWraper>
			)}
			{errors?.map((error) => (
				<Paragraph>{error?.message}</Paragraph>
			))}
		</>
	);
};

export default AddApplicationLayout;
