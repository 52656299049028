import * as React from "react";
import { Tab, TabPanel } from "@ingka/tabs";
import InlineMessage from "@ingka/inline-message";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import ActionList from "@ingka/action-list";
import styled from "styled-components";
import { flow } from "lodash";

import { Order } from "../../../types/configuration";
import OrderItem from "./OrderItem";
import { runAll, swap } from "./utils";

export const OrderTab: React.FC = () => <Tab key="order-tab" text="Set display order" tabPanelId="orderPanel" />;

interface OrderPanelProps {
	order: Order[];
	onOrderChange: (value: Order[]) => void;
	enabled: boolean;
}

const StyledPanel = styled(TabPanel)`
	padding-bottom: 0;
`;

const StyledList = styled(ActionList)`
	padding-left: 0;
`;

const labels: Record<Order, string> = {
	address: "Address",
	city: "City",
	name: "Name",
	postcode: "Postal Code",
	country: "Country",
	privateAddress: "Private Address",
};

const OrderPanel: React.FC<OrderPanelProps> = ({ order, onOrderChange, enabled }) => {
	const [displayOrder, setDisplayOrder] = React.useState(order);

	const onItemMove = React.useCallback(
		(dragIndex: number, hoverIndex: number): void => {
			const nextOrder = swap(displayOrder, dragIndex, hoverIndex);
			let moveItemFlow = flow(runAll(setDisplayOrder, onOrderChange));
			return moveItemFlow(nextOrder);
		},
		[displayOrder, setDisplayOrder, onOrderChange]
	);

	return (
		<StyledPanel key="order" tabPanelId="orderPanel">
			<InlineMessage
				ssrIcon={informationCircle}
				body="Drag the objects up and down to set the display order"
				variant="informative"
				subtle
			/>
			<StyledList id="display-order">
				{displayOrder.map((key, index) => (
					<OrderItem key={key} id={key} index={index} moveItem={onItemMove} label={labels[key]} enabled={enabled} />
				))}
			</StyledList>
		</StyledPanel>
	);
};

export default OrderPanel;
