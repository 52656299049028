import * as React from "react";
import styled from "styled-components";

import Modal, { Sheets, ModalBody, Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import Button from "@ingka/button";
import FormField from "@ingka/form-field";
import { colourSemanticNegative } from "@ingka/variables/colours-css";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Toast from "@ingka/toast";
import Checkbox from "@ingka/checkbox";
import InlineMessage from "@ingka/inline-message";
import InputField from "@ingka/input-field";
import Text from "@ingka/text";

import { ACTIVE_TABS, ApplicationValidation, Errors, Valid, Value } from "./types";
import { ModalButtonWrapper } from "../../styles";
import { ApplicationData } from "../../../store";
import AddApplicationLayout from "./AddApplicationLayout";
import UpdateApplicationLayout from "./UpdateApplicationLayout";
import DeleteApplicationLayout from "./DeleteApplicationLayout";

import { UserData } from "../../../services/users";
import { StyledModalHeader } from "../../styles";

export const StyledFormField = styled(FormField)`
	margin-bottom: 2rem;
`;

export const Paragraph = styled.p`
	color: ${colourSemanticNegative};
`;

export const SwitchWraper = styled.div`
	margin: 1rem 0;
`;

export const StyledCheckbox = styled(Checkbox)`
	.checkbox__label {
		margin-top: 0rem;
	}
`;

export const StyledInputLabel = styled.label`
	font-size: 0.875rem;
	line-height: 1.571;
`;

export const StyledFormFieldWrapper = styled.div`
	margin-bottom: 2rem;
`;

const StyledPrompt = styled(Prompt)`
	width: 25rem;
`;

export const LoadingWrapper = styled.div`
	height: 10vh; /* Adjust the height as needed */
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center; /* Align text in the center */
`;

const StyledText = styled(Text)`
	margin: 2rem 0 0.5rem 0;
`;

interface AddApplicationLayoutProps {
	value: Value;
	valid: Valid;
	validation: ApplicationValidation;
	handleChange: (val: string | boolean | string[], name: string) => void;
	shouldValidate: Valid;
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	isButtonDisabled: boolean;
	btnLoading: boolean;
	handlAddApplication?: React.FormEventHandler<HTMLFormElement>;
	applications: ApplicationData[] | null;
	activeTab: string;
	setActiveTab: (data: string) => void;
	setUpdateAppSelected: (data?: ApplicationData) => void;
	onUpdateApplication: () => void;
	updateLoading: boolean;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	updateAppSelected?: ApplicationData;
	isUpdateDisabled?: boolean;
	isFlaggaAdmin: boolean;
	isApplicationAdmin: boolean;
	errors?: Errors[];
	showOnlyCreate: boolean;
	getSuggestions: (input: string) => void;
	userSuggestions: UserData[] | undefined;
	noOptionsText: string;
	resetValue: () => void;
	setSelectedAppToDelete: (data?: ApplicationData) => void;
	selectedAppToDelete: ApplicationData | undefined;
	onDeleteApplication: () => void;
	isDeleteApplicaionBackup: boolean | undefined;
	setIsDeleteApplicationBackup: (value: boolean) => void;
	showDeleteApplicationModal: boolean;
	setShowDeleteApplicationModal: (value: boolean) => void;
	isDeleteButtonDisabled: boolean;
	deleteInProgress: boolean;
	deleteApplicationId: string;
	setDeleteApplicationId: (value: string) => void;
	isDeleteApplicationIdValid: boolean;
}

const Layout: React.FC<AddApplicationLayoutProps> = ({
	value,
	valid,
	validation,
	handleChange,
	shouldValidate,
	handlAddApplication,
	showModal,
	setShowModal,
	isButtonDisabled,
	errors,
	btnLoading,
	applications,
	activeTab,
	setActiveTab,
	setUpdateAppSelected,
	updateAppSelected,
	onUpdateApplication,
	isUpdateDisabled,
	updateLoading,
	toastMessage,
	setToastMessage,
	isFlaggaAdmin,
	isApplicationAdmin,
	showOnlyCreate,
	getSuggestions,
	userSuggestions,
	noOptionsText,
	resetValue,
	setSelectedAppToDelete,
	selectedAppToDelete,
	onDeleteApplication,
	isDeleteApplicaionBackup,
	setIsDeleteApplicationBackup,
	showDeleteApplicationModal,
	setShowDeleteApplicationModal,
	isDeleteButtonDisabled,
	deleteInProgress,
	setDeleteApplicationId,
	isDeleteApplicationIdValid,
	deleteApplicationId,
}) => {
	const tabs = React.useMemo(() => {
		return [
			<Tab
				key="add-application"
				text={"Add New App"}
				type="button"
				tabPanelId={ACTIVE_TABS.ADD_APPICATION}
				onClick={() => {
					if(ACTIVE_TABS.ADD_APPICATION)
					{
						resetValue();
					}
					setActiveTab(ACTIVE_TABS.ADD_APPICATION);
				}}
			/>,
			<Tab
				key="update-application"
				text={"Update App"}
				tabPanelId={ACTIVE_TABS.UPDATE_APPLICATION}
				type="button"
				onClick={() => {
					setActiveTab(ACTIVE_TABS.UPDATE_APPLICATION);
				}}
			/>,
			<Tab
				key="delete-application"
				text={"Delete App"}
				tabPanelId={ACTIVE_TABS.DELETE_APPLICATION}
				type="button"
				onClick={() => {
					setActiveTab(ACTIVE_TABS.DELETE_APPLICATION);
				}}
			/>,
		];
	}, [setActiveTab]);

	const tabPanels = React.useMemo(() => {
		const tabPanels = [
			<TabPanel key="update-application" tabPanelId={ACTIVE_TABS.UPDATE_APPLICATION}>
				<UpdateApplicationLayout
					applications={applications}
					setUpdateAppSelected={setUpdateAppSelected}
					updateAppSelected={updateAppSelected}
				/>
			</TabPanel>,
			<TabPanel key="add-application" tabPanelId={ACTIVE_TABS.ADD_APPICATION}>
				<AddApplicationLayout
					value={value}
					valid={valid}
					validation={validation}
					handleChange={handleChange}
					shouldValidate={shouldValidate}
					errors={errors}
					isFlaggaAdmin={isFlaggaAdmin}
					userSuggestions={userSuggestions}
					noOptionsText={noOptionsText}
					getSuggestions={getSuggestions}
				/>
			</TabPanel>,
			<TabPanel key="delete-application" tabPanelId={ACTIVE_TABS.DELETE_APPLICATION}>
				<DeleteApplicationLayout
					applications={applications}
					setSelectedAppToDelete={setSelectedAppToDelete}
					selectedAppToDelete={selectedAppToDelete}
					isDeleteApplicationBackup={isDeleteApplicaionBackup}
					setIsDeleteApplicationBackup={setIsDeleteApplicationBackup}
					isDeleteButtonDisabled={isDeleteButtonDisabled}
					deleteInProgress={deleteInProgress}
				/>
			</TabPanel>,
		];
		return tabPanels;
	}, [
		applications,
		setUpdateAppSelected,
		updateAppSelected,
		value,
		valid,
		validation,
		handleChange,
		shouldValidate,
		errors,
		isFlaggaAdmin,
		userSuggestions,
		noOptionsText,
		getSuggestions,
		setSelectedAppToDelete,
		selectedAppToDelete,
		isDeleteApplicaionBackup,
		setIsDeleteApplicationBackup,
		isDeleteButtonDisabled,
		deleteInProgress,
	]);

	return (
		<>
			<form onSubmit={handlAddApplication}>
				<Modal
					focusLockProps={{ locked: false }}
					visible={showModal}
					handleCloseBtn={() => {
						resetValue();
						setShowModal(false);
					}}
				>
					<Sheets
						footer={
							<ModalButtonWrapper>
								{activeTab === ACTIVE_TABS.UPDATE_APPLICATION && (isFlaggaAdmin || isApplicationAdmin) ? (
									<Button
										data-testid="updateApplication"
										text="Update Application"
										type="emphasised"
										disabled={isUpdateDisabled}
										htmlType="button"
										loading={updateLoading}
										onClick={onUpdateApplication}
									/>
								) : activeTab === ACTIVE_TABS.DELETE_APPLICATION && isFlaggaAdmin ? (
									<Button
										data-testid="deleteApplication"
										text="Delete Application"
										type="emphasised"
										disabled={isDeleteButtonDisabled}
										htmlType="button"
										onClick={() => setShowDeleteApplicationModal(true)}
										loading={deleteInProgress}
									/>
								) : (
									<Button
										data-testid="addApplilcation"
										text={isFlaggaAdmin ? "Add Application" : "Send request"}
										type="emphasised"
										htmlType="submit"
										disabled={isButtonDisabled}
										loading={btnLoading}
									/>
								)}
							</ModalButtonWrapper>
						}
						header={
							<StyledModalHeader
								title={
									isFlaggaAdmin
										? showOnlyCreate
											? "Add Application"
											: activeTab === ACTIVE_TABS.UPDATE_APPLICATION
											? "Update Application"
											: activeTab === ACTIVE_TABS.DELETE_APPLICATION
											? "Delete Application"
											: "Add Application"
										: isApplicationAdmin && !showOnlyCreate
											? "Update Application"
											: "Request New Application"
								}
							/>
						}
					>
						<ModalBody>
							{isFlaggaAdmin ? (
								showOnlyCreate ? (
									<AddApplicationLayout
										value={value}
										valid={valid}
										validation={validation}
										handleChange={handleChange}
										shouldValidate={shouldValidate}
										errors={errors}
										isFlaggaAdmin={isFlaggaAdmin}
										getSuggestions={getSuggestions}
										userSuggestions={userSuggestions}
										noOptionsText={noOptionsText}
									/>
								) : (
									<Tabs tabs={tabs} tabPanels={tabPanels} defaultActiveTab={activeTab} activeTab={activeTab} />
								)
							) : isApplicationAdmin && !showOnlyCreate ? (
								<UpdateApplicationLayout
									applications={applications}
									setUpdateAppSelected={setUpdateAppSelected}
									updateAppSelected={updateAppSelected}
								/>
							) : (
								<AddApplicationLayout
									value={value}
									valid={valid}
									validation={validation}
									handleChange={handleChange}
									shouldValidate={shouldValidate}
									errors={errors}
									isFlaggaAdmin={isFlaggaAdmin}
									getSuggestions={getSuggestions}
									userSuggestions={userSuggestions}
									noOptionsText={noOptionsText}
								/>
							)}
						</ModalBody>
					</Sheets>
				</Modal>
			</form>
			<Toast
				data-testid="message"
				text={toastMessage}
				isOpen={!!toastMessage}
				onCloseRequest={() => setToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
			<Modal
				visible={showDeleteApplicationModal}
				escapable={true}
				handleCloseBtn={() => setShowDeleteApplicationModal(false)}
			>
				<StyledPrompt
					title=""
					titleId={"modalTitleId"}
					data-testid="confirm-delete-application-modal"
					header={<StyledModalHeader ariaCloseTxt="Close button text" title="Delete Application" />}
					footer={
						<ModalFooter>
							<Button
								data-testid="Delete"
								text={deleteInProgress ? "In Progress" : "Delete"}
								type="danger"
								disabled={selectedAppToDelete?.id !== deleteApplicationId}
								onClick={() => onDeleteApplication()}
							/>
							<Button
								data-testid="Cancel"
								text="Cancel"
								type="secondary"
								onClick={() => {
									setDeleteApplicationId("");
									setShowDeleteApplicationModal(false);
								}}
							/>
						</ModalFooter>
					}
				>
					<InlineMessage
						variant="cautionary"
						body={
							<>
								This will permanently delete all the data, including <strong>schema</strong>,{" "}
								<strong>ui-schemas</strong> and <strong>markets data</strong>.
							</>
						}
					/>

					<StyledText tagName="p" bodySize="m">
						Confirm you want to delete this appication by typing its ID: <strong>{selectedAppToDelete?.id}</strong>
					</StyledText>
					<FormField
						shouldValidate={selectedAppToDelete?.id !== deleteApplicationId}
						valid={selectedAppToDelete?.id !== deleteApplicationId}
						validation={{ msg: `enter ${selectedAppToDelete?.id} to delete`, type: "error" }}
					>
						<InputField
							data-testid="delete-app-id"
							id={"delete-app-id"}
							type={"text"}
							onChange={(e) => setDeleteApplicationId(e.target.value)}
						/>
					</FormField>
				</StyledPrompt>
			</Modal>
		</>
	);
};

export default Layout;
