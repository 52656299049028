import * as React from "react";
import { useNavigate } from "react-router-dom";

import Layout from "./Layout";
import useStore, { setUserSelector, userSelector } from "../../store";
import useRemoveUserNewApplicationRequest from "../../hooks/useRemoveUserNewApplicationRequest";
import useCancelApplicationRequest from "../../hooks/useCancelApplicationRequest";
import {
	ONBOARDING_ACTION_REQUEST_CREATE_NEW_APP,
	ONBOARDING_ACTION_REQUEST_JOIN_EXISTINF_APP,
	ROUTE_HOME,
	ROUTE_ONBOARDING,
	ResponseMessage,
} from "../../utils/constants";
import useGetLoggedInUser from "../../hooks/useGetLoggedInUser";
import useGetApplications from "../../hooks/useGetApplications";

const FirstTimeUser: React.FC = () => {
	const navigate = useNavigate();
	const [createdAppRemoving, setCreatedAppRemoving] = React.useState<string>("");
	const [existingAppRequestRemoving, setExistingAppRequestRemoving] = React.useState<string>("");

	const [toast, setToast] = React.useState<string>("");
	const [showApplicationModal, setShowApplicationModal] = React.useState(false);

	const currentUser = useStore(userSelector);
	const setUser = useStore(setUserSelector);

	const { removeUserRequestedNewApplications, loading: cancelNewlyCreatedAppLoading } =
		useRemoveUserNewApplicationRequest();
	const { cancelApplicationRequest, loading: cancelExistingAppRequestLoading } = useCancelApplicationRequest();
	const { getUser, loading: userLoading, data: userData } = useGetLoggedInUser();

	const hasApplications = React.useMemo(() => currentUser?.user.hasApplications, [currentUser]);
	const { data, loading, getApplicationsData } = useGetApplications();

	React.useEffect(() => {
		getApplicationsData(true);
	}, [getApplicationsData]);

	React.useEffect(() => {
		hasApplications && navigate(ROUTE_HOME);
	}, [hasApplications, navigate]);

	const existingAppRequests = React.useMemo(
		() => currentUser?.user?.requestedApplications,
		[currentUser?.user?.requestedApplications]
	);
	const newAppsRequested = React.useMemo(
		() => currentUser?.user?.newApplicationRequests,
		[currentUser?.user?.newApplicationRequests]
	);

	const hasRequests = React.useMemo(
		() => !!newAppsRequested?.length || !!existingAppRequests?.length,
		[existingAppRequests?.length, newAppsRequested?.length]
	);

	const cancelCreateNewApplicationRequest = React.useCallback(
		async (appId: string) => {
			setCreatedAppRemoving(appId);
			const response = await removeUserRequestedNewApplications(appId);
			if (response?.toLowerCase() === ResponseMessage.Success.toLowerCase()) {
				await getUser();
				setCreatedAppRemoving("");
			} else {
				console.log(response);
			}
		},
		[getUser, removeUserRequestedNewApplications]
	);

	const cancelExistingAppRequest = React.useCallback(
		async (appId: string) => {
			setExistingAppRequestRemoving(appId);
			const response = await cancelApplicationRequest(appId);
			if (response?.toLowerCase() === ResponseMessage.Success.toLowerCase()) {
				await getUser();
				setExistingAppRequestRemoving("");
			} else {
				console.log(response);
			}
		},
		[cancelApplicationRequest, getUser]
	);

	React.useEffect(() => {
		if (userData) {
			setUser(userData);
		}
	}, [userData, setUser]);

	const onJoinExistingAppClick = React.useCallback(() => {
		navigate(`${ROUTE_ONBOARDING}?action=${ONBOARDING_ACTION_REQUEST_JOIN_EXISTINF_APP}`);
	}, [navigate]);

	const onCreateNewAppClick = React.useCallback(() => {
		navigate(`${ROUTE_ONBOARDING}?action=${ONBOARDING_ACTION_REQUEST_CREATE_NEW_APP}`);
	}, [navigate]);

	return (
		<Layout
			currentUser={currentUser}
			showApplicationModal={showApplicationModal}
			setShowApplicationModal={setShowApplicationModal}
			toast={toast}
			setToast={setToast}
			existingAppRequests={existingAppRequests}
			newAppsRequested={newAppsRequested}
			hasRequests={hasRequests}
			cancelCreateNewApplicationRequest={cancelCreateNewApplicationRequest}
			cancelExistingAppRequest={cancelExistingAppRequest}
			cancelExistingAppRequestLoading={cancelExistingAppRequestLoading}
			cancelNewlyCreatedAppLoading={cancelNewlyCreatedAppLoading}
			onJoinExistingAppClick={onJoinExistingAppClick}
			onCreateNewAppClick={onCreateNewAppClick}
			createdAppRemoving={createdAppRemoving}
			existingAppRequestRemoving={existingAppRequestRemoving}
			userLoading={userLoading}
			applications={data}
			applicationsLoading={loading}
		/>
	);
};

export default FirstTimeUser;
