import React, { useEffect } from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import { Autocomplete, TextField } from "@mui/material";

import Hyperlink from "@ingka/hyperlink";
import Checkbox from "@ingka/checkbox";
import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";

import ContentBox from "../../../ContentBox/ContentBox";
import useGetUserSuggestions from "../../../../hooks/useGetUserSuggestions";
import { AutocompleteStyles } from "../../../styles";
import { FLAGGA_ONBOARDING_DOCUMENTATION_URL } from "../../../../utils/constants";
import { ValidationProps } from "../../types";
import { useIsFlaggaAdmin } from "../../../../hooks/useIsFlaggaAdmin";

export const StyledInputLabel = styled.label`
	font-size: 0.875rem;
	line-height: 1.571;
`;

export const StyledFormFieldWrapper = styled.div`
	margin-bottom: 2rem;
`;

interface AdminNameProps extends ValidationProps {}

const AdminName: React.FC<AdminNameProps> = ({ value, valid, validation, handleChange, shouldValidate }) => {
	const { getSuggestions, data: userSuggestions, error, loading, noOptionsText } = useGetUserSuggestions();
	const { isFlaggaAdmin } = useIsFlaggaAdmin();

	const [open, setOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		value.email &&
			userSuggestions?.length &&
			handleChange(userSuggestions?.find((user) => user.email === value.email)?.name?.split("(")[0] || "", "userName");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSuggestions, value.email]);

	return (
		<>
			<ContentBox>
				<FormField
					valid={valid.hasReadDocumentation}
					shouldValidate={shouldValidate.hasReadDocumentation}
					validation={validation.hasReadDocumentation}
					fieldHelper={{ id: "hasReadDocsValIdationHelper" }}
				>
					<Checkbox
						id="hasReadDocumentation"
						label={
							<>
								Yes, I've read the documentation about{" "}
								<Hyperlink text="Flagga Onboarding" url={FLAGGA_ONBOARDING_DOCUMENTATION_URL} newWindow />
							</>
						}
						checked={value.hasReadDocumentation}
						value={""}
						onChange={(e) => {
							handleChange(e.target.checked, "hasReadDocumentation");
						}}
					/>
				</FormField>{" "}
				{!isFlaggaAdmin && (
					<FormField
						valid={valid.email}
						shouldValidate={shouldValidate.email}
						disabled={true}
						validation={validation.email}
						fieldHelper={{ msg: "Application Admin's Email Address.", id: "emailValidationHelper" }}
					>
						<InputField req={true} id="email" value={value.email} disabled={true} label="Admin Email *" type="text" />
					</FormField>
				)}
				{isFlaggaAdmin && (
					<>
						<StyledInputLabel htmlFor="adminInput" title="Admin email *">
							Admin email *
						</StyledInputLabel>
						<Autocomplete
							id="adminInput"
							onInputChange={debounce((e, value) => {
								getSuggestions(value?.toLowerCase());
							}, 300)}
							options={userSuggestions.map((user) => user.email)}
							loading={loading}
							renderInput={(params) => (
								<TextField {...params} placeholder="Please enter user name/email" variant="outlined" />
							)}
							onChange={(_, adminEmailValue) => {
								handleChange(adminEmailValue || "", "email");
							}}
							value={value.email}
							sx={AutocompleteStyles}
							isOptionEqualToValue={(option, value) => option === value}
							noOptionsText={noOptionsText}
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
						/>
					</>
				)}
			</ContentBox>
		</>
	);
};

export default AdminName;
