import * as React from "react";
import { getMarkets } from "../services/markets";
import useStore, { userSelector, activeApplicationSelector } from "../store";
import useAuthentication from "./useAuthentication";

export interface MarketsData {
	isAdmin: boolean;
	permittedMarkets?: string[];
	otherMarkets?: string[];
}

export interface GetMarketsArgs {
	appId?: string;
	isOnboarding?: boolean;
}

const useGetMarkets = () => {
	const [loading, setLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<string | undefined>(undefined);
	const [data, setData] = React.useState<MarketsData | undefined>(undefined);
	const { getToken, handleLogout } = useAuthentication();

	const getMarketsData = React.useCallback(
		async (appId: string, isOnboarding: boolean = false) => {
			try {
				const token = await getToken();
				const authHeader = {
					headers: {
						authorization: `Bearer ${token}`,
					},
				};
				setLoading(true);
				const { data: marketData } = await getMarkets(authHeader, appId, isOnboarding);
				setData({
					otherMarkets: marketData.data.markets?.otherMarkets?.length
						? marketData.data.markets?.otherMarkets
						: undefined,
					permittedMarkets: marketData.data.markets?.permittedMarkets?.length
						? marketData.data.markets.permittedMarkets
						: undefined,
					isAdmin: marketData.data.admin || false,
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				});
			} catch (error: any) {
				if (error) {
					setData(undefined);
					setLoading(false);
					if (error?.response?.data?.message === "something went wrong") {
						setError("Can't fetch Markets, something went wrong");
					} else setError(error?.response?.data?.message as string);
					if (error?.response?.status == 401) {
						handleLogout();
					}
				}
			} finally {
				setLoading(false);
			}
		},
		[getToken, handleLogout]
	);

	return { data, loading, setData, error, getMarketsData };
};

export default useGetMarkets;
