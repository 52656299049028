import { AxiosResponse } from "axios";
import axios from "../../configs/axios";
import { User } from "../../store";
import { AuthHeader } from "../../types";
import { UserPermissions } from "../../utils/constants";

export interface UserMarketPermissions {
	[key: string]: string;
}

export interface UserApplications {
	[key: string]: {
		markets?: UserMarketPermissions | undefined;
		existingMarkets?: string[];
		permissions?: string;
		role?: string;
		requestedMarkets?: string[];
		requestedPermissions?: string;
		canOnlyEnableProperties?: boolean;
	};
}

export interface ApplicationUserRequests {
	id: string;
	email: string;
	name: string;
	requestType: "application" | "markets" | "role";
	requestedMarkets?: string[];
	existingMarkets?: string[];
	requestedRole?: UserPermissions;
}

export interface ApplicationOnboardedUsers {
	id: string;
	email: string;
	name: string;
	isApplicationAdmin: boolean;
	applications: UserApplications;
}

export interface ApplicationUserData {
	requests: ApplicationUserRequests[];
	onboardedUsers: ApplicationOnboardedUsers[];
}

interface UserResponseData {
	message: string;
	data: User;
}

export interface UserData {
	id: string;
	name?: string;
	applications: UserApplications;
	"flagga-admin"?: boolean;
	email?: string;
	isApplicationAdmin?: boolean;
}

export interface UserApplicationData {
	id: string;
	name?: string;
	applications: UserApplications;
	"flagga-admin"?: boolean;
	email?: string;
	isApplicationAdmin?: boolean;
	permissions?: string;
}

export interface UserInApplication {
	application: string;
}

export interface NoApplicationAccess {
	no_application_access: boolean | 0 | null;
}

export interface FlaggaAdmin {
	flagga_admin: boolean;
}

export interface UserSearchQuery {
	q: string;
	excluded_application?: string;
}

export type UsersListQueryParams = UserInApplication | NoApplicationAccess | FlaggaAdmin | UserSearchQuery;

interface UsersListResponseData {
	data: { users: UserData[] };
}

interface ApplicationUsersListResponseData {
	data: ApplicationUserData;
}
interface UpdateUserResponse {
	message: string;
}

interface ApplicationAdminsResponseData {
	data: UserData[];
}

export interface NewApplicationRequestData {
	id: string;
	name: string;
}

interface NewApplicationRequestsResponseData {
	data: NewApplicationRequestData[];
}

interface UserRequestData extends AuthHeader {}

export const getLoggedInUser = async (token: string) => {
	const userData: AxiosResponse<UserResponseData, UserRequestData> = await axios.get("/users/me", {
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
	return userData;
};

export const getUsersList = async (token: string, queryParams?: UsersListQueryParams) => {
	const userData: AxiosResponse<UsersListResponseData, UserRequestData> = await axios.get("/users", {
		headers: {
			authorization: `Bearer ${token}`,
		},
		params: queryParams,
	});
	return userData;
};

export const getAppUsersList = async (token: string, appId: string | undefined) => {
	const userData: AxiosResponse<ApplicationUsersListResponseData, UserRequestData> = await axios.get(
		`application/${appId}/users`,
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
	return userData;
};

export const getAppUsersMarketList = async (token: string, appId: string | undefined, market: string) => {
	const userData: AxiosResponse<ApplicationUsersListResponseData, UserRequestData> = await axios.get(
		`application/${appId}/market/${market}/users`,
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
	return userData;
};

export const getAppAdmins = async (token: string, appId: string | undefined) => {
	const userData: AxiosResponse<ApplicationAdminsResponseData, UserData> = await axios.get(
		`application/${appId}/admins`,
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
	return userData;
};

export const getRequestRoles = async (header: AuthHeader, appId: string) => {
	const rolesData: AxiosResponse<UserPermissions[], UserRequestData> = await axios.get(
		`/application/${appId}/role/request`,
		{
			...header,
		}
	);
	return rolesData;
};

export const requestNewRole = async (
	header: AuthHeader,
	appId: string,
	role: UserPermissions,
	markets?: UserMarketPermissions | string[]
) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		`/application/${appId}/role/request`,
		{ role, markets },
		{
			...header,
		}
	);
	return userData;
};

export const updateUserData = async (
	token: string,
	applications: any,
	user: UserData,
	requestType?: "application" | "markets" | "role"
) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		"/users/applications",
		{ applications, user, requestType },
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
	return userData;
};

export const updateEmailConsent = async (header: AuthHeader, emailConsentAccepted: boolean) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		"/users/consent",
		{ emailConsentAccepted },
		{
			...header,
		}
	);
	return userData;
};

export const removeUser = async (token: string, userId: string) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.delete(`/users/remove/${userId}`, {
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
	return userData;
};

export const removeMe = async (token: string) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.delete("/users/remove/me", {
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
	return userData;
};

export const declineApplicationAccessRequest = async (
	header: AuthHeader,
	email: string | undefined,
	reason: string,
	applicationId: string | undefined
) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		`/application/${applicationId}/access/request/decline`,
		{ email, reason },
		{
			...header,
		}
	);
	return userData;
};

export const cancelApplicationAccessRequest = async (header: AuthHeader, applicationId: string | undefined) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.delete(
		`/application/${applicationId}/access/request/cancel`,
		{
			...header,
		}
	);
	return userData;
};

export const declineMarketsRequest = async (
	header: AuthHeader,
	email: string | undefined,
	reason: string,
	applicationId: string | undefined
) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		`/application/${applicationId}/markets/access/request/decline`,
		{ email, reason },
		{
			...header,
		}
	);
	return userData;
};

export const cancelMarketsRequest = async (header: AuthHeader, applicationId: string | undefined) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.delete(
		`/application/${applicationId}/markets/access/request/cancel`,
		{
			...header,
		}
	);
	return userData;
};

export const declineRoleRequest = async (
	header: AuthHeader,
	email: string | undefined,
	reason: string,
	applicationId: string | undefined
) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		`/application/${applicationId}/role/request/decline`,
		{ email, reason },
		{
			...header,
		}
	);
	return userData;
};

export const cancelRoleRequest = async (header: AuthHeader, applicationId: string | undefined) => {
	const userData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.delete(
		`/application/${applicationId}/role/request/cancel`,
		{
			...header,
		}
	);
	return userData;
};

export const getNewApplicationRequested = async (header: AuthHeader) => {
	const appData: AxiosResponse<NewApplicationRequestsResponseData, UserRequestData> = await axios.get(
		"/users/requests/new-applications",
		{
			...header,
		}
	);
	return appData;
};

export const cancelNewApplicationRequest = async (header: AuthHeader, appId: string) => {
	const appData: AxiosResponse<UpdateUserResponse, UserRequestData> = await axios.post(
		`/application/request/${appId}/cancel`,
		{},
		{
			...header,
		}
	);
	return appData;
};
