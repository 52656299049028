import * as React from "react";
import styled from "styled-components";

import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";
import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";
import { colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";
import Switch from "@ingka/switch";

import { Schema } from "../../utils/types";
import * as helpers from "../../utils/helpers";
import { StyledDivider } from "../../../styles";

interface ModalLayoutProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	schema: any;
	onChange: (schema: Schema) => void;
	schemaKey: string;
}

interface DashboardProperties {
	dashboard: {
		label: string;
		market: boolean;
		global: boolean;
		isVisible: boolean;
		groupId: string;
		columnIndex: number;
		groupIdIndex: number;
	};
}

const StyledModalBody = styled(ModalBody)`
	padding: 1rem 3rem;
	.switch {
		padding-top: 0.5rem;
	}
	.inline-message {
		margin: 1rem 0;
	}
`;

const StyledToggleWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	margin-bottom: 1rem;
`;

const StyledHeader = styled(ModalHeader)`
	background-color: ${colourStaticIkeaBrandYellow};
`;

const DashboardSwitchWrapper = styled.div`
	margin-bottom: 1rem;
`;

const Layout: React.FC<ModalLayoutProps> = ({ showModal, setShowModal, schema, onChange, schemaKey }) => {
	const [dashboardEnabled, setDashboardEnabled] = React.useState<boolean>(false);

	React.useEffect(() => {
		schema.dashboard && setDashboardEnabled(true);
	}, [schema.dashboard]);

	return (
		<Modal focusLockProps={{ locked: false }} visible={showModal} handleCloseBtn={() => setShowModal(false)}>
			<Sheets footer={<></>} header={<StyledHeader title={`Dashboard configuration: ${schemaKey}`} />}>
				<StyledModalBody>
					<>
						<DashboardSwitchWrapper>
							<FormField>
								<Switch
									id={"enable-dashboard"}
									label="Enable Dashboard Properties"
									value={""}
									defaultChecked={!!schema.dashboard}
									onChange={(e) => {
										if (!schema.dashboard) {
											onChange(helpers.addDashboardOptions(schema));
											setDashboardEnabled(true);
										}
										if (schema.dashboard && !e.target.checked) {
											onChange(helpers.removeDashboardOptions(schema));
										}
										setDashboardEnabled(e.target.checked ? true : false);
									}}
								/>
							</FormField>
							<StyledDivider />
						</DashboardSwitchWrapper>
						<FormField>
							<InputField
								label="Label"
								id={"dashboardLabelVal"}
								type="text"
								disabled={!dashboardEnabled}
								value={schema.dashboard?.label}
								onChange={({ target: { value } }) => {
									onChange(helpers.setDashboardProperty(schema, "label", value));
								}}
							/>
						</FormField>
						<FormField>
							<InputField
								label="GroupId"
								id={"dashboardGroupIdVal"}
								type="text"
								disabled={!dashboardEnabled}
								value={schema.dashboard?.groupId}
								onChange={({ target: { value } }) => {
									onChange(helpers.setDashboardProperty(schema, "groupId", value));
								}}
							/>
						</FormField>

						<StyledToggleWrapper>
							<Switch
								id="market-dasboard-switch"
								value=""
								label="Enable for Market Dashboard"
								disabled={!dashboardEnabled}
								onChange={({ target: { checked } }) => {
									onChange(helpers.setDashboardProperty(schema, "market", checked));
								}}
								checked={schema.das}
								defaultChecked={schema.dashboard?.market}
							/>
						</StyledToggleWrapper>
						{schema.type === "boolean" && (
							<>
								<StyledToggleWrapper>
									<Switch
										id="global-dashboard-switch"
										value=""
										label="Enable for Global Dashboard"
										disabled={!dashboardEnabled}
										onChange={({ target: { checked } }) => {
											onChange(helpers.setDashboardProperty(schema, "global", checked));
										}}
										checked={schema.dashboard?.global}
										defaultChecked={schema.dashboard?.global}
									/>
								</StyledToggleWrapper>

								<StyledToggleWrapper>
									<Switch
										id="initially-visible-switch"
										value=""
										label="Initially Visible"
										disabled={!dashboardEnabled || !schema?.dashboard?.global}
										onChange={({ target: { checked } }) => {
											onChange(helpers.setDashboardProperty(schema, "isVisible", checked));
										}}
										checked={schema.dashboard?.isVisible}
										defaultChecked={schema.dashboard?.isVisible}
									/>
								</StyledToggleWrapper>
								<FormField>
									<InputField
										label="Column Index"
										id={"columnIndexVal"}
										type="number"
										value={schema.dashboard?.columnIndex}
										disabled={!dashboardEnabled || !schema?.dashboard?.global}
										onChange={({ target: { value } }) => {
											onChange(helpers.setDashboardProperty(schema, "columnIndex", value));
										}}
									/>
								</FormField>
								<FormField>
									<InputField
										label="GroupId Index"
										id={"groupIdIndexVal"}
										type="number"
										value={schema.dashboard?.groupIdIndex}
										disabled={!dashboardEnabled || !schema?.dashboard?.global}
										onChange={({ target: { value } }) => {
											onChange(helpers.setDashboardProperty(schema, "groupIdIndex", value));
										}}
									/>
								</FormField>
							</>
						)}
					</>
				</StyledModalBody>
			</Sheets>
		</Modal>
	);
};

export default Layout;
