import {
	getUiSchemaList,
	updateSchemas,
	getApplicationFromOtherEnv,
	importSchemaAndUISchema,
} from "../../services/applications";
import { Application } from "../../store";
import { ApiResponseMessages } from "./constants";

interface UpdateSchemaData {
	getToken: () => Promise<string>;
	activeApplication: Application;
	setToastMessage: (val: string) => void;
	schemaData: any;
	reason?: string;
	setSaveBtnLoading: (val: boolean) => void;
}

interface GetApplicationsDataFromOtheEnv {
	getToken: () => Promise<string>;
	activeApplication: Application;
	env: string;
	setDataLoaded: (val: boolean) => void;
	setLoadingSchema: (val: boolean) => void;
	setSchemaList: (val: any) => void;
	setErrorCode: (code: number) => void;
}

interface ImportSchemaAndUISchemaData {
	getToken: () => Promise<string>;
	activeApplication: Application;
	schemaList: string[] | undefined;
	uiSchemas: any;
	selectedEnv: string;
	setImportingSchemaUISchema: (val: boolean) => void;
	setShowModal: (val: boolean) => void;
	setSelectedEnv: (val: string) => void;
	setToastMessage: (val: string) => void;
	setImportedSchema: (val: boolean) => void;
}

interface AnyObject {
    [key: string]: any;
}

export const handleImportSchemaAndUISchema = async ({
	activeApplication,
	schemaList,
	uiSchemas,
	getToken,
	selectedEnv,
	setImportingSchemaUISchema,
	setShowModal,
	setSelectedEnv,
	setToastMessage,
	setImportedSchema,
}: ImportSchemaAndUISchemaData) => {
	if (activeApplication) {
		setImportingSchemaUISchema(true);
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};

		schemaList = (schemaList || []).filter((item:string) => item == "schema" || uiSchemas.includes(item));

		try {
			await importSchemaAndUISchema(authHeader, activeApplication?.id, schemaList, selectedEnv);
			setToastMessage("Data was successfully imported");
			setImportedSchema(true);
		} catch (error: any) {
			setToastMessage(error?.response?.data?.message
				|| "Failed to import data");
			console.error(error);
		} finally {
			setImportingSchemaUISchema(false);
			setShowModal(false);
			setSelectedEnv("");
		}
	}
};

export const getApplicationDataFromOtherEnv = async ({
	getToken,
	activeApplication,
	env,
	setDataLoaded,
	setLoadingSchema,
	setSchemaList,
	setErrorCode,
}: GetApplicationsDataFromOtheEnv) => {
	if (activeApplication) {
		setDataLoaded(false);
		setLoadingSchema(true);
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		try {
			const { data } = await getApplicationFromOtherEnv(authHeader, activeApplication?.id, env);
			setSchemaList(data.data.schemaList);
		} catch (error: any) {
			console.error(error);
			setErrorCode(error.response.status);
			setSchemaList(undefined);
		} finally {
			setDataLoaded(true);
			setLoadingSchema(false);
		}
	}
};

export const updateSchemaData = async ({
	getToken,
	activeApplication,
	setToastMessage,
	schemaData,
	reason,
	setSaveBtnLoading,
}: UpdateSchemaData) => {
	if (activeApplication) {
		const token = await getToken();
		const authHeader = {
			headers: {
				authorization: `Bearer ${token}`,
			},
		};
		try {
			const { data } = await updateSchemas(authHeader, schemaData, activeApplication?.id, reason);
			if (data.message === ApiResponseMessages.Success || data.message === "success") {
				const { data: UiSchemaData } = await getUiSchemaList(authHeader, activeApplication?.id, {
					uiSchema: true,
					getSchema: true,
				});
				return { schema: UiSchemaData.data.schema, uiSchemas: UiSchemaData.data["ui-schemas"] };
			}
		} catch (error: any) {
			setSaveBtnLoading(false);
			setToastMessage(error?.response?.data?.message);
			console.error(error);
		}
	}
};

export const isInvalidSchema = (schema: any): boolean => {
	if (typeof schema["oneOf"] === "undefined") {
		if (schema["properties"] && !schema["type"]) {
			return true;
		} else if (schema["type"] == "array") {
			if (schema["items"]) {
				if (isInvalidSchema(schema["items"])) {
					return true;
				}
			} else {
				return true;
			}
		} else {
			if (schema["properties"]) {
				for (let key in schema["properties"]) {
					if (key != "dashboard" && key != "default" && key != "options") {
						if (isInvalidSchema(schema["properties"][key])) {
							return true;
						}
					}
				}
			} else {
				if (!schema["type"]) {
					return true;
				}
			}
		}
	}

	return false;
};

export const sortObjectByKey = (obj: any): any => {
	// Base case: if it's not an object or is null, return it directly
	if (typeof obj !== "object" || obj === null) {
		return obj;
	}

	// Arrays are sorted based on their elements
	if (Array.isArray(obj)) {
		return obj.map(sortObjectByKey);
	}
	// Create a new sorted object
	const sortedObj: { [key: string]: any } = {};
	Object.keys(obj)
		.sort((a, b) => {
			const nameA = a.toUpperCase(); // ignore upper and lowercase
			const nameB = b.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// keys must be equal
			return 0;
		})
		.forEach((key) => {
			// Recursively sort each property
			sortedObj[key] = sortObjectByKey(obj[key]);
		});

	return sortedObj;
};

// Recursive function to sort object properties by 'type'
export const sortObjectByType = (obj: any): any => {
	if (typeof obj !== "object" || obj === null) {
		return obj; // Base case: return non-object values directly
	}

	// Arrays are sorted based on their elements
	if (Array.isArray(obj)) {
		return obj.map(sortObjectByType);
	}

	// Sort object keys by 'type' property if it exists
	const sortedKeys = Object.keys(obj).sort((a, b) => {
		if (typeof obj[a]?.type === "string" && typeof obj[b]?.type === "string") {
			const typeA = obj[a]?.type || "";
			const typeB = obj[b]?.type || "";
			return typeA.localeCompare(typeB); // Sort keys lexicographically by 'type'
		} 
	});

	// Create a new object to store sorted properties
	const sortedObject = {} as any;
	sortedKeys.forEach(key => {
		sortedObject[key] = sortObjectByType(obj[key]); // Recursively sort nested objects
	});

	return sortedObject;
};

export const findEmptyOneOf = (obj: AnyObject): boolean => {
	// Check if object is an object
	if (typeof obj !== "object" || obj === null) {
        return false;
    }

    // If obj has oneOf property and it's an array and is empty, return true
    if (Object.prototype.hasOwnProperty.call(obj, "oneOf") && Array.isArray(obj.oneOf) && obj.oneOf.length === 0) {
        return true; // Return true indicating oneOf is empty
    }

    // Recursively check nested properties
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // Recursively check nested properties for oneOf
            if (findEmptyOneOf(obj[key])) {
                return true; // If oneOf is found and not empty, return true
            }
        }
    }
    // If no empty oneOf is found, return false
    return false;
};