import * as React from "react";
import useStore, { activeApplicationSelector, userSelector } from "../store";

export const useIsUserCanOnlyEnableProperties = () => {
	const currentUser = useStore(userSelector);
	const activeApplication = useStore(activeApplicationSelector);
	const userCanOnlyEnableProperties = React.useMemo(
		() =>
			!!currentUser &&
			activeApplication &&
			!currentUser.user["flagga-admin"] &&
			currentUser.user.applications[activeApplication.id]?.canOnlyEnableProperties,
		[activeApplication, currentUser]
	);
	return userCanOnlyEnableProperties;
};
