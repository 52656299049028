import * as React from "react";

import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import styled from "styled-components";
import Button from "@ingka/button";
import { colourStaticIkeaBrandYellow } from "@ingka/variables/colours-css";
import TextArea from "@ingka/text-area";
import FormField from "@ingka/form-field";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import Checkbox from "@ingka/checkbox";
import Toast from "@ingka/toast";
import InlineMessage from "@ingka/inline-message";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";

const StyledModalHeader = styled(ModalHeader)`
	background-color: ${colourStaticIkeaBrandYellow};
`;

const StyledTextArea = styled(TextArea)`
	margin-top: 2rem;

	label {
		display: flex;
	}
`;

const StyledInlineMessage = styled(InlineMessage)`
	margin-bottom: 1.5rem;
`;

interface ScheduleChangesModalProps {
	showScheduleChangesModal: boolean;
	setShowScheduleChangesModal: (val: boolean) => void;
	schedulingComment: string;
	setSchedulingComment: (val: string) => void;
	sendCopyEmail: boolean;
	setSendCopyEmail: (val: boolean) => void;
	sendScheduleChangesLoading: boolean;
	setSendScheduleChangesLoading: (val: boolean) => void;
	sendScheduleChanges: () => void;
	scheduleDate: Dayjs | null;
	setScheduleDate: (val: Dayjs | null) => void;
	scheduleToastMessage: string;
	setScheduleToastMessage: (val: string) => void;
}

const ScheduleChangesModal: React.FC<ScheduleChangesModalProps> = ({
	showScheduleChangesModal,
	setShowScheduleChangesModal,
	schedulingComment,
	setSchedulingComment,
	sendCopyEmail,
	setSendCopyEmail,
	sendScheduleChangesLoading,
	setSendScheduleChangesLoading,
	sendScheduleChanges,
	scheduleDate,
	setScheduleDate,
	scheduleToastMessage,
	setScheduleToastMessage,
}) => {
	const minDate = new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1);

	return (
		<>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showScheduleChangesModal}
				escapable={false}
				handleCloseBtn={() => setShowScheduleChangesModal(false)}
			>
				<Sheets
					id="new-ui-schema-modal"
					title=""
					header={<StyledModalHeader title="Choose your date and time to schedule changes" />}
					footer={
						<ModalFooter>
							<Button
								small
								text="Cancel"
								type="secondary"
								onClick={() => {
									setScheduleDate(null);
									setShowScheduleChangesModal(false);
									setSchedulingComment("");
								}}
							/>
							<Button
								id="schedule-changes-button"
								small
								text="Send"
								disabled={scheduleDate === null}
								loading={sendScheduleChangesLoading}
								type="emphasised"
								onClick={sendScheduleChanges}
							/>
						</ModalFooter>
					}
				>
					<ModalBody>
						<div id="date-picker">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									data-testid="schedule-time"
									label="Schedule time"
									value={scheduleDate}
									minutesStep={15}
									onChange={(newValue) => setScheduleDate(newValue)}
									minDateTime={dayjs(minDate)}
									slotProps={{
										textField: {
											helperText: "Time is selected in local timezone",
										},
									}}
								/>
							</LocalizationProvider>
						</div>
						<FormField>
							<StyledTextArea
								id="open-feedback"
								label="Comments"
								rows={8}
								value={schedulingComment}
								onChange={(e) => {
									setSchedulingComment(e.target.value);
								}}
							/>
						</FormField>
						<StyledInlineMessage
							body={
								<>
									Scheduled changes <strong>can't</strong> be removed/modified before <strong>30min</strong> of
									scheduled time
								</>
							}
							ssrIcon={informationCircle}
							variant="informative"
							subtle
						/>
						<FormField>
							<Checkbox
								id="send-copy-email-scheduling"
								label="Send me a copy of the email"
								value={sendCopyEmail.toString()}
								checked={sendCopyEmail}
								onChange={(ev) => setSendCopyEmail(ev.target.checked)}
							/>
						</FormField>
					</ModalBody>
				</Sheets>
			</Modal>
			<Toast
				data-testid="message"
				text={scheduleToastMessage}
				isOpen={!!scheduleToastMessage}
				onCloseRequest={() => setScheduleToastMessage("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default ScheduleChangesModal;
