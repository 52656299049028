import styled from "styled-components";
import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Button from "@ingka/button";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import WarningModal from "../../WarningModal/WarningModal";
import { User } from "../../../store";

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

const ModalButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
`;

interface ManageSchedulingDateTimePickerProps {
	scheduledTime: number | null;
	scheduledChangeId: string;
	onRemoveScheduledChanges: (id: string) => void;
	onUpdateScheduledChanges: (changeId: string, updatedDate: string) => void;
	updateScheduledChangesLoading: boolean;
	setUpdateScheduledChangesLoading: (value: boolean) => void;
	isAdmin: boolean;
	currentUser: User | null;
	lastUpdatedBy: string;
}

const ManageSchedulingDateTimePicker: React.FC<ManageSchedulingDateTimePickerProps> = ({
	scheduledTime,
	scheduledChangeId,
	lastUpdatedBy,
	onRemoveScheduledChanges,
	onUpdateScheduledChanges,
	updateScheduledChangesLoading,
	setUpdateScheduledChangesLoading,
	isAdmin,
	currentUser,
}) => {
	const [updatedScheduledTime, setUpdatedScheduledTime] = React.useState<Dayjs | null>(null);
	const [showWarningModal, setShowWarningModal] = React.useState<boolean>(false);
	const minDate = new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1);
	const [dateUpdated, setDateUpdated] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (dayjs(updatedScheduledTime).format() !== dayjs(scheduledTime).format()) {
			setDateUpdated(true);
		} else {
			setDateUpdated(false);
		}
	}, [updatedScheduledTime, setUpdatedScheduledTime, scheduledTime]);

	React.useEffect(() => {
		if (scheduledTime) {
			setUpdatedScheduledTime(dayjs(scheduledTime));
		}
	}, [scheduledTime]);

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DateTimePicker
					disabled={!isAdmin && currentUser?.user?.email !== lastUpdatedBy}
					label="Select time"
					value={updatedScheduledTime}
					minutesStep={15}
					onChange={(newValue) => setUpdatedScheduledTime(newValue)}
					minDateTime={dayjs(minDate)}
					slotProps={{
						textField: {
							helperText: "Time is selected in local timezone",
						},
					}}
				/>
			</LocalizationProvider>
			<ButtonWrapper>
				<Button
					text="Remove"
					disabled={!isAdmin && currentUser?.user?.email !== lastUpdatedBy}
					onClick={() => setShowWarningModal(true)}
				/>
				{dateUpdated && (
					<Button
						text="Save"
						type="emphasised"
						loading={updateScheduledChangesLoading}
						onClick={() => {
							setUpdateScheduledChangesLoading(true);
							onUpdateScheduledChanges(scheduledChangeId, dayjs(updatedScheduledTime).format());
						}}
					/>
				)}
			</ButtonWrapper>
			<WarningModal
				modalId={"remove-change-dialog"}
				modalTitle={"Warning"}
				showWarningModal={showWarningModal}
				setShowWarningModal={setShowWarningModal}
				children={
					<>
						<p>Are you sure you want to delete scheduled changes?</p>
						<ModalButtonWrapper>
							<Button small text="Cancel" type="emphasised" onClick={() => setShowWarningModal(false)} />
							<Button
								small
								text="OK"
								onClick={() => {
									onRemoveScheduledChanges(scheduledChangeId);
								}}
							/>
						</ModalButtonWrapper>
					</>
				}
				variant="cautionary"
				dismissable={false}
			/>
		</>
	);
};

export default ManageSchedulingDateTimePicker;
