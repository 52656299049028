import * as React from "react";
import styled from "styled-components";

import ListBox from "@ingka/list-box";
import Button from "@ingka/button";
import Tooltip from "@ingka/tooltip";
import { breakpointL } from "@ingka/variables/design-tokens";
import SSRIcon from "@ingka/ssr-icon";
import chevronDownSmall from "@ingka/ssr-icon/paths/chevron-down-small";
import chevronUpSmall from "@ingka/ssr-icon/paths/chevron-up-small";

import { StyledListView } from "../styles";
import { Counter } from "../Counter/Counter";

export interface ButtonDropDownProps {
	id: string;
	children: React.ReactNode;
	text?: string;
	type?: any;
	ssrIcon: any;
	small: boolean;
	xsmallIconOnly?: boolean;
	open: boolean;
	setMenuOpen: (value: boolean) => void;
	className: string;
	showCounter?: boolean | null;
	counter?: number;
	noArrow?: boolean;
}

const ListViewWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledListBox = styled(ListBox)`
	margin-top: 0.5rem;
	z-index: 1000;
	width: 20rem;

	@media (width < ${breakpointL}) {
		width: 70%;
		right: 2rem;
	}
`;

const StyledCounter = styled(Counter)`
	margin-left: 0.5rem;
`;

const StyledIcon = styled(SSRIcon)`
	margin-left: 0.5rem;
`;

interface ButtonProps {
	open?: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`
	${({ open }) => {
		return open && "background-color: rgb(223, 223, 223);";
	}}
	.btn__inner {
		padding: 0 1.5rem;
	}
`;

const ButtonDropDown: React.FC<ButtonDropDownProps> = ({
	id,
	children,
	text,
	ssrIcon,
	small,
	xsmallIconOnly,
	type,
	open,
	setMenuOpen,
	className,
	showCounter,
	counter,
	noArrow
}) => {
	const ref = React.useRef<any>(null);

	const handleClose = React.useCallback(() => {
		setMenuOpen(false);
	}, [setMenuOpen]);

	const handleClick = React.useCallback(() => {
		setMenuOpen(true);
	}, [setMenuOpen]);

	const closeOpenMenus = React.useCallback(
		(e) => {
			if (!ref?.current?.contains(e.target)) {
				handleClose();
			}
		},
		[handleClose]
	);

	React.useEffect(() => {
		document.addEventListener("mousedown", closeOpenMenus);
		return () => {
			document.removeEventListener("mousedown", closeOpenMenus);
		};
	}, [closeOpenMenus]);

	React.useEffect(() => {
		document.addEventListener("mousedown", closeOpenMenus);
		return () => {
			document.removeEventListener("mousedown", closeOpenMenus);
		};
	}, [closeOpenMenus]);

	return (
		<div>
			<Tooltip tooltipText={text ? text : ""} >
				<StyledButton
					data-testid={text}
					text={text}
					type={type}
					onClick={handleClick}
					ssrIcon={ssrIcon}
					small={small}
					xsmallIconOnly={xsmallIconOnly}
					iconOnly={xsmallIconOnly}
					className={className}
					open={open}
				>
					{showCounter && <StyledCounter>{counter}</StyledCounter>}
					{!noArrow && <StyledIcon paths={open ? chevronUpSmall : chevronDownSmall} />}
				</StyledButton>
			</Tooltip>
			<StyledListBox open={open}>
				<ListViewWrapper ref={ref}>
					<StyledListView id={`${id}-list-box`} showDivider size="small">
						{children}
					</StyledListView>
				</ListViewWrapper>
			</StyledListBox>
		</div>
	);
};

export default ButtonDropDown;
