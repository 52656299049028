import * as React from "react";
import styled from "styled-components";
import { To, useLocation, useNavigate } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { colourSemanticInformative, colourTextAndIcon5 } from "@ingka/variables/colours-css";
import { breakpointL } from "@ingka/variables/design-tokens";
import Button from "@ingka/button";

import IkeaLogo from "../../assets/ikea-logo-white.svg";
import { loginRequest } from "../../authConfig";
import useStore, { setRouterStateFromSelector, routerStateFromSelector, userSelector } from "../../store";
import { ROUTE_HOME } from "../../utils/constants";
import useWindowSize from "../../hooks/useWindowSize";
import { FLAGGA_VIDEO_OVERVIEW_PAGE_URL } from "../../utils/constants";

const ContainerDiv = styled.div`
	@media (max-width: ${breakpointL}) {
		display: block;
	}
	display: flex;
	height: 100vh;
`;

const LeftContainer = styled.div`
	@media (max-width: ${breakpointL}) {
		height: 50%;
		min-height: 20rem;
	}
	overflow: hidden;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	background-color: ${colourSemanticInformative};
	position: relative;
	padding: 2rem;
`;

const LogoContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	gap: 2rem;
	margin: 2rem 0 0 4rem;
	width: 100%;
	top: 0;
`;

const StyledH3 = styled.h3`
	color: ${colourTextAndIcon5};
`;

const RightContainer = styled.div`
	@media (max-width: ${breakpointL}) {
		height: 50%;
		min-height: 20rem;
	}
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: 2rem;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

interface H1Props {
	color?: string;
}

const StyledH1 = styled.h1<H1Props>`
	${({ color }) => color && `color: ${color}`};
	text-align: center;
`;

const Login: React.FC = () => {
	const { instance } = useMsal();
	const user = useStore(userSelector);
	const setRouterStatefrom = useStore(setRouterStateFromSelector);
	const routerStateFrom = useStore(routerStateFromSelector);
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();
	const { isMobile } = useWindowSize();
	const location = useLocation();
	const from = location.state?.from;

	const handleLogin = React.useCallback(
		() => instance.loginRedirect(loginRequest).catch((err) => console.log(err)),
		[instance]
	);

	React.useEffect(() => {
		if (!isAuthenticated && from) {
			//after login clicked, user is redirected to microsoft login page so all location from which user came to login page will become null
			//storing the path from which the user came to login page in global state
			//so can redirect user after login
			setRouterStatefrom(from);
		}
	}, [from, isAuthenticated, setRouterStatefrom]);

	React.useEffect(() => {
		if (isAuthenticated && user) {
			navigate((`${routerStateFrom.pathname}${routerStateFrom.search}` || ROUTE_HOME) as To, { replace: true });
		}
	}, [from, isAuthenticated, navigate, routerStateFrom.pathname, routerStateFrom.search, user]);

	return (
		<>
			<ContainerDiv>
				<LeftContainer>
					<LogoContainer>
						<img src={IkeaLogo} />
						{isMobile ? null : <StyledH3>Flagga Configuration Tool</StyledH3>}
					</LogoContainer>
					<ContentWrapper>
						<StyledH1 color={colourTextAndIcon5}>
							Hej! <br />
							Welcome to FLAGGA
						</StyledH1>
						<Button
							id="flagga-login-button"
							type="emphasised"
							inverseTheme
							href={FLAGGA_VIDEO_OVERVIEW_PAGE_URL}
							newWindow
						>
							Get a Demo tour
						</Button>
					</ContentWrapper>
				</LeftContainer>
				<RightContainer>
					<ContentWrapper>
						<StyledH1>
							Skip tour and continue
							<br />
							Login
						</StyledH1>
						<Button data-testid="login" id="flagga-login-button" type="emphasised" onClick={handleLogin}>
							Login
						</Button>
					</ContentWrapper>
				</RightContainer>
			</ContainerDiv>
		</>
	);
};

export default Login;
