import * as React from "react";

import useGetApplications from "../../hooks/useGetApplications";
import { useIsFlaggaAdmin } from "../../hooks/useIsFlaggaAdmin";
import Layout from "./Layout";

const Dashboard: React.FC = () => {
	const { data: applicationsData, loading: applicationsLoading, getApplicationsData } = useGetApplications();
	React.useEffect(() => {
		getApplicationsData();
	}, [getApplicationsData]);
	const { isFlaggaAdmin } = useIsFlaggaAdmin();
	return (
		<Layout
			applicationsData={applicationsData}
			applicationsLoading={applicationsLoading}
			isFlaggaAdmin={isFlaggaAdmin}
		/>
	);
};

export default Dashboard;
