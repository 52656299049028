import React from "react";
import { RoleData } from "./RequestNewRoleModal";

import { StyledCommercialMessage, StyledDivider, StyledModalHeader } from "../styles";
import styled from "styled-components";

import { ListViewItem } from "@ingka/list-view";
import Modal, { ModalBody, ModalFooter, Prompt, Sheets } from "@ingka/modal";
import laptop from "@ingka/ssr-icon/paths/laptop";
import Skeleton from "@ingka/skeleton";
import Button from "@ingka/button";
import Toast from "@ingka/toast";
import InlineMessage from "@ingka/inline-message";

import { UserPermissions } from "../../utils/constants";
import { UserApplication } from "../../store";
import { isUserHasMarkets } from "../../utils/common";
import FlagsRow from "../FLagsRow";
import AdminRoleSelector from "./AdminRoleSelector";
import { UserMarketPermissions } from "../../services/users";
import MaintainerRoleSelector from "./MaintainerRoleSelector";
import ReadOnlyRoleSelector from "./ReadOnlyRoleSelector";

interface RequestNewRoleModalLayoutProps {
	showRequestNewRoleModal: boolean;
	setShowRequestNewRoleModal: (val: boolean) => void;
	setSelectedRole: (role: UserPermissions | null) => void;
	selectedRole: UserPermissions | null;
	maintainerMarkets: UserMarketPermissions;
	setMaintainerMarkets: (data: UserMarketPermissions) => void;
	loading: boolean;
	onRequestRole: () => void;
	requestingRole: boolean;
	toast: string;
	setToast: (msg: string) => void;
	readOnlyToMaintainerMarkets: string[];
	setReadOnlyToMaintainerMarkets: (data: string[]) => void;
	isRoleRequest: boolean;
	setRoleChangeConfirmationModalOpen: (data: boolean) => void;
	isEditingMarkets: boolean;
	setIsEditingMarkets: (data: boolean) => void;
	roleChangeConfirmationModalOpen: boolean;
	rolesData?: RoleData[];
	app?: UserApplication & {
		id: string;
	};
}

const ApplicationDetailWrapper = styled.div`
	margin-bottom: 2rem;
`;

const SkeletonWrapper = styled.div`
	display: flex;
	flex: 1;
	gap: 0.5rem;
`;

const MarketsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	align-items: center;
`;

const Layout: React.FC<RequestNewRoleModalLayoutProps> = ({
	rolesData,
	showRequestNewRoleModal,
	setShowRequestNewRoleModal,
	setSelectedRole,
	selectedRole,
	app,
	maintainerMarkets,
	setMaintainerMarkets,
	loading,
	onRequestRole,
	requestingRole,
	toast,
	setToast,
	readOnlyToMaintainerMarkets,
	setReadOnlyToMaintainerMarkets,
	isRoleRequest,
	setRoleChangeConfirmationModalOpen,
	roleChangeConfirmationModalOpen,
	isEditingMarkets,
	setIsEditingMarkets,
}) => {
	return (
		<>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showRequestNewRoleModal}
				handleCloseBtn={() => {
					setSelectedRole(null);
					setMaintainerMarkets({});
					setShowRequestNewRoleModal(false);
				}}
			>
				<Sheets
					footer={
						<ModalFooter>
							<Button
								id="request-role-button"
								text={isRoleRequest ? "Request Role" : "Change Role"}
								type="emphasised"
								onClick={() => (isRoleRequest ? onRequestRole() : setRoleChangeConfirmationModalOpen(true))}
								disabled={
									isEditingMarkets ||
									!selectedRole ||
									(app?.permissions === UserPermissions.Admin &&
										selectedRole === UserPermissions.Maintainer &&
										!Object.keys(maintainerMarkets as UserMarketPermissions).length) ||
									(app?.permissions === UserPermissions.Maintainer &&
										isUserHasMarkets(app?.markets) &&
										selectedRole === UserPermissions.Maintainer &&
										!readOnlyToMaintainerMarkets.length)
								}
								loading={requestingRole}
							/>
						</ModalFooter>
					}
					header={
						<StyledModalHeader
							title={"Request Change Role"}
							backBtnClick={() => {
								setSelectedRole(null);
								setMaintainerMarkets({});
								setShowRequestNewRoleModal(false);
							}}
						/>
					}
				>
					<ModalBody>
						<ApplicationDetailWrapper>
							<ListViewItem
								leadingIcon={laptop}
								showDivider={false}
								emphasised
								description={
									<>
										Role:{" "}
										{app?.permissions === "admin" ? (
											<StyledCommercialMessage message="Admin" variant="family" subtle />
										) : app?.permissions === UserPermissions.Maintainer && !Object.keys(app?.markets || {}).length ? (
											<StyledCommercialMessage message="Read Only" variant="new" subtle />
										) : (
											app?.permissions === UserPermissions.Maintainer &&
											isUserHasMarkets(app?.markets) && (
												<StyledCommercialMessage message="Maintainer" variant="nlp" subtle />
											)
										)}
									</>
								}
								addOn={
									app?.requestedPermissions && (
										<>
											<div>
												Requested Role:{" "}
												{app.requestedPermissions === "admin" ? (
													<StyledCommercialMessage message="Admin" variant="family" subtle />
												) : app.requestedPermissions === UserPermissions.ReadOnly ? (
													<StyledCommercialMessage message="Read Only" variant="new" subtle />
												) : (
													app.requestedPermissions === UserPermissions.Maintainer && (
														<StyledCommercialMessage message="Maintainer" variant="nlp" subtle />
													)
												)}
											</div>
											{app?.requestedPermissions === UserPermissions.Maintainer && (
												<MarketsWrapper>
													Requested Markets:{" "}
													<FlagsRow
														markets={app?.requestedMarkets || []}
														size={125}
														tooltipBoundaryContainerID="user-application-overview"
													/>
												</MarketsWrapper>
											)}
										</>
									)
								}
								controlIcon="chevronRightSmall"
								title={app?.applicationName}
							/>
							<StyledDivider />
						</ApplicationDetailWrapper>
						{loading ? (
							<>
								<SkeletonWrapper>
									<Skeleton height="10rem" />
									<Skeleton height="10rem" />
								</SkeletonWrapper>
							</>
						) : !rolesData ? (
							<InlineMessage
								variant="cautionary"
								title="Oops! Something went wrong."
								body="Enable to get roles to request. Please try after some time."
							/>
						) : rolesData && !rolesData.length ? (
							<InlineMessage
								title="Already requested a new role"
								body="You've already requested for a role. Please wait for the admins to accept your request."
							/>
						) : (
							<>
								<h3>Please select new role</h3>
								{app?.permissions === UserPermissions.Admin && (
									<AdminRoleSelector
										rolesData={rolesData}
										setSelectedRole={setSelectedRole}
										requestingRole={requestingRole}
										selectedRole={selectedRole}
										maintainerMarkets={maintainerMarkets}
										setMaintainerMarkets={setMaintainerMarkets}
										app={app}
										setIsEditingMarkets={setIsEditingMarkets}
									/>
								)}
								{app?.permissions === UserPermissions.Maintainer && isUserHasMarkets(app?.markets) && (
									<MaintainerRoleSelector
										rolesData={rolesData}
										setSelectedRole={setSelectedRole}
										selectedRole={selectedRole}
										requestingRole={requestingRole}
									/>
								)}
								{app?.permissions === UserPermissions.Maintainer && !isUserHasMarkets(app?.markets) && (
									<ReadOnlyRoleSelector
										app={app}
										rolesData={rolesData}
										setSelectedRole={setSelectedRole}
										selectedRole={selectedRole}
										requestingRole={requestingRole}
										readOnlyToMaintainerMarkets={readOnlyToMaintainerMarkets}
										setReadOnlyToMaintainerMarkets={setReadOnlyToMaintainerMarkets}
									/>
								)}
							</>
						)}
					</ModalBody>
				</Sheets>
			</Modal>
			<Modal visible={roleChangeConfirmationModalOpen} handleCloseBtn={() => setRoleChangeConfirmationModalOpen(false)}>
				<Prompt
					title={null}
					header={
						<StyledModalHeader
							title={"Confirm Change Role!"}
							backBtnClick={() => {
								setSelectedRole(null);
								setMaintainerMarkets({});
								setShowRequestNewRoleModal(false);
							}}
						/>
					}
					footer={
						<ModalFooter>
							<Button text="Continue" type="emphasised" onClick={onRequestRole} />
							<Button text="Cancel" onClick={() => setRoleChangeConfirmationModalOpen(false)} />
						</ModalFooter>
					}
					titleId={""}
				>
					<InlineMessage
						variant="cautionary"
						title={"You are about to change your current role. Please note the following"}
					/>
					<>
						<p>1. Once the role is changed, you cannot go back to the previous role.</p>
						<p>2. Make sure you understand the implications of the new role.</p>
						<p>3. If you proceed, you'll be bound by the permissions and attributes of the new role.</p>
						<p>Are you sure you want to continue?</p>
					</>
				</Prompt>
			</Modal>
			<Toast
				data-testid="message"
				text={toast}
				isOpen={!!toast}
				onCloseRequest={() => setToast("")}
				ariaLabelCloseBtn="Dismiss notification"
			/>
		</>
	);
};

export default Layout;
