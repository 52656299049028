import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { DatePicker as DatePickerComponent } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InputLabel, Tooltip } from "@mui/material";
import { Badge } from "@material-ui/core";
import { PickersDay } from "@mui/x-date-pickers";
import InputField from "@ingka/input-field";
import Skeleton from "@ingka/skeleton";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { colourSemanticInformative } from "@ingka/variables/colours-css";
import Toast from "@ingka/toast";
import Button from "@ingka/button";
import Modal, { ModalBody, Sheets } from "@ingka/modal";
import Select, { Option as SelectOption } from "@ingka/select";
import { ApplicationData } from "../../store";
import { ModalButtonWrapper, datePickerInputFieldStyles } from "../styles";
import { StyledModalHeader } from "../styles";
import { BACKUPRESTORETABS, ApplicationName } from "./types";
import InlineMessage from "@ingka/inline-message";
import Switch from "@ingka/switch";

interface BackupAndRestoreLayoutProps {
	showModal: boolean;
	setShowModal: (val: boolean) => void;
	applications: ApplicationName[];
	handleDateChange: (date: Dayjs | null) => void;
	handleAppChange: (e: any) => void;
	handleMonthChange: (date: Dayjs) => void;
	highlightedDays: number[];
	handleBackupDataChange: (e: any) => void;
	backupDataLoading: boolean;
	backupDatesLoading: boolean;
	restoreInProgress: boolean;
	handleRestoreData: () => void;
	handleBackUpData: () => void;
	toastMessage: string;
	setToastMessage: (value: string) => void;
	backupData?: string[];
	selectedDate?: Dayjs;
	minDate?: string;
	maxDate?: string;
	selectedApp?: string;
	selectedBackupData?: string;
	activeTab: string;
	setActiveTab: (value: string) => void;
	backupInProgress: boolean;
	isMarketBackup: boolean;
	setIsMarketBackup: (val: boolean) => void;
	marketList: { id: string; name: string }[];
	selectedMarket: string | undefined;
	setSelectedMarket: (val: string | undefined) => void;
	backupName: string | undefined;
	setBackupName: (val: string | undefined) => void;
	getMarketBackupList: (market: string) => void;
	backupNameList: string[];
	backupNameListLoading: boolean;
}

const StyledBadge = styled(Badge)`
	.MuiBadge-badge {
		background-color: ${colourSemanticInformative};
	}
`;

export const SwitchWraper = styled.div`
	margin: 1rem 0;
`;

const DatePickerContainer = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
`;

const BackupNameMargin = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;
`;

const randerBatch = (props: any) => {
	const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

	const isSelected = !props.outsideCurrentMonth && highlightedDays.includes(props.day.date());

	return (
		<Tooltip
			componentsProps={{
				tooltip: {
					sx: {
						bgcolor: "black",
					},
				},
			}}
			placement="top"
			title={isSelected && "Multiple backups on this date"}
		>
			<StyledBadge key={props.day.toString()} overlap="circular" variant="dot" invisible={!isSelected}>
				<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
			</StyledBadge>
		</Tooltip>
	);
};

const Layout: React.FC<BackupAndRestoreLayoutProps> = ({
	showModal,
	setShowModal,
	applications,
	selectedApp,
	handleDateChange,
	handleAppChange,
	minDate,
	maxDate,
	selectedDate,
	highlightedDays,
	handleMonthChange,
	backupData,
	backupDataLoading,
	handleBackupDataChange,
	backupDatesLoading,
	selectedBackupData,
	handleRestoreData,
	restoreInProgress,
	toastMessage,
	setToastMessage,
	activeTab,
	setActiveTab,
	handleBackUpData,
	backupInProgress,
	isMarketBackup,
	setIsMarketBackup,
	marketList,
	selectedMarket,
	setSelectedMarket,
	backupName,
	setBackupName,
	getMarketBackupList,
	backupNameList,
	backupNameListLoading,
}) => {
	const tabs = React.useMemo(() => {
		return [
			<Tab
				text={"Restore"}
				tabPanelId={BACKUPRESTORETABS.RESTORE}
				type="button"
				onClick={() => {
					if (activeTab == BACKUPRESTORETABS.BACKUP) {
						setBackupName(undefined);
						setSelectedMarket(undefined);
						setIsMarketBackup(false);
					}
					setActiveTab(BACKUPRESTORETABS.RESTORE);
				}}
			/>,
			<Tab
				key="backup"
				text={"Backup"}
				type="button"
				tabPanelId={BACKUPRESTORETABS.BACKUP}
				onClick={() => {
					if (activeTab == BACKUPRESTORETABS.RESTORE) {
						setBackupName(undefined);
						setSelectedMarket(undefined);
						setIsMarketBackup(false);
					}

					setActiveTab(BACKUPRESTORETABS.BACKUP);
				}}
			/>,
		];
	}, [activeTab, setActiveTab, setBackupName, setIsMarketBackup, setSelectedMarket]);

	const tabPanels = React.useMemo(() => {
		const tabPanels = [
			<TabPanel key={BACKUPRESTORETABS.RESTORE} tabPanelId={BACKUPRESTORETABS.RESTORE}>
				{applications?.length > 1 && (
					<Select id="applications" label="Applications" value={selectedApp || undefined} onChange={handleAppChange}>
						{applications?.map((application) => (
							<SelectOption key={application?.id} value={application?.id} name={application?.applicationName} />
						))}
					</Select>
				)}
				{applications?.length === 1 && (
					<InlineMessage body={applications[0].id} title={applications[0].applicationName} />
				)}

				{
					<SwitchWraper>
						<Switch
							data-testid="market-restore-switch"
							value={""}
							checked={isMarketBackup}
							id="market-backup"
							label="Restore Market"
							onChange={() => {
								setIsMarketBackup(!isMarketBackup);
							}}
						/>
					</SwitchWraper>
				}

				{isMarketBackup && (
					<>
						<Select
							id="restore-market-select"
							label="Market"
							value={selectedMarket || ""}
							onChange={(e) => {
								setSelectedMarket(e.target.value);
								getMarketBackupList(e.target.value);
							}}
						>
							{marketList?.map((market) => (
								<SelectOption key={market.id} value={market.id} name={`${market.name}(${market.id})`} />
							))}
						</Select>
						<BackupNameMargin>
							{backupNameListLoading ? (
								<Skeleton height="4rem" />
							) : (
								<Select
									id="select-back-up"
									label="Select Backup To Restore"
									onChange={(e) => {
										setBackupName(e.target.value);
									}}
									disabled={!selectedMarket || backupNameListLoading}
								>
									{backupNameList?.map((item) => (
										<SelectOption key={item} value={item} name={item} />
									))}
								</Select>
							)}
						</BackupNameMargin>
					</>
				)}

				{!isMarketBackup && (
					<DatePickerContainer>
						{backupDatesLoading ? (
							<Skeleton height="4rem" />
						) : (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<InputLabel
									htmlFor="date-input"
									sx={{
										fontSize: "0.875rem",
										lineHeight: "1.571",
										color: "rgb(72, 72, 72)",
										letterSpacing: "0",
										// eslint-disable-next-line quotes
										fontFamily: `"Noto IKEA", "Noto Sans", "Roboto", "Open Sans", "sans-serif"`,
									}}
								>
									Date
								</InputLabel>
								<DatePickerComponent
									value={(selectedDate && dayjs(selectedDate)) || null}
									maxDate={dayjs(maxDate)}
									minDate={dayjs(minDate)}
									onChange={(value: Dayjs | null) => handleDateChange(value)}
									disabled={!selectedApp || backupDatesLoading}
									slotProps={{
										textField: {
											variant: "outlined",
											label: "",
											id: "date-input",
											fullWidth: true,
											sx: datePickerInputFieldStyles,
										},
										day: {
											highlightedDays,
										} as any,
									}}
									slots={{
										day: randerBatch,
									}}
									onMonthChange={handleMonthChange}
									disableHighlightToday
								/>
							</LocalizationProvider>
						)}
					</DatePickerContainer>
				)}
				{!isMarketBackup && selectedDate && backupDataLoading && <Skeleton height="48px" />}
				{!isMarketBackup && backupData && !backupDataLoading && backupData?.length === 1 && (
					<p>{`Backup was made at ${dayjs(backupData[0]).format("hh:mm A")}`}</p>
				)}
				{!isMarketBackup && backupData && !backupDataLoading && backupData?.length > 1 && (
					<Select id="select-time" label="Time" disabled={backupDataLoading} onChange={handleBackupDataChange}>
						{backupData?.map((data) => (
							<SelectOption key={data} value={data} name={dayjs(data).format("hh:mm A")} />
						))}
					</Select>
				)}
			</TabPanel>,
			<TabPanel key={BACKUPRESTORETABS.BACKUP} tabPanelId={BACKUPRESTORETABS.BACKUP}>
				{applications?.length > 1 && (
					<Select id="applications" label="Applications" value={selectedApp || undefined} onChange={handleAppChange}>
						{applications?.map((application) => (
							<SelectOption key={application?.id} value={application?.id} name={application?.applicationName} />
						))}
					</Select>
				)}
				{applications?.length === 1 && (
					<InlineMessage body={applications[0].id} title={applications[0].applicationName} />
				)}

				{
					<SwitchWraper>
						<Switch
							data-testid="market-backup-switch"
							value={""}
							checked={isMarketBackup}
							id="market-backup"
							label="Backup only selected market data"
							onChange={() => {
								setIsMarketBackup(!isMarketBackup);
							}}
						/>
					</SwitchWraper>
				}

				{isMarketBackup && (
					<>
						<Select
							id="backup-market-select"
							label="Market"
							value={selectedMarket || ""}
							onChange={(e) => {
								setSelectedMarket(e.target.value);
							}}
						>
							{marketList?.map((market) => (
								<SelectOption key={market.id} value={market.id} name={`${market.name}(${market.id})`} />
							))}
						</Select>
						<BackupNameMargin>
							<InputField
								req={false}
								value={backupName || ""}
								id="backupName"
								label="Backup Name"
								type="text"
								onChange={({ target: { value } }) => {
									setBackupName(value);
								}}
							/>
						</BackupNameMargin>
					</>
				)}
			</TabPanel>,
		];
		return tabPanels;
	}, [
		applications,
		backupData,
		backupDataLoading,
		backupDatesLoading,
		backupName,
		backupNameList,
		backupNameListLoading,
		getMarketBackupList,
		handleAppChange,
		handleBackupDataChange,
		handleDateChange,
		handleMonthChange,
		highlightedDays,
		isMarketBackup,
		marketList,
		maxDate,
		minDate,
		selectedApp,
		selectedDate,
		selectedMarket,
		setBackupName,
		setIsMarketBackup,
		setSelectedMarket,
	]);
	return (
		<>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showModal}
				onModalClosed={() => {
					setShowModal(false);
					setBackupName(undefined);
					setIsMarketBackup(false);
					setSelectedMarket(undefined);
					setIsMarketBackup(false);
					setActiveTab(BACKUPRESTORETABS.RESTORE);
				}}
				handleCloseBtn={() => setShowModal(false)}
			>
				<Sheets
					footer={
						<ModalButtonWrapper>
							{activeTab == BACKUPRESTORETABS.RESTORE ? (
								<Button
									data-testid="restoreData"
									text={"Restore Data"}
									type="emphasised"
									disabled={
										(!isMarketBackup &&
											(backupDataLoading ||
												backupDatesLoading ||
												!selectedApp ||
												!selectedDate ||
												!selectedBackupData)) ||
										(isMarketBackup && (!selectedApp || !selectedMarket || !backupName))
									}
									onClick={handleRestoreData}
									loading={restoreInProgress}
								/>
							) : (
								<Button
									data-testid="backupData"
									text={"Backup Data"}
									type="emphasised"
									disabled={!selectedApp}
									onClick={handleBackUpData}
									loading={backupInProgress}
								/>
							)}
						</ModalButtonWrapper>
					}
					header={<StyledModalHeader title={"Backup/Restore Application Data"} />}
				>
					<ModalBody>
						<Tabs
							tabs={tabs}
							tabPanels={tabPanels}
							defaultActiveTab={BACKUPRESTORETABS.RESTORE}
							onTabChanged={(e) => setActiveTab(e)}
						/>
					</ModalBody>
				</Sheets>
			</Modal>
			<Toast
				text={toastMessage}
				isOpen={!!toastMessage}
				ariaLabelCloseBtn="Dismiss notification"
				onCloseRequest={() => setToastMessage("")}
			/>
		</>
	);
};

export default Layout;
