import * as React from "react";

import Modal, { ModalHeader, Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import Label from "@ingka/label";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import { MultiSelectStyles, StyledModalHeader } from "../styles";

import styled from "styled-components";
import Select, { Option as SelectOption } from "@ingka/select";
import FormField from "@ingka/form-field";
import { ENV_DEV, ENV_STAGE, ENV_PROD, ENV_LOCAL } from "../../utils/constants";
import Loading, { LoadingBall } from "@ingka/loading";
import { getApplicationDataFromOtherEnv, handleImportSchemaAndUISchema } from "./utils";
import useAuthentication from "../../hooks/useAuthentication";
import useStore, { activeApplicationSelector } from "../../store";
import InlineMessage from "@ingka/inline-message";
import ListView, { ListViewItem } from "@ingka/list-view";
import { SchemaNameList } from "../../services/applications/types";

const StyledPrompt = styled(Prompt)`
	width: 30rem;
`;

const StyledDiv = styled.div`
	padding-top: 1rem;
`;

const StyledLoading = styled(Loading)`
	.loading__text {
		margin-top: 0.5rem;
	}
`;

const StyledListView = styled(ListView)`
	padding-inline-start: 0rem;
`;

const StyledListHeader = styled.p`
	padding-top: 1rem;
	font-size: 0.875rem;
	line-height: 1.571;
	font-weight: bold;
`;

interface ImportSchemaModalProps {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	setToastMessage: (value: string) => void;
	setImportedSchema: (value: boolean) => void;
}

const ImportSchemaModal: React.FC<ImportSchemaModalProps> = ({
	showModal,
	setShowModal,
	setToastMessage,
	setImportedSchema,
}) => {
	const [appEnvironments, setAppEnvironments] = React.useState<string[]>([]);
	const [loadingSchema, setLoadingSchema] = React.useState<boolean>(false);
	const { getToken } = useAuthentication();
	const activeApplication = useStore(activeApplicationSelector);
	const [selectedEnv, setSelectedEnv] = React.useState<string>("");
	const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
	const [schemaList, setSchemaList] = React.useState<string[] | undefined>();
	const [errorCode, setErrorCode] = React.useState<number | null>(null);
	const [selectedUISchemas, setSelectedUISchemas] = React.useState<string[]>(["ui-schema"]);
	const [importingSchemaUISchema, setImportingSchemaUISchema] = React.useState<boolean>(false);

	React.useEffect(() => {
		let baseURL = process.env.REACT_APP_FLAGGA_ADMIN_BASE_URL;
		if (baseURL) {
			if (baseURL.includes(ENV_DEV) || baseURL.includes(ENV_LOCAL) || baseURL.includes(ENV_PROD)) {
				setAppEnvironments([ENV_STAGE]);
			} else if (baseURL.includes(ENV_STAGE)) {
				setAppEnvironments([ENV_DEV, ENV_PROD]);
			}
		}
	}, []);

	return (
		<Modal
			focusLockProps={{ locked: false }}
			visible={showModal}
			escapable={true}
			handleCloseBtn={() => {
				setShowModal(false);
				setLoadingSchema(false);
				setDataLoaded(false);
				setSelectedEnv("");
				setImportingSchemaUISchema(false);
			}}
		>
			<StyledPrompt
				title=""
				titleId={"modalTitleId"}
				header={
					<StyledModalHeader
						id="import-schema-modal-header"
						ariaCloseTxt="Close button text"
						title="Import schema & ui-schema"
					/>
				}
				footer={
					<ModalFooter>
						<Button
							data-testid="importvaluesbutton"
							text="Import"
							loading={importingSchemaUISchema}
							disabled={!schemaList || !dataLoaded || !(schemaList?.length || schemaList?.includes("schema"))}
							type="emphasised"
							onClick={() =>
								handleImportSchemaAndUISchema({
									activeApplication,
									schemaList,
									uiSchemas: selectedUISchemas,
									getToken,
									selectedEnv,
									setImportingSchemaUISchema,
									setShowModal,
									setSelectedEnv,
									setToastMessage,
									setImportedSchema,
								})
							}
						/>
					</ModalFooter>
				}
			>				
				<StyledDiv>
					<InlineMessage
						title={"Warning"}
						body={"Importing schema will remove all the properties from market values that are removed from schema."}
						variant={"cautionary"}
						dismissable={false}
					/>
					<br />
					<FormField shouldValidate={false}>
						<Select
							id="select-env"
							label="Select from which environment data should be copied"
							value={selectedEnv}
							onChange={({ target: { value } }) => {
								if (value === "Choose an option") {
									setSelectedEnv("");
									setDataLoaded(false);
								} else {
									setSelectedEnv(value);
									getApplicationDataFromOtherEnv({
										getToken,
										activeApplication,
										env: value.toLowerCase(),
										setDataLoaded,
										setLoadingSchema,
										setSchemaList,
										setErrorCode,
									});
								}
							}}
						>
							{appEnvironments.map((env) => (
								<SelectOption key={env} value={env} name={env} />
							))}
						</Select>
					</FormField>
					{loadingSchema && (
						<StyledLoading text="Loading data" labelTransitions>
							<LoadingBall />
						</StyledLoading>
					)}
					{!schemaList && dataLoaded && errorCode === 403 && (
						<InlineMessage
							title={"Check admin privileges"}
							body={`User is not an admin for ${activeApplication?.name} in ${selectedEnv}`}
							variant={"negative"}
						/>
					)}
					{!schemaList && dataLoaded && errorCode !== 403 && (
						<InlineMessage
							title={"Application's data was not found"}
							body={`${activeApplication?.name}'s data was not found in ${selectedEnv}`}
							variant={"negative"}
						/>
					)}
					{dataLoaded && schemaList && (
						<>
							{(schemaList.length == 0 || !schemaList.includes("schema")) && (
								<InlineMessage
									title={`No schema was found for ${activeApplication?.name} in ${selectedEnv}`}
									variant={"negative"}
								/>
							)}
							{schemaList.length > 0 && schemaList.includes("schema") && (
								<>
									<StyledListHeader>Application Schema</StyledListHeader>
									<StyledListView id="schema-list-view" showDivider size="small">
										<ListViewItem
											control="checkbox"
											controlProps={
												{
													value: "schema",
													checked: true,
												} as any
											}
											disabled
											description=""
											title="Schema"
										/>
									</StyledListView>
									{schemaList.filter((item) => item !== "schema").length > 0 && (
										<>
											<StyledListHeader>List of UI-Schemas</StyledListHeader>
											<StyledListView id="uischema-list-view" showDivider size="small">
												{(schemaList || [])
													.filter((item) => item !== "schema")
													.sort()
													.map((uiSchema) => (
														<ListViewItem
															control="checkbox"
															controlProps={
																{
																	value: uiSchema,
																	defaultChecked: uiSchema === "ui-schema",
																} as any
															}
															disabled={uiSchema === "ui-schema"}
															onChange={(e) => {
																if ((e.target as any)?.checked) {
																	setSelectedUISchemas(
																		selectedUISchemas ? [...selectedUISchemas, uiSchema] : [uiSchema]
																	);
																} else {
																	setSelectedUISchemas(selectedUISchemas.filter((_uiSchema) => _uiSchema !== uiSchema));
																}
															}}
															title={uiSchema}
														/>
													))}
											</StyledListView>
										</>
									)}
								</>
							)}
						</>
					)}
				</StyledDiv>
			</StyledPrompt>
		</Modal>
	);
};

export default ImportSchemaModal;
