import * as React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import InputField from "@ingka/input-field";
import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal";
import { ModalButtonWrapper } from "../styles";
import TextArea from "@ingka/text-area";
import { FormErrors, FormValid, ShouldValid } from "./types";
import FormField from "@ingka/form-field";
import { NewSchemaFormFieldNames } from "./constants";

interface AddSchemaModalProps {
	showModal: boolean;
	onAdd: React.FormEventHandler<HTMLFormElement>;
	setNewUiSchemaValue: (value: string) => void;
	setNewUiSchemaName: (value: string) => void;
	formValid: FormValid;
	formErrors: FormErrors;
	shouldValid: ShouldValid;
	validate: (val: { name: string; value: string }) => void;
	onModalClose: () => void;
	isNewApplication: boolean;
}

const StyledTextArea = styled(TextArea)`
	margin-top: 2rem;
	textarea {
		height: 30vw;
	}
`;

const AddSchemaModal: React.FC<AddSchemaModalProps> = ({
	showModal,
	onAdd,
	setNewUiSchemaValue,
	setNewUiSchemaName,
	shouldValid,
	formErrors,
	formValid,
	validate,
	onModalClose,
	isNewApplication,
}) => {
	React.useEffect(() => {
		setNewUiSchemaName("ui-schema");
	}, [setNewUiSchemaName]);

	return (
		<form
			onSubmit={(e) => {
				onAdd(e);
			}}
		>
			<Modal
				focusLockProps={{ locked: false }}
				visible={showModal}
				handleCloseBtn={() => {
					onModalClose();
				}}
			>
				<Sheets
					footer={
						<ModalButtonWrapper>
							<Button text="Add UI-Schema" type="emphasised" htmlType="submit" />
						</ModalButtonWrapper>
					}
					size="medium"
					header={<ModalHeader title="Add UI-Schema" />}
				>
					<ModalBody>
						<FormField
							valid={formValid.nameValid}
							shouldValidate={shouldValid.shouldValidName}
							disabled={isNewApplication}
							fieldHelper={{
								msg: isNewApplication
									? "New Application must have default ui-schema"
									: "Spaces and Special Characters not allowed",
								id: "ui-schema-name",
							}}
							validation={{ msg: formErrors.nameError, id: "name-error", type: "error" }}
						>
							<InputField
								req={true}
								id="ui-schema-name"
								label="UI-Schema Name"
								type="text"
								defaultValue={isNewApplication ? "ui-schema" : undefined}
								disabled={isNewApplication}
								onChange={({ target: { value } }) => {
									setNewUiSchemaName(value);
								}}
								onBlur={({ target: { value } }) => {
									validate({ name: NewSchemaFormFieldNames.UiSchemaName, value });
								}}
							/>
						</FormField>
						<FormField
							valid={formValid.uiSchemaValid}
							shouldValidate={shouldValid.shouldValidUiSchema}
							fieldHelper={{ msg: "JSON Object", id: "ui-schema-value" }}
							validation={{ msg: formErrors.uiSchemaError, id: "ui-schema-error", type: "error" }}
						>
							<StyledTextArea
								req={true}
								id="ui-schema"
								label="UI-Schema"
								onChange={({ target: { value } }) => {
									setNewUiSchemaValue(value);
								}}
								onBlur={({ target: { value } }) => {
									validate({ name: NewSchemaFormFieldNames.UiSchemaValue, value });
								}}
							/>
						</FormField>
					</ModalBody>
				</Sheets>
			</Modal>
		</form>
	);
};

export default AddSchemaModal;
